import React from "react";
import { useNavigate } from 'react-router-dom';
import { Modal } from "react-bootstrap";
import {LoadingIcon} from "../../../themes/userq/Loader/loadingIcon";
import { Text } from "../Text/Text";

export default function LoadingModal({
    open,
    message
}) {
   
    return (
        <Modal
            show={open}
            centered
            className="fade custom-modal-wrap  loading-modal-wrap"
        >
            <Modal.Body className="">
                <div className="modal-loader text-center">
                    <div className="loader-with-text">
                        <LoadingIcon/>
                        <Text type={"subtitle-2"} fontWeight={"medium-font"}>{message}</Text>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}
