import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { isEmpty } from "lodash";
import { Dropdown } from "react-bootstrap";
import {Input} from "../Input/Input";
import {DropdownComponent} from "../Dropdown/Dropdown";
import { Icon } from './../Icon/Icon';
import { QuestionImage } from "../QuestionImage/QuestionImage";

export default function Options(props) {
    const [optionError, setOptionError] = useState("");
    const [optionErrorIndex, setOptionErrorIndex] = useState(null);
    const [otherOptionIncluded, setOtherOptionIncluded] = useState(false);
    const queryAttr = "data-rbd-drag-handle-draggable-id";
    const [placeholderProps, setPlaceholderProps] = useState({});
    const [dropdownOptions, setDropdownOptions] = useState([]);
    const [logicVal, setLogicVal] = useState(null);

    useEffect(()=>{
        var dropdownOptionsArray = [];
        if (props.logicAdded == true) {
            props.jumpToList.forEach((item, i) => (
                <>
                {item == "End Survey" ?
                    dropdownOptionsArray.push({ label: "End survey", value: "End Survey" })
                    :
                    (item - props.questionno == 1)?
                        dropdownOptionsArray.push({ label: "Next Question", value: item })
                        :
                        dropdownOptionsArray.push({ label: "Question "+item, value: item })
                }
                </>
            ));
            dropdownOptionsArray.push({ label: "End survey", value: "End Survey" });
            setDropdownOptions(dropdownOptionsArray);
        }
    },[props.logicAdded,props.jumpToList])

    const handleDragStart = (event) => {
        const draggedDOM = getDraggedDom(event.draggableId);

        if (!draggedDOM) {
            return;
        }

        const { clientHeight, clientWidth } = draggedDOM;
        const sourceIndex = event.source.index;
        var clientY =
            parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
            [...draggedDOM.parentNode.children]
                .slice(0, sourceIndex)
                .reduce((total, curr) => {
                    const style = curr.currentStyle || window.getComputedStyle(curr);
                    const marginBottom = parseFloat(style.marginBottom);
                    const marginTop = parseFloat(style.marginTop);
                    return total + curr.clientHeight + marginTop;
                }, 0);

        setPlaceholderProps({
            clientHeight,
            clientWidth,
            clientY,
            clientX: parseFloat(
                window.getComputedStyle(draggedDOM.parentNode).paddingLeft
            ),
            background:'#CCCCCC',
            border:'none'
        });
    };

    const handleDragUpdate = (event) => {
        if (!event.destination) {
            return;
        }

        const draggedDOM = getDraggedDom(event.draggableId);

        if (!draggedDOM) {
            return;
        }

        const { clientHeight, clientWidth } = draggedDOM;
        const destinationIndex = event.destination.index;

        const sourceIndex = event.source.index;

        const childrenArray = [...draggedDOM.parentNode.children];
        const movedItem = childrenArray[sourceIndex];
        childrenArray.splice(sourceIndex, 1);

        const updatedArray = [
            ...childrenArray.slice(0, destinationIndex),
            movedItem,
            ...childrenArray.slice(destinationIndex + 1),
        ];



        var clientY =
            parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
            updatedArray.slice(0, destinationIndex).reduce((total, curr) => {
                const style = curr.currentStyle || window.getComputedStyle(curr);
                const marginBottom = parseFloat(style.marginBottom);
                const marginTop = parseFloat(style.marginTop);
                return total + curr.clientHeight + marginTop;
            }, 0);

        setPlaceholderProps({
            clientHeight,
            clientWidth,
            clientY,
            clientX: parseFloat(
                window.getComputedStyle(draggedDOM.parentNode).paddingLeft
            ),
            background:'none',
             border:'1px dashed'
        });
    };

    const getDraggedDom = (draggableId) => {
        const domQuery = `[${queryAttr}='${draggableId}']`;
        const draggedDOM = document.querySelector(domQuery);

        return draggedDOM;
    };

    var a = ['','one ','two ','three ','four ', 'five ','six ','seven ','eight ','nine ','ten ','eleven ','twelve ','thirteen ','fourteen ','fifteen ','sixteen ','seventeen ','eighteen ','nineteen '];
    var b = ['', '', 'twenty','thirty','forty','fifty', 'sixty','seventy','eighty','ninety'];

    function inWords (num) {
        //console.log(num)
        if ((num = num.toString()).length > 12) return 'overflow';
        var n = ('00000000000' + num).substr(-12).match(/^(\d{3})(\d{3})(\d{3})(\d{1})(\d{2})$/);
        if (!n) return; var str = '';
        str += (n[1] != 0) ? (Number(n[1]) > 99 ? this.a[Number(n[1][0])] + 'hundred ' : '') + (a[Number(n[1])] || b[n[1][1]] + ' ' + a[n[1][2]]) + 'billion ' : '';
        str += (n[2] != 0) ? (Number(n[2]) > 99 ? this.a[Number(n[2][0])] + 'hundred ' : '') + (a[Number(n[2])] || b[n[2][1]] + ' ' + a[n[2][2]]) + 'million ' : '';
        str += (n[3] != 0) ? (Number(n[3]) > 99 ? this.a[Number(n[3][0])] + 'hundred ' : '') + (a[Number(n[3])] || b[n[3][1]] + ' ' + a[n[3][2]]) + 'thousand ' : '';
        str += (n[4] != 0) ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'hundred ' : '';
        str += (Number(n[5]) !== 0) ? ((str !== '') ? ' ' : '') +
        //str += (Number(n[5]) !== 0) ? ((str !== '') ? 'and ' : '') +
            (a[Number(n[5])] || b[n[5][0]] + ' ' +
                a[n[5][1]]) + '' : '';
        return str;
    }


    return (
        props.questionType != "likertscale" ?
            props.questionType != "ratingscale" ?
                <div style={{ position: 'relative' }}>
                    <DragDropContext
                        onDragEnd={(param) => {
                            const srcI = param.source.index;
                            const desI = param.destination.index;
                            props.allOptions.splice(desI, 0, props.allOptions.splice(srcI, 1)[0]);
                            props.allJumpTo.splice(desI, 0, props.allJumpTo.splice(srcI, 1)[0]);
                            props.optionImages.splice(desI, 0, props.optionImages.splice(srcI, 1)[0]);

                            setPlaceholderProps({});
                        }}
                        onDragStart={handleDragStart}
                        onDragUpdate={handleDragUpdate}
                    >
                        {props.language == "ar" ?
                            <Droppable droppableId="droppable-1">
                                {(provided, snapshot) => (
                                    <div ref={provided.innerRef} {...provided.droppableProps}>
                                        {props.allOptions.map((option, index) =>
                                                (option != "آخر" && option != "لا شيء مما بالأعلى") ? (
                                                    <Draggable
                                                        key={index}
                                                        draggableId={"optiondraggable-" + index}
                                                        index={index}
                                                    >
                                                        {(provided, snapshot) => (
                                                            <div
                                                                className={`ans-list-repeat saved-ans-repeat ${snapshot.isDragging ? "question-answers-dragging draggable-element-start" : ""
                                                                    } `}
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                            >
                                                                <div className="ans-icon" {...provided.dragHandleProps}>
                                                                    <Icon value="move-object" colorClass={'gray-900-svg'} size="medium" hover={true}/>
                                                                </div>
                                                                <div className={`ans-data  ${snapshot.isDragging ? 'draggable-inner-element-start' : ''} ${props.questionType === "singlechoice" ? 'answer-input-with-image' : ''} ${props.questionType === "multiplechoice" ? 'answer-input-with-image' : ''}`}>
                                                                    {(props.questionType == "singlechoice" || props.questionType=="multiplechoice") &&
                                                                    <>
                                                                    {(!props.methodology || props.methodology=="" || props.methodology!="preference_test") &&
                                                                    <QuestionImage 
                                                                        questionSection={props.questionSection?props.questionSection:null}
                                                                        questionSubSection={"options"}
                                                                        url={props.optionImages[index] ? props.optionImages[index].image_url:null} 
                                                                        id={props.optionImages[index] ? props.optionImages[index].image_id:null} 
                                                                        onImageUpload={(image_id,image_url)=>{
                                                                            props.saveOptionImage(index,image_id, image_url);
                                                                        }}
                                                                    />
                                                                    }
                                                                    </>
                                                                    }
                                                                    <Input
                                                                        label={ index == 0 ? "First option" :
                                                                            index == 1 ? "Second option" :
                                                                                index == 2 ? "Third option" :
                                                                                    index == 3 ? "Fourth option" :
                                                                                        index == 4 ? "Fifth option" :
                                                                                            index == 5 ? "Sixth option" :
                                                                                                index == 6 ? "Seventh option" :
                                                                                                    index == 7 ? "Eighth option" :
                                                                                                        index == 8 ? "Ninth option" :
                                                                                                            index == 9 ? "Tenth option" :
                                                                                                                "Another option"
                                                                        }
                                                                        placeholder="Please enter an option"
                                                                        onChange={(e) => {
                                                                            props.save(index, e.target.value);
                                                                        }}
                                                                        required={true}
                                                                        value={option}
                                                                        onKeyDown={(e) => {
                                                                            if (e.key === 'Enter') {
                                                                                props.addOption(index);
                                                                            }
                                                                        }}
                                                                        onBlur={(e) => {
                                                                            if (props.allOptions[index] == "") {
                                                                            } else {
                                                                                props.save(index, e.target.value);
                                                                            }
                                                                        }}
                                                                        autoFocus={props.allOptions.length==2?false:true}
                                                                        clearField={false}
                                                                        rtl={props.language == "ar" ? true : false}
                                                                        maxLength={"250"}

                                                                    />
                                                                </div>

                                                                <div className="ans-close-row">
                                                                    <span onClick={() => props.close(index)}>
                                                                          <Icon value="close" size="medium" hover={true}/>
                                                                    </span>
                                                                </div>


                                                                {props.questionType == "singlechoice" && props.logicAdded == true &&
                                                                <div className="small-dropdown add-logic-dropdown">
                                                                    <DropdownComponent
                                                                        label="Jump to"
                                                                        options={dropdownOptions}
                                                                        value={
                                                                            logicVal?
                                                                                logicVal:
                                                                            props.allJumpTo[index] != "End Survey" ? (props.allJumpTo[index] - props.questionno == 1 ? "Next question" : "Question " + props.allJumpTo[index]) : "End survey"
                                                                        }
                                                                        onChange={(item) => {
                                                                            props.saveJumpTo(index, item.value)
                                                                        }} />
                                                                    
                                                                </div>
                                                                }
                                                            </div>

                                                        )}
                                                    </Draggable>
                                                ) : (
                                                    <>
                                                    {option === "آخر" &&
                                                        setOtherOptionIncluded(true)
                                                    }
                                                    </>
                                                )
                                        )}
                                        {provided.placeholder}
                                        {!isEmpty(placeholderProps) && (
                                            <div
                                                className="placeholder"
                                                style={{
                                                    top: placeholderProps.clientY,
                                                    left: placeholderProps.clientX,
                                                    height: placeholderProps.clientHeight,
                                                    width: placeholderProps.clientWidth,
                                                    position: "absolute",
                                                    borderColor: "#000000",
                                                    background:placeholderProps.background,
                                                    borderRadius:'10px',
                                                    border:placeholderProps.border,
                                                    margin: '-4px 0px 0px'
                                                }}
                                            />
                                        )}
                                    </div>
                                )}
                            </Droppable>
                            :
                            <Droppable droppableId="droppable-1">
                                {(provided, snapshot) => (
                                    <div ref={provided.innerRef} {...provided.droppableProps}>
                                        {props.allOptions.map((option, index) =>
                                                (option != "Other" && option != "None of the above") ? (
                                                    <Draggable
                                                        key={index}
                                                        draggableId={"optiondraggable-" + index}
                                                        id={"draggable-" + index}
                                                        index={index}
                                                    >
                                                        {(provided, snapshot) => (
                                                            <div
                                                            className={`d-flex m-3 ans-list-repeat saved-ans-repeat ${snapshot.isDragging ? "question-answers-dragging draggable-element-start" : ""} 
                                                                ${props.questionType === "singlechoice" ? 'input-image-answer-hold-wrap' : ''} 
                                                                ${props.questionType === "multiplechoice" ? 'input-image-answer-hold-wrap' : ''}`}
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                            >
                                                                <div className="ans-icon move-item" {...provided.dragHandleProps}>
                                                                    <Icon value="move-object" colorClass={'gray-900-svg'} size="medium" hover={true}/>
                                                                </div>
                                                                <div className={`ans-data  ${snapshot.isDragging ? 'draggable-inner-element-start' : ''} ${props.questionType === "singlechoice" ? ((!props.methodology || props.methodology=="" || props.methodology!="preference_test") ? ((!props.methodology || props.methodology=="" || props.methodology!="preference_test")?'answer-input-with-image':''):'') : ''} ${props.questionType === "multiplechoice" ? 'answer-input-with-image' : ''}`}>
                                                                {(props.questionType == "singlechoice" || props.questionType=="multiplechoice") &&
                                                                    <>
                                                                    {(!props.methodology || props.methodology=="" || props.methodology!="preference_test") &&
                                                                    <QuestionImage 
                                                                        questionSection={props.questionSection?props.questionSection:null}
                                                                        questionSubSection={"options"}
                                                                        url={props.optionImages[index] ? props.optionImages[index].image_url:null} 
                                                                        id={props.optionImages[index] ? props.optionImages[index].image_id:null} 
                                                                        onImageUpload={(image_id,image_url)=>{
                                                                            props.saveOptionImage(index,image_id, image_url);
                                                                        }}
                                                                    />
                                                                    }
                                                                    </>
                                                                    }
                                                                    <Input
                                                                        label={ index == 0 ? "First option" :
                                                                            index == 1 ? "Second option" :
                                                                                index == 2 ? "Third option" :
                                                                                    index == 3 ? "Fourth option" :
                                                                                        index == 4 ? "Fifth option" :
                                                                                            index == 5 ? "Sixth option" :
                                                                                                index == 6 ? "Seventh option" :
                                                                                                    index == 7 ? "Eighth option" :
                                                                                                        index == 8 ? "Ninth option" :
                                                                                                            index == 9 ? "Tenth option" :
                                                                                                                "Another option"
                                                                        }
                                                                        placeholder="Please enter an option"
                                                                        onChange={(e) => {
                                                                            if(e.target.value!="Other"){
                                                                            props.save(index, e.target.value);
                                                                            }
                                                                        }}
                                                                        required={true}
                                                                        value={option}
                                                                        onKeyDown={(e) => {
                                                                            if (e.key === 'Enter') {
                                                                                if(option=="Othe" || option=="other"){
                                                                                props.close(index);
                                                                                props.save(index, "Other");
                                                                                }else{
                                                                                    props.addOption(index);
                                                                                }
                                                                            }
                                                                        }}
                                                                        onBlur={(e) => {
                                                                            if (props.allOptions[index] == "") {
                                                                            } else {
                                                                                if(option=="Other" || e.target.value=="other"){
                                                                                    if(otherOptionIncluded){
                                                                                    props.close(index);
                                                                                    }else{
                                                                                    props.save(index, "Other");
                                                                                    }
                                                                                }
                                                                                else{
                                                                                    props.save(index, e.target.value);
                                                                                }
                                                                                
                                                                            }
                                                                        }}
                                                                        autoFocus={props.allOptions.length==2?false:true}
                                                                        clearField={false}
                                                                        rtl={props.language == "ar" ? true : false}
                                                                        maxLength={"250"}

                                                                    />
                                                                </div>

                                                                <div className="ans-close-row">
                                                                    <span onClick={() => props.close(index)}>
                                                                      <Icon value="close" size="medium" hover={true}/>
                                                                    </span>
                                                                </div>

                                                                {props.questionType == "singlechoice" && props.logicAdded == true && props.allJumpTo &&
                                                                <div className="small-dropdown add-logic-dropdown">
                                                                    <DropdownComponent
                                                                        label="Jump to"
                                                                        options={dropdownOptions}
                                                                        //value={logicVal}
                                                                        value={
                                                                            //setLogicVal
                                                                            logicVal?
                                                                                logicVal:
                                                                                props.allJumpTo[index] != "End Survey" ? (props.allJumpTo[index] - props.questionno == 1 ? "Next question" : "Question " + props.allJumpTo[index]) : "End survey"
                                                                        }
                                                                        onChange={(item) => {
                                                                            // setLikertScaleType(item.value);
                                                                            // mapLikertOptions(item.value);
                                                                           // saveJumpTo(i, item.value);
                                                                            //setLogicVal(item.value)
                                                                            props.saveJumpTo(index, item.value)
                                                                        }} />
                                                                    
                                                                </div>
                                                                }
                                                            </div>

                                                        )}
                                                    </Draggable>
                                                ) : (
                                                    <>
                                                    {option === "Other" &&
                                                        setOtherOptionIncluded(true)
                                                    }
                                                    </>
                                                )
                                        )}
                                        {provided.placeholder}
                                        {!isEmpty(placeholderProps) && (
                                            <div
                                                className="placeholder"
                                                style={{
                                                    top: placeholderProps.clientY,
                                                    left: placeholderProps.clientX,
                                                    height: placeholderProps.clientHeight,
                                                    width: placeholderProps.clientWidth,
                                                    position: "absolute",
                                                    borderColor: "#000000",
                                                    background:placeholderProps.background,
                                                    borderRadius:'10px',
                                                    border:placeholderProps.border,
                                                    margin: '-4px 0px 10px'
                                                }}
                                            />
                                        )}
                                    </div>
                                )}
                            </Droppable>}
                    </DragDropContext>
                    {otherOptionIncluded && props.questionType !== 'rankingscale' && (
                        <div className={`d-flex ans-list-repeat saved-ans-repeat ${props.logicAdded==true?"other-answer-added":""} other-choice-container`}>


                            {props.language == "ar" ? (<div className="ans-data arabic-ans-data arabic-wrapper">آخر</div>) : (<div className="ans-data">Other</div>)
                            }
                            <div className="ans-close-row">
                                <span onClick={() => {
                                    props.closeOtherOption();
                                    setOtherOptionIncluded(false);
                                }}>
                                      <Icon value="close" size="medium" hover={true}/>
                                </span>
                            </div>

                            {props.questionType == "singlechoice" && props.logicAdded == true &&
                            <div className="small-dropdown add-logic-dropdown">
                                <Dropdown>
                                    <Dropdown.Toggle
                                        id={"dropdownMenuButtonother"}
                                        className="dropdown-toggle"
                                    >
                                        {
                                            props.allJumpTo[props.allJumpTo.length - 1] == "End Survey" ?
                                                <>Jump to <b>End Survey </b> </>
                                                :
                                                props.allJumpTo[props.allJumpTo.length - 1] - props.questionno == 1 ?
                                                    <>Jump to <b>Next Question </b> </>
                                                    :
                                                    <>Jump to <b>Question {props.allJumpTo[props.allJumpTo.length - 1]}</b> </>
                                        }

                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <div className="drop-down-link-hold">
                                            {props.jumpToList.map((item, index) => (
                                                <Dropdown.Item
                                                    onClick={() => {
                                                        props.saveJumpTo(props.allJumpTo.length - 1, item);
                                                    }}
                                                >
                                                    {
                                                        item == "End Survey" ?
                                                            <>End Survey</>
                                                            :
                                                            item - props.questionno == 1 ?
                                                                <>Next Question</>
                                                                :
                                                                <>Question {item}</>
                                                    }
                                                </Dropdown.Item>
                                            ))}
                                            {<Dropdown.Item
                                                onClick={() => {
                                                    props.saveJumpTo(props.allJumpTo.length - 1, "End Survey");
                                                }}
                                            >
                                                {
                                                    <>End Survey</>

                                                }
                                            </Dropdown.Item>}
                                        </div>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                            }
                        </div>

                    )}


                    {props.noneOfTheAboveOption && (
                        <div className={`d-flex ans-list-repeat saved-ans-repeat none-of-the-above-container other-choice-container`}>


                            {props.language == "ar" ? (<div className="ans-data arabic-ans-data arabic-wrapper">لا شيء مما بالأعلى</div>) : (<div className="ans-data">None of the above</div>)
                            }
                            <div className="ans-close-row">
                                <span onClick={() => {
                                    props.closeNoneOfTheAboveOption();
                                }}>
                                      <Icon value="close" size="medium" hover={true}/>
                                </span>
                            </div>

                        </div>

                    )}

                </div>
                :
                <>
                    {props.allOptions.map((option, index) => (

                        <label for="radio4">
                            <input
                                key={index}
                                className={`${option != ""
                                    ? ""
                                    : optionErrorIndex == index
                                        ? optionError
                                        : ""
                                    } ${props.language == "ar" ? "arabic_wrapper" : ""
                                    }`}
                                type="text"
                                onChange={(e) => {
                                    props.save(index, e.target.value);
                                }}
                                value={option}
                                // placeholder={`option ${index + 1}`}
                                placeholder="Please enter an option"
                                onBlur={(e) => {
                                    if (props.allOptions[index] == "") {
                                        e.target.placeholder =
                                            "Please enter an option";
                                    } else {
                                        props.save(index, e.target.value);
                                    }
                                }}
                            />
                            <input type="radio" name="ans" className="hidden radio_btn_input" required="required" />
                            <span>
                <img
                    className="empty-fill-icon"
                    src={
                        process.env.REACT_APP_URL +
                        "images/Polygon-blank.svg"
                    }
                />
                <img
                    className="fill-icon"
                    src={
                        process.env.REACT_APP_URL +
                        "images/pol-fill.svg"
                    }
                />
              </span>
                        </label>
                    ))
                    }
                </>
            :
            <>
                {props.allOptions.map((option, index) => (
                    <input
                        key={index}
                        className={`${option != ""
                            ? ""
                            : optionErrorIndex == index
                                ? optionError
                                : ""
                            } ${props.language == "ar" ? "arabic_wrapper" : ""
                            }`}
                        type="text"
                        onChange={(e) => {
                            props.save(index, e.target.value);
                        }}
                        value={option}
                        // placeholder={`option ${index + 1}`}
                        placeholder="Please enter an option"
                        onBlur={(e) => {
                            if (props.allOptions[index] == "") {
                                e.target.placeholder =
                                    "Please enter an option";
                            } else {
                                props.save(index, e.target.value);
                            }
                        }}
                    />
                ))
                }
            </>
    );
}