import React from 'react';
import { useParams, useLocation } from "react-router-dom";
import { ReactSession } from '../../../lib/secure_reactsession';
import { useState, useEffect, useRef } from "react";
import {
    loadMoreTreeTestQuestionResultData,
} from "../../../services/researcher_test_results";
import toast from 'react-hot-toast';
import HideParticipant from './dialog/hide-participant';
import ReportAnswer from "./dialog/report-answer";
import { atom, useRecoilState } from 'recoil';
import { showToast } from '../../../lib/helpers';
import { QuestionsDropdown } from '../../themes/userq/QuestionsDropdown/QuestionsDropdown';
import { Icon } from '../../themes/userq/Icon/Icon';
import { Text } from '../../themes/userq/Text/Text';
import { Search } from '../../themes/userq/Search/Search';
import { ByUserTable } from '../../themes/userq/ByUserTable/ByUserTable';
import { PieStatsBoxSingleChoice } from './components/pie_stats_box_singlechoice';
import { AggregatedDataTable } from '../../themes/userq/AggregatedDataTable/AggregatedDataTable';
import { HorizontalBarChartStatsBoxMultipleChoice } from './components/horizontal_bar_chart_stats_box_multiplechoice';
import { LikertScaleChart } from '../../themes/userq/LikertScaleChart/LikertScaleChart';
import { LikertScaleAggregateData } from '../../themes/userq/LikertScaleAggregateData/LikertScaleAggregateData';
import { LikertScaleByUser } from '../../themes/userq/LikertScaleByUser/LikertScaleByUser';
import { HorizontalBarChartStatsBoxRankingScale } from './components/horizontal_bar_chart_stats_box_rankingscale';
import { Button } from '../../themes/userq/Button/Button';
import { RatingScaleChart } from '../../themes/userq/RatingScale/RatingScaleChart';
import { RatingMultiSelect } from '../../themes/userq/MultiSelecteDropdownList/RatingMultiSelect';

export function compare(a, b) {

    const value1 = a[1]["averageranking"];
    const value2 = b[1]["averageranking"];

    let comparison = 0;

    if (value1 > value2) {
        comparison = -1;
    } else if (value1 < value2) {
        comparison = 1;
    }
    return comparison;
}

export default function TreeTestFollowupQuestionsResult({ resultData, resultCount }) {

    let { test_id } = useParams();
    const location = useLocation();

    const scrollContainerRef = useRef(null);
    const [isScrolled, setIsScrolled] = useState(false);

    const testState = atom({
        key: 'testresult-' + test_id,
        default: ""
    });
    const planState = atom({
        key: 'testresultplan-' + test_id,
        default: ""
    });

    const [sectionLoading, setSectionLoading] = useState(false);
    const [test, setTest] = useRecoilState(testState);
    const [questionArray, setQuestionArray] = useState(resultData);
    const [isActive, setActive] = useState(0);
    const [dropdownQuestions, setDropdownQuestions] = useState(false);
    const [loadMoreLoading, setLoadMoreLoading] = useState(false);

    const [openDeleteAnswerModal, setOpenDeleteAnswerModal] = useState({ active: false, test_result_id: null, hidden_result: false });
    const [openReportAnswerModal, setOpenReportAnswerModal] = useState({ active: false, answer_id: null });

    const [answerArray, setAnswerArray] = useState([]);
    const [aggregrateArray, setAggregrateArray] = useState([]);
    const [metaData, setMetaData] = useState({ questionType: "", totalResultCount: 0, userType: "", aggregate_total_voters: 0, sort_time: "", sort_date: "", sort_vote: "", load_more: "", test_question_id: "", page: 1 });
    const [search, setSearch] = useState("");
    const [searchLoading, setSearchLoading] = useState("");
    const [chartData, setChartData] = useState({});
    const [rankingchartData, setRankingChartData] = useState({});
    const [matrixArray, setMatrixArray] = useState([]);
    const [matrixheight, setMatrixHeight] = useState(600);
    const [sortedchartData, setSortedChartData] = useState([]);
    const [rankingTableData, setRankingTableData] = useState([]);
    const [searchByUser, setSearchByUser] = useState("");
    const [rating, setRating] = useState([]);
    const [searchAggregateData, setSearchAggregateData] = useState("");
    const [likertQuestion, setLikertQuestion] = useState({});
    const [predictions, setPredictions] = useState({"average":null, csat:null, nps:null});


    const [sortLoading, setSortLoading] = useState(false);

    // const [resultCount, SetResultCount] = useState(0);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [activeMenu, setActiveMenu] = useState("chart");

    let searchController = useRef();

    const jumptoSection = (area, questiontype) => {
        setActiveMenu(area);
        document.getElementById(area + "-" + questiontype).scrollIntoView({
            behavior: "smooth",
            block: "center",
        });

    }

    const searchByUserData = (searchByUser) => {
        // setMetaData({...metaData,"search":search})
        setSearchByUser(searchByUser);
        setSearchLoading(true);
        //setTimeout(function () {
        if (searchController.current) {
            searchController.current.abort();

        }
        const controller = new AbortController();

        searchController.current = controller;

        let guest_result_token = '';
        if(location.pathname.substr(1, 1) === 'v'){

            guest_result_token = ReactSession.get("guest_result_token");
        }



        let token = ReactSession.get("token");
        loadMoreTreeTestQuestionResultData({
            test_id: test_id,
            test_question_id: metaData.test_question_id,
            page: 1,
            type: metaData.questionType,
            search: searchByUser,
            questiontype: "TreeTest",
            guest_result_token:guest_result_token
        }, token, searchController.current?.signal).then((response) => {
            if (response.success) {
                setSearchLoading(false);
                setAnswerArray(response.data);
                setMetaData({ ...metaData, page: 1, load_more: response.more_records });

            } else {
                setSearchLoading(false);
                showToast(response.message, "error");
            }
        });
    }
    const searchByRating = (rating) => {
        // setMetaData({...metaData,"search":search})
        //setSearchByUser(searchByUser);
        setSearchLoading(true);
        setRating(rating);
        //setTimeout(function () {
        if (searchController.current) {
            searchController.current.abort();

        }
        const controller = new AbortController();

        searchController.current = controller;

        let guest_result_token = '';
        if(location.pathname.substr(1, 1) === 'v'){

            guest_result_token = ReactSession.get("guest_result_token");
        }

        let token = ReactSession.get("token");
        loadMoreTreeTestQuestionResultData({
            test_id: test_id,
            test_question_id: metaData.test_question_id,
            page: 1,
            type: metaData.questionType,
            rating: rating,
            questiontype: "TreeTest",
            guest_result_token:guest_result_token
        }, token, searchController.current?.signal).then((response) => {
            if (response.success) {
                setSearchLoading(false);
                setAnswerArray(response.data);
                setMetaData({ ...metaData, page: 1, load_more: response.more_records });

            } else {
                setSearchLoading(false);
                showToast(response.message, "error");
            }
        });
    }

    const searchByAggregateData = (searchAggregateData) => {
        setSearchAggregateData(searchAggregateData);
        const dataAnswer = questionArray.filter((item, indexItem) => indexItem + 1 === isActive)[0];
        if (dataAnswer) {
            const newAggregrateArray = dataAnswer.aggregate.filter(items => items.option.toLowerCase().indexOf(searchAggregateData.toLowerCase()) > -1);
            setAggregrateArray(newAggregrateArray)
        }
    }
    const handleScroll = () => {
        // Check the scroll position or any other logic you need
        const scrolled = scrollContainerRef.current.scrollTop > 0;

        // Update state based on the scroll position
        setIsScrolled(scrolled);
    };
    useEffect(() => {
        // Attach scroll event listener to the referenced element
        const scrollContainer = scrollContainerRef.current;

        if (scrollContainer) {
            scrollContainer.addEventListener('scroll', handleScroll);

            // Remove the event listener when the component unmounts
            return () => {
                scrollContainer.removeEventListener('scroll', handleScroll);
            };
        }
    }, [rankingTableData]);
    useEffect(() => {
        //document.title = process.env.REACT_APP_NAME + " - first click";
        changeActiveQuestion(1, resultData);
        setQuestionArray(resultData);

    }, [resultData]);

    const changeActiveQuestion = (index, data) => {
        setActive(index)
        setDropdownQuestions(false); // hide dropdown value
        setSearch(""); // hide dropdown value
        setSearchByUser("");
        setRating([]);
        // filter data from array
        const dataAnswer = data && data.filter((item, indexItem) => indexItem + 1 === index)[0];

        if (dataAnswer) {

            setAnswerArray(dataAnswer.result);  // set all test result data list
            setAggregrateArray(dataAnswer.aggregate);
            if (dataAnswer.question_type == "multiplechoice") {
                setChartData(dataAnswer.chartDataMultipleChoice);
            }
            else {
                setChartData(dataAnswer.chartData);
            }
            if (dataAnswer.question_type == "rankingscale") {
                setRankingChartData(dataAnswer.rankingchartData);
                setRankingTableData([dataAnswer.rankingtableData]);
            }
            if (dataAnswer.question_type == "likertscale") {
                let matrixData = [];

                dataAnswer.matrix.forEach((items) => {

                    var totalheat = 0;
                    dataAnswer.matrix.forEach((entry) => {
                        if (items.y == entry.y) {
                            totalheat = totalheat + entry.heat
                        }
                    })
                    var fill = '';
                    if ((Math.round((items.heat * 100 / totalheat) * 100) / 100) >= 80) {
                        fill = '#5C208D';
                    }
                    else if ((Math.round((items.heat * 100 / totalheat) * 100) / 100) >= 60 && (Math.round((items.heat * 100 / totalheat) * 100) / 100) < 80) {
                        fill = '#793EAB';
                    }
                    else if ((Math.round((items.heat * 100 / totalheat) * 100) / 100) >= 40 && (Math.round((items.heat * 100 / totalheat) * 100) / 100) < 60) {
                        fill = '#9759CB';
                    }
                    else if ((Math.round((items.heat * 100 / totalheat) * 100) / 100) >= 20 && (Math.round((items.heat * 100 / totalheat) * 100) / 100) < 40) {
                        fill = '#BF8EE8';
                    }
                    else if ((Math.round((items.heat * 100 / totalheat) * 100) / 100) > 0 && (Math.round((items.heat * 100 / totalheat) * 100) / 100) < 20) {
                        fill = '#EFDCFF';
                    }
                    else {
                        fill = '#E9E9E9';
                    }


                    matrixData.push({
                        x: items.x,
                        // x: items.x.length > 15 ? (test.language == "ar" ? "..." + items.x.substring(0, 14) : items.x.substring(0, 14) + "..") : items.x,
                        y: items.y,
                        // y: items.y.length > 20 ? (test.language == "ar" ? "..." + items.y.substring(0, 19) : items.y.substring(0, 19) + "...") : items.y,
                        // heat: (Math.round((items.heat * 100 / totalheat) * 100) / 100) + "%",
                        heat: items.heat,
                        fill: fill,
                        // participants:items.heat>1?items.heat+" Participants":items.heat+" Participant",
                        participants: (Math.round((items.heat * 100 / totalheat) * 100) / 100) + "% Participants",
                        percent_participants: (Math.round((items.heat * 100 / totalheat) * 100) / 100),
                    })
                })


                setMatrixArray(matrixData);
                setMatrixHeight(dataAnswer.subquestionscount * 30 + 50);
                setLikertQuestion(dataAnswer.questions);
            }
            if (dataAnswer.question_type == "ratingscale") {

                setMatrixArray(dataAnswer.matrix);
                setLikertQuestion(dataAnswer.questions);
                setPredictions({"average":dataAnswer.average, "nps":dataAnswer.nps, "csat":dataAnswer.csat});
            }
            let sortable = [];
            for (var option in dataAnswer.chartData) {
                sortable.push([option, dataAnswer.chartData[option]]);
            }

            sortable.sort(function (a, b) {
                return b[1] - a[1];
            });

            setSortedChartData(sortable);

            setMetaData({
                'anyOptionHaveImage': dataAnswer.any_option_have_image ? dataAnswer.any_option_have_image : false,
                'questionType': dataAnswer.question_type,
                'totalResultCount': dataAnswer.result_count,
                'userType': (dataAnswer.question_type === 'free') ? "by-user" : "chart",
                'aggregate_total_voters': dataAnswer.aggregate_total_voters,
                sort_result_id: "asc",
                sort_time: "",
                sort_date: "",
                sort_vote: "",
                load_more: (dataAnswer.result_count > 10) ? true : false,
                test_question_id: dataAnswer.id,
                page: 1
            });
        }

    }

    const sortData = (type) => {
        if (type === 'time') {
            if (metaData.sort_time === 'asc') {
                // setAnswerArray([]);
                sortDataApi('time', 'desc');
            } else {
                // setAnswerArray([]);
                sortDataApi('time', 'asc');
            }
        } else if (type === 'date') {
            if (metaData.sort_date === 'asc') {
                // setAnswerArray([]);
                sortDataApi('date', 'desc');
            } else {
                // setAnswerArray([]);
                sortDataApi('date', 'asc');
            }
        } else if (type === 'vote') {
            if (metaData.sort_vote === 'asc') {
                setAggregrateArray(aggregrateArray.sort((a, b) => (a.vote < b.vote) ? 1 : -1));
                setMetaData({ ...metaData, sort_vote: "desc" });
            } else {
                setAggregrateArray(aggregrateArray.sort((a, b) => (a.vote > b.vote) ? 1 : -1));
                setMetaData({ ...metaData, sort_vote: "asc" });
            }
        }
        else if (type === 'result_id') {
            if (metaData.sort_result_id === 'asc') {
                // setAnswerArray([]);
                sortDataApi('result_id', 'desc');
            } else {
                // setAnswerArray([]);
                sortDataApi('result_id', 'asc');
            }
        }


    }

    const sortDataApi = (sortColumn, orderBy) => {

        // setMetaData({ ...metaData, load_more: true });
        // setLoadMoreLoading(true);
        setSortLoading(true);
        let token = ReactSession.get("token");

        let guest_result_token = '';
        if(location.pathname.substr(1, 1) === 'v'){

            guest_result_token = ReactSession.get("guest_result_token");
        }
        loadMoreTreeTestQuestionResultData({
            test_id: test_id,
            test_question_id: metaData.test_question_id,
            page: 1,
            type: metaData.questionType,
            search: metaData.questionType == 'free'?search:searchByUser,
            sort_column: sortColumn,
            order_by: orderBy,
            questiontype: "TreeTest",
            guest_result_token:guest_result_token
        }, token).then((response) => {
            // setLoadMoreLoading(false);
            setSortLoading(false);

            if (response.success) {
                setAnswerArray(response.data);

                if (sortColumn === 'time') {
                    if (orderBy === 'desc') {
                        setMetaData({ ...metaData, sort_result_id:"", sort_time: "desc", sort_date: "", page: 1, load_more: response.more_records });
                    } else {
                        setMetaData({ ...metaData, sort_result_id:"", sort_time: "asc", sort_date: "", page: 1, load_more: response.more_records });
                    }
                }
                else if (sortColumn === 'result_id') {
                    if (orderBy === 'desc') {
                        setMetaData({ ...metaData,  sort_result_id:"desc", sort_time: "", sort_date: "", page: 1, load_more: response.more_records });
                    } else {
                        setMetaData({ ...metaData, sort_result_id:"asc", sort_time: "", sort_date: "", page: 1, load_more: response.more_records });
                    }
                }
                else {
                    if (orderBy === 'desc') {
                        setMetaData({ ...metaData, sort_result_id:"", sort_time: "", sort_date: "desc", page: 1, load_more: response.more_records });
                    } else {
                        setMetaData({ ...metaData, sort_result_id:"", sort_time: "", sort_date: "asc", page: 1, load_more: response.more_records });
                    }
                }

            } else {
                toast(
                    <div className="toastinner">
                        {response.message}
                        <a onClick={() => {
                            toast.dismiss();
                        }}>&times;</a>
                    </div>,
                    {
                        className: (response.success) ? "successtoast" : "errortoast",
                        position: "bottom-center",
                        duration: 2000,
                    }
                );
            }
        });
    }

    const loadMore = () => {

        setLoadMoreLoading(true);
        let token = ReactSession.get("token");

        let guest_result_token = '';
        if(location.pathname.substr(1, 1) === 'v'){

            guest_result_token = ReactSession.get("guest_result_token");
        }

        loadMoreTreeTestQuestionResultData({
            test_id: test_id,
            test_question_id: metaData.test_question_id,
            page: metaData.page + 1,
            type: metaData.questionType,
            search: metaData.questionType == 'free'?search:searchByUser,
            questiontype: "TreeTest",
            guest_result_token:guest_result_token
        }, token).then((response) => {
            setLoadMoreLoading(false);

            if (response.success) {
                setAnswerArray(answerArray.concat(response.data));
                setMetaData({ ...metaData, page: metaData.page + 1, load_more: response.more_records });

            } else {
                toast(
                    <div className="toastinner">
                        {response.message}
                        <a onClick={() => {
                            toast.dismiss();
                        }}>&times;</a>
                    </div>,
                    {
                        className: (response.success) ? "successtoast" : "errortoast",
                        position: "bottom-center",
                        duration: 2000,
                    }
                );
            }
        });
    }

    // delete modal popup functionlity
    const hideParticipantModal = (id, hiddenResult) => {
        setOpenDeleteAnswerModal({ active: true, test_result_id: id, hidden_result: hiddenResult });
    }

    const closeDeleteAnswerModal = (data) => {

        // if successfully delete then delete object of array from answerArray
        if (data) {

            const getAnswerArray = answerArray;
            for (var i = 0; i < getAnswerArray.length; i++) {
                if (openDeleteAnswerModal.test_result_id === getAnswerArray[i].test_result_id) {

                    if (getAnswerArray[i].result.is_hidden === 1) {
                        getAnswerArray[i].result.is_hidden = 0;
                    } else {
                        getAnswerArray[i].result.is_hidden = 1;
                    }
                }
            }
        }
        setOpenDeleteAnswerModal({ active: false, test_result_id: null, hidden_result: false })

    }

    const reportAnswerModal = (id) => {
        setOpenReportAnswerModal({ active: true, answer_id: id });
    }
    const closeReportAnswerModal = (data) => {
        setOpenReportAnswerModal({ active: false, answer_id: null });
    }

    const searchData = (search) => {
        setSearch(search);
        // if search record from aggregated data
        if (metaData.userType === 'aggregated') {
            const dataAnswer = questionArray.filter((item, indexItem) => indexItem + 1 === isActive)[0];
            if (dataAnswer) {
                const newAggregrateArray = dataAnswer.aggregate.filter(items => items.option.toLowerCase().indexOf(search.toLowerCase()) > -1);
                setAggregrateArray(newAggregrateArray)
            }
        } else {
            setSearchLoading(true);
            let token = ReactSession.get("token");

            let guest_result_token = '';
            if(location.pathname.substr(1, 1) === 'v'){

                guest_result_token = ReactSession.get("guest_result_token");
            }

            loadMoreTreeTestQuestionResultData({
                test_id: test_id,
                test_question_id: metaData.test_question_id,
                page: 1,
                type: metaData.questionType,
                search: search,
                questiontype: "TreeTest",
                guest_result_token:guest_result_token
            }, token).then((response) => {
                setSearchLoading(false);

                if (response.success) {
                    setAnswerArray(response.data);
                    setMetaData({ ...metaData, page: 1, load_more: response.more_records });

                } else {
                    toast(
                        <div className="toastinner">
                            {response.message}
                            <a onClick={() => {
                                toast.dismiss();
                            }}>&times;</a>
                        </div>,
                        {
                            className: (response.success) ? "successtoast" : "errortoast",
                            position: "bottom-center",
                            duration: 2000,
                        }
                    );
                }
            });
        }
    }

    const renderRankingQuestionTable = () => {

        var colors = [
            { color: "#FF6DB1", text: "" },
            { color: "#9759CB", text: "" },
            { color: "#F2C690", text: "" },
            { color: "#698CE7", text: "" },
            { color: "#31F0E5", text: "" },
            { color: "#378B10", text: "" },
            { color: "#E32F83", text: "" },
            { color: "#793EAB", text: "" },
            { color: "#D7A56A", text: "" },
            { color: "#3668E7", text: "" },
            { color: "#11BFB4", text: "" },
            { color: "#164103", text: "" },
            { color: "#FF8AC1", text: "" },
            { color: "#AC7AD5", text: "" },
            { color: "#FFE2C0", text: "" },
            { color: "#9EB4ED", text: "" },
            { color: "#90F2EC", text: "" },
            { color: "#BFF0A8", text: "" },
            { color: "#A72561", text: "" },
            { color: "#5C208D", text: "" },
            { color: "#C38D4D", text: "" },
            { color: "#0D3BB0", text: "" },
            { color: "#0A877F", text: "" },
            { color: "#A72561", text: "" },
            { color: "#5C208D", text: "" },
            { color: "#C38D4D", text: "" },
            { color: "#06236C", text: "" },
            { color: "#045A54", text: "" },
        ];

        var tableData = [];
        for (var i in rankingTableData[0])
            tableData.push([i, rankingTableData[0][i]]);
        tableData.sort(compare);

        return (
            <div ref={scrollContainerRef} className={`ranking-table-holder max-height-table  ${isScrolled ? 'scrolled' : ''} ${tableData && tableData.length>8 ? "responsive-table-scroll":""}`}>
                <table className="table prefer-aggregatd-data-table ranking-table">
                    <thead>
                    <tr>
                        <th>Answers</th>
                        {tableData.map(function (item, index) {
                            return (<th>{index + 1 == 1 ? "1st" : index + 1 == 2 ? "2nd" : index + 1 == 3 ? "3rd" : index + 1 + "th"}</th>)
                        })}
                        <th className="score-heading-table"><b>Score</b></th>
                    </tr>
                    </thead>
                    <tbody>
                    {tableData.map(function (item, index) {
                        return (
                            <tr className={`${index == 0 ? "ranking-table-first-row" : ""}`}>
                                <td>
                                    <div className='d-flex table-answer-column-outer'>
                                        <div style={{ backgroundColor: colors[index].color }} className='table-color-box'></div>
                                        <span className={`table-answer-column ${test.language == "ar" ? "arabic-font" : ""}`}>{item[0]}</span>
                                    </div>
                                </td>
                                {tableData.map(function (itemm, i) {
                                    return (
                                        <td>
                                            <div className='d-flex table-answer-column-votes'>
                                                <span className='table-votes'>{item[1]["votes"][i + 1]}</span>
                                                <span className='table-percentage'> ({Math.round((item[1]["votes"][i + 1] * 100 / metaData.totalResultCount) * 100) / 100}%)</span>
                                            </div>

                                        </td>
                                    )
                                })}
                                <td>
                                    {Math.round(item[1]["averageranking"] * 100) / 100}
                                </td>
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
            </div>
        )
    }

    return (

        <div className="prototype-test-questions tree-test-question">
            <div className="">
                {!sectionLoading && questionArray && questionArray.length > 0 && resultCount > 0 &&
                <div className="bd-question-view-wrap">
                    {metaData.totalResultCount >= 1 &&
                    <div className="question-result-header question-result-data" >
                        <QuestionsDropdown questionArray={questionArray} changeActiveQuestionProp={(index) => changeActiveQuestion(index, questionArray)} dropdownOpen={(state) => setDropdownOpen(state)} resultCount={metaData.totalResultCount} test={test} active={isActive}/>

                        {(metaData.questionType == 'singlechoice' || metaData.questionType == 'multiplechoice' || metaData.questionType == 'likertscale'|| metaData.questionType == 'ratingscale') ?
                            <div className="accountnaviagtion-outer result-navigation-outer question-navigator-active-link">
                                        <span className={`${activeMenu == 'chart' ? "active" : ''}`} onClick={() => { jumptoSection("chart", metaData.questionType) }}>
                                            <a data-href={`#chart-${metaData.questionType}`}>Chart</a>
                                        </span>
                                {metaData.questionType != 'ratingscale' &&
                                <span className={`${activeMenu == 'aggregate-data' ? "active" : ''}`} onClick={() => { jumptoSection("aggregate-data", metaData.questionType) }}>
                                            <a data-href={`#aggregate-data-${metaData.questionType}`}>Aggregated data</a>
                                        </span>
                                }
                                <span className={`${activeMenu == 'byuser' ? "active" : ''}`} onClick={() => { jumptoSection("byuser", metaData.questionType) }}>
                                            <a data-href={`#byuser-${metaData.questionType}`}>By user</a>
                                        </span>
                            </div>
                            : <></>}
                    </div>
                    }

                    {metaData.totalResultCount < 1 ?
                        <div className={`no-participants-data no-questions-data ${dropdownOpen ? "black-overlay" : ""}`}>
                            <div className="no-page-data-wrap" style={{ marginTop: "80px" }}>
                                <div className="no-data-icon">
                                    <Icon colorClass={"gray-700-svg"} value={"attention"} size={"large"} />
                                </div>

                                <Text type={"subtitle-1"} fontWeight="medium-font">Details are not available at the moment as this question didn't receive any response yet. Please come back later.</Text>
                            </div>
                        </div>
                        :
                        <div className={`question-result-data  ${dropdownOpen ? "black-overlay" : ""}`}>


                            {metaData.questionType === 'free' &&
                            <div className='free-question-result-data test-result-inner-data'>
                                <div className='free-question-table-header'>
                                    <div className='free-question-table-header-left'>
                                        <Text type="subtitle-1" fontWeight="medium-font">Free text</Text>
                                    </div>
                                    <div className='free-question-table-header-right'>
                                        <Search
                                            size={"small"}
                                            placeholder="Search in answers"
                                            onChange={(e) => searchData(e.target.value)}
                                            onClear={() => {
                                                setTimeout(() => {
                                                    setSearch("");
                                                    searchData("")
                                                }, 1000)
                                            }}
                                            loading={searchLoading}
                                            value={search}
                                        />
                                    </div>
                                </div>
                                <ByUserTable metaData={metaData} answerArray={answerArray} sortData={sortData} hideParticipantModal={hideParticipantModal} reportAnswerModal={reportAnswerModal} search={search} test={test} loadMore={loadMore} loadMoreLoading={loadMoreLoading} clearSearch={()=>{setSearch("");
                                    searchData("")}}/>
                            </div>
                            }
                            {metaData.questionType === 'singlechoice' &&
                            <div className='free-question-result-data test-result-inner-data test-class'>
                                <div className='free-question-table-header' id="chart-singlechoice">
                                    <div className='free-question-table-header-left'>
                                        <Text type="h4" fontWeight="bold-font">Chart</Text>
                                    </div>
                                </div>
                                <div className='chart-data'>
                                    <PieStatsBoxSingleChoice data={chartData} sortedchartData={sortedchartData} title={<span>{metaData.totalResultCount}
                                        {metaData.totalResultCount == 1 ? ' Answer' : ' Answers'}
                                            </span>} test={test} totalAnswers={metaData.totalResultCount} />
                                </div>
                                <div className='aggregated-data-table' id="aggregate-data-singlechoice">
                                    <div className='free-question-table-header'>
                                        <div className='free-question-table-header-left'>
                                            <Text type="h4" fontWeight="bold-font">Aggregated data</Text>
                                        </div>
                                        <div className='free-question-table-header-right'>
                                            <Search
                                                size={"small"}
                                                placeholder="Search in answers"
                                                onChange={(e) => searchByAggregateData(e.target.value)}
                                                onClear={() => {
                                                    setTimeout(() => {
                                                        setSearchAggregateData("");
                                                        searchByAggregateData("")
                                                    }, 0)
                                                }}
                                                loading={false}
                                                value={searchAggregateData}
                                            />
                                        </div>
                                    </div>
                                    <AggregatedDataTable metaData={metaData} aggregrateArray={aggregrateArray} sortData={sortData} search={searchAggregateData} test={test} clearSearch={()=>{setSearchAggregateData("");
                                        searchByAggregateData("")}}/>
                                </div>
                                <div className='by-user-table' >
                                    <div className='free-question-table-header'>
                                        <div className='free-question-table-header-left'>
                                            <Text type="h4" fontWeight="bold-font">By user</Text>
                                        </div>
                                        <div className='free-question-table-header-right'>
                                            <Search
                                                size={"small"}
                                                placeholder="Search in answers"
                                                onChange={(e) => searchByUserData(e.target.value)}
                                                onClear={() => {
                                                    setTimeout(() => {
                                                        setSearchByUser("");
                                                        searchByUserData("")
                                                    }, 0)
                                                }}
                                                loading={searchLoading}
                                                value={searchByUser}
                                            />
                                        </div>
                                    </div>
                                    <div id="byuser-singlechoice"></div>
                                    <ByUserTable metaData={metaData} answerArray={answerArray} sortData={sortData} hideParticipantModal={hideParticipantModal} reportAnswerModal={reportAnswerModal} search={searchByUser} test={test} loadMore={loadMore} loadMoreLoading={loadMoreLoading} clearSearch={()=>{setSearchByUser("");
                                        searchByUserData("")}}/>
                                </div>
                            </div>
                            }
                            {metaData.questionType === 'multiplechoice' &&
                            <div className='free-question-result-data test-result-inner-data'>
                                <div className='free-question-table-header' id="chart-multiplechoice">
                                    <div className='free-question-table-header-left'>
                                        <Text type="h4" fontWeight="bold-font">Chart</Text>
                                    </div>
                                </div>
                                <div className='chart-data' >
                                    <div className="parti-ga-grapg-area">
                                        <HorizontalBarChartStatsBoxMultipleChoice resultCount={metaData.totalResultCount} test={test} title={<span>{metaData.totalResultCount}{metaData.totalResultCount == 1 ? ' Answer' : ' Answers'}</span>} data={chartData} />
                                    </div>
                                </div>
                                <div className='aggregated-data-table' id="aggregate-data-multiplechoice">
                                    <div className='free-question-table-header'>
                                        <div className='free-question-table-header-left'>
                                            <Text type="h4" fontWeight="bold-font">Aggregated data</Text>
                                        </div>
                                        <div className='free-question-table-header-right'>
                                            <Search
                                                size={"small"}
                                                placeholder="Search in answers"
                                                onChange={(e) => searchByAggregateData(e.target.value)}
                                                onClear={() => {
                                                    setTimeout(() => {
                                                        setSearchAggregateData("");
                                                        searchByAggregateData("")
                                                    }, 0)
                                                }}
                                                loading={false}
                                                value={searchAggregateData}
                                            />
                                        </div>
                                    </div>
                                    <AggregatedDataTable metaData={metaData} aggregrateArray={aggregrateArray} sortData={sortData} search={searchAggregateData} test={test} clearSearch={()=>{setSearchAggregateData("");
                                        searchByAggregateData("")}}/>
                                </div>
                                <div className='by-user-table'>
                                    <div className='free-question-table-header'>
                                        <div className='free-question-table-header-left'>
                                            <Text type="h4" fontWeight="bold-font">By user</Text>
                                        </div>
                                        <div className='free-question-table-header-right'>
                                            <Search
                                                size={"small"}
                                                placeholder="Search in answers"
                                                onChange={(e) => searchByUserData(e.target.value)}
                                                onClear={() => {
                                                    setTimeout(() => {
                                                        setSearchByUser("");
                                                        searchByUserData("")
                                                    }, 0)
                                                }}
                                                loading={searchLoading}
                                                value={searchByUser}
                                            />
                                        </div>
                                    </div>
                                    <div id="byuser-multiplechoice"></div>
                                    <ByUserTable metaData={metaData} answerArray={answerArray} sortData={sortData} hideParticipantModal={hideParticipantModal} reportAnswerModal={reportAnswerModal} search={searchByUser} test={test} loadMore={loadMore} loadMoreLoading={loadMoreLoading} clearSearch={()=>{setSearchByUser("");
                                        searchByUserData("")}}/>
                                </div>
                            </div>
                            }
                            {metaData.questionType === 'likertscale' &&
                            <div className='free-question-result-data test-result-inner-data'>
                                <div className='free-question-table-header' id="chart-likertscale">
                                    <div className='free-question-table-header-left'>
                                        <Text type="h4" fontWeight="bold-font">Chart</Text>
                                    </div>
                                </div>
                                <div className='chart-data'>
                                    {matrixArray.length > 0 && Object.keys(likertQuestion).length !== 0 &&
                                    <LikertScaleChart data={matrixArray} likertQuestion={likertQuestion} test={test} />
                                    }
                                </div>
                                <div className='aggregated-data-table' id="aggregate-data-likertscale">
                                    <div className='free-question-table-header'>
                                        <div className='free-question-table-header-left'>
                                            <Text type="h4" fontWeight="bold-font">Aggregated data</Text>
                                        </div>
                                    </div>
                                    <div className={`likert-area-chart ${test.language == "ar" ? "arabic-font" : ""}`}>
                                        {matrixArray.length > 0 && Object.keys(likertQuestion).length !== 0 &&
                                        <LikertScaleAggregateData data={matrixArray} likertQuestion={likertQuestion} />
                                        }
                                    </div>
                                </div>
                                <div className='by-user-table'>
                                    <div className='free-question-table-header'>
                                        <div className='free-question-table-header-left'>
                                            <Text type="h4" fontWeight="bold-font">By user</Text>
                                        </div>
                                    </div>
                                    <div id="byuser-likertscale"></div>
                                    {Object.keys(likertQuestion).length !== 0 &&
                                    <LikertScaleByUser likertQuestion={likertQuestion} metaData={metaData} test_id={test_id} test={test} answerArray={answerArray} hideParticipantModal={hideParticipantModal} reportAnswerModal={reportAnswerModal} loadMore={loadMore} loadMoreLoading={loadMoreLoading} />
                                    }
                                </div>
                            </div>
                            }
                            {metaData.questionType == 'rankingscale' &&
                            (<div className="test-result-inner-data parti-ga-grapg-area">
                                <div className="barchart-ranking-scale">
                                    <HorizontalBarChartStatsBoxRankingScale test={test} rankingchartData={rankingchartData} />
                                </div>
                                <div className="ranking-scale-table mt-40">
                                    {renderRankingQuestionTable()}
                                </div>
                                <div className="ranking-page-infodata-wrap">
                                    <h3>How to read this bar chart?</h3>
                                    <p>The bar chart is based on the score each ranked option has received. The scores represent the average ranking.</p>
                                    <h3>How the scores are calculated?</h3>
                                    <p>Think of the score as a weighted average. Suppose you have 5 options for testers to rank. The testers’ most preferred option (ranked as #1 most of the times) is given the largest weight (in this case 5). Their least preferred option has a weight of 1. The weights are then multiplied by the numbers of testers selected a particular option.
                                        Their sum is divided by the total number of testers.</p>
                                    <div className="response-text-wrap mathmaticbox">
                                        <p>X<sub>1</sub> W<sub>1</sub> + X<sub>2</sub> W<sub>2</sub>
                                            + X<sub>3</sub> W<sub>3</sub> + ... + X<sub>n</sub> W<sub>n</sub></p>
                                        <span>Total responses</span>
                                    </div>
                                    <div className="ranking-wrap-info-bottom">
                                        <strong>Where:</strong>
                                        <p>X - number of testers</p>
                                        <p>W - weight of the ranked option</p>
                                    </div>
                                </div>
                            </div>)
                            }
                            {metaData.questionType === 'ratingscale' &&

                            <div className='free-question-result-data test-result-inner-data'>
                                <div className='free-question-table-header' id="chart-ratingscale">
                                    <div className='free-question-table-header-left'>
                                        <Text type="h4" fontWeight="bold-font">Chart</Text>
                                    </div>
                                </div>
                                <div className='chart-data' >
                                    {matrixArray && Object.keys(likertQuestion).length !== 0 &&
                                    <>
                                        <RatingScaleChart
                                            test={test}
                                            data={matrixArray}
                                            question={{"type":likertQuestion.scale_type=="Emotions"?"emoticons":likertQuestion.scale_type,"scale":likertQuestion.scale_length.match(/\d+/)[0], labels: likertQuestion.labels}}
                                            predictions={predictions}
                                        />
                                    </>
                                    }

                                </div>

                                <div className='by-user-table'>
                                    <div className='free-question-table-header'>
                                        <div className='free-question-table-header-left'>
                                            <Text type="h4" fontWeight="bold-font">By user</Text>
                                        </div>
                                    </div>
                                    <div className='free-question-table-header'>
                                        <div className='free-question-table-header-left'>
                                            <Text cssClasses={"gray-color"} type="body-text-3" fontWeight="medium-font">{metaData.totalResultCount} answer{metaData.totalResultCount>1?'s':''}</Text>
                                        </div>

                                        <div className='free-question-table-header-right'>
                                            {Object.keys(likertQuestion).length !== 0 &&
                                            <RatingMultiSelect onChange={(rating)=>{

                                                searchByRating(rating);
                                            }}
                                                               value={rating}
                                                               type={
                                                                   function(){
                                                                       var scale = {"Stars":"stars","Emotions":"emoticons","Numeric":"numeric"};

                                                                       return scale[likertQuestion.scale_type];

                                                                   }()}
                                                               scale={likertQuestion.scale_length.match(/\d+/)[0]} />
                                            }
                                            {/*<Search
                                                label="Search"
                                                onChange={(e) => searchByUserData(e.target.value)}
                                                onClear={() => {
                                                    setTimeout(() => {
                                                        setSearchByUser("");
                                                        searchByUserData("")
                                                    }, 0)
                                                }}
                                                loading={searchLoading}
                                                value={searchByUser}
                                            />*/}
                                        </div>
                                    </div>
                                    <div id="byuser-ratingscale"></div>
                                    {Object.keys(likertQuestion).length !== 0 &&
                                    <ByUserTable
                                        metaData={metaData}
                                        answerArray={answerArray}
                                        sortData={sortData}
                                        hideParticipantModal={hideParticipantModal}
                                        reportAnswerModal={reportAnswerModal}
                                        search={search}
                                        test={test}
                                        loadMore={loadMore}
                                        loadMoreLoading={loadMoreLoading}
                                        clearSearch={()=>{
                                            setRating([]);
                                            searchByRating([]);
                                        }}
                                        question={{"type":likertQuestion.scale_type=="Emotions"?"emoticons":likertQuestion.scale_type,"scale":likertQuestion.scale_length.match(/\d+/)[0]}}
                                    />
                                    }
                                </div>
                            </div>
                            }
                            {/* <div className="bd-question-bottom-pagination-arrow prev-next-button-bottom">
                                                    <div className="bd-que-slide-arrow tablebottompagination">
                                                        <Button
                                                            type={"ghost"}
                                                            microLoading={false}
                                                            label={"Previous question"}
                                                            size={"medium"}
                                                            onClick={(e) => {
                                                                e.preventDefault(); if (isActive > 1) {
                                                                    changeActiveQuestion(isActive - 1, questionArray)
                                                                }
                                                            }}
                                                            iconLeft={
                                                                <Icon value={"backward-arrow"} size={"medium"} />
                                                            }
                                                            state={`${isActive === 1 ? 'disabled' : 'active'}`}
                                                        />
                                                        <Button
                                                            type={"ghost"}
                                                            label={"Next question"}
                                                            size={"medium"}
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                if (isActive < questionArray.length) { changeActiveQuestion(isActive + 1, questionArray) }
                                                            }}
                                                            iconRight={
                                                                <Icon value={"forward-arrow"} size={"medium"} />
                                                            }
                                                            state={`${isActive === questionArray.length ? 'disabled' : 'active'}`}
                                                        />

                                                    </div>
                                                </div> */}
                        </div>
                    }

                </div>
                }

                {!sectionLoading && questionArray && questionArray.length === 0 &&
                <div className={`no-participants-data no-questions-data ${dropdownOpen ? "black-overlay" : ""}`}>
                    <div className="no-page-data-wrap" style={{ marginTop: "80px" }}>
                        <div className="no-data-icon">
                            <Icon colorClass={"gray-700-svg"} value={"attention"} size={"large"} />
                        </div>

                        <Text type={"subtitle-1"} fontWeight="medium-font">You have not added any follow up questions to this task.</Text>
                    </div>
                </div>
                }
                {!sectionLoading && resultCount === 0 && (!(!sectionLoading && questionArray && questionArray.length === 0)) &&
                <div className={`no-participants-data no-questions-data ${dropdownOpen ? "black-overlay" : ""}`}>
                    <div className="no-page-data-wrap" style={{ marginTop: "80px" }}>
                        <div className="no-data-icon">
                            <Icon colorClass={"gray-700-svg"} value={"attention"} size={"large"} />
                        </div>

                        <Text type={"subtitle-1"} fontWeight="medium-font">Details are not available at the moment as the question didn’t receive any response yet. Please come back later.</Text>
                    </div>
                </div>
                }
            </div>
            <HideParticipant
                test_id={test_id}
                openModal={openDeleteAnswerModal}
                closeModal={closeDeleteAnswerModal} />
            <ReportAnswer
                test_id={test_id}
                openModal={openReportAnswerModal}
                closeModal={closeReportAnswerModal} />
        </div>
    )
}