import React from 'react';
import { Navigate } from "react-router-dom";
//import { ReactSession } from 'react-client-session';
import { ReactSession } from '../../lib/secure_reactsession';
import Cookies from 'js-cookie';

import { logoutService } from "../../services/user";

export default function GuestRoute(props){
    ReactSession.setStoreType("localStorage");
    let ptoken = ReactSession.get("token");
    let user = ReactSession.get("user");

    var expires_at = Cookies.get("expires_at");

    var current_date = new Date();

    var current_time = current_date.getTime();


    if(!expires_at || current_time > parseInt(expires_at)){

        let token = ReactSession.get("token");

        if(token){
            logoutService(token).then((response) => {
                if (response.success) {
                }
            });
        }
        Cookies.remove('expires_at', { path: '' }) 
        
        ReactSession.set("token", null);
        ReactSession.set("user", null);
        ReactSession.set("plan", null);
        ReactSession.set("show_subscribed", null);
        ReactSession.set("username", null);

        if(user && user.role=="Researcher"){
            return (<Navigate to="/researcher/sign-in" />);
        }
        else if(user && user.role=="Tester"){
            return (<Navigate to="/tester/sign-in" />);
        } else {
            if(props.pathFor && props.pathFor=="tester"){
                if(props.path){
                    return (<Navigate to={"/tester/sign-in?redirect_to="+props.path} />);        
                }
                return (<Navigate to={"/tester/sign-in"} />);    
            }
            return (<Navigate to="/researcher/sign-in" />);
        }
    }
    else if(user && ptoken!="" && ptoken!="null" && ptoken!=null){
        
        return props.element;
        
    } else {
        
        if(props.pathFor && props.pathFor=="tester"){
            if(props.path){
                return (<Navigate to={"/tester/sign-in?redirect_to="+props.path} />);        
            }
            return (<Navigate to={"/tester/sign-in"} />);    
        }
        return (<Navigate to="/" />);
        
    }
    
}