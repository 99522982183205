import React, { useCallback } from 'react';
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { ReactSession } from "../../../lib/secure_reactsession";
import LayoutResearcherFullWidth from "../../layouts/layout_researcher_full_width";
import { LayoutResearcher } from '../../themes/userq/Layouts/layout_researcher';
import { useState, useEffect, useRef } from "react";
import {
    getResearcherTestResultData,
    getTestCardSortingSimilarityMatrixResultData
} from "../../../services/researcher_test_results";
import { atom, useRecoilState } from 'recoil';

import { LoadingIcon } from "../../loader/loadingIcon";
import { Dropdown } from "react-bootstrap";
import exportAsImage from "../../../lib/exportAsImage";
import { downloadBlob, showToast } from "../../../lib/helpers";
import { Icon } from "../../themes/userq/Icon/Icon";
import { Footer } from '../../themes/userq/Footer/Footer';
import {Tooltip} from "../../themes/userq/Tooltip/Tooltip";
import { Helmet } from 'react-helmet';

export default function TestResultCardSortingSimilarity({isSharedLink}) {

    let { test_id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    const exportSimilarityRef = useRef();
    const exportSimilaritySvgRef = useRef();

    const ref = useRef();
    const tipref = useRef();


    const testState = atom({
        key: 'testresult-' + test_id,
        default: ""
    });
    const planState = atom({
        key: 'testresultplan-' + test_id,
        default: ""
    });

    const [isLoading, setLoading] = useState(false);
    const [sectionLoading, setSectionLoading] = useState(false);
    const [dataLoading, setDataLoading] = useState(false);
    const [test, setTest] = useRecoilState(testState);
    const [planType, setPlanType] = useRecoilState(planState);

    const [categoryArray, setCategoryArray] = useState([]);
    const [cardArray, setCardArray] = useState([]);
    const [matrixArray, setMatrixArray] = useState([]);
    const [similarityMatrixArray, setSimilarityMatrixArray] = useState([]);
    const [metaData, setMetaData] = useState({ active: 'matrix', 'sorting_type': 'open', cardSort: "", categorySort: "" });
    const [expandData, setExpandData] = useState(false);
    const [expandSimilarityData, setExpandSimilarityData] = useState(false);

    const [tooltip, showTooltip] = useState({
        tooltipLeft:0,
        tooltipTop:0,
        tooltipData:"",
        open: false,
        show:false
    });

    const handlePointerMove = (cardy,cardx,group_cards_percent,group_cards) => {


        return (
            (event) => {


                let tipPosition = "left";

                var tool_width = tipref && tipref.current ?  tipref.current.clientWidth : 260;


                var tool_height =  tipref && tipref.current ?  tipref.current.clientHeight + 12 : 800;




                let containerX = ('clientX' in event ? event.clientX : 0) - ref.current.getBoundingClientRect().left - tool_width/2;
                let containerY = ('clientY' in event ? event.clientY : 0)  - ref.current.getBoundingClientRect().top - tool_height - 10 ;


                var window_width = window.innerWidth;

                var window_height = window.innerHeight;

                if(event.clientX + tool_width/2 > window_width){
                    containerX = ('clientX' in event ? event.clientX : 0) - ref.current.getBoundingClientRect().left - tool_width/2 - (event.clientX + tool_width/2 - window_width) ;

                }
                if(event.clientX - tool_width/2 < 0){
                    containerX = ('clientX' in event ? event.clientX : 0) - ref.current.getBoundingClientRect().left - tool_width/2 + (tool_width/2 - event.clientX) ;
                }

                // Get the target
                //const target = e.target;

                // Get the bounding rectangle of target
                //const rect = target.getBoundingClientRect();

                // Mouse position
                const x = event.clientX - ref.current.getBoundingClientRect().left;
                const y = event.clientY - ref.current.getBoundingClientRect().top - tool_height/2 ;
                {/*        <p>*/}
                {/*            <b>{data.cardy} </b>and*/}
                {/*            <b> {data.cardx} </b>*/}
                {/*        </p>*/}
                {/*        {data.group_cards_percent}%*/}
                {/*        of participants*/}
                {/*        ({data.group_cards} times)*/}

                // console.log(x+5)
                // console.log(y)
                showTooltip({
                    tooltipLeft: x+25,
                    tooltipTop: y,
                    tooltipData:<><p><b>{cardy}</b> and <b>{cardx}</b></p> {group_cards_percent}% of participants ({group_cards} times)</>,
                    open:true,
                    show: tooltip.open && tipref && tipref.current ? true: false,
                    tipPostion: tipPosition
                });
            });
    }

    const getSimilarityMatrix = () => {

        let token = ReactSession.get("token");

        setDataLoading(true);

        let guest_result_token = '';
        if(location.pathname.substr(1, 1) === 'v'){

            guest_result_token = ReactSession.get("guest_result_token");
        }


        getTestCardSortingSimilarityMatrixResultData({ test_id: test_id,guest_result_token:guest_result_token }, token).then((response) => {
            setDataLoading(false);

            console.log(response.category)
            if (response) {

                setSimilarityMatrixArray(response.category);
            }


        });
    }

    const getTest = () => {

        if (!test) {
            let token = ReactSession.get("token");

            setLoading(true);

            let guest_result_token = '';
            if(location.pathname.substr(1, 1) === 'v'){

                // create guest_result_token
                if (!ReactSession.get("guest_result_token")) {
                    const randomToken = Math.random().toString(36).substr(2, 15);
                    ReactSession.set("guest_result_token", randomToken);
                }

                guest_result_token = ReactSession.get("guest_result_token");
            }


            getResearcherTestResultData({ test_id: test_id,guest_result_token:guest_result_token }, token).then((response) => {
                setLoading(false);

                if (response.success) {

                    // check if guest user not validate there password
                    if(location.pathname.substr(1, 1) === 'v' && response.test.password && !response.test_password_verify){

                        navigate(`/v/results/${test_id}/password`);
                    }

                    setTest(response.test);
                    getSimilarityMatrix();


                } else {
                    navigate("/dashboard");

                    showToast(response.message, "error");
                }
            });
        } else {
            getSimilarityMatrix();
        }
    };








    useEffect(() => {
        //document.title = process.env.REACT_APP_NAME + " - card sorting";
        getTest();


    }, []);



    const callbackTopbar = (data) => {
        getTest();
    }


    var chart1_settings = {
        id: "Aerachart1",
        width: "100%",
        background: 'transparent',
        height: 600,
        type: 'heatMap',
        data: matrixArray,
        colors: {
            darken: "#ff0000"
        },
        darken: {
            fill: "#ff0000"
        },
        hovered: {
            fill: "#FF66AD"
        },

        tooltip: {
            enabled: true,
            // background: "#defcfc",
            background: {
                fill: "#defcfc",
                corners: 17
            },
            fontColor: "#171637",
            fontSize: "14px",
            fontFamily: "sans-serif",
            // fontWeight: 100,
            title: false,
            separator: false,
            padding: "20px 40px",
            textAlign: "center",
            format: "{%participants}",
            // anchor:"left-center",
            offsetX: 30,
            offsetY: -30
        },
        xAxis: {
            // staggerMode:true,
            labels: {
                // rotation: -40,
                fontFamily: "sans-serif",
                fontSize: "12px",
                rotation: -45
            }
        },
        yAxis: {
            labels: {
                fontFamily: "sans-serif"
            }
        }

        /*label: {
            text: '',
            width: "100%",
            height: "100%",
            fontSize: '45px',
            fontColor: "#fff",
            hAlign: 'center',
            vAlign: 'middle'
        },
        title: {
            text: '',
            fontColor: "#fff",
            fontWeight: 'bold'
        }*/
    };

    const activeMatrixClass = (x, y) => {
        //document.getElementById(x).classList.add('active');
        //document.getElementById(y).classList.add('active');
        //document.getElementById(x + '-' + y).style.display = "block";
    }

    const inactiveMatrixClass = (x, y) => {

        //document.getElementById(x).classList.remove('active');
        //document.getElementById(y).classList.remove('active');

        //document.getElementById(x + '-' + y).style.display = "none";
    }

    const downloadSVG = useCallback(() => {
        //  const svg = exportSimilarityRef.current.getElementsByTagName('div')[0].innerHTML;

        var svg = '<svg><foreignObject><body xmlns="http://www.w3.org/1999/xhtml">';
        svg += exportSimilaritySvgRef.current.getElementsByTagName('div')[0].innerHTML;
        svg += '</body></foreignObject></svg>';

        const blob = new Blob([svg], { type: "image/svg+xml" });

        downloadBlob(blob, `similaritymatrix.svg`);
    }, []);




    return (
        <>
            <Helmet>
                <title>Similarity matrix - Analyse card sorting  | UserQ</title>
            </Helmet>
            {isLoading &&
                <div className="page-loader full-page-loader mh-100vh">
                    <LoadingIcon />
                </div>
            }
            {!isLoading && test &&
                <div className="chart-new-tab-data">



                    <div className={`container matrix-full-view hide-for-mobile`}>




                        {/* {sectionLoading &&
                            <div className={'page-loader accountloading-box result-sections-loader'}>
                                <LoadingIcon />
                            </div>
                        } */}

                        {!sectionLoading &&
                            <div className="matricx-chart-data-hold">
                                <div className='matricx-chart-top-head d-flex justify-content-between mb-60'>
                                    <div className='matricx-top-left'>


                                        <span className='subtitle-1 medium-font'>Similarity matrix</span>
                                        <p className='body-text body-text-1 medium-font'>Similarity matrix shows the pair combinations, intented to give a quick insight into the cards participants paired together in the same group the most often.</p>




                                    </div>

                                    <div className='matricx-top-right'>

                                        <span className="small-dropdown hamburger-menu">
                                            <Dropdown class={"dropdown"}>
                                                <Dropdown.Toggle id={"dropdownMenuButton"} className="dropdown-toggle secondary-btn secondary-medium  btn-with-icon btn-with-icon-left">
                                                    <Icon size={'small'} value={'download'} />
                                                    Download
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu className='hamburger-items'>
                                                    <Dropdown.Item href="#"
                                                        onClick={() => exportAsImage(exportSimilarityRef.current, "similarityMatrix")}>
                                                        Download PNG

                                                    </Dropdown.Item>
                                                    <Dropdown.Item href="#" onClick={() => downloadSVG(exportSimilaritySvgRef.current)}>
                                                        Download  SVG

                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </span>
                                    </div>
                                </div>

                                <div className="bd-que-table-wrapper">


                                    {dataLoading &&
                                        <div className={'page-loader accountloading-box result-sections-loader'}>
                                            <LoadingIcon />
                                        </div>
                                    }
                                    {!dataLoading &&
                                        <div className={`matricx-table-wrap ${(test.language !== 'en') ? 'cairo-font' : ''}`} ref={exportSimilaritySvgRef}>
                                            <div className="table-responsive1" ref={ref} style={{position:'relative'}}>
                                                {tooltip.open ? (
                                                    <>
                                                        <Tooltip
                                                            ref={tipref}
                                                            type={'left'}
                                                            key={Math.random()} // needed for bounds to update correctly
                                                            //style={{ zIndex:9999, display: tooltip.show? "block" : 'block', whiteSpace:"nowrap", width:"auto", minWidth:"150px",}}
                                                            //style={{ zIndex:9999, display: tooltip.show? "block" : 'block', whiteSpace:"nowrap", width:"auto", minWidth:"150px", margin:0, position:"absolute",left:(tooltip.tooltipLeft)+"px",top:(tooltip.tooltipTop)+"px"}}
                                                            style={{ zIndex:2, display: tooltip.show? "block" : 'block', whiteSpace:"normal", width:"260px",minHeight:'15px', margin:"0", position:"absolute",left:(tooltip.tooltipLeft)+"px",top:(parseInt(tooltip.tooltipTop))+"px"}}
                                                            data={tooltip.tooltipData}
                                                            className="tooltipChart left-tooltip-similarity-matrix"

                                                        />
                                                    </>
                                                ): (
                                                    <>
                                                        <Tooltip
                                                            ref={tipref}
                                                            type={"bottom"}
                                                            key={Math.random()} // needed for bounds to update correctly
                                                            style={{ zIndex:9999, display: tooltip.show? "none" : 'none', whiteSpace:"normal", margin:0, position:"absolute",left:(tooltip.tooltipLeft)+"px",top:(tooltip.tooltipTop)+"px"}}
                                                            data={"."}
                                                            className="tooltipChart"

                                                        />
                                                        </>
                                                )}

                                                {/*<Tooltip*/}
                                                {/*    //ref={tipref}*/}
                                                {/*    type={'left'}*/}
                                                {/*    key={Math.random()} // needed for bounds to update correctly*/}
                                                {/*    //style={{ zIndex:9999, display: tooltip.show? "block" : 'block', whiteSpace:"nowrap", width:"auto", minWidth:"150px",}}*/}
                                                {/*    //style={{ zIndex:9999, display: tooltip.show? "block" : 'block', whiteSpace:"nowrap", width:"auto", minWidth:"150px", margin:0, position:"absolute",left:(tooltip.tooltipLeft)+"px",top:(tooltip.tooltipTop)+"px"}}*/}
                                                {/*    //style={{ zIndex:9999, display: tooltip.show? "block" : 'block', whiteSpace:"nowrap", width:"auto", minWidth:"150px", margin:'0', position:"absolute",left:(tooltip.tooltipLeft)+"px",top:(tooltip.tooltipTop)+"px"}}*/}
                                                {/*    style={{ zIndex:9999, display: tooltip.show? "block" : 'block', whiteSpace:"nowrap", width:"auto", minWidth:"150px", marginLeft:'50px',marginTop:'0',marginBottom:'0', position:"absolute",left:"96px",top:"96px"}}*/}
                                                {/*    data={'asdsad'}*/}
                                                {/*    className="tooltipChart left-tooltip-similarity-matrix"*/}

                                                {/*/>*/}
                                                <table ref={exportSimilarityRef} style={{margin:'5px'}}>
                                                    <tbody>

                                                        {similarityMatrixArray.map((datas, indexs) => {

                                                            return (
                                                                <>

                                                                    {(indexs === 0) &&

                                                                        <tr>
                                                                            <td id={datas[0].cardyId} className={`card-name-td ${(test.language !== 'en') ? 'cairo-font' : ''}`}
                                                                                colSpan="5">{datas[0].cardy}</td>
                                                                        </tr>
                                                                    }

                                                                    <tr>
                                                                        {datas.map((data, index) => {
                                                                            return (
                                                                                <>
                                                                                    <td
                                                                                        onMouseOver={() => activeMatrixClass(data.cardxId, data.cardyId)}
                                                                                        onMouseOut={() => inactiveMatrixClass(data.cardxId, data.cardyId)}
                                                                                        onPointerMove={handlePointerMove(data.cardy,data.cardx,data.group_cards_percent,data.group_cards) }
                                                                                        onPointerLeave={()=>{ showTooltip({...tooltip, open:false, show:false}) } }
                                                                                    >
                                                                                        {/*        <p>*/}
                                                                                        {/*            <b>{data.cardy} </b>and*/}
                                                                                        {/*            <b> {data.cardx} </b>*/}
                                                                                        {/*        </p>*/}
                                                                                        {/*        {data.group_cards_percent}%*/}
                                                                                        {/*        of participants*/}
                                                                                        {/*        ({data.group_cards} times)*/}

                                                                                        <div
                                                                                            className="table-tooltip position-relative">

                                                                                            <span
                                                                                                className={(test.language !== 'en') ? 'cairo-font' : ''}
                                                                                                style={{
                                                                                                    backgroundColor: data.colorCode, width: '60px', height: '40px', display: 'flex',
                                                                                                    alignItems: 'center',
                                                                                                    justifyContent: 'center',
                                                                                                    borderRadius: '2px',
                                                                                                    color: data.labelColorCode,
                                                                                                    font: '12px',
                                                                                                }}>
                                                                                                {(data.group_cards_percent != 0) ? data.group_cards_percent + '%' : '0%'}
                                                                                            </span>

                                                                                            {/*{tooltip.open ? (*/}
                                                                                            {/*    <div*/}
                                                                                            {/*        className="tooltip-wrapper left-tooltip tooltipChart-matrix"*/}
                                                                                            {/*        id={`${data.cardxId}-${data.cardyId}`}*/}
                                                                                            {/*        style={{display: 'none'}}*/}
                                                                                            {/*        ref={tipref}*/}
                                                                                            {/*        >*/}
                                                                                            {/*        <p>*/}
                                                                                            {/*            <b>{data.cardy} </b>and*/}
                                                                                            {/*            <b> {data.cardx} </b>*/}
                                                                                            {/*        </p>*/}
                                                                                            {/*        {data.group_cards_percent}%*/}
                                                                                            {/*        of participants*/}
                                                                                            {/*        ({data.group_cards} times)*/}
                                                                                            {/*    </div>*/}
                                                                                            {/*) : <></>*/}
                                                                                            {/*}*/}

                                                                                            {/*{tooltip.open ? (*/}
                                                                                            {/*    <>*/}
                                                                                            {/*        <Tooltip*/}
                                                                                            {/*            ref={tipref}*/}
                                                                                            {/*            type={tooltip.tipPosition}*/}
                                                                                            {/*            key={Math.random()} // needed for bounds to update correctly*/}
                                                                                            {/*            style={{ zIndex:9999, display: tooltip.show? "block" : 'block', whiteSpace:"nowrap", width:"auto", minWidth:"150px", margin:0, position:"absolute",left:(tooltip.tooltipLeft)+"px",top:(tooltip.tooltipTop)+"px"}}*/}
                                                                                            {/*            data={'asdsa'}*/}
                                                                                            {/*            className="tooltipChart"*/}

                                                                                            {/*        />*/}
                                                                                            {/*    </>*/}
                                                                                            {/*): (*/}
                                                                                            {/*    <></>*/}
                                                                                            {/*)}*/}

                                                                                        </div>
                                                                                    </td>

                                                                                    {(indexs === index) &&
                                                                                        <td id={data.cardxId} className={`card-name-td ${(test.language !== 'en') ? 'cairo-font' : ''}`}
                                                                                            colSpan={data.col}>

                                                                                                <div className='similartity-text-dat' style={{width:'350px'}}>{data.cardx}</div>

                                                                                        </td>
                                                                                    }
                                                                                </>
                                                                            );
                                                                        })}
                                                                    </tr>
                                                                </>
                                                            );
                                                        })}

                                                        {/*<tr>*/}
                                                        {/*    <td tooltip="120 testers" flow="down"><span>80</span></td>*/}
                                                        {/*    <td tooltip="120 testers" flow="down"><span>20</span></td>*/}
                                                        {/*    <td className="card-name-td" colSpan="2">test card name</td>*/}
                                                        {/*</tr>*/}
                                                        {/*<tr>*/}
                                                        {/*    <td tooltip="120 testers" flow="down"><span>80</span></td>*/}
                                                        {/*    <td tooltip="120 testers" flow="down"><span>20</span></td>*/}
                                                        {/*    <td tooltip="120 testers" flow="down"><span>10</span></td>*/}
                                                        {/*    <td className="card-name-td">test card name</td>*/}
                                                        {/*</tr>*/}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    }

                                </div>



                            </div>
                        }




                    </div>

                    <div className="participants-result-responsive-section hide-for-desktop">
                        <div className="mobile-charts-not-available">
                            <div className="no-data-icon">
                                <Icon colorClass={"gray-700-svg"} value={"attention"} size={"large"} />
                            </div>
                            <p>
                                This page is not available on mobile. Please log in from a desktop device to access it.
                            </p>
                        </div>

                    </div>

                    <Footer />

                </div>
            }
        </>
    )
}
