import { useEffect, useState, useRef } from "react";
import Task from "./task";
import { useLocation } from 'react-router-dom';
import toast from 'react-hot-toast';
import { savePreferenceTestTaskResultService } from "../../../../services/test_result";
//import { ReactSession } from "react-client-session";
import { ReactSession } from "../../../../lib/secure_reactsession";
import ConfirmModal from "../../../dialog/confirm_modal";
import { FormattedMessage } from "react-intl";
import PreferenceTestIntroduction from "./preference_test_introduction";
import { ToastMessage } from "../../../themes/userq/ToastMessage/ToastMessage";

import { settings } from '../../../../data/settings';
import PreferenceTestFollowUpQuestions from "./preference_test_followup_questions";
import { Button } from "../../../themes/userq/Button/Button";
import { Icon } from "../../../themes/userq/Icon/Icon";
import { Preview } from "../../../themes/userq/Preview/preview";
import { Text } from "../../../themes/userq/Text/Text";

export default function PreferenceTest({ test, result, callback, timeSpent, questionCallback, questionType }) {
    const [initialScreen, setInitialScreen] = useState(true);
    const [designs, setDesigns] = useState([]);
    const [formLoading, setFormLoading] = useState(false);
    const [answer, setAnswer] = useState({});
    const [skippedTask, setSkippedTask] = useState(false);
    const location = useLocation();
    const [startTime, setStartTime] = useState(0);
    const [confirm, setConfirm] = useState({ open: false });
    const [showFollowUpQuestions, setShowFollowUpQuestions] = useState(false);
    const [previewModal, setPreviewModal] = useState({ open: false, file: "", file_type: "image" });
    const item = useRef(null);

    const refQuestion = useRef(null);


    useEffect(()=>{

        if(showFollowUpQuestions){

            const item = document.getElementById('scroll-follow-up-question');

            item.scrollIntoView({
                behavior: "smooth",
                block: "center",
            });
        }

    },[showFollowUpQuestions])

    const savePreferenceTestTask = (skipped) => {
        var p = location.pathname.substr(1, 1);

        if (p == "p") {
            if (test.preference_test_question.length > 0) {
                setShowFollowUpQuestions(true);


            } else {
                callback();
            }
        } else {

            setFormLoading(true);

            savePreferenceTestTaskResultService({
                test_id: test.id,
                result_id: result.id,

                guest_token: ReactSession.get("guest_token"),
                task: answer,
                skipped: skipped,
                time_spent: timeSpent,
            }, ReactSession.get("token")).then((response) => {

                window.scrollTo(0, 0);

                setFormLoading(false);

                if (response.success) {
                    if (test.preference_test_question.length > 0) {
                        setShowFollowUpQuestions(true);


                    } else {
                        callback();
                    }

                } else {
                    showError(response.message);

                    /*toast((
                        <div className='toastinner'>
                            {response.message}
                            <a  onClick={()=>{toast.dismiss(); } }>&times;</a>
                        </div>), {
                        className: 'errortoast',
                        position: 'bottom-center'
                    });*/
                }
            });

        }
    }
    const skipPreferenceTestTask = () => {

        return () => {

            setAnswer({});
            savePreferenceTestTask(true);
        };

    }
    const showError = (error) => {


        toast(
            <ToastMessage type={"error"} message={error} closable={true} onClose={() => { toast.dismiss(); }} />,
            {
                className: "errortoast",
                position: "bottom-center",
                duration: settings.toast_duration,
            }
        );
    };

    const showSuccess = (success) => {
        toast(
            <ToastMessage type={"success"} message={success} closable={true} onClose={() => { toast.dismiss(); }} />,
            {
                className: "successtoast",
                position: "bottom-center",
                duration: settings.toast_duration,
            }
        );
    };
    return (
        <>
            {initialScreen &&
                <PreferenceTestIntroduction test={test} goToTask={
                    () => {
                        setInitialScreen(false);

                        if (test.preference_test_setting) {

                            setStartTime(new Date().getTime());
                        }
                        window.scrollTo(0, 0);
                    }
                } />
            }
            {!initialScreen && !showFollowUpQuestions &&
                <>
                    <Task test={test} preference_test_setting={test.preference_test_setting} preference_test_designs={test.preference_test_designs} answer={answer}
                        formLoading={formLoading}
                        nextTask={() => {

                            if ('answer_id' in answer) {

                                savePreferenceTestTask(false);

                            } else {

                                showError(<><FormattedMessage id="Please select answer before going ahead." /></>);


                            }
                        }}
                        skipTask={() => {

                            setConfirm({ ...confirm, open: true, callback: skipPreferenceTestTask() });
                        }}
                        setAnswer={
                            (answer_id, sequence) => {
                                if ('answer_id' in answer && answer_id == answer.answer_id) {
                                    setAnswer({});
                                } else {
                                    setAnswer({ answer_id: answer_id, sequence: sequence, time_spent: new Date().getTime() - startTime });
                                }
                            }
                        }
                        setSelectedDesign={(i) => {
                            item.current = i;
                        }}
                    />
                </>
            }


            {showFollowUpQuestions &&

                <div className="preference-taking-test-designs-wrapper  preference-test-followup-questions-wrap">
                    <Button
                        type={"secondary"}
                        size={"small"}
                        cssclass='selected-btn'
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            // setAnswer(item.id, sequence);
                            // setFullView(false);
                        }}
                        label={test.language == "en" ?
                            "You've voted for this option"
                            : "حدد هذا الخيار"}
                        iconRight={<Icon colorClass={"gray-50-svg"} value={'check mark'} size={'small'}/>}
                    />

                    <div className="preference-test-followup-questions mt-40">


                        <div className="selected-design">

                            <div className="preference-test-designs-repeat">
                                <div className="added-design-preview-thum"
                                >


                                    <div className="prefer-design-image-view-hold" onClick={() => {
                                        /*if (preference_test_setting.show_fullscreen != 1) {
                                            setActiveIndex(loopindex);
                                        }
    
                                        setFullView(true)*/

                                        setPreviewModal({ ...previewModal, open: true, img: process.env.REACT_APP_IMG_URL + item.current.design_file, file_type: item.current.type, background: item.current.color });
                                    }}>
                                        <div className="test-design-name-and-option-wrap" style={{ position: "relative" }} >
                                            <Text type={"body-text-1"} fontWeight={"medium-font"} cssClasses={`h3 prefer-design-name ${(test.language == "ar") ? "" : ""}`}>

                                                {test.preference_test_setting.show_design_titles == 1 &&
                                                    <>{item.current.title}
                                                    </>}
                                                &nbsp;
                                            </Text>
                                            <div className="design-max-screen-icon" >
                                                <Icon colorClass="gray-900-svg" size={"small"} value={'maximize'} hover="true" />
                                            </div>


                                        </div>
                                        <div className="prefer-test-selected-design-view">
                                            <div className="prefer-test-thum-data-hold">

                                                <div className="added-design-img-thm" style={{ "backgroundColor": item.current.color }}>
                                                    {item.current.type == "video" &&
                                                        <div class="video-design-icon">
                                                            <img src={process.env.REACT_APP_URL + "images/video-play-btn.svg"} alt="icon" />
                                                        </div>
                                                    }
                                                    {test.preference_test_setting.show_fullscreen != 1 &&
                                                        <img src={process.env.REACT_APP_IMG_URL + item.current.thumb} />
                                                    }
                                                    {test.preference_test_setting.show_fullscreen == 1 &&
                                                        <>
                                                            {item.current.type == "image" &&
                                                                <img src={process.env.REACT_APP_IMG_URL + item.current.design_file} />
                                                            }
                                                            {item.current.type == "video" &&
                                                                <video width="320" height="240" controls>
                                                                    <source src={process.env.REACT_APP_IMG_URL + item.current.design_file} type="video/mp4" />
                                                                </video>
                                                            }
                                                        </>
                                                    }


                                                </div>
                                            </div>
                                        </div>
                                        <div className="test-design-name-and-option-wrap">






                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div id={'scroll-follow-up-question'}></div>
                            <PreferenceTestFollowUpQuestions test={test} result={result} callback={callback} questionCallback={questionCallback} questionType={questionType} timeSpent={timeSpent} />
                    </div>
                    </div>
            }

            <ConfirmModal openModal={confirm.open} confirm_message="Are you sure you want to skip this task. This will not save any credits to your account?" confirm_title="Skip" close={() => { setConfirm({ ...confirm, open: false }) }}
                confirm={() => {
                    confirm.callback();
                    setConfirm({ ...confirm, open: false });
                }}
            />
            <Preview rtl={test && test.language == "ar" ? true : false} open={previewModal.open} withMinimizeIcon={true} background={previewModal.background} loading={false} file_type={previewModal.file_type} img={previewModal.img} close={() => {
                setPreviewModal({ ...previewModal, open: false });
            }} />

        </>

    );
}