import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef } from "react";
import { Text } from './../Text/Text';
import { Icon } from './../Icon/Icon';
import { Tag } from './../Tag/Tag';
export const TasksDropdown = ({ options, changeActiveOptionProp, dropdownOpen, rtl, active }) => {

    const wrapperRef = useRef(null);

    const [isListOpen, setListOpen] = useState(false);
    const [isActive, setActive] = useState(active?active:1);

    const changeActiveOption = (index) => {
        setActive(index);
        

    }
    const showPreQuestion = (type) => {
        if (type === "increase") {
            // decrease active question length
            if (isActive !== 1) {
                changeActiveOption(isActive - 1);
                changeActiveOptionProp(isActive - 1);
            }
        } else {
            // increase active question length
            if (options.length !== isActive) {
                changeActiveOption(isActive + 1);
                changeActiveOptionProp(isActive + 1);
            }
        }
    }

    function useOutsideAlerter(ref, questionFormValues) {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            //   console.log(ref.current);
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setListOpen(false);
                    dropdownOpen(false);
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    useOutsideAlerter(wrapperRef);

    useEffect(() => { changeActiveOption(active?active:1); }, [active]);


    return (
        <div className="question-dropdown-container" ref={wrapperRef}>
            <div
                className="dropdown-header"
                onClick={(event) => {
                    // console.log(event.target);
                    // console.log(event.target.classList.contains('dropdown-toggle-disabled'));
                    if (!event.target.classList.contains('dropdown-toggle-disabled')) {
                        setListOpen(!isListOpen);
                        dropdownOpen(!isListOpen);
                    }
                }
                }
            >

                <div className="dropdown-header-left">
                    <div className="dropdown-header-top">
                        <p className="text-small-info text-uppercase">
                            <span className='active-question'>{isActive}</span>
                            <span className='text-lowercase'>of</span> {options.length} - 
                            Tree test {" "}
                            <span className='task-dropdown-subtitle-text purple-text'>({options.length > 0 ? (options[isActive-1].subtitle > 1 ? options[isActive-1].subtitle+" answers":options[isActive-1].subtitle+" answer"):""})</span>
                        </p>
                    </div>
                    <div className="dropdown-header-bottom">
                        <div className="dropdown-header-selected-question">
                            <Text type="subtitle-2" fontWeight="medium-font">
                                {options && options.length>0 && 
                                <div className={`${rtl?"arabic-font":""}`}>{options[isActive-1].title}</div>
                                }
                            </Text>
                        </div>
                        <div className="dropdown-header-arrow-buttons">
                            <div className="bd-que-slide-arrow">
                                <a onClick={() => showPreQuestion("increase")}
                                    className={`${isActive === 1 ? 'disabled-link' : ''} dropdown-toggle-disabled`}>
                                    <Icon value="back-arrow" size="medium" colorClass={'gray-50-svg dropdown-toggle-disabled'}/>
                                    {/* <Icon value="backward-arrow-white" /> */}
                                </a>
                                <a onClick={() => showPreQuestion("decrease")}
                                    className={`${options.length === isActive ? 'disabled-link' : ''} dropdown-toggle-disabled`}>
                                    <Icon value="forward-arrow" size="medium" colorClass={'gray-50-svg dropdown-toggle-disabled'}/>
                                    {/* <Icon value="forward-arrow-white" /> */}
                                </a>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="dropdown-header-right">
                    <div className="dropdown-header-chevron">
                        {isListOpen
                            ? <Icon value="chevron-up" size="medium" />
                            : <Icon value="chevron" size="medium" />}
                    </div>
                </div>
            </div>

            <div className="dropdown-header-arrow-buttons none-for-desktop mobile-nav-arrows">
                    <div className="bd-que-slide-arrow">
                        <a onClick={() => showPreQuestion("increase")}
                           className={`${isActive === 1 ? 'disabled-link' : ''} dropdown-toggle-disabled`}>
                            <Icon value="back-arrow" size="medium" colorClass={'gray-50-svg dropdown-toggle-disabled'}/>
                            {/* <Icon value="backward-arrow-white" /> */}
                        </a>
                        <a onClick={() => showPreQuestion("decrease")}
                           className={`${options.length === isActive ? 'disabled-link' : ''} dropdown-toggle-disabled`}>
                            <Icon value="forward-arrow" size="medium" colorClass={'gray-50-svg dropdown-toggle-disabled'}/>
                            {/* <Icon value="forward-arrow-white" /> */}
                        </a>
                    </div>
            </div>
            {isListOpen && (
                <div className='dropdown-list-hold-wrap'>
                <div
                    className="dropdown-list-container"
                >
                    {options && options.map((item, index) => (
                        <span
                            className="dropdown-list-item"
                            key={"option-"+item.title+index}
                            onClick={() => {
                                setListOpen(false);
                                dropdownOpen(false);
                                changeActiveOption(index + 1);
                                changeActiveOptionProp(index + 1);
                                
                            }}
                        >
                            {isActive == index + 1 ?
                            <div className='dropdown-active-question dropdown-question-item'>
                                <Text type="body-text-1" fontWeight="medium-font" cssClasses={`${rtl?"arabic-font":""}`}>{item.title}</Text>
                            </div>
                            :
                            <div className='dropdown-question-item'>
                                <Text type="body-text-1" fontWeight="medium-font" cssClasses={`${rtl?"arabic-font":""}`}>{item.title}</Text>
                                
                            </div>
}
                        </span>
                    ))}
                </div>
                </div>
            )}
        </div>
    )


}
TasksDropdown.propTypes = {
    options: PropTypes.array,
    changeActiveOption: PropTypes.func,
    dropdownOpen: PropTypes.func,
}
TasksDropdown.defaultProps = {
    options: [],
    changeActiveOption: ()=>{},
    dropdownOpen: ()=>{},
}