import React from "react";
import { InformationTilesBox } from "../themes/userq/InformationTilesBox/information_tiles_box";

export const ResearcherSlider = React.memo(() => {
  return (
    <InformationTilesBox
      tiles={[
        {
          title: "Easily recruit participants",
          subtitle:
            "Choose from a variety of demographics to find the ideal participants for your study.",
        },
        {
          title: "Screening questions to reach your audience",
          subtitle:
            "Add up to 3 screening questions when recruiting from the UserQ panel to reach the right audience.",
        },
        {
          title: "You pay only for the good responses",
          subtitle:
            "By reporting testers who didn’t comply with the quality guidelines, you will receive a refund of the credits you spent.",
        },

        {
          title: "Share the insights with your team",
          subtitle: "Download or share a live link to your results instantly.",
        },
        {
          title: "UserQ team is here to support you",
          subtitle:
            "Access the live chat from your dashboard to receive real-time support from the team.",
        },
      ]}
    />
  );
});
