import PropTypes from 'prop-types';
export const Text = ({type, display, id, fontWeight, cssClasses, children,onPointerMove,onPointerLeave})=>{
    
    if(type=="h1"){
        return (
            <h1
                id={id}
                className={`heading h1 ${fontWeight?fontWeight:""} ${cssClasses?cssClasses:""}`}>
                {children}
            </h1>
        )
    }
    else if(type=="h2"){
        return (
            <h2 id={id} className={`heading h2 ${fontWeight?fontWeight:""} ${cssClasses?cssClasses:""} `}>{children}</h2>
        )
    } else if(type=="h3"){
        return (
            <h3 id={id} className={`heading h3 ${fontWeight?fontWeight:""} ${cssClasses?cssClasses:""}`}>{children}</h3>
        )
    } else if(type=="h4"){
        return (
            <h4 id={id} className={`heading h4 ${fontWeight?fontWeight:""} ${cssClasses?cssClasses:""}`}>{children}</h4>
        )
    } else if(type=="subtitle-1"){
        return (
            <span id={id} className={`subtitle-1 ${fontWeight?fontWeight:""} ${cssClasses?cssClasses:""}`}>{children}</span>
        )
    }  else if(type=="subtitle-2"){
        return (
            <span id={id} onPointerLeave={onPointerLeave} onPointerMove={onPointerMove} className={`subtitle-2 ${fontWeight?fontWeight:""} ${cssClasses?cssClasses:""}`}>{children}</span>
        )
    }  else if(type=="body-text-1"){
        if(display && display=="inline"){
            return (
                <span id={id} className={`body-text body-text-1 ${fontWeight?fontWeight:""} ${cssClasses?cssClasses:""}`}>{children}</span>
            )
        } else {
            return (
                <p id={id} className={`body-text body-text-1 ${fontWeight?fontWeight:""} ${cssClasses?cssClasses:""}`}>{children}</p>
            )
        }
    }  else if(type=="body-text-2"){
        if(display && display=="inline"){
            return (
                <span id={id} className={`body-text body-text-2 ${fontWeight?fontWeight:""} ${cssClasses?cssClasses:""}`}>{children}</span>
            )
        } else {
            return (
                <p id={id} className={`body-text body-text-2 ${fontWeight?fontWeight:""} ${cssClasses?cssClasses:""}`}>{children}</p>
            )
        }
    } else if(type=="body-text-3"){
        if(display && display=="inline"){
            return (
                <span id={id} className={`body-text body-text-3 ${fontWeight?fontWeight:""} ${cssClasses?cssClasses:""}`}>{children}</span>
            )
        } else {
            return (
                <p id={id} className={`body-text body-text-3 ${fontWeight?fontWeight:""} ${cssClasses?cssClasses:""}`}>{children}</p>
            )
        }
    } else if(type=="overline"){
        return (
            <span id={id} className={`overline ${fontWeight?fontWeight:""} ${cssClasses?cssClasses:""}`}>{children}</span>
        )
    } else if(type=="caption"){
        return (
            <span id={id} className={`caption ${fontWeight?fontWeight:""} ${cssClasses?cssClasses:""}`}>{children}</span>
        )
    }

    
}
Text.propTypes = {
    type: PropTypes.oneOf(["h1","h2","h3","h4","subtitle-1","subtitle-2","body-text-1","body-text-2","body-text-3","overline","caption"]),
    fontWeight: PropTypes.oneOf(["light-font","normal-font","medium-font","semi-bold-font","bold-font","tag"]),
    id:PropTypes.string,
    children: PropTypes.any
};