import React, {useRef, useState} from "react";
import {  Link,useNavigate } from "react-router-dom";
import { Text } from "../Text/Text";
import { Icon } from "../Icon/Icon";
import { HamburgerMenu } from "../HamburgerMenu/HamburgerMenu";
import PropTypes from 'prop-types';
import Skeleton from "react-loading-skeleton";
import {Tooltip} from "../Tooltip/Tooltip";
export const CardTemplate = ({
                                 testName,
                                 status,
                                 isDuplicate,
                                 methodology,
                                 language,
                                 createdOn,
                                 endedOn,
                                 percentageComplete,
                                 participants,
                                 participantsTotal,
                                 averageTimeSpendMinute,
                                 averageTimeSpendSeconds,
                                 endingInDay,
                                 endingInText,
                                 duplicate,
                                 deleteTest,
                                 openCopyLink,
                                 endTest,
                                 editTest,
                                 navigateLink,
                                 testSummary,
                                 isLoading,
                                 key,
                                 isReportedTester,
                                 countReportedTester,
                                 timeLengthLabel
                             }) => {
    const navigate = useNavigate();

    const refs = useRef([]);
    const ref = useRef();
    const tipref = useRef();

    const [tooltip, showTooltip] = useState({
        tooltipLeft:0,
        tooltipTop:0,
        tooltipData:"",
        open: false,
        show:false
    });

    const handlePointerMove = (data,currentEle) => {


        console.log(data)
        return (
            (event) => {


                let tipPosition = "bottom";

                var tool_width = tipref && tipref.current ?  tipref.current.clientWidth : 260;


                var tool_height =  tipref && tipref.current ?  tipref.current.clientHeight + 12 : 800;



                let containerX = ('clientX' in event ? event.clientX : 0) - ref.current.getBoundingClientRect().left - tool_width/2;
                let containerY = ('clientY' in event ? event.clientY : 0)  - ref.current.getBoundingClientRect().top - tool_height - 10 ;


                var window_width = window.innerWidth;

                var window_height = window.innerHeight;

                if(event.clientX + tool_width/2 > window_width){
                    containerX = ('clientX' in event ? event.clientX : 0) - ref.current.getBoundingClientRect().left - tool_width/2 - (event.clientX + tool_width/2 - window_width) ;

                }
                if(event.clientX - tool_width/2 < 0){
                    containerX = ('clientX' in event ? event.clientX : 0) - ref.current.getBoundingClientRect().left - tool_width/2 + (tool_width/2 - event.clientX) ;
                }

                console.log('khkjh')
                // check if content is more then tooltip is shown
                //if(currentEle.scrollWidth > currentEle.clientWidth){
                    showTooltip({
                        tooltipLeft: containerX,
                        tooltipTop: containerY,
                        tooltipData: data,
                        open:true,
                        show: true,
                        tipPostion: tipPosition
                    });
              //  }

            });
    }

    const [draftMenu, setDraftMenu] =   useState([
        {
            isLink: false,
            name: 'Duplicate',
            onClick: duplicate,
            urlLink: '',
            itemClass:''
        },
        {
            isLink: false,
            name: 'Edit',
            onClick: editTest,
            urlLink: '',
            itemClass:''
        },
        {
            isLink: false,
            name: 'Delete',
            onClick: deleteTest,
            urlLink: '',
            itemClass:'text-danger'
        },
        /* {
        isLink: false,
        name: 'Test summary',
        onClick: testSummary,
        urlLink: '',
        itemClass:''
        }*/
    ]);
    const [liveMenu, setLiveMenu] =   useState([
        {
            isLink: false,
            name: 'Share link',
            onClick: openCopyLink,
            urlLink: '',
            itemClass:''
        },
        {
            isLink: false,
            name: 'Test summary',
            onClick: testSummary,
            urlLink: '',
            itemClass:''
        },
        {
            isLink: false,
            name: 'View results',
            onClick: editTest,
            urlLink: '',
            itemClass:''
        },
        {
            isLink: false,
            name: 'Duplicate',
            onClick: duplicate,
            urlLink: '',
            itemClass:''
        },
        {
            isLink: false,
            name: 'End test',
            onClick: endTest,
            urlLink: '',
            itemClass:'text-danger'
        }
    ]);
    const [completedMenu, setCompletedMenu] =   useState([
        {
            isLink: false,
            name: 'Share link',
            onClick: openCopyLink,
            urlLink: '',
            itemClass:''
        },
        {
            isLink: false,
            name: 'Test summary',
            onClick: testSummary,
            urlLink: '',
            itemClass:''
        },
        {
            isLink: false,
            name: 'View results',
            onClick: editTest,
            urlLink: '',
            itemClass:''
        },
        {
            isLink: false,
            name: 'Duplicate',
            onClick: duplicate,
            urlLink: '',
            itemClass:''
        }
    ]);
    return (
        <div className={`test-list-repeat-wrap researcher-test-card`} key={key}>
            <div className={`test-list-data ${(!isLoading)?(status === 'draft')?'test-draft':status+'-test':'loading-cards'}`}>
                <div className={"all-test"}>


                    {isLoading ?
                        <>
                            <div
                                className="test-list-head d-flex align-items-center justify-content-between position-relative">
                                <h4 className="heading h4 mb-0">
                                    <Skeleton width={280} height={18} style={{borderRadius:'30px'}} />
                                </h4>
                            </div>
                            <div className="test-list-mid-data position-relative">
                                <div className="tag-wrap d-flex">
                                    <Skeleton width={100} height={20} style={{marginRight:'0px',borderRadius:'5px'}} />
                                    <Skeleton width={66} height={20} style={{borderRadius:'5px'}}/>
                                    <Skeleton width={66} height={20} style={{borderRadius:'5px'}}/>
                                </div>
                            </div>
                        </>
                        :
                        <Link to={navigateLink} className="card-top-link">


                            <div
                                className="test-list-head d-flex align-items-center justify-content-between position-relative"
                                ref={ref}
                            >
                                {tooltip.open ? (
                                    <>
                                        {/*{console.log(tooltip,testName)}*/}
                                        <Tooltip
                                            ref={tipref}
                                            type={tooltip.tipPosition}
                                            key={Math.random()} // needed for bounds to update correctly
                                            style={{ zIndex:2, display: tooltip.show? "block" : 'block', whiteSpace:"normal", width:"260px", minWidth:"150px", margin:0, position:"absolute"}}
                                            data={testName}
                                            className="tooltipChart"

                                        />
                                    </>
                                ): (
                                    <></>
                                )}

                                    <Text type={"subtitle-2"} cssClasses={(language === 'Arabic')?'arabic-font':''}
                                          // onPointerMove={handlePointerMove(({testName},refs.current[key])) }
                                          // onPointerLeave={()=>{ showTooltip({...tooltip, open:false, show:false}) } }
                                    >
                                        {testName}
                                    </Text>
                            </div>
                            <div className="test-list-mid-data position-relative">
                                <div className={"card-tag-wrap d-flex justify-content-between align-items-center"}>
                                    <div className="tag-wrap mb-0">
                                        <span className="tag">{methodology=="Five Seconds Test"?"5 second test":methodology}</span>
                                        <span className={`tag ${(language === 'Arabic')?'arabic-font':''}`}> {language === 'Arabic'?'عربى':language}</span>
                                        {(timeLengthLabel) && <span className={'tag'}>{timeLengthLabel}</span> }
                                    </div>
                                </div>
                            </div>
                        </Link>
                    }
                    {isLoading ?
                        <div className="card-load-mid">
                            <div className="test-time-data d-flex">
                                <div className="test-time-data-repeat participant-data">
                                    <Skeleton width={80} height={11} style={{borderRadius:'15px', marginBottom:'0px',}} />
                                    <Skeleton width={60} height={21} style={{borderRadius:'30px', marginTop:'0px'}} />
                                </div>
                                <div className="test-time-data-repeat">
                                    <Skeleton width={86} height={11} style={{borderRadius:'15px', marginBottom:'0px',}} />
                                    <Skeleton width={60} height={21} style={{borderRadius:'30px', marginTop:'0px'}} />
                                </div>
                                <div className="test-time-data-repeat">
                                    <Skeleton width={53} height={11} style={{borderRadius:'15px', marginBottom:'0px',}} />
                                    <Skeleton width={60} height={21} style={{borderRadius:'30px', marginTop:'0px'}} />
                                </div>
                            </div>
                        </div>
                        :
                        <Link to={navigateLink} className="card-middle-link">
                            {status === 'draft' &&
                            <div className="progress-bar-wrap">
                                <Text type='body-text-3' fontWeight='semi-bold-font' children={`${percentageComplete}% complete`}/>
                                <div className="progress-bar-inner">
                                    <div className="progress-fill" style={{width:`${percentageComplete}%`}}></div>
                                </div>
                            </div>
                            }
                            {status !== 'draft' &&
                            <div className="test-time-data d-flex">
                                <div className="test-time-data-repeat participant-data">
                                    <Text type='caption' children={'Participants'}/>
                                    <h4 className="heading h4">{participants}
                                        {participantsTotal &&
                                        '/'
                                        }
                                        <sub>
                                            {participantsTotal &&
                                            participantsTotal
                                            }
                                        </sub>
                                    </h4>
                                </div>
                                <div className="test-time-data-repeat">
                                    <Text type='caption' children={'Avg time spent'}/>
                                    <h4 className="heading h4">{averageTimeSpendMinute}<sub> {averageTimeSpendSeconds}</sub></h4>
                                </div>
                                {(isReportedTester) ? (
                                <div className="test-time-data-repeat">
                                    <Text type='caption' children={'Reported'}/>
                                    <div className="reported-user-info d-flex align-items-center">
                                        <Text type={'h4'}>
                                            {countReportedTester}
                                        </Text>
                                        {isReportedTester ? (
                                            <span className="duplicate-test reported-tester-test info-wrap position-relative">
                                                <Icon value={'under-review'}/>
                                                <div className="tooltip-wrapper bottom-tooltip">
                                                    <Text type='body-text-2' id='tooltip'
                                                          children={`${countReportedTester} ${countReportedTester===1?'Participant':'Participants'} under review`}
                                                          fontWeight='normal-font'/>
                                                </div>
                                            </span>
                                        ) :''}
                                    </div>
                                </div>
                                ) :''}
                            </div>
                            }
                        </Link>
                    }
                </div>


                <div className="test-footer-data d-flex justify-content-between align-items-end position-relative">
                    <div className="test-footer-lhs">

                        {isLoading ? (
                            <>
                                <div className="d-flex align-tems-center test-card-footer-lhs-data card-footer-left-loader">
                                    <Skeleton width={53} height={25} style={{borderRadius:'30px'}} />
                                    <Skeleton width={120} height={14} style={{borderRadius:'15px', marginLeft:'10px',}} />
                                </div>
                            </>
                        ) : (
                            <Link to={navigateLink} className="">
                                <div className="d-flex test-card-footer-lhs-data">
                                    <Text
                                        type="caption"
                                        children={status}
                                        cssClasses={`status-tag ${(status) ? 'status-' + status : ''} text-capitalize`}
                                    />
                                    <div className="test-date-wrap">
                                        <Text
                                            type="caption"
                                            children={`${(status === 'draft') ? 'Created' : (status === 'completed')?'Closed':'Published'} on: ${createdOn}`}
                                        />
                                    </div>
                                </div>
                            </Link>
                        )}

                    </div>
                    <div className="test-footer-rhs d-flex align-items-center justify-content-end">
                        {isLoading ? (
                            <>
                                <div className="d-flex align-tems-center test-card-footer-lhs-data card-footer-left-loader">
                                    <Skeleton width={53} height={25} style={{borderRadius:'30px'}} />
                                </div>
                            </>
                        ) : (
                            <>
                                {isDuplicate ? (
                                    <Link to={navigateLink} className="">
                                        <span className="duplicate-test info-wrap position-relative">
                                            <Icon value={'duplicate'}/>
                                            <div className="tooltip-wrapper bottom-tooltip">
                                                <Text type='body-text-2' id='tooltip'
                                                      children={'This test has been duplicated.'}
                                                      fontWeight='normal-font'/>
                                            </div>
                                        </span>
                                    </Link>
                                ) :''}
                                {!isLoading &&
                                <>
                                    {status === 'draft' &&
                                    <HamburgerMenu
                                        items={draftMenu}
                                    />
                                    }
                                    {status === 'completed' &&
                                    <HamburgerMenu
                                        items={completedMenu}
                                    />
                                    }
                                    {status === 'live' &&
                                    <HamburgerMenu
                                        items={liveMenu}
                                    />
                                    }
                                </>
                                }
                            </>
                        )}

                    </div>
                </div>
            </div>
        </div>
    );
};
CardTemplate.propTypes = {
    testName: PropTypes.string,
    status: PropTypes.oneOf(["draft","live","completed"]),
    methodology:PropTypes.string,
    percentageComplete:PropTypes.number,
    language: PropTypes.oneOf(["English","Arabic"])
};