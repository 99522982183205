import React, { useEffect, useState, useRef } from "react";
import PrototypeTestIntroduction from "./prototype_test_introduction";
import PrototypeTestTaskFlow from "./prototype_test_task_flow";
import PrototypeTestFreeFlow from "./prototype_test_free_flow";

export default function PrototypeTest({
    test,
    result,
    questionCallback,
    callback,
    questionType,
    timeSpent,
    isPaidUser
}) {
    const [isActiveTask, setActiveTask] = useState(0);
    const [initialScreen, setInitialScreen] = useState(true);
    const [taskScreen, setTaskScreen] = useState(false);

    return (
        <>
            {initialScreen &&
                <PrototypeTestIntroduction goToFirstTask={
                    () => {
                        setInitialScreen(false);
                        setTaskScreen(true);
                    }
                }
                    testlanguage={test.language}
                    test={test}
                />
            }
            {taskScreen &&
                <>
                    {test.prototype_test_setting.objective == "closed" ?
                        <PrototypeTestTaskFlow goToFirstQuestion={
                            () => {
                                setTaskScreen(false);
                            }
                        }
                            test={test}
                            task={test.prototype_test_tasks[isActiveTask]}
                            active_task={isActiveTask}
                            result={result}
                            timeSpent={timeSpent}
                            moveToNextTask={(task) => {
                                console.log(task);
                                console.log(test.prototype_test_tasks.length);
                                if (task < test.prototype_test_tasks.length) {
                                    setActiveTask(task);
                                } else {
                                    callback();
                                }
                            }}
                            isPaidUser={isPaidUser}
                        />
                        :
                        <PrototypeTestFreeFlow goToFirstQuestion={
                            () => {
                                setTaskScreen(false);
                            }
                        }
                            test={test}
                            task={test.prototype_test_tasks[isActiveTask]}
                            active_task={isActiveTask}
                            result={result}
                            timeSpent={timeSpent}
                            moveToNextTask={(task) => {
                                console.log(task);
                                console.log(test.prototype_test_tasks.length);
                                if (task < test.prototype_test_tasks.length) {
                                    setActiveTask(task);
                                } else {
                                    callback();
                                }
                            }}
                            isPaidUser={isPaidUser}
                        />
                    }
                </>
            }
        </>
    );
}
