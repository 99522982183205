import React from "react";
import { useNavigate, useParams, Link, useSearchParams } from "react-router-dom";
//import { ReactSession } from "react-client-session";
import { ReactSession } from "../../../lib/secure_reactsession";
//import LayoutResearcherFullWidth from "../../layouts/layout_researcher_full_width";
import { LayoutResearcher } from "../../themes/userq/Layouts/layout_researcher";
import { Stepper } from './../../themes/userq/Stepper/Stepper';
import { Button } from './../../themes/userq/Button/Button';
import { LoadingIcon } from "./../../themes/userq/Loader/loadingIcon";
import { Accordian } from './../../themes/userq/Accordion/Accordion';
import { Icon } from './../../themes/userq/Icon/Icon';
import Header from "./components/header";

import Progress from "../study/progress/progress.js";
import Topbar from "../study/topbar/topbar.js";
import { QuestionCard } from "../../themes/userq/QuestionCard/QuestionCard";
import { Question } from "../../themes/userq/Question/Question";
import { QuestionInput } from "../../themes/userq/Question/QuestionInput";
import EditQuestionInput from "../../themes/userq/Question/EditQuestionInput";
import { useState, useEffect, useRef } from "react";
import {
	getTestData,
	deleteFirstclickTestTaskService,
	reorderDesignService,
	updateFirstclickTestService,
	savePreferenceTestQuestions,
	addFirstclickTestTaskService
} from "../../../services/test";
import toast from "react-hot-toast";
import ConfirmModal from "../../themes/userq/ConfirmModal/confirm_modal";
import ConfirmationModal from "../../themes/userq/Modal/ConfirmationModal";
import moment from "moment";
import redirectToStep from "./redirect_to_step";
import { FormattedMessage } from "react-intl";
import { maxLengthCheck, renderTestTitleCreate } from "../../../lib/helpers";
import InputField from "../../layouts/elements/fields/input";
import Checkbox from "../../layouts/elements/fields/checkbox";
import PreferenceTestDesignModal from "./preference_test/preference_test_design_modal";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import EditPreferenceTestDesignModal from "./preference_test/edit_preference_test_design_modal";
import { ImageUpload } from "./components/image_upload_design";
import { ToastMessage } from "../../themes/userq/ToastMessage/ToastMessage";
import { Input } from "../../themes/userq/Input/Input";
import { Text } from "../../themes/userq/Text/Text";
import { CheckBox } from "../../themes/userq/CheckBox/CheckBox";
import { ToggleButton } from "../../themes/userq/ToggleButton/ToggleButton";
import { ConclusionQuestions } from './conclusion_questions';
import { IntroductionQuestions } from './introduction_questions';
import { settings } from "../../../data/settings";
import _,{ isEmpty } from "lodash";
import { RadioButton } from "../../themes/userq/RadioButton/RadioButton";
import Task from "./firstclick_test/task";
import ChangeOrderModal from "./firstclick_test/change_order";
import HelpModal from "../../themes/userq/Modal/HelpModal";
import TestSettingsModal from "../../dashboard/modals/test_settings_modal";
import { Helmet } from "react-helmet";
import { useTestBuilderData } from "./contexts/TestBuilderContext.js";

export default function FirstclickTest() {
	let { test_id } = useParams();
	const nodeRef = useRef(null);
	const navigate = useNavigate();

	ReactSession.setStoreType("localStorage");

	const { updateTestLength, getTestLength } = useTestBuilderData();

	let testController = useRef();

	const [formValues, setFormValues] = useState({
		tasks: []
	});
	const [validate_form, setValidateForm] = useState(false);
	
   	const [changeOrderModal, setChangeOrderModal] = useState({open:false});

	const [testSettingModal, setTestSettingModal] = useState({open:false});
	
	const [pageLoading, setPageLoading] = useState(false);

	const [isSaved, setIsSaved] = useState({ 'status': '', 'message': '' });

	const [syncLoading, setSyncLoading] = useState(false);

	const [prototypeTestLoading, setPrototypeTestLoading] = useState(false);

	const [prototypeTestStepLoading, setPrototypeTestStepLoading] =useState(false);


	
	const [lastSaved, setLastSaved] = useState(null);

	const [isLoading, setLoading] = useState(false);

	const [confirmModal, setConfirmModal] = useState({ open: false });

	const [switchConfirmModal, setSwitchConfirmModal] = useState({ open: false });

	const [helpModal, setHelpModal] = useState({ open: false });

	const [test, setTest] = useState("");

	const [publishing_credits, setPublishingCredits] = useState(0);

	const [editable_text, setEditableText] = useState("");

	const [step, setStep] = useState(0);

	const [addDesignModal, setAddDesignModal] = useState({ open: false });

	const [editDesignModal, setEditDesignModal] = useState({ open: false, design: null });

	const [searchParams] = useSearchParams()

	const [accordians, setAccordians] = useState({ 'introduction': false, firstclick: searchParams.get("error") == "true"?true:false, conclusion: false })

	const [formErrors, setFormErrors] = useState({
		title: null,
		tasks:{},
		error_class: "input_error",
	});

	const [show_support_panel, setShowSupportPanel] = useState(false);

	let treeError = false;

	let treeAnswerError = false;

    const [stepperError, setStepperError] = useState(false);
    
    const [introCount, setIntroCount] = useState(0);
    const [concCount, setConcCount] = useState(0);
    const [introductionQuestions, setIntroductionQuestions] = useState(null);
    const [conclusionQuestions, setConclusionQuestions] = useState(null);

	const queryAttr = "data-rbd-drag-handle-draggable-id";
    const [placeholderProps, setPlaceholderProps] = useState({});
	const [language, setLanguage] = useState("en");
    const [questionType, setQuestionType] = useState("");
	const [surveyQuestions, setSurveyQuestions] = useState([]);
	const [addingSurveyQuestions, setAddingSurveyQuestions] = useState(false);
    const [edittingSurveyQuestions, setEdittingSurveyQuestions] = useState(false);
	const [editQuestionId, setEditQuestionId] = useState(null);

	
	const [addTaskLoading, setAddTaskLoading] = useState(false);

	const taskRefs = useRef({});

	
	const introductionRef = useRef(null);

	const conclusionRef = useRef(null);
	const showError = (error) => {
		toast(
			<ToastMessage type={"error"} message={error} closable={false} onClose={() => { toast.dismiss(); }} />,
			{
				id:"error",
				className: "errortoast",
				position: "bottom-center",
				duration: settings.toast_duration,
			}
		);
	};

	const addFirstclickTestTask = () => {
		let token = ReactSession.get("token");

		setAddTaskLoading(true);


		addFirstclickTestTaskService({ test_id: test_id }, token).then((response) => {
			setAddTaskLoading(false);

			if (response.success) {

				getTestLength(test_id);
				var tasksLoc = Object.assign([], formValues.tasks);

				tasksLoc.push(response.task);

				setFormValues({...formValues, tasks: tasksLoc});
				
			} else {
                showError(response.message);
			}
		});
	};
	const getTestApi = () => {
		let token = ReactSession.get("token");

		setPageLoading(true);

		setLoading(true);

		getTestData({ test_id: test_id }, token).then((response) => {
			setPageLoading(false);

			setLoading(false);

			if (response.success) {

				getTestLength(test_id);
				setTest(response.test);

				setIntroCount(response.test.briefingQuestion.length);
                setConcCount(response.test.debriefingQuestion.length);
                setIntroductionQuestions(response.test.briefingQuestion);
                setConclusionQuestions(response.test.debriefingQuestion);

                
				let questions = [];
                
				response.test.preferenceTestQuestion.forEach((question) => {
                    questions.push(JSON.parse(question));
                });
				
				if (response.test.status) {
					if (response.test.status == "published") {
						navigate("/r/project/" + response.test.project_id);
					} else {
						if (
                            response.test.welcome_title == "" ||
                            response.test.welcome_description == ""
                        ) {
                            localStorage.setItem("stepper_error", "The welcome page cannot be empty! Please enter a title and a description.");
                            navigate("/r/test/" + test_id + "/welcome?error=true");
                            showError("The welcome page cannot be empty! Please enter a title and a description.");
                        }
					}
				}

				setLastSaved(moment(response.test.updated_at));

				

				setFormValues({
					...formValues,
					tasks:  response.test.firstclick_test_tasks.length > 0 ? response.test.firstclick_test_tasks : [{}]
				});

				setValidateForm(true);

			} else {
                showError(response.message);
			}
		});
	};
	const validateFullForm = () => {
		
		var error = false;

        var firsterrorid = null;

		var question_error = false;

		var form_errors = resetFormErrors() ? resetFormErrors() :{"tasks":{}} ;

		if(conclusionRef.current){
			conclusionRef.current.resetErrors();

		}
		if(introductionRef.current){
			introductionRef.current.resetErrors();
		}
		for(var i=0; i<formValues.tasks.length; i++){

			var task = formValues.tasks[i];
			
			if(taskRefs.current[task.id]){

				var task_ref = taskRefs.current[task.id];

				if(task_ref.followupRef){

					var followup_ref = task_ref.followupRef;

					if(followup_ref.questionRef){

						followup_ref.questionRef.resetErrors();

						
					}
					if(followup_ref.editQuestionRef){

						followup_ref.editQuestionRef.resetErrors();

						
					}
				}
			}
		}
		
		for(var i=0; i<formValues.tasks.length; i++){
			var task = formValues.tasks[i];
			form_errors["tasks"][task.id] = {};
		}
		if(introductionRef.current){
			error = !introductionRef.current.questionValidateForm();

			question_error = error;
		}
		if(!error){
			for(var i=0; i<formValues.tasks.length; i++){
				
				

				var task = formValues.tasks[i];

			

				form_errors["tasks"][task.id] = {};

				var task_errors = {};

				if(!task.description){
					task_errors = {...task_errors,"description":"A task description is required"};
					
					if(firsterrorid==null){
						firsterrorid = "task_description_"+task.id;
					}
					
					error = true;

					
				}
				else if( !task.design_file){
					task_errors = {...task_errors,"design_file":"A design is required"};

					if(firsterrorid==null){
						firsterrorid = "design_file_error_"+task.id;
					}

					error = true;

					
				}
				else { 
					
					if(taskRefs.current[task.id]){

						var task_ref = taskRefs.current[task.id];

						if(task_ref.followupRef){

							var followup_ref = task_ref.followupRef;

							if(followup_ref.questionRef){

								question_error = followup_ref.questionRef.handleQuestionSubmit(true);

								
							}
							if(followup_ref.editQuestionRef){

								question_error = followup_ref.editQuestionRef.handleQuestionSubmit(true);

								
							}
						}
					}
				}
				form_errors["tasks"][task.id] = task_errors;

				if(task_errors.length>0 || question_error){
					break;
				}
			}
		}
		if(!error && !question_error && conclusionRef.current){
			error = !conclusionRef.current.questionValidateForm();

			question_error = error;
		}
				

        if (error && !question_error && firsterrorid != null && accordians["firstclick"]) {
            
            document.getElementById(firsterrorid).scrollIntoView({
                behavior: "smooth",
                block: "center",
            });
        }

		
		if (error && !question_error) {
            
			setStepperError(true);

			localStorage.setItem("stepper_error","You missed some key information. Please check again and fill out any missing fields.");
            
			showError("You missed some key information. Please check again and fill out any missing fields.");

			

		}
		//console.log(form_errors)
		setFormErrors(form_errors);
		return error || question_error;
	}
	
	const saveBuildTestStep = () => {

		var error = validateFullForm();
		
		if (!error) {

			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({
				'event' : 'create_test_step2',
				'type': test.methodology,
				'userID' : ReactSession.get("user") ? ReactSession.get("user").user_id : ""
			});

			navigate("/r/test/" + test_id + "/thank-you");
			//savePreferenceTestData(formValues, true);
		}
	};
	const savePrototypeTestData = (form, shouldnavigate) => {
		if (shouldnavigate) {
			setPrototypeTestStepLoading(true);
		} else {
			
			//setLoading(true);
		}
		setIsSaved({status:"loading", message:""})

		
		let data = Object.assign({}, form);

		data["test_id"] = test_id;

		
		data["autosave"] = 1;





		if (shouldnavigate) {
			data["autosave"] = 0;
		}

		updateFirstclickTestService(data, ReactSession.get("token")).then(
			(response) => {
				setLoading(false);

				setPrototypeTestStepLoading(false);

				console.log(response)
				

				if (response.success) {

					getTestLength(test_id);

					setFormValues({
						...formValues,
						tasks:  response.test.firstclick_test_tasks.length > 0 ? response.test.firstclick_test_tasks : [{"is_synced":false, prototype_link:""}]
					});

					setIsSaved({status:"success", message:""})
					setLastSaved(moment.now());
					if (shouldnavigate) {
						window.dataLayer = window.dataLayer || [];
						window.dataLayer.push({
							'event' : 'create_test_step2',
							'type': test.methodology,
							'userID' : test.user_id
						});
						navigate("/r/test/" + test_id + "/thank-you");
					}
				} else {
					setIsSaved({status:"error", message:response.message})
                    showError(response.message);
				}
			}
		);
	};
	useEffect(() => {
		//document.title = process.env.REACT_APP_NAME + " - First click";

		//window.addEventListener('scroll', handleScroll);

		getTestApi();
        if(searchParams.get("error")=="true"){
            setStepperError(true);
        }
	}, []);
	useEffect(() => {
		
		if(formValues.validate_form){
			if(searchParams.get("error")=="true"){
				setStepperError(true);
				validateFullForm();
			}
		}
	}, [formValues]);

	useEffect(() => {
		
		if(validate_form){

			setValidateForm(false);

			if(searchParams.get("error")=="true"){
				
				setStepperError(true);
	
				validateFullForm();

				
			}
		}


	}, [validate_form]);

	useEffect(() => {
        if (nodeRef.current) {
            nodeRef.current.focus();
        }
		if(surveyQuestions.length>0){
        save5SecondsTestEveryChange(true,false);
		}
    }, [surveyQuestions, edittingSurveyQuestions]);

	const set = (name) => {
		return ({ target: { value } }) => {
			setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
		};
	};
	const setFormValue = (name, value) => {
		setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
	};
	const showFormError = (name, value) => {
		let formErrorsLocal = Object.assign({}, formErrors);
		for (var key in formErrors) {
			formErrorsLocal[key] = null;
		}
		formErrorsLocal[name] = value;
		formErrorsLocal["error_class"] = "input_error";
		setFormErrors(formErrorsLocal);
		// console.log(formErrorsLocal);
	};
	const clearFormValue = (name) => {
		setFormValues({ ...formValues, [name]: "" });

		/*setTimeout(function () {
			
		}, 1000);*/
	};
	const resetFormErrors = () => {
		let formErrorsLocal = Object.assign({}, formErrors);
		for (var key in formErrors) {
			if(key=="tasks"){
				formErrorsLocal[key] = {};
			} else {
				formErrorsLocal[key] = null;
			}
			
		}
		setFormErrors(formErrorsLocal);
	};

	
	const renderAddDesign = (e) => {
		e.preventDefault();
	};

	const deleteTask = (task_id) => {
		let token = ReactSession.get("token");

		setLoading(true);
		deleteFirstclickTestTaskService({ id: task_id, test_id: test_id }, token).then(
			(response) => {
				setLoading(false);
				if (response.success) {

					getTestLength(test_id);

					setLastSaved(moment());

					var tasks = [];

					formValues.tasks.forEach(function (item, index) {
						if (item.id != task_id) {
							var new_task = item;
							tasks.push(new_task);
						}
					});

					setFormValues({ ...formValues, tasks: tasks });

					

				} else {
					showError(response.message)
				}
			}
		);
	};

	const reorder = (list, startIndex, endIndex) => {
		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);

		return result;
	};
	
	const designTitleMissing = () => {
		var error = false;
		formValues.designs.forEach(function(item){
			if(!item.title){
				error = true;
			}
		})
		return error;
	}

	const handleDragStart = (event) => {
        const draggedDOM = getDraggedDom(event.draggableId);

        if (!draggedDOM) {
            return;
        }

        const { clientHeight, clientWidth } = draggedDOM;
        const sourceIndex = event.source.index;
        var clientY =
            parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
            [...draggedDOM.parentNode.children]
                .slice(0, sourceIndex)
                .reduce((total, curr) => {
                    const style = curr.currentStyle || window.getComputedStyle(curr);
                    const marginBottom = parseFloat(style.marginBottom);
                    return total + curr.clientHeight + marginBottom;
                }, 0);

        setPlaceholderProps({
            clientHeight,
            clientWidth,
            clientY,
            clientX: parseFloat(
                window.getComputedStyle(draggedDOM.parentNode).paddingLeft
            ),
        });
    };

    const handleDragUpdate = (event) => {
        if (!event.destination) {
            return;
        }

        const draggedDOM = getDraggedDom(event.draggableId);

        if (!draggedDOM) {
            return;
        }

        const { clientHeight, clientWidth } = draggedDOM;
        const destinationIndex = event.destination.index;

        const sourceIndex = event.source.index;

        const childrenArray = [...draggedDOM.parentNode.parentNode.children];
        const movedItem = childrenArray[sourceIndex];
        childrenArray.splice(sourceIndex, 1);

        const updatedArray = [
            ...childrenArray.slice(0, destinationIndex),
            movedItem,
            ...childrenArray.slice(destinationIndex + 1),
        ];


        var clientY =
            parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
            updatedArray.slice(0, destinationIndex).reduce((total, curr) => {
                const style = curr.currentStyle || window.getComputedStyle(curr);
                const marginBottom = parseFloat(style.marginBottom);
                return total + curr.clientHeight + marginBottom;
            }, 0);

        setPlaceholderProps({
            clientHeight,
            clientWidth,
            clientY,
            clientX: parseFloat(
                window.getComputedStyle(draggedDOM.parentNode).paddingLeft
            ),
        });
    };

    const getDraggedDom = (draggableId) => {
        const domQuery = `[${queryAttr}='${draggableId}']`;
        const draggedDOM = document.querySelector(domQuery);

        return draggedDOM;
    };

	const save5SecondsTestEveryChange = (saveUsingNavigation,taskName) => {

        if (testController.current) {
            testController.current.abort();

        }
        const controller = new AbortController();

        testController.current = controller;

        let token = ReactSession.get("token");
        if (!saveUsingNavigation) {
            setLoading(true);
        }

        setIsSaved({ 'status': 'loading', 'message': '' });
        var data = new FormData();

        data.append("test_id", test_id);
        // if (image) {
        //     data.append("task_file", image);
        // }
        // data.append("file_type", fileType);
        // set blank task name if clear input field

        if (!saveUsingNavigation) {
            data.append("saved_step", 2);
        }

        surveyQuestions.forEach((item) => {
            data.append("survey_questions[]", JSON.stringify(item));
        });

        savePreferenceTestQuestions(data, token, testController.current?.signal).then((response) => {
            setLoading(false);
            if (response.success) {

				getTestLength(test_id);
                setIsSaved({ 'status': 'success', 'message': '' });

                setLastSaved(moment.now());
                if (!saveUsingNavigation) {
                    navigate("/r/test/" + test_id + "/thank-you/");
                }
            } else {
                setIsSaved({ 'status': 'error', 'message': response.message });
            }
        });

    };

	const duplicatequestion = (index) => {
        if (!edittingSurveyQuestions) {
			if(surveyQuestions.length<3){
            let list = [...surveyQuestions];
            // let question = Object.assign({},surveyQuestions[index]);
            let question = _.cloneDeep(surveyQuestions[index]);
            // list.push(question);
            list.splice(index, 0, question);
            setSurveyQuestions(list);
			}else{
					showError("You've hit the maximum amount of follow up questions! If you need to add more, consider removing earlier ones.");
			}
        } else {
            showError("Finish editing question");
        }
    };

    const edittingQuestionsHandler = (index) => {
        if (!edittingSurveyQuestions) {
            setEdittingSurveyQuestions(true);
            setEditQuestionId(index);
        } else {
            showError("Finish editing earlier question.");
        }
    };

	const removequestion = (index) => {
        const list = [...surveyQuestions];
            list.splice(index, 1);
            setSurveyQuestions(list);
            // if (list.length == 0) {
                setAddingSurveyQuestions(true);
            // }
    };

	const toggleLogicHandler = (logicstatus, questionindex) => {
        if(questionType === 'singlechoice') {
            const surveyQuestionsEdit = [...surveyQuestions];
            surveyQuestionsEdit[questionindex]["is_logic"] = logicstatus;
            surveyQuestionsEdit[questionindex]["jumpTo"] = [];
            var jumptolist = [];
            surveyQuestionsEdit[questionindex].options.forEach(() => {
                jumptolist.push(
                    questionindex + 1 < surveyQuestionsEdit.length
                        ? questionindex + 2
                        : "End Survey"
                );
            });
            surveyQuestionsEdit[questionindex]["jumpTo"] = jumptolist;

            setSurveyQuestions(surveyQuestionsEdit);
        }else{
            const surveyQuestionsEdit = [...surveyQuestions];
            surveyQuestionsEdit[questionindex]["is_logic"] = logicstatus;
            surveyQuestionsEdit[questionindex]["jump_to_question"] = (logicstatus)?'End Survey':'';

            setSurveyQuestions(surveyQuestionsEdit);
        }
    };

	const saveOptionJumpToHandler = (optionindex, value,questionType, questionindex) => {
        // console.log(questionType)
        if(questionType === 'singlechoice') {
            // console.log(surveyQuestions[questionindex]);
            const surveyQuestionsEdit = [...surveyQuestions];
            let question = Object.assign({}, surveyQuestions[questionindex]);
            question['jumpTo'][optionindex] = value;
            surveyQuestionsEdit.splice(
                questionindex,
                1,
                question
            );
            setSurveyQuestions([
                ...surveyQuestionsEdit,
            ]);
        }else{
            const surveyQuestionsEdit = [...surveyQuestions];
            let question = Object.assign({}, surveyQuestions[questionindex]);
            question['jump_to_question'] = value;
            surveyQuestionsEdit.splice(
                questionindex,
                1,
                question
            );
            setSurveyQuestions([
                ...surveyQuestionsEdit,
            ]);
        }
    };

	const cancelQuestionHandler = () => {
        if (surveyQuestions.length != 0) {
            // setAddingSurveyQuestions(false);
        }
        setQuestionType("");
    };
    const cancelEditQuestionHandler = () => {
        setEdittingSurveyQuestions(false);
        setEditQuestionId(null);
    };

	const addquestion = (type) => {
        if (surveyQuestions.length<3) {
            setQuestionType(type);
        } else {
            showError("You've hit the maximum amount of follow up questions! If you need to add more, consider removing earlier ones.");
        }
    }; 
	const renderTasks = ()=>{
		var render_tasks = [];
		
		formValues.tasks.forEach(function(task, index){
			render_tasks.push(<Task
				ref={(element)=>{taskRefs.current[task.id] = element;}}
				setStepperError={setStepperError}
				opentestSettingModal={()=>{
					setTestSettingModal({...testSettingModal, open:true})
				}}
				language={test.language}
				openHelpModal={()=>{
					setHelpModal({...helpModal, open:true})
				}}
				changeOrder={()=>{
				setChangeOrderModal({...changeOrderModal, open:true});

			}} 
			taskErrors={formErrors["tasks"][task.id]?formErrors["tasks"][task.id]:{}} 
			total_tasks={formValues.tasks.length} key={"firstclick-test-task-"+index} 
			setConfirmModal={setConfirmModal} 
			test={test} 
			setIsSaved={setIsSaved} 
			test_id={test_id} 
			dont_ask_again={formValues.resync_confirmation==0?false:true} objective={formValues.objective} task={task} index={index}

			updateTask={(task, index)=>{
				var tasksLoc = Object.assign([], formValues.tasks);

				tasksLoc[index] = task;

				setFormValues({...formValues, tasks: tasksLoc});
			}}
			updateTaskAndDontAsk={(task, index)=>{
				var tasksLoc = Object.assign([], formValues.tasks);

				tasksLoc[index] = task;

				setFormValues({...formValues, tasks: tasksLoc, resync_confirmation:1});
			}}

		/>);
	})

		return render_tasks;
	}
	const checkValidBtnState = ()=>{
		var error = false;

		for(var i=0; i<formValues.tasks.length; i++){
			var task = formValues.tasks[i];

			if(!task.description){
				error=true;
				break;
			}
			else if(!task.design_file){
				error=true;
				break;
			}
		}

		return error;
	}
	const checkIfAddBtn = ()=>{

		let canBeAdded = true;

		for(var i = 0; i<formValues.tasks.length; i++){
			if(!formValues.tasks[i].design_file || !formValues.tasks[i].description){
				canBeAdded = false;
				break;
			}
		}

		return canBeAdded;
	}
	
	return (
		<LayoutResearcher
			isLoading={false}
			fixed_header_target={true}
			wrapClass={`create-test-data-wrapper ${test && test.language == "ar" ? "arabic_wrapper_main" : ""}`}
			skipCheck={false}
			extendedFooter={false}
			activeMenu={""}
			openSupportPanel={show_support_panel} hideSupportPanel={()=>{setShowSupportPanel(false)}}
		>
			<Helmet>
                <title>Create first click - step 2 | UserQ</title>
            </Helmet>
			<div className="three-column-layout">
				<Header
					test={test}
					isLoading={isLoading}
					isSaved={isSaved}
					credits_required={publishing_credits}
				/>
				<div className="three-column-layout-body">
					<div className="three-column-layout-left">
						<Stepper
							test={test}
							completed_step={1}
							stepCallback={() => {
								//savePreferenceTestData(formValues, false);
							}}
                            error={stepperError}
						/>
					</div>
					{pageLoading &&
						<div className="test-section-loader"> <LoadingIcon /></div>
					}
					{!pageLoading &&
						<>
							<div className="three-column-layout-center">
								<div className="create-prefer-text-wrapper create-prototype-test">
									<div className="card-sorting-creating-test-wrap">
										<div className="section-minimum-height">
											<Accordian
												title={"Introduction questions"}
												subtitle={"(Optional)"}
												tooltip={"Some cool text"}
												open={accordians['introduction'] ? true : false}
												onChange={() => {
													let new_accordians = Object.assign({}, accordians);

													for (var key in accordians) {
														if (key == "introduction") {
															if (accordians[key]) {
																new_accordians[key] = false;
															} else {
																new_accordians[key] = true;
															}
														}

													}
													setAccordians(new_accordians);
												}}
                                                questionCount={introCount}
											>
												<IntroductionQuestions ref={introductionRef} questions={introductionQuestions} test={test} setLoading={(status)=>setIsSaved(status)} changeCount={(newCount)=>{setIntroCount(newCount)}} updateQuestions={(questions)=>{setIntroductionQuestions([...questions])}}/>
											</Accordian>
											<Accordian
												title={"First click"}
												tooltip={"Some cool text"}
												open={accordians['firstclick'] ? true : false}
												onChange={() => {
													let new_accordians = Object.assign({}, accordians);

													for (var key in accordians) {
														if (key == "firstclick") {
															if (accordians[key]) {
																new_accordians[key] = false;
															} else {
																new_accordians[key] = true;
															}
														}

													}
													setAccordians(new_accordians);
												}}
											>
												
                                                
												{renderTasks()}
												{checkIfAddBtn() && 
												<div>
													<Button 
														type={"primary"}
														size={"large"}
														label="Add new task"
														iconLeft={<Icon value="add" colorClass={'gray-50-svg'} size="snall" />}
														onClick={()=>{
															
															if(formValues.tasks.length<5){
																addFirstclickTestTask();
															} else {
																showError("You cannot add more than 5 tasks.")
															}

														}}
														microLoading={addTaskLoading}
														state={formValues.tasks.length<5?"enabled":"disabled"}
													/>
												</div>
												}
											</Accordian>
											<Accordian
												title={"Conclusion questions"}
												subtitle={"(Optional)"}
												tooltip={"Some cool text"}
												open={accordians['conclusion'] ? true : false}
												onChange={() => {
													let new_accordians = Object.assign({}, accordians);

													for (var key in accordians) {
														if (key == "conclusion") {
															if (accordians[key]) {
																new_accordians[key] = false;
															} else {
																new_accordians[key] = true;
															}
														}

													}
													setAccordians(new_accordians);
												}}
                                                questionCount={concCount}
											>
												<ConclusionQuestions ref={conclusionRef} questions={conclusionQuestions} test={test} setLoading={(status)=>setIsSaved(status)} changeCount={(newCount)=>{setConcCount(newCount)}} updateQuestions={(questions)=>{setConclusionQuestions([...questions])}}/>
											</Accordian>
										</div>
										<div class="btns-wrap-bottom">
											<Button
												onClick={() => { navigate("/r/test/" + test_id + "/welcome"); }}
												type={"ghost"}
												label={"Previous"}
												size={"large"}
												iconLeft={
													<Icon value="back-arrow" colorClass="gray-900-svg" size={"large"} />
												}
											/>
											<Button
												id="r_create_step_2_build_next_btn"
												onClick={(e)=>{saveBuildTestStep()}}
												iconRight={<Icon value="forward-arrow" colorClass="gray-50-svg" />}
												label="Next"
												size={"large"}
												state={!checkValidBtnState()?"enabled":"disabled"}
												
											/>
										</div>
									</div>
								</div>
								

							</div>
							<div className="three-column-layout-right">
							<a  onClick={(e)=>{
                                    e.preventDefault();

                                    setShowSupportPanel(true);

                                }}
								href={process.env.REACT_APP_URL+"r/support/contact"} target="_blank" className='support-button'><svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.879 8.2817C11.05 7.2567 12.95 7.2567 14.121 8.2817C15.293 9.3067 15.293 10.9687 14.121 11.9937C13.918 12.1727 13.691 12.3197 13.451 12.4357C12.706 12.7967 12.001 13.4347 12.001 14.2627V15.0127M21 12.7627C21 13.9446 20.7672 15.1149 20.3149 16.2068C19.8626 17.2988 19.1997 18.2909 18.364 19.1267C17.5282 19.9624 16.5361 20.6253 15.4442 21.0776C14.3522 21.5299 13.1819 21.7627 12 21.7627C10.8181 21.7627 9.64778 21.5299 8.55585 21.0776C7.46392 20.6253 6.47177 19.9624 5.63604 19.1267C4.80031 18.2909 4.13738 17.2988 3.68508 16.2068C3.23279 15.1149 3 13.9446 3 12.7627C3 10.3757 3.94821 8.08656 5.63604 6.39873C7.32387 4.71091 9.61305 3.7627 12 3.7627C14.3869 3.7627 16.6761 4.71091 18.364 6.39873C20.0518 8.08656 21 10.3757 21 12.7627ZM12 18.0127H12.008V18.0207H12V18.0127Z" stroke="#9759CB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                        Support
                                    </a>
							</div>
						</>
					}
				</div>
			</div>
			<ConfirmationModal
				confirm_message="Are you sure you want to delete this task?"
				confirm_btn_title="Delete"
				confirm_title="Delete Task"
				confirm_title_class={"text-danger"}
				openModal={confirmModal.open}
				close={() => {
					setConfirmModal({ ...confirmModal, open: false });
				}}
				confirm={() => {
					setConfirmModal({ ...confirmModal, open: false });

					deleteTask(confirmModal.task_id);
				}}
			/>
			
			<ConfirmationModal
				confirm_message={"Are you sure you want to change the objective of the prototype test?"}
				confirm_btn_title="Proceed"
				confirm_title="Some information may be lost"
				confirm_title_class={"text-danger"}
				openModal={switchConfirmModal.open}
				close={() => {
					setSwitchConfirmModal({ ...switchConfirmModal, open: false });
				}}
				confirm={() => {
					setSwitchConfirmModal({ ...switchConfirmModal, open: false });

					setFormValues({...formValues, objective:switchConfirmModal.objective}); 
																	
					savePrototypeTestData({...formValues, objective:switchConfirmModal.objective}, false);	

					
				}}
			/>
			<ChangeOrderModal 
				objective={formValues.objective}
				tasks={formValues.tasks}
				open={changeOrderModal.open}
				rtl={test.language=="ar"?true:false}
				close={()=>{
                    setChangeOrderModal({...changeOrderModal, open:false})
                }}
				updateOrder={(tasks)=>{
					setChangeOrderModal({...changeOrderModal, open:false})
					setFormValues({...formValues, tasks: tasks});
				}}
			/>
			<HelpModal 
				open={helpModal.open}
				close={()=>{
					setHelpModal({...helpModal, open:false});
				}}
				heading={"Figma prototype criteria"}
				body={
					<div className="prototype-criteria-modal-data">
						<Text type={"body-text-2"} fontWeight={"semi-bold-font"} cssClasses="purple-color">File Access & Settings</Text>
						<ul>


							<li><span>Eliminate password protection</span> from your Figma prototype.</li>
							<li><span>Configure permissions</span> for your Figma file and prototype to enable viewing by anyone with the link.
								Otherwise, the preview of your prototype won't load, and testers won't be able to view it during
								the test. Please note that choosing "Anyone with a link [can view prototypes only]" will also
								result in an error since UserQ must have access to the file.</li>

							<li><span>Create a designated testing file.</span> Duplicate your prototype and remove all irrelevant pages, frames,
								assets, images, and elements before importing to UserQ. We advise creating separate files for
								each flow that requires testing.</li>
						</ul>
						<Text type={"body-text-2"} fontWeight={"semi-bold-font"} cssClasses="purple-color">Designs & Screens</Text>

						<ul>

							<li><span>The number of screens</span> in your prototype to speed up the tester's experience.
								It is recommended to limit the frames and pages to only what is needed for testing.</li>

							<li><span> Use separate top-level frames for each screen.</span> Components that are not within a
								frame will cause processing issues for UserQ.</li>

							<li><span> Compress images</span> within the prototype before syncing. Large embedded photos may result in
								very large file sizes, which can be challenging to load.</li>

							<li><span> Simplify custom fonts.</span> Custom fonts can increase the size of your Figma prototype,
								making it difficult to load for testers. Moreover, using a large number of
								custom fonts or large font files can negatively affect the loading times of your prototype.
								If using a custom icon font, consider converting the icons to outlines to reduce the use of
								custom fonts.</li>
						</ul>
						<Text type={"body-text-2"} fontWeight={"semi-bold-font"} cssClasses="purple-color">Interactions</Text>
						<ul>

							<li><span>Include at least two screens</span> in your Figma prototype and hotspots on the first screen linking
								to the second. Otherwise, you won't be able to import.</li>

							<li><span>Verify</span> that all necessary screens for the test are correctly linked in Figma.</li>

						</ul>
					</div>

				}
			/>
			<TestSettingsModal 
				read_mode={true}
				test={test}
				openModal={testSettingModal.open}
				updateTestDevice={(device)=>{
					var testLocal = {...test};

					testLocal.device = device;

					setTest(testLocal)

					setTestSettingModal({...testSettingModal, open:false})
				}}
				closeModal={()=>{
					setTestSettingModal({...testSettingModal, open:false})
				}}
			/>
		</LayoutResearcher>
	);
}
