import React from "react";
import { Link } from "react-router-dom";
import { ReactSession } from "../../../../lib/secure_reactsession";
import PropTypes from 'prop-types';
import { ErrorIcon } from "../ErrorIcon/ErrorIcon";
import { Text } from './../Text/Text';

export const Stepper = ({ completed_step, test, stepCallback, error }) => {
    var stepper_error = localStorage.getItem("stepper_error");

    const renderBuild = () =>{

        if(completed_step>=3){

            return <Link to={"/r/test/"+test.id+"/thank-you"}><span className="stepper-count">1</span> Build</Link>

        } else if(test && completed_step==2){

            var link = "";

            if(test.methodology=="Tree Test"){
                link = "/r/test/" + test.id + "/tree-test";
            }
            if(test.methodology=="Preference Test"){
                link = "/r/test/" + test.id + "/preference-test";
            }
            if(test.methodology=="Survey"){
                link = "/r/test/" + test.id + "/survey";
            }
            if(test.methodology=="Card Sorting"){
                link = "/r/test/" + test.id + "/card-sorting-test";
            }
            if(test.methodology=="Five Seconds Test"){
                link = "/r/test/" + test.id + "/five-seconds-test";
            }
            if(test.methodology=="Prototype test"){
                link = "/r/test/" + test.id + "/prototype-test";
            }
            if(test.methodology=="First click"){
                link = "/r/test/" + test.id + "/first-click";
            }
            

            return <Link to={link}><span className="stepper-count">1</span> Build</Link>

        } else if(completed_step==1){

            return <Link to={"/r/test/"+test.id+"/welcome"}><span className="stepper-count">1</span> Build</Link>

        }
        return <><span className="stepper-count">1</span> Build</>

    }
    return (
        <>
        {!test &&
        <div className="stepper-wrap">
            <div className="step-repeat completed-step"><span className="stepper-count">1</span> Build</div>
            <div className="step-repeat sub-pages-steps">
                <div className="sub-pages-steps-repeat active-step">
                    <Link
                        onClick={(e)=>{e.preventDefault()}}
                        to={"/r/test/1/welcome"}
                    >
                        <span className="stepper-count">&nbsp;</span> Welcome page
                        {completed_step == 0 && error && (
                            <span className="info-wrap">
                                <ErrorIcon />
                                <div className="tooltip-wrapper left-tooltip">
                                    <Text 
                                        type='body-text-2' 
                                        id='tooltip'
                                        children={stepper_error}
                                        fontWeight='normal-font' />
                                </div>
                            </span>
                        )}
                    </Link>
                </div>
                <div className={`sub-pages-steps-repeat ${completed_step >= 1 ? "active-step" : ""}`}>
                    <Link onClick={(e) => { e.preventDefault(); } } to={"/r/test/1/tree-test"}>
                        <span className="stepper-count">&nbsp;</span> Test builder
                        {completed_step == 1 && error && (
                            <span className="info-wrap">
                            <ErrorIcon />
                            <div className="tooltip-wrapper left-tooltip">
                                <Text type='body-text-2' id='tooltip'
                                    children={stepper_error}
                                    fontWeight='normal-font' />
                            </div>
                        </span>
                        )}
                    </Link>
                </div>
                <div className={`sub-pages-steps-repeat ${completed_step >= 2 ? "active-step" : ""}`}>
                    <Link
                        onClick={(e) => {
                            e.preventDefault();
                        }}
                        to={"/r/test/1/thank-you"}
                    >
                        <span className="stepper-count">&nbsp;</span> Thank you
                        {completed_step == 2 && error && (
                            <span className="info-wrap">
                            <ErrorIcon />
                            <div className="tooltip-wrapper left-tooltip">
                                <Text type='body-text-2' id='tooltip'
                                    children={stepper_error}
                                    fontWeight='normal-font' />
                            </div>
                        </span>
                        )}
                    </Link>
                </div>
            </div>

            <div className={`step-repeat ${completed_step >= 3 ? "completed-step" : ""}`}>
                <Link
                    onClick={(e) => {
                        e.preventDefault();
                    }}
                    to={"/r/test/1/recruit"}
                >
                    <span className="stepper-count">2</span> Recruit
                    {completed_step == 3 && error && (
                        <span className="info-wrap">
                        <ErrorIcon />
                        <div className="tooltip-wrapper left-tooltip">
                            <Text type='body-text-2' id='tooltip'
                                children={stepper_error}
                                fontWeight='normal-font' />
                        </div>
                    </span>
                    )}
                </Link>
            </div>
            <div className={`step-repeat ${completed_step >= 4 ? "completed-step" : ""}`}>
                <Link
                    onClick={(e) => {
                        e.preventDefault();
                    }}
                    to={"/r/test/1/publish"}
                >
                    <span className="stepper-count">3</span> Publish
                    {completed_step == 4 && error && (
                        <span className="info-wrap">
                        <ErrorIcon />
                        <div className="tooltip-wrapper left-tooltip">
                            <Text type='body-text-2' id='tooltip'
                                children={stepper_error}
                                fontWeight='normal-font' />
                        </div>
                    </span>
                    )}
                </Link>
            </div>
        </div>
        }
        {test &&
        <div className="stepper-wrap">
            <div className="step-repeat completed-step">
                {renderBuild()}
                
            </div>
            <div className="step-repeat sub-pages-steps">
                <div className="sub-pages-steps-repeat active-step">
                    <Link
                        onClick={(e) => {
                            if (stepCallback) {
                                stepCallback();
                            }
                        }}
                        to={"/r/test/" + test.id + "/welcome"}
                    >
                        <span className="stepper-count">&nbsp;</span> Welcome page
                        {completed_step == 0 && error && (
                            <span className="info-wrap">
                                <ErrorIcon />
                                <div className="tooltip-wrapper left-tooltip">
                                    <Text 
                                        type='body-text-2' 
                                        id='tooltip'
                                        children={stepper_error}
                                        fontWeight='normal-font' />
                                </div>
                            </span>
                        )}
                    </Link>
                </div>
                <div className={`sub-pages-steps-repeat ${completed_step >= 1 ? "active-step" : ""}`}>
                    {!test ?
                        ((ReactSession.get("methodology")) === 'Survey')
                            ?
                            <Link onClick={(e) => { e.preventDefault() }} to={"/"}>
                                <span className="stepper-count">&nbsp;</span> Test builder
                                {completed_step == 1 && error && (
                                    <span className="info-wrap">
                                    <ErrorIcon />
                                    <div className="tooltip-wrapper left-tooltip">
                                        <Text type='body-text-2' id='tooltip'
                                            children={stepper_error}
                                            fontWeight='normal-font' />
                                    </div>
                                </span>
                                )}
                            </Link>
                            :
                            <Link onClick={(e) => { e.preventDefault() }} to={"/"}>
                                <span className="stepper-count">&nbsp;</span> Test builder
                                {completed_step == 1 && error && (
                                    <span className="info-wrap">
                                    <ErrorIcon />
                                    <div className="tooltip-wrapper left-tooltip">
                                        <Text type='body-text-2' id='tooltip'
                                            children={stepper_error}
                                            fontWeight='normal-font' />
                                    </div>
                                </span>
                                )}
                            </Link>
                        :
                        <></>
                    }
                    {test.methodology == "Tree Test" &&
                        <Link onClick={(e) => { if (stepCallback) { stepCallback(); } }} to={"/r/test/" + test.id + "/tree-test"}>
                            <span className="stepper-count">&nbsp;</span> Test builder
                            {completed_step == 1 && error && (
                                <span className="info-wrap">
                                <ErrorIcon />
                                <div className="tooltip-wrapper left-tooltip">
                                    <Text type='body-text-2' id='tooltip'
                                        children={stepper_error}
                                        fontWeight='normal-font' />
                                </div>
                            </span>
                            )}
                        </Link>
                    }
                    {test.methodology == "Preference Test" &&
                        <Link onClick={(e) => { if (stepCallback) { stepCallback(); } }} to={"/r/test/" + test.id + "/preference-test"}>
                            <span className="stepper-count">&nbsp;</span> Test builder
                            {completed_step == 1 && error && (
                                <span className="info-wrap">
                                <ErrorIcon />
                                <div className="tooltip-wrapper left-tooltip">
                                    <Text type='body-text-2' id='tooltip'
                                        children={stepper_error}
                                        fontWeight='normal-font' />
                                </div>
                            </span>
                            )}

                        </Link>
                    }
                    {test.methodology == "Card Sorting" &&
                        <Link onClick={(e) => { if (stepCallback) { stepCallback(); } }} to={"/r/test/" + test.id + "/card-sorting-test"}>
                            <span className="stepper-count">&nbsp;</span> Test builder
                            {completed_step == 1 && error && (
                                <span className="info-wrap">
                                <ErrorIcon />
                                <div className="tooltip-wrapper left-tooltip">
                                    <Text type='body-text-2' id='tooltip'
                                        children={stepper_error}
                                        fontWeight='normal-font' />
                                </div>
                            </span>
                            )}
                        </Link>
                    }
                    {test.methodology == "Survey" &&
                        <Link onClick={(e) => { if (stepCallback) { stepCallback(); } }} to={"/r/test/" + test.id + "/survey"}>
                            <span className="stepper-count">&nbsp;</span> Test builder
                            {completed_step == 1 && error && (
                                <span className="info-wrap">
                                <ErrorIcon />
                                <div className="tooltip-wrapper left-tooltip">
                                    <Text type='body-text-2' id='tooltip'
                                        children={stepper_error}
                                        fontWeight='normal-font' />
                                </div>
                            </span>
                            )}
                        </Link>
                    }

                    {test.methodology == "Five Seconds Test" &&
                        <Link onClick={(e) => { if (stepCallback) { stepCallback(); } }} to={"/r/test/" + test.id + "/five-seconds-test"}>
                            <span className="stepper-count">&nbsp;</span> Test builder
                            {completed_step == 1 && error && (
                                <span className="info-wrap">
                                <ErrorIcon />
                                <div className="tooltip-wrapper left-tooltip">
                                    <Text type='body-text-2' id='tooltip'
                                        children={stepper_error}
                                        fontWeight='normal-font' />
                                </div>
                            </span>
                            )}
                        </Link>
                    }
                    {test.methodology == "Prototype test" &&
                        <Link onClick={(e) => { if (stepCallback) { stepCallback(); } }} to={"/r/test/" + test.id + "/prototype-test"}>
                            <span className="stepper-count">&nbsp;</span> Test builder
                            {completed_step == 1 && error && (
                                <span className="info-wrap">
                                <ErrorIcon />
                                <div className="tooltip-wrapper left-tooltip">
                                    <Text type='body-text-2' id='tooltip'
                                        children={stepper_error}
                                        fontWeight='normal-font' />
                                </div>
                            </span>
                            )}
                        </Link>
                    }
                    {test.methodology == "First click" &&
                        <Link onClick={(e) => { if (stepCallback) { stepCallback(); } }} to={"/r/test/" + test.id + "/first-click"}>
                            <span className="stepper-count">&nbsp;</span> Test builder
                            {completed_step == 1 && error && (
                                <span className="info-wrap">
                                <ErrorIcon />
                                <div className="tooltip-wrapper left-tooltip">
                                    <Text type='body-text-2' id='tooltip'
                                        children={stepper_error}
                                        fontWeight='normal-font' />
                                </div>
                            </span>
                            )}
                        </Link>
                    }
                    {/* <span className="stepper-count">&nbsp;</span> Test builder */}
                </div>
                <div className={`sub-pages-steps-repeat ${completed_step >= 2 ? "active-step" : ""}`}>
                    <Link
                        onClick={(e) => {
                            if (stepCallback) {
                                stepCallback();
                            }
                        }}
                        to={"/r/test/" + test.id + "/thank-you"}
                    >
                        <span className="stepper-count">&nbsp;</span> Thank you
                        {completed_step == 2 && error && (
                            <span className="info-wrap">
                            <ErrorIcon />
                            <div className="tooltip-wrapper left-tooltip">
                                <Text type='body-text-2' id='tooltip'
                                    children={stepper_error}
                                    fontWeight='normal-font' />
                            </div>
                        </span>
                        )}
                    </Link>
                </div>
            </div>

            <div className={`step-repeat ${completed_step >= 3 ? "completed-step" : ""}`}>
                <Link
                    onClick={(e) => {
                        if (stepCallback) {
                            stepCallback();
                        }
                    }}
                    to={"/r/test/" + test.id + "/recruit"}
                >
                    <span className="stepper-count">2</span> Recruit
                    {completed_step == 3 && error && (
                        <span className="info-wrap">
                        <ErrorIcon />
                        <div className="tooltip-wrapper left-tooltip">
                            <Text type='body-text-2' id='tooltip'
                                children={stepper_error}
                                fontWeight='normal-font' />
                        </div>
                    </span>
                    )}
                </Link>
            </div>
            <div className={`step-repeat ${completed_step >= 4 ? "completed-step" : ""}`}>
                <Link
                    onClick={(e) => {
                        if (stepCallback) {
                            stepCallback();
                        }
                    }}
                    to={"/r/test/" + test.id + "/publish"}
                >
                    <span className="stepper-count">3</span> Publish
                    {completed_step == 4 && error && (
                        <span className="info-wrap">
                        <ErrorIcon />
                        <div className="tooltip-wrapper left-tooltip">
                            <Text type='body-text-2' id='tooltip'
                                children={stepper_error}
                                fontWeight='normal-font' />
                        </div>
                    </span>
                    )}
                </Link>
            </div>
        </div>
        }
        </>
    );
};

Stepper.propTypes = {
    completed_step: PropTypes.number,

};
Stepper.defaultProps = {
    completed_step: 0,

};