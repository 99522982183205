import React, { useEffect, useState } from "react";
import Layout from "./layouts/layout";
import { useNavigate, Link } from "react-router-dom";
import { Logo } from "./themes/userq/Logo/Logo";
import { Footer } from "./themes/userq/Footer/Footer";
import { Icon } from "./themes/userq/Icon/Icon";
import { Text } from "./themes/userq/Text/Text";
import { ReactSession } from "../lib/secure_reactsession";
import { Button } from "./themes/userq/Button/Button";


export default function PageNotFound() {

	const [counter, setCounter] = useState(10);
	const navigate = useNavigate();

	useEffect(() => {

		document.title = process.env.REACT_APP_NAME + " - 404 error";
	}, []);

	useEffect(() => {
		// set time
		function tickTime() {
			if (counter === 0) {
				navigate('/');

			} else {
				setCounter(prevSeconds => prevSeconds - 1)
			}

		}
		let timeData = setInterval(() => tickTime(), 1000)

		return () => clearInterval(timeData);

	}, [counter]);


	return (
		<div className="login-page-wrapper 404-page-error page-not-find">




			<div className="login-page-wrapper 404-err0r-wrap">


				<div className="error-inner-404">
					<div className="login-data-wrap flex-wrap already-taken-testlogin-wrap error-404">
						<div className="container">
							<Link to={'/'} className="logo">

								<Logo theme={"white"} background={"white"} />
							</Link>

							<div className="not-found-page-text">
								<Icon size={'large-icon'} value={"404"} />
								<Text type={"subtitle-1"} fontWeight="medium-font">
									It looks like this page doesn’t exist.

								</Text>
								<Button
									type={"primary"}
									size={"large"}
									iconRight={<Icon value={'forward-arrow'} />}
									label={"Back to home"}
									onClick={() => {
										navigate("/")
									}}
								/>
							</div>



							<div className={'error-page-footer-wrap d-flex text-center'}>

								<Text type={"body-text-3"} fontWeight="medium-font">
									You wll be redirected back to the homepage in:

								</Text>

								<span className="error-timer-hold">
									<svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
										<path d="M13 6V14H19M25 13C25 14.5759 24.6896 16.1363 24.0866 17.5922C23.4835 19.0481 22.5996 20.371 21.4853 21.4853C20.371 22.5996 19.0481 23.4835 17.5922 24.0866C16.1363 24.6896 14.5759 25 13 25C11.4241 25 9.86371 24.6896 8.4078 24.0866C6.95189 23.4835 5.62902 22.5996 4.51472 21.4853C3.40042 20.371 2.5165 19.0481 1.91345 17.5922C1.31039 16.1363 1 14.5759 1 13C1 9.8174 2.26428 6.76516 4.51472 4.51472C6.76516 2.26428 9.8174 1 13 1C16.1826 1 19.2348 2.26428 21.4853 4.51472C23.7357 6.76516 25 9.8174 25 13Z" stroke="#676767" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
									</svg>

									<Text type={"h4"} fontWeight="medium-font">
										0:{String(counter).padStart(2, "0")}

									</Text>
								</span>
							</div>
						</div>

					</div>
				</div>
			</div>

			<Footer />
		</div>
	);
}