import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
//import { ReactSession } from "react-client-session";
import { ReactSession } from "../../../lib/secure_reactsession";
import LayoutResearcherFullWidth from "../../layouts/layout_researcher_full_width";
import { LayoutResearcher } from '../../themes/userq/Layouts/layout_researcher';
import { useState, useEffect, useRef } from "react";
import {
	getResearcherTestResultData,
	getTestParicipantsData,
} from "../../../services/researcher_test_results";
import toast, { LoaderIcon } from "react-hot-toast";
import Topbar from "./components/topbar";
import Sidebar from "./components/sidebar";
import { getCurrentPlansService } from "../../../services/plans";
import moment from "moment";
import PieChart from "./components/pie_chart";
import NationalitiesFlags from "./components/nationalities_flags";
import BarChart from "./components/bar_chart";
import BubbleChart from "./components/bubble_chart";
import Checkbox from "../../layouts/elements/fields/checkbox";
import AreaChart from "./components/area_chart";
import {
	atom,
	useRecoilState,
} from 'recoil';
import { LoadingIcon } from "../../loader/loadingIconNew";
import { propTypes } from "react-bootstrap/esm/Image";
import { TestResultsHeader } from '../../themes/userq/TestResultsHeader/TestResultsHeader';
import { TestResultsSidebarNavigation } from './components/test_results_sidebar_navigation';
import { PieStatsBox } from "./components/pie_stats_box";
import { CirclesGroupStatsBox } from "./components/circles_group_stats_box";
import { HorizontalBarChartStatsBox } from "./components/horizontal_bar_chart_stats_box";
import { DevicesStatsBox } from "./components/devices_stats_box";
import { Text } from "../../themes/userq/Text/Text";
import { VerticalBarChartStatsBox } from "./components/vertical_bar_chart_stats_box";
import { AppsStatsBox } from "./components/apps_stats_box";
import { Icon } from "../../themes/userq/Icon/Icon";
import { Box } from "../../themes/userq/Box/Box";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { NoParticipants } from "./components/no_participants";
import { Button } from '../../themes/userq/Button/Button.js';
import { ParticipantsNotAvailable } from "./components/participants_not_available";
import { HorizontalBarChartResidencyStatsBox } from "./components/horizontal_bar_chart_residency_stats_box";
import { Helmet } from "react-helmet";
import { renderTestTitle } from "../../../lib/helpers";


export default function TestResultParticipants({isSharedLink}) {
	let { test_id } = useParams();
	const navigate = useNavigate();
	const parRef = useRef();
	
	const location = useLocation();

	const [isLoading, setLoading] = useState(false);

	const [sectionLoading, setSectionLoading] = useState(false);
	const [showParticipantNotification, setShowParticipantNotification] = useState(true);

	const testState = atom({
		key: 'testresult-' + test_id,
		default: ""
	});
	const planState = atom({
		key: 'testresultplan-' + test_id,
		default: ""
	});
	const partiState = atom({
		key: 'testresultparti-' + test_id,
		default: null
	});
	const gender_colors = ["rgb(119, 225, 254)", "rgb(85, 86, 111)", "rgb(194, 194, 210)", "rgb(47, 46, 85)", "rgb(255, 102, 173)", "rgb(150, 255, 252)", "rgb(150, 155, 255)", "rgb(130, 255, 252)"];

	const age_colors = ["rgb(47, 46, 85)", "rgb(255, 102, 173)", "rgb(130, 255, 252)", "rgb(150, 155, 255)", "rgb(119, 225, 254)", "rgb(85, 86, 111)", "rgb(150, 255, 252)", "rgb(194, 194, 210)"];

	const tech_colors = ["rgb(150, 255, 252)", "rgb(47, 46, 85)", "rgb(255, 102, 173)", "rgb(194, 194, 210)", "rgb(85, 86, 111)", "rgb(119, 225, 254)", "rgb(150, 155, 255)", "rgb(130, 255, 252)"];

	const [test, setTest] = useRecoilState(testState);
	const [planType, setPlanType] = useRecoilState(planState);
	const [parti, setParti] = useRecoilState(partiState);
	const [term, setTerm] = useState("Monthly");
	const [collapse, setCollapse] = useState({ "apps": false, "nationalities": false, "residency": false, "education": false, "technology": false, "employment": false, "industry": false, "department": false, "household": false })

	const slides = [{ "title": "Sharing research findings", "subtitle": "87% of researchers share their research results in live presentations" }, { "title": "Sharing research findings", "subtitle": "71% of publishers say content that looks well-organized on mobile devices boosts positive user experience feedback." }, { "title": "Sharing research findings", "subtitle": "3.42 seconds is the time needed for users to judge a web site’s credibility merely on the basis of its aesthetic appeal" }]
	const [show_support_panel, setShowSupportPanel] = useState(false);

	const getParticipants = () => {

		//if (!parti) {
		let token = ReactSession.get("token");

		setSectionLoading(true);

		let guest_result_token = '';

		if(location.pathname.substr(1, 1) === 'v'){

			// create guest_result_token
			if (!ReactSession.get("guest_result_token")) {
				const randomToken = Math.random().toString(36).substr(2, 15);
				ReactSession.set("guest_result_token", randomToken);
			}

			guest_result_token = ReactSession.get("guest_result_token");
		}

		getTestParicipantsData({ test_id: test_id, guest_result_token:guest_result_token }, token).then((response) => {
			setSectionLoading(false);

			if (response.success) {
				setParti(response.data);
			} else {
				toast(
					<div className="toastinner">
						{response.message}
						<a
							onClick={() => {
								toast.dismiss();
							}}
						>
							&times;
						</a>
					</div>,
					{
						className: response.success ? "successtoast" : "errortoast",
						position: "bottom-center",
						duration: 2000,
					}
				);
			}
		});
		//}
	};

	const getTestafterEndTest = () => {

		let token = ReactSession.get("token");

		getResearcherTestResultData({ test_id: test_id,guest_result_token:'' }, token).then((response) => {
			setLoading(false);

			if (response.success) {
				setTest(response.test);
			} else {
				navigate("/dashboard");
				toast(
					<div className="toastinner">
						{response.message}
						<a onClick={() => {
							toast.dismiss();
						}}>&times;</a>
					</div>,
					{
						className: (response.success) ? "successtoast" : "errortoast",
						position: "bottom-center",
						duration: 2000,
					}
				);
			}
		})

	}

	const getTest = () => {

		if (!test) {
			
			let token = ReactSession.get("token");

			setLoading(true);

			let guest_result_token = '';

            if(location.pathname.substr(1, 1) === 'v'){

                // create guest_result_token
                if (!ReactSession.get("guest_result_token")) {
                    const randomToken = Math.random().toString(36).substr(2, 15);
                    ReactSession.set("guest_result_token", randomToken);
                }

                guest_result_token = ReactSession.get("guest_result_token");
            }

			getResearcherTestResultData({ test_id: test_id, guest_result_token:guest_result_token }, token).then(
				(response) => {
					setLoading(false);

					if (response.success) {

						// check test is available for sharing
						if(location.pathname.substr(1, 1) === 'v' && response.is_share_result){
							navigate(`/v/results/${test_id}/results-not-available`);
						}


						// check if guest user not validate there password
						if(location.pathname.substr(1, 1) === 'v' && response.test.password && !response.test_password_verify){

							navigate(`/v/results/${test_id}/password`);
						}

						setTest(response.test);

						getParticipants();
					} else {
						toast(
							<div className="toastinner">
								{response.message}
								<a
									onClick={() => {
										toast.dismiss();
									}}
								>
									&times;
								</a>
							</div>,
							{
								className: response.success ? "successtoast" : "errortoast",
								position: "bottom-center",
								duration: 2000,
							}
						);
					}
				}
			);
		} else {
			getParticipants();
		}
	};

	const getPlan = () => {

		if (!planType) {
			let token = ReactSession.get("token");

			setLoading(true);

			getCurrentPlansService(token).then((response) => {
				setLoading(false);

				if (response.success) {
					setPlanType(response.plan.type);

					getTest();
				} else {
					toast(
						<div className="toastinner">
							{response.message}
							<a
								onClick={() => {
									toast.dismiss();
								}}
							>
								&times;
							</a>
						</div>,
						{
							className: response.success ? "successtoast" : "errortoast",
							position: "bottom-center",
							duration: 2000,
						}
					);
				}
			});
		} else {
			getTest();
		}
	};

	useEffect(() => {
		//document.title = process.env.REACT_APP_NAME + " - Test Result Participants";
		getTest();
	}, []);
	const renderTechnology = () => {

		var technology = [];

		for (var key in parti.hours) {

			technology.push(parti.hours[key])


		}
		for (var key in parti.devices) {

			technology.push(parti.devices[key]);


		}
		if (technology.length > 0) {

			return (
				<div className="confidence-with-tch-graph-wrap">
					<div className="confid-tech-devices-sec">
						<p className="confidance-heading-wrap">
							Favourite devices
						</p>

						<div className="fav-devices-list-wrap">
							{renderDevices()}

						</div>
					</div>
					<div className="confid-tech-graph-lhs">
						<p className="confidance-heading-wrap">
							AVERAGE TIME SPENT ONLINE PER DAY
						</p>

						<div className="confid-tech-hraph-hold">
							{renderHoursPie()}
						</div>
					</div>
				</div>);
		} else {
			return <div className="info-not-available">No info available</div>
		}

	}
	const renderHoursPie = () => {

		var hours = [];



		for (var key in parti.hours) {

			hours.push(parti.hours[key])


		}
		if (hours.length > 0) {
			return <>
				<PieChart txtcolors={{ "1-3 hours": "black", "4-6 hours": "white", "more than 6 hours": "black", "Unknown": "black" }} colors={{ "1-3 hours": tech_colors[0], "4-6 hours": tech_colors[1], "more than 6 hours": tech_colors[2], "Unknown": tech_colors[3] }} data={parti.hours} width={300} height={300} animate={true} />
				<div className="chart-info-wrap">
					<div className="chart-info-repeat">
						<span style={{ "display": "inline-block", "backgroundColor": tech_colors[0] }}></span>
						<p>1-3 hours</p>
					</div>
					<div className="chart-info-repeat">
						<span style={{ "display": "inline-block", "backgroundColor": tech_colors[1] }}></span>
						<p>4-6 hours</p>
					</div>
					<div className="chart-info-repeat">
						<span style={{ "display": "inline-block", "backgroundColor": tech_colors[2] }}></span>
						<p>more than 6 hours</p>
					</div>
					<div className="chart-info-repeat">
						<span style={{ "display": "inline-block", "backgroundColor": tech_colors[3] }}></span>
						<p>Unknown</p>
					</div>
				</div>
			</>;
		} else {
			return <div className="info-not-available">No info available</div>
		}
	}
	const renderDevices = () => {
		var devices = [];

		var index = 0;

		for (var key in parti.devices) {

			devices.push((
				<div key={"device" + key} className={`device-list-repeat ${index == 0 ? 'active' : ''} `}>
					<div className="tech-icon-wname">
						{renderDeviceIcon(key)}
						<span>{key}</span>
					</div>
					<div className="device-selected-count">{parti.devices[key]}</div>
				</div>
			))
			index++;

		}

		if (devices.length > 0) {
			return devices;
		} else {
			return <div className="info-not-available">No info available</div>
		}
	}
	const renderDeviceIcon = (device) => {
		if (device == "Desktop") {
			return <img src={process.env.REACT_APP_URL + "images/desk-top-icon.svg"} alt="img" />
		}
		if (device == "Mobile") {
			return <img src={process.env.REACT_APP_URL + "images/mob-icon.svg"} alt="img" />
		}
		if (device == "Smartphone") {
			return <img src={process.env.REACT_APP_URL + "images/mob-icon.svg"} alt="img" />
		}
		if (device == "Tablet") {
			return <img src={process.env.REACT_APP_URL + "images/tab-licon.svg"} alt="img" />
		}
		if (device == "Unknown") {
			return <img src={process.env.REACT_APP_URL + "images/unknown-icon.svg"} alt="img" />
		}
	}
	const renderBarChart = () => {
		var lChartData = [];

		var index = 0;

		var data = parti.education;

		//var data = {"A":1,"B":2,"C":3,"D":4,"E":4,"F":8,"G":8,"H":8};

		var colors = [
			{ color: "rgb(119, 225, 254)", text: "" },
			{ color: "rgb(89, 89, 114)", text: "white-text" },
			{ color: "rgb(150, 255, 252)", text: "" },
			{ color: "rgb(47, 46, 85)", text: "white-text" },
			{ color: "rgb(255, 102, 173)", text: "white-text" },
			{ color: "rgb(165, 159, 255)", text: "" },
			{ color: "rgb(194, 194, 210)", text: "" }
		];

		var max = 0;
		for (var key in data) {

			if (data[key] > max) {
				max = data[key];
			}
		}
		var sum = 0;
		for (var key in data) {

			sum += data[key];
		}
		var bars = [];

		for (var key in data) {
			var percentage = max > 0 ? Math.round(((data[key] / max) * 100) * 100) / 100 : 0;
			var percentage_label = sum > 0 ? Math.round(((data[key] / sum) * 100) * 100) / 100 : 0;
			var color = colors[6];
			if (index < 6) {

				color = colors[index];
			}

			lChartData.push({
				label: key,
				value: data[key],
				color: color,
				percentage_label: percentage_label + "%"
			});
			index++;

			bars.push((
				<div className="edu-graph-data-repeat">
					<div className={`edu-grph-data-lhs ${key=="Unknown"?'unknown-label':''}`}>
						<span>{key}</span>
						<b>-</b>
					</div>
					<div className={`edu-grph-data-rhs `}>
						<div class={`edulabel-width-wrap ${percentage <= 30 ? 'small-width-chart-area' : ''} ${percentage >= 1 ? 'small-width-chart-area-1' : ''}`}
							style={{ width: percentage + "%" }}
						>
							<div
								flow="down"
								className={`edu-graph-count ${percentage == 0 ? 'p-0' : ''}`}
								tooltip={data[key] > 1 ? data[key] + " Participants" : data[key] + ' Participant'}
								style={{ backgroundColor: color.color }}
							>

							</div>
							<span className={`${data[key] != 0 ? color.text : ''}`}>{percentage_label + "%"}</span>
						</div>
					</div>
				</div>
			))
		}
		if (lChartData.length > 0) {
			return <div className="edu-label-graph-bg industry-graph"><div className="edu-graph-inner-hold"> {bars}</div></div>;
			//<BarChart data={lChartData} width={300} height={300} />
		} else {
			return <div className="info-not-available">No info available</div>
		}
	}
	const renderEmploymentBarChart = () => {
		var lChartData = [];

		var index = 0;

		var data = parti.employment;

		//var data = {"A":1,"B":2,"C":3,"D":4,"E":4,"F":8,"G":8,"H":8};

		var colors = [
			{ color: "rgb(255, 183, 183)", text: "" },
			{ color: "rgb(119, 225, 254)", text: "" },
			{ color: "rgb(89, 89, 114)", text: "white-text" },
			{ color: "rgb(150, 255, 252)", text: "" },
			{ color: "rgb(47, 46, 85)", text: "white-text" },
			{ color: "rgb(255, 102, 173)", text: "white-text" },
			{ color: "rgb(165, 159, 255)", text: "" },
			{ color: "rgb(194, 194, 210)", text: "" }
		];


		var max = 0;
		for (var key in data) {

			if (data[key] > max) {
				max = data[key];
			}
		}
		var sum = 0;
		for (var key in data) {

			sum += data[key];

		}
		var bars = [];

		for (var key in data) {
			var percentage = max > 0 ? Math.round(((data[key] / max) * 100) * 100) / 100 : 0;
			var percentage_label = sum > 0 ? Math.round(((data[key] / sum) * 100) * 100) / 100 : 0;

			var color = colors[7];
			if (index <= 7) {

				color = colors[index];
			}

			lChartData.push({
				label: key,
				value: data[key],
				color: color.color,
				textcolor: color.text,
				percentage_label: percentage + "%"
			});

			index++;

			bars.push((
				<div className="edu-graph-data-repeat">
					<div className="edu-grph-data-lhs">
						<span>{key}</span>
						<b>-</b>
					</div>
					<div className={`edu-grph-data-rhs `}>
						<div class={`edulabel-width-wrap ${percentage <= 30 ? 'small-width-chart-area' : ''} ${percentage >= 1 ? 'small-width-chart-area-1' : ''}`}
							style={{ width: percentage + "%" }}
						>
							<div
								className={`edu-graph-count ${percentage == 0 ? 'p-0' : ''}`}
								flow="down"
								tooltip={data[key] > 1 ? data[key] + " Participants" : data[key] + ' Participant'}
								style={{ backgroundColor: color.color }}
							>

							</div>

							<span className={`${data[key] != 0 ? color.text : ''}`}>{percentage_label + "%"}</span>
						</div>
					</div>
				</div>
			))
		}
		if (lChartData.length > 0) {
			return <div className="edu-label-graph-bg"><div className="edu-graph-inner-hold"> {bars}</div></div>;

		} else {
			return <div className="info-not-available">No info available</div>
		}
	}
	const renderPeopleBarChart = () => {
		var lChartData = [];

		var index = 0;

		var data = parti.people;

		var colors = [
			{ color: "rgb(119, 225, 254)", text: "" },
			{ color: "rgb(89, 89, 114)", text: "white-text" },
			{ color: "rgb(150, 255, 252)", text: "" },
			{ color: "rgb(47, 46, 85)", text: "white-text" },
			{ color: "rgb(255, 102, 173)", text: "white-text" },
			{ color: "rgb(165, 159, 255)", text: "" },
			{ color: "rgb(194, 194, 210)", text: "" }
		];


		var max = 0;
		for (var key in data) {

			if (data[key] > max) {
				max = data[key];
			}
		}
		var sum = 0;
		for (var key in data) {

			sum += data[key];
		}

		var bars = [];

		for (var key in data) {
			var percentage = max > 0 ? Math.round(((data[key] / max) * 100) * 100) / 100 : 0;

			var percentage_label = sum > 0 ? Math.round(((data[key] / sum) * 100) * 100) / 100 : 0;

			var color = colors[6];
			if (index < 6) {

				color = colors[index];
			}

			lChartData.push({
				label: key,
				value: data[key],
				color: color,
				percentage_label: percentage + "%"
			});

			index++;

			bars.push((
				<div className="edu-graph-data-repeat">
					<div className="edu-grph-data-lhs">
						<span>{key}</span>
						<b>-</b>
					</div>
					<div className={`edu-grph-data-rhs `}>
						<div class={`edulabel-width-wrap ${percentage <= 30 ? 'small-width-chart-area' : ''} ${percentage >= 1 ? 'small-width-chart-area-1' : ''}`}
							style={{ width: percentage + "%" }}
						>
							<div
								className={`edu-graph-count ${percentage == 0 ? 'p-0' : ''}`}
								flow="down"
								tooltip={data[key] > 1 ? data[key] + " Participants" : data[key] + ' Participant'}
								style={{ backgroundColor: color.color }}
							>

							</div>

							<span className={`${data[key] != 0 ? color.text : ''}`}>{percentage_label + "%"}</span>
						</div>
					</div>
				</div>
			))
		}
		if (lChartData.length > 0) {
			return <div className="edu-label-graph-bg"><div> {bars}</div></div>;
			//<BarChart data={lChartData} width={300} height={300} />
		} else {
			return <div className="info-not-available">No info available</div>
		}
	}
	const renderIndustryBarChart = () => {
		var lChartData = [];

		var index = 0;

		var data = parti.industry;

		var colors = ["rgb(119, 225, 254)", "rgb(85, 86, 111)", "rgb(150, 255, 252)", "rgb(47, 46, 85)", "rgb(255, 102, 173)", "rgb(194, 194, 210)", "rgb(150, 155, 255)"];


		var max = 0;
		for (var key in data) {

			if (data[key] > max) {
				max = data[key];
			}
		}
		var sum = 0;
		for (var key in data) {

			sum += data[key];
		}
		var bars = [];

		for (var key in data) {
			var percentage_label = sum > 0 ? Math.round(((data[key] / sum) * 100)) : 0;
			var color = colors[6];
			if (index < 6) {

				color = colors[index];
			}

			lChartData.push({
				label: key,
				percentage: percentage_label,
				value: data[key],
				color: color,
				percentage_label: percentage_label + "%"
			});

			index++;

		}
		if (lChartData.length > 0) {
			return <div className="edu-label-graph-bg"><BarChart data={lChartData} /></div>;

		} else {
			return <div className="info-not-available">No info available</div>
		}
	}
	const renderIncomeBarChart = () => {
		var lChartData = [];

		var index = 0;

		var data = parti.income;

		var colors = ["rgb(119, 225, 254)", "rgb(85, 86, 111)", "rgb(150, 255, 252)", "rgb(47, 46, 85)", "rgb(255, 102, 173)", "rgb(194, 194, 210)", "rgb(150, 155, 255)"];


		var max = 0;
		for (var key in data) {

			if (data[key] > max) {
				max = data[key];
			}
		}
		var sum = 0;
		for (var key in data) {

			sum += data[key];
		}
		var bars = [];

		for (var key in data) {
			var percentage_label = sum > 0 ? Math.round(((data[key] / sum) * 100)) : 0;
			var color = colors[6];
			if (index < 6) {

				color = colors[index];
			}

			lChartData.push({
				label: key,
				percentage: percentage_label,
				value: data[key],
				color: color,
				percentage_label: percentage_label + "%"
			});

			index++;

		}
		if (lChartData.length > 0) {
			return <div className="edu-label-graph-bg"><BarChart marginLeft={30} data={lChartData} /></div>;

		} else {
			return <div className="info-not-available">No info available</div>
		}
	}
	const renderDepartments = () => {
		var departments = [];

		var classes = {
			"Finance": "finance", "HR": "hr-depart", "Admin": "admin-depart", "Design & research": "ds-depart", "IT & tech": "it-tech-depart", "Branding & Marketing": "bm-depart",
			"Sales": "sales-depart", "Purchase": "purchase-depart", "Legal": "legal-depart", "Others": "other-depart", "Unknown": ""
		};
		var index = 0;
		for (var key in parti.department) {
			var bigClass = '';

			if (index == 0) {
				bigClass = 'active';
			}
			departments.push((
				<div className={'na-name-wrap ' + bigClass + ' ' + classes[key]}>
					<span className="flag-hold"></span>
					<p className={`country-name-txt ${key.length > 8 ? 'ellipsText' : ''}`} tooltip={`${key.length > 8 ? key : ''}`}>
						<span>{key}</span>
					</p>
					<span className="na-user-count">{parti.department[key]}</span>
				</div>
			));
			index++;
		}
		if (departments.length > 0) {
			return <div className="nationalities-list-wrap department-selected-analyze">{departments}</div>;
			//<BarChart data={lChartData} width={300} height={300} />
		} else {
			return <div className="info-not-available">No info available</div>
		}
	}
	const renderAppsBubbleChart = () => {

		var lChartData = [];

		var index = 0;

		var data = parti.apps;

		//var data = {"Shooping":9,"News":5,"Gamees":4};

		var colors = ["rgb(255, 102, 173)", "rgb(244, 158, 204)", "rgb(243, 160, 206)", "rgb(243, 160, 206)", "rgb(244, 159, 205)", "rgb(252, 117, 182)", "rgb(249, 131, 189)"];



		for (var key in data) {
			var color = colors[6];
			if (index < 6) {

				color = colors[index];

			}
			lChartData.push({
				label: key,
				value: data[key],
				color: color
			});
			index++;

		}
		if (lChartData.length > 0) {
			return (
				<div className="most-usedapp-graph-wrap"><BubbleChart data={{ "children": lChartData }} width={700} height={700} /></div>
			);
			//<BarChart data={lChartData} width={300} height={300} />
		} else {
			return <div className="info-not-available">No info available</div>
		}
	}
	const renderNationalities = () => {


		if (parti.nationalities && parti.nationalities.length > 0) {
			return <div className="nationalities-list-wrap"><NationalitiesFlags data={parti.nationalities} /></div>
		} else {
			return <div className="info-not-available">No info available</div>
		}
	}
	const renderAreaChart = () => {

		if ((parti.income && parti.income[term])) {

			var lChartData = parti.income[term];

			var data = [];

			data = [];

			//lChartData = [0,2,3,5,6,7,7,8,9,10];

			//lChartData.sort();

			let mean = getMean(lChartData);
			let stdDev = getStandardDeviation(lChartData);

			//getStdDeviation(Math.min(...lChartData), Math.max(...lChartData));

			//stdDev = 2;
			let points = lChartData;

			let seriesData = points.map(x => ({ x: x, y: normalY(x, mean, stdDev) }));



			let lineData = [
				[{ x: mean - 3 * stdDev, y: 0, "label": "2.1%", y0: 0 }, { x: mean - 3 * stdDev, y: normalY(mean - 3 * stdDev, mean, stdDev), "label": "34.1%", y0: mean }],
				[{ x: mean - 2 * stdDev, y: 0, "label": "13.6%", y0: 0 }, { x: mean - 2 * stdDev, y: normalY(mean - 2 * stdDev, mean, stdDev), "label": "34.1%", y0: mean }],
				[{ x: mean - 1 * stdDev, y: 0, "label": "34.1%", y0: 0 }, { x: mean - 1 * stdDev, y: normalY(mean - 1 * stdDev, mean, stdDev), "label": "34.1%", y0: mean }],
				[{ x: mean, y: 0, "label": "0%", y0: 0 }, { x: mean, y: normalY(mean, mean, mean), "label": "34.1%", y0: mean }],
				[{ x: mean + 1 * stdDev, y: 0, "label": "34.1%", y0: 0 }, { x: mean + 1 * stdDev, y: normalY(mean + 1 * stdDev, mean, stdDev), "label": "34.1%", y0: mean }],
				[{ x: mean + 2 * stdDev, y: 0, "label": "13.6%", y0: 0 }, { x: mean + 2 * stdDev, y: normalY(mean + 2 * stdDev, mean, stdDev), "label": "34.1%", y0: mean }],
				[{ x: mean + 3 * stdDev, y: 0, "label": "2.1%", y0: 0 }, { x: mean + 3 * stdDev, y: normalY(mean + 3 * stdDev, mean, stdDev), "label": "34.1%", y0: mean }]
			];

			lineData.forEach(function (item) {
				seriesData.push({ x: item[1].x, y: item[1].y });
			})
			for (var i = 0; i < seriesData.length - 1; i++) {

				for (var j = i + 1; j < seriesData.length - 1; j++) {
					if (seriesData[i].x > seriesData[j].x) {
						var temp = seriesData[j];

						seriesData[j] = seriesData[i];

						seriesData[i] = temp;

					}
				}
			}

			if (lChartData.length > 0) {
				return (
					<div className="edu-label-graph-bg mt-32" ref={parRef}><AreaChart pwidth={parRef.current && parRef.current.getBoundingClientRect().width - 70} lineData={lineData} data={seriesData} /></div>
				);

			} else {
				return <div className="info-not-available mt-32">No info available</div>
			}
		} else {
			return <div className="info-not-available mt-32">No info available</div>
		}
	}
	const getMean = (arr) => {
		var sum = 0;

		for (var i = 0; i < arr.length; i++) {
			sum += arr[i];
		}
		return sum / arr.length;
	}
	const normalY = (x, mean, stdDev) => Math.exp((-0.5) * Math.pow((x - mean) / stdDev, 2));

	function getStandardDeviation(array) {
		const n = array.length
		const mean = array.reduce((a, b) => a + b) / n
		return Math.sqrt(array.map(x => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n)
	}

	const callbackTopbar = (data) => {
		getTest();
		getTestafterEndTest();
	}

	const getTotalParticipants = () => {

		var total = 0;

		for (var key in parti.gender) {
			total += parti.gender[key];
		}
		return total == 0 ? true : false;
	}
	return (
		<LayoutResearcher
			isSharedLink={isSharedLink}
			fixed_header_target={true}
			isLoading={false}
			wrapClass={"researcher-test-view-overflow create-test-data-wrapper"}
			resultHeaderClass={"result-header"}
			skipCheck={false}
			extendedFooter={false}
			activeMenu={""}
			openSupportPanel={show_support_panel} hideSupportPanel={()=>{setShowSupportPanel(false)}}
		>

			{true && (
				<div className="three-column-layout two-column-layout">
					<Helmet>
						<title>Participants {test && test.methodology ? " - "+renderTestTitle(test.methodology):""}  | UserQ</title>
					</Helmet>
					{isLoading &&
						<div className="page-loader full-page-loader">
							<LoadingIcon />
						</div>
					}
					{!isLoading && test &&
						<>
							<TestResultsHeader
								isSharedLink={isSharedLink} 
								test={test} 
								endedOn={test.status === 'completed' ? moment.utc(test.ended_on).format('DD-MM-Y') : ''} 
								callbackTopbar={callbackTopbar} 
							/>

							<div className="three-column-layout-body">
								<div className="three-column-layout-left">
									<TestResultsSidebarNavigation
										isSharedLink={isSharedLink} 
										methodology={(test.methodology === 'Five Seconds Test') ? "5 second test" : test.methodology}
										selected_index={2}
										screening={test && test.screening_questions  && test.screening_questions.length>0 ? "Yes" : "No"}
										test_id={(location.pathname.substr(1, 1) === 'v')?test_id:test.id}
									/>
								</div>
								<div className="two-column-right-side">
									<div className="test-result-inner-data">
										
										{sectionLoading &&
											<div className="page-loader accountloading-box result-sections-loader">
												<LoadingIcon />
											</div>
										}
										{!sectionLoading && parti &&
											<>
												{parti && parti.testSharedTargetGroupWithAllGuest ?
													<>
														<div className="participants-result-page-data">
															<ParticipantsNotAvailable small={false} />
															
														</div>
													</>
													:
													<>
														{getTotalParticipants()  ?

															<NoParticipants small={false} />
															:
															<div className="participants-result-page-data">



																<div className="parti-overflow-top-graph-wrap mb-60">

																	<div className="mb-20 box-outer-heading">
																		<Text type={"h4"}>Participants overview</Text>
																	</div>

																	{false && showParticipantNotification && parti && !parti.totalUserTestGivenMatchedTargetGroup && parti.target_group === 'target-group' &&
																		<div className="participants-notification">
																			<div className="profile-complete-progress-wrap notify-info w-100">
																				<div className="pro-lft-wrap">
																					<strong>
																						Unknown users are those from outside the UserQ Panel.
																						If you directly shared the test link with others, you
																						might also see participants outside your target
																						demographic group.
																					</strong>
																				</div>
																				<div className="pro-right-wrap" onClick={() => { setShowParticipantNotification(false) }}>
																					<div className="delete-progress-row">
																						<img src={process.env.REACT_APP_URL + "images/cross.svg"}
																							alt="img" />
																					</div>
																				</div>
																			</div>
																		</div>
																	}

																	{false && showParticipantNotification && parti && !parti.totalUserTestGivenMatchedTargetGroup && parti.target_group === 'random-testers' &&
																		<div className="participants-notification">
																			<div className="profile-complete-progress-wrap notify-info w-100">
																				<div className="pro-lft-wrap">
																					<strong>
																						Unknown users are those from outside the UserQ Panel.
																						If you directly shared the test link with others, you
																						might also see participants outside your target
																						demographic group.
																					</strong>
																				</div>
																				<div className="pro-right-wrap" onClick={() => { setShowParticipantNotification(false) }}>
																					<div className="delete-progress-row">
																						<img src={process.env.REACT_APP_URL + "images/cross.svg"}
																							alt="img" />
																					</div>
																				</div>
																			</div>
																		</div>
																	}

																	{parti && (parti.gender || parti.age) &&
																		<div className="result-pie-chart-wrap box-full-width">
																			<div className="row">
																				<div className="col-lg-6">
																					<PieStatsBox data={parti.age} title={"Age"} />
																				</div>
																				<div className="col-lg-6">
																					<PieStatsBox data={parti.gender} title={"Gender"} />
																				</div>

																			</div>
																		</div>
																	}
																	{parti && (!parti.gender && !parti.age) &&
																		<div className="row">

																			<div className="col-md-6">
																				<div className="info-not-available">No info available</div>
																			</div>

																		</div>
																	}
																</div>

																<div className="partis-nationalities-wrap mb-60">
																	<CirclesGroupStatsBox data={parti.nationalities} title={"Nationalities"} />
																</div>

																{/* <div className="partis-nationalities-wrap partis-row-repeat pb-0">
														<h3 className={`h3 font-inter mb-32 d-flex justify-content-between  ${collapse.residency ? 'closed-sec' : ''}  `}>
															Residency 
															<span className="close-row" onClick={() => { setCollapse({ ...collapse, 'residency': !collapse.residency }) }} >
																{!collapse.residency &&
																	<img src={process.env.REACT_APP_URL + "images/minus-icon.svg"} />
																}
																{collapse.residency &&
																	<img src={process.env.REACT_APP_URL + "images/plus-icon-black.png"} />
																}
															</span>
														</h3>
														{!collapse.residency &&
															<>
																{parti && (parti.countries.emirates.countries.length > 0 && parti.countries.ksa.countries.length > 0 && parti.countries.others.countries.length > 0) &&
																	<>
																		{parti.countries.emirates.countries.length > 0 && parti.countries.emirates.total_count > 0 &&
																			<div className="res-country-wrap">
																				<div className="rs-country-block">
																					<div className="main-ctr-row">
																						<div className="na-name-wrap ae">
																							<span className="flag-hold">
																								<img src={process.env.REACT_APP_URL + "images/flag_icons/ae.svg"} />
																							</span>
																							<p className="country-name-txt">
																								<span>UAE</span>
																							</p>
																						</div>
																						<h5>{parti.countries.emirates.total_count}</h5>
																					</div>
																					<div className="sp5-country-inr-repeat-wrap">
																						<ul>
																							{parti.countries.emirates.countries.map(function (item, index) {
																								return (<li key={"emic" + item.country} className={`${index == 0 ? 'active' : ''}`}>
																									<span>{item.country}</span> <b>{item.count}</b>
																								</li>);
																							})}
																						</ul>
																					</div>
																				</div>
																			</div>
																		}
																		{parti.countries.ksa.countries.length > 0 && parti.countries.ksa.total_count > 0 &&
																			<div className="res-country-wrap">
																				<div className="rs-country-block">
																					<div className="main-ctr-row">
																						<div className="na-name-wrap ae">
																							<span className="flag-hold">
																								<img src={process.env.REACT_APP_URL + "images/flag_icons/sa.svg"} />
																							</span>
																							<p className="country-name-txt">
																								<span>KSA</span>
																							</p>
																						</div>
																						<h5>{parti.countries.ksa.total_count}</h5>
																					</div>
																					<div className="sp5-country-inr-repeat-wrap">
																						<ul>
																							{parti.countries.ksa.countries.map(function (item, index) {
																								return (<li key={"ksa" + item.country} className={`${index == 0 ? 'active' : ''}`}>
																									<span>{item.country}</span> <b>{item.count}</b>
																								</li>);
																							})}

																						</ul>
																					</div>
																				</div>
																			</div>
																		}
																		{parti.countries.others.countries.length > 0 &&
																			<div className="res-country-wrap">
																				<div className="rs-country-block">
																					<div className="sp5-country-inr-repeat-wrap">
																						<ul>
																							{parti.countries.others.countries.map(function (item, index) {
																								return (
																									<li key={"otherc" + item.country} className={`${index == 0 ? 'active' : ''}`}>

																										<div className="main-ctr-row w-100 mb-0">
																											<div className="na-name-wrap ae">
																												<span><img src={process.env.REACT_APP_URL + "images/flag_icons/" + (item.short_code != null ? item.short_code.toLowerCase() : '') + ".svg"} /></span>
																												<p className="country-name-txt">
																													<span>{item.country}</span>
																												</p>
																											</div>
																											<h5>{item.count}</h5>
																										</div>
																									</li>);
																							})}
																						</ul>
																					</div>
																				</div>
																			</div>
																		}
																	</>
																}
																{parti && (parti.countries.emirates.total_count == 0 && parti.countries.ksa.total_count == 0 && parti.countries.others.countries.length == 0) &&
																	<div className="info-not-available">No info available</div>
																}
															</>
														}
													</div> */}
																<div className="horizontal-chart-wrapper mb-60">
																	<HorizontalBarChartResidencyStatsBox data={parti.countries} title={"Residency"} />
																</div>

																<div className="horizontal-chart-wrapper mb-60">
																	<HorizontalBarChartStatsBox data={parti.education} title={"Minimum level of education"} />
																</div>

																<div className="result-pie-chart-wrap result-device-wrap box-full-width mb-60">
																	<div className="mb-20 box-outer-heading">
																		<Text type={"h4"}>Confidence with technology</Text>
																	</div>
																	<div className="row">
																		<div className="col-lg-6">
																			<PieStatsBox title="Average time spent per day" data={parti.hours} />
																		</div>
																		<div className="col-lg-6">
																			<DevicesStatsBox title="Favorite devices" devices={parti.devices} active={parti.include_devices} />
																		</div>
																	</div>
																</div>

																<div className="apps-stats-chart-wrap mb-60">
																	<AppsStatsBox title="Most apps used daily" apps={parti.apps} />
																</div>

																<div className="horizontal-chart-wrapper mb-60">
																	<HorizontalBarChartStatsBox title={"Current employment status"} data={parti.employment} />
																</div>

																<div className="vertical-chart-wrapper mb-60">
																	<VerticalBarChartStatsBox id="industry" marginLeft={160} marginRight={160} title={"Industry"} data={parti.industry} />

																</div>

																<div className="vertical-chart-wrapper mb-60">
																	<VerticalBarChartStatsBox id="department" title={"Department"} data={parti.department} marginLeft={50} marginRight={50} />
																</div>

																<div className="horizontal-chart-wrapper mb-60">
																	<HorizontalBarChartStatsBox data={parti.people} title={"Household"} />
																</div>
																<div className="vertical-chart-wrapper household-income-chart">

																	<VerticalBarChartStatsBox id="household_income" title={"Household income $"} data={parti.income} marginLeft={80} marginRight={80} />
																</div>
															</div>

														}
													</>
												}

												<div className="participants-result-responsive-section">
													<div className="mobile-charts-not-available">
														<div className="no-data-icon">
															<Icon value={"Attention"} size={"large"} />
														</div>
														<p>
															This page is not available on mobile. Please log in from a desktop device to access it.
														</p>
													</div>

												</div>
											</>

										}
									</div>
								</div>

							</div>
						</>
					}
				</div>
			)}
			{!isSharedLink &&
			<a onClick={(e)=>{
                e.preventDefault();

                setShowSupportPanel(true);

            }}  href={process.env.REACT_APP_URL+"r/support"} target="_blank" className='support-button'><svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.879 8.2817C11.05 7.2567 12.95 7.2567 14.121 8.2817C15.293 9.3067 15.293 10.9687 14.121 11.9937C13.918 12.1727 13.691 12.3197 13.451 12.4357C12.706 12.7967 12.001 13.4347 12.001 14.2627V15.0127M21 12.7627C21 13.9446 20.7672 15.1149 20.3149 16.2068C19.8626 17.2988 19.1997 18.2909 18.364 19.1267C17.5282 19.9624 16.5361 20.6253 15.4442 21.0776C14.3522 21.5299 13.1819 21.7627 12 21.7627C10.8181 21.7627 9.64778 21.5299 8.55585 21.0776C7.46392 20.6253 6.47177 19.9624 5.63604 19.1267C4.80031 18.2909 4.13738 17.2988 3.68508 16.2068C3.23279 15.1149 3 13.9446 3 12.7627C3 10.3757 3.94821 8.08656 5.63604 6.39873C7.32387 4.71091 9.61305 3.7627 12 3.7627C14.3869 3.7627 16.6761 4.71091 18.364 6.39873C20.0518 8.08656 21 10.3757 21 12.7627ZM12 18.0127H12.008V18.0207H12V18.0127Z" stroke="#9759CB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
                Support
            </a>
			}
		</LayoutResearcher>
	);
}
