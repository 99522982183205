import React from "react";
import { Link } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
//import { ReactSession } from "react-client-session";
import { ReactSession } from "../../../lib/secure_reactsession";
import LayoutResearcherFullWidth from "../../layouts/layout_researcher_full_width";
import { LayoutResearcher } from "../../themes/userq/Layouts/layout_researcher";
import Topbar from "../study/topbar/topbar.js";
import { useState, useEffect, useRef } from "react";
import {
    getTestData,
    getTestOrderData,
    updatePublishSettingsService,
} from "../../../services/test";
import toast from "react-hot-toast";
import moment from "moment";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Checkbox from "../../layouts/elements/fields/checkbox";
import InputField from "../../layouts/elements/fields/input";
import 'react-rangeslider/lib/index.css';

import "react-datepicker/dist/react-datepicker.css";
import { getCreditSummaryService } from "../../../services/credits";
import { publishTestService } from "../../../services/test";

import Progress from "../study/progress/progress.js";
import { FormattedMessage } from 'react-intl';
import { atom, useResetRecoilState } from 'recoil';
import { LoadingIcon } from "../../themes/userq/Loader/loadingIcon";
import { encryptId, encryptClient, roundNumber, renderTestTitleCreate } from "../../../lib/helpers";

import { getBillingInfoService, updateBillingInfoService } from "../../../services/user";
import { countries } from '../../../data/stripe_countries.js';
import { RadioButton } from "../../themes/userq/RadioButton/RadioButton"
import Select from 'react-select';
import {
    addNewPaymentMethodService,
    getPaymentMethodsService,
    deletePaymentMethodService,
    setDefaultCard,
} from "../../../services/payment_methods";

import { DropdownIndicator } from "../../../lib/helpers";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, useStripe } from "@stripe/react-stripe-js";
import Cleave from "cleave.js/react";
import validator from "validator";

import ConfirmationModal from "../../themes/userq/Modal/ConfirmationModal";

import { applyCouponOnTestService, removeCouponOnTestService, publishCreditsTestService, makePaymentForTestService } from "../../../services/credits";
import PublishModal from "../study/publishmodal/publish_modal";
import Header from './components/header.js';
import { Stepper } from "../../themes/userq/Stepper/Stepper";
import OrderSummary from "./components/order_summary";
import { Text } from "../../themes/userq/Text/Text";
import { CheckBox } from "../../themes/userq/CheckBox/CheckBox";
import { Button } from "../../themes/userq/Button/Button";
import PromoCode from "../components/promo_code";
import { ToastMessage } from "../../themes/userq/ToastMessage/ToastMessage";
import SuccessMessageModal from "../../themes/userq/SuccessMessageModal/success_message_modal";
import { Icon } from "../../themes/userq/Icon/Icon";
import creditCardType from "credit-card-type";

import { CreditCardForm } from "./components/credit_card_form";
import TestSummaryModal from "../../dialog/test_summary_modal";
import { settings as globalsettings } from "../../../data/settings";
import { Helmet } from "react-helmet";
import { useTestBuilderData } from "./contexts/TestBuilderContext.js";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

export default function PublishTest(props) {
    return (<Elements stripe={stripePromise}>
        <PaymentComponent {...props} />
    </Elements>);
};


const PaymentComponent = (props) => {

    ReactSession.setStoreType("localStorage");

    const { getTestLength } = useTestBuilderData();

    const stripe = useStripe();

    let { test_id } = useParams();

    let user = ReactSession.get("user");

    const dateTesterSectionRef = useRef();


    const navigate = useNavigate();

    const [test, setTest] = useState("");

    const [lastSaved, setLastSaved] = useState(null);

    const [linkCopied, setLinkCopied] = useState(false);

    const [isLoading, setLoading] = useState(false);

    const [orderLoading, setOrderLoading] = useState(false);


    const [order, setOrder] = useState(false);

    const [saveLoading, setSaveLoading] = useState(false);

    const [success_modal, setSuccessModal] = useState({ open: false });

    const [testSummaryModal, setTestSummaryModal] = useState({
        open: false
    });

    const [formValues, setFormValues] = useState({
        closed_on: new Date(),
        password: "",
        password_field: 0,
        close_condition: "no-specific-date",
        participants: 5,
        promo_code: "",
        payment_method_id: "",
        name: "",
        city: "",
        country: "",
        address: "",
        card_name: "",
        card_no: "",
        exp_date: "",
        cvv: "",
        future_use: false,
        method_id: ""
    });

    const handleScroll = ref => {
        window.scrollTo({
            behavior: "smooth",
            top: ref.current.offsetTop
        });
    };

    const [formErrors, setFormErrors] = useState({
        password: null,
        promo_code: null,
        payment_method_id: null,
        name: null,
        city: null,
        country: null,
        address: null,
        card_name: null,
        card_no: null,
        exp_date: null,
        cvv: null,
        error_class: "input_error",
    });

    const [credits_required, setCreditsRequired] = useState(0);

    const [isSaved, setIsSaved] = useState({ 'status': '', 'message': '' });

    const [summary, setSummary] = useState({ credits_balance: 100 });

    const [settings, setSettings] = useState({ max_participants: 500 });

    const [participants_selected, setParticipantsSelected] = useState(5);

    const [targetType, setTargetType] = useState('test-links');

    const [couponError, setCouponError] = useState('');


    const creditsState = atom({
        key: 'credits',
        default: 0
    });
    const reset = useResetRecoilState(creditsState);

    /*New Form */
    const [isBillingEditable, setBillingEditable] = useState(false);

    const [paymentMethods, setPaymentMethods] = useState([]);

    const [selected_package, setSelectPackage] = useState(null);

    const [addNewCard, setAddNewCard] = useState(false);

    const [credits, setCredits] = useState(50);

    const [credits_error, setCreditsError] = useState(null);

    const [isExpanded, setExpanded] = useState(false);

    const [countryLabelClass, setCountryLabelClass] = useState("inactive_label");

    const [billingInfo, setBillingInfo] = useState({
        name: "",
        country: null,
        city: "",
        address: "",
    });

    const [billingLoading, setBillingLoading] = useState(false);

    const [formLoading, setFormLoading] = useState(false);

    const errorRef = useRef(null);

    const [firstErrorRef, setFirstErrorRef] = useState(null);

    const [cardFormLoading, setCardFormLoading] = useState(false);

    const [confirmModal, setConfirmModal] = useState({
        open: false,
        target_id: null,
    });

    const [publishModal, setPublishModal] = useState({
        open: false,
        close: null,
        confirm: null,
    });

    const [couponLoading, setCouponLoading] = useState(false);

    let method_id = null;

    const [show_support_panel, setShowSupportPanel] = useState(false);

    /*New Form */

    let treeEmptyNodeError = false;

    useEffect(function () {

        scroll();

    }, [firstErrorRef])

    const scroll = () => {
        if (errorRef && errorRef.current) {

            const y = errorRef.current.getBoundingClientRect().top + window.pageYOffset - 300;

            window.scrollTo({ top: y, behavior: 'smooth' });
        }
    }
    useEffect(() => {
        document.title = process.env.REACT_APP_NAME + " - Publish";

        getTestApi();
    }, []);

    /*useEffect(() => {
        const timer = setInterval(() => {
            savePublishSettings(formValues, false);
        }, 30000);

        return () => {
            clearTimeout(timer);
        };
    }, [formValues]);*/

    const showError = (error) => {
        localStorage.setItem("stepper_error", error);
        toast(
            <ToastMessage type={"error"} message={error} closable={false} onClose={() => { toast.dismiss(); }} />,
            {
                id:"error",
                className: "errortoast",
                position: "bottom-center",
                duration: globalsettings.toast_duration,
            }
        );
        
    };
    function checkEmptyNodeInTree(tree){
        tree.forEach(function (item) {
          
            if (!item.title) {
              treeEmptyNodeError = true;
      
              item["error"] = true;
            } else {
              item["error"] = false;
            }
            if (item.children.length > 0) {
            
                checkEmptyNodeInTree(item.children);
            }
        });
      };

    const savePublishSettings = () => {
        if (test && test.status != "published") {
            let data = Object.assign({}, formValues);

            data["test_id"] = test_id;

            setSaveLoading(true);

            updatePublishSettingsService(data, ReactSession.get("token")).then(
                (response) => {
                    setSaveLoading(false);

                    if (response.success) {
                        setLastSaved(moment.now());

                        //reset();

                    } else {
                        toast(
                            <ToastMessage type={"error"} message={response.message} closable={true} onClose={() => { toast.dismiss(); }} />,
                            {
                                className: "errortoast",
                                position: "bottom-center",
                                duration: globalsettings.toast_duration,
                            }
                        );
                    }
                }
            );
        }
    };
    const fetchCreditsSummary = () => {
        let token = ReactSession.get("token");

        setLoading(true);

        getCreditSummaryService(token).then((response) => {
            //setLoading(false);
            if (response.success) {
                setSummary(response.summary);

                ReactSession.set("credits", response.summary.credits_balance);
            } else {
                var id = toast(
                    <div className="toastinner">
                        {response.message}
                        <a
                            onClick={() => {
                                toast.dismiss(id);
                            }}
                        >
                            &times;
                        </a>
                    </div>,
                    {
                        className: "errortoast",
                        position: "bottom-center",
                    }
                );
            }
        });
    };
    const getTestApi = () => {
        let token = ReactSession.get("token");

        setLoading(true);

        getTestData({ test_id: test_id }, token).then((response) => {
            //setLoading(false);

            if (response.success) {

                if (response.test.status) {
                    
                    if (response.test.status == "published") {
                        //navigate("/r/project/" + response.test.project_id);

                        navigate("/dashboard");
                    }

                    else {
                        getTestLength(test_id);
                        if (
                            response.test.welcome_title == "" ||
                            response.test.welcome_description == ""
                        ) {
                            navigate("/r/test/" + test_id + "/welcome?error=true");
                            showError("The welcome page cannot be empty! Please enter a title and a description.");
                        } /*else if (response.test.methodology == "Tree Test" && response.test.tasks.length == 0) {
                            navigate("/r/test/" + test_id + "/tree-test/");
                            showError("Please add tasks");
                        } */ else if (response.test.methodology === "Card Sorting") {
                            if (response.test.card_sorting === null || !response.test.card_sorting.title) {
                                showError(
                                    "You missed some key information. Please check again and fill out any missing fields."
                                );
                                navigate(
                                    "/r/test/" + test_id + "/card-sorting-test?error=true"
                                );

                            }else if (response.test.card_sorting && response.test.card_sorting_card.length === 0) {
                                showError(
                                    "Looks like you haven't created enough cards for this test. Please add at least two cards to move to the next steps."
                                );
                                navigate(
                                    "/r/test/" + test_id + "/card-sorting-test?error=true"
                                );

                            }else if (response.test.card_sorting.card_sorting === 'close' && response.test.card_sorting_category.length < 2) {
                                navigate("/r/test/" + test_id + "/card-sorting-test?error=true");
                                showError("Looks like you haven't created enough categories for this test. Please add at least two categories to move to the next steps.");

                            }else if (response.test.card_sorting_card.length < 2) {
                                showError(
                                    "Looks like you haven't created enough cards for this test. Please add at least two cards to move to the next steps."
                                );
                                navigate(
                                    "/r/test/" + test_id + "/card-sorting-test?error=true"
                                );

                            } 
                            else if (
                                response.test.thankyou_title == "" || response.test.thankyou_title == null ||
                                response.test.thankyou_description == "" || response.test.thankyou_description == null
                            ) {
                                navigate("/r/test/" + test_id + "/thank-you?error=true");
                                showError("The thank you page cannot be empty! Please enter a title and a description.");
                            }
                            else if (response.test.target_group == null) {
                                navigate("/r/test/" + test_id + "/recruit?error=true");
                                showError("Please choose your recruitment preferences.");
                            } else if (
                                response.test.target_group != null &&
                                response.test.target_group.target_type !== 'test-links'
                            ) {
                                
                                if (response.test.target_group.participants_selected == null) {
                                    navigate("/r/test/" + test_id + "/recruit?error=true");
                                    showError("Please choose your recruitment preferences.");
                                } else if (response.test.target_group.participants_selected === 0) {
                                    navigate("/r/test/" + test_id + "/recruit?error=true");
                                    showError(
                                        <span className={"w-100"}>
                                            Unfortunately, we can't fulfil your recruitment specifications.
                                            Please contact us at
                                            <a href={"mailto:support@userq.com"} className={'font-weight-600'} style={{ fontSize: '16px' }}>support@userq.com</a>
                                        </span>
                                    );
                                    return;
                                } 
                               

                               else if (response.test.target_group && response.test.target_group.participants_selected*(100/20)  > response.test.testers_count) {
                                navigate("/r/test/" + test_id + "/recruit?error=true");
                                showError(
                                    <span className={"w-100"}>
                                        Uh oh! Try again by reducing the number of participants or changing the selected criteria. For additional support,
                                        <a href={"mailto:support@userq.com"} className={'font-weight-600'} style={{ fontSize: '16px' }}>get in touch with us.</a>
                                    </span>
                                );
                                localStorage.setItem("stepper_error", "Uh oh! Try again by reducing the number of participants or changing the selected criteria.");
                                return;
                                }
                                

                            } 


                        }
                        else if (response.test.methodology === "Five Seconds Test") {
                            if (response.test.fiveSecondsTestTask == null) {
                                navigate(
                                    "/r/test/" + test_id + "/five-seconds-test?error=true"
                                );
                                showError(
                                    "Looks like you haven't added any task for this test! Please add task to move to the next steps."
                                );
                            }else if ((response.test.fiveSecondsTestTask.task == "" || response.test.fiveSecondsTestTask.task == null) && response.test.fiveSecondsTestTask.task_file == null && response.test.fiveSecondsTestQuestion.length === 0) {
                                showError(
                                    "Looks like you haven't added any task for this test! Please add task to move to the next steps."
                                );
                                navigate(
                                    "/r/test/" + test_id + "/five-seconds-test?error=true"
                                );

                            }
                            else if (response.test.fiveSecondsTestTask.task == "" || response.test.fiveSecondsTestTask.task == null) {
                                navigate(
                                    "/r/test/" + test_id + "/five-seconds-test?error=true"
                                );
                                showError(
                                    "Looks like you haven't added any task for this test! Please add task to move to the next steps."
                                );
                            } else if (response.test.fiveSecondsTestTask.task_file == null) {
                                navigate(
                                    "/r/test/" + test_id + "/five-seconds-test?error=true"
                                );
                                showError(
                                    "Looks like you haven't added design for this test! Please add design to move to the next steps."
                                );
                            }
                            else if (response.test.fiveSecondsTestQuestion.length === 0) {
                                navigate(
                                    "/r/test/" + test_id + "/five-seconds-test?error=true"
                                );
                                showError(
                                    "Looks like you haven't added questions for this test! Please add at least 1 question to move to the next steps."
                                );
                            }
                            else if (response.test.fiveSecondsTestQuestion.length > 0) {
                                let nonoptionalcount = 0;
                                response.test.fiveSecondsTestQuestion.forEach((question) => {
                                    if (JSON.parse(question).is_optional == 0) {
                                        nonoptionalcount = 1
                                    }
                                }
                                );

                                if (nonoptionalcount == 0) {
                                    navigate(
                                        "/r/test/" + test_id + "/five-seconds-test?error=true"
                                    );
                                    showError(
                                        "At least one question should not be optional."
                                    );
                                }
                                else if (
                                    response.test.thankyou_title == "" || response.test.thankyou_title == null ||
                                    response.test.thankyou_description == "" || response.test.thankyou_description == null
                                ) {
                                    navigate("/r/test/" + test_id + "/thank-you?error=true");
                                    showError("The thank you page cannot be empty! Please enter a title and a description.");
                                }
                                else if (response.test.target_group == null) {
                                    navigate("/r/test/" + test_id + "/recruit?error=true");
                                    showError("Please choose your recruitment preference before proceeding to publish.");
                                } else if (
                                    response.test.target_group != null &&
                                    response.test.target_group.target_type !== 'test-links'
                                ) {
                                    if (response.test.target_group.participants_selected == null) {
                                        navigate("/r/test/" + test_id + "/recruit?error=true");
                                        showError("Please choose your recruitment preference before proceeding to publish.");
                                    } else if (response.test.target_group.participants_selected === 0) {
                                        navigate("/r/test/" + test_id + "/recruit?error=true");
                                        showError(
                                            <span className={"w-100"}>
                                                Unfortunately, we can't fulfil your recruitment specifications.
                                                Please contact us at
                                                <a href={"mailto:support@userq.com"} className={'font-weight-600'} style={{ fontSize: '16px' }}>support@userq.com</a>
                                            </span>
                                        );
                                    } else if (response.test.target_group && response.test.target_group.participants_selected*(100/20)  > response.test.testers_count) {
                                        navigate("/r/test/" + test_id + "/recruit?error=true");
                                        showError(
                                            <span className={"w-100"}>
                                                Uh oh! Try again by reducing the number of participants or changing the selected criteria. For additional support,
                                                <a href={"mailto:support@userq.com"} className={'font-weight-600'} style={{ fontSize: '16px' }}>get in touch with us.</a>
                                            </span>
                                        );
                                        localStorage.setItem("stepper_error", "Uh oh! Try again by reducing the number of participants or changing the selected criteria.");
                                        
                                    }

                                } 

                            }
                        }
                        else if (
                            response.test.methodology == "Survey"
                        ) {
                            if (response.test.surveyQuestion.length === 0) {
                                showError(
                                    "Please add at least one question for the survey before proceeding."
                                );
                                navigate(
                                    "/r/test/" + test_id + "/survey?error=true"
                                );
                                
                            }
                            else if (response.test.surveyQuestion.length > 0) {
                                let nonoptionalcount = 0;
                                validatelogic(response.test.surveyQuestion);
                                response.test.surveyQuestion.forEach((question) => {
                                    if (JSON.parse(question).is_optional == 0) {
                                        nonoptionalcount = 1
                                    }
                                }
                                );

                                if (nonoptionalcount == 0) {
                                    showError(
                                        "At least one question should not be optional."
                                    );
                                    navigate(
                                        "/r/test/" + test_id + "/survey?error=true"
                                    );
                                    
                                }
                                else if (
                                    response.test.thankyou_title == "" || response.test.thankyou_title == null ||
                                    response.test.thankyou_description == "" || response.test.thankyou_description == null
                                ) {
                                    navigate("/r/test/" + test_id + "/thank-you?error=true");
                                    showError("The thank you page cannot be empty! Please enter a title and a description.");
                                }
                                 else if (response.test.target_group == null) {
                                    navigate("/r/test/" + test_id + "/recruit?error=true");
                                    showError("Please choose your recruitment preference before proceeding to publish.");
                                }  else if (response.test.target_group.participants_selected === 0) {
                                    navigate("/r/test/" + test_id + "/recruit?error=true");
                                    showError(
                                        <span className={"w-100"}>
                                            Unfortunately, we can't fulfil your recruitment specifications.
                                            Please contact us at
                                            <a href={"mailto:support@userq.com"} className={'font-weight-600'} style={{ fontSize: '16px' }}>support@userq.com</a>
                                        </span>
                                    );
                                } else if (response.test.target_group && response.test.target_group.participants_selected*(100/20)  > response.test.testers_count) {
                                    navigate("/r/test/" + test_id + "/recruit?error=true");
                                    showError(
                                        <span className={"w-100"}>
                                            Uh oh! Try again by reducing the number of participants or changing the selected criteria. For additional support,
                                            <a href={"mailto:support@userq.com"} className={'font-weight-600'} style={{ fontSize: '16px' }}>get in touch with us.</a>
                                        </span>
                                    );
                                    localStorage.setItem("stepper_error", "Uh oh! Try again by reducing the number of participants or changing the selected criteria.");
                                    
                                }

                            }
                        }
                        else if (
                            response.test.thankyou_title == "" || response.test.thankyou_title == null ||
                            response.test.thankyou_description == "" || response.test.thankyou_description == null
                        ) {
                            navigate("/r/test/" + test_id + "/thank-you?error=true");
                            showError("The thank you page cannot be empty! Please enter a title and a description.");
                        } else if (response.test.target_group == null) {
                            navigate("/r/test/" + test_id + "/recruit?error=true");
                            showError("Please choose your recruitment preference before proceeding to publish.");
                        }
                        if (
                            response.test.target_group != null &&
                            response.test.target_group.target_type !== 'test-links'
                        ) {

                            
                            
                            if (response.test.target_group.participants_selected == null) {
                                navigate("/r/test/" + test_id + "/recruit?error=true");
                                showError("Please choose your recruitment preference before proceeding to publish.");
                            } else if (response.test.target_group.participants_selected === 0) {
                                navigate("/r/test/" + test_id + "/recruit?error=true");
                                showError(
                                    <span className={"w-100"}>
                                        Unfortunately, we can't fulfil your recruimtent specifications.
                                        Please contact us at
                                        <a href={"mailto:support@userq.com"} className={'font-weight-600'} style={{ fontSize: '16px' }}>support@userq.com</a>
                                    </span>
                                );
                            } else if (response.test.target_group && response.test.target_group.is_screening_questions==1 && response.test.screening_questions.length==0 ){
                                
                                
                                navigate("/r/test/" + test_id + "/recruit?error=true");
                                showError("Some required information for the recruitment step is missing or incomplete. Please review and try again.");
                                localStorage.setItem("stepper_error", "Some required information for the recruitment step is missing or incomplete. Please review and try again.");
                                
                            } else if (response.test.target_group && response.test.target_group.participants_selected*(100/20)  > response.test.testers_count) {

                                showError(
                                    <span className={"w-100"}>
                                        Uh oh! Try again by reducing the number of participants or changing the selected criteria. For additional support,
                                        <a href={"mailto:support@userq.com"} className={'font-weight-600'} style={{ fontSize: '16px' }}>get in touch with us.</a>
                                    </span>
                                );
                                localStorage.setItem("stepper_error", "Uh oh! Try again by reducing the number of participants or changing the selected criteria.");
                                navigate("/r/test/" + test_id + "/recruit?error=true");
                                
                            }

                        } else if (
                            response.test.methodology == "Tree Test"
                        ) {
                            var tree_error = false;
                            
                            if(response.test.tree){

                                if(response.test.tree.length==0){
                                    tree_error = true;
                                    showError("Some required information for the tree testing is missing or incomplete. Please review and try again.");
                                    navigate("/r/test/" + test_id + "/tree-test?error=true");    
                                } else {
                                    treeEmptyNodeError = false;

                                    checkEmptyNodeInTree(response.test.tree);

                                    if(treeEmptyNodeError){
                                        tree_error = true;
                                        showError("Some required information for the tree testing is missing or incomplete. Please review and try again.");
                                        navigate("/r/test/" + test_id + "/tree-test?error=true");    
                                    }
                                }
                                
                            }
                            if(!tree_error){
                                if (response.test.tasks.length === 0) {
                                    showError("Some required information for the tree testing is missing or incomplete. Please review and try again.");
                                    navigate("/r/test/" + test_id + "/tree-test?error=true");

                                } else {
                                    
                                    for (var i = 0; i < response.test.tasks.length; i++) {
                                        if (!response.test.tasks[i].title || response.test.tasks[i].title == null) {
                                            showError("Some required information for the tree testing is missing or incomplete. Please review and try again.");
                                            navigate("/r/test/" + test_id + "/tree-test?error=true");
                                            break;
                                        }
                                        else if (response.test.tasks[i].correct_answer_id == null) {
                                            showError("Some required information for the tree testing is missing or incomplete. Please review and try again.");
                                            navigate("/r/test/" + test_id + "/tree-test?error=true");
                                            break;
                                        }
                                    }

                                }
                            }
                        } else if (
                            response.test.methodology == "Preference Test"
                        ) {
                            var error = false;
                            if (
                                response.test.welcome_title == "" ||
                                response.test.welcome_description == ""
                            ) {
                                navigate("/r/test/" + test_id + "/welcome?error=true");
                                showError("The welcome page cannot be empty! Please enter a title and a description.");
                            }
                            else if (response.test.preference_test_designs.length < 2) {
                                error = true;
                                navigate("/r/test/" + test_id + "/preference-test?error=true");
                                showError("You missed some key information. Please check again and fill out any missing fields.");
                            }
                        }   else if(response.test.methodology=="Prototype test"){
                            if (
                                response.test.welcome_title == "" ||
                                response.test.welcome_description == ""
                            ) {
                                navigate("/r/test/" + test_id + "/welcome?error=true");
                                showError("The welcome page cannot be empty! Please enter a title and a description.");
                            }else{

                            var error = false;

                            for(var i=0; i<response.test.prototype_test_tasks.length; i++){
			
                                var task = response.test.prototype_test_tasks[i];
                    
                                
                                if(!task.prototype_link){
                                    
                                    error = true;
                                    break;
                                }
                                else if(!task.is_synced){
                                    error = true;
                                    break;
                                }
                                else if(!task.description){
                                    error = true;
                                    break;
                                }
                                else if(response.test.prototype_test_setting.objective=="closed" && !task.goal_screen_node_id){
                                    error = true;
                                    break;
                                }
                                else if(response.test.prototype_test_setting.objective=="open" && task.questions.length==0){
                                    error = true;
                                    break;
                                }
                                else if(response.test.prototype_test_setting.objective=="open" && task.questions.length>0){
                                    var no_of_optional = 0;
                    
                                    task.questions.forEach(function(item){
                                        if(item.is_optional){
                                            no_of_optional++;
                                        }
                                    })
                                    if(no_of_optional==task.questions.length){
                                        error=true;
                                        break;
                                    }
                                    
                                }
                                
                            }
                            if(error){
                                showError("You missed some key information. Please check again and fill out any missing fields.");
                                navigate("/r/test/" + test_id + "/prototype-test?error=true");
                            }
                        }
                        }  else if(response.test.methodology=="First click"){
                            if (
                                response.test.welcome_title == "" ||
                                response.test.welcome_description == ""
                            ) {
                                navigate("/r/test/" + test_id + "/welcome?error=true");
                                showError("The welcome page cannot be empty! Please enter a title and a description.");
                            }else{
                            var error = false;

                            for(var i=0; i<response.test.firstclick_test_tasks.length; i++){
			
                                var task = response.test.firstclick_test_tasks[i];
                    
                                
                                if(!task.description){
                                    error = true;
                                    break;
                                }
                                else if(!task.design_file){
                                    error = true;
                                    break;
                                }
                            
                            }
                            if(error){
                                showError("You missed some key information. Please check again and fill out any missing fields.");
                                navigate("/r/test/" + test_id + "/first-click?error=true");
                            }
                        }
                        }
                    }
                }

                if (response.test.target_group) {
                    if (response.test.target_group.participants_selected) {
                        setFormValue(
                            "pariticipants",
                            response.test.target_group.participants_selected
                        );
                        setParticipantsSelected(
                            response.test.target_group.participants_selected
                        );
                    }

                    setTargetType(response.test.target_group.target_type);
                }
                setSettings(response.settings);

                setTest(response.test);

                if (response.test.password) {
                    setFormValue("password", response.test.password);
                    setFormValue("password_field", 1);
                } else {
                    setFormValue("password", "");
                    setFormValue("password_field", 0);
                }
                setFormValue("close_condition", response.test.close_condition);

                if (response.test.closed_on) {
                    setFormValue(
                        "closed_on",
                        new Date(moment().utc(response.test.closed_on).local().format())
                    );
                } /*else {
                    setFormValue("closed_on", new Date());
                }*/

                if (response.test.participants) {
                    setFormValue("participants", response.test.participants);
                } else {
                    setFormValue("participants", response.test.target_group.participants_selected);
                }

                /*if(response.test.saved_step || response.test.saved_step==0){
                            
                            if(response.test.saved_step<5){
                                navigate("/r/test/"+test_id+"/review");
                            }
                        }*/
                // redirectToStep({
                //   test_id: test_id,
                //   current_step: 2,
                //   saved_step: response.test.saved_step,
                //   navigate: navigate,
                // });

                setLastSaved(moment(response.test.updated_at));

                fetchCreditsSummary();

                getOrderApi();


            } else {
                toast(
                    <div className="toastinner">
                        {response.message}
                        <a
                            onClick={() => {
                                toast.dismiss();
                            }}
                        >
                            &times;
                        </a>
                    </div>,
                    {
                        className: "errortoast",
                        position: "bottom-center",
                    }
                );
            }
        });
    };
    const getOrderApi = () => {
        let token = ReactSession.get("token");

        setLoading(true);

        getTestOrderData({ test_id: test_id }, token).then((response) => {
            //setLoading(false);

            if (response.success) {

                if (response.order) {
                    setOrder(response.order);

                    if (response.order.coupon_code) {
                        setFormValues({ ...formValues, "promo_code": response.order.coupon_code })
                    }
                    getBillingInfo();

                    setTimeout(function () {
                        window.setLabels();
                    }, 100);
                }


            } else {
                toast(
                    <div className="toastinner">
                        {response.message}
                        <a
                            onClick={() => {
                                toast.dismiss();
                            }}
                        >
                            &times;
                        </a>
                    </div>,
                    {
                        className: "errortoast",
                        position: "bottom-center",
                    }
                );
            }
        });
    };
    const getBillingInfo = () => {
        let token = ReactSession.get("token");

        setLoading(true);


        getBillingInfoService(token).then((response) => {
            //setLoading(false);

            if (response.success) {
                //setFormValues()

                for (var key in response.billing_address) {
                    if (response.billing_address[key] == null) {
                        response.billing_address[key] = "";
                    }
                }
                var country = null;

                countries.forEach(function (item) {
                    if (item.value == response.billing_address.country) {
                        country = item;
                    }
                });
                if (response.billing_address.country == "") {
                    setCountryLabelClass("inactive_label");
                } else {
                    setCountryLabelClass("active_label");
                }
                setBillingInfo((oldValues) => ({
                    ...oldValues,
                    ["address"]: response.billing_address.address,
                    ["city"]: response.billing_address.city,
                    ["country"]: country,
                    ["name"]: response.billing_address.name,
                }));

                setFormValues((oldValues) => ({
                    ...oldValues,
                    ["address"]: response.billing_address.address,
                    ["city"]: response.billing_address.city,
                    ["country"]: country,
                    ["name"]: response.billing_address.name,
                }));
                if (!response.billing_address.name) {
                    setBillingEditable(true);
                }
                window.setLabels();
                getPaymentMethods();
            } else {
                var id = toast(
                    <div className="toastinner">
                        {response.message}
                        <a
                            onClick={() => {
                                toast.dismiss(id);
                            }}
                        >
                            &times;
                        </a>
                    </div>,
                    {
                        className: "errortoast",
                        position: "bottom-center",
                    }
                );
            }
        });
    };
    const updateBillingInfo = () => {
        resetFormErrors();

        if (!billingLoading) {
            var error = false;

            resetFormErrors();

            if (formValues.name == "") {
                showFormError("name", "Required field");
                error = true;
            } else if (formValues.address == "") {
                showFormError("address", "Required field");
                error = true;
            } else if (formValues.city == "") {
                showFormError("city", "Required field");
                error = true;
            } else if (!formValues.country) {
                showFormError("country", "Required field");
                error = true;
            }

            if (!error) {
                setBillingLoading(true);

                const token = ReactSession.get("token");

                var formData = {};

                formData["name"] = formValues.name;

                formData["address"] = formValues.address;

                formData["country"] = formValues.country.value;

                formData["city"] = formValues.city;

                updateBillingInfoService(formData, token).then((response) => {
                    setBillingLoading(false);

                    if (response.success) {
                        for (var key in response.billing_address) {
                            if (response.billing_address[key] == null) {
                                response.billing_address[key] = "";
                            }
                        }
                        var country = null;

                        countries.forEach(function (item) {
                            if (item.value == response.billing_address.country) {
                                country = item;
                            }
                        });

                        setBillingInfo((oldValues) => ({
                            ...oldValues,
                            ["address"]: response.billing_address.address,
                            ["city"]: response.billing_address.city,
                            ["country"]: country,
                            ["name"]: response.billing_address.name,
                        }));

                        setFormValues((oldValues) => ({
                            ...oldValues,
                            ["address"]: response.billing_address.address,
                            ["city"]: response.billing_address.city,
                            ["country"]: country,
                            ["name"]: response.billing_address.name,
                        }));

                        setBillingEditable(false);

                        window.setLabels();
                    } else {
                        var id = toast(
                            <div className="toastinner">
                                {response.message}
                                <a
                                    onClick={() => {
                                        toast.dismiss(id);
                                    }}
                                >
                                    &times;
                                </a>
                            </div>,
                            {
                                className: "errortoast",
                                position: "bottom-center",
                            }
                        );
                    }
                });
            }
        }
    };
    
    const renderPaymentMethods = () => {
        var render = [];

        paymentMethods.forEach(function (method, index) {
           

            render.push(
              

                <div
                    key={"methods" + method.id}
                    className="checkboxes col-md-7 publish-field-whidth" 
                >
                    
                   <div className="d-flex addedpayment-cards-wrap">
                    <div className="card-left-side"> 
                        <label htmlFor={method.stripe_payment_method_id} className="mt-0">
                            <RadioButton
                                id={method.stripe_payment_method_id}
                                type="radio"
                                isChecked={
                                    formValues.payment_method_id == method.id ? true : false
                                }
                                onChange={() => {
                                    setFormValues({...formValues,"payment_method_id":method.id,card_no:"",cvv:"",exp_date:""});
                                }}
                                name="payment_method"
                                value={method.stripe_payment_method_id}
                                className="hidden radio_btn_input"
                                required="required"
                            />
                            <p>
                                <img
                                    src={
                                        process.env.REACT_APP_URL +
                                        "images/" +
                                        method.brand +
                                        "-icon.svg"
                                    }
                                />{" "}
                                 {/* ****{method.last_4}{" "}

                                <b>{method.card_name}</b> */}
                            </p>
                        </label>

                        {/* {method.default == 1 && (
                            <span className="primary-card">Primary</span> 
                        )} */}
                    </div>

                    {user.permission != "Editor" && (
                        <div className="card-right-side">

                            {/* {method.default == 0 && (
                                <span
                                    className="secondary-btn secondary-small"
                                    onClick={() => {
                                        setDefaultCardValue(method.stripe_payment_method_id);
                                    }}
                                >
                                    Make primary
                                </span>
                            )} */}
                            
                            <b className="card-last-number">**** {method.last_4}{" "}</b>


                            {/*<span
                                className="remove-card"
                                onClick={() => {
                                    setConfirmModal({
                                        open: true,
                                        target_id: method.id,
                                        confirm_title: "Delete Card",
                                        confirm_btn_title: "Delete",
                                        confirm_message:
                                            "Are you sure you want to delete this card?",
                                    });
                                }}
                            >
                                {" "} 
                                <img
                                    src={process.env.REACT_APP_URL + "img/delete.svg"}
                                    alt="delete"
                                />


                            </span>*/}
                        </div>

                    )}

                    </div>
                </div>
            );
        });

        if (render.length > 0) {
            return render;
        } else {
            return <></>;
            return <div className="no_payment_add">No payment methods added.</div>;
        }
    };
    const setDefaultCardValue = (value) => {

        let token = ReactSession.get("token");
        var data = new FormData();
        data.append("stripe_id", value);
        setDefaultCard(data, token).then((response) => {
            if (response.success) {
                getPaymentMethods();
            }
        });
    };
    const getPaymentMethods = () => {
        let token = ReactSession.get("token");

        setLoading(true);

        getPaymentMethodsService(token).then((response) => {
            setLoading(false);

            if (response.success) {
                if (response.payment_methods.length > 0) {
                    //setFormValue("payment_method_id", response.payment_methods[0].id);
                }
                var exist = false;
                response.payment_methods.forEach(function(method){
                    if(method.default==1){
                        exist = true;
                        setFormValue("payment_method_id", method.id);
                    }
                })
                if(!exist) {
                    if (response.payment_methods.length > 0) {
                        setFormValue("payment_method_id", response.payment_methods[0].id);
                    }
                }
                setPaymentMethods(response.payment_methods);
            } else {
                var id = toast(
                    <div className="toastinner">
                        {response.message}
                        <a
                            onClick={() => {
                                toast.dismiss(id);
                            }}
                        >
                            &times;
                        </a>
                    </div>,
                    {
                        className: "errortoast",
                        position: "bottom-center",
                    }
                );
            }
        });
    };
    const deletePaymentMethod = (id) => {
        let token = ReactSession.get("token");

        //setLoading(true);

        setIsSaved({ status: "loading", "message": "" });

        deletePaymentMethodService({ id: id }, token).then((response) => {
            //setLoading(false);

            if (response.success) {
                setIsSaved({ status: "success", "message": "" })
                getPaymentMethods();
            } else {
                setIsSaved({ status: "error", "message": response.message });
                var id = toast(
                    <ToastMessage type={"error"} message={response.message} closable={true} onClose={() => { toast.dismiss(); }} />,
                    {
                        className: "errortoast",
                        position: "bottom-center",
                        duration: globalsettings.toast_duration,
                    }

                );
            }
        });
    };
    const addNewPaymentMethod = () => {
        resetFormErrors();

        if (!cardFormLoading) {
            var error = false;

            // resetFormErrors();

            error = validatePaymentMethodForm();

            if (!error) {
                setCardFormLoading(true);

                const token = ReactSession.get("token");

                var formData = {};

                formData["card_no"] = encryptClient(formValues.card_no);

                formData["exp_date"] = formValues.exp_date;

                formData["cvv"] = encryptClient(formValues.cvv);

                formData["card_name"] = formValues.card_name;

                addNewPaymentMethodService(formData, token).then((response) => {
                    setCardFormLoading(false);

                    if (response.success) {
                        setFormValue("card_name", "");
                        setFormValue("card_no", "");
                        setFormValue("cvv", "");
                        setFormValue("exp_date", "");

                        getPaymentMethods();

                        setAddNewCard(false);

                        window.setLabels();
                    } else {
                        var id = toast(
                            <div className="toastinner">
                                {response.message}
                                <a
                                    onClick={() => {
                                        toast.dismiss(id);
                                    }}
                                >
                                    &times;
                                </a>
                            </div>,
                            {
                                className: "errortoast",
                                position: "bottom-center",
                            }
                        );
                    }
                });
            }
        }
    };
    const validatePaymentMethodForm = () => {
        var error = false;

        var form_errors = formErrors;

        var firsterrorid = null;

        if (formValues.card_no == "") {
            form_errors = {
                ...form_errors,
                card_no: "Required field",
                error_class: "input_error",
            };
            error = true;
            firsterrorid = firsterrorid == null ? "card-number" : firsterrorid;
        } else if (!validator.isCreditCard(formValues.card_no)) {
            showFormError("card_no", "Enter valid Credit Card Number!");
            form_errors = {
                ...form_errors,
                card_no: "Enter valid Credit Card Number!",
                error_class: "input_error",
            };
            error = true;
            firsterrorid = firsterrorid == null ? "card-number" : firsterrorid;
        }
        /*if (formValues.card_name == "") {
            form_errors = {
                ...form_errors,
                card_name: "Required field",
                error_class: "input_error",
            };
            error = true;
            firsterrorid = firsterrorid == null ? "cardholder-name" : firsterrorid;
        }*/
        if (formValues.exp_date == "") {
            form_errors = {
                ...form_errors,
                exp_date: "Required field",
                error_class: "input_error",
            };
            error = true;
            firsterrorid = firsterrorid == null ? "expiration-date" : firsterrorid;
        }
        var new_exp_date = formValues.exp_date.replaceAll(" ","");


        var new_exp_date_arr = new_exp_date.split("/");

        
        if (new_exp_date.length == 5) {

            if (new Date().getTime() > new Date("20"+new_exp_date_arr[1]+"-"+new_exp_date_arr[0]+"-01").getTime() || isNaN(new Date("20"+new_exp_date_arr[1]+"-"+new_exp_date_arr[0]+"-01").getTime())
            ) {
                form_errors = {
                    ...form_errors,
                    exp_date: "Expiry cannot be past date",
                    error_class: "input_error",
                };
                error = true;
                firsterrorid = firsterrorid == null ? "expiration-date" : firsterrorid;
            }

        } else if (new_exp_date.length > 0) {
            form_errors = {
                ...form_errors,
                exp_date: "Invalid CVV/CVC",
                error_class: "input_error",
            };
            error = true;
            firsterrorid = firsterrorid == null ? "expiration-date" : firsterrorid;
        }
        if (formValues.cvv == "") {
            form_errors = {
                ...form_errors,
                cvv: "Required field",
                error_class: "input_error",
            };
            error = true;
            firsterrorid = firsterrorid == null ? "cvv" : firsterrorid;
        }


        setFormErrors(form_errors);

        if (firsterrorid != null) {
            document.getElementById(firsterrorid).scrollIntoView({
                behavior: "smooth",
                block: "center",
            });
        }

        return error;
    };
    const validatePaymentForm = () => {
        var error = false;

        
        if (formValues.card_no == "") {
            error = true;
            
        } else if (!validator.isCreditCard(formValues.card_no)) {
            error = true;
            
        }
        
        if (formValues.exp_date == "") {
            error = true;
            
        }
        var new_exp_date = formValues.exp_date.replaceAll(" ","");

        var new_exp_date_arr = new_exp_date.split("/");
        
        if (new_exp_date.length == 5) {

            if (new Date().getTime() > new Date("20"+new_exp_date_arr[1]+"-"+new_exp_date_arr[0]+"-01").getTime() || isNaN(new Date("20"+new_exp_date_arr[1]+"-"+new_exp_date_arr[0]+"-01").getTime())
            ) {
                
                error = true;
                
            }

        } else if (new_exp_date.length > 0) {
            
            error = true;
            
        }
        if (formValues.cvv == "") {
            
            error = true;
            
        }
        return error;
    };
    const validatePaymentFormError = () => {
        var error = false;

        
        if (!validator.isCreditCard(formValues.card_no)) {
            error = true;
            
        }
        
        var new_exp_date = formValues.exp_date.replaceAll(" ","");

        
        if (new_exp_date.length == 5) {

            if (new Date().getTime() > new Date("01/" + new_exp_date).getTime()
            ) {
                
                error = true;
                
            }

        } else if (new_exp_date.length > 0) {
            
            error = true;
            
        }
        if (formValues.cvv == "") {
            
            error = true;
            
        }
        return error;
    };
    const validateCreditCard = (value) => {
        if (!validator.isCreditCard(value)) {
            setFormErrors((oldValues) => ({ ...oldValues, "card_no": "Enter valid Credit Card Number!", "error_class": "card-error" }));
            // showFormError("card_no", "Enter valid Credit Card Number!");
        } else {
            setFormErrors((oldValues) => ({ ...oldValues, "card_no": null }));
            // showFormError("card_no", null);
        }
        var card_type = creditCardType(value);

        if(card_type.length > 0 && card_type[0].length){

            if (card_type[0].type != "visa" && card_type[0].type != "mastercard") {
                setFormErrors((oldValues) => ({ ...oldValues, "card_no": "Only visa and mastercard are allowed.", "error_class": "card-error" }));
            }  else {
                setFormErrors((oldValues) => ({ ...oldValues, "card_no": null }));
                // showFormError("card_no", null);
            }
        }  else {
            setFormErrors((oldValues) => ({ ...oldValues, "card_no": null }));
            // showFormError("card_no", null);
        } 
        /*if (formValues.card_type != "visa" && formValues.card_type != "mastercard") {
            setFormErrors((oldValues) => ({ ...oldValues, "card_no": "Only visa and mastercard are allowed.", "error_class": "card-error" }));
            // showFormError("card_no", "Only visa and mastercard are allowed.");
        } else {
            setFormErrors((oldValues) => ({ ...oldValues, "card_no": null }));
            // showFormError("card_no", null);
        }*/
    };
    const removePromoCode = () => {

        if (!couponLoading) {

            resetFormErrors();


            setCouponLoading(true);

            const token = ReactSession.get("token");

            var formData = {};

            formData.promo_code = formValues.promo_code;

            formData.test_id = test.id;

            removeCouponOnTestService(formData, token).then((response) => {

                setCouponLoading(false);

                if (response.success) {

                    setFormValues({
                        ...formValues,
                        promo_code: ""
                    })
                    setOrder(response.order);

                    var id = toast(
                        <ToastMessage type={"success"} message={response.message} closable={true} onClose={() => { toast.dismiss(); }} />,
                        {
                            className: "successtoast",
                            position: "bottom-center",
                            duration: globalsettings.toast_duration,
                        }
                    );

                } else {
                    var id = toast(
                        <ToastMessage type={"error"} message={response.message} closable={true} onClose={() => { toast.dismiss(); }} />,
                        {
                            className: "errortoast",
                            position: "bottom-center",
                            duration: globalsettings.toast_duration,
                        }
                    );
                }
            });


        }
    }
    const checkPublishBtnState = () => {
        if(formValues.payment_method_id=="" && order && order.amount > 0){

            var error = validatePaymentForm();

            if(error){
                return "disabled";
            } else {
                return "active";
            }
        }
        return "active";

    }
    const checkPublishConditions = () => {

        resetFormErrors();

        if (!formLoading) {

            var error = false;

            var address_error = false;

            var card_error = false;

            setFirstErrorRef(null);


            if (!error) {

                if (order && order.amount > 0) {
                    if (formValues.payment_method_id == '') {

                        card_error = validatePaymentMethodForm();

                        error = card_error;
                    }




                    if (!error) {

                        setPublishModal({
                            ...publishModal, open: true, "confirm": function () {
                                setPublishModal({
                                    ...publishModal,
                                    open: false
                                });
                                publishTest()
                            }
                        })
                    }


                } else {
                    setPublishModal({
                        ...publishModal, open: true, "confirm": function () {
                            setPublishModal({
                                ...publishModal,
                                open: false
                            });
                            publishTest()
                        }
                    })
                }
            }
        }
    };
    const publishTest = () => {

        if (!formLoading) {
            setFormLoading(true);

            const token = ReactSession.get("token");

            var formData = Object.assign({},{...formValues});

            formData["test_id"] = test.id;

            if (formValues.country) {
                formData["country"] = formValues.country.value;
            }
            formData["card_no"] = encryptClient(formData.card_no);

            formData["cvv"] = encryptClient(formData.cvv);

            publishCreditsTestService(formData, token).then((response) => {
                setFormLoading(false);

                if (response.success) {

                    if (response.client_secret) {
                        setFormValues({ ...formValues, order_id: response.order_id, invoice_id: response.invoice_id, method_id: response.method_id });

                        method_id = response.method_id;


                        stripe.confirmCardPayment(response.client_secret).then(handleStripeJsResult)

                    } else {
                        reset();

                        window.dataLayer = window.dataLayer || [];
                        window.dataLayer.push({
                            'event' : 'create_test_publish',
                            'type': test.methodology,
                            'option': test.target_group ? (test.target_group.target_type=="test-links"?"share":"recruit") : "share",
                            'userID' : ReactSession.get("user") ? ReactSession.get("user").user_id : ""
                        });


                        setSuccessModal({ open: true });

                        //navigate("/r/test/" + test.id + "/live");
                    }
                } else {
                    var id = toast(
                        <ToastMessage type={"error"} message={response.message} closable={true} onClose={() => { toast.dismiss(); }} />,
                        {
                            className: "errortoast",
                            position: "bottom-center",
                            duration: globalsettings.toast_duration,
                        }
                    );
                }
            });
        }
    }
    function handleStripeJsResult(result) {

        if (result.error) {
            // Show error in payment form
            if (method_id) {
                deletePaymentMethod(method_id);
            }
        } else {
            setFormLoading(true);

            var formData = formValues;

            console.log(formValues);

            const token = ReactSession.get("token");

            formData["payment_intent_id"] = result.paymentIntent.id;

            formData["method_id"] = method_id;

            formData["test_id"] = test.id;

            makePaymentForTestService(formData, token).then((response) => {
                setFormLoading(false);

                if (response.success) {

                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        'event' : 'create_test_publish',
                        'type': test.methodology,
                        'option': test.target_group ? (test.target_group.target_type=="test-links"?"share":"recruit") : "share",
                        'userID' : ReactSession.get("user") ? ReactSession.get("user").user_id : ""
                    });

                    setSuccessModal({ open: true });
                    //navigate("/r/test/" + test.id + "/live");

                } else {
                    var id = toast(
                        <div className="toastinner">
                            {response.message}
                            <a
                                onClick={() => {
                                    toast.dismiss(id);
                                }}
                            >
                                &times;
                            </a>
                        </div>,
                        {
                            className: "errortoast",
                            position: "bottom-center",
                        }
                    );
                }
            });

        }
    }
    const set = (name) => {
        return ({ target: { value } }) => {
            setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
            setFormErrors((oldValues) => ({ ...oldValues, [name]: null }));
        };
    };
    const setFormValue = (name, value) => {
        setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
    };
    const showFormError = (name, value) => {
        let formErrorsLocal = Object.assign({}, formErrors);
        for (var key in formErrors) {
            formErrorsLocal[key] = null;
        }
        formErrorsLocal[name] = value;
        formErrorsLocal["error_class"] = "input_error";
        setFormErrors(formErrorsLocal);
        console.log(formErrorsLocal);
    };
    const clearFormValue = (name) => {
        return ({ target: { value } }) => {
            setTimeout(function () {
                setFormValues((oldValues) => ({ ...oldValues, [name]: "" }));
            }, 1000);
        };
    };
    const resetFormErrors = () => {
        let formErrorsLocal = Object.assign({}, formErrors);
        for (var key in formErrors) {
            formErrorsLocal[key] = null;
        }
        setFormErrors(formErrorsLocal);
    };
    const setPublish = () => {
        var error = false;

        if (formValues.password_field == 1) {
            if (formValues.password == "") {
                setFormErrors({ ...formErrors, password: "Required!" });
                error = true;
            }
        }
        if (!error) {
            let token = ReactSession.get("token");

            setSaveLoading(true);

            publishTestService(
                {
                    test_id: test.id,
                    password: formValues.password_field == 1 ? formValues.password : "",
                    close_condition: formValues.close_condition,
                    participants: formValues.participants ? formValues.participants : participants_selected,
                    closed_on: moment(formValues.closed_on)
                        .utc()
                        .format("YYYY-MM-DD HH:mm:ss"),
                    validate: true,
                },
                token
            ).then((response) => {
                setSaveLoading(false);

                if (response.success) {
                    reset();
                    if (test.status == "draft") {
                        navigate("/r/test/" + test.id + "/live");
                    } else {
                        test.status = "published";
                        setTest(test);


                        toast(
                            <div className="toastinner">
                                <><FormattedMessage id="Your test has been published successfully." /></>
                                <a
                                    onClick={() => {
                                        toast.dismiss();
                                    }}
                                >
                                    &times;
                                </a>
                            </div>,
                            {
                                className: "successtoast",
                                position: "bottom-center",
                            }
                        );
                    }
                } else {
                    if (response.last_published) {
                        toast(
                            <div className="toastinner free-plan-published-notification">
                                <div className="free-plan-published-error d-flex">
                                    <p>You've reached your <strong>monthly active test limit (1/1)</strong>. But don't worry, you can publish this test by upgrading your plan. </p>

                                    <a
                                        onClick={(e) => {
                                            e.preventDefault();
                                            navigate("/r/billing-information");
                                        }}
                                        className="btn form-btn"
                                        href={"/r/billing-information"}
                                    >
                                        Upgrade to publish now
                                    </a>
                                </div>
                                <a
                                    onClick={() => {
                                        toast.dismiss();
                                    }}
                                >
                                    &times;
                                </a>
                            </div>,
                            {
                                className: "errortoast",
                                position: "bottom-center",
                            }
                        );
                    } else {
                        toast(
                            <div className="toastinner">
                                {response.message}
                                <a
                                    onClick={() => {
                                        toast.dismiss();
                                    }}
                                >
                                    &times;
                                </a>
                            </div>,
                            {
                                className: "errortoast",
                                position: "bottom-center",
                            }
                        );
                    }
                }
            });
        }
    };

    const applyCouponApi = () => {

        if (!couponLoading) {

            resetFormErrors();

            var error = false;

            if (!formValues.promo_code) {
                error = true;
                setFormErrors({ ...formErrors, promo_code: "Required field", error_class: "input_error" });
            }
            if (!error) {
                setCouponLoading(true);

                const token = ReactSession.get("token");

                var formData = {};

                formData.promo_code = formValues.promo_code;

                formData.test_id = test.id;

                applyCouponOnTestService(formData, token).then((response) => {

                    setCouponLoading(false);

                    if (response.success) {

                        setOrder(response.order);

                        var id = toast(
                            <ToastMessage type={"success"} message={response.message} closable={true} onClose={() => { toast.dismiss(); }} />,
                            {
                                className: "successtoast",
                                position: "bottom-center",
                                duration: globalsettings.toast_duration,
                            }
                        );

                    } else {

                        setCouponError(response.message);

                        /*var id = toast(
                            <ToastMessage type={"error"} message={response.message} closable={true} onClose={() => { toast.dismiss(); }} />,
                            {
                                className: "errortoast",
                                position: "bottom-center",
                            }
                        );*/
                    }
                });
            }

        }
    };
    const horizontalLabels = {
        participants_selected,
        5000: 5000
    }

    const validatelogic = (surveyQuestions) => {
        // console.log(surveyQuestions);
        // console.log("validatelogic");
        var error=false;
        surveyQuestions.forEach((question,index) => {
            if (JSON.parse(question).questionType != "singlechoice") {
                if(JSON.parse(question).is_logic==1 && JSON.parse(question).jump_to_question<index+2){
                    
                    error=true;
                }
                if(JSON.parse(question).is_logic==1 && JSON.parse(question).jump_to_question=="End survey"){
                    error=false;
                }
                //
                if(JSON.parse(question).error && JSON.parse(question).error==1){
                    // console.log(JSON.parse(question));
                    // console.log("logictobeupdated");
                    error=true;
                }
            }else{
                if(JSON.parse(question).is_logic==1){
                    JSON.parse(question).jumpTo.forEach((jumptoOption,i)=>{
                        if(jumptoOption!="End survey" && jumptoOption<index+2){
                            // console.log(JSON.parse(question));
                            // console.log("jumptoless");
                            error=true;
                        }
                        //
                        if(JSON.parse(question).error && JSON.parse(question).error[i]==1){
                            // console.log(JSON.parse(question));
                            // console.log("logictobeupdated");
                            error=true;
                        }
                    })
                }
            }
        }
        );

        if(error==true){
            showError(
                "Update the correct logic to proceed further."
            );
            navigate(
                "/r/test/" + test_id + "/survey?error=true"
            );
        }
    }
    
    const formatkg = value => value + ' kg'

    const [isPanelVisible, setIsPanelVisible] = useState(false);
    const panelRef = useRef(null);
    const orderRef = useRef(null);


    const togglePanel = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsPanelVisible((prevIsPanelVisible) => !prevIsPanelVisible);

    };

    const handleClickOutside = (event) => {
        if (panelRef.current && !panelRef.current.contains(event.target) && orderRef.current && !orderRef.current.contains(event.target) && !event.target.classList.contains("panel-info-right")) {
        
            setIsPanelVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
        document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <LayoutResearcher
            credits={summary.credits_balance}
            isLoading={false}
            wrapClass={"create-test-data-wrapper " + (test && test.language == "ar" ? "" : "")}
            activeMenu={""}
            openSupportPanel={show_support_panel} hideSupportPanel={()=>{setShowSupportPanel(false)}}
        >
             <Helmet>
                <title>Create {test && test.methodology ? renderTestTitleCreate(test.methodology):"test"} - Publish your test | UserQ</title>
            </Helmet>
            <div className="three-column-layout">
                <Header test={test} isLoading={isLoading} credits={settings.publishing_credits} isSaved={isSaved ? isSaved : false} />
                <div className="three-column-layout-body">
                    <div className="three-column-layout-left">
                        <Stepper
                            test={test}
                            completed_step={4}
                            stepCallback={() => {
                                //saveTargetGroupData(formValues, false, showActiveOption.activeOption); 
                            }}
                        />
                    </div>
                    <div className="three-column-layout-center recruit-layout-center publish-layout-center recuruitment-mid-sec">

                        {/* /* side panel for tab and mobile start here --> */}
                        <div className='mobile-panel-hold position-relative'>
                            <div className='estimatepanel-top-info d-flex align-items-center justify-content-between'>
                                <div className='panel-info-left d-flex align-items-center'>
                                    <Text type={'body-text-2'} fontWeight={'medium-font'} cssClasses={'gray-color'}>Payment Total</Text>

                                    <Text type={'h4'} cssClasses={'panel-total-price'}>
                                        {order.discount > 0 &&
                                        <span className="crdis-price-text">
                                    ${order.recruitment_fees + order.publishing_fees - order.credits_used}
                                </span>
                                        }
                                        ${order.amount > 0 ? order.amount : '0'}

                                    </Text>

                                </div>
                                <div className='panel-info-right'>
                                    <div className='clickable-wrap'onClick={togglePanel}  ref={orderRef} >&nbsp;</div>
                                    <div className={`order-summary-link d-flex align-items-center cursor-pointer ${isPanelVisible ? 'active' : ''}`}
                                         >
                                        <Text cssClasses={'gray-color'} type={'body-text-3'} fontWeight={'medium-font'}>Order Summary</Text>
                                        <Icon value={isPanelVisible ? 'Chevron-Up' : 'Chevron'} size={'medium'} hover={'true'} />
                                    </div>
                                </div>
                            </div>

                            {isPanelVisible && (

                                <div className="estimated-panel-side-bar pricepnl-small" ref={panelRef} >
                                    {order &&
                                    <div className="create-test-preview-area estimated-panel-size">
                                        <div className="create-test-preview-area-inner">
                                            <OrderSummary order={order} participants={order.participants} researcher_fee={test.researcher_fee?test.researcher_fee:0} />
                                        </div>
                                    </div>
                                    }
                                </div>
                            )}
                        </div>
                        {/* /* side panel for tab and mobile end here--> */}

                        <div className="create-test-publish-page-data">
                            <div className='mid-spacing'>
                            <div className="section-minimum-height">
                                {isLoading &&
                                    <div className="test-section-loader publish-page-loader"> <LoadingIcon /></div>
                                }
                                {!isLoading &&
                                <>
                                    <div className="publish-page-row">
                                        <div className="recurit-panel-top-head">
                                            <Text type={"h3"} fontWeight={"bold-font"}>You're nearly ready to publish!</Text>
                                            <Text type={"body-text-2"} fontWeight={"medium-font"}>A test will only go live when you publish it - start collecting real time data now.{" "}
                                                <Button
                                                    label="View test summary"
                                                    onClick={()=>{
                                                        setTestSummaryModal({
                                                            open: true,
                                                            test_id: test.id
                                                        });
                                                    }}
                                                    type="ghost"
                                                    cssclass={"purple-color text-decoration-underline"}
                                                />
                                            </Text>

                                        </div>


                                        {order  && (order.amount > 0 || order.coupon_code) &&
                                            <> 
                                                <PromoCode
                                                    discount={order.discount}
                                                    applyCoupon={() => { setCouponError(""); applyCouponApi() }}
                                                    removeCoupon={() => { if(couponError){
                                                        setCouponError("");
                                                        setFormValues({...formValues, promo_code:""})
                                                    } else {
                                                        setCouponError(""); removePromoCode()
                                                    } }}
                                                    formErrors={formErrors}
                                                    formValues={formValues}
                                                    coupon_code={order.coupon_code}
                                                    couponLoading={couponLoading}
                                                    setFormValues={setFormValues}
                                                    coupon_code_desc={order.coupon_code_desc}
                                                    couponError={couponError}
                                                />

                                            </>
                                        }

                                        {order && order.amount > 0 &&
                                            <div className="pay-on-fly-coupon-code-wrap pay-on-fly-payment-methods">
                                                <div className="promo-code-inner">
                                                    <div className="pay-on-fly-promo-code-hdr">
                                                        <Text type={'subtitle-2'} fontWeight={'medium-font'}>Payment method</Text>
                                                    </div>

                                                    <div className="promocode-inner-form-wrap pubish-payment-inner-wrap">

                                                        <div className="row">
                                                            <div className="col-md-12">

                                                                <div className="added-payment-method-cards-wrapper row">
                                                                    {paymentMethods.length>0 &&
                                                                    <Text type={"overline"}>SAVED CARD{paymentMethods.length>1?'S':''}</Text>
                                                                    }
                                                                    {renderPaymentMethods()}
                                                                </div>

                                                                {user.permission != "Editor" && (


                                                                    <>
                                                                    
                                                                        <CreditCardForm isSavedForFuture={true}  isValid={(!validatePaymentForm() && formValues.payment_method_id=="")?true:false} formValues={formValues} setFormValues={(formValues)=>{   setFormValues(formValues) } }/>
                                                                    </>
                                                                )}
                                                            </div>
                                                        </div>

                                                    

                                                        <div className="row">
                                                            <div className="col-md-7 publish-field-whidth">
                                                                <div className="button-wrap-publish-btn publish-page-bottom-wrap">
                                                                    {order.amount > 0 &&
                                                                        <div className="card-secure-text">
                                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M16.5 10.5V6.75C16.5 5.55653 16.0259 4.41193 15.182 3.56802C14.3381 2.72411 13.1935 2.25 12 2.25C10.8065 2.25 9.66193 2.72411 8.81802 3.56802C7.97411 4.41193 7.5 5.55653 7.5 6.75V10.5M6.75 21.75H17.25C17.8467 21.75 18.419 21.5129 18.841 21.091C19.2629 20.669 19.5 20.0967 19.5 19.5V12.75C19.5 12.1533 19.2629 11.581 18.841 11.159C18.419 10.7371 17.8467 10.5 17.25 10.5H6.75C6.15326 10.5 5.58097 10.7371 5.15901 11.159C4.73705 11.581 4.5 12.1533 4.5 12.75V19.5C4.5 20.0967 4.73705 20.669 5.15901 21.091C5.58097 21.5129 6.15326 21.75 6.75 21.75Z" stroke="#676767" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                            </svg>

                                                                            <span>This is a secure 128-bit SSL encrypted payment</span>
                                                                        </div>
                                                                    }
                                                                    <Button
                                                                        id="r_create_step_6_build_next_btn"
                                                                        type="primary"
                                                                        size={"large"}
                                                                        onClick={() => { checkPublishConditions() }}
                                                                        label={<>
                                                                            Publish {order.amount > 0 && <>for ${roundNumber(order.amount,2)}</>}
                                                                        </>}
                                                                        state={checkPublishBtnState()}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>




                                            </div>
                                        }
                                        {order && order.amount == 0 &&
                                            <>
                                            <div className="promocode-inner-form-wrap-1 pubish-payment-inner-wrap">
                                                <div className="row">
                                                    <div className="col-md-7 publish-field-whidth">
                                                        <div className="button-wrap-publish-btn publish-page-bottom-wrap">
                                                            {order.amount > 0 &&
                                                                <div className="card-secure-text">
                                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M16.5 10.5V6.75C16.5 5.55653 16.0259 4.41193 15.182 3.56802C14.3381 2.72411 13.1935 2.25 12 2.25C10.8065 2.25 9.66193 2.72411 8.81802 3.56802C7.97411 4.41193 7.5 5.55653 7.5 6.75V10.5M6.75 21.75H17.25C17.8467 21.75 18.419 21.5129 18.841 21.091C19.2629 20.669 19.5 20.0967 19.5 19.5V12.75C19.5 12.1533 19.2629 11.581 18.841 11.159C18.419 10.7371 17.8467 10.5 17.25 10.5H6.75C6.15326 10.5 5.58097 10.7371 5.15901 11.159C4.73705 11.581 4.5 12.1533 4.5 12.75V19.5C4.5 20.0967 4.73705 20.669 5.15901 21.091C5.58097 21.5129 6.15326 21.75 6.75 21.75Z" stroke="#676767" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                    </svg>

                                                                    <span>This is a secure 128-bit SSL encrypted payment</span>
                                                                </div>
                                                            }
                                                            <div className="publish-btn-custom">
                                                            <Button
                                                                id="r_create_step_6_build_next_btn"
                                                                type="primary"
                                                                size={"large"}
                                                                onClick={() => { checkPublishConditions() }}
                                                                label={<>
                                                                    {order.amount >0 &&
                                                                    <>Publish {<>for ${roundNumber(order.amount,2)}</>}</>
                                                                    }
                                                                    {order.amount ==0 &&
                                                                    <>Publish now</>
                                                                    }
                                                                </>}
                                                                state={checkPublishBtnState()}
                                                            />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            </>
                                        }
                                        
                                        
                                    </div>
                                </>
                                }
                            </div>
                                
                                <div className='btns-wrap-bottom'>
                                    <Button
                                        type={"ghost"}
                                        label={"Previous"}
                                        size={"large"}
                                        iconLeft={
                                            <Icon value="back-arrow" colorClass="gray-900-svg" size={"large"} />
                                        }
                                        onClick={() => { navigate("/r/test/" + test.id + "/recruit") }}
                                    />

                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="three-column-layout-right estimated-panel-side-bar publish-panel-sidebar">
                        {order &&
                            <div className="create-test-preview-area estimated-panel-size">
                                <div className="create-test-preview-area-inner">
                                    <OrderSummary order={order} participants={order.participants} researcher_fee={test.researcher_fee?test.researcher_fee:0} />
                                </div>
                            </div>
                        }

                        <a  onClick={(e)=>{
                                    e.preventDefault();

                                    setShowSupportPanel(true);

                                }} href={process.env.REACT_APP_URL+"r/support"} target="_blank" className='support-button'><svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.879 8.2817C11.05 7.2567 12.95 7.2567 14.121 8.2817C15.293 9.3067 15.293 10.9687 14.121 11.9937C13.918 12.1727 13.691 12.3197 13.451 12.4357C12.706 12.7967 12.001 13.4347 12.001 14.2627V15.0127M21 12.7627C21 13.9446 20.7672 15.1149 20.3149 16.2068C19.8626 17.2988 19.1997 18.2909 18.364 19.1267C17.5282 19.9624 16.5361 20.6253 15.4442 21.0776C14.3522 21.5299 13.1819 21.7627 12 21.7627C10.8181 21.7627 9.64778 21.5299 8.55585 21.0776C7.46392 20.6253 6.47177 19.9624 5.63604 19.1267C4.80031 18.2909 4.13738 17.2988 3.68508 16.2068C3.23279 15.1149 3 13.9446 3 12.7627C3 10.3757 3.94821 8.08656 5.63604 6.39873C7.32387 4.71091 9.61305 3.7627 12 3.7627C14.3869 3.7627 16.6761 4.71091 18.364 6.39873C20.0518 8.08656 21 10.3757 21 12.7627ZM12 18.0127H12.008V18.0207H12V18.0127Z" stroke="#9759CB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                        Support
                                    </a>

                    </div>
                </div>
            </div>

            <PublishModal
                open={publishModal.open}
                close={() => {
                    setPublishModal({ ...publishModal, open: false });
                }}
                confirm={publishModal.confirm}
            />

            <ConfirmationModal
                confirm_message={confirmModal.confirm_message}
                confirm_btn_title="Delete"
                confirm_title="Delete card"
                confirm_title_class={"text-danger"}
                openModal={confirmModal.open}
                close={() => {
                    setConfirmModal({ ...confirmModal, open: false });
                }}
                confirm={() => {
                    setConfirmModal({ ...confirmModal, open: false });
                    deletePaymentMethod(confirmModal.target_id);
                    if (formValues.payment_method_id == confirmModal.target_id) {
                        setFormValue("payment_method_id", "");
                    }
                }}
            />
            <ConfirmationModal openModal={formLoading?true:false} btnLoading={formLoading}/>
            <SuccessMessageModal
                open={success_modal.open}
                close={() => {
                    setSuccessModal({ ...success_modal, open: false })
                }}
                test={test}
                test_link={process.env.REACT_APP_URL + "t/" + encryptId(test.id, test)}
                title={"Congratulations"}
                subtitle={"Your test is live!"}
                btn={
                    <Button
                        type={"primary"}
                        size={"large"}
                        label="Your dashboard"
                        onClick={() => {
                            navigate("/dashboard");
                        }}
                    />
                }
            />
            <TestSummaryModal
                test_id={testSummaryModal.test_id}
                openModal={testSummaryModal.open}
                close={() => {
                    setTestSummaryModal({ ...testSummaryModal, open: false });
                }}
            />
        </LayoutResearcher>
    );
}
