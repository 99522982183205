import React, { useState, useEffect, useRef,useImperativeHandle } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import toast from "react-hot-toast";
import Options from './options';
import { isEmpty } from "lodash";
import { Dropdown } from "react-bootstrap";
import { Text } from "../Text/Text";
import { ToggleButton } from "../ToggleButton/ToggleButton";
import { Icon } from "../Icon/Icon";
import { Input } from "../Input/Input";
import { Button } from "../Button/Button";
import { FilterButtons } from "../FilterButtons/FilterButtons";
import { DropdownComponent } from "../Dropdown/Dropdown";
import { Tag } from "../Tag/Tag";
import { ToastMessage } from "../ToastMessage/ToastMessage";
import { settings } from "../../../../data/settings";
import { InputPlaceholder } from "../Input/InputPlaceholder";
import { GuideLink } from "../GuideLink/GuideLink";
import { QuestionImage } from "../QuestionImage/QuestionImage";

export const EditQuestionInput = React.forwardRef((props,ref)=> {
    // console.log(props.questionDetails);
    const [questionEditFormValues, setQuestionEditFormValues] = useState({
        question: props.questionDetails.question,
        is_optional: props.questionDetails.is_optional,
        is_logic: props.questionDetails.is_logic,
        jump_to: (props.questionDetails.jump_to_question) ? props.questionDetails.jump_to_question : "End Survey",
        image_id: props.questionDetails.image_id ? props.questionDetails.image_id:null,
        image_url: props.questionDetails.image_url ? props.questionDetails.image_url: null ,
    });

    const [questionEditFormErrors, setQuestionEditFormErrors] = useState({
        question: null,
        error_class: null,
    });

    const [allOptions, setAllOptions] = useState(props.questionDetails.questionType === "free" ? [] : [...props.questionDetails.options]);
    const [optionImages, setOptionImages] = useState(props.questionDetails.optionImages ? [...props.questionDetails.optionImages] : [] );
    
    const [isNoneOfTheAbove, setNoneOfTheAbove] = useState(false);
    const [noneOfTheAboveEnText] = useState("None of the above");
    const [noneOfTheAboveArText] = useState("لا شيء مما بالأعلى");

    const [tempQuestionType, setTempQuestionType] = useState(props.questionDetails.questionType);
    const [tempAllOptions, setTempAllOptions] = useState([""]);
    const [allJumpTo, setAllJumpTo] = useState([]);
    const [likertSubQuestions, setLikertSubQuestions] = useState(props.questionDetails.questionType === "likertscale" ? [...props.questionDetails.subQuestions] : ['']);
    const [unsavedOptions, setUnsavedOptions] = useState([]);

    const [dropdownOptions, setDropdownOptions] = useState([]);

    const [optionError, setOptionError] = useState("");
    const [optionErrorIndex, setOptionErrorIndex] = useState(null);
    const [otherOptionIncluded, setOtherOptionIncluded] = useState(props.other);
    const [ratingScale, setRatingScale] = useState("5");
    const queryAttr = "data-rbd-drag-handle-draggable-id";
    const [placeholderProps, setPlaceholderProps] = useState({});
    const [logicAdded, setLogicAdded] = useState(props.questionDetails.is_logic);
    const [jumpToListItems, setJumpToListItems] = useState([]);
    const [likertScaleLength, setLikertScaleLength] = useState((props.questionDetails.scale_length == '7 points') ? "7 points" : "5 points");
    const [likertScaleType, setLikertScaleType] = useState((props.questionDetails.scale_type) ? props.questionDetails.scale_type : "Satisfaction");
    const [likertStatement, setLikertStateMent] = useState("“How satisfied are you with the speed of response to your enquiry?”");

    const [ratingScaleLength, setRatingScaleLength] = useState(props.questionDetails.scale_length ? props.questionDetails.scale_length : "3 points");
    const [ratingScaleType, setRatingScaleType] = useState((props.questionDetails.scale_type) ? props.questionDetails.scale_type : "Numeric");
    const [ratingScaleLabels, setRatingScaleLabels] = useState((props.questionDetails.labels) ? props.questionDetails.labels : ["", "", ""]);

    const wrapperRef = useRef(null);

    const questionTypes = [{ label: "Free text", value: "free" }, { label: "Single choice", value: "singlechoice" }, { label: "Multiple choice", value: "multiplechoice" }, { label: "Likert scale", value: "likertscale" }, { label: "Rating scale", value: "ratingscale" }, { label: "Ranking scale", value: "rankingscale" }];

    
    const likert5ScaleOptions = [
        { label: props.language === "en" ? "Satisfaction" : "مقياس الرضا", value: props.language === "en" ? "Satisfaction" : "مقياس الرضا" },
        { label: props.language === "en" ? "Likelihood" : "مقياس الاحتمالية", value: props.language === "en" ? "Likelihood" : "مقياس الاحتمالية" },
        { label: props.language === "en" ? "Level of concern" : "مقياس مستوى القلق", value: props.language === "en" ? "Level of concern" : "مقياس مستوى القلق" },
        { label: props.language === "en" ? "Agreement" : "مقياس الاتفاق", value: props.language === "en" ? "Agreement" : "مقياس الاتفاق" },
        { label: props.language === "en" ? "Frequency" : "مقياس التكرار", value: props.language === "en" ? "Frequency" : "مقياس التكرار" },
        { label: props.language === "en" ? "Awareness" : "مقياس الوعي", value: props.language === "en" ? "Awareness" : "مقياس الوعي" },
        { label: props.language === "en" ? "Familiarity" : "مقياس الألفة", value: props.language === "en" ? "Familiarity" : "مقياس الألفة" },
        { label: props.language === "en" ? "Quality" : "مقياس الجودة", value: props.language === "en" ? "Quality" : "مقياس الجودة" },
        { label: props.language === "en" ? "Importance" : "مقياس الأهمية", value: props.language === "en" ? "Importance" : "مقياس الأهمية" },
    ];
    const likert7ScaleOptions = [
        { label: props.language === "en" ? "Agreement" : "مقياس الاتفاق", value: props.language === "en" ? "Agreement" : "مقياس الاتفاق" },
        { label: props.language === "en" ? "Frequency" : "مقياس التكرار", value: props.language === "en" ? "Frequency" : "مقياس التكرار" },
        { label: props.language === "en" ? "Appropriateness" : "مقياس الملاءمة", value: props.language === "en" ? "Appropriateness" : "مقياس الملاءمة" }, 
        { label: props.language === "en" ? "Satisfaction" : "مقياس الرضا", value: props.language === "en" ? "Satisfaction" : "مقياس الرضا" },
        { label: props.language === "en" ? "Reflective of me" : "مقياس عكس الشخصية", value: props.language === "en" ? "Reflective of me" : "مقياس عكس الشخصية" },
        { label: props.language === "en" ? "Level of difficulty" : "مستوى الصعوبة", value: props.language === "en" ? "Level of difficulty" : "مستوى الصعوبة" },
        { label: props.language === "en" ? "Priority" : "مقياس الأولوية", value: props.language === "en" ? "Priority" : "مقياس الأولوية" },
        { label: props.language === "en" ? "Quality" : "مقياس الجودة", value: props.language === "en" ? "Quality" : "مقياس الجودة" },
        { label: props.language === "en" ? "Importance" : "مقياس الأهمية", value: props.language === "en" ? "Importance" : "مقياس الأهمية" },
    ];


    const ratingScaleOptions = [
        { label: <span className="dropdown-icon"><Icon value={"Numeric"} size={"small"} /> {"   "}Numeric</span>, value: "Numeric" },
        { label: <span className="dropdown-icon"><Icon value={"Star"} size={"small"} /> {"   "}Stars</span>, value: "Stars" },
        { label: <span className="dropdown-icon"><Icon value={"Emotions"} size={"small"} /> {"   "}Emotions</span>, value: "Emotions" },
    ];

    const mapLikertOptions = (scaleType) => {
        if (likertScaleLength == "5 points") {
            if (scaleType == "Satisfaction" || scaleType == 'مقياس الرضا') {
                if (props.language == "en") {
                    setAllOptions(["Very dissatisfied", "Dissatisfied", "Neither dissatisfied or satisfied", "Satisfied", "Very satisfied", "Not applicable"]);
                }
                else {
                    setAllOptions(["مستاء جدا", "غير راض", "لا غير راض أو راض", "راضي", "راضي جدا", "لا ينطبق عليّ"]);
                }
                setLikertStateMent("“How satisfied are you with the speed of response to your enquiry?”");
            }
            if (scaleType == "Likelihood" || scaleType == 'مقياس الاحتمالية') {
                if (props.language == "en") {
                    setAllOptions(["Very unlikely", "Unlikely", "Neutral", "Likely", "Very likely", "Not applicable"]);
                }
                else {
                    setAllOptions(["مستبعد جدا", "غير مرجح", "محايد", "محتمل", "مرجح جدا", "لا ينطبق عليّ"]);
                }
                setLikertStateMent("“Would you recommend our customer care service to others, based on your experience?”");
            }
            if (scaleType == "Level of concern" || scaleType == 'مقياس مستوى القلق') {
                if (props.language == "en") {
                    setAllOptions(["Very unconcerned", "Unconcerned", "Neutral", "concerned", "Very Concerned", "Not applicable"]);
                } else {
                    setAllOptions([" غير قلق ابداً", "غير قلق", "محايد", "قلق", "قلق جداً", "لا ينطبق عليّ"]);
                }
                setLikertStateMent("“How concerned are you about the customer care service's ability to address your inquiries and issues?”");
            }
            if (scaleType == "Agreement" || scaleType == 'مقياس الاتفاق') {
                if (props.language == "en") {
                    setAllOptions(["Strongly disagree", "Disagree", "Neither agree or disagree", "Agree", "Strongly agree", "Not applicable"]);
                } else {
                    setAllOptions(["لا أوافق بشدة", "غير موافق", "لا أوافق ولا أعارض", "أوافق", "أوافق بشدة", "لا ينطبق عليّ"]);
                }
                setLikertStateMent("“The customer service representatives were helpful and knowledgeable”");
            }
            if (scaleType == "Frequency" || scaleType == 'مقياس التكرار') {
                if (props.language == "en") {
                    setAllOptions(["Never", "Rarely", "Sometimes", "Often", "Always", "Not applicable"]);
                } else {
                    setAllOptions(["أبدا", "نادرا", "أحيانا", "غالبا", "دائما", "لا ينطبق عليّ"]);
                }
                setLikertStateMent("“How often do you call our customer service line?”");
            }
            if (scaleType == "Awareness" || scaleType == 'مقياس الوعي') {
                if (props.language == "en") {
                    setAllOptions(["Very unaware", "Unaware", "Neither aware or unaware", "Aware", "Very aware", "Not applicable"]);
                } else {
                    setAllOptions(["غير مدرك جدا", "غير مدرك", "لا مدرك ولا غير مدرك", "مدرك", "مدرك جدا", "لا ينطبق عليّ"]);
                }
                setLikertStateMent("“Are you aware of our customer care service channels and resources for support?”");
            }
            if (scaleType == "Familiarity" || scaleType == 'مقياس الألفة') {
                if (props.language == "en") {
                    setAllOptions(["Very unfamiliar", "Unfamiliar", "Somewhat familiar", "Familiar", "Very familiar", "Not applicable"]);
                } else {
                    setAllOptions(["غير مألوف", "غير مألوف", "مألوف إلى حد ما", "مألوف", "مألوف جدا", "لا ينطبق عليّ"]);
                }
                setLikertStateMent("“How familiar are you with the various support options offered by the customer care service?”");
            }
            if (scaleType == "Quality" || scaleType == 'مقياس الجودة') {
                if (props.language == "en") {
                    setAllOptions(["Very poor", "Poor", "Acceptable", "Good", "Very good", "Not applicable"]);
                } else {
                    setAllOptions(["قليلة الجودة جدا", "قليلة الجودة", "مقبولة", "جيدة", "جيدة جدا", "لا ينطبق عليّ"]);
                }
                setLikertStateMent("“Attention to detail and accuracy in addressing my inquiries”");
            }
            if (scaleType == "Importance" || scaleType == 'مقياس الأهمية') {
                if (props.language == "en") {
                    setAllOptions(["Very unimportant", "Not important", "Neutral", "Important", "Very important", "Not applicable"]);
                } else {
                    setAllOptions(["غير مهمة للغاية", "غير مهمة", "محايدة", "مهمة", "مهمة جدا", "لا ينطبق عليّ"]);
                }
                setLikertStateMent("“How important is timely and satisfactory support from our customer care service?”");
            }
        }
        if (likertScaleLength == "7 points") {
            if (scaleType == "Agreement" || scaleType == 'مقياس الاتفاق') {
                if (props.language == "en") {
                    setAllOptions(["Strongly disagree", "Disagree", "Somewhat disagree", "Neither agree or disagree", "Somewhat agree", "Agree", "Strongly agree", "Not applicable"]);
                } else {
                    setAllOptions(["أعارض بشدة", "أعارض", "لا أوافق إلى حد ما", "لا أوافق ولا أعارض", "أوافق إلى حد ما", "أوافق", "أوافق بشدة", "لا ينطبق عليّ"]);
                }
                setLikertStateMent("“The customer service representatives were helpful and knowledgeable”");
            }
            if (scaleType == "Frequency" || scaleType == 'مقياس التكرار') {
                if (props.language == "en") {
                    setAllOptions(["Never", "Rarely (less than 10% of the time)", "Occasionally (about 30% of the time)", "Sometimes (about 50% of the time)", "Frequently (about 70% of the time)", "Usually (about 90% of the time)", "Everytime", "Not applicable"]);
                } else {
                    setAllOptions(["أبدا", "نادرا (أقل من 10% من المرات)", "من حين الى آخر (حوالي 30% من المرات)", "أحيانا (حوالي 50% من المرات)", "في كثير من الأحيان (حوالي 70% من المرات)", "عادة (حوالي 90% من المرات)", "كل مرة", "لا ينطبق عليّ"]);
                }
                setLikertStateMent("“How often do you call our customer service line?”");
            }
            if (scaleType == "Appropriateness" || scaleType == 'مقياس الملاءمة') {
                if (props.language == "en") {
                    setAllOptions(["Absolutely inappropriate", "Inappropriate", "Slightly Inappropriate", "Neutral", "Slightly appropriate", "Appropriate", "Absolutely appropriate", "Not applicable"]);
                } else {
                    setAllOptions(["غير ملائم  إطلاقا", "غير ملائم", "غير ملائم إلى حد ما", "محايد", "ملائم إلى حد ما", "ملائم", "ملائم تمام", "لا ينطبق عليّ"]);
                }
                setLikertStateMent("“The tone and language used by our customer care representatives”");
            }
            if (scaleType == "Satisfaction" || scaleType == 'مقياس الرضا') {
                if (props.language == "en") {
                    setAllOptions(["Very dissatisfied", "Dissatisfied", "Slightly dissatisfied", "Neutral", "Slightly satisfied", "Satisfied", "Very satisfied", "Not applicable"]);
                } else {
                    setAllOptions(["مستاء جدا", "غير راض", "غير راض إلى حد ما", "محايد", "راض إلى حد ما", "راضي", "راضي جدا", "لا ينطبق عليّ"]);
                }
                setLikertStateMent("“How satisfied are you with the speed of response to your enquiry?”");
            }
            if (scaleType == "Reflective of me" || scaleType == 'مقياس عكس الشخصية') {
                if (props.language == "en") {
                    setAllOptions(["Very untrue of me", "Untrue of me", "Somewhat untrue of me", "Neutral", "Somewhat true of me", "True of me", "Very true of me", "Not applicable"]);
                } else {
                    setAllOptions(["غير حقيقي ابدا بالنسبة لي", "غير حقيقي بالنسبة لي", "غير حقيقي بالنسبة لي إلى حد ما", "محايد", "حقيقي بالنسبة لي إلى حد ما", "حقيقي بالنسبة لي", "حقيقي جدا بالنسبة لي", "لا ينطبق عليّ"]);
                }
                setLikertStateMent("“I prefer customer care to respond to me via email, as I value an uninterrupted workday”");
            }
            if (scaleType == "Level of difficulty" || scaleType == 'مستوى الصعوبة') {
                if (props.language == "en") {
                    setAllOptions(["Very easy", "Easy", "Somewhat easy", "Neutral", "Somewhat hard", "Hard", "Very hard", "Not applicable"]);
                } else {
                    setAllOptions(["سهل جدا", "سهل", "سهل إلى حد ما", "محايد", "صعب إلى حد ما", "صعب", "صعب جدا", "لا ينطبق عليّ"]);
                }
                setLikertStateMent("“How easy is it to reach our customer care service via phone?”");
            }
            if (scaleType == "Priority" || scaleType == 'مقياس الأولوية') {
                if (props.language == "en") {
                    setAllOptions(["Not a priority", "Low priority", "Somewhat a priority", "Neutral", "Moderate priority", "High priority", "Essential priority", "Not applicable"]);
                } else {
                    setAllOptions(["ليست أولوية", "أولوية منخفضة", "أولوية إلى حد ما", "محايد", "أولوية معتدلة", "ذو أهمية عالية", "أولوية أساسية", "لا ينطبق عليّ"]);
                }
                setLikertStateMent("“Receiving proactive communication and updates from the customer care service”");
            }
            if (scaleType == "Quality" || scaleType == 'مقياس الجودة') {
                if (props.language == "en") {
                    setAllOptions(["Very poor", "Poor", "Below average", "Average", "Above Average", "Good", "Excellent", "Not applicable"]);
                } else {
                    setAllOptions(["قليلة الجودة جدا", "قليلة الجودة", "أقل من المتوسط", "متوسط", "فوق المتوسط", "جيد", "ممتاز", "لا ينطبق عليّ"]);
                }
                setLikertStateMent("“Attention to detail and accuracy in addressing my inquiries”");
            }
            if (scaleType == "Importance" || scaleType == 'مقياس الأهمية') {
                if (props.language == "en") {
                    setAllOptions(["Very unimportant", "Not important", "Slightly unimportant", "Neutral", "Slightly important", "Important", "Very important", "Not applicable"]);
                } else {
                    setAllOptions(["غير مهمة للغاية", "غير مهمة", "غير مهمة إلى حد ما", "محايد", "مهمة إلى حد ما", "مهمة", "مهمة جدا", "لا ينطبق عليّ"]);
                }
                setLikertStateMent("“How important is timely and satisfactory support from our customer care service?”");
            }
        }
    }
    useEffect(()=>{

        if(props.onUpdate){
            
            props.onUpdate({...questionEditFormValues, options: allOptions, optionImages: optionImages});
        }
    },[questionEditFormValues]);
    useEffect(()=>{
        if(props.onUpdate){
            props.onUpdate({...questionEditFormValues, options: allOptions, optionImages: optionImages});
        }
    },[allOptions]);

    useEffect(()=>{
        if(props.onUpdate){
            props.onUpdate({...questionEditFormValues, options: allOptions, optionImages: optionImages});
        }
    },[optionImages]);

    const showError = (error) => {
        toast(
            <ToastMessage type={"error"} message={error} closable={true} onClose={() => { toast.dismiss(); }} />,
            {
                id:"error_question",
                className: "errortoast",
                position: "bottom-center",
                duration: settings.toast_duration,
            }
        );
    };

    const showSuccess = (success) => {
        toast(
            <ToastMessage type={"success"} message={success} closable={true} onClose={() => { toast.dismiss(); }} />,
            {
                className: "successtoast",
                position: "bottom-center",
                duration: settings.toast_duration,
            }
        );
    };

    const setQuestion = (name) => {
        return ({ target: { value } }) => {
            setQuestionEditFormValues((oldValues) => ({ ...oldValues, [name]: value }));
            if(props.onUpdate){
                props.onUpdate({...questionEditFormValues, question: value });
            }
            if (value.length > 0) {
                setQuestionEditFormErrors({ question: null, error_class: null });
            }
        };
    };

    const saveOptionHandler = (index, value) => {

        if (value == "Other") {
            if (!allOptions.includes("Other")) {
                allOptions.splice(allOptions.length - 1, 1, value);
                setAllOptions([...allOptions]);
                
            }
            setOtherOptionIncluded(true);
        }
        else {
            allOptions.splice(index, 1, value);
            setAllOptions([...allOptions]);
        }

    };

    const saveOptionJumpToHandler = (index, value) => {
        allJumpTo.splice(index, 1, value);
        setAllJumpTo([...allJumpTo]);
    };
    // const closeOtherOptionHandler = () => {
    //     const list = [...allOptions];
    //     list.pop();
    //     setAllOptions(list);
    //     const listjumto = [...allJumpTo];
    //     listjumto.pop();
    //     setAllJumpTo(listjumto);

    //     const listOptionImages = [...optionImages];
    //     listOptionImages.pop();
    //     setOptionImages(listOptionImages);

    //     setOtherOptionIncluded(false);
    // };

    const closeOtherOptionHandler = () => {

        
        const list = [...allOptions];
        const listjumto = [...allJumpTo];
        const listOptionImages = [...optionImages];
        if(otherOptionIncluded === true && isNoneOfTheAbove === true){
            list.splice(-2, 1);
            listjumto.splice(-2, 1);
            listOptionImages.splice(-2, 1);
        } else {
            list.pop();
            listjumto.pop();
            listOptionImages.pop();
        }
        
        setAllOptions(list);
        
        setAllJumpTo(listjumto);

        setOptionImages(listOptionImages);

        setOtherOptionIncluded(false);

        
    }


    const closeNotaOptionHandler = () => {

        
        const list = [...allOptions];
        list.pop();
        
        setAllOptions(list);

        setNoneOfTheAbove(false);
        
    }


    const closeOptionHandler = (index) => {
        const list = [...allOptions];
        list.splice(index, 1);
        setAllOptions(list);
        const listjumto = [...allJumpTo];
        listjumto.splice(index, 1);
        setAllJumpTo(listjumto);

        const listOptionImages = [...optionImages];
        listOptionImages.splice(index, 1);
        setOptionImages(listOptionImages);
    };
    const editOptionHandler = (index) => {
        const list = [...allOptions];
        const optionToBeEdited = list[index];
        list.splice(index, 1);
        setAllOptions(list);

        const listUnsavedOptions = [...unsavedOptions];
        listUnsavedOptions.splice(0, 0, optionToBeEdited);
        setUnsavedOptions(listUnsavedOptions);
    };

    const addSubQuestionHandler = () => {
        likertSubQuestions.push("");
        setLikertSubQuestions([...likertSubQuestions]);
    };

    const removeSubQuestionHandler = (index) => {
        if (likertSubQuestions.length == 1) {
            showError(
                "Minimum one option is required."
            );
        } else {
            const list = [...likertSubQuestions];
            list.splice(index, 1);
            setLikertSubQuestions(list);
        }
    };

    useEffect(() => { }, [unsavedOptions, allOptions]);
    useEffect(() => {
        var list = [];
        for (var i = props.questionno + 1; i <= props.questionlist.length; i++) {
            list.push(i);
        }
        setJumpToListItems(list);

       // console.log(props.questionDetails.options);

        var checkNoneOptionExists = '';
        var checkOtherOptionExists = '';

        if(props.questionDetails.options && props.questionDetails.options.length>0){

            props.questionDetails.options.forEach((items)=>{
                //console.log(items)
                if(items === noneOfTheAboveEnText || items === noneOfTheAboveArText){
                    //  console.log('yes')

                    checkNoneOptionExists = true;
                }

                if(items === 'Other' || items === '' || items === 'آخر'){

                    // console.log('yes 1')
                    checkOtherOptionExists = true;

                }
            })
        }


        // check none of the above exists
        if(checkNoneOptionExists){
            setNoneOfTheAbove(true);
        }else{
            setNoneOfTheAbove(false);
        }

        // check other option exits
        if(checkOtherOptionExists){
            setOtherOptionIncluded(true);
        }else{
            setOtherOptionIncluded(false);
        }

        if (props.questionDetails.is_logic == 1) {
            setAllJumpTo(props.questionDetails.jumpTo ? props.questionDetails.jumpTo : []);
        }
        else {
            var jumptolist = [];
            for (var i = 0; i < allOptions.length; i++) {
                jumptolist.push(props.questionno < props.questionlist.length ? props.questionno + 1 : "End Survey");
            }
            setAllJumpTo(jumptolist);
        }

        var dropdownOptionsArray = [];

        console.log(list)

        list.forEach((item, i) => (
            <>
                {item == "End Survey" ?
                    dropdownOptionsArray.push({ label: "End survey", value: "End Survey" })
                    :
                    (item - props.questionno == 1) ?
                        dropdownOptionsArray.push({ label: "Next Question", value: item })
                        :
                        dropdownOptionsArray.push({ label: "Question " + item, value: item })
                }
            </>
        ));
        dropdownOptionsArray.push({ label: "End survey", value: "End Survey" });
        setDropdownOptions(dropdownOptionsArray);

        if (props.questionDetails.questionType == "ratingscale") {
            if (props.questionDetails.options.length == 5) {
                setRatingScale("5");
            } else if (props.questionDetails.options.length == 7) {
                setRatingScale("7");
            } else {
                setRatingScale("10");
            }
        }
        mapLikertOptions(props.questionDetails.scale_type);
    }, []);

    const showQuestionFormError = (name, value) => {
        let formErrorsLocal = Object.assign({}, questionEditFormErrors);

        for (var key in questionEditFormErrors) {
            formErrorsLocal[key] = null;
        }
        formErrorsLocal[name] = value;

        formErrorsLocal["error_class"] = "input_error";

        setQuestionEditFormErrors(formErrorsLocal);

    };

    useImperativeHandle(ref, () => ({
        handleQuestionSubmit: handleQuestionEditSubmit,
        resetErrors: resetErrors
    }));
    const resetErrors =   () => {
        let formErrorsLocal = Object.assign({}, questionEditFormErrors);

        for (var key in questionEditFormErrors) {
            formErrorsLocal[key] = null;
        }

        formErrorsLocal["error_class"] = "input_error";

        setQuestionEditFormErrors(formErrorsLocal);
    };
    const checkBtnstatus = () => {
        var error = false;

        if (questionEditFormValues.question === "") {
            
             error = true;
        } 
        if(props.questionDetails.questionType=="singlechoice" || props.questionDetails.questionType=="multiplechoice" || props.questionDetails.questionType=="multiplechoice"|| props.questionDetails.questionType=="rankingscale" ){
            let nulloptioncount = 0;
            allOptions.forEach((element) => {
                if (element !== "") {
                    nulloptioncount = nulloptioncount + 1;
                }
                
            });
            
            const notEmptyOptions = [...allOptions];
            for (var i = 0; i < notEmptyOptions.length; i++) {
                if (notEmptyOptions[i] == "" || notEmptyOptions[i] == " ") {
                    notEmptyOptions.splice(i, 1);
                    i--;
                }
            }
            if (nulloptioncount < 2) {
                error=true;   
            }
            
        }
        if(props.questionDetails.questionType=="likertscale" ){
            let nulloptioncount = 0;
            likertSubQuestions.forEach((element) => {
                if (element !== "") {
                    nulloptioncount = nulloptioncount + 1;
                }
                
            });
            
            const notEmptyOptions = [...likertSubQuestions];
            for (var i = 0; i < notEmptyOptions.length; i++) {
                if (notEmptyOptions[i] == "" || notEmptyOptions[i] == " ") {
                    notEmptyOptions.splice(i, 1);
                    i--;
                }
            }
            if (nulloptioncount < 1) {
                error=true;   
            }
            
        }
        return error;
    }
    const handleQuestionEditSubmit = () => {
        setOptionErrorIndex(null);

        var checkIfErrorExits = false;

        if (questionEditFormValues.question === "") {
            showQuestionFormError("question", "Required field");
            document.getElementById("question"+(props.id_prefix?props.id_prefix:"")).scrollIntoView({
                behavior: "smooth",
                block: "center",
            });
            // error = true;

            checkIfErrorExits = true;  // check if error exists while adding question
        } else {
            if (props.questionDetails.questionType === "free") {
                props.editQuestions({
                    id: props.questionDetails.id,
                    questionType: props.questionDetails.questionType,
                    question: questionEditFormValues.question,
                    is_optional: questionEditFormValues.is_optional,
                    is_logic: (props.methodology === 'Survey') ? questionEditFormValues.is_logic : false,
                    jump_to_question: (questionEditFormValues.is_logic) ? questionEditFormValues.jump_to : "",
                    error: props.questionDetails.error,
                    errorvalue: props.questionDetails.errorvalue,
                    manuallyupdated: props.questionDetails.manuallyupdated,
                    logicupdated: props.questionDetails.logicupdated,
                    image_id: questionEditFormValues.image_id,
                    image_url: questionEditFormValues.image_url

                });
                setQuestionEditFormValues({
                    question: "",
                    is_optional: true,
                });
                props.cancelEditQuestion();
            }
            if (props.questionDetails.questionType === "singlechoice") {
                let nulloptioncount = 0;
                var errorlist = [];
                var logicupdatedlist = [];
                var manuallyupdatedlist = [];
                var errorValuelist = [];
                allOptions.forEach((element) => {
                    if (element !== "") {
                        nulloptioncount = nulloptioncount + 1;
                    }
                    errorlist.push(0);
                    errorValuelist.push("");
                    manuallyupdatedlist.push(false);
                    logicupdatedlist.push(false);
                });
                const list = [...allOptions];
                const option_images_list = [...optionImages];

                for (var i = 0; i < list.length; i++) {
                    if (list[i] == "" || list[i] == " ") {
                        list.splice(i, 1);
                        option_images_list.splice(i,1);
                        i--;
                    }
                }
                if (nulloptioncount !== 0) {
                    if (nulloptioncount < 2) {
                        if(document.getElementById("question-options"+(props.id_prefix ? props.id_prefix : ""))) {
                            document.getElementById("question-options" + (props.id_prefix ? props.id_prefix : "")).scrollIntoView({
                                behavior: "smooth",
                                block: "center",
                            });
                        }
                        showError("Your answer is empty. Please enter at least two options.");
                        checkIfErrorExits = true;  // check if error exists while adding question
                    } else {

                        let all_jump_to_loc = [...allJumpTo];
                        if(isNoneOfTheAbove && !list.includes(props.language==='en'?noneOfTheAboveEnText:noneOfTheAboveArText)){
                            list.push(props.language==='en'?noneOfTheAboveEnText:noneOfTheAboveArText)
                        }else{
                            if(!isNoneOfTheAbove && list.includes(props.language==='en'?noneOfTheAboveEnText:noneOfTheAboveArText)){

                                    list.splice(list.length -1,1)

                            }
                        }

                        props.editQuestions({
                            id: props.questionDetails.id,
                            questionType: props.questionDetails.questionType,
                            question: questionEditFormValues.question,
                            is_optional: questionEditFormValues.is_optional,
                            is_logic: questionEditFormValues.is_logic,
                            jump_to_question: "",
                            options: list,
                            jumpTo: allJumpTo,
                            error: errorlist,
                            errorValue: errorValuelist,
                            manuallyupdated: manuallyupdatedlist,
                            logicupdated: logicupdatedlist,
                            image_id: questionEditFormValues.image_id,
                            image_url: questionEditFormValues.image_url,
                            optionImages: option_images_list

                        });
                        setQuestionEditFormValues({
                            question: "",
                            is_optional: true,
                        });
                        props.cancelEditQuestion();
                        setAllOptions([]);
                    }
                }
                else {

                    if(document.getElementById("question-options"+(props.id_prefix ? props.id_prefix : ""))) {
                        document.getElementById("question-options" + (props.id_prefix ? props.id_prefix : "")).scrollIntoView({
                            behavior: "smooth",
                            block: "center",
                        });
                    }
                    showError("Your answer is empty. Please enter at least two options.");
                    checkIfErrorExits = true;  // check if error exists while adding question
                }
            }
            if (props.questionDetails.questionType === "multiplechoice") {
                let nulloptioncount = 0;
                allOptions.forEach((element) => {
                    if (element !== "") {
                        nulloptioncount = nulloptioncount + 1;
                    }
                });
                const list = [...allOptions];
                const option_images_list = [...optionImages];
                for (var i = 0; i < list.length; i++) {
                    if (list[i] == "" || list[i] == " ") {
                        list.splice(i, 1);
                        option_images_list.splice(i,1);
                        i--;
                    }
                }
                if (nulloptioncount !== 0) {
                    if (nulloptioncount < 2) {

                        if(document.getElementById("question-options"+(props.id_prefix ? props.id_prefix : ""))) {
                            document.getElementById("question-options" + (props.id_prefix ? props.id_prefix : "")).scrollIntoView({
                                behavior: "smooth",
                                block: "center",
                            });
                        }
                        showError(
                            "Your answer is empty. Please enter at least two options."
                        );
                        checkIfErrorExits = true;  // check if error exists while adding question
                    } else {

                        if(isNoneOfTheAbove && !list.includes(props.language==='en'?noneOfTheAboveEnText:noneOfTheAboveArText)){
                            list.push(props.language==='en'?noneOfTheAboveEnText:noneOfTheAboveArText)
                        }else{
                            if(!isNoneOfTheAbove && list.includes(props.language==='en'?noneOfTheAboveEnText:noneOfTheAboveArText)){

                                list.splice(list.length -1,1)

                            }
                        }


                        props.editQuestions({
                            id: props.questionDetails.id,
                            questionType: props.questionDetails.questionType,
                            question: questionEditFormValues.question,
                            is_optional: questionEditFormValues.is_optional,
                            is_logic: (props.methodology === 'Survey') ? questionEditFormValues.is_logic : false,
                            jump_to_question: (questionEditFormValues.is_logic) ? questionEditFormValues.jump_to : "",
                            options: list,
                            error: props.questionDetails.error,
                            errorvalue: props.questionDetails.errorvalue,
                            manuallyupdated: props.questionDetails.manuallyupdated,
                            logicupdated: props.questionDetails.logicupdated,
                            image_id: questionEditFormValues.image_id,
                            image_url: questionEditFormValues.image_url,
                            optionImages: option_images_list
                        });
                        setQuestionEditFormValues({
                            question: "",
                            is_optional: true,
                        });
                        props.cancelEditQuestion();
                        setAllOptions([]);
                    }
                } else {
                    if(document.getElementById("question-options"+(props.id_prefix ? props.id_prefix : ""))) {
                        document.getElementById("question-options" + (props.id_prefix ? props.id_prefix : "")).scrollIntoView({
                            behavior: "smooth",
                            block: "center",
                        });
                    }
                    showError(
                        "Your answer is empty. Please enter at least two options."
                    );
                    checkIfErrorExits = true;  // check if error exists while adding question
                }
            }

            if (props.questionDetails.questionType === "rankingscale") {
                let nulloptioncount = 0;
                allOptions.forEach((element) => {
                    if (element !== "") {
                        nulloptioncount = nulloptioncount + 1;
                    }
                });
                const list = [...allOptions];
                for (var i = 0; i < list.length; i++) {
                    if (list[i] == "" || list[i] == " ") {
                        list.splice(i, 1);
                        i--;
                    }
                }

                if (nulloptioncount !== 0) {
                    if (nulloptioncount < 2) {

                        if(document.getElementById("question-options"+(props.id_prefix ? props.id_prefix : ""))) {
                            document.getElementById("question-options" + (props.id_prefix ? props.id_prefix : "")).scrollIntoView({
                                behavior: "smooth",
                                block: "center",
                            });
                        }
                        showError(
                            "Your answer is empty. Please enter at least two options."
                        );
                        checkIfErrorExits = true;  // check if error exists while adding question
                    } else {

                        if(list.includes(props.language==='en'?noneOfTheAboveEnText:noneOfTheAboveArText)){

                            list.splice(list.length -1,1)

                        }

                        props.editQuestions({
                            id: props.questionDetails.id,
                            questionType: props.questionDetails.questionType,
                            question: questionEditFormValues.question,
                            is_optional: questionEditFormValues.is_optional,
                            is_logic: (props.methodology === 'Survey') ? questionEditFormValues.is_logic : false,
                            jump_to_question: (questionEditFormValues.is_logic) ? questionEditFormValues.jump_to : "",
                            options: list,
                            error: props.questionDetails.error,
                            errorvalue: props.questionDetails.errorvalue,
                            manuallyupdated: props.questionDetails.manuallyupdated,
                            logicupdated: props.questionDetails.logicupdated,
                            image_id: questionEditFormValues.image_id,
                            image_url: questionEditFormValues.image_url
                        });
                        setQuestionEditFormValues({
                            question: "",
                            is_optional: true,
                        });
                        props.cancelEditQuestion();
                        setAllOptions([]);
                    }
                }
                else {
                    if(document.getElementById("question-options"+(props.id_prefix ? props.id_prefix : ""))) {
                        document.getElementById("question-options" + (props.id_prefix ? props.id_prefix : "")).scrollIntoView({
                            behavior: "smooth",
                            block: "center",
                        });
                    }
                    showError(
                        "Your answer is empty. Please enter at least two options."
                    );
                    checkIfErrorExits = true;  // check if error exists while adding question
                }
            }

            if (props.questionDetails.questionType === "likertscale") {

                let nulloptioncount = 0;
                allOptions.forEach((element) => {
                    if (element == "") {
                        nulloptioncount = nulloptioncount + 1;
                    }
                });
                if (nulloptioncount < 1) {
                    let nullsubQuestioncount = 0;
                    likertSubQuestions.forEach((element) => {
                        if (element !== "") {
                            nullsubQuestioncount = nullsubQuestioncount + 1;
                        }
                    });
                    const list = [...likertSubQuestions];
                    for (var i = 0; i < list.length; i++) {
                        if (list[i] == "" || list[i] == " ") {
                            list.splice(i, 1);
                            i--;
                        }
                    }
                    if (nullsubQuestioncount === 0) {

                        if(document.getElementById("question-options"+(props.id_prefix ? props.id_prefix : ""))) {
                            document.getElementById("question-options" + (props.id_prefix ? props.id_prefix : "")).scrollIntoView({
                                behavior: "smooth",
                                block: "center",
                            });
                        }
                        showError(
                            "Your answer is empty. Please enter at least one option."
                        );
                        checkIfErrorExits = true;  // check if error exists while adding question
                    } else {
                        props.editQuestions({
                            id: props.questionDetails.id,
                            questionType: props.questionDetails.questionType,
                            question: questionEditFormValues.question,
                            is_optional: questionEditFormValues.is_optional,
                            is_logic: (props.methodology === 'Survey') ? questionEditFormValues.is_logic : false,
                            jump_to_question: (questionEditFormValues.is_logic) ? questionEditFormValues.jump_to : "",
                            options: allOptions,
                            subQuestions: list,
                            scale_length: likertScaleLength,
                            scale_type: likertScaleType,
                            error: props.questionDetails.error,
                            errorvalue: props.questionDetails.errorvalue,
                            manuallyupdated: props.questionDetails.manuallyupdated,
                            logicupdated: props.questionDetails.logicupdated,
                            image_id: questionEditFormValues.image_id,
                            image_url: questionEditFormValues.image_url
                        });
                        setQuestionEditFormValues({
                            question: "",
                            is_optional: true,
                        });
                        props.cancelEditQuestion();
                        setAllOptions([]);

                    }
                } else {
                    if(document.getElementById("question-options"+(props.id_prefix ? props.id_prefix : ""))) {
                        document.getElementById("question-options" + (props.id_prefix ? props.id_prefix : "")).scrollIntoView({
                            behavior: "smooth",
                            block: "center",
                        });
                    }
                    showError(
                        "Your answer is empty. Please enter at least one option."
                    );
                    checkIfErrorExits = true;  // check if error exists while adding question
                }

            }

            if (props.questionDetails.questionType === "ratingscale") {

                props.editQuestions({
                    id: props.questionDetails.id,
                    questionType: props.questionDetails.questionType,
                    question: questionEditFormValues.question,
                    is_optional: questionEditFormValues.is_optional,
                    is_logic: (props.methodology === 'Survey') ? questionEditFormValues.is_logic : false,
                    jump_to_question: (questionEditFormValues.is_logic) ? questionEditFormValues.jump_to : "",
                    options: allOptions,
                    error: props.questionDetails.error,
                    errorvalue: props.questionDetails.errorvalue,
                    manuallyupdated: props.questionDetails.manuallyupdated,
                    logicupdated: props.questionDetails.logicupdated,
                    labels: ratingScaleLabels,
                    scale_length: ratingScaleLength,
                    scale_type: ratingScaleType,
                    image_id: questionEditFormValues.image_id,
                    image_url: questionEditFormValues.image_url
                });
                setQuestionEditFormValues({
                    question: "",
                    is_optional: true,
                });
                props.cancelEditQuestion();
                setAllOptions([]);

            }
        }

        return checkIfErrorExits;
    };

    function useOutsideAlerter(ref, questionFormValues) {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event) {
                
                if (ref.current && !ref.current.contains(event.target)) {
                    if (event.target.nodeName != "svg") {
                        if (event.target.nodeName != "path") {
                            if (questionFormValues.question != "") {
                                if (!event.target.classList.contains('icon-medium')) {
                                    if (!event.target.classList.contains('toast-close-icon')  && !event.target.classList.contains('btn')) {
                                        handleQuestionEditSubmit();
                                    }
                                }
                            }
                        }
                    }
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref, questionFormValues, allOptions, likertSubQuestions, ratingScaleLabels, ratingScaleLength, ratingScaleType]);
    }

    useOutsideAlerter(wrapperRef, questionEditFormValues);

    const handleDragStart = (event) => {
        const draggedDOM = getDraggedDom(event.draggableId);

        if (!draggedDOM) {
            return;
        }

        const { clientHeight, clientWidth } = draggedDOM;
        const sourceIndex = event.source.index;
        var clientY =
            parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
            [...draggedDOM.parentNode.children]
                .slice(0, sourceIndex)
                .reduce((total, curr) => {
                    const style = curr.currentStyle || window.getComputedStyle(curr);
                    const marginBottom = parseFloat(style.marginBottom);
                    const marginTop = parseFloat(style.marginTop);
                    return total + curr.clientHeight + marginTop;
                }, 0);

        setPlaceholderProps({
            clientHeight,
            clientWidth,
            clientY,
            clientX: parseFloat(
                window.getComputedStyle(draggedDOM.parentNode).paddingLeft
            ),
            background: '#CCCCCC',
            border: 'none'
        });
    };

    const handleDragUpdate = (event) => {
        if (!event.destination) {
            return;
        }

        const draggedDOM = getDraggedDom(event.draggableId);

        if (!draggedDOM) {
            return;
        }

        const { clientHeight, clientWidth } = draggedDOM;
        const destinationIndex = event.destination.index;

        const sourceIndex = event.source.index;

        const childrenArray = [...draggedDOM.parentNode.children];
        const movedItem = childrenArray[sourceIndex];
        childrenArray.splice(sourceIndex, 1);

        const updatedArray = [
            ...childrenArray.slice(0, destinationIndex),
            movedItem,
            ...childrenArray.slice(destinationIndex + 1),
        ];



        var clientY =
            parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
            updatedArray.slice(0, destinationIndex).reduce((total, curr) => {
                const style = curr.currentStyle || window.getComputedStyle(curr);
                const marginBottom = parseFloat(style.marginBottom);
                const marginTop = parseFloat(style.marginTop);
                return total + curr.clientHeight + marginTop;
            }, 0);

        setPlaceholderProps({
            clientHeight,
            clientWidth,
            clientY,
            clientX: parseFloat(
                window.getComputedStyle(draggedDOM.parentNode).paddingLeft
            ),
            background: 'none',
            border: '1px dashed'
        });
    };

    const getDraggedDom = (draggableId) => {
        const domQuery = `[${queryAttr}='${draggableId}']`;
        const draggedDOM = document.querySelector(domQuery);

        return draggedDOM;
    };

    return (
        <div ref={wrapperRef}>
            {props.questionDetails.questionType == "free" && (
                <div className={`survey-add-question-wrapper questioninputbox ${props.language == "ar" ? "arabic_wrapper" : ""}`}>
                    <div className={`bd-question-top-slide d-flex justify-content-between survey-question-dropdoen-wrap ${props.language == "ar" ? "arabic_wrapper" : ""}`}>
                        <div className="bd-que-slide-left">
                            <div className="db-que-list-wrap survey-question-drop-down">
                                <div className="dropdown">
                                    Question {props.questionno} -
                                </div>
                            </div>
                            <div className="small-dropdown change-questiontype-dropdown">
                                <Dropdown>
                                    <Dropdown.Toggle
                                        id="dropdownMenuButton"
                                        className="dropdown-toggle"
                                    >
                                        <div className="dropdown dropdown-question-type">Free text {" "}</div>
                                        <svg width="21" height="21" viewBox="0 0 18 18" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path d="M15 6L9 12L3 6" stroke="#9C9C9C" stroke-width="0.75" />
                                        </svg>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <div className="drop-down-link-hold">
                                            {questionTypes.map((item, i) => (
                                                item.value != props.questionDetails.questionType &&
                                                <Dropdown.Item
                                                    key={item.value + "-" + i}
                                                    onClick={() => {
                                                        props.editQuestions({
                                                            questionType: item.value,
                                                            question: questionEditFormValues.question,
                                                            is_optional: questionEditFormValues.is_optional,
                                                            options: allOptions,
                                                        });
                                                        if (item.value == "likertscale") {
                                                            props.addLikertSubquestions();
                                                            setTempAllOptions(allOptions.length == 0 ? [""] : [...allOptions]);
                                                            if (props.language == "en") {
                                                                // setAllOptions(["Strongly agree", "Somewhat agree", "Neutral", "Somewhat disagree", "Strongly disagree", "Not applicable"]);
                                                                mapLikertOptions(likertScaleType);
                                                            }
                                                            else {
                                                                setAllOptions(["أوافق بشدة", "أوافق إلى حد ما", "محايد", "اختلف إلى حد ما", "اختلف بشده", "لا ينطبق"]);
                                                            }
                                                        }
                                                        if (item.value == "ratingscale") {
                                                            setTempAllOptions(allOptions.length == 0 ? [""] : [...allOptions]);
                                                            if (props.language == "en") {
                                                                setAllOptions(["Strongly agree", "Somewhat agree", "Neutral", "Somewhat disagree", "Strongly disagree"]);
                                                            }
                                                            else {
                                                                setAllOptions(["أوافق بشدة", "أوافق إلى حد ما", "محايد", "اختلف إلى حد ما", "اختلف بشده"]);
                                                            }
                                                        }
                                                        if (item.value == "singlechoice") {
                                                            setAllOptions([...tempAllOptions]);
                                                            setQuestionEditFormValues({
                                                                ...questionEditFormValues,
                                                                is_logic: 0,
                                                            });
                                                            setLogicAdded(false);
                                                            console.log(allJumpTo);
                                                            allJumpTo.push(props.questionno < props.questionlist.length ? props.questionno + 1 : "End Survey");
                                                            setAllJumpTo([...allJumpTo]);
                                                        }
                                                        if (item.value == "multiplechoice" || item.value == "rankingscale") {
                                                            setAllOptions([...tempAllOptions]);
                                                        }
                                                    }}
                                                >
                                                    {item.label}

                                                </Dropdown.Item>
                                            ))}
                                        </div>
                                    </Dropdown.Menu>

                                </Dropdown>

                                <div className={'cross-q m-cross-q mobile-view'} onClick={() => {
                                    props.changeQuestionType(tempQuestionType);
                                    props.cancelEditQuestion();
                                    props.onDelete();
                                    setQuestionEditFormValues({
                                        question: "",
                                        is_optional: true,
                                    });
                                }}>
                                    <Icon value="delete" size="medium" hover={true} />
                                </div>
                            </div>
                        </div>
                        <div className="bd-que-slide-arrow">
                            <div className="sqa-check-top">
                                <div className="d-flex form-group custom-control custom-checkbox mb-0 dark-checkbox">
                                <div className="optional-text-hold d-flex align-items-center">

                                    <span>Optional</span>
                                    <span className="info-icon info-wrap position-relative">
                                        <Icon value="information" size="medium" hover={true} />
                                        <div className="tooltip-wrapper top-tooltip">
                                            <Text type='body-text-2' id='tooltip'
                                                children={"By enabling the optional toggle you’re allowing testers to skip this question"}
                                                fontWeight='normal-font' />
                                        </div>

                                    </span>
                                    </div>

                                    <ToggleButton
                                        id="customCheck1"
                                        isChecked={Boolean(questionEditFormValues.is_optional)}
                                        onChange={(e) => {

                                            if (props.checkOptionalCondition) {

                                                if (e.target.checked && props.noOfOptional == props.totalQuestions - 1) {
                                                    showError("At least one question has to be mandatory");
                                                    return;
                                                }
                                            }
                                            setQuestionEditFormValues({
                                                ...questionEditFormValues,
                                                is_optional: e.target.checked ? 1 : 0,
                                            })
                                        }
                                        }
                                    />
                                    {(props.methodology === 'Survey' && props.islogic) && (
                                        <div className="form-group custom-control mb-0 logicbuttonbox">

                                            <ToggleButton
                                                id="logicCheck"
                                                isChecked={Boolean(questionEditFormValues.is_logic)}
                                                label={<Text display="inline" fontWeight={'medium-font'} type="body-text-2">Add logic</Text>}
                                                onChange={(e) => {
                                                    setQuestionEditFormValues({
                                                        ...questionEditFormValues,
                                                        is_logic: e.target.checked ? 1 : 0,
                                                    });
                                                    setLogicAdded(logicAdded == true ? false : true);
                                                }
                                                }
                                            />
                                        </div>
                                    )}
                                    <div className={'cross-q'} onClick={() => {
                                        props.changeQuestionType(tempQuestionType);
                                        props.cancelEditQuestion();
                                        props.onDelete();
                                        setQuestionEditFormValues({
                                            question: "",
                                            is_optional: true,
                                        });

                                    }}>
                                        <Icon value="delete" size="medium" hover={true} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`create-projectmodal-form-group d-flex ${(!props.methodology || props.methodology=="" || props.methodology!="preference_test")?"input-with-image":""} ${props.language === "ar" ? 'arabic-add-logic-true' : ''} ${questionEditFormValues.is_logic ? 'add-logic-true' : ''}`}>
                        {(!props.methodology || props.methodology=="" || props.methodology!="preference_test") &&
                        <QuestionImage 
                            questionSection={props.questionSection?props.questionSection:null}
                            url={questionEditFormValues.image_url} 
                            id={questionEditFormValues.image_id} 
                            onImageUpload={(image_id,image_url)=>{
                                setQuestionEditFormValues({...questionEditFormValues, image_url: image_url, image_id: image_id});
                            }}
                        />
                        }
                        <Input
                            label="Your question"
                            onChange={setQuestion("question")}
                            onClear={() => {
                                if(props.onUpdate){
                                    props.onUpdate({...questionEditFormValues, question:""});
                                    
                                }
                                setQuestionEditFormValues((oldValues) => ({
                                    ...oldValues,
                                    question: "",
                                }));
                            }}
                            required={true}
                            value={questionEditFormValues.question}
                            maxLength={"350"}
                            error={questionEditFormErrors.question}
                            error_class={questionEditFormErrors.error_class}
                            id={"question"+(props.id_prefix?props.id_prefix:"")}
                            rtl={props.language == "ar" ? true : false}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    handleQuestionEditSubmit();
                                }
                            }}

                        />


                        {props.methodology === 'Survey' && questionEditFormValues.is_logic ? (
                            <DropdownComponent
                                label="Jump to"
                                options={dropdownOptions}
                                value={

                                    questionEditFormValues.jump_to != "End Survey" ? (questionEditFormValues.jump_to - props.questionno == 1 ? "Next question" : "Question " + questionEditFormValues.jump_to) : "End survey"

                                }
                                onChange={(item) => {
                                    setQuestionEditFormValues((oldValues) => ({
                                        ...oldValues,
                                        jump_to: item.value,
                                    }));
                                }}
                            />
                        ) : ''}
                    </div>
                    <div className="button-wrap d-flex justify-content-start">

                        <Button
                            label="Save"
                            onClick={handleQuestionEditSubmit}
                            state={checkBtnstatus() ? "disabled" : "active"}
                        />
                    </div>
                    <div class="create-test-preview-area">
                        <Text
                            type={'overline'}
                            children={'Preview'}
                        />
                        <div className="create-test-preview-area-inner">
                            <img src={
                                process.env.REACT_APP_URL + "img/free-question-preview.svg"}
                                alt="img" />
                        </div>
                        {props.methodology == "Survey" &&
                            <GuideLink methodology={"survey"} />
                        }
                    </div>
                </div>
            )}
            {(props.questionDetails.questionType == "singlechoice" ||
                props.questionDetails.questionType == "multiplechoice" || props.questionDetails.questionType == "rankingscale") && (
                    <div className={`survey-add-question-wrapper questioninputbox ${props.language == "ar" ? "arabic_wrapper" : ""}`}>
                        <div className={`bd-question-top-slide d-flex justify-content-between survey-question-dropdoen-wrap ${props.language == "ar" ? "arabic_wrapper" : ""} ${(props.questionDetails.questionType == "singlechoice" && props.islogic) ? 'addlogic-singlelogicbox-outer' : ''}`}>

                            <div className="bd-que-slide-left question-heading-left">
                                <div className="db-que-list-wrap survey-question-drop-down">
                                    <div className="dropdown">
                                        Question {props.questionno} -

                                    </div>

                                </div>
                                <div className="small-dropdown change-questiontype-dropdown">
                                    <Dropdown>
                                        <Dropdown.Toggle
                                            id="dropdownMenuButton"
                                            className="dropdown-toggle"
                                        >
                                            {props.questionDetails.questionType == "singlechoice" && (
                                                <div className="dropdown dropdown-question-type">
                                                    Single choice {" "}

                                                </div>
                                            )}
                                            {props.questionDetails.questionType == "multiplechoice" &&
                                                (
                                                    <div className="dropdown dropdown-question-type">
                                                        Multi choice {" "}

                                                    </div>
                                                )}
                                            {props.questionDetails.questionType == "rankingscale" &&
                                                (
                                                    <div className="dropdown dropdown-question-type">
                                                        Ranking scale {" "}

                                                    </div>
                                                )}
                                            <svg width="21" height="21" viewBox="0 0 18 18" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path d="M15 6L9 12L3 6" stroke="#9C9C9C" stroke-width="0.75" />
                                            </svg>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <div className="drop-down-link-hold">
                                                {questionTypes.map((item, i) => (
                                                    item.value != props.questionDetails.questionType &&
                                                    <Dropdown.Item
                                                        key={item.value + "-" + i}
                                                        onClick={() => {
                                                            
                                                            props.changeQuestionType(item.value);
                                                            if (item.value == "likertscale") {
                                                                setTempAllOptions([...allOptions]);
                                                                props.addLikertSubquestions();
                                                                setLikertSubQuestions([...likertSubQuestions]);
                                                                if (props.language == "en") {
                                                                    mapLikertOptions(likertScaleType);
                                                                }
                                                                else {
                                                                    setAllOptions(["أوافق بشدة", "أوافق إلى حد ما", "محايد", "اختلف إلى حد ما", "اختلف بشده", "لا ينطبق"]);
                                                                }
                                                            }
                                                            if (item.value == "ratingscale") {
                                                                setTempAllOptions([...allOptions]);
                                                                if (props.language == "en") {
                                                                    setAllOptions(["Strongly agree", "Somewhat agree", "Neutral", "Somewhat disagree", "Strongly disagree"]);
                                                                }
                                                                else {
                                                                    setAllOptions(["أوافق بشدة", "أوافق إلى حد ما", "محايد", "اختلف إلى حد ما", "اختلف بشده"]);
                                                                }
                                                            }
                                                            if (item.value == "singlechoice") {
                                                                if (props.questionType == "likertscale" || props.questionType == "likertscale") {
                                                                    setAllOptions([...tempAllOptions]);
                                                                }
                                                                setQuestionEditFormValues({
                                                                    ...questionEditFormValues,
                                                                    is_logic: 0,
                                                                    jump_to:[]
                                                                });
                                                                setLogicAdded(false);
                                                                allJumpTo.push(props.questionno < props.questionlist.length ? props.questionno + 1 : "End Survey");
                                                                setAllJumpTo([...allJumpTo]);
                                                            }
                                                            if (item.value == "multiplechoice" || item.value == "rankingscale") {
                                                                setLogicAdded(false);
                                                                setQuestionEditFormValues({
                                                                    ...questionEditFormValues,
                                                                    is_logic: 0,
                                                                    jump_to:[]
                                                                })
                                                                setLogicAdded(false);
                                                                setAllOptions([...allOptions]);
                                                            }
                                                            if (item.value == "free") {
                                                                setTempAllOptions([...allOptions]);
                                                                setLogicAdded(false);
                                                                setAllOptions([]);
                                                            }

                                                            if(item.value == "rankingscale"){

                                                                //console.log('asdas')
                                                                setNoneOfTheAbove(false);

                                                                //console.log(allOptions)
                                                                var checkIndexOther = '';
                                                                if(props.language === "ar"){
                                                                    checkIndexOther = allOptions.findIndex(x => x === 'آخر');
                                                                    if(checkIndexOther !== -1){
                                                                        allOptions.splice(checkIndexOther, 1, 'آخر');
                                                                        setAllOptions([...allOptions]);
                                                                        closeOtherOptionHandler();
                                                                    }

                                                                }else{
                                                                    checkIndexOther = allOptions.findIndex(x => x === 'Other');
                                                                    if(checkIndexOther !== -1){
                                                                        allOptions.splice(checkIndexOther, 1);

                                                                        //console.log(allOptions);

                                                                        closeOtherOptionHandler();

                                                                        setAllOptions([...allOptions]);
                                                                    }
                                                                }

                                                                //console.log(checkIndexOther)

                                                            }


                                                        }}
                                                    >
                                                        {item.label}

                                                    </Dropdown.Item>
                                                ))}
                                            </div>
                                        </Dropdown.Menu>

                                    </Dropdown>

                                    <div className={'cross-q m-cross-q mobile-view'} onClick={() => {
                                        props.changeQuestionType(tempQuestionType);
                                        props.cancelEditQuestion();
                                        props.onDelete();
                                        setQuestionEditFormValues({
                                            question: "",
                                            is_optional: true,
                                        });
                                    }}>
                                        <Icon value="delete" size="medium" hover={true} />
                                    </div>
                                </div>
                            </div>
                            <div className={`bd-que-slide-arrow add-logic-survey-option ${(props.questionDetails.questionType == "singlechoice" && props.islogic) ? 'addlogic-singlelogicbox' : ''}`}>
                                <div className="sqa-check-top singlechoice-addlogic">
                                    <div className="form-group custom-control custom-checkbox d-flex">
                                        <div className="optional-text-hold d-flex align-items-center">
                                        <span>Optional</span> 
                                        <span className="info-icon info-wrap position-relative"> 
                                            <Icon value="information" size="medium" hover={true} />
                                            <div className="tooltip-wrapper top-tooltip">
                                                <Text type='body-text-2' id='tooltip'
                                                    children={"By enabling the optional toggle you’re allowing testers to skip this question"}
                                                    fontWeight='normal-font' />
                                            </div>
                                        </span>
                                        </div>

                                        <ToggleButton
                                            id="customCheck1"
                                            isChecked={Boolean(questionEditFormValues.is_optional)}
                                            onChange={(e) => {
                                                if (props.checkOptionalCondition) {

                                                    if (e.target.checked && props.noOfOptional == props.totalQuestions - 1) {
                                                        showError("At least one question has to be mandatory");
                                                        return;
                                                    }
                                                }
                                                setQuestionEditFormValues({
                                                    ...questionEditFormValues,
                                                    is_optional: e.target.checked ? 1 : 0,
                                                });

                                            }
                                            }
                                        />

                                        {((props.methodology === 'Survey' || props.questionDetails.questionType === "singlechoice") && props.islogic) && (
                                            <div className="form-group custom-control mb-0 logicbuttonbox">

                                                <ToggleButton
                                                    id="logicCheck"
                                                    isChecked={Boolean(questionEditFormValues.is_logic)}
                                                    label={<Text display="inline" fontWeight={'medium-font'} type="body-text-2">Add logic</Text>}
                                                    onChange={(e) => {
                                                        setQuestionEditFormValues({
                                                            ...questionEditFormValues,
                                                            is_logic: e.target.checked ? 1 : 0,
                                                            jump_to:e.target.checked  ? props.questionno < props.questionlist.length ? props.questionno + 1 : "End Survey" : questionEditFormValues.jump_to
                                                        });
                                                        setLogicAdded(logicAdded == true ? false : true);
                                                        
                                                        var jumptolist = [];
                                                        for (var i = 0; i < allOptions.length; i++) {
                                                            jumptolist.push(props.questionno < props.questionlist.length ? props.questionno + 1 : "End Survey");
                                                        }
                                                        setAllJumpTo(jumptolist);
                                                    }
                                                    }
                                                />
                                            </div>
                                        )}
                                        <div className={'cross-q'} onClick={() => {
                                            props.changeQuestionType(tempQuestionType);
                                            props.cancelEditQuestion();
                                            props.onDelete();
                                            setQuestionEditFormValues({
                                                question: "",
                                                is_optional: true,
                                            });
                                            setOtherOptionIncluded(false);
                                        }}>
                                            <Icon value="delete" size="medium" hover={true} />

                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                        <div className={`create-projectmodal-form-group d-flex ${(!props.methodology || props.methodology=="" || props.methodology!="preference_test")?"input-with-image":""} ${props.language === "ar" ? 'arabic-add-logic-true' : ''} ${(props.methodology === 'Survey' && props.questionDetails.questionType !== "singlechoice") && questionEditFormValues.is_logic ? 'add-logic-true' : ''}`}>
                            {(!props.methodology || props.methodology=="" || props.methodology!="preference_test") &&
                            <QuestionImage
                                questionSection={props.questionSection?props.questionSection:null} 
                                url={questionEditFormValues.image_url} 
                                id={questionEditFormValues.image_id} 
                                onImageUpload={(image_id,image_url)=>{
                                    setQuestionEditFormValues({...questionEditFormValues, image_url: image_url, image_id: image_id});
                                }}
                            />
                            }
                            <Input
                                required={true}
                                label="Your question"
                                onChange={setQuestion("question")}
                                onClear={() => {
                                    if(props.onUpdate){
                                        props.onUpdate({...questionEditFormValues, question:""});
                                        
                                    }
                                    setQuestionEditFormValues((oldValues) => ({
                                        ...oldValues,
                                        question: "",
                                    }));
                                }}
                                value={questionEditFormValues.question}
                                maxLength="350"
                                error={questionEditFormErrors.question}
                                error_class={questionEditFormErrors.error_class}
                                id={"question"+(props.id_prefix?props.id_prefix:"")}
                                rtl={props.language == "ar" ? true : false}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        handleQuestionEditSubmit();
                                    }
                                }}
                            />

                            {props.methodology === 'Survey' && props.questionDetails.questionType !== "singlechoice" && questionEditFormValues.is_logic ? (
                                <DropdownComponent
                                    label="Jump to"
                                    options={dropdownOptions}
                                    value={

                                        questionEditFormValues.jump_to != "End Survey" ? (questionEditFormValues.jump_to - props.questionno == 1 ? "Next question" : "Question " + questionEditFormValues.jump_to) : "End survey"

                                    }
                                    onChange={(item) => {
                                        setQuestionEditFormValues((oldValues) => ({
                                            ...oldValues,
                                            jump_to: item.value,
                                        }));
                                    }}
                                />
                            ) : ''}
                        </div>
                        <div id={"question-options"+(props.id_prefix?props.id_prefix:"")} className={`ans-list-wrap ${questionEditFormValues.is_logic == 1 ? "survey-logic-added" : ""} ${props.language == "ar" ? "arabic_wrapper" : ""}`}>


                            <p className="small-info-heading">
                                {(props.questionType == "rankingscale") ?
                                    "Ranking options"
                                    :
                                    "Answers"
                                }
                            </p>
                            
                            <Options
                                questionSection={props.questionSection?props.questionSection:null}
                                allOptions={allOptions}
                                optionImages={optionImages}
                                edit={(index) => editOptionHandler(index)}
                                close={(index) => closeOptionHandler(index)}
                                closeOtherOption={(index) => closeOtherOptionHandler()}
                                save={(index, value) => saveOptionHandler(index, value)}
                                saveJumpTo={(index, value) => saveOptionJumpToHandler(index, value)}
                                saveOptionImage={(index, image_id, image_url) => {
                                    optionImages.splice(index, 1, {image_id: image_id, image_url: image_url});
                                    setOptionImages([...optionImages]);
                                }}
                                language={props.language}
                                addOption={(index) => {
                                    if (allOptions.length < 200) {
                                        allOptions.splice(index + 1, 0, "");
                                        setAllOptions([...allOptions]);
                                        allJumpTo.splice(index + 1, 0, props.questionno < props.questionlist.length ? props.questionno + 1 : "End Survey");
                                        setAllJumpTo([...allJumpTo]);

                                        optionImages.splice(index + 1, 0, {image_id:null, image_url:null});
                                        setOptionImages([...optionImages]);
                                    }
                                    else {

                                        showError("Max two hundred options can be added")
                                    }
                                }}
                                logicAdded={logicAdded}
                                jumpToList={jumpToListItems}
                                allJumpTo={allJumpTo}
                                questionno={props.questionno}
                                questionType={props.questionDetails.questionType}
                                noneOfTheAboveOption={isNoneOfTheAbove}
                                closeNoneOfTheAboveOption={()=>closeNotaOptionHandler()}
                                methodology={props.methodology}
                            />
                        </div>
                        <div className="add-survey-answer-btn">

                            <Button
                                iconLeft={<Icon value="add" />}
                                label={props.questionDetails.questionType != "rankingscale" ? "Add answer" : "Add options"}
                                type="ghost"
                                onClick={() => {
                                    if (allOptions.length < 200) {
                                        if (otherOptionIncluded == true || isNoneOfTheAbove == true) {
                                            let skipOptionsBackward = -1;

                                            if(otherOptionIncluded === true && isNoneOfTheAbove === true){
                                                skipOptionsBackward = -2;
                                            }

                                            allOptions.splice(skipOptionsBackward, 0, "");
                                            setAllOptions([...allOptions]);
                                            //optionImages.splice(-1,0,{image_id:null, image_url:null});
                                            //setOptionImages([...optionImages]);
                                            optionImages.push({image_id:null, image_url:null});
                                            setOptionImages([...optionImages]);
                                            allJumpTo.splice(skipOptionsBackward, 0, props.questionno < props.questionlist.length ? props.questionno + 1 : "End Survey");
                                            setAllJumpTo([...allJumpTo]);
                                        } else {
                                            allOptions.push("");
                                            setAllOptions([...allOptions]);
                                            optionImages.push({image_id:null, image_url:null});
                                            setOptionImages([...optionImages]);
                                            allJumpTo.push(props.questionno < props.questionlist.length ? props.questionno + 1 : "End Survey");
                                            setAllJumpTo([...allJumpTo]);
                                        }
                                    } else {
                                        showError("Max two hundred options can be added");
                                    }
                                }}
                            />

                            {props.questionDetails.questionType != "rankingscale" && (
                            <div className="add-other-survey add-othrnone-survey">
                                <span className="add-other-survey-left">
                                <span className="body-text body-text-3 medium-font">
                                        Do you want to add "Other" option or "None of the above"?
                                    </span>
                                     
                                </span>

                                <div className="add-othrnone-survey-btn">
                                <Button
                                    size={'small'}
                                    type={'secondary'}
                                    iconLeft={<Icon value={'add'} />}
                                    label={'Add other'}
                                    state={otherOptionIncluded?'disabled':''}
                                    onClick={() => {
                                        if(!otherOptionIncluded){
                                            if (allOptions.length < 200) {
                                                if (props.language == "ar") {
                                                    if(isNoneOfTheAbove){
                                                        allOptions.splice(-1, 0, "آخر");
                                                        allJumpTo.splice(-1, 0, props.questionno < props.questionlist.length ? props.questionno + 1 : "End Survey");
                                                        setAllOptions([...allOptions]);
                                                        setAllJumpTo([...allJumpTo]);
                                                    } else {
                                                        allOptions.push("آخر");
                                                        allJumpTo.push();
                                                        setAllOptions([...allOptions]);
                                                        setAllJumpTo([...allJumpTo]);
                                                    }
                                                }
                                                else {
                                                    const alloptDup = [...allOptions];
                                                    if(isNoneOfTheAbove){
                                                        allOptions.splice(-1, 0, "Other");
                                                        allJumpTo.splice(-1, 0, props.questionno < props.questionlist.length ? props.questionno + 1 : "End Survey");
                                                        setAllOptions([...allOptions]);
                                                        setAllJumpTo([...allJumpTo]);
                                                    } else {
                                                        allOptions.push("Other");
                                                        allJumpTo.push(props.questionno < props.questionlist.length ? props.questionno + 1 : "End Survey");
                                                        setAllOptions([...allOptions]);
                                                        setAllJumpTo([...allJumpTo]);
                                                    }
                                                }
                                                setOtherOptionIncluded(true);
                                                allJumpTo.push(props.questionno < props.questionlist.length ? props.questionno + 1 : "End Survey");
                                                setAllJumpTo([...allJumpTo]);
                                            } else {
                                                showError("Max two hundred options can be added.");
                                            }

                                        }
                                    }}
                                />

                                <Button
                                    size={'small'}
                                    type={'secondary'}
                                    state={isNoneOfTheAbove?'disabled':''}
                                    iconLeft={< Icon value={'add'} />}
                                    label={`Add none of the above`}
                                    cssclass={'none-of-the-above-text'}
                                    onClick={() => {
                                        
                                        if(!isNoneOfTheAbove){

                                            if (props.language == "ar") {
                                                 allOptions.push("لا شيء مما بالأعلى");  
                                            } else{
                                                allOptions.push("None of the above");
                                            }
                                            

                                            allJumpTo.push(props.questionno < props.questionlist.length ? props.questionno + 1 : "End Survey");
                                            setAllJumpTo([...allJumpTo]);
                                            setAllOptions([...allOptions]);
                                            setNoneOfTheAbove(true);
                                        }

                                    }}
                                />
                                </div>


                            </div>
                            )}
                        </div>
                        <div className="button-wrap d-flex justify-content-start">
                            <Button
                                label="Save"
                                onClick={handleQuestionEditSubmit}
                                state={checkBtnstatus() ? "disabled" : "active"}
                            />
                        </div>
                        <div className="create-test-preview-area">
                            <Text
                                type={'overline'}
                                children={'Preview'}
                            />
                            <div className="create-test-preview-area-inner">
                                {props.questionDetails.questionType == "rankingscale" ?
                                    <img src={process.env.REACT_APP_URL + "img/ranking-question-preview.svg"} alt="img" />
                                    :
                                    <img src={process.env.REACT_APP_URL + "img/multiple-question-preview.svg"} alt="img" />}
                            </div>
                            {props.methodology == "Survey" &&
                                <GuideLink methodology={"survey"} />
                            }
                        </div>
                    </div>
                )}

            {/* Akshay:Added Code to Edit Ratingscale */}
            {(props.questionDetails.questionType == "ratingscale") && (
                <div className={`survey-question-holder questioninputbox ${props.language == "ar" ? "arabic_wrapper" : ""}`}>
                    <div className="bd-question-top-slide d-flex justify-content-between survey-question-dropdoen-wrap">
                        <div className="bd-que-slide-left">
                            <div className="db-que-list-wrap survey-question-drop-down">
                                <div className="dropdown">
                                    Question {props.questionno} -
                                </div>
                            </div>
                            <div className="small-dropdown change-questiontype-dropdown">
                                <Dropdown>
                                    <Dropdown.Toggle
                                        id="dropdownMenuButton"
                                        className="dropdown-toggle"
                                    >
                                        <div className="dropdown dropdown-question-type">Rating scale {" "}</div>
                                        <svg width="21" height="21" viewBox="0 0 18 18" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path d="M15 6L9 12L3 6" stroke="#9C9C9C" stroke-width="0.75" />
                                        </svg>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <div className="drop-down-link-hold">
                                            {questionTypes.map((item, i) => (
                                                item.value != props.questionDetails.questionType &&
                                                <Dropdown.Item
                                                    key={item.value + "-" + i}
                                                    onClick={() => {
                                                        
                                                        props.changeQuestionType(item.value);

                                                        
                                                        if (item.value == "likertscale") {
                                                            props.addLikertSubquestions();
                                                            setTempAllOptions(allOptions.length == 0 ? [""] : [...allOptions]);
                                                            
                                                            if (props.language == "en") {
                                                                mapLikertOptions(likertScaleType);
                                                            }
                                                            else {
                                                                setAllOptions(["أوافق بشدة", "أوافق إلى حد ما", "محايد", "اختلف إلى حد ما", "اختلف بشده", "لا ينطبق"]);
                                                            }
                                                        }
                                                        if (item.value == "singlechoice") {
                                                            setAllOptions([...tempAllOptions]);
                                                            setQuestionEditFormValues({
                                                                ...questionEditFormValues,
                                                                is_logic: 0,
                                                            });
                                                            setLogicAdded(false);
                                                            allJumpTo.push(props.questionno < props.questionlist.length ? props.questionno + 1 : "End Survey");
                                                            setAllJumpTo([...allJumpTo]);
                                                        }
                                                        if (item.value == "multiplechoice" || item.value == "ratingscale" || item.value == "rankingscale" || item.value == "free") {
                                                            setAllOptions([...tempAllOptions]);
                                                        }
                                                    }}
                                                >
                                                    {item.label}

                                                </Dropdown.Item>
                                            ))}
                                        </div>
                                    </Dropdown.Menu>

                                </Dropdown>

                                <div className={'cross-q m-cross-q mobile-view'} onClick={() => {
                                    props.changeQuestionType(tempQuestionType);
                                    props.cancelEditQuestion();
                                    props.onDelete();
                                    setQuestionEditFormValues({
                                        question: "",
                                        is_optional: true,
                                    });
                                }}>
                                    <Icon value="delete" size="medium" hover={true} />
                                </div>
                            </div>
                        </div>
                        <div className="bd-que-slide-arrow add-logic-survey">
                            <div className="sqa-check-top">
                                <div className="form-group custom-control custom-checkbox mb-0 dark-checkbox">
                                <div className="optional-text-hold d-flex align-items-center">

                                    <span>Optional</span>
                                    <span className="info-icon info-wrap position-relative">
                                        <Icon value="information" size="medium" hover={true} />
                                        <div className="tooltip-wrapper top-tooltip">
                                            <Text type='body-text-2' id='tooltip'
                                                children={"By enabling the optional toggle you’re allowing testers to skip this question"}
                                                fontWeight='normal-font' />
                                        </div>
                                    </span>
                                    </div>

                                    <ToggleButton
                                        id="customCheck1"
                                        isChecked={Boolean(questionEditFormValues.is_optional)}
                                        onChange={(e) => {
                                            if (props.checkOptionalCondition) {

                                                if (e.target.checked && props.noOfOptional == props.totalQuestions - 1) {
                                                    showError("At least one question has to be mandatory");
                                                    return;
                                                }
                                            }
                                            setQuestionEditFormValues({
                                                ...questionEditFormValues,
                                                is_optional: e.target.checked ? 1 : 0,
                                            })

                                        }
                                        }
                                    />
                                    {((props.methodology === 'Survey') && props.islogic) && (
                                        <div className="form-group custom-control mb-0 logicbuttonbox">

                                            <ToggleButton
                                                id="logicCheck"
                                                isChecked={Boolean(questionEditFormValues.is_logic)}
                                                label={<Text display="inline" fontWeight={'medium-font'} type="body-text-2">Add logic</Text>}
                                                onChange={(e) => {
                                                    setQuestionEditFormValues({
                                                        ...questionEditFormValues,
                                                        is_logic: e.target.checked ? 1 : 0,
                                                    });
                                                    setLogicAdded(logicAdded == true ? false : true);
                                                }
                                                }
                                            />
                                        </div>
                                    )}

                                    <div className={'cross-q'} onClick={() => {
                                        props.changeQuestionType(tempQuestionType);
                                        props.cancelEditQuestion();
                                        props.onDelete();
                                        setQuestionEditFormValues({
                                            question: "",
                                            is_optional: true,
                                        });
                                    }}>
                                        <Icon value="delete" size="medium" hover={true} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`create-projectmodal-form-group d-flex ${(!props.methodology || props.methodology=="" || props.methodology!="preference_test")?"input-with-image":""} ${props.language === "ar" ? 'arabic-add-logic-true' : ''} ${(props.methodology === 'Survey') && questionEditFormValues.is_logic ? 'add-logic-true' : ''}`}>
                        {(!props.methodology || props.methodology=="" || props.methodology!="preference_test") &&
                        <QuestionImage 
                            questionSection={props.questionSection?props.questionSection:null}
                            url={questionEditFormValues.image_url} 
                            id={questionEditFormValues.image_id} 
                            onImageUpload={(image_id,image_url)=>{
                                
                                setQuestionEditFormValues({...questionEditFormValues, image_url: image_url, image_id: image_id});
                            }}
                        />
                        }
                        <Input
                            required={true}
                            label="Your question"
                            onChange={setQuestion("question")}
                            onClear={() => {
                                if(props.onUpdate){
                                    props.onUpdate({...questionEditFormValues, question:""});
                                    
                                }
                                setQuestionEditFormValues((oldValues) => ({
                                    ...oldValues,
                                    question: "",
                                }));
                            }}
                            value={questionEditFormValues.question}
                            maxLength="350"
                            error={questionEditFormErrors.question}
                            error_class={questionEditFormErrors.error_class}
                            id={"question"+(props.id_prefix?props.id_prefix:"")}
                            rtl={props.language == "ar" ? true : false}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    handleQuestionEditSubmit();
                                }
                            }}
                        />
                        {props.methodology === 'Survey' && questionEditFormValues.is_logic ? (
                            <DropdownComponent
                                label="Jump to"
                                options={dropdownOptions}
                                value={

                                    questionEditFormValues.jump_to != "End Survey" ? (questionEditFormValues.jump_to - props.questionno == 1 ? "Next question" : "Question " + questionEditFormValues.jump_to) : "End survey"

                                }
                                onChange={(item) => {
                                    setQuestionEditFormValues((oldValues) => ({
                                        ...oldValues,
                                        jump_to: item.value,
                                    }));
                                }}
                            />
                        ) : ''}
                    </div>

                    <div className="points-outer rating-scale-dropdown-wrap">
                        <div className="scalebuttonbox rating-scale-option-wrap">
                            <span>Scale length</span>
                            <FilterButtons options={ratingScaleType == "Stars" ? ["3 points", "5 points"] : ["3 points", "5 points", "7 points", "10 points"]} selected={ratingScaleLength}
                                onChange={(value) => {
                                    setRatingScaleLength(value);
                                }} />
                        </div>
                        <DropdownComponent
                            label="Scale type"
                            options={ratingScaleOptions}
                            value={ratingScaleType} onChange={(item) => {
                                setRatingScaleType(item.value);
                                if (item.value == "Stars") {
                                    if (ratingScaleLength == "7 points" || ratingScaleLength == "10 points") {
                                        setRatingScaleLength("3 points");
                                    }
                                }
                            }} />

                    </div>
                    <div className="rating-scale-labels-container d-flex">
                        {ratingScaleLabels.map((label, index) => (
                            <div className="rating-scale-label">
                                <InputPlaceholder
                                    required={true}
                                    clearField={false}
                                    value={label}
                                    label={
                                        index == 0 ? "Left label" :
                                            index == 1 ? "Middle label" : "Right label"
                                    }
                                    placeholder={
                                        index == 0 ? "Left label" :
                                            index == 1 ? "Middle label" : "Right label"
                                    }
                                    onChange={(e) => {
                                        ratingScaleLabels.splice(index, 1, e.target.value);
                                        setRatingScaleLabels([...ratingScaleLabels]);
                                    }}
                                    onKeyDown={(e) => {

                                    }}
                                    autoFocus={false}
                                    rtl={props.language == "ar" ? true : false}
                                    maxLength={50}
                                />
                            </div>
                        ))}
                    </div>
                    <div className="button-wrap d-flex justify-content-start">

                        <Button
                            label="Save"
                            onClick={handleQuestionEditSubmit}
                            state={checkBtnstatus() ? "disabled" : "active"}
                        />
                    </div>
                    <div className="create-test-preview-area rating-scale-preview">
                        <Text
                            type={'overline'}
                            children={'Preview'}
                        />
                        <div className="create-test-preview-area-inner">
                            <img src={process.env.REACT_APP_URL + "img/rating-question-preview.svg"} alt="img" />
                        </div>
                        {props.methodology == "Survey" &&
                            <GuideLink methodology={"survey"} />
                        }
                    </div>
                </div>
            )}
            {(props.questionDetails.questionType == "likertscale") && (
                <div className={`survey-question-holder questioninputbox ${props.language == "ar" ? "arabic_wrapper" : ""}`}>
                    <div className="bd-question-top-slide d-flex justify-content-between survey-question-dropdoen-wrap">
                        <div className="bd-que-slide-left">
                            <div className="db-que-list-wrap survey-question-drop-down">
                                <div className="dropdown">
                                    Question {props.questionno} -
                                </div>
                            </div>
                            <div className="small-dropdown change-questiontype-dropdown">
                                <Dropdown>
                                    <Dropdown.Toggle
                                        id="dropdownMenuButton"
                                        className="dropdown-toggle"
                                    >
                                        <div className="dropdown dropdown-question-type">Likert scale {" "}</div>
                                        <svg width="21" height="21" viewBox="0 0 18 18" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path d="M15 6L9 12L3 6" stroke="#9C9C9C" stroke-width="0.75" />
                                        </svg>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <div className="drop-down-link-hold">
                                            {questionTypes.map((item, i) => (
                                                item.value != props.questionDetails.questionType &&
                                                <Dropdown.Item
                                                    key={item.value + "-" + i}
                                                    onClick={() => {
                                                        props.changeQuestionType(item.value);
                                                        if (item.value == "ratingscale") {
                                                            if (props.language == "en") {
                                                                setAllOptions(["Strongly agree", "Somewhat agree", "Neutral", "Somewhat disagree", "Strongly disagree"]);
                                                            }
                                                            else {
                                                                setAllOptions(["أوافق بشدة", "أوافق إلى حد ما", "محايد", "اختلف إلى حد ما", "اختلف بشده"]);
                                                            }
                                                        }
                                                        if (item.value == "singlechoice") {
                                                            setAllOptions([...tempAllOptions]);
                                                            setQuestionEditFormValues({
                                                                ...questionEditFormValues,
                                                                is_logic: 0,
                                                            });
                                                            setLogicAdded(false);
                                                            allJumpTo.push(props.questionno < props.questionlist.length ? props.questionno + 1 : "End Survey");
                                                            setAllJumpTo([...allJumpTo]);
                                                        }
                                                        if (item.value == "multiplechoice" || item.value == "rankingscale" || item.value == "free") {
                                                            setAllOptions([...tempAllOptions]);
                                                        }
                                                    }}
                                                >
                                                    {item.label}

                                                </Dropdown.Item>
                                            ))}
                                        </div>
                                    </Dropdown.Menu>

                                </Dropdown>

                                <div className={'cross-q m-cross-q mobile-view'} onClick={() => {
                                    props.changeQuestionType(tempQuestionType);
                                    props.cancelEditQuestion();
                                    props.onDelete();
                                    setQuestionEditFormValues({
                                        question: "",
                                        is_optional: true,
                                    });
                                }}>
                                    <Icon value="delete" size="medium" hover={true} />
                                </div>


                            </div>
                        </div>
                        <div className="bd-que-slide-arrow">
                            <div className="sqa-check-top">
                                <div className="form-group custom-control custom-checkbox mb-0 dark-checkbox">
                                <div className="optional-text-hold d-flex align-items-center">

                                    <Text type="body-text-3" fontWeight="medium">Optional</Text>
                                    <span className="info-icon info-wrap position-relative">
                                        <Icon value="information" size="medium" hover={true} />
                                        <div className="tooltip-wrapper top-tooltip">
                                            <Text type='body-text-2' id='tooltip'
                                                children={"By enabling the optional toggle you’re allowing testers to skip this question"}
                                                fontWeight='normal-font' />
                                        </div>
                                    </span>
                                    </div>
                                    <ToggleButton isChecked={Boolean(questionEditFormValues.is_optional)} onChange={(e) =>
                                        setQuestionEditFormValues({
                                            ...questionEditFormValues,
                                            is_optional: e.target.checked ? 1 : 0,
                                        })
                                    } />
                                    {((props.methodology === 'Survey') && props.islogic) && (
                                        <div className="form-group custom-control mb-0 logicbuttonbox">

                                            <ToggleButton
                                                id="logicCheck"
                                                isChecked={Boolean(questionEditFormValues.is_logic)}
                                                label={<Text display="inline" fontWeight={'medium-font'} type="body-text-2">Add logic</Text>}
                                                onChange={(e) => {
                                                    setQuestionEditFormValues({
                                                        ...questionEditFormValues,
                                                        is_logic: e.target.checked ? 1 : 0,
                                                    });
                                                    setLogicAdded(logicAdded == true ? false : true);
                                                }
                                                }
                                            />
                                        </div>
                                    )}
                                    <div className={'cross-q'} onClick={() => {
                                        props.changeQuestionType(tempQuestionType);
                                        props.cancelEditQuestion();
                                        props.onDelete();
                                        setQuestionEditFormValues({
                                            question: "",
                                            is_optional: true,
                                        });
                                    }}>
                                        <Icon value="delete" size="medium" hover={true} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`create-projectmodal-form-group d-flex ${(!props.methodology || props.methodology=="" || props.methodology!="preference_test")?"input-with-image":""} ${props.language === "ar" ? 'arabic-add-logic-true' : ''} ${(props.methodology === 'Survey') && questionEditFormValues.is_logic ? 'add-logic-true' : ''}`}>
                        {(!props.methodology || props.methodology=="" || props.methodology!="preference_test") &&
                        <QuestionImage 
                            questionSection={props.questionSection?props.questionSection:null}
                            url={questionEditFormValues.image_url} 
                            id={questionEditFormValues.image_id} 
                            onImageUpload={(image_id,image_url)=>{
                                setQuestionEditFormValues({...questionEditFormValues, image_url: image_url, image_id: image_id});
                            }}
                        />
                        }
                        <InputPlaceholder
                            required={true}
                            label="Your question"
                            placeholder="Add an overall statement. Eg. ‘‘Please rate the following statements on your customer support experience’’"
                            onChange={setQuestion("question")}
                            onClear={() => {
                                if(props.onUpdate){
                                    props.onUpdate({...questionEditFormValues, question:""});
                                    
                                }
                                setQuestionEditFormValues((oldValues) => ({
                                    ...oldValues,
                                    question: "",
                                }));
                            }}
                            value={questionEditFormValues.question}
                            maxLength="350"
                            error={questionEditFormErrors.question}
                            error_class={questionEditFormErrors.error_class}
                            id={"question"+(props.id_prefix?props.id_prefix:"")}
                            rtl={props.language == "ar" ? true : false}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    handleQuestionEditSubmit();
                                }
                            }}
                        />

                        {props.methodology === 'Survey' && questionEditFormValues.is_logic ? (
                            <DropdownComponent
                                label="Jump to"
                                options={dropdownOptions}
                                value={

                                    questionEditFormValues.jump_to != "End Survey" ? (questionEditFormValues.jump_to - props.questionno == 1 ? "Next question" : "Question " + questionEditFormValues.jump_to) : "End survey"

                                }
                                onChange={(item) => {
                                    setQuestionEditFormValues((oldValues) => ({
                                        ...oldValues,
                                        jump_to: item.value,
                                    }));
                                }}
                            />
                        ) : ''}
                    </div>


                    <div className="points-outer likert-scale-length-wrap">
                        <div className="scalebuttonbox">
                            <span>Scale length</span>
                            <FilterButtons options={["5 points", "7 points"]} selected={likertScaleLength}
                                onChange={(value) => {
                                    setLikertScaleLength(value);
                                    if (value == "5 points") {
                                        setLikertScaleType(props.language === "en" ? "Satisfaction" : "مقياس الرضا");
                                        if (props.language == "en") {
                                            setAllOptions(["Very dissatisfied", "Dissatisfied", "Neither dissatisfied or satisfied", "Satisfied", "Very satisfied"]);
                                        } else {
                                            setAllOptions(["مستاء جدا", "غير راض", "لا غير راض أو راض", "راضي", "راضي جدا"]);
                                        }
                                        setLikertStateMent("“How satisfied are you with the speed of response to your enquiry?”");
                                    }
                                    if (value == "7 points") {
                                        setLikertScaleType(props.language === "en" ? "Agreement" : "مقياس الاتفاق");
                                        if (props.language == "en") {
                                            setAllOptions(["Strongly disagree", "Disagree", "Somewhat disagree", "Neither agree or disagree", "Somewhat agree", "Agree", "Strongly agree"]);
                                        } else {
                                            setAllOptions(["أعارض بشدة", "أعارض", "لا أوافق إلى حد ما", "لا أوافق ولا أعارض", "أوافق إلى حد ما", "أوافق", "أوافق بشدة"]);
                                        }
                                        setLikertStateMent("“The customer service representatives were helpful and knowledgeable”");
                                    }
                                }} />
                        </div>
                        <DropdownComponent
                            label={props.language === "en" ? "Scale type" : "نوع المقياس"}
                            options={likertScaleLength == "5 points" ? likert5ScaleOptions : likert7ScaleOptions}
                            value={likertScaleType} onChange={(item) => {
                                setLikertScaleType(item.value);
                                mapLikertOptions(item.value);
                            }} />
                    </div>
                    <div className="tagbox">
                        {allOptions.map((option, index) => (
                            <label htmlFor="radio4" key={option + "-" + index}>
                                {(option == "Not applicable" || option == "لا ينطبق عليّ") ?
                                    <Tag title={option} type="purple-tag" onClose={() => {
                                        const list = [...allOptions];
                                        list.pop();
                                        setAllOptions(list);
                                    }} /> :
                                    <Tag title={option} type="purple-tag" />
                                }

                            </label>

                        ))}
                        {props.language == "en" && !allOptions.includes("Not applicable") &&
                            <Tag title={"Not applicable"} type="purple-tag" cssClasses="not-applicable-notselected" onAdd={() => {
                                const list = [...allOptions];
                                list.push("Not applicable");
                                setAllOptions(list);
                            }} />
                        }
                        {props.language == "ar" && !allOptions.includes("لا ينطبق عليّ") &&
                            <Tag title={"لا ينطبق عليّ"} type="purple-tag" cssClasses="not-applicable-notselected" onAdd={() => {
                                const list = [...allOptions];
                                list.push("لا ينطبق عليّ");
                                setAllOptions(list);
                            }} />
                        }
                    </div>
                    <div className={`${props.language == "ar" ? 'arabic_wrapper statementbox' : 'statementbox'}`}>
                        <Text type="body-text-2" fontWeight="medium">Your statements</Text>

                        <div id={"question-options"+(props.id_prefix?props.id_prefix:"")} className="">

                            <div style={{ position: 'relative' }}>
                                <DragDropContext
                                    onDragEnd={(param) => {
                                        const srcI = param.source.index;
                                        const desI = param.destination.index;
                                        if (likertSubQuestions) {
                                            likertSubQuestions.splice(desI, 0, likertSubQuestions.splice(srcI, 1)[0]);
                                        }
                                        setPlaceholderProps({});
                                    }}
                                    onDragStart={handleDragStart}
                                    onDragUpdate={handleDragUpdate}
                                >
                                    <Droppable droppableId="droppable-1">
                                        {(provided, snapshot) => (
                                            <div ref={provided.innerRef} {...provided.droppableProps}>
                                                {likertSubQuestions.map((subQuestion, index) => (
                                                    <Draggable
                                                        key={index}
                                                        draggableId={"optiondraggable-" + index}
                                                        index={index}
                                                    >
                                                        {(provided, snapshot) => (
                                                            <div
                                                                className={`ans-list-repeat saved-ans-repeat ${snapshot.isDragging ? "question-answers-dragging draggable-element-start" : ""
                                                                    } `}
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                            >
                                                                <div className={`full draginput-main ${props.language == "ar" ? "arabic_wrapper" : ""}`} key={index}>
                                                                    <div className="full draginput-outer">
                                                                        <div className="draginput-inner">
                                                                            <div className="draginputbox" {...provided.dragHandleProps}>
                                                                                <Icon value="move-object" colorClass={'gray-900-svg'}  size="medium" hover={true} />
                                                                            </div>
                                                                            <div className={`draginput-main ${snapshot.isDragging ? 'draggable-inner-element-start' : ''}`}>

                                                                                <InputPlaceholder
                                                                                    required={true}
                                                                                    clearField={false}
                                                                                    value={subQuestion}
                                                                                    label={
                                                                                        index == 0 ? "First statement" :
                                                                                            index == 1 ? "Second statement" :
                                                                                                index == 2 ? "Third statement" :
                                                                                                    index == 3 ? "Fourth statement" :
                                                                                                        index == 4 ? "Fifth statement" :
                                                                                                            index == 5 ? "Sixth statement" :
                                                                                                                index == 6 ? "Seventh statement" :
                                                                                                                    index == 7 ? "Eighth statement" :
                                                                                                                        index == 8 ? "Ninth statement" :
                                                                                                                            index == 9 ? "Tenth statement" :
                                                                                                                                "Another statement"
                                                                                    }
                                                                                    placeholder={
                                                                                        index == 0 ? "Add your first statement to rate. Eg. " + likertStatement :
                                                                                            index == 1 ? "Second statement" :
                                                                                                index == 2 ? "Third statement" :
                                                                                                    index == 3 ? "Fourth statement" :
                                                                                                        index == 4 ? "Fifth statement" :
                                                                                                            index == 5 ? "Sixth statement" :
                                                                                                                index == 6 ? "Seventh statement" :
                                                                                                                    index == 7 ? "Eighth statement" :
                                                                                                                        index == 8 ? "Ninth statement" :
                                                                                                                            index == 9 ? "Tenth statement" :
                                                                                                                                "Another statement"
                                                                                    }
                                                                                    onChange={(e) => {
                                                                                        likertSubQuestions.splice(index, 1, e.target.value);
                                                                                        setLikertSubQuestions([...likertSubQuestions]);
                                                                                    }}
                                                                                    onBlur={(e) => {
                                                                                        if (likertSubQuestions[index] == "") {
                                                                                            setOptionError("input_error");
                                                                                            setOptionErrorIndex(null);
                                                                                        } else {
                                                                                            // props.save(index, e.target.value);
                                                                                        }
                                                                                    }}
                                                                                    onKeyDown={(e) => {
                                                                                        if (e.key === 'Enter') {
                                                                                            likertSubQuestions.splice(index + 1, 0, "");
                                                                                            setLikertSubQuestions([...likertSubQuestions]);
                                                                                        }
                                                                                    }}
                                                                                    autoFocus={() => { setOptionErrorIndex(index); }}
                                                                                    rtl={props.language == "ar" ? true : false}
                                                                                    maxLength={"250"}
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                        <div className="draginput-crossicon-outer">

                                                                            {likertSubQuestions.length > 1 &&
                                                                                <div className="draginput-crossicon-inner"><span onClick={() => removeSubQuestionHandler(index)}>
                                                                                    <Icon
                                                                                        value="close" size="small" hover={true} />
                                                                                </span></div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                ))}
                                                {provided.placeholder}
                                                {!isEmpty(placeholderProps) && (
                                                    <div
                                                        className="placeholder"
                                                        style={{
                                                            top: placeholderProps.clientY,
                                                            left: placeholderProps.clientX,
                                                            height: placeholderProps.clientHeight,
                                                            width: placeholderProps.clientWidth,
                                                            position: "absolute",
                                                            borderColor: "#000000",
                                                            background: placeholderProps.background,
                                                            borderRadius: '10px',
                                                            border: placeholderProps.border,
                                                            margin: '-4px 0px 10px'
                                                        }}
                                                    />
                                                )}
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            </div>

                        </div>
                    </div>
                    <Button
                        iconLeft={<Icon value="add" />}
                        label="Add statement"
                        type="ghost"
                        onClick={addSubQuestionHandler}
                    />

                    <div className="">

                        <Button label="Save" onClick={handleQuestionEditSubmit} type="primary"
                            state={checkBtnstatus() ? "disabled" : "active"} size="small" />
                    </div>

                    <div className="create-test-preview-area">
                        <Text
                            type={'overline'}
                            children={'Preview'}
                        />
                        <div className="create-test-preview-area-inner">
                            <img src={process.env.REACT_APP_URL + "img/likert-question-preview.svg"} alt="img" />
                        </div>
                        {props.methodology == "Survey" &&
                            <GuideLink methodology={"survey"} />
                        }
                    </div>
                </div>
            )}
        </div>
    );
});