
import { useEffect, useState } from 'react';
import { Accordian } from '../../../themes/userq/Accordion/Accordion';
import { Text } from '../../../themes/userq/Text/Text';
import { Icon } from '../../../themes/userq/Icon/Icon';
import { Link } from 'react-router-dom';
import { getFeeRewardSettingsService } from '../../../../services/test';
import toast from 'react-hot-toast';
import { ToastMessage } from '../../../themes/userq/ToastMessage/ToastMessage';
import { settings } from '../../../../data/settings.js';
import { roundNumber } from '../../../../lib/helpers.js';

export default  function OrderSummary({order,researcher_fee, participants}){
    

    const [accordians, setAccordians] = useState( { 'Test fees': false});



    const [feeRewardSettings, setFeeRewardSettings] = useState([]);

    const [order_participants, setOrderParticipants] = useState(participants);


    useEffect(()=>{
        setOrderParticipants(participants);
    },[participants])
    const getFeeRewardSettingsApi = () => {
        


        getFeeRewardSettingsService().then((response) => {

            

            if (response.success) {
                setFeeRewardSettings(response.settings);
            } else {
                toast((
                    <ToastMessage type={"error"} message={response.message} closable={true} onClose={() => { toast.dismiss(); }} />
                ), {
                    className: 'errortoast',
                    position: 'bottom-center',
                    duration: settings.toast_duration,
                });
            }
        });
    }

    useEffect(()=>{
        getFeeRewardSettingsApi();
    },[])

    return (
        <>
            
            <Accordian
                title={"Test fees"}
                Accordion_small={true}
                open={accordians['Test fees'] ? true : false}
                onChange={() => {
                    let new_accordians = Object.assign({}, accordians);
                    setAccordians(new_accordians);
                }}

            >
                <div className="ordersummary-table-wrap">
                   
                    <table>
                        <tbody>
                            {order.publishing_fees==0 &&
                            <tr>
                                <td><Text type={'body-text-3'} fontWeight={'medium-font'}>Test publishing fee</Text></td>
                                <td><Text type={'body-text-3'} fontWeight={'medium-font'}><div className="order-summary-publishing-fees">{order.publishing_fees==0 && <span className="pink-text">FREE</span>}<span className={`${order.publishing_fees==0?"strike-text":""}`}>{roundNumber(order.test_publishing_fees,2)} credits</span></div></Text></td>
                            </tr>
                            }
                            {order.publishing_fees!=0 &&
                            <tr>
                                <td><Text type={'body-text-3'} fontWeight={'medium-font'}>Test publishing fee</Text></td>
                                <td><Text type={'body-text-3'} fontWeight={'medium-font'}>{roundNumber(order.test_publishing_fees,2)} credits</Text></td>
                            </tr>
                            }
                            <tr>
                                <td>
                                    <Text type={'body-text-3'} cssClasses={"user-response-wrap participants_info-tooltip d-block"} fontWeight={'medium-font'}>{order_participants}{order_participants>1?" participants":" participant"} - UserQ panel
                                        {feeRewardSettings.length > 0 &&
                                        <span className='info-wrap'>
                                                   
                                            <Icon value={'information'} colorClass={'gray-600-svg'} size="medium" /> 
                                            <div className="tooltip-wrapper bottom-tooltip">
                                                <Text type='body-text-2' cssClasses={'white-color'}>
                                                    Credits required to recruit participants are determined by the length of the test.
                                                </Text>
                                                <div className='credit-score-tooltip-info'>
                                                    {feeRewardSettings.map(function(setting){
                                                        return (
                                                            <Text type='body-text-3' cssClasses={'d-flex align-items-center'} fontWeight={'medium-fone'}>
                                                                <Icon value={'length'} colorClass={'gray-50-svg'} size={'small'}/>
                                                                {setting.duration_label} length {setting.sub_label}
                                                                <Icon value={'credit'} colorClass={'pink-200-svg'} size={'small'}/>
                                                                <span className='pink-color'>{roundNumber(setting.researcher_fee,2)} credits</span>
                                                            </Text>
                                                        );
                                                    })}
                                                </div>
                                                <Text type='body-text-2' cssClasses={'white-color'}>
                                                    You can save up to 20% when
                                                    <Link className={'link-text'} to={"/r/buy-credits/"}>
                                                    bulk buying credits.</Link>
                                                </Text>
                                            </div>
                                            
                                        </span>
                                        }
                                    </Text>
                                </td>
                                <td><Text type={'body-text-3'} fontWeight={'medium-font'}>{roundNumber(researcher_fee * order_participants,2)} credits</Text></td>
                            </tr>
                        
                        </tbody>
                    </table>
                </div>
            </Accordian>
            <div className="ordersummary-table-wrap"> 
                <Text type={"body-text-2"} fontWeight={"semi-bold-font"}>Order Summary</Text>
                <table>
                    <tbody>
                        
                        
                        <tr className="order-credits-required-row">
                            <td><Text type={'body-text-3'} fontWeight={'medium-font'}>Total credits required</Text></td>
                            <td><Text type={'body-text-3'} fontWeight={'medium-font'}>{roundNumber(order.total_fees,2)} credits</Text></td>
                        </tr>
                        <tr>
                            <td><Text type={'body-text-3'} fontWeight={'medium-font'}>Available in your wallet</Text></td>
                            <td><Text type={'body-text-3'} fontWeight={'medium-font'}>{roundNumber(order.credits_balance,2)} credits</Text></td>
                        </tr>
                        {order.credits_balance < order.total_fees &&
                        <>
                        <tr>
                            <td><Text type={'body-text-3'} fontWeight={'medium-font'}>Order total</Text></td>
                            <td>
                                <Text type={'body-text-3'} fontWeight={'medium-font'}>
                                    {roundNumber(order.recruitment_fees + order.publishing_fees - order.credits_used,2)} credits
                                
                                </Text>
                            </td>
                        </tr>
                        {order.discount > 0 &&
                        <>
                        <tr className="order-promocode-row">
                            <td>
                                <Text type={'body-text-3'} fontWeight={'medium-font'}>Promo code</Text>
                            </td>
                            <td>
                                <Text type={'body-text-3'} fontWeight={'medium-font'}>-{order.discount_desc}</Text>
                                
                            </td>
                        </tr>
                        </>
                        }
                        <tr  className={`order-credits-required-row`}>
                            <td><Text type={'h4'} fontWeight={'medium-font'}>Payment Total</Text></td>
                            <td>
                                <Text type={'h4'} fontWeight={'medium-font'}>
                                    {order.discount >0 &&
                                    <span className="crdis-price-text">
                                        ${roundNumber(order.recruitment_fees + order.publishing_fees - order.credits_used,2)}
                                    </span>
                                    }
                                    ${roundNumber(order.amount,2)}
                                    
                                </Text>
                            </td>
                        </tr>
                        {/*<tr>
                            <td colSpan={2} className="credit-amount-info"><strong>1 credit = $1</strong></td>
                            </tr>*/}
                        </>
                        }
                    </tbody>

                </table>
                <p className='estimated-credit-link'>Save up to 20% when you <Link className={'link-text'} to={"/r/buy-credits/"}>
                            buy in bulk</Link></p>
            </div>
        </>
    )
    
}