import React from 'react';
import { Link } from 'react-router-dom';
import { useNavigate, useParams,useLocation } from "react-router-dom";
import { ReactSession } from '../../../lib/secure_reactsession';
import { LayoutResearcher } from '../../themes/userq/Layouts/layout_researcher';
import { OverviewTestDetail } from '../../themes/userq/TestResults/overview/OverviewTestDetail';
import { OverviewParticipant } from '../../themes/userq/TestResults/overview/OverviewParticipant';
import { OverviewEngagement } from '../../themes/userq/TestResults/overview/OverviewEngagement';
import { TestResultsSidebarNavigation } from './components/test_results_sidebar_navigation';
import { useState, useEffect, useRef } from "react";
import { getResearcherTestResultData, getResearcherTestResultOverviewData } from "../../../services/researcher_test_results";
import toast from 'react-hot-toast';
import { getCurrentPlansService } from "../../../services/plans";
import moment from "moment";
import {
    atom,
    useRecoilState,
} from 'recoil';
import { LoadingIcon } from "../../loader/loadingIcon";
import { TestResultsHeader } from '../../themes/userq/TestResultsHeader/TestResultsHeader';
import { PieStatsBox } from './components/pie_stats_box';
import { Button } from '../../themes/userq/Button/Button';
import { Icon } from '../../themes/userq/Icon/Icon';
import { Text } from "../../themes/userq/Text/Text";
import TestSummaryModal from "../../dialog/test_summary_modal";
import { NoParticipants } from './components/no_participants';
import { ParticipantsNotAvailable } from './components/participants_not_available';
import { TreeTasksOverviewStatsBox } from './components/tree_tasks_overview_stats_box';
import { LinearAxisChartStatsBox } from './components/linear_axis_chart_stats_box';
import {renderTestTitle, roundNumber} from "../../../lib/helpers";
import { Box } from '../../themes/userq/Box/Box';
import { Helmet } from 'react-helmet';
import {ToastMessage} from "../../themes/userq/ToastMessage/ToastMessage";
import {settings} from "../../../data/settings";



export default function TestResultOverview({isSharedLink}) {

    let { test_id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    const [isLoading, setLoading] = useState(false);
    const [sectionLoading, setSectionLoading] = useState(false);
    const [testDetailData, setTestDetailData] = useState([]);

    const [testPrototypeData, setTestPrototypeData] = useState("");
    const [testFirstClickData, setFirstClickData] = useState("");

    const [parti, setParti] = useState({});

    const [treeTestOverview, setTreeTestOverview] = useState(null);

    const [testSummaryModal, setTestSummaryModal] = useState({
        open: false
    });

    const testState = atom({
        key: 'testresult-' + test_id,
        default: ""
    });

    const planState = atom({
        key: 'testresultplan-' + test_id,
        default: ""
    });
    const testResultOverviewState = atom({
        key: 'testresultoverview-' + test_id,
        default: ""
    });
    const [test, setTest] = useRecoilState(testState);
    const [planType, setPlanType] = useRecoilState(planState);
    const [participantPercentage, setParticipantPercentage] = useState(0);
    const [averageTimeSpend, setAverageTimeSpend] = useState("");
    //const [areaClosedChartData, setAreaClosedChartData] = useRecoilState(testResultOverviewState);
    const [areaClosedChartData, setAreaClosedChartData] = useState([]);
    const [show_support_panel, setShowSupportPanel] = useState(false);
    const [totalReportApprovedByAdmin, setTotalReportApprovedByAdmin] = useState(0);

    const showError = (error) => {
        toast(
            <ToastMessage type={"error"} message={error} closable={false} onClose={() => { toast.dismiss(); }} />,
            {
                id:"error",
                className: "errortoast",
                position: "bottom-center",
                duration: settings.toast_duration,
            }
        );
    };

    const getTestafterEndTest = () => {

        let token = ReactSession.get("token");

        getResearcherTestResultData({ test_id: test_id,guest_result_token:'' }, token).then((response) => {
            setLoading(false);

            if (response.success) {
                setTest(response.test);
                setTotalReportApprovedByAdmin(response.totalReportApprovedByAdmin);
            } else {

                toast(
                    <div className="toastinner">
                        {response.message}
                        <a onClick={() => {
                            toast.dismiss();
                        }}>&times;</a>
                    </div>,
                    {
                        className: (response.success) ? "successtoast" : "errortoast",
                        position: "bottom-center",
                        duration: 2000,
                    }
                );
            }
        })

    }

    const getTest = () => {
        if (!test) {
            let token = ReactSession.get("token");

            setLoading(true);

            let guest_result_token = '';

            if(location.pathname.substr(1, 1) === 'v'){

                // create guest_result_token
                if (!ReactSession.get("guest_result_token")) {
                    const randomToken = Math.random().toString(36).substr(2, 15);
                    ReactSession.set("guest_result_token", randomToken);
                }

                guest_result_token = ReactSession.get("guest_result_token");
            }

            getResearcherTestResultData({ test_id: test_id, guest_result_token:guest_result_token }, token).then((response) => {
                setLoading(false);

                if (response.success) {

                    // check test is available for sharing
                    if(location.pathname.substr(1, 1) === 'v' && response.is_share_result){
                        navigate(`/v/results/${test_id}/results-not-available`);
                    }
                    // check if guest user not validate there password
                    if(location.pathname.substr(1, 1) === 'v' && response.test.password && !response.test_password_verify){

                        navigate(`/v/results/${test_id}/password`);
                    }

                    setTest(response.test);
                    setTotalReportApprovedByAdmin(response.totalReportApprovedByAdmin)
                    getOverview();
                    // setAreaClosedChartData(response.testResultoverviewChart);


                    if (response.test.test_results_count) {
                        const totalParticipants = (response.test.test_results_count) / (response.test.participants) * 100;

                        var totalParticipantCalculated = parseFloat(totalParticipants.toFixed());
                        if (totalParticipantCalculated > 100) {
                            setParticipantPercentage(100);
                        } else {
                            setParticipantPercentage(totalParticipantCalculated);
                        }

                    }

                    if (response.test.total_time_spent) {

                        var averageTimeSpendMinute = Math.floor(response.test.total_time_spent / (response.test.test_results_count * 60));
                        var averageTimeSpendSeconds = ((response.test.total_time_spent / response.test.test_results_count) % 60).toFixed(0);

                        setAverageTimeSpend(`${averageTimeSpendMinute}m ${averageTimeSpendSeconds}s`);

                        
                    }


                } else {

                    navigate("/dashboard");

                    showError(response.message)
                    // toast(
                    //     <div className="toastinner">
                    //         {response.message}
                    //         <a onClick={() => {
                    //             toast.dismiss();
                    //         }}>&times;</a>
                    //     </div>,
                    //     {
                    //         className: (response.success) ? "successtoast" : "errortoast",
                    //         position: "bottom-center",
                    //         duration: 2000,
                    //     }
                    // );
                }
            });
        } else {

            getOverview();
            if (test.test_results_count) {
                const totalParticipants = (test.test_results_count) / (test.participants) * 100;

                var totalParticipantCalculated = parseFloat(totalParticipants.toFixed());
                if (totalParticipantCalculated > 100) {
                    setParticipantPercentage(100);
                } else {
                    setParticipantPercentage(totalParticipantCalculated);
                }
            }

            if (test.total_time_spent) {

                var averageTimeSpendMinute = Math.floor(test.total_time_spent / (test.test_results_count * 60));
                var averageTimeSpendSeconds = ((test.total_time_spent / test.test_results_count) % 60).toFixed(0);

                setAverageTimeSpend(`${averageTimeSpendMinute}m ${averageTimeSpendSeconds}s`);
                
            }
        }
    };

    const getOverview = () => {
        let token = ReactSession.get("token");

        setSectionLoading(true);

        let guest_result_token = '';
        if(location.pathname.substr(1, 1) === 'v'){

            guest_result_token = ReactSession.get("guest_result_token");
        }

        getResearcherTestResultOverviewData({ test_id: test_id,guest_result_token:guest_result_token }, token).then((response) => {
            setSectionLoading(false);

            if (response.success) {
                setTest(response.test);
                setTotalReportApprovedByAdmin(response.totalReportApprovedByAdmin)

                setParti(response.participants);

                setAreaClosedChartData(response.testResultoverviewChart);

                setTestDetailData(response.testDetailOverviewArray);

                setTestPrototypeData(response.prototype_test_summary);
                setFirstClickData(response.first_click_test_summary);

                if (response.test.methodology == "Tree Test") {
                    setTreeTestOverview(response.tree_test_summary);
                }


                if (response.test.test_results_count) {
                    const totalParticipants = (response.test.test_results_count) / (response.test.participants) * 100;

                    var totalParticipantCalculated = parseFloat(totalParticipants.toFixed());
                    if (totalParticipantCalculated > 100) {
                        setParticipantPercentage(100);
                    } else {
                        setParticipantPercentage(totalParticipantCalculated);
                    }

                }

                if (response.test.total_time_spent) {

                    var averageTimeSpendMinute = Math.floor(response.test.total_time_spent / (response.test.test_results_count * 60));
                    var averageTimeSpendSeconds = ((response.test.total_time_spent / response.test.test_results_count) % 60).toFixed(0);

                    setAverageTimeSpend(`${averageTimeSpendMinute}m ${averageTimeSpendSeconds}s`);
                    
                }


            } else {
                toast(
                    <div className="toastinner">
                        {response.message}
                        <a onClick={() => {
                            toast.dismiss();
                        }}>&times;</a>
                    </div>,
                    {
                        className: (response.success) ? "successtoast" : "errortoast",
                        position: "bottom-center",
                        duration: 2000,
                    }
                );
            }
        });
    }

    const getPlan = () => {

        if (!planType) {
            let token = ReactSession.get("token");

            setLoading(true);

            getCurrentPlansService(token).then((response) => {
                setLoading(false);

                if (response.success) {
                    setPlanType(response.plan.type);
                } else {
                    toast(
                        <div className="toastinner">
                            {response.message}
                            <a onClick={() => {
                                toast.dismiss();
                            }}>&times;</a>
                        </div>,
                        {
                            className: (response.success) ? "successtoast" : "errortoast",
                            position: "bottom-center",
                            duration: 2000,
                        }
                    );
                }
            });
        }
    };




    useEffect(() => {
        //document.title = process.env.REACT_APP_NAME + " - Test Result Overview";
        // getPlan();
        getTest();

    }, []);

    const callbackTopbar = (data) => {
        getTest();
        getTestafterEndTest();
    }
    const getTotalParticipants = () => {

        var total = 0;

        for (var key in parti.gender) {
            total += parti.gender[key];
        }
        return total > 0 ? true : false;
    }

    const averageTaskTime = (timeSpend,resultCount) => {
        //const avearagetime = parseInt((timeSpend) / (resultCount));

        var averageTimeSpendMinute = Math.floor(timeSpend / (resultCount * 60));
        var averageTimeSpendSeconds = ((timeSpend / resultCount) % 60).toFixed(0);

        if(averageTimeSpendSeconds.length === 1){
            return averageTimeSpendMinute+'m 0'+ averageTimeSpendSeconds+'s';
        }else{
            return averageTimeSpendMinute+'m '+ averageTimeSpendSeconds+'s';
        }

    }


    return (
        <LayoutResearcher 
            isSharedLink={isSharedLink} 
            fixed_header_target={true} 
            isLoading={false} 
            wrapClass={"researcher-test-view-overflow create-test-data-wrapper"} 
            resultHeaderClass={"result-header"} 
            skipCheck={false} 
            extendedFooter={false} 
            activeMenu={""} 
            openSupportPanel={show_support_panel} 
            hideSupportPanel={()=>{setShowSupportPanel(false)}}
        >
            
            <Helmet>
                <title>Overview {test && test.methodology ? " - "+renderTestTitle(test.methodology):""}  | UserQ</title>
            </Helmet>
            {test &&
                <div className="three-column-layout two-column-layout result">
                    
                    <TestResultsHeader isSharedLink={isSharedLink} test={test} endedOn={test.status === 'completed' ? moment.utc(test.ended_on).format('DD-MM-Y') : ''} callbackTopbar={callbackTopbar} />
                    <div className="three-column-layout-body">
                        <div className="three-column-layout-left">
                            {console.log(test && test.screening_questions  && test.screening_questions.length)}
                            <TestResultsSidebarNavigation
                                isSharedLink={isSharedLink}
                                methodology={(test.methodology === 'Five Seconds Test') ? "5 second test" : test.methodology}
                                selected_index={0}
                                test_id={(location.pathname.substr(1, 1) === 'v')?test_id:test.id}
                                screening={test && test.screening_questions  && test.screening_questions.length>0 ? "Yes" : "No"}
										
                            />
                        </div>
                        <div className="two-column-right-side">
                            <div className='test-result-inner-data'>
                                {sectionLoading &&
                                    <div className={'page-loader accountloading-box result-sections-loader'}>
                                        <LoadingIcon />
                                    </div>
                                }
                                {!sectionLoading &&
                                    <div className="test-result-overview-data">
                                        <div className={"test-result-header-heading mb-20 d-flex justify-content-between align-items-center"}>
                                            <Text type={"h4"}>Overview</Text>

                                            <Button type={"ghost"} label={"View test summary"} cssclass={"purple-color text-decoration-underline"}
                                                onClick={() => {
                                                    setTestSummaryModal({
                                                        open: true,
                                                        test_id: test.id
                                                    });
                                                }}
                                            />
                                        </div>

                                        <div className='test-result-overview-top-area mb-60 box-full-width'>
                                            <div className="row">
                                                <div className="col-lg-3 col-md-6">
                                                    <OverviewParticipant
                                                        language={(test.language === 'en') ? "english" : 'arabic'}
                                                        userQPanel={
                                                            (test.target_group && (test.target_group.target_type === 'random-testers' || (test.target_group.project_target_group && test.target_group.target_type === 'target-group'))) ?
                                                                test.test_results_tester_count
                                                                :
                                                                0
                                                        }
                                                        sharedLink={test.test_results_guest_count}
                                                        test={test}
                                                        totalReportApprovedByAdmin={totalReportApprovedByAdmin}
                                                    />
                                                </div>
                                                <div className="col-lg-3 col-md-6">

                                                    <OverviewTestDetail
                                                        items={testDetailData}
                                                        methodology={''}
                                                    />
                                                </div>

                                                <div className="col-lg-6">

                                                    <OverviewEngagement
                                                        liveFrom={moment.utc(test.published_on).format('DD-MM-Y')}
                                                        avgTime={averageTimeSpend}
                                                        methodology={test.methodology}
                                                        graph={areaClosedChartData}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        {test && test.target_group.target_type == 'test-links' &&
                                        <>
                                            <div className='shared-link-overview-participants'>
                                                <Text type={"h4"}>Participants overview</Text>
                                                <Box size="large" cssClasses="shared-link-overview-participants-box">
                                                <ParticipantsNotAvailable small={true} />
                                                </Box>
                                                {/* <Button
                                                 label={"Use UserQ panel to recruit participants"}
                                                 size={"large"}
                                                 onClick={()=>{}}
                                                /> */}
                                            </div>

                                            {test && test.methodology == "Tree Test"  && treeTestOverview &&
                                            <div className='result-pie-chart-wrap box-full-width mt-40'>
                                                <div className='mb-20 box-outer-heading'>
                                                    <Text type={"h4"}>Test overview</Text>
                                                </div>
                                                <div className="tasksuccess-outerbox mb-40 d-block">
                                                    <div className='row'>
                                                        <div className='col-lg-6'>
                                                            <LinearAxisChartStatsBox title={"Task success %"} size={"medium"} percentage={((treeTestOverview.overview.success_count / treeTestOverview.overview.total_count) * 100).toFixed(2)} />
                                                        </div>
                                                        <div className='col-lg-6'>
                                                            <LinearAxisChartStatsBox title={"Task success directness  %"} size={"medium"} percentage={((treeTestOverview.overview.direct_count / treeTestOverview.overview.total_count) * 100).toFixed(2)} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row  mb-40">
                                                    <div className='col-md-12'>
                                                    <TreeTasksOverviewStatsBox tasks={treeTestOverview.tasks} title={"By Task"} hide={false} />
                                                    </div>
                                                </div>

                                                <div className='text-center d-flex justify-content-center'>
                                                    <Button
                                                        label={"Tree testing analysis"}
                                                        size={"large"}
                                                        iconRight={
                                                            <Icon value={"forward-arrow"} colorClass={'gray-50-svg'} size={"medium"} />
                                                        }
                                                        onClick={() => {
                                                            if(isSharedLink){
                                                                navigate("/v/results/" + test_id + "/tree-test");
                                                            }else {
                                                                navigate("/r/results/" + test.id + "/tree-test");
                                                            }
                                                        }}
                                                    />
                                                </div>

                                            </div>
                                            
                                        }
                                        </>
                                        }

                                        {test && (test.target_group.target_type !== 'test-links') && parti &&
                                            <>
                                                {
                                                    getTotalParticipants()
                                                        ?
                                                        <>
                                                            <div className='result-pie-chart-wrap box-full-width'>
                                                                <div className='mb-20 box-outer-heading'>
                                                                    <Text type={"h4"}>Participants overview</Text>
                                                                </div>
                                                                <div className="row  mb-40">
                                                                    <div className='col-lg-6'>
                                                                        <PieStatsBox data={parti.age} title={"Age"} />
                                                                    </div>
                                                                    <div className='col-lg-6'>
                                                                        <PieStatsBox data={parti.gender} title={"Gender"} />
                                                                    </div>
                                                                </div>

                                                                <div className='text-center d-flex justify-content-center'>
                                                                    <Button
                                                                        label={"More about your participants"}
                                                                        size={"large"}
                                                                        iconRight={
                                                                            <Icon value={"forward-arrow"} colorClass={'gray-50-svg'} size={"medium"} />
                                                                        }
                                                                        onClick={() => {
                                                                            if(isSharedLink){   
                                                                                navigate("/v/results/" + test_id + "/participants");
                                                                            } else {
                                                                                navigate("/r/results/" + test.id + "/participants");
                                                                            }
                                                                            
                                                                        }}
                                                                    />
                                                                </div>

                                                            </div>
                                                            {test && test.methodology == "Tree Test" && treeTestOverview &&
                                                                <div className='result-pie-chart-wrap box-full-width mt-40'>
                                                                    <div className='mb-20 box-outer-heading'>
                                                                        <Text type={"h4"}>Test overview</Text>
                                                                    </div>
                                                                    <div className="tasksuccess-outerbox mb-40 d-block">
                                                                        <div className='row'>
                                                                            <div className='col-lg-6'>
                                                                                <LinearAxisChartStatsBox title={"Task success %"} size={"medium"} percentage={((treeTestOverview.overview.success_count / treeTestOverview.overview.total_count) * 100).toFixed(2)} />
                                                                            </div>
                                                                            <div className='col-lg-6'>
                                                                                <LinearAxisChartStatsBox title={"Task success directness  %"} size={"medium"} percentage={((treeTestOverview.overview.direct_count / treeTestOverview.overview.total_count) * 100).toFixed(2)} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row  mb-40">
                                                                        <div className='col-md-12'>
                                                                        <TreeTasksOverviewStatsBox tasks={treeTestOverview.tasks} title={"By Task"} hide={false} />
                                                                        </div>
                                                                    </div>

                                                                    <div className='text-center d-flex justify-content-center'>
                                                                        <Button
                                                                            label={"Tree testing analysis"}
                                                                            size={"large"}
                                                                            iconRight={
                                                                                <Icon value={"forward-arrow"} colorClass={'gray-50-svg'} size={"medium"} />
                                                                            }
                                                                            onClick={() => {
                                                                                if(isSharedLink){
                                                                                    navigate("/v/results/" + test_id + "/tree-test");
                                                                                }else{
                                                                                    navigate("/r/results/" + test.id + "/tree-test");
                                                                                }
                                                                            }}
                                                                        />
                                                                    </div>

                                                                </div>
                                                            }
                                                        </>
                                                        :
                                                        <NoParticipants small={true} />
                                                }
                                            </>
                                        }
                                        

                                        {testPrototypeData && testPrototypeData.taskType === 'closed' && (testPrototypeData.resultcount > 0) &&
                                        <div className='test-result-overview-prototype-data-area mb-60 box-full-width mt-60'>

                                            <div className={'test-result-overview-prototype-data-area-header'}>
                                                <Text type={"h4"}>Test overview</Text>
                                                <Text type={"body-text-2"} fontWeight={'medium-font'} cssClasses="light-dark-text mt-20">
                                                    This is an overview to show how your testers performed on the different tasks.
                                                </Text>
                                            </div>

                                            <div className='prototype-overview-content mt-20 mb-40 prototype-result-overview-wrap p-0'>

                                            <div className="row">

                                                <div className={`col-lg-3 col-md-6`}>
                                                    <div className=' prototype-overview-inner-data average-click'>

                                                        <Text type={"h4"} children={"Total tasks"} />

                                                        <div className={`data-percent d-flex`}>

                                                            <Icon size={'large'} value={'click'} />
                                                            <Text type={"h1"} children={testPrototypeData.prototypeTaskCount} />

                                                        </div>

                                                        <Text type={"caption"}>
                                                            {testPrototypeData.prototypeTaskCount} {testPrototypeData.prototypeTaskCount ===1?'task':'tasks'} and {testPrototypeData.prototypeTaskQuestionCount} follow up questions
                                                        </Text>

                                                    </div>
                                                </div>

                                                <div className={`col-lg-3 col-md-6`}>
                                                    <div className=' prototype-overview-inner-data completion-rate'>


                                                        <Text type={"h4"} children={"Success rate"} />

                                                        <div className={`data-percent d-flex`}>
                                                            {/*isActive*/}
                                                            <Icon size={'large'} value={'success'} colorClass={'success-green-200-svg'} />
                                                            {/*testPrototypeData.completedTaskCountPercentage*/}
                                                            <Text type={"h1"} cssClasses={"green-text"} children={`${roundNumber(testPrototypeData.successTaskCountPercentage, 1)}%`} />

                                                        </div>

                                                        <Text type={"caption"}>
                                                             An average of {testPrototypeData.successTaskCount} users per task have completed the task on the goal screen
                                                        </Text>

                                                    </div>
                                                </div>

                                                <div className={`col-lg-3 col-md-6`}>
                                                    <div className=' prototype-overview-inner-data skip-rate'>


                                                        <Text type={"h4"} children={"Skip rate"} />

                                                        <div className={`data-percent d-flex`}>

                                                            <Icon size={'large'} value={'forward'} />
                                                            <Text type={"h1"} cssClasses={"light-dark-text"} children={`${roundNumber(testPrototypeData.skipTaskCountPercentage, 1)}%`} />

                                                        </div>

                                                        <Text type={"caption"}>
                                                            {testPrototypeData.skipTaskCount} out of {testPrototypeData.resultcount} have left or given up on your task
                                                        </Text>

                                                    </div>
                                                </div>

                                                <div className={`col-lg-3 col-md-6`}>

                                                    <div className=' prototype-overview-inner-data average-time'>
                                                        <Text type={"h4"} children={"Average time spent"} />

                                                        <div className={`data-percent d-flex`}>

                                                            <Icon size={'large'} value={'time'} /> 
                                                            <Text type={"h1"} children={`${averageTaskTime(testPrototypeData.prototypeTaskResultsAverageTime,testPrototypeData.resultcount)}`} />

                                                        </div>

                                                        <Text type={"caption"}>
                                                            The average time spent on this task is {averageTaskTime(testPrototypeData.prototypeTaskResultsAverageTime,testPrototypeData.resultcount)}
                                                        </Text>

                                                    </div>
                                                </div>



                                            </div>
                                            </div>

                                            <div className='text-center d-flex justify-content-center test-result-overview-prototype-data-area-footer'>
                                                <Button
                                                    label={"Task flow analysis"}
                                                    size={"large"}
                                                    iconRight={
                                                        <Icon value={"forward-arrow"} colorClass={'gray-50-svg'} size={"medium"} />
                                                    }
                                                    onClick={() => {
                                                        if(isSharedLink){
                                                            navigate("/v/results/" + test_id + "/prototype-test");
                                                        }else {
                                                            navigate("/r/results/" + test.id + "/prototype-test");
                                                        }
                                                    }}
                                                />
                                            </div>

                                        </div>
                                        }


                                        {testFirstClickData && (testFirstClickData.resultcount > 0) &&
                                        <div
                                            className='test-result-overview-prototype-data-area mb-60 first-click-overview box-full-width mt-60'>

                                            <div className={'test-result-overview-prototype-data-area-header'}>
                                                <Text type={"h4"}>Test overview</Text>
                                            </div>

                                            <div
                                                className='prototype-overview-content mt-20 mb-40 prototype-result-overview-wrap p-0'>

                                                <div className="row">

                                                    <div className={`col-lg-4 col-md-6`}>

                                                        <div className=' prototype-overview-inner-data average-time'>
                                                            <Text type={"h4"} children={"Average time spent"}/>

                                                            <div className={`data-percent d-flex`}>

                                                                <Icon size={'large'} value={'time'}/>
                                                                <Text type={"h1"} children={`${averageTaskTime(testFirstClickData.averageTime,testFirstClickData.resultcount)}`} />

                                                            </div>

                                                            <Text type={"caption"}>
                                                                The average time spent on this task is {averageTaskTime(testFirstClickData.averageTime,testFirstClickData.resultcount)}
                                                            </Text>

                                                        </div>
                                                    </div>



                                                </div>
                                            </div>

                                            <div
                                                className='text-center d-flex justify-content-center test-result-overview-prototype-data-area-footer'>
                                                <Button
                                                    label={"First click analysis"}
                                                    size={"large"}
                                                    iconRight={
                                                        <Icon value={"forward-arrow"} colorClass={'gray-50-svg'} size={"medium"}/>
                                                    }
                                                    onClick={() => {
                                                        if(isSharedLink){   
                                                            navigate("/v/results/" + test_id + "/first-click");
                                                        } else {
                                                            navigate("/r/results/" + test.id + "/first-click");
                                                        }
                                                    }}
                                                />
                                            </div>

                                        </div>
                                        }

                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }
            <TestSummaryModal
                test_id={testSummaryModal.test_id}
                openModal={testSummaryModal.open}
                close={() => {
                    setTestSummaryModal({ ...testSummaryModal, open: false });
                }}
            />
            {!isSharedLink &&
            <a onClick={(e) => {
                e.preventDefault();

                setShowSupportPanel(true);

            }} href={process.env.REACT_APP_URL + "r/support"} target="_blank" className='support-button'>
                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M9.879 8.2817C11.05 7.2567 12.95 7.2567 14.121 8.2817C15.293 9.3067 15.293 10.9687 14.121 11.9937C13.918 12.1727 13.691 12.3197 13.451 12.4357C12.706 12.7967 12.001 13.4347 12.001 14.2627V15.0127M21 12.7627C21 13.9446 20.7672 15.1149 20.3149 16.2068C19.8626 17.2988 19.1997 18.2909 18.364 19.1267C17.5282 19.9624 16.5361 20.6253 15.4442 21.0776C14.3522 21.5299 13.1819 21.7627 12 21.7627C10.8181 21.7627 9.64778 21.5299 8.55585 21.0776C7.46392 20.6253 6.47177 19.9624 5.63604 19.1267C4.80031 18.2909 4.13738 17.2988 3.68508 16.2068C3.23279 15.1149 3 13.9446 3 12.7627C3 10.3757 3.94821 8.08656 5.63604 6.39873C7.32387 4.71091 9.61305 3.7627 12 3.7627C14.3869 3.7627 16.6761 4.71091 18.364 6.39873C20.0518 8.08656 21 10.3757 21 12.7627ZM12 18.0127H12.008V18.0207H12V18.0127Z"
                        stroke="#9759CB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                Support
            </a>
            }
        </LayoutResearcher>
    )
}