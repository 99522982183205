import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import { Icon } from './../Icon/Icon';


export const InputPlaceholder=React.forwardRef(({type, readOnly, isValid, style,  required, value, defaultValue, maxLength,id, onChange, error, onClear, label,placeholder, clearField, focussed, disabled, onKeyDown,onKeyUp, onBlur, autoFocus, onInput, rtl, },ref)=>{
    const [fieldType, setFieldType] =   useState((type ? type : "text"));
    const [passwordVisible, setPasswordVisibility] = useState(false);
    const [elemValue, setElemValue] = useState(value);
    const [placeholderText, setPlaceholderText] = useState(!(elemValue==""||elemValue==null)?label:placeholder);
    const togglePassword = () => {
        setPasswordVisibility(!passwordVisible);
    };
    useEffect(()=>{
        setElemValue(value);
    },[value])

    useEffect(()=>{
        setPlaceholderText(!(value==""||value==null)?label:placeholder);
        
    },[placeholder])
    
    var password_error = false;

    return (
        
            <div  className={` form-group input-field  ${(clearField==true || isValid )?'input-with-right-icon':''} mb-0 ${( value!='' || focussed==true)?'focused':''}  ${(error || password_error)?'field-error':''} ${readOnly==true?'read-only-field':''} ${disabled==true?'disabled-field':''}`}>
                <div className={`input-position-relative  ${(rtl==true)?'arabic_wrapper':''}`}>
                    <input
                        ref={ref}
                        type={fieldType}
                        id={id}
                        className={`form-control body-text body-text-2 ${(rtl==true)?'arabic_wrapper':''}`}
                        value={elemValue}
                        defaultValue={defaultValue}
                        onChange={(e)=>
                            { 
                                setElemValue(e.target.value);

                                if(onChange){
                                    onChange(e);
                                }
                                
                            }
                        }
                        maxLength={maxLength}
                        onKeyDown={onKeyDown}
                        onKeyUp={onKeyUp}
                        onBlur={(e)=>{
                            if(!(elemValue==""||elemValue==null)){
                                setPlaceholderText(label)
                            }else{
                                setPlaceholderText(placeholder)
                            }
                            if(onBlur){
                                onBlur(e);
                            }
                        }}
                        onInput={onInput}
                        autoFocus={autoFocus}
                        rtl={rtl?rtl:''}
                        readOnly={readOnly}
                        autoComplete="new-password"
                        onFocus={()=>{setPlaceholderText(label)}}

                    />

                    {clearField==true && value!="" && !readOnly && (
                    <span className="input-right-icon" onClick={()=>{ if(onClear){
                        onClear();
                        setPlaceholderText(placeholder);
                        } }}>
                        <Icon value="close" size="medium" hover={true}/>
                    </span>
                    )}

                    {readOnly==true && !isValid && !disabled && (
                        /*onClick={()=>{ if(onClear){onClear();} }} */
                    <span className="input-right-icon" >
                        <Icon value="lock" size="medium" />
                    </span>
                    )}

                    {isValid=="Yes" && (
                    <span className="input-right-icon input-right-without-pointer-cursor">
                        <Icon value="tick-green" size="medium"/>
                    </span>
                    )}
                    {isValid=="No" && (
                    <span className="input-right-icon input-right-without-pointer-cursor">
                        <Icon value="error-red" size="medium"/>
                    </span>
                    )}
                    
                    
                    <label className="control-label body-text body-text-2" htmlFor={id}>
                        {placeholderText}
                        {!required && rtl==false &&
                        (" (Optional)")
                        }
                        {!required && rtl==true &&
                        (" (اختياري)")
                        }
                    </label>
                    
                    
                </div>
                {!error && maxLength && !disabled &&
                <p className="caption">
                    Max characters:&nbsp;
                    <span className="max-characters">
                    {value && value.length > 0 && (
                        <>{value.length}/</>
                    )}
                    {maxLength}
                    </span>
                </p>
                }

                {error != null && (
                    <span className="caption">
                        {error}
                    </span>
                )}
                
            </div>
        
    )
}); 
InputPlaceholder.propTypes = {
    type:PropTypes.string,
    required:PropTypes.bool,
    value: PropTypes.string,
    defaultValue: PropTypes.string,
    maxLength: PropTypes.any,
    id:PropTypes.string,
    onChange: PropTypes.func,
    onKeyDown: PropTypes.func,
    autoFocus: PropTypes.func,
    error: PropTypes.string,
    label: PropTypes.string,
    clearField:PropTypes.bool,
    focussed:PropTypes.bool,
    disabled:PropTypes.bool,
    
};
InputPlaceholder.defaultProps = {
    required:false,
    clearField:true,
    onChange: undefined,
    onKeyDown: undefined,
    autoFocus: undefined,
    focussed: false,
    disabled:false,
    
};
