import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Modal } from "react-bootstrap";
import { Text } from "../../themes/userq/Text/Text"
import { Button } from "../../themes/userq/Button/Button";
import PromoCode from "../components/promo_code";
import { CreditCardForm } from "../tests/components/credit_card_form";
import { roundNumber } from "../../../lib/helpers";
import { Icon } from "../../themes/userq/Icon/Icon";

export default function CheckoutModal({
                                          open,
                                          close,
                                          paymentMethods,
                                          renderPaymentMethods,
                                          user,
                                          validatePaymentForm,
                                          formValues,
                                          setFormValues,
                                          applyCouponApi,
                                          couponError,
                                          setCouponError,
                                          couponLoading,
                                          formErrors,
                                          removePromoCode,
                                          creditPrice,
                                          selected_package,
                                          buyCreditsApi

                                      }) {
    const closeModal = () => {
        close();
    };


    const checkBuyBtnState = () => {
        if(formValues.payment_method_id==""){

            var error = validatePaymentForm();

            if(error){
                return "disabled";
            } else {
                return "active";
            }
        }
        return "active";

    }
    return (
        <Modal
            show={open}
            centered
            className="fade custom-modal-wrap  checkout-modal-wrap"
            onHide={() => {
                closeModal();
            }}
        >

            <Modal.Body className="">


                <div className="modal-inner-text">
                    <div className="modal-header-top">
                        <Text type={"h3"}>Checkout</Text>
                        <button
                            type="button"
                            className="close"
                            onClick={() => {
                                closeModal();
                            }}
                            aria-label="Close"
                        >
                        <span aria-hidden="true">

                            <Icon value="Close" size="medium" hover={true} />
                        </span>
                        </button>
                    </div>
                    <div className="pay-on-fly-coupon-code-wrap pay-on-fly-payment-methods">
                        <div className="promo-code-inner">
                            <div className="pay-on-fly-promo-code-hdr">
                                <Text type={'subtitle-2'} fontWeight={'medium-font'}>Payment method</Text>
                            </div>
                            <div className="row">
                                <div className="col-md-8">
                                    <div className="promocode-inner-form-wrap pubish-payment-inner-wrap">

                                        <div className="row">
                                            <div className="col-md-12">

                                                <div className="added-payment-method-cards-wrapper row">
                                                    {paymentMethods.length>0 &&
                                                    <Text type={"overline"}>SAVED CARD{paymentMethods.length>1?'S':''}</Text>
                                                    }
                                                    {renderPaymentMethods()}
                                                </div>

                                                {user.permission != "Editor" && (


                                                    <>
                                                        <CreditCardForm  isValid={(!validatePaymentForm() && formValues.payment_method_id=="")?true:false} formValues={formValues} setFormValues={(formValues)=>{   setFormValues(formValues) } } isSavedForFuture={true} />
                                                    </>
                                                )}
                                            </div>
                                        </div>



                                        <div className="row">
                                            <div className="col-md-7 publish-field-whidth">
                                                <div className="button-wrap-publish-btn publish-page-bottom-wrap">

                                                    <div className="card-secure-text">
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M16.5 10.5V6.75C16.5 5.55653 16.0259 4.41193 15.182 3.56802C14.3381 2.72411 13.1935 2.25 12 2.25C10.8065 2.25 9.66193 2.72411 8.81802 3.56802C7.97411 4.41193 7.5 5.55653 7.5 6.75V10.5M6.75 21.75H17.25C17.8467 21.75 18.419 21.5129 18.841 21.091C19.2629 20.669 19.5 20.0967 19.5 19.5V12.75C19.5 12.1533 19.2629 11.581 18.841 11.159C18.419 10.7371 17.8467 10.5 17.25 10.5H6.75C6.15326 10.5 5.58097 10.7371 5.15901 11.159C4.73705 11.581 4.5 12.1533 4.5 12.75V19.5C4.5 20.0967 4.73705 20.669 5.15901 21.091C5.58097 21.5129 6.15326 21.75 6.75 21.75Z" stroke="#676767" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                        </svg>

                                                        <span>This is a secure 128-bit SSL encrypted payment</span>
                                                    </div>

                                                    <Button
                                                        id="r_create_step_6_build_next_btn"
                                                        type="primary"
                                                        size={"large"}
                                                        label={<>
                                                            Buy {formValues.credits} credits for ${roundNumber(formValues.credits*creditPrice  - formValues.discount,2)}
                                                        </>}
                                                        state={checkBuyBtnState()}
                                                        onClick={()=>{ buyCreditsApi(); }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <h1 className="heading h4">Order summary</h1>

                                    <div className="ordersummary-table-wrap">
                                        <table>
                                            <tbody>

                                            <tr>
                                                <td>Credits</td>
                                                <td>{formValues.credits} Credits</td>
                                            </tr>

                                            <tr>
                                                <td>Price per credit</td>
                                                <td>${creditPrice}</td>
                                            </tr>
                                            {selected_package && creditPrice < 1 &&
                                            <tr>
                                                <td>Discount</td>
                                                <td><span className="green-color">-${roundNumber(formValues.credits - formValues.credits*creditPrice,2)}</span></td>
                                            </tr>
                                            }
                                            {formValues.discount > 0 &&
                                            <tr>
                                                <td>Discount</td>
                                                <td><span className="green-color">-${roundNumber(formValues.discount,2)}</span></td>
                                            </tr>
                                            }
                                            <tr  className={`pay-on-fly-order-summary-total`}>
                                                <td>Total price</td>
                                                <td>
                                                    ${roundNumber(formValues.credits*creditPrice - formValues.discount,2)}
                                                </td>
                                            </tr>
                                            </tbody>

                                        </table>

                                    </div>
                                    <PromoCode
                                        discount={formValues.discount}
                                        applyCoupon={() => {
                                            setCouponError("");
                                            applyCouponApi()
                                        }}
                                        removeCoupon={() => {
                                            if(couponError){
                                                setCouponError("");
                                                setFormValues({...formValues, promo_code:""})
                                            } else {
                                                setCouponError(""); removePromoCode()
                                            }
                                        }}
                                        formErrors={formErrors}
                                        formValues={formValues}
                                        coupon_code={formValues.promo_code}
                                        couponLoading={couponLoading}
                                        setFormValues={setFormValues}
                                        coupon_code_desc={formValues.description}
                                        couponError={couponError}
                                        section={"checkout"}
                                    />
                                </div>
                            </div>


                        </div>




                    </div>


                </div>
            </Modal.Body>
        </Modal>
    );
}
