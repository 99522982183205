import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ReactSession } from "../../../../lib/secure_reactsession";
import { Text } from "../../../themes/userq/Text/Text";
import { Button } from "../../../themes/userq/Button/Button";
import { Icon } from "../../../themes/userq/Icon/Icon";
import { LoadingIcon } from "../../../themes/userq/Loader/loadingIcon";
import { savePrototypeTesterInterctions, exitPrototypeTask } from "../../../../services/test_result";
import { showToast } from "../../../../lib/helpers";
import DoneWithTaskModal from "./done_with_task_modal";
import PrototypeTestTaskFlowQuestions from "./prototype_test_task_flow_question";
import { ArabicNumbers } from 'react-native-arabic-numbers';
import ExitTestModal from "../../dialog/exit_test_modal";
import { validateResultData } from "../../../../services/test_result";
// import h337 from "heatmap.js"

export default function PrototypeTestTaskFlow({ test, task, active_task, result, timeSpent, moveToNextTask, isPaidUser }) {
    let { test_id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    let user = ReactSession.get("user");

    const [sidebarOpen, setSidebarOpen] = useState(true);
    const [loading, setLoading] = useState(false);
    const [taskModalOpen, setTaskModalOpen] = useState(false);
    const [getStarted, setGetStarted] = useState(true);
    const [prototypeTask, setPrototypeTask] = useState(task);
    const travelledPath = useRef([]);
    const currentNode = useRef(null);
    const currentNodeclickMapData = useRef([]);
    const clickMapData = useRef([]);
    const [taskTimeSpent, setTaskTimeSpent] = useState(0);
    const nodeTimeSpent = useRef(0);
    const [questionScreen, setQuestionScreen] = useState(false);
    const [openExitTestModal, setOpenExitTestModal] = useState(false);

    // close exit test modal & rediect to home page if exits success
    const closeExitTestModal = (data) => {
        setOpenExitTestModal(false);
        if (data) {
            saveAbandonedTest(); // save abandoned test
            if (location.pathname.substr(1, 1) === 'p') {

                //navigate("/r/test/" + test.id + "/welcome");
                navigate("/");
            } else {
                if (!(ReactSession.get("token"))) {
                    navigate("/tester/sign-in");
                } else {
                    navigate("/");
                }

            }
        }
    };

    const saveAbandonedTest = () => {
        const token = ReactSession.get("token");
        const data = new FormData();
        data.append("test_id", test && test.id);
        data.append("guest_token", ReactSession.get("guest_token"));
        data.append("validate", "abandoned");
        data.append("type", location.pathname.substr(1, 1));
        data.append("result_id", result && result.id);
        data.append("time_spent", timeSpent);

        setLoading(true);
        validateResultData(data, token).then((response) => {
            setLoading(false);

            if (response.success) {
                // console.log(window.history)
                navigate("/");
            } else {
                navigate("/");
            }
        });
    };

    useEffect(() => {
        setSidebarOpen(true);
        setGetStarted(true);
        setPrototypeTask(task);
        setLoading(false);
        travelledPath.current = [];
        currentNodeclickMapData.current = [];
        clickMapData.current = [];
        currentNode.current = null;

        setTaskTimeSpent(0);

    }, [active_task, task]);

    useEffect(() => {
        function tickTime() {
            setTaskTimeSpent((prevSeconds) => prevSeconds + 1);

            nodeTimeSpent.current = nodeTimeSpent.current + 1;

        }
        let timeData = setInterval(() => tickTime(), 1000);

        return () => clearInterval(timeData);
    }, []);

    const saveQuestionData = (travelledPath) => {
        setLoading(true);
        clickMapData.current.push({ node: currentNode.current, data: currentNodeclickMapData.current, timespent: nodeTimeSpent.current });
        if (location.pathname.substr(1, 1) === "t") {
            let data = Object.assign({}, { path_travelled: travelledPath.toString() });
            data["test_id"] = result && result.test_id;
            data["result_id"] = result && result.id;
            data["guest_token"] = ReactSession.get("guest_token");
            data["time_spent"] = timeSpent;
            data["task_time_spent"] = taskTimeSpent;
            data["task_id"] = task.id;
            data["is_success"] = travelledPath[travelledPath.length - 1] == prototypeTask.goal_screen_node_id ? 1 : 0;
            data["click_map_data"] = clickMapData.current;
            savePrototypeTesterInterctions(data, ReactSession.get("token")).then(
                (response) => {
                    setLoading(false);
                    if (response.success) {
                        if (task.prototype_test_task_question.length == 0) {
                            moveToNextTask(active_task + 1);
                        } else {
                            setQuestionScreen(true);
                        }

                    } else {
                        showToast(response.message, "error");
                    }
                }
            );
        } else {
            if (task.prototype_test_task_question.length == 0) {
                moveToNextTask(active_task + 1);
            } else {
                setQuestionScreen(true);
            }
        }
    };

    const ExitTask = () => {
        setLoading(true);
        if (location.pathname.substr(1, 1) === "t") {
            let data = Object.assign({}, {});
            data["test_id"] = result && result.test_id;
            data["result_id"] = result && result.id;
            data["guest_token"] = ReactSession.get("guest_token");
            data["time_spent"] = timeSpent;
            data["task_time_spent"] = taskTimeSpent;
            data["task_id"] = task.id;
            exitPrototypeTask(data, ReactSession.get("token")).then(
                (response) => {
                    setLoading(false);
                    if (response.success) {
                        // moveToNextTask(active_task + 1);
                        if (task.prototype_test_task_question.length == 0) {
                            if (task.prototype_test_task_question.length == 0) {
                                moveToNextTask(active_task + 1);
                            } else {
                                setQuestionScreen(true);
                            }
                        } else {
                            setQuestionScreen(true);
                        }
                    } else {
                        showToast(response.message, "error");
                    }
                }
            );
        } else {
            // moveToNextTask(active_task + 1);
            if (task.prototype_test_task_question.length == 0) {
                if (task.prototype_test_task_question.length == 0) {
                    moveToNextTask(active_task + 1);
                } else {
                    setQuestionScreen(true);
                }
            } else {
                setQuestionScreen(true);
            }
        }
    }


    window.addEventListener("message", receiveMessage, false);

    function receiveMessage(event) {
        var data = event.data;
        //console.log(data);
        if (data.type == "MOUSE_PRESS_OR_RELEASE") {

            var clickednode = {
                //x: Math.round(data.data.nearestScrollingFrameMousePosition.x),
                //y: Math.round(data.data.nearestScrollingFrameMousePosition.y),
                x: Math.round(data.data.nearestScrollingFrameOffset.x + data.data.nearestScrollingFrameMousePosition.x),
                y: Math.round(data.data.nearestScrollingFrameOffset.y + data.data.nearestScrollingFrameMousePosition.y),
                value: 1
            }
            if (currentNodeclickMapData.current.length == 0) {
                currentNodeclickMapData.current.push(clickednode);
            } else {
                if (clickednode.x != currentNodeclickMapData.current[currentNodeclickMapData.current.length - 1].x) {
                    currentNodeclickMapData.current.push(clickednode);
                }
            }

        }

        if (data.type == "PRESENTED_NODE_CHANGED" && data.data.presentedNodeId) {
            if (travelledPath.current.length == 0) {
                travelledPath.current.push(data.data.presentedNodeId);
                currentNode.current = data.data.presentedNodeId;
            } else {
                if (data.data.presentedNodeId != travelledPath.current[travelledPath.current.length - 1]) {
                    travelledPath.current.push(data.data.presentedNodeId);
                    clickMapData.current.push({ node: currentNode.current, data: currentNodeclickMapData.current, timespent: nodeTimeSpent.current });
                    currentNode.current = data.data.presentedNodeId;
                    currentNodeclickMapData.current = [];
                    nodeTimeSpent.current = 0;
                }
            }

        }
    }
    const [showNav, setShowNav] = useState(false);
    const showRef = useRef(null);

    const toggleNav = () => {
        setShowNav(!showNav);
    };

    // useEffect(()=>{      
    //         var heatmapInstanceforClick = h337.create({
    //           // only container is required, the rest will be defaults
    //           container: document.getElementById('heatmapContainer'),
    //           radius: 30
    //         });

    //         document.getElementById('heatmapContainer').onclick = function(ev) {
    //         console.log({
    //             x: ev.layerX,
    //             y: ev.layerY,
    //             value: 1
    //         });
    //         currentNodeclickMapData.current.push({
    //             x: ev.layerX,
    //             y: ev.layerY,
    //             value: 1
    //         });

    //         heatmapInstanceforClick.addData({
    //           x: ev.layerX,
    //           y: ev.layerY,
    //           value: 1
    //         });
    //       };     
    // },[active_task, task]);

    return (
        <>
            {!questionScreen ?

                <div className="tester-page-wrapper tester-welcome-page-wrap tester-test-intro figma-mobile-prototype" >

                    <div className="prototype-test-wrap five-second-full-view-wrap prototype-test-fullscreen">
                        {loading ?
                            <div className="loader_section full-page-loader prototype-loader">
                                <LoadingIcon />
                            </div>
                            :
                            <>
                                {sidebarOpen &&
                                    <div className="figma_prototype_sidebar prototypesidebar">
                                        <div className="sidebar-header">
                                            {test.language == "ar" ?
                                                <div onClick={() => { setOpenExitTestModal(true) }} className="exit-test-button"><Text type="body-text-3" fontWeight="medium-font">{" "}{test.language == "ar" ? <>اخرج</> : <>Exit test</>}</Text><Icon  colorClass={"gray-900-svg"} value="back-arrow" size={"small"} /></div>
                                                :
                                                <div onClick={() => { setOpenExitTestModal(true) }} className="exit-test-button"><Icon  colorClass={"gray-900-svg"} value="back-arrow" size={"small"} /><Text type="body-text-3" fontWeight="medium-font">{" "}{test.language == "ar" ? <>اخرج</> : <>Exit test</>}</Text></div>
                                            }
                                            <div className="contact-support-button">
                                                {user && user.role == "Tester" ?
                                                    <div onClick={() => { navigate('/t/support/contact?reason=Problem with the platform'); }}><Text type="body-text-3" fontWeight="medium-font">{test.language == "ar" ? <>اتصل بالدعم</> : <>Contact support</>}</Text></div>
                                                    :
                                                    <>
                                                        {user && user.role == "Researcher" ?
                                                            <div onClick={() => { navigate('/r/support/contact'); }}><Text type="body-text-3" fontWeight="medium-font">{test.language == "ar" ? <>اتصل بالدعم</> : <>Contact support</>}</Text></div>
                                                            :
                                                            <a href={'https://userq.com/contact-us/'} target={"_blank"} className="contact-support-guestuser">
                                                                <div onClick={() => { }}><Text type="body-text-3" fontWeight="medium-font">{test.language == "ar" ? <>اتصل بالدعم</> : <>Contact support</>}</Text></div>
                                                            </a>
                                                        }
                                                    </>
                                                }
                                            </div>
                                            <div className="minimie-screen-icon" onClick={() => {
                                                setSidebarOpen(false);
                                                setGetStarted(false);
                                            }}>
                                                <Icon colorClass="gray-900-svg" value="Minimize cards" size={"small"} hover={true} />
                                            </div>
                                        </div>
                                        <div className="figma_prototype_sidebar_body">
                                            <div className="figma-prototype-sidebar-body-inner">
                                                {test.language == "ar" ?
                                                    <Text type="h3" >المهمة رقم {ArabicNumbers(active_task + 1)}</Text>
                                                    :
                                                    <Text type="h3" >Task {active_task + 1}</Text>
                                                }
                                                <Text type="body-text-2" fontWeight="medium-font">{task.description}</Text>
                                                <div className={'remember-text grey-border-top'}>
                                                    <Text type={"body-text-3"} fontWeight={'medium-font'}>
                                                        {test.language=="en"?`Click the "I'm done" button once you think you have completed the task`
                                                            :`اضغط على زر ‘انتهيت’ عندما تعتقد انك اكملت المهمة`}
                                                    </Text>
                                                </div>
                                                {getStarted ?
                                                    <>
                                                        {test.language == "ar" ?
                                                            <>{currentNode.current == null ?
                                                                <Button
                                                                    type={"primary"}
                                                                    size="large"
                                                                    onClick={() => {
                                                                        // setSidebarOpen(false);
                                                                        // setGetStarted(false);
                                                                    }}

                                                                    label="جاري تحميل النموذج المبدئي"
                                                                    microLoading={true}
                                                                />
                                                                :
                                                                <Button
                                                                    type={"primary"}
                                                                    size="large"
                                                                    onClick={() => {
                                                                        setSidebarOpen(false);
                                                                        setGetStarted(false);
                                                                        nodeTimeSpent.current = 0;
                                                                    }}

                                                                    label="لنبدأ"
                                                                    iconRight={<Icon colorClass={"gray-50-svg"} value={"back-arrow"} size="medium" />}
                                                                />
                                                            }
                                                            </>
                                                            :
                                                            <>
                                                                {currentNode.current == null ?
                                                                    <div className="loading-prototype-button">
                                                                        <Button
                                                                            type={"primary"}
                                                                            size="large"
                                                                            onClick={() => {
                                                                                // setSidebarOpen(false);
                                                                                // setGetStarted(false);
                                                                            }}

                                                                            label="Loading prototype"
                                                                            microLoading={true}
                                                                        />
                                                                    </div>
                                                                    :
                                                                    <Button
                                                                        type={"primary"}
                                                                        size="large"
                                                                        onClick={() => {
                                                                            setSidebarOpen(false);
                                                                            setGetStarted(false);
                                                                            nodeTimeSpent.current = 0;
                                                                        }}

                                                                        label="Get started"
                                                                        iconRight={<Icon colorClass={"gray-50-svg"} value={"forward-arrow"} size="medium" />}
                                                                    />
                                                                }
                                                            </>
                                                        }
                                                    </>
                                                    :
                                                    <div className="free-flow-task-flow">
                                                        <Button
                                                            type={"secondary"}
                                                            size="large"
                                                            onClick={() => {
                                                                setSidebarOpen(false);
                                                            }}

                                                            label={test.language == "ar" ? "استمر في الاستكشاف" : "Keep exploring"}
                                                        />
                                                        {test.language == "ar" ?
                                                            <Button
                                                                type={"primary"}
                                                                size="large"
                                                                onClick={() => {
                                                                    setSidebarOpen(false);
                                                                    setTaskModalOpen(true);
                                                                }}

                                                                label="انتهيت"
                                                                iconRight={<Icon colorClass={"gray-50-svg"} value={"back-arrow"} size="medium" />}
                                                            />
                                                            :
                                                            <Button
                                                                type={"primary"}
                                                                size="large"
                                                                onClick={() => {
                                                                    setSidebarOpen(false);
                                                                    setTaskModalOpen(true);
                                                                    console.log(currentNodeclickMapData.current);
                                                                }}

                                                                label="I’m done"
                                                                iconRight={<Icon colorClass={"gray-50-svg"} value={"forward-arrow"} size="medium" />}
                                                            />
                                                        }
                                                        
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className={`figma_prototype_design_screen_container ${sidebarOpen ? "prototype-overlay-class" : ""}`}>
                                    <div className="figma_prototype_design_screen">
                                        <div id="heatmapContainer" className="prototype-iframe-overlay"></div>
                                        <div id="prototype-iframe">
                                            <iframe
                                                id="figma_prototype_iframe"
                                                height="450"
                                                width="800"
                                                // src={"https://www.figma.com/embed?embed_host=userq&url=" + prototypeTask.prototype_link + "&version=" + prototypeTask.version + "&node-id=" + prototypeTask.start_screen_node_id + "&scaling=contain"}
                                                src={"https://www.figma.com/embed?embed_host=userq&url=" + encodeURIComponent("https://www.figma.com/proto/" + prototypeTask.file_key + "/" + prototypeTask.figma_filename + "?node-id=" + prototypeTask.start_screen_node_id + "&version-id=" + prototypeTask.version + "&starting-point-node-id=" + prototypeTask.start_screen_node_id + "&hide-ui=1&hotspot-hints=0&scaling=scale-down-width")}
                                                allowfullscreen
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className="open-nav-btn none-for-desktop"
                                        onClick={toggleNav}
                                    >
                                        <span>
                                            <Icon
                                                value={"menu"}
                                                size={"medium"}
                                                color={"gray-50-svg"}
                                            />
                                        </span>
                                    </div>
                                    {!sidebarOpen &&
                                        <>
                                            {showNav && (
                                                <div
                                                    ref={showRef}
                                                    className="figma_prototype_footer prototype-footer-mobile"
                                                >
                                                    <div className="prototype-figma-footer-data none-for-desktop">
                                                        <div
                                                            className="close-nav"
                                                            onClick={toggleNav}
                                                        >
                                                            <Icon
                                                                value={"close"}
                                                                size={"medium"}
                                                            />
                                                        </div>

                                                        <Text type="caption">
                                                            {test.language ==
                                                            "ar"
                                                                ? 'راجع المهمة المعطاة وتنقل عبر النموذج المبدئي. انقر فوق "لقد انتهيت" عند إكمال المهمة.'
                                                                : "Read the task instructions and navigate through the prototype to complete it. Once finished, click on “I’m done”."}{" "}
                                                        </Text>

                                                        {test.language ==
                                                        "ar" ? (
                                                            <Button
                                                                type={
                                                                    "secondary"
                                                                }
                                                                size={"large"}
                                                                onClick={() => {
                                                                    if (
                                                                        currentNode.current !=
                                                                        null
                                                                    ) {
                                                                        setSidebarOpen(
                                                                            true
                                                                        );
                                                                        toggleNav();
                                                                    }
                                                                }}
                                                                label="اقرأ المهمة"
                                                                iconLeft={
                                                                    <Icon
                                                                        value={
                                                                            "eye"
                                                                        }
                                                                        size="medium"
                                                                    />
                                                                }
                                                                state={
                                                                    currentNode.current ==
                                                                    null
                                                                        ? "disabled"
                                                                        : ""
                                                                }
                                                            />
                                                        ) : (
                                                            <Button
                                                                type={
                                                                    "secondary"
                                                                }
                                                                size={"large"}
                                                                onClick={() => {
                                                                    if (
                                                                        currentNode.current !=
                                                                        null
                                                                    ) {
                                                                        setSidebarOpen(
                                                                            true
                                                                        );
                                                                        toggleNav();
                                                                    }
                                                                }}
                                                                label="Read task"
                                                                iconLeft={
                                                                    <Icon
                                                                        value={
                                                                            "eye"
                                                                        }
                                                                        size="medium"
                                                                    />
                                                                }
                                                                state={
                                                                    currentNode.current ==
                                                                    null
                                                                        ? "disabled"
                                                                        : ""
                                                                }
                                                            />
                                                        )}

                                                        <Button
                                                            type={"primary"}
                                                            size={"large"}
                                                            onClick={() => {
                                                                // console.log(travelledPath.current);
                                                                if (
                                                                    currentNode.current !=
                                                                    null
                                                                ) {
                                                                    setTaskModalOpen(
                                                                        true
                                                                    );
                                                                    toggleNav();
                                                                }
                                                            }}
                                                            iconRight={
                                                                <Icon
                                                                    value={
                                                                        "forward-arrow"
                                                                    }
                                                                    size="medium"
                                                                    colorClass={
                                                                        "gray-50-svg"
                                                                    }
                                                                />
                                                            }
                                                            label={
                                                                test.language ==
                                                                "ar"
                                                                    ? "انتهيت"
                                                                    : "I'm done"
                                                            }
                                                            state={
                                                                currentNode.current ==
                                                                null
                                                                    ? "disabled"
                                                                    : "active"
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                            <div className="figma_prototype_footer none-for-mobile">
                                                {test.language == "ar" ?
                                                    <Button
                                                        type={"secondary"}
                                                        onClick={() => {
                                                            if(currentNode.current != null){
                                                            setSidebarOpen(true);
                                                            }
                                                        }}

                                                        label="اقرأ المهمة"
                                                        iconLeft={<Icon value={"eye"} size="medium" />}
                                                        state={currentNode.current == null?"disabled":""}
                                                    />
                                                    :
                                                    <Button
                                                        type={"secondary"}
                                                        onClick={() => {
                                                            if(currentNode.current != null){
                                                            setSidebarOpen(true);
                                                            }
                                                        }}

                                                        label="Read task"
                                                        iconLeft={<Icon value={"eye"} size="medium" />}
                                                        state={currentNode.current == null?"disabled":""}
                                                    />
                                                }
                                                <Text type="caption" >{test.language == "ar" ? 'راجع المهمة المعطاة وتنقل عبر النموذج المبدئي. انقر فوق "لقد انتهيت" عند إكمال المهمة.' : 'Read the task instructions and navigate through the prototype to complete it. Once finished, click on “I’m done”.'} </Text>
                                                <Button
                                                    type={"primary"}
                                                    onClick={() => {
                                                        // console.log(travelledPath.current);
                                                        if(currentNode.current != null){
                                                        setTaskModalOpen(true);
                                                        }
                                                    }}

                                                    label={test.language == "ar" ? "انتهيت" : "I'm done"}
                                                    state={currentNode.current == null?"disabled":"active"}
                                                />
                                            </div>
                                        </>
                                    }
                                </div>
                            </>
                        }
                        <DoneWithTaskModal
                            cssClass={(document.body.clientWidth > 767 && test.device === 'mobile' && (location.pathname.substr(1, 1) === "p" && test.methodology === 'Prototype test')) ? 'prototype-test-preview-for-desktop-mode-modal' : ''}
                            test={test}
                            openModal={taskModalOpen}
                            closeModal={() => { setTaskModalOpen(false) }}
                            submit={() => {
                                saveQuestionData(travelledPath.current);
                                setTaskModalOpen(false);
                            }}
                            exit={() => {
                                ExitTask();
                                setTaskModalOpen(false);
                                // moveToNextTask(active_task + 1);
                                // setLoading(true);
                            }}
                        />
                        <ExitTestModal
                            cssClass={(document.body.clientWidth > 767 && test.device === 'mobile' && (location.pathname.substr(1, 1) === "p" && test.methodology === 'Prototype test')) ? 'prototype-test-preview-for-desktop-mode-modal' : ''}
                            openExitTestModal={openExitTestModal}
                            closeExitTestModal={closeExitTestModal}
                            test={test}
                            isPaidUser={isPaidUser}
                        />
                    </div>
                </div>
                :
                <PrototypeTestTaskFlowQuestions
                    prototype_test_task_question={task.prototype_test_task_question}
                    test={test}
                    result={result}
                    questionType="Survey"
                    timeSpent={timeSpent}
                    moveToNextTask={() => {
                        moveToNextTask(active_task + 1);
                        setQuestionScreen(false);
                    }}
                    task={task}
                />
            }
        </>
    );
}