import { useD3 } from '../../../../hooks/useD3';
import React, {useRef, useState} from 'react';
import * as d3 from 'd3';
import { Tooltip } from '../../../themes/userq/Tooltip/Tooltip';


function BarChart({barpadding, data, marginLeft, marginRight, width, id, onClick, insideModal, openModal }) {
  const tooltipref = useRef();
  const tooltip_ele = useRef();
  const tipref = useRef();
  
  const [tooltip, showTooltip] = useState({
		tooltipLeft:0,
		tooltipTop:0,
		tooltipData:"",
		open: false
	});

  const [height, setHeight] = useState(400);
  

  const ref = useD3(
    (svg) => {

      let lheight = 400;

      //svg.selectAll('*').remove();
      //console.log(ref.current.getBoundingClientRect().width)
      const width = ref.current.getBoundingClientRect().width;
      const margin = { top: 20, right:  marginRight? marginRight: 0, bottom: 0, left: marginLeft? marginLeft: 0 };

      const x = d3
        .scaleBand()
        .domain(data.map((d) => d.label))
        .rangeRound([margin.left, width - margin.right])
        .paddingOuter(0)
        .paddingInner(barpadding);

      const y1 = d3
        .scaleLinear()
        .domain([0, d3.max(data, (d) => d.percentage)])
        .rangeRound([lheight - margin.bottom, margin.top]);

      const xAxis = (g) =>
        g.attr("transform", `translate(0,${lheight - margin.bottom})`)
        .attr("fill", "rgb(255, 102, 173)")
        .call(
          
          d3
            .axisBottom(x)
            .tickValues(
              data.map(function(d){ return d.label; })
                
            )
            .tickSizeOuter(2)
            
        )
        .selectAll("text")  
            .style("text-anchor", "end")
            .attr("fontSize", "12")
            .attr("dx", "-.8em")
            .attr("dy", ".15em")
            .attr("transform", "rotate(-45)" );;

      const y1Axis = (g) =>
        g
          .attr("transform", `translate(${margin.left},0)`)
          .attr("fill", "rgb(255, 102, 173)")
          .call(d3.axisLeft(y1).ticks(null, "s"))
          //.call((g) => g.select(".domain").remove())
          /*.call((g) =>
            g
              .append("text")
              .attr("x", -margin.left)
              .attr("y", 10)
              .attr("text-anchor", "start")
              .text(data.y1)
          );*/

          d3.selectAll('.bar'+id).remove();

          d3.selectAll('.bar-title'+id).remove();
    
          d3.selectAll('.baraxis'+id).remove();
    
          d3.selectAll('.yaxis'+id).remove();

          d3.selectAll('.x-axis'+id).remove();

          svg.append("g").attr("class","x-axis baraxis baraxis"+id).attr("fill","#FFFFFF").attr("stroke-width",0);

      svg.select(".x-axis").attr("class", "baraxis  baraxis"+id).call(xAxis).selectAll(".baraxis"+id+" .tick text").call(truncateLabel,x.bandwidth());
      //svg.select(".y-axis").attr("class", "baraxis").call(y1Axis);

      function truncateLabel(text, width) {
        text.each(function() {
          var gameName = d3.select(this).text();
          if(gameName.length > 30){
              gameName = gameName.slice(0,27)+"..."
          }
          d3.select(this).text(gameName)
        })
      }

      

      svg
        .select(".plot-area")
        .selectAll(".bar")
        .data(data)
        
        .join("rect")
        .attr("fill",(d)=> {
            return d.color;
        })
        .on("mousemove", function(d){
          handlePointerMove(d);
        })
        .on("mouseout", function(){
          showTooltip({...tooltip, open:false}) 
        }) 
        .on("click", function(d){
          if(d.target.__data__.label=="More"){
            showTooltip({...tooltip, open:false}) 
            onClick();
          }
        })
        /*+ (x.bandwidth()-50 )/2*/
        .attr("class", (d)=> {

          return "bar bar"+id+" "+(d.label=="More"?"cursor-pointer":"");
        })
        .attr("x", (d) => x.bandwidth() > 80 ? x(d.label)  + (x.bandwidth()-80 )/2  : x(d.label))
        .attr("width",   x.bandwidth() > 80 ? 80 : x.bandwidth() )
        .attr("y", (d) => y1(d.percentage))
        .attr("height", (d) => y1(0) - y1(d.percentage));

        svg
        .select(".plot-area").selectAll(".bar-title")
        .data(data)
        .enter()
        .append("text")
        .classed('bar-title bar-title'+id, true)
        .attr('text-anchor', 'middle')
        .attr('text-size', '16')
        .attr("fill",(d)=> {
          return d.color;
      })
        .attr("x", d => x(d.label) + x.bandwidth()/2)
        .attr("y", d => y1(d.percentage)-5)
        .text(d => d.percentage > 0 ? d.percentage+"%" : '0%' );

      var plotbox =  svg
        .select(".plot-area").node().getBBox()

        var axisbox =  svg
        .select(".baraxis").node().getBBox()

        setHeight(plotbox.height+axisbox.height+20)
    },
    [data.length, width]
  );
  const handlePointerMove = (event) => {

    var tool_width = tipref && tipref.current ?  tipref.current.clientWidth : 222;


    var tool_height =  tipref && tipref.current ?  tipref.current.clientHeight+12+10 : 86;
    
    let containerX = ('clientX' in event ? event.clientX : 0) - tooltipref.current.getBoundingClientRect().left - tool_width/2 ;
    let containerY = ('clientY' in event ? event.clientY : 0)  - tooltipref.current.getBoundingClientRect().top - tool_height ;
    
    var window_width = window.innerWidth;

    var window_height = window.innerHeight;

    if(event.clientX + tool_width/2 > window_width){
        containerX = ('clientX' in event ? event.clientX : 0) - tooltipref.current.getBoundingClientRect().left - tool_width/2 - (event.clientX + tool_width/2 - window_width) ;
    
    }
    if(event.clientX - tool_width/2 < 0){
        containerX = ('clientX' in event ? event.clientX : 0) - tooltipref.current.getBoundingClientRect().left - tool_width/2 + (tool_width/2 - event.clientX)  ;
    }
    showTooltip({
      tooltipLeft: containerX,
      tooltipTop: containerY,
      tooltipData: <>{event.target.__data__.label}<br/>{event.target.__data__.value+" participant"}{event.target.__data__.value>1?'s':''}</>, //event.target.__data__.value > 1 ? event.target.__data__.value+" Participants" : event.target.__data__.value+" Participant"},
      open:true
    });
    
	}
  return (
    <div ref={tooltipref} style={{position:'relative'}} className="industry-barchart">
      {tooltip.open && (!openModal || insideModal)? (
            <>
                <Tooltip
                    ref={tipref}
                    type={tooltip.tipPosition}
                    key={Math.random()} // needed for bounds to update correctly
                    style={{ zIndex:2, display:"block", whiteSpace:"nowrap", width:"auto", minWidth:"150px", margin:0, position:"absolute",left:(tooltip.tooltipLeft)+"px",top:(tooltip.tooltipTop)+"px"}}
                    data={tooltip.tooltipData}
                    className="tooltipChart"
                
                />
            </>
            ): (
                <></>
            )}
    <svg
      ref={ref}
      style={{
        height: height,
        width: "100%",
        marginRight: "0px",
        marginLeft: "0px",
      }}
    >
      <g className="plot-area" />
      <g className="x-axis" fill="#FFFFFF" strokeWidth={0}/>
      <g className="y-axis" />
    </svg>
    </div>
  );
}

export default BarChart;