import React, { useEffect, useState } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { Button } from "../Button/Button";
import { CircularNameIconGroup } from "../CircularNameIcon/CircularNameIconGroup";
import { Icon } from "../Icon/Icon";
import { Logo } from "../Logo/Logo";
import { getCreditSummaryService } from "../../../../services/credits";
import { logoutService } from "../../../../services/user";
import { roundNumber } from '../../../../lib/helpers';
import {
atom,
useRecoilState,
useResetRecoilState
} from 'recoil';
import Skeleton from "react-loading-skeleton";
import { ReactSession } from "../../../../lib/secure_reactsession";
import { Dropdown } from "react-bootstrap";
import Cookies from "js-cookie";
export const Header = ({collaborators,  user, resultHeaderClass, openSupportPanel}) => {
const navigate = useNavigate();
const location = useLocation();
const [creditsLoading, setCreditsLoading] = useState(false);
const [menuClass, setMenuClass] = useState('');
const creditsState = atom({
key: 'credits',
default: 0
});
const [credits, setCredits] = useRecoilState(creditsState);
const resetCredits = useResetRecoilState(creditsState);
const logout = (e) => {
e.preventDefault();
let user = ReactSession.get("user");
if (!user) {
navigate("/tester/sign-in");
}
let token = ReactSession.get("token");
document.body.classList.remove('open-m-menu');
logoutService(token).then((response) => {
if (response.success) {
}
});
resetCredits();
Cookies.remove('expires_at', { path: '' }) 
ReactSession.set("token", null);
ReactSession.set("prototype_download_failed_messages", null);
ReactSession.set("user", null);
ReactSession.set("plan", null);
ReactSession.set("show_subscribed", null);
ReactSession.set("username", null);
ReactSession.set("new_target_id", null);
localStorage.clear();
if (user.role == "Tester") {
navigate("/tester/sign-in");
} else {
navigate("/researcher/sign-in");
}
};
const goToHome = () => {
let user = ReactSession.get("user");
if (user.role == "Tester") {
return process.env.REACT_APP_TESTER_URL;
} else {
return process.env.REACT_APP_RESEARCHER_URL;
}
};
const fetchCreditsSummary = () => {
let token = ReactSession.get("token");
setCreditsLoading(true);
if(!token){
setCreditsLoading(false);
ReactSession.set("credits", 0);
setCredits(0);
} else {
getCreditSummaryService(token).then((response) => {
setCreditsLoading(false);
if (response.success) {
ReactSession.set("credits", response.summary.credits_balance);
setCredits(response.summary.credits_balance);
}
});
}
};
useEffect(() => {
let user = ReactSession.get("user");
if (!user) {
navigate("/tester/sign-in");
}
fetchCreditsSummary();
}, [credits]);
return (
<>
{(process.env.REACT_APP_URL.includes("localhost")|| process.env.REACT_APP_URL.includes("apprevampui") || process.env.REACT_APP_URL.includes("appuat")) &&
<div className="test-environment-header">This is a test environment</div>
}
<div className={`header researcher-header ${resultHeaderClass?resultHeaderClass:""}`}>
<div className="container">
    <div className="header-inner-data body-text body-text body-text-2 medium-font none-for-mobile">
        <div className="header-lhs-side">
            <div className="logo" onClick={()=>
                {console.log(process.env.REACT_APP_URL)}}>
                <Link to={"/"} >
                <Logo theme={"white"} background={"black"} />
                </Link>
            </div>
            <div className="hdr-workspace-data">
                <div className="workspace-dropdown">
                    <Dropdown className="dropdown">
                        <Dropdown.Toggle className="dropdown-toggle">
                            <span className={`workspace-menu-btn`}>
                                Workspace
                                <Icon colorClass="gray-50-svg chevron-icon" size={"medium"} value={"chevron"} />
                            </span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <div className={`hamburger-items`}>
                                
                                <Dropdown.Item onClick={
                                ()=>{
                                navigate("/r/payment-methods");
                                }}
                                className={`${location.pathname.includes("/r/payment-methods")?"active-menu":""}`}
                                >
                                <Icon colorClass="gray-600-svg billing-icon" size={"medium"} value={"billing"} />

                                Billing
                                </Dropdown.Item>
                                <Dropdown.Item onClick={
                                ()=>{
                                navigate("/r/credits");
                                }}
                                className={`${location.pathname.includes("/r/credits")?"active-menu":""}`}
                                >
                                <Icon colorClass="gray-600-svg credit-icon" size={"medium"} value={"credit"} />
                                Credits
                                </Dropdown.Item>
                            </div>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                <CircularNameIconGroup size={"medium"} count={2} circularIconsArray={collaborators} />
            </div>
        </div>
        <div className="header-rhs-side">
            <Button onClick={()=>{ navigate("/r/invite-and-earn");}} type={"ghost"} size={"medium"} label={"Refer & Earn"} />
            <Button onClick={()=>{ navigate("/r/buy-credits");}} type={"secondary"} size={"medium"} label={"Buy Credits"} />
            <div className="header-credits-wrap">
                <span className="header-link credits-link-header" onClick={()=>
                    {
                    navigate("/r/credits")
                    }}>
                    <Icon colorClass="gray-50-svg credit-icon" size={"large"} value={"credit"} />    
                    {!creditsLoading &&
                    <>{roundNumber(credits,2)}{" "}Credits</>
                    }
                    {creditsLoading &&
                    <>
                    <Skeleton className="header-credit-skeleton" width={30} height={14} />
                    Credits</>
                    }
                </span>
                {/*
                <Link to={"/profile"}  className="header-link">
                */}
                <Dropdown className="dropdown">
                    <Dropdown.Toggle className="dropdown-toggle">
                        
                        <Icon colorClass="gray-50-svg profile-icon" size={"large"} value={"profile"} />

                        <span className={`profile-menu-btn`}>
                        {user && user.first_name}
                        </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <div className={`hamburger-items`}>
                            <Dropdown.Item onClick={
                            ()=>{
                            navigate("/r/profile");
                            }}
                            className={`${location.pathname.includes("/r/profile")?"active-menu":""}`}
                            >
                            <Icon colorClass="gray-600-svg profile-icon" size={"medium"} value={"profile"} />

                            Account
                            </Dropdown.Item>
                            <Dropdown.Item onClick={
                            ()=>{
                            navigate("/r/settings");
                            }}
                            className={`${location.pathname.includes("/r/settings")?"active-menu":""}`}
                            >
                            <Icon colorClass="gray-600-svg notification-icon" size={"medium"} value={"notification"} />

                            Notifications settings
                            </Dropdown.Item>
                            <Dropdown.Item onClick={
                            ()=>{
                            openSupportPanel();
                            //navigate("/r/support");
                            }}
                            >
                           <Icon colorClass="gray-600-svg support-icon" size={"medium"} value={"support"} />

                            Support
                            </Dropdown.Item>
                            <Dropdown.Item onClick={logout}
                                >

                                <Icon colorClass="gray-600-svg exit-icon" size={"medium"} value={"exit"} />
                                Log out
                            </Dropdown.Item>
                        </div>
                    </Dropdown.Menu>
                </Dropdown>
                {/*</Link>*/}
            </div>
        </div>
    </div>
    <div className="header-for-mobile none-for-desktop">
        <div className="mobile-header-top">
            <div className="logo">
                <Link to={"/"} >
                <Logo theme={"white"} background={"black"} />
                </Link>
            </div>
            <div className="mobile-menu-wrap">
                <span className="open-m-menu" onClick={()=>{setMenuClass("open-menu"); document.body.classList.add('open-m-menu'); }}>
                  <Icon colorClass="gray-50-svg menu-icon" size={"medium"} value={"menu"} />
                </span>
            </div>
        </div>
        <div className="mobile-header-bottom-bar">
            <div className="hdr-workspace-data">
                <div className="workspace-dropdown">
                    <Dropdown className="dropdown">
                        <Dropdown.Toggle className="dropdown-toggle">
                            <span className={`workspace-menu-btn`}>
                                Workspace
                                <Icon size={"medium"} value={"dropdown-white"} />
                            </span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <div className={`hamburger-items`}>
                               
                                <Dropdown.Item onClick={
                                ()=>{
                                navigate("/r/payment-methods");
                                }}
                                className={`${location.pathname.includes("/r/payment-methods")?"active-menu":""}`}
                                >
                               <Icon colorClass="gray-600-svg billing-icon" size={"medium"} value={"billing"} />

                                Billing
                                </Dropdown.Item>
                                <Dropdown.Item onClick={
                                ()=>{
                                navigate("/r/credits");
                                }}
                                className={`${location.pathname.includes("/r/credits")?"active-menu":""}`}
                                >
                                <Icon colorClass="gray-600-svg credit-icon" size={"medium"} value={"credit"} />

                                Credits
                                </Dropdown.Item>
                            </div>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                <CircularNameIconGroup count={2} circularIconsArray={collaborators} />
            </div>
        </div>
        <div className={`m-nagivation-wrap ${menuClass}`}>
        <div className="mobile-header-top">
            <div className="logo">
                <Link to={"/"} >
                <img src={process.env.REACT_APP_URL+"img/UQ.svg"} alt="UserQ"/>
                </Link>
            </div>
            <div className="mobile-menu-wrap">
                <span className="close-m-menu" onClick={()=>{setMenuClass("");   document.body.classList.remove('open-m-menu');}}>
                   <Icon colorClass="gray-50-svg close-icon" size={"large"} value={"close"} />
                </span>
            </div>
        </div>
        <div className="header-login-userinfo">
            {user && user.first_name}
        </div>
        <div className="topnavigationlist navigation-listing">
            <ul>
                <li className={`${location.pathname.includes("/dashboard")?"active-menu":""}`}>
                    <Link to={"/"}>
                    <Icon colorClass="gray-600-svg dashboard-icon" size={"medium"} value={"dashboard"} />
                    <span> Dashboard </span>
                    </Link>
                </li>
                <li className={`${location.pathname.includes("/r/profile")?"active-menu":""}`}>
                    <Link to={"/r/profile"}>
                    <Icon colorClass="gray-600-svg profile-icon" size={"medium"} value={"profile"} />
                    <span> Account </span>
                    </Link>
                </li>
                <li className={`${location.pathname.includes("/r/setting")?"active-menu":""}`}>
                    <Link to={"/r/settings"}>
                    <Icon colorClass="gray-600-svg notification-icon" size={"medium"} value={"notification"} />
                    <span> Notifications settings</span>
                    </Link>
                </li>
            </ul>
        </div>
        <div className="header-workspaceheading">
            Workspace
        </div>
        <div className="middlenavigationlist navigation-listing">
            <ul>
                <li className={`${location.pathname.includes("/r/payment-methods")?"active-menu":""}`}>
                    <Link to={"/r/payment-methods"}>
                    <Icon colorClass="gray-600-svg billing-icon" size={"medium"} value={"billing"} />
                    <span> Billing </span>
                    </Link>
                </li>
                <li className={`${location.pathname.includes("/r/credits")?"active-menu":""}`}>
                    <Link to={"/r/credits"}>

                    <Icon colorClass="gray-600-svg credit-icon" size={"medium"} value={"credit"} />

                    <span className={"mobile-credits-link"} onClick={()=>{
                    navigate("/r/credits")
                    }}>
                    {!creditsLoading &&
                    <>{roundNumber(credits,2)}{" "}Credits</>
                    }
                    </span>
                    </Link>
                </li>
            </ul>
        </div>
        <div className="mobilemenubtn-box">
            <Button
                onClick={()=>{ navigate("/r/buy-credits");}}
            type={"secondary"}
            size={"large"}
            label={"Buy Credits"}
            />
            <Button
                onClick={()=>{ navigate("/r/invite-and-earn");}}
            type={"secondary"}
            size={"large"}
            label={"Refer & Earn"}
            />
        </div>
        <div className="supportnavigationbox navigation-listing">
            <ul>
                <li>
                    <Link onClick={(e)=>
                    {
                    setMenuClass("");   document.body.classList.remove('open-m-menu');
                    e.preventDefault();
                    openSupportPanel();
                    //navigate("/r/support");
                    }} to={"/r/support"}>
                    <Icon colorClass="gray-600-svg support-icon" size={"medium"} value={"support"} />
                    <span> Support </span>
                    </Link>
                </li>
            </ul>
        </div>
        <div className="bottomnavigationlist navigation-listing">
            <ul>
                <li><a href="#" onClick={logout}>Logout</a></li>
            </ul>
        </div>
    </div>
</div>
</div>
</div>
</>
);
};