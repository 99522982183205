import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { isEmpty } from "lodash";
import { Dropdown } from "react-bootstrap";
import {Input} from "../Input/Input";
import {DropdownComponent} from "../Dropdown/Dropdown";
import { Icon } from './../Icon/Icon';

export default function ScreenQuestionOptions(props) {
    const [optionError, setOptionError] = useState("");
    const [optionErrorIndex, setOptionErrorIndex] = useState(null);
    const [otherOptionIncluded, setOtherOptionIncluded] = useState(false);
    const queryAttr = "data-rbd-drag-handle-draggable-id";
    const [placeholderProps, setPlaceholderProps] = useState({});
    const [dropdownOptions, setDropdownOptions] = useState([{"label":"Good fit (continue)","value":"Good fit (continue)"}, {"label":"Not a fit (end test)","value":"Not a fit (end test)"}]);
    const [logicVal, setLogicVal] = useState(null);

    const [formErrors, setFormErrors] = useState(props.formErrors?props.formErrors:[]);

    useEffect(()=>{
        console.log(props.formErrors)
        setFormErrors(props.formErrors);
    },[props.formErrors])
    const handleDragStart = (event) => {
        const draggedDOM = getDraggedDom(event.draggableId);

        if (!draggedDOM) {
            return;
        }

        const { clientHeight, clientWidth } = draggedDOM;
        const sourceIndex = event.source.index;
        var clientY =
            parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
            [...draggedDOM.parentNode.children]
                .slice(0, sourceIndex)
                .reduce((total, curr) => {
                    const style = curr.currentStyle || window.getComputedStyle(curr);
                    const marginBottom = parseFloat(style.marginBottom);
                    const marginTop = parseFloat(style.marginTop);
                    return total + curr.clientHeight + marginTop;
                }, 0);

        setPlaceholderProps({
            clientHeight,
            clientWidth,
            clientY,
            clientX: parseFloat(
                window.getComputedStyle(draggedDOM.parentNode).paddingLeft
            ),
            background:'#CCCCCC',
            border:'none'
        });
    };

    const handleDragUpdate = (event) => {
        if (!event.destination) {
            return;
        }

        const draggedDOM = getDraggedDom(event.draggableId);

        if (!draggedDOM) {
            return;
        }

        const { clientHeight, clientWidth } = draggedDOM;
        const destinationIndex = event.destination.index;

        const sourceIndex = event.source.index;

        const childrenArray = [...draggedDOM.parentNode.children];
        const movedItem = childrenArray[sourceIndex];
        childrenArray.splice(sourceIndex, 1);

        const updatedArray = [
            ...childrenArray.slice(0, destinationIndex),
            movedItem,
            ...childrenArray.slice(destinationIndex + 1),
        ];



        var clientY =
            parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
            updatedArray.slice(0, destinationIndex).reduce((total, curr) => {
                const style = curr.currentStyle || window.getComputedStyle(curr);
                const marginBottom = parseFloat(style.marginBottom);
                const marginTop = parseFloat(style.marginTop);
                return total + curr.clientHeight + marginTop;
            }, 0);

        setPlaceholderProps({
            clientHeight,
            clientWidth,
            clientY,
            clientX: parseFloat(
                window.getComputedStyle(draggedDOM.parentNode).paddingLeft
            ),
            background:'none',
             border:'1px dashed'
        });
    };

    const getDraggedDom = (draggableId) => {
        const domQuery = `[${queryAttr}='${draggableId}']`;
        const draggedDOM = document.querySelector(domQuery);

        return draggedDOM;
    };

    var a = ['','one ','two ','three ','four ', 'five ','six ','seven ','eight ','nine ','ten ','eleven ','twelve ','thirteen ','fourteen ','fifteen ','sixteen ','seventeen ','eighteen ','nineteen '];
    var b = ['', '', 'twenty','thirty','forty','fifty', 'sixty','seventy','eighty','ninety'];

    function inWords (num) {
        //console.log(num)
        if ((num = num.toString()).length > 12) return 'overflow';
        var n = ('00000000000' + num).substr(-12).match(/^(\d{3})(\d{3})(\d{3})(\d{1})(\d{2})$/);
        if (!n) return; var str = '';
        str += (n[1] != 0) ? (Number(n[1]) > 99 ? this.a[Number(n[1][0])] + 'hundred ' : '') + (a[Number(n[1])] || b[n[1][1]] + ' ' + a[n[1][2]]) + 'billion ' : '';
        str += (n[2] != 0) ? (Number(n[2]) > 99 ? this.a[Number(n[2][0])] + 'hundred ' : '') + (a[Number(n[2])] || b[n[2][1]] + ' ' + a[n[2][2]]) + 'million ' : '';
        str += (n[3] != 0) ? (Number(n[3]) > 99 ? this.a[Number(n[3][0])] + 'hundred ' : '') + (a[Number(n[3])] || b[n[3][1]] + ' ' + a[n[3][2]]) + 'thousand ' : '';
        str += (n[4] != 0) ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'hundred ' : '';
        str += (Number(n[5]) !== 0) ? ((str !== '') ? ' ' : '') +
        //str += (Number(n[5]) !== 0) ? ((str !== '') ? 'and ' : '') +
            (a[Number(n[5])] || b[n[5][0]] + ' ' +
                a[n[5][1]]) + '' : '';
        return str;
    }


    return (
        <div style={{ position: 'relative' }}>
            <DragDropContext
                onDragEnd={(param) => {
                    const srcI = param.source.index;
                    const desI = param.destination.index;
                    props.allOptions.splice(desI, 0, props.allOptions.splice(srcI, 1)[0]);
                    props.allScreeningOptions.splice(desI, 0, props.allScreeningOptions.splice(srcI, 1)[0]);
                    formErrors.splice(desI, 0, formErrors.splice(srcI, 1)[0]);
                    setFormErrors(formErrors);
                    setPlaceholderProps({});
                }}
                onDragStart={handleDragStart}
                onDragUpdate={handleDragUpdate}
            >
                {props.language == "ar" ?
                    <Droppable droppableId="droppable-1">
                        {(provided, snapshot) => (
                            <div ref={provided.innerRef} {...provided.droppableProps}>
                                {props.allOptions.map((option, index) =>
                                        (option != "آخر") ? (
                                            <Draggable
                                                key={index}
                                                draggableId={"optiondraggable-" + index}
                                                index={index}
                                            >
                                                {(provided, snapshot) => (
                                                    <div
                                                        className={`ans-list-repeat saved-ans-repeat ${snapshot.isDragging ? "question-answers-dragging draggable-element-start" : ""
                                                            } `}
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        <div className="ans-icon" {...provided.dragHandleProps}>
                                                            <Icon value="move-object" size="medium" hover={true}/>
                                                        </div>
                                                        <div className={`ans-data ${snapshot.isDragging?'draggable-inner-element-start':''}`}>
                                                            <Input
                                                                id={"question-option-"+index}
                                                                //label={`${(inWords(index)?inWords(index+1):inWords(1))} option`}
                                                                label={ index == 0 ? "First option" :
                                                                    index == 1 ? "Second option" :
                                                                        index == 2 ? "Third option" :
                                                                            index == 3 ? "Fourth option" :
                                                                                index == 4 ? "Fifth option" :
                                                                                    index == 5 ? "Sixth option" :
                                                                                        index == 6 ? "Seventh option" :
                                                                                            index == 7 ? "Eighth option" :
                                                                                                index == 8 ? "Ninth option" :
                                                                                                    index == 9 ? "Tenth option" :
                                                                                                        "Another option"
                                                                }
                                                                placeholder="Please enter an option"
                                                                onChange={(e) => {
                                                                    props.save(index, e.target.value);
                                                                }}
                                                                required={true}
                                                                value={option}
                                                                onKeyDown={(e) => {
                                                                    if (e.key === 'Enter') {
                                                                        props.addOption(index);
                                                                    }
                                                                }}
                                                                onBlur={(e) => {
                                                                    if (props.allOptions[index] == "") {
                                                                        
                                                                    } else {
                                                                        props.save(index, e.target.value);
                                                                    }
                                                                }}
                                                                autoFocus={props.allOptions.length==2?false:true}
                                                                clearField={false}
                                                                rtl={props.language == "ar" ? true : false}
                                                                error={formErrors[index]?formErrors[index]:null}

                                                            />
                                                        </div>

                                                        <div className="ans-close-row">
                                                            <span onClick={() => props.close(index)}>
                                                                <Icon value="close" size="medium" hover={true}/>
                                                            </span>
                                                        </div>


                                                        <div className={`small-dropdown add-logic-dropdown ${props.allScreeningOptions[index]=="Not a fit (end test)"?"dropdown-alert-text":""}`}>
                                                            <DropdownComponent
                                                                label="Screening"
                                                                options={dropdownOptions}
                                                                value={
                                                                    props.allScreeningOptions[index]
                                                                }
                                                                onChange={(item) => {
                                                                    props.saveScreening(index, item.value)
                                                                }} />
                                                            
                                                        </div>
                                                        
                                                    </div>

                                                )}
                                            </Draggable>
                                        ) : (
                                            setOtherOptionIncluded(true)
                                        )
                                )}
                                {provided.placeholder}
                                {!isEmpty(placeholderProps) && (
                                    <div
                                        className="placeholder"
                                        style={{
                                            top: placeholderProps.clientY,
                                            left: placeholderProps.clientX,
                                            height: placeholderProps.clientHeight,
                                            width: placeholderProps.clientWidth,
                                            position: "absolute",
                                            borderColor: "#000000",
                                            background:placeholderProps.background,
                                            borderRadius:'10px',
                                            border:placeholderProps.border,
                                            margin: '-4px 0px 0px'
                                        }}
                                    />
                                )}
                            </div>
                        )}
                    </Droppable>
                    :
                    <Droppable droppableId="droppable-1">
                        {(provided, snapshot) => (
                            <div ref={provided.innerRef} {...provided.droppableProps}>
                                {props.allOptions.map((option, index) =>
                                        (option != "Other") ? (
                                            <Draggable
                                                key={index}
                                                draggableId={"optiondraggable-" + index}
                                                id={"draggable-" + index}
                                                index={index}
                                            >
                                                {(provided, snapshot) => (
                                                    <div
                                                        className={`d-flex m-3 ans-list-repeat saved-ans-repeat ${snapshot.isDragging ? "question-answers-dragging draggable-element-start" : ""
                                                            } `}
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        <div className="ans-icon" {...provided.dragHandleProps}>
                                                            <Icon value="move-object" size="medium" hover={true}/>
                                                        </div>
                                                        <div className={`ans-data ${snapshot.isDragging?'draggable-inner-element-start':''}`}>
                                                            
                                                            <Input
                                                                id={"question-option-"+index}
                                                                label={ index == 0 ? "First option" :
                                                                    index == 1 ? "Second option" :
                                                                        index == 2 ? "Third option" :
                                                                            index == 3 ? "Fourth option" :
                                                                                index == 4 ? "Fifth option" :
                                                                                    index == 5 ? "Sixth option" :
                                                                                        index == 6 ? "Seventh option" :
                                                                                            index == 7 ? "Eighth option" :
                                                                                                index == 8 ? "Ninth option" :
                                                                                                    index == 9 ? "Tenth option" :
                                                                                                        "Another option"
                                                                }
                                                                placeholder="Please enter an option"
                                                                onChange={(e) => {
                                                                    formErrors[index] = null;
                                                                    setFormErrors(formErrors)
                                                                    props.save(index, e.target.value);
                                                                }}
                                                                required={true}
                                                                value={option}
                                                                onKeyDown={(e) => {
                                                                    if (e.key === 'Enter') {
                                                                        props.addOption(index);
                                                                    }
                                                                }}
                                                                onBlur={(e) => {
                                                                    if (props.allOptions[index]["option"] == "") {
                                                                    } else {
                                                                        props.save(index, e.target.value);
                                                                    }
                                                                }}
                                                                autoFocus={props.allOptions.length==2?false:true}
                                                                clearField={false}
                                                                rtl={props.language == "ar" ? true : false}
                                                                error={formErrors[index]?formErrors[index]:null}
                                                                maxLength={"250"}
                                                            />
                                                        </div>

                                                        <div className="ans-close-row">
                                                            <span onClick={() => props.close(index)}>
                                                                <Icon value="close" size="medium" hover={true}/>
                                                            </span>
                                                        </div>

                                                        <div className={`small-dropdown add-logic-dropdown ${props.allScreeningOptions[index]=="Not a fit (end test)"?"dropdown-alert-text":""} `}>
                                                            <DropdownComponent
                                                                label="Screening"
                                                                options={dropdownOptions}
                                                                value={
                                                                    
                                                                    props.allScreeningOptions[index]
                                                                }
                                                                onChange={(item) => {
                                                                    props.saveScreening(index, item.value)
                                                                }} 
                                                            />
                                                        </div>
                                                    </div>

                                                )}
                                            </Draggable>
                                        ) : (
                                            setOtherOptionIncluded(true)
                                        )
                                )}
                                {provided.placeholder}
                                {!isEmpty(placeholderProps) && (
                                    <div
                                        className="placeholder"
                                        style={{
                                            top: placeholderProps.clientY,
                                            left: placeholderProps.clientX,
                                            height: placeholderProps.clientHeight,
                                            width: placeholderProps.clientWidth,
                                            position: "absolute",
                                            borderColor: "#000000",
                                            background:placeholderProps.background,
                                            borderRadius:'10px',
                                            border:placeholderProps.border,
                                            margin: '-4px 0px 10px'
                                        }}
                                    />
                                )}
                            </div>
                        )}
                    </Droppable>}
            </DragDropContext>
        </div>
           
    );
}
