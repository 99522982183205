import React, {useState, useEffect, useRef} from 'react';
import { useNavigate , Link, useParams } from 'react-router-dom';
import {encryptClient, preventClick} from '../../../lib/helpers';
import { Modal } from 'react-bootstrap';
import {Dropdown} from "reactjs-dropdown-component";
//import { ReactSession } from "react-client-session";
import { ReactSession } from '../../../lib/secure_reactsession';
import {getCollaboratorByVerificationCode,createCollaboratorByVerificationCode} from "../../../services/user";
import toast from "react-hot-toast";
import {FullPageLoadingIcon} from "../../loader/full_page_loader_lcon";
import { load } from 'recaptcha-v3'

let captcha = null;

export default function CollaboratorCreateAccount() {

    let { code } = useParams();
    let jobRolesSelect = useRef();
    const navigate = useNavigate();

   // let user = ReactSession.get("user");

    const [errorMsg, setErrorMessage] = useState(null);
    const [userData, setUserData] = useState(null);

    const [passwordVisible, setPasswordVisibility] = useState(false);

    const [confirmPasswordVisible, setConfirmPasswordVisibility] = useState(false);

    const [jobRoleLabelClass, setJobRoleLabelClass] = useState("inactive_label");

    const [isLoading, setLoading] = useState(false);
    const [formLoading, setFormLoading] = useState(false);

    const jobRoleOptions = [{ "label": "UX/UI designer", "value": "UX/UI designer" }, { "label": "UX researcher", "value": "UX researcher" }, { "label": "Product owner", "value": "Product owner" }, { "label": "Product manager", "value": "Product manager" }, { "label": "Social media manager", "value": "Social media manager" }, { "label": "Marketing manager", "value": "Marketing manager" }, { "label": "Entrepreneur", "value": "Entrepreneur" }, { "label": "Other...", "value": "Other..." }];


    const [formValues, setFormValues] = useState({first_name: '', last_name: '',password:'',company_name: '', job_role: '', job_role_other: '', confirm_password:'', code: code});

    const [formErrors, setFormErrors] = useState({first_name: null, last_name: null,company_name: null, job_role: null, job_role_other: null,password:null, passwordErrorStyle: {color:'#000'}, terms:null});

    const [modal, setModal] = useState({modalHeading:null, modalMessage:null, modalOpen:false});

    useEffect(() => {

        setTimeout(function(){
            window.animate();
        },1000);
        document.title = process.env.REACT_APP_NAME+" - Create account";

        getCollaboratorData();
    }, []);

    const generateCaptcha = (callback)=>{
        load(process.env.REACT_APP_GCAPTCHA_V3).then((recaptcha) => {
            recaptcha.execute('register').then((token) => {
                captcha = token;
    
                setFormValues({...formValues, captcha:captcha});
                
                callback(token);
                
            })
        })
    }
    const getCollaboratorData = () =>{

        setLoading(true);

        const data = new FormData();
        data.append("code", code);

        getCollaboratorByVerificationCode(data).then((response) => {
            setLoading(false);
            if (response.success) {

                setUserData(response.user);

                if(response.profile){
                    setFormValues({...formValues, 'company_name':response.profile.company_name});
                }

            } else {

                var id = toast(
                    <div className="toastinner">
                        {response.message}
                        <a
                            onClick={() => {
                                toast.dismiss(id);
                            }}
                        >
                            &times;
                        </a>
                    </div>,
                    {
                        className: "errortoast",
                        position: "bottom-center",
                    }
                );


            }
        });
    }

    const createCollaborator = () => {


        setFormLoading(true);

        generateCaptcha(function(token){
            setErrorMessage(null);

            const data = new FormData();

            data.append("captcha",token);
            data.append("code", code);
            data.append("company_name", formValues.company_name);
            //data.append("confirm_password", formValues.confirm_password);
            data.append("first_name", formValues.first_name);
            data.append("job_role", formValues.job_role);
            data.append("job_role_other", formValues.job_role_other);
            data.append("last_name", formValues.last_name);
            data.append("password", formValues.password);

            createCollaboratorByVerificationCode(data).then((response) => {

                if (response.success) {

                    generateCaptcha(function(token){
                        var body = formValues;

                        body['role'] = 'Researcher';
                        body['email'] = userData.email;
                        body['login_token'] = token;
                        body['password'] = encryptClient(formValues.password);

                        fetch(process.env.REACT_APP_API_END_POINT+'login', {
                            method: 'POST',
                            body: JSON.stringify(body),
                            headers:{"Content-Type":"application/json"}
                        })
                            .then(res => res.json())
                            .then(response => {
                                setFormLoading(false);
                                if(response.success){
                                    ReactSession.set("token", response.token);
                                    ReactSession.set("user", response.user);
                                    navigate('/dashboard');
                                } else {

                                    var id = toast(
                                        <div className="toastinner">
                                            {response.message}
                                            <a
                                                onClick={() => {
                                                    toast.dismiss(id);
                                                }}
                                            >
                                                &times;
                                            </a>
                                        </div>,
                                        {
                                            className: (response.success) ? "successtoast" : "errortoast",
                                            position: "bottom-center",
                                        }
                                    );
                                }
                            });
                        // navigate('/researcher/sign-in')
                    })
                    
                }else {

                    setFormLoading(false);

                    var id = toast(
                        <div className="toastinner">
                            {response.message}
                            <a
                                onClick={() => {
                                    toast.dismiss(id);
                                }}
                            >
                                &times;
                            </a>
                        </div>,
                        {
                            className: (response.success) ? "successtoast" : "errortoast",
                            position: "bottom-center",
                        }
                    );
                }

            });
        })
        


    }
    const validateForm = (name) => {


        return (event)=>{

            setFormValues(oldValues => ({...oldValues, [name]: event.target.value }));


            var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-zA-Z]).{6,}$/;

            if(formValues.password.length<6 || !formValues.password.match(re)){
                setFormErrors(oldValues => ({...oldValues, ['password_error_class']:  "password_error" }));
            } else {
                setFormErrors(oldValues => ({...oldValues, ['password_error_class']:  'none' }));
            }
        }

    }
    const resetFormErrors = () => {

        let formErrorsLocal = Object.assign({}, formErrors);

        for(var key in formErrors){
            formErrorsLocal[key] = null;
        }
        setFormErrors(formErrorsLocal);

        return formErrorsLocal;
    }
    const validateFullForm = () => {

        var form_errors = resetFormErrors();

        var error = false;

        var re = /^(?=.*\d)(?=.*[@$!%*#?&])(?=.*[a-zA-Z]).{6,}$/;

        var firsterrorid=null;

        if(formValues.first_name==''){

            form_errors = {
                ...form_errors,
                first_name: "Required field",
                error_class: "input_error"
            };
            error = true;

            firsterrorid=firsterrorid==null?"first-name":firsterrorid;
        }

        if(formValues.last_name==''){

            form_errors = {
                ...form_errors,
                last_name: "Required field",
                error_class: "input_error"
            };
            error = true;

            firsterrorid=firsterrorid==null?"last-name":firsterrorid;

        }

        if (formValues.job_role == '') {

            form_errors = {
                ...form_errors,
                job_role: "Required field",
                error_class: "input_error"
            };
            error = true;
            firsterrorid=firsterrorid==null?"job_role":firsterrorid;
        }
        if (formValues.job_role == 'Other...' && formValues.job_role_other == '') {

            form_errors = {
                ...form_errors,
                job_role_other: "Required field",
                error_class: "input_error"
            };
            error = true;

            firsterrorid=firsterrorid==null?"job_role_other":firsterrorid;
        }

        if(formValues.password.length<6 || !formValues.password.match(re)){

            form_errors = {
                ...form_errors,
                password:"Password",
                password_error_class:"password_error"
            };
            error = true;
            firsterrorid=firsterrorid==null?"pass_log_id":firsterrorid;


        } else {
            form_errors ={
                ...form_errors,
                password: null,
                error_class: "input_error",
                password_error_class:"none",
            };
        }
        if(formValues.confirm_password==''){

            form_errors = {
                ...form_errors,
                confirm_password: "Required field",
                error_class: "input_error"
            };
            error = true;

        }
        else if(formValues.password!=formValues.confirm_password){

            form_errors = {
                ...form_errors,
                confirm_password: "Confirm password must be same as password.",
                error_class: "input_error"
            };
            error = true;

        }



        setFormErrors(form_errors);

        if(firsterrorid!=null){
            document.getElementById(firsterrorid).scrollIntoView({
                behavior: "smooth",
                block: "center",
            });
        }

        return error;
    }
    const onSubmit =  async (event) => {
        event.preventDefault();

        setErrorMessage(null);

        if(!isLoading){

            var error = validateFullForm();

            if(!error){
                createCollaborator();
            }
        }
        return false;
    }
    const set = name => {
        return ({ target: { value } }) => {
            setFormValues(oldValues => ({...oldValues, [name]: value }));
            setFormErrors((oldValues) => ({ ...oldValues, [name]: null }));
        }
    };
    const clearFormValue = name => {
        return ({ target: { value } }) => {
            setTimeout(function(){
                setFormValues(oldValues => ({...oldValues, [name]: '' }));
            },1000)

        }
    };
    const togglePassword = () => {
        setPasswordVisibility(!passwordVisible);
    };
    const toggleConfirmPassword = () => {
        setConfirmPasswordVisibility(!confirmPasswordVisible);
    };
    const clearErrorMessage = () => {
        setErrorMessage(null);
    }

    const jobRoleChange = (item) => {
        console.log(item)
        jobRolesSelect.current.selectSingleItem({ value: item.value });
        setFormValues({...formValues,"job_role":item.value});
        setJobRoleLabelClass('active_label');
        setFormErrors((oldValues) => ({ ...oldValues, job_role: null }));
        window.animate();
    }
    return (
        <>
        {isLoading &&
                <FullPageLoadingIcon />
        }
            {!isLoading &&
            <div className="login-page-wrapper">
                <div className="login-page-header">
                    <div className="login-hdr-left">
                        <a href={process.env.REACT_APP_RESEARCHER_URL} className="logo"><img
                            src={process.env.REACT_APP_URL + "images/logo.svg"} alt="logo"/></a>
                    </div>
                </div>
                <div className="login-data-wrap">
                    <div className="container">
                        <div className="login-left-side">
                            <h1 className="form-heading">Create account</h1>
                            <p className="form-sub-head">&nbsp;</p>
                            <div className="login-form-inner-data">
                                {errorMsg &&
                                <div className="profile-complete-progress-wrap login-message-wrap error-bg-color">
                                    <div className="pro-lft-wrap">
                                        {errorMsg}
                                    </div>
                                    <div className="pro-right-wrap">
                                        <div className="delete-progress-row">
                                            <img onClick={clearErrorMessage}
                                                 src={process.env.REACT_APP_URL + "images/cross.svg"} alt="img"/>
                                        </div>
                                    </div>
                                </div>
                                }
                                <form action="#" onSubmit={onSubmit}>
                                    <div className="input input-field form-group ">
                                        <input
                                            type="text"
                                            id="first-name"
                                            className={
                                                formErrors.first_name != null
                                                    ? "form-control " + formErrors.error_class
                                                    : "form-control"
                                            }
                                            name="first_name"
                                            value={formValues.first_name}
                                            onChange={set("first_name")}
                                        />
                                        <label htmlFor="first-name" className="control-label">
                                            First name{" "}
                                            <img
                                                src={
                                                    process.env.REACT_APP_URL + "images/star-field.svg"
                                                }
                                                className="required-field-icon"
                                                alt="required-field"
                                            />
                                        </label>
                                        <span
                                            className="clear"
                                            onClick={clearFormValue("first_name")}
                                        >
                                        <svg viewBox="0 0 24 24">
                                            <path className="line" d="M2 2L22 22"/>
                                            <path className="long" d="M9 15L20 4"/>
                                            <path className="arrow" d="M13 11V7"/>
                                            <path className="arrow" d="M17 11H13"/>
                                        </svg>
                                    </span>
                                        {formErrors.first_name != null && (
                                            <span className={formErrors.error_class}>
                                            {formErrors.first_name}
                                        </span>
                                        )}
                                    </div>
                                    <div className="input input-field form-group ">
                                        <input
                                            type="text"
                                            id="last-name"
                                            name="last_name"
                                            className={
                                                formErrors.last_name != null
                                                    ? "form-control " + formErrors.error_class
                                                    : "form-control"
                                            }
                                            value={formValues.last_name}
                                            onChange={set("last_name")}
                                        />
                                        <label htmlFor="last-name">
                                            Last name{" "}
                                            <img
                                                src={
                                                    process.env.REACT_APP_URL + "images/star-field.svg"
                                                }
                                                className="required-field-icon"
                                                alt="required-field"
                                            />
                                        </label>
                                        <span
                                            className="clear"
                                            onClick={clearFormValue("last_name")}
                                        >
                                        <svg viewBox="0 0 24 24">
                                            <path className="line" d="M2 2L22 22"/>
                                            <path className="long" d="M9 15L20 4"/>
                                            <path className="arrow" d="M13 11V7"/>
                                            <path className="arrow" d="M17 11H13"/>
                                        </svg>
                                    </span>
                                        {formErrors.last_name && (
                                            <span className={formErrors.error_class}>
                                            {formErrors.last_name}
                                        </span>
                                        )}
                                    </div>

                                    <div className="input input-field form-group read-only-field">
                                        <input
                                            type="text"
                                            id="company_name"
                                            className={
                                                formErrors.company_name != null
                                                    ? "form-control " + formErrors.error_class
                                                    : "form-control"
                                            }
                                            name="company_name"
                                            readOnly
                                            value={formValues.company_name}
                                            onChange={set("company_name")}
                                        />
                                        <label htmlFor="company_name">
                                            Company name{" "}
                                            <img
                                                src={
                                                    process.env.REACT_APP_URL + "images/star-field.svg"
                                                }
                                                className="required-field-icon"
                                                alt="required-field"
                                            />
                                        </label>
                                        <span
                                            className="clear"
                                            onClick={clearFormValue("company_name")}
                                        >
                                        <svg viewBox="0 0 24 24">
                                            <path className="line" d="M2 2L22 22"/>
                                            <path className="long" d="M9 15L20 4"/>
                                            <path className="arrow" d="M13 11V7"/>
                                            <path className="arrow" d="M17 11H13"/>
                                        </svg>
                                    </span>
                                        {formErrors.company_name != null && (
                                            <span className={formErrors.error_class}>
                                            {formErrors.company_name}
                                        </span>
                                        )}
                                    </div>
                                    <div className="profile-form-group">
                                        <div className="input-field static-label">
                                            <label className={jobRoleLabelClass} htmlFor="job_role">
                                                Job Role
                                            </label>
                                            <div
                                                className={
                                                    formErrors.job_role != null
                                                        ? formErrors.error_class
                                                        : ""
                                                }
                                                id="job_role"
                                            >
                                                <Dropdown
                                                    name="job_role"
                                                    title="Job Role"
                                                    list={jobRoleOptions}
                                                    onChange={jobRoleChange}
                                                    ref={jobRolesSelect}
                                                    styles={{
                                                        headerArrowUpIcon: {  backgroundImage: `url("${process.env.REACT_APP_URL}/images/up-chevron.png")`, backgroundSize: '24px',width: '24px',height: '24px'},
                                                        headerArrowDownIcon: {  backgroundImage: `url("${process.env.REACT_APP_URL}/images/down-chevron.png")`, backgroundSize: '24px',width: '24px',height: '24px' }
                                                    }}
                                                />
                                            </div>
                                            {formErrors.job_role != null && (
                                                <span className={formErrors.error_class}>
                                                {formErrors.job_role}
                                            </span>
                                            )}
                                        </div>
                                    </div>
                                    {formValues.job_role == "Other..." && (
                                        <div className="profile-form-group">
                                            <div className="input input-field form-group">
                                                <input
                                                    type="text"
                                                    id="job_role_other"
                                                    className={
                                                        formErrors.job_role_other != null
                                                            ? "form-control " + formErrors.error_class
                                                            : "form-control"
                                                    }
                                                    name="job_role_other"
                                                    value={formValues.job_role_other}
                                                    onChange={set("job_role_other")}
                                                />
                                                <label
                                                    htmlFor="job_role_other"
                                                    className="control-label"
                                                >
                                                    Job Role Other{" "}
                                                    <img
                                                        src={
                                                            process.env.REACT_APP_URL +
                                                            "images/star-field.svg"
                                                        }
                                                        className="required-field-icon"
                                                        alt="required-field"
                                                    />
                                                </label>
                                                <span
                                                    className="clear"
                                                    onClick={clearFormValue("job_role_other")}
                                                >
                                                <svg viewBox="0 0 24 24">
                                                    <path className="line" d="M2 2L22 22"/>
                                                    <path className="long" d="M9 15L20 4"/>
                                                    <path className="arrow" d="M13 11V7"/>
                                                    <path className="arrow" d="M17 11H13"/>
                                                </svg>
                                            </span>
                                                {formErrors.job_role_other != null && (
                                                    <span className={formErrors.error_class}>
                                                    {formErrors.job_role_other}
                                                </span>
                                                )}
                                            </div>
                                        </div>
                                    )}


                                    <div className=" input-field form-group ">
                                        <div className='position-relative w-100'>
                                            <input type={passwordVisible ? "text" : "password"}
                                                   className={formErrors.password_error_class != null ? "form-control " + formErrors.password_error_class : "form-control"}
                                                   id="pass_log_id" value={formValues.password}
                                                   onChange={set('password')} onKeyUp={validateForm('password')}/>
                                            <i className="toggle-password" onClick={togglePassword}><img
                                                src={process.env.REACT_APP_URL + "images/eye.svg"} alt="eye"/></i>
                                            <label htmlFor="pass_log_id">Password <img
                                                src={process.env.REACT_APP_URL + "images/star-field.svg"}/></label>

                                        </div>
                                        {formErrors.password_error_class != 'none' &&
                                        <p className={formErrors.password_error_class != null ? "password-guide-text " + formErrors.password_error_class : "password-guide-text "}>Password
                                            must contain a minimum of 6 characters and a combination of alphabet letters
                                            [a-z], numbers [0-9] and special characters [ie. !@#$^&*].</p>
                                        }
                                    </div>
                                    <div className=" input-field  form-group mb-0 password-margin-top">
                                        <div className='position-relative w-100'>
                                            <input type={confirmPasswordVisible ? "text" : "password"}
                                                   className={formErrors.confirm_password != null ? "form-control " + formErrors.error_class : "form-control"}
                                                   value={formValues.confirm_password}
                                                   onChange={set('confirm_password')} id="pass_log_id-2"/>
                                            <i className="toggle-password" onClick={toggleConfirmPassword}><img
                                                src={process.env.REACT_APP_URL + "images/eye.svg"} alt="eye"/></i>

                                            <label htmlFor="pass_log_id-2">Confirm Password <img
                                                src={process.env.REACT_APP_URL + "images/star-field.svg"}/></label>

                                            {formErrors.confirm_password &&
                                            <span
                                                className={formErrors.error_class}>{formErrors.confirm_password}</span>
                                            }
                                        </div>
                                    </div>
                                    <div className="reset-btn-wrap">
                                        <button id="r_registration" type="submit" className="btn btn-block form-btn">
                                            Submit
                                            {formLoading &&
                                            <i className="fa fa-spinner fa-spin" aria-hidden="true"
                                               style={{"marginLeft": "5px"}}></i>
                                            }
                                        </button>
                                        <Link to="/researcher/sign-in" className="btn btn-block form-btn form-back-btn">Take
                                            me back to sign in</Link>
                                    </div>

                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }
            </>

    )
}