import React, { createContext, useState, useContext, useRef } from 'react';
import { getTestLengthService } from '../../../../services/test';
import { ReactSession } from '../../../../lib/secure_reactsession';

const TestBuilderContext = React.createContext();

export const TestBuilderProvider = ({ children }) => {
    
    const [test_time_length, setTestTimeLength] = useState(null);

    let testController = useRef();


    const updateTestLength = (time) => {
        setTestTimeLength(time);
    };
    const getTestLength = (test_id)=>{

        if (testController.current) {
            testController.current.abort();

        }
        const controller = new AbortController();

        testController.current = controller;

        let token = ReactSession.get("token");


        getTestLengthService({test_id: test_id}, token, testController.current?.signal).then((response) => {
            if (response.success) {

              updateTestLength(response.label);
            }
        });
    }

  return (
    <TestBuilderContext.Provider value={{ test_time_length, updateTestLength, getTestLength }}>
      {children}
    </TestBuilderContext.Provider>
  );
};

export const useTestBuilderData = () => {
  return React.useContext(TestBuilderContext);
};