import { useEffect, useRef, useState } from "react";
import './SearchDropdown.css';
import { Icon } from "../Icon/Icon";
import { Text } from "../Text/Text";


  
export const  SearchDropdown = ({label, options, readOnly, onChange, value, className}) =>{
    
    const [selected, setSelected] = useState(null);

    const [inputVal, setInputVal] = useState(null);

    const [startTyping, setStartTyping] = useState(false);

    const [filteredOptions, setFilteredOptions] = useState([]);

    const [showDropdown, setShowDropDown] = useState(false);

    const ref = useRef(null);

    const searchref = useRef(null);

    useEffect(()=>{
        if(value){
            
            setSelected(value);

            setInputVal("");
        }
    },[value])

    useEffect(()=>{
        setFilteredOptions(options);
    },[options])


      useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            
          if (ref.current && !ref.current.contains(event.target)) {
            setShowDropDown(false);
            setFilteredOptions(options);
            setInputVal("");
            setStartTyping(false);
          }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          // Unbind the event listener on clean up
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }, [ref]);

      useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            
          if (ref.current && !ref.current.contains(event.target)) {
            setShowDropDown(false);
            setStartTyping(false);
            setFilteredOptions(options);
            setInputVal("");
          }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          // Unbind the event listener on clean up
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }, [selected]);
      

    const renderSelectedValues=() =>{
        
        return <div className="search-label-handle">
            {!startTyping &&
            <span>
            {selected}
            </span>
            }
            <input style={{width:startTyping?"87%":"20px"}} ref={searchref} type="text" value={inputVal} 
                clearField={false}
                onChange={(e)=>{
                    setStartTyping(true);
                setInputVal(e.target.value);
                let filteredOptionsLoc = options.filter(function(item){
                    return item.value.toLowerCase().indexOf(e.target.value.toLowerCase())!=-1 ? item : false;
                });
                setFilteredOptions(filteredOptionsLoc);
                setShowDropDown(true)
            }} 
                onClick={(e)=>{
                    e.preventDefault();
                    e.stopPropagation();
                    setShowDropDown(true);
                }}
                onKeyDown={(event)=>{
                    if (event.key === 'Backspace' && !startTyping) {
                        setStartTyping(true);
                    }
                }}
            />
        </div>
            
            
        
    }
    const renderSelectBox = ()=>{
        return <div  className={`searchdropdown-box-container ${((selected && selected.length>0) || (inputVal && inputVal.length>0))?"focussed":""}`} onClick={(e)=>{ e.preventDefault(); if(searchref && searchref.current){
            searchref.current.focus();
            const input = searchref.current;
            input.setSelectionRange(input.value.length, input.value.length);
        } setShowDropDown(!showDropdown)}}>
            <div className={`searchdropdown-selected-values-container`}>
                {renderSelectedValues()}
            
            </div>
            
            <Text type={"body-text-2"} fontWeight={"normal-font"}>{label}</Text>
            <div className={`searchdropdown-dropdown-icon-container`}>
                {/*inputVal && inputVal.length > 0 &&
                <span onClick={(e)=>{ 
                    e.preventDefault();
                    e.stopPropagation();
                    setShowDropDown(true);
                    
                    setInputVal("");

                    if(searchref && searchref.current){
                        searchref.current.focus();

                    }
                    setFilteredOptions(options)
                }} >
                <Icon 
                    
                    colorClass={"gray-900-svg"} 
                    value={"close"} 
                    hover={true}
                />
                </span>
                */}
                <span onClick={(e)=>{ 
                    
                    //setInputVal(selected);

                    setStartTyping(false);
                    
                    setFilteredOptions(options)
                }}>
                <Icon colorClass={"gray-900-svg"} value={showDropdown? "Chevron up" : "Chevron"} hover={true}/>
                </span>
                
            </div>
        </div>
    }
    const renderDropDown = ()=>{

        if(showDropdown){
            return <div className="searchdropdown-options" style={{position:"absolute", background:"#fff", width:"200px"}}>
                {filteredOptions.map((option, index)=>{
                    return <div key={"select-option"+index} className={`searchdropdown-option ${option.value==selected?"searchdropdown-option-disabled":""} `}
                        onClick={(e)=>{
                            if(option.value!=selected){
                                setShowDropDown(false);

                                setStartTyping(false);
                                setInputVal("");
                                
                                setSelected(option.value);

                                

                                if(onChange){
                                    onChange(option.value);
                                }
                                setFilteredOptions(options);
                            }
                        }}
                    >
                        {option.value}
                        
                    </div>
                })}
                {filteredOptions.length==0 &&
                <div key={"select-option"} className="searchdropdown-option"
                        
>
                        
                    No data found.        
                    </div>
                }
            </div>
        } else  {
            return <></>
        }
    }
    return <div ref={ref} className={`searchdropdown-box position-relative ${className?className:""} ${readOnly?"searchdropdown-readonly":""}`}>
        {renderSelectBox()}
        {renderDropDown()}
    </div>;
}