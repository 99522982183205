import React from 'react';
import { Link } from 'react-router-dom';
import { useNavigate, useParams } from "react-router-dom";
//import { ReactSession } from "react-client-session";
import { ReactSession } from '../../../lib/secure_reactsession';
import LayoutResearcherFullWidth from "../../layouts/layout_researcher_full_width";
import { LayoutResearcher } from '../../themes/userq/Layouts/layout_researcher';
import { useState, useEffect, useRef } from "react";
import toast from 'react-hot-toast';
// import Accordian from '../../Accordian';
import { Text } from '../../themes/userq/Text/Text';
import { Icon } from '../../themes/userq/Icon/Icon';
import ResearcherSupportNavigation from './researcher_support_navigation';
import { Accordian } from '../../themes/userq/Accordion/Accordion';



export default function ResearcherSupport() {

    const navigate = useNavigate();
    const [faqSections, setFaqSections] = useState({ 'general': true, privacy: false, pricing: false, building: false, recruiting: false, analysing: false, reporting: false, refunds: false })

    const [accordianData, setAccordionData] = useState([
        {
            id: 1,
            open: true,
            title: ' What is UserQ? ',
            info:
                'UserQ is a user research platform that enables designers, managers and researchers to gain unique insights from testers in the MENA region about their digital products. You can build and publish your own tests, in English or Arabic, and easily analyse any data you collect through your UserQ account.\n' +
                '\n <br/><br/>' +
                'For testers, UserQ is a platform that pays you for your insights and opinions – it really is as simple as that.'
        },
        {
            id: 2,
            open: false,
            title: ' How does UserQ work? ',
            info:
                'We bring researchers and testers together on an easily-accessible, powerful research platform. Researchers can easily create, publish and analyse tests that are filled out by real people in the MENA region (testers). Once a test goes live, the UserQ platform finds the perfect testers based on the parameters set by the researcher, and results can come in after as soon as a few minutes.\n' +
                '\n <br/><br/>' +
                'Using the UserQ platform, researchers get the invaluable insights they need to support their projects and design process in real time, and testers get paid for their time and thoughts.',
        },
        {
            id: 3,
            open: false,
            title: ' What tests can I do with UserQ? ',
            info:
                'The UserQ platform offers six user research tests based on varying methodologies types that can be taken part in remotely.\n' +
                '\n <br/><br/>' +
                'Each of these offers a way for businesses to collect primary research data about their digital experiences from real people.\n' +
                '\n <br/><br/>' +
                'What is tree testing?\n <br/>' +
                'This kind of testing helps you to define an easy to follow, logical site hierarchy by asking testers to complete tasks using a text-only version of your site.\n' +
                '\n <br/><br/>' +
                'What is card sorting?\n <br/>' +
                'Card sorting helps you to group information together in the right way, and involves asking testers to categorise information that you give them. This will help shape your navigation structure.\n' +
                '\n <br/><br/>' +
                'What is a preference test?\n <br/>' +
                'A preference test allows you to validate your designs easily by giving your testers two options to choose from with preference testing.\n' +
                '\n <br/><br/>' +
                'What is an online survey?\n <br/>' +
                'This type of test allows you to ask whatever questions you have about your site, customers or product. Running a survey means you can get all the answers you need to more specific questions.\n' +
                '\n <br/><br/>' +
                'What is a 5 seconds test?\n <br/>' +
                'First impressions are everything in UX, and a 5 second test can tell you what your users think of your designs in the first few seconds of seeing them.\n' +
                '\n <br/><br/>' +
                'What is a first click test?\n <br/>' +
                'This test involves asking testers to perform a task using wireframes or prototypes and recording where they first click.',
        },
        {
            id: 4,
            open: false,
            title: ' And what tests can’t I carry out on UserQ?',
            info:
                " As a remote research tool, any user tests that require observation or in-person facilitation (such as focus groups) can’t be done on the UserQ platform. If you want to run in-person user research using primary research methods like focus groups, you can book a slot in our parent company, <a class='faq-support-link' href='https://www.digitalofthings.com/usability-lab'>Digital of Things’ in-house UX lab</a> - where you can access state-of-the-art tech and in-house expertise to support your design process.",
        },
        {
            id: 5,
            open: false,
            title: ' What is user testing? ',
            info:
                'User testing, whether it’s through online surveys or preference tests, assists businesses at each step of the website design process. The data collected from these different primary research methods provides key information about what designs, functionalities and navigation structures come together to create a seamless user experience.\n' +
                '\n <br/><br/>' +
                'If you’re completely new to user testing, don’t worry. UserQ is easy to use and guides you through each step of testing – from design to publishing. If you want to see how well your website functions before you fully develop it, you can carry out any of our remote tests to gain insights from real people in your local area. It’s as easy as that.',
        },
        {
            id: 6,
            open: false,
            title: ' What measures does UserQ take to ensure the quality of testers? ',
            info:
                "To ensure accuracy and credibility of tester responses, we have adopted a comprehensive tester scoring system that evaluates the quality of contributions provided by our testers. You can read more about the reporting guidelines at <a class='faq-support-link' href='"+process.env.REACT_APP_URL+"r/reporting-guidelines' target='_blank'>this link.</a>\n" +
                '\n <br/><br/>' +
                'All testers are assigned a rating within a range of 0% to 100%. Our recruitment algorithm prioritises testers with the highest score to make sure you’ll get only the best quality responses . Additionally, all testers with a score of 50% or below are automatically blocked from taking any further tests on the platform.' +
                '\n <br/><br/>' +
                "As a researcher, you also have the ability to report any testers that violate our quality standards or Terms of Use. Every request will be reviewed and approved by the QA team, lowering the tester's score to reflect their performance and initiating a credit refund directly to your wallet. ",
                
        }
    ]);

    const [accordianSecondData, setAccordionSecondData] = useState([
        {
            id: 1,
            open: true,
            title: '  Is my data secure on the UserQ platform? ',
            info: 'All data that you provide in setting up your account, including private information and payment details – is safe with us. We are completely compliant with all data privacy laws in the UAE, and your security is our top priority.'
        },
        {
            id: 2,
            open: false,
            title: '   How does UserQ use my personal information?  ',
            info: 'The UserQ platform works by matching your profile to tests where researchers are looking for insights from a specific group of people. This means that we’ll use your demographic data such as gender, age or occupation to match you to the right tests. The researchers who create the tests won’t have access to this information – it’s only used by us at UserQ to make sure we send you the right tests.\n' +
                '\n <br/><br/>' +
                'Your data is always stored securely and safely on UserQ – only you will be able to access your sensitive information such as payment details and passwords.'
        }
    ]);

    const [accordianThirdData, setAccordionThirdData] = useState([
        {
            id: 1,
            open: true,
            title: 'How do I purchase credits?',
            info: 'After logging into your researcher profile, you can purchase credits from the Bulk Buy page at this link: <a href="https://app.userq.com/r/buy-credits" class="link-text">https://app.userq.com/r/buy-credits</a>'+
                  '<br/><br/>You can save up to 20% when buying credits in bulk and utilize them whenever necessary.' 
        },
        {
            id:2,
            open: false,
            title: 'How many credits do I need to publish a test?  ',
            info: 'Publishing a test is a set fee of XX credits or XX$.'
        },
        {
            id:3,
            open: false,
            title: '    What are credits?  ',
            info: 'Credits are used to recruit testers for your projects and pay them for their time and insights. You’ll need credits to publish any tests using UserQ’s panel.\n' +
                '\n  <br/><br/>' +
                'Credits start at $1 each, with the minimum number of credits you can buy at any one time is 50. If you’re buying 50 credits, you would pay $50 at $1 each. But when you choose to buy your credits in bulk (500 or more), you’ll qualify for a discount. Here’s how it would work:\n' +
                ' <br/><ul><li>500 credits = 5% discount, so you pay $475\n</li>' +
                ' <br/><li>1000 credits =10% discount, so you pay $900\n</li>' +
                ' <br/><li>1500 credits = 15% discount, so you pay $1275\n</li>' +
                ' <br/><li>2000 credits = 20% discount, so you pay $1600\n</li></ul>' +
                '\n <br/>' +
                'The discounts are included on each transaction up to the next bracket, so if you buy between 500 and 1000 credits, you’ll get a 5% discount on your total. All you need to do is add credits to your basket and your discount will be automatically applied.'
        },
        {
            id:4,
            open: false,
            title: '   How much does it cost to use UserQ? ',
            info: 'We offer complete flexibility with our pay-as-you-go pricing, ensuring you pay only for what you use, without the requirement for a subscription or contract. This enables you to swiftly adjust your testing requirements to suit your business needs.\n' +
                '\n <br/><br/>' +
                'Each credit costs $1. When you publish your tests or recruit testers from the UserQ panel, you’ll need to utilize credits from your wallet or credit card to finalize your order. Upon completing the order, you can obtain a shareable link for your testers to take the test without any additional credit requirements.'
        },

        {
            id:5,
            open: false,
            title: '   How many credits do I need to recruit testers from the UserQ panel? ',
            info: 'If you wish to recruit from UserQ panel, you have the option to recruit using credits or pay with your credit card when publishing your test. The number of credits required varies based on the size of the panel selected and the duration of your test. Heres an overview of the average credit costs per respondent:\n' +
                '\n <br/>' +
                ' <ul><li>Short test (0-3 min): 2 credits per response\n</li>' +
                ' <br/><li>Medium-length test (3-7 min): 4 credits per response\n</li>' +
                ' <br/><li>Long test (>7 min): 6 credits per response\n</li>' 
        } 
    ]);

    const [accordianFourData, setAccordionFourData] = useState([
        {
            id: 1,
            open: true,
            title: '   What tests can I build?  ',
            info: 'The UserQ platform offers six tests based on varying methodologies. They are:\n <br/><br/>' +
                '<ul><li>Tree testing\n</li><br/>' +
                '<li>Card sorting\n</li><br/>' +
                '<li>Preference test\n</li><br/>' +
                '<li>Survey\n</li><br/>' +
                '<li>5 seconds test\n</li><br/>' +
                '<li>First click test\n</li></ul> <br/><br/>' +
                '\n' +
                'Depending on what insights you’re looking for, each test can give you different information at a different stage of the design process.'
        },
        {
            id: 2,
            open: false,
            title: '    What about other types of tests?   ',
            info: 'For tests that need to be carried out in a lab, you won’t be able to use the UserQ platform, but can book a slot in our parent company, <a href="https://www.digitalofthings.com/usability-lab">Digital of Things’ in-house UX lab</a>'
        },
        {
            id: 3,
            open: false,
            title: '   Can I edit my test once it’s live?   ',
            info: 'Unfortunately, once your test is live, you won’t be able to edit it or make changes. So make sure you double check everything before hitting that publish button!'
        },
        {
            id: 4,
            open: false,
            title: '    Who will have access to my test once it’s live?   ',
            info: 'Only the testers you choose or people you give the test link to will be able to see your test. Each of our testers in the UserQ panel also agree to not copy or share any of the content they see in their tests.'
        }
    ]);

    const [accordianFifthData, setAccordionFifthData] = useState([
        {
            id: 1,
            open: true,
            title: '    Can I recruit my own testers?   ',
            info: 'Yes, of course! When you have created your test and it’s ready to be published, you can choose who will take the test by selecting the ‘Share the test link with your own panel’ option. Remember that if your testers don’t have a UserQ account, you won’t be able to access demographic data.\n' +
                '\n <br/><br/>' +
                'Even if you do use a panel from UserQ, once your test is live, you’ll still be able access and distribute a link to your test to your own pool of testers too.'
        },
        {
            id: 2,
            open: false,
            title: ' How do I recruit testers? ',
            info:
                'When it comes to finding the right testers for your project, you have access to UserQ’s extensive panel of MENA-based testers – where you can build your own target group or select a random group of testers.\n' +
                '\n <br/><br/>' +
                'You also have the option of recruiting your own testers and distributing your test to them yourself – however, this can be more time consuming and difficult to find local testers.',
        },
        {
            id: 3,
            open: false,
            title: '    What about UserQ’s panel?   ',
            info: 'We have access to an extensive pool of testers in the MENA region. So when you choose to recruit testers from the UserQ panel, your testers will be locals who can give their unique insights, fast, and offer accurate primary research data.\n' +
                '\n  <br/><br/>' +
                'Using the UserQ panel, you have the option to create a specific target group to take your test, or select your panel randomly – the choice is yours.'
        },
        {
            id: 4,
            open: false,
            title: '    How many tests can I create at the same time?    ',
            info: 'You’ll be able to create and run as many tests as you want on the UserQ platform, providing your account has enough credits.'
        },
        {
            id: 5,
            open: false,
            title: '   How do I pay for a test I create and launch?  ',
            info: 'Once your online surveys or preferences tests are ready to be published, you’ll need to use credits to recruit testers from the UserQ panel so they can be compensated for their time. You can buy credits from your account dashboard.\n' +
                '\n <br/><br/>' +
                'If you’re planning to distribute your test to your own panel of testers, you won’t need to use any credits, and can publish your test for free.'
        }
    ]);

    const [accordianSixData, setAccordionSixData] = useState([
        {
            id: 1,
            open: true,
            title: '    How can I see the results of my test?    ',
            info: 'Once your test has a few responses, you’ll be able to access all of the insights and data collected throughout the live period through your UserQ account dashboard. You’ll have unlimited access to your results, as well as the option to download a .csv file of your findings.'
        },
        {
            id: 2,
            open: false,
            title: '    How do I analyse my results?    ',
            info: 'It’s easy to look at the data collected from your test, no matter the research methodology chosen, because UserQ displays it in an easy-to-understand way, with automatically-generated charts and diagrams.\n' +
                '\n <br/><br/>' +
                'You can also download insights using whatever parameters you want – choose to download the data from specific questions, filter out certain insights as you need, or download a general overview to identify any trends.'
        },
        {
            id: 3,
            open: false,
            title: '    Can I download data if I’ve self-recruited for my test?    ',
            info: 'Yes! Even if you use your own panel, testers’ insights will all still be recorded and available on the UserQ platform. You can view, analyse and download information the same way you could if you used a target panel from UserQ’s network.'
        }
    ]);

    const [accordianSevenData, setAccordionSevenData] = useState([
        {
            id: 1,
            open: true,
            title: '  How do I report a tester who has provided poor or inappropriate feedback to a test?',
            info: 'If you encounter a tester who has failed to meet quality standards or our Terms of Use, you can report them through the following procedure:\n' +
                '\n <br/><br/>' +
                'Step 1: '+
                '\n <br/>' +
                'Download the xlsx report from the results page of your test and analyse feedback from all the participants.'+
                '\n <br/><br/>' +
                'Step 2: '+
                '\n <br/>' +
                'Note down every participant you want to report (eg. Participant 4, 82 etc). Please note that you can only report testers on the UserQ panel and not unknown participants that have accessed tests from shared links.'+
                '\n <br/><br/>' +
                'Step 3: '+
                '\n <br/>' +
                'Return to the online results page and click on the ‘Report Testers’ button available in the top right corner. You can also select them individually from each question/task from the test results page.'+
                '\n <br/><br/>' +
                'Step 4: '+
                '\n <br/>' +
                'Select all the testers you want to report from the list and add all the violations you are reporting them for - including any additional notes you want to highlight. Please make sure the feedback is accurate, in order to avoid delays.'+
                '\n <br/><br/>' +
                'Step 5: '+
                '\n <br/>' +
                'Submit your reporting request once you are ready. Please note that you can only submit 1 request per test. So make sure that you have received all responses from your test, before submitting the report to our QA team.'+
                '\n <br/><br/>' +
                "We will review your request based on our guidelines and either approve or decline every single request. You can read more about the reporting guidelines at <a class='faq-support-link' href='"+process.env.REACT_APP_URL+"r/reporting-guidelines' target='_blank'>this link.</a>"
        },
        {
            id: 2,
            open: false,
            title: '   Who can I report on my tests? ',
            info: "You can only report UQ testers that were chosen from our tester panel and not unknown testers from a shared link. In case of approved requests, those testers’ contributions will be hidden from the test report. You can read more about the reporting guidelines at <a class='faq-support-link' href='"+process.env.REACT_APP_URL+"r/reporting-guidelines' target='_blank'>this link.</a>"
        },
        {
            id: 3,
            open: false,
            title: '   When can I start reporting testers?',
            info: "You can start reporting testers as soon as your test is live and the first response is received. While you have the option to report multiple testers from a test, please note that you can only send a single request per test. All individual reports are collated as one request, per test. So make sure to save all your selections and notes in the draft mode - until you’ve received all the results and are ready to submit the final request. "
        },
        {
            id: 4,
            open: false,
            title: '   What happens after I report a tester?',
            info: "Every report is reviewed by our QA team. Once it is found to be valid (as per our quality guidelines), the tester’s score will be lowered. We will also issue a credit refund and hide these tester(s) from your test results."
        },
        {
            id: 5,
            open: false,
            title: '   How do I know if my request has been reviewed?',
            info: "You will be notified about the status of your request via email. You can also visit the results page of the relevant test to view the full list of reports that have been approved or declined. "
        },
    ]);

    const [accordianEightData, setAccordionEightData] = useState([
        {
            id: 1,
            open: true,
            title: '  When am I eligible for a credit refund? ',
            info: 'You are eligible to get a refund of credits in the following situations:'+
            '<ul><li>When you’ve reported a tester for poor performance on your test and the request has been approved by our QA team;\n</li>' +
                '<li>When you have marked the test as completed before you have reached the participant limit set for your test\n</li></ul> <br/><br/>' 
        },
        {
            id: 2,
            open: false,
            title: '   How long does it take to receive the refund after my request has been approved?  ',
            info: 'A credit refund is issued immediately after a request to report a tester is approved or when a test is marked as completed, as described in the cases mentioned above.'
        },
        {
            id: 3,
            open: false,
            title: '   Where do I receive a refund? ',
            info: 'The refund will be added back to your wallet as credits which can be used towards your next studies.'
        }
    ]);


    useEffect(() => {
        document.title = "FAQs | "+process.env.REACT_APP_NAME;

    }, []);



    return (
        <LayoutResearcher fixed_header_target={true} isLoading={false} wrapClass={"rs-inner-profile-wrapper"} skipCheck={false} extendedFooter={false} activeMenu={""}>
            <div className='page-heading-wrap accounts-page--heading'>
                <Text type="h1">Support</Text>
            </div>
            <ResearcherSupportNavigation activeMenu="faqs" />
            <div className='faqs-section'>
                <div className="faqs-section-left">
                    <div className='stepper-wrap test-result-nav-wrap'>
                        <ul className="test-results-navigation">
                            <li key={"general"} className={`${faqSections.general == true ? "selected-option" : ""}`} onClick={
                                () => {
                                    let new_accordians = Object.assign({}, faqSections);

                                    for (var key in faqSections) {
                                        if (key == "general") {
                                            if (faqSections[key]) {
                                                new_accordians[key] = false;
                                            } else {
                                                new_accordians[key] = true;
                                            }
                                        }
                                        else {
                                            new_accordians[key] = false;
                                        }
                                    }
                                    setFaqSections(new_accordians);
                                }
                            }>
                                <span><Icon value="error" colorClass={'gray-50-svg faq-info'} size={"small"} /></span>
                                <>General</>
                            </li>
                            <li key={"privacy"} className={`${faqSections.privacy == true ? "selected-option" : ""}`} onClick={
                                () => {
                                    let new_accordians = Object.assign({}, faqSections);

                                    for (var key in faqSections) {
                                        if (key == "privacy") {
                                            if (faqSections[key]) {
                                                new_accordians[key] = false;
                                            } else {
                                                new_accordians[key] = true;
                                            }
                                        }
                                        else {
                                            new_accordians[key] = false;
                                        }
                                    }
                                    setFaqSections(new_accordians);
                                }
                            }>
                                <span><Icon value="error" colorClass={'gray-50-svg faq-info'} size={"small"} /></span>
                                <>Privacy & security</>
                            </li>
                            <li key={"pricing"} className={`${faqSections.pricing == true ? "selected-option" : ""}`} onClick={
                                () => {
                                    let new_accordians = Object.assign({}, faqSections);

                                    for (var key in faqSections) {
                                        if (key == "pricing") {
                                            if (faqSections[key]) {
                                                new_accordians[key] = false;
                                            } else {
                                                new_accordians[key] = true;
                                            }
                                        }
                                        else {
                                            new_accordians[key] = false;
                                        }
                                    }
                                    setFaqSections(new_accordians);
                                }
                            }>
                                <span><Icon value="error" colorClass={'gray-50-svg faq-info'} size={"small"} /></span>
                                <>Pricing</>
                            </li>
                            <li key={"building"} className={`${faqSections.building == true ? "selected-option" : ""}`} onClick={
                                () => {
                                    let new_accordians = Object.assign({}, faqSections);

                                    for (var key in faqSections) {
                                        if (key == "building") {
                                            if (faqSections[key]) {
                                                new_accordians[key] = false;
                                            } else {
                                                new_accordians[key] = true;
                                            }
                                        }
                                        else {
                                            new_accordians[key] = false;
                                        }
                                    }
                                    setFaqSections(new_accordians);
                                }
                            }>
                                <span><Icon value="error" colorClass={'gray-50-svg faq-info'} size={"small"} /></span>
                                <>Building tests</>
                            </li>
                            <li key={"recruiting"} className={`${faqSections.recruiting == true ? "selected-option" : ""}`} onClick={
                                () => {
                                    let new_accordians = Object.assign({}, faqSections);

                                    for (var key in faqSections) {
                                        if (key == "recruiting") {
                                            if (faqSections[key]) {
                                                new_accordians[key] = false;
                                            } else {
                                                new_accordians[key] = true;
                                            }
                                        }
                                        else {
                                            new_accordians[key] = false;
                                        }
                                    }
                                    setFaqSections(new_accordians);
                                }
                            }>
                                <span><Icon value="error" colorClass={'gray-50-svg faq-info'} size={"small"} /></span>
                                <>Recruiting testers</>
                            </li>
                            <li key={"analysing"} className={`${faqSections.analysing == true ? "selected-option" : ""}`} onClick={
                                () => {
                                    let new_accordians = Object.assign({}, faqSections);

                                    for (var key in faqSections) {
                                        if (key == "analysing") {
                                            if (faqSections[key]) {
                                                new_accordians[key] = false;
                                            } else {
                                                new_accordians[key] = true;
                                            }
                                        }
                                        else {
                                            new_accordians[key] = false;
                                        }
                                    }
                                    setFaqSections(new_accordians);
                                }
                            }>
                                <span><Icon value="error" colorClass={'gray-50-svg faq-info'} size={"small"} /></span>
                                <>Analysing results</>
                            </li>
                            <li key={"reporting"} className={`${faqSections.reporting == true ? "selected-option" : ""}`} onClick={
                                () => {
                                    let new_accordians = Object.assign({}, faqSections);

                                    for (var key in faqSections) {
                                        if (key == "reporting") {
                                            if (faqSections[key]) {
                                                new_accordians[key] = false;
                                            } else {
                                                new_accordians[key] = true;
                                            }
                                        }
                                        else {
                                            new_accordians[key] = false;
                                        }
                                    }
                                    setFaqSections(new_accordians);
                                }
                            }>
                                <span><Icon value="error" colorClass={'gray-50-svg faq-info'} size={"small"} /></span>
                                <>Reporting testers</>
                            </li>
                            <li key={"refunds"} className={`${faqSections.refunds == true ? "selected-option" : ""}`} onClick={
                                () => {
                                    let new_accordians = Object.assign({}, faqSections);

                                    for (var key in faqSections) {
                                        if (key == "refunds") {
                                            if (faqSections[key]) {
                                                new_accordians[key] = false;
                                            } else {
                                                new_accordians[key] = true;
                                            }
                                        }
                                        else {
                                            new_accordians[key] = false;
                                        }
                                    }
                                    setFaqSections(new_accordians);
                                }
                            }>
                                <span><Icon value="error" colorClass={'gray-50-svg faq-info'} size={"small"} /></span>
                                <>Credit refunds</>
                            </li>
                            
                        </ul>
                    </div>
                </div>
                <div className="faqs-section-right">
                    <div className="sp5-overview-data faq-data-wrapper">
                        <div className="accordion" id="faq">
                            {faqSections.general == true && (
                                <>
                                    <Text type="h4">General</Text>
                                    {accordianData.map((data, index) => (
                                        <Accordian title={data.title} open={data.open}
                                            onChange={() => {
                                                if (data.open == true) {
                                                    accordianData[index].open = false;
                                                } else {
                                                    accordianData[index].open = true;
                                                }
                                                setAccordionData([...accordianData]);
                                            }}
                                        >

                                            <Text type="body-text-2" fontWeight="medium-font">
                                                <div className="faq-accordion-data" dangerouslySetInnerHTML={{ __html: data.info }}>
                                                </div>
                                            </Text>
                                        </Accordian>
                                    ))}
                                </>
                            )}

                            {faqSections.privacy == true && (
                                <>
                                    <Text type="h4">Privacy and security</Text>
                                    {accordianSecondData.map((data, index) => (
                                        <Accordian title={data.title} open={data.open}
                                            onChange={() => {
                                                if (data.open == true) {
                                                    accordianSecondData[index].open = false;
                                                } else {
                                                    accordianSecondData[index].open = true;
                                                }
                                                setAccordionSecondData([...accordianSecondData]);
                                            }}
                                        >
                                            <Text type="body-text-2" fontWeight="medium-font">
                                                <div className="faq-accordion-data" dangerouslySetInnerHTML={{ __html: data.info }}>
                                                </div>
                                            </Text>
                                        </Accordian>
                                    ))}
                                </>
                            )}


                            {faqSections.pricing == true && (
                                <>
                                    <Text type="h4">Pricing plan</Text>
                                    {accordianThirdData.map((data, index) => (
                                        <Accordian title={data.title} open={data.open}
                                            onChange={() => {
                                                if (data.open == true) {
                                                    accordianThirdData[index].open = false;
                                                } else {
                                                    accordianThirdData[index].open = true;
                                                }
                                                setAccordionThirdData([...accordianThirdData]);
                                            }}
                                        >
                                            <Text type="body-text-2" fontWeight="medium-font">
                                                <div className="faq-accordion-data" dangerouslySetInnerHTML={{ __html: data.info }}>
                                                </div>
                                            </Text>
                                        </Accordian>
                                    ))}
                                </>
                            )}

                            {faqSections.building == true && (
                                <>
                                    <Text type="h4">Building tests</Text>
                                    {accordianFourData.map((data, index) => (
                                        <Accordian title={data.title} open={data.open}
                                            onChange={() => {
                                                if (data.open == true) {
                                                    accordianFourData[index].open = false;
                                                } else {
                                                    accordianFourData[index].open = true;
                                                }
                                                setAccordionFourData([...accordianFourData]);
                                            }}
                                        >

                                            <Text type="body-text-2" fontWeight="medium-font">
                                                <div className="faq-accordion-data" dangerouslySetInnerHTML={{ __html: data.info }}>
                                                </div>
                                            </Text>
                                        </Accordian>
                                    ))}
                                </>
                            )}

                            {faqSections.recruiting == true && (
                                <>
                                    <Text type="h4">Recruiting testers</Text>
                                    {accordianFifthData.map((data, index) => (
                                        <Accordian title={data.title} open={data.open}
                                            onChange={() => {
                                                if (data.open == true) {
                                                    accordianFifthData[index].open = false;
                                                } else {
                                                    accordianFifthData[index].open = true;
                                                }
                                                setAccordionFifthData([...accordianFifthData]);
                                            }}
                                        >

                                            <Text type="body-text-2" fontWeight="medium-font">
                                                <div className="faq-accordion-data" dangerouslySetInnerHTML={{ __html: data.info }}>
                                                </div>
                                            </Text>
                                        </Accordian>
                                    ))}
                                </>
                            )}

                            {faqSections.analysing == true && (
                                <>
                                    <Text type="h4">Analysing your results</Text>
                                    {accordianSixData.map((data, index) => (
                                        <Accordian title={data.title} open={data.open}
                                            onChange={() => {
                                                if (data.open == true) {
                                                    accordianSixData[index].open = false;
                                                } else {
                                                    accordianSixData[index].open = true;
                                                }
                                                setAccordionSixData([...accordianSixData]);
                                            }}
                                        >

                                            <Text type="body-text-2" fontWeight="medium-font">
                                                <div className="faq-accordion-data" dangerouslySetInnerHTML={{ __html: data.info }}>
                                                </div>
                                            </Text>
                                        </Accordian>
                                    ))}
                                </>
                            )}

                            {faqSections.reporting == true && (
                                <>
                                    <Text type="h4">Reporting testers</Text>
                                    {accordianSevenData.map((data, index) => (
                                        <Accordian title={data.title} open={data.open}
                                            onChange={() => {
                                                if (data.open == true) {
                                                    accordianSevenData[index].open = false;
                                                } else {
                                                    accordianSevenData[index].open = true;
                                                }
                                                setAccordionSevenData([...accordianSevenData]);
                                            }}
                                        >

                                            <Text type="body-text-2" fontWeight="medium-font">
                                                <div className="faq-accordion-data" dangerouslySetInnerHTML={{ __html: data.info }}>
                                                </div>
                                            </Text>
                                        </Accordian>
                                    ))}
                                </>
                            )}

                            {faqSections.refunds == true && (
                                <>
                                    <Text type="h4">Credit refunds</Text>
                                    {accordianEightData.map((data, index) => (
                                        <Accordian title={data.title} open={data.open}
                                            onChange={() => {
                                                if (data.open == true) {
                                                    accordianEightData[index].open = false;
                                                } else {
                                                    accordianEightData[index].open = true;
                                                }
                                                setAccordionEightData([...accordianEightData]);
                                            }}
                                        >

                                            <Text type="body-text-2" fontWeight="medium-font">
                                                <div className="faq-accordion-data" dangerouslySetInnerHTML={{ __html: data.info }}>
                                                </div>
                                            </Text>
                                        </Accordian>
                                    ))}
                                </>
                            )}

                        </div>
                    </div>
                </div>
            </div>
        </LayoutResearcher>
    )
}