import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import { Icon } from "../Icon/Icon";

export const Search=({size, type,  value, defaultValue, placeholder, maxLength,id, onChange, label, onClear, inactive, active,loading, error,onBlur})=>{
    const [fieldType, setFieldType] =   useState((type ? type : "text"));
    return (
        <div className={` form-group input-field input-with-icon search-input-field  mb-0 ${size ? size+'-search-field' : ''} ${value!=''?'focused active-search input-with-right-icon':''}   ${inactive==true?'inactive-search':''} ${active==true?'active-search':''} ${error?'field-error':''}`}>
            <div className={`input-position-relative`}>
                <input
                    type={fieldType}
                    id={id}
                    className={`form-control  body-text body-text-2`}
                    value={value}
                    defaultValue={defaultValue}
                    onChange={onChange}
                    maxLength={maxLength}
                    placeholder={placeholder? placeholder : "Search"}
                    onBlur={onBlur}
                />
                <span class="input-icon"><Icon value="search" size="medium" hover={true} /></span>

                {loading==false && value!='' && (
                <span className="input-right-icon" onClick={onClear}>
                    <Icon value="close" size="medium" hover={true}/>
                </span>
                )}
                {loading==true && (
                <span className="input-right-icon">
                    <span className="autosave-icon info-wrap">
                                    <i
                                        className="fa fa-spinner fa-spin"
                                        aria-hidden="true"
                                        style={{ marginRight: "5px" }}
                                    ></i>
                            </span>
                </span>
                )}
    
            </div>
            {error != null && (
                    <span className="caption">
                        {error}
                    </span>
                )}
            
        </div>
    )
}
Search.propTypes = {
    type:PropTypes.string,
    value: PropTypes.string,
    defaultValue: PropTypes.string,
    maxLength: PropTypes.number,
    id:PropTypes.string,
    onChange: PropTypes.func,
    label: PropTypes.string,
    inactive:PropTypes.bool,
    focussed:PropTypes.bool,
    
};
Search.defaultProps = {
    onChange: undefined,
    inactive:false,
    focussed:false
    
};
