import React, { useRef} from "react";
import { Text } from "../../../themes/userq/Text/Text";
import PieChart from "./pie_chart_v2";
import { theme } from "../../../themes/userq/theme";
import { Box } from "../../../themes/userq/Box/Box";
import { Icon } from "../../../themes/userq/Icon/Icon";
import { Tooltip } from "../../../themes/userq/Tooltip/Tooltip";
import Skeleton from "react-loading-skeleton";
import exportAsImage from "../../../../lib/exportAsImage";


export const PieStatsBox = ({title, colorsSet, data, loading}) =>{

    let colors = {};

    const exportPngRef = useRef();

    const color_unknown = theme.colors["gray"]["600"];

    const colorsPallete = colorsSet ?
        colorsSet:
        [
            "rgba(255, 109, 177, 1)",
            "rgba(151, 89, 203, 1)",
            "rgba(242, 198, 144, 1)",
            "rgba(105, 140, 231, 1)",
            "rgba(49, 240, 229, 1)",
            "rgba(55, 139, 16, 1)",
            "rgba(227, 47, 131, 1)",
            "rgba(121, 62, 171, 1)",
            "rgba(215, 165, 106, 1)",
            "rgba(54, 104, 231, 1)",
            "rgba(17, 191, 180, 1)",
            "rgba(22, 65, 3, 1)",
            "rgba(255, 155, 202, 1)",
            "rgba(191, 142, 232, 1)",
            "rgba(191, 142, 232, 1)",
            "rgba(158, 180, 237, 1)",
            "rgba(144, 242, 236, 1)",
            "rgba(191, 240, 168, 1)"
        ];
    var color_index = 0;
    for(var key in data){

        if(key=="Unknown"){
            colors["Unknown"] = color_unknown;
        } else {
            colors[key] = colorsPallete[color_index];
            color_index++;
        }
    }
    const renderLegend = ()=>{
        let legends = [];

        var index = 0;
        for(var key in data){

            if(key=="Unknown"){
                legends.push( (
                    <div key={"legend"+key} className="chart-info-repeat unknown-info ">
                        <span style={{ "display": "inline-block", "backgroundColor": color_unknown}}></span>
                        <p>{key}
                            <span className="info-icon info-wrap position-relative">
                                <Icon value={"information"} colorClass={'gray-700-svg'} size="medium" />
                                <Tooltip
                                    className={"tooltip-wrapper"}
                                    type={"bottom"}
                                    data="If you directly shared the link with others, you might see unknown users outside your target demographic group."
                                />
                            </span>
                        </p>

                    </div>
                ));

            } else {

                legends.push( (
                    <div key={"legend"+key} className="chart-info-repeat">
                        <span style={{ "display": "inline-block", "backgroundColor": colorsPallete[index]}}></span>
                        <p>{key}</p>
                    </div>
                ));
            }
            index++;
        }
        return legends;
    }


    return(
        <div className="chart-hold-wrap position-relative">

            <div className="download-chart-png cursor-pointer" onClick={() => exportAsImage(exportPngRef.current, title ? title : 'Chart')}>
                <Icon value={'download'} size={'small'} hover={'true'}></Icon>
                <div className="tooltip-wrapper top-tooltip">
                    <p class="body-text body-text-2 normal-font ">Download</p>
                </div>
            </div>

            <div className="donwload-chart-ref" ref={exportPngRef}>

                <Box title={title} size={"medium"}>
                    <div  className="pie-chart-box">
                        <div  className="pie-chart-area">
                            {loading
                                ?
                                <>
                                    <Skeleton width={240} height={240} style={{borderRadius:'115px'}} />
                                </>
                                :
                                <PieChart colors={colors} data={data} width={240} height={240} animate={false} />
                            }

                        </div>
                        <div className="chart-info-wrap">
                            {loading
                                ?
                                <>
                                    <div className="chart-info-repeat">
                                        <Skeleton width={100} height={25} style={{borderRadius:'10px'}} />
                                    </div>
                                    <div className="chart-info-repeat">
                                        <Skeleton width={100} height={25} style={{borderRadius:'10px'}} />
                                    </div>
                                </>
                                :
                                <>{renderLegend()}</>
                            }
                        </div>
                    </div>

                </Box>
            </div>
        </div>
    );
}