import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Box } from "../Box/Box";
import { Text } from "../Text/Text";
import React, { memo, useState } from "react";

export const InformationTilesBox = ({tiles})=>{
    
    
    return (
        
        <div className="did-you-know-slider-hold did-you-know-slider didyou-know-slider-component">
            
            <Box size={"large"}>
                <OwlCarousel
                    className="owl-theme"
                    loop={true}
                    dots={true}
                    margin={0}
                    nav
                    mouseDrag={true}
                    touchDrag={true}
                    responsiveclassName={true}
                    autoplay={true}
                    autoplayTimeout={1000*60*7}
                    responsive={{
                        0: { items: 1 },
                        600: { items: 1 },
                        1000: { items: 1 },
                        1600: { items: 1 }
                    }}
                   // onChanged={(object) => updateCarouselPosition(object)}
                    
                >
                    {tiles.map((item, index) => {
                        return <InformationTile item={item} index={index} />
                    })}
                    


                </OwlCarousel>
            </Box>
        </div>
    );
};
export const InformationTile = ({item, index})=>{
    return (
        <div key={"slide" + index} className={`did-you-know-slide`}>
            <Text type={"h1"}>
                {item.title}
            </Text>
            <Text type={"subtitle-1"} fontWeight="medium-font">
                {item.subtitle}
            </Text>
        </div>
    );
}