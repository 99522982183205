import { useState } from "react";
import PreferenceTest from "./preference_test/preference_test";
import CardSorting from "./card_sorting/card_sorting";
import TreeSort from "./tree_sort/tree_sort_test";
import SurveyTest from "./survey/survey_test";
import FiveSecondsTest from './five_seconds_test/five_seconds_test';
import PrototypeTest from "./prototype_test/prototype_test";
import FirstClickTest from "./firstclick_test/firstclick_test";

export default function Methodology({test, result, methodologyCallback,timeSpent, questionCallback,isPaidUser}){

    return (
        <>
            
            {test.methodology=="Tree Test" &&
                <TreeSort test={test} result={result} timeSpent={timeSpent} callback={methodologyCallback} />
            }
            {test.methodology=="Preference Test" &&
                <PreferenceTest test={test} result={result} timeSpent={timeSpent} callback={methodologyCallback} questionCallback={questionCallback} questionType="Preference Test Followup Questions"/>
            }
            {test.methodology=="Card Sorting" &&
            <CardSorting test={test} result={result} timeSpent={timeSpent} callback={methodologyCallback} />
            }
            {test.methodology=="Survey" &&
            <SurveyTest test={test} result={result} timeSpent={timeSpent} callback={methodologyCallback} questionCallback={questionCallback} questionType="Survey"/>
            }
            {test.methodology=="Five Seconds Test" &&
            <FiveSecondsTest test={test} result={result} timeSpent={timeSpent} callback={methodologyCallback} questionCallback={questionCallback} questionType="Five Seconds Test Questions"/>
            }
            {test.methodology=="Prototype test" &&
            <PrototypeTest test={test} result={result} timeSpent={timeSpent} callback={methodologyCallback} questionCallback={questionCallback} questionType="Prototype" isPaidUser={isPaidUser}/>
            }
            {test.methodology=="First click" &&
            <FirstClickTest test={test} result={result} timeSpent={timeSpent} callback={methodologyCallback} questionCallback={questionCallback} questionType="Firstclick" isPaidUser={isPaidUser}/>
            }
        </>

    );
}