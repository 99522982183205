import { Icon } from "../Icon/Icon";

export const Chip = ({title, value, type, onRemove, onClick}) => {

    return (
        <div className={`chip chip-${type?type:"default"} `} onClick={(e)=>{ if(onClick){ onClick(value); } }}>
            {type && type=="icon-left" &&
            <span className="chip-option-icon-left" onClick={()=>{ if(onRemove){ onRemove(value); } }}>
                <Icon colorClass={"gray-50-svg"}  value={"Close"} size={"small"} />
            </span>
            }
            {type && type=="selected" &&
            <span className="chip-option-icon-left" onClick={()=>{ if(onRemove){ onRemove(value); } }}>
                <Icon colorClass={"gray-900-svg"} value={"Check mark"} size={"small"} />
            </span>
            }
            <span className="chip-name" >{title}</span>
            {type && type=="icon-right" &&
            <span className="chip-option-icon-right" onClick={()=>{ if(onRemove){ onRemove(value); } }}>
                <Icon colorClass={"gray-50-svg"}  value={"Close"} size={"small"} />
            </span>
            }
        </div>        
    );
};
export const Chips = ({children}) => {

    return (
        <div className="chips-wrap">
            {children}
        </div>        
    );
};