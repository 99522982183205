import { useNavigate, useParams, Link, useSearchParams } from 'react-router-dom';
import { ReactSession } from "../../../lib/secure_reactsession.js";
import React, { useEffect, useRef, useState } from "react";
import {
    duplicateQuestion,
    getTestData, removeFiveSecondsDesignService, saveFiveSecondsTestData, saveFiveSecondsTestTask, saveSurveyQuestions
} from "../../../services/test";
import moment from "moment";
import toast from "react-hot-toast";
import { LayoutResearcher } from './../../themes/userq/Layouts/layout_researcher';
import { Stepper } from './../../themes/userq/Stepper/Stepper';
import { Button } from './../../themes/userq/Button/Button';
import { LoadingIcon } from "./../../themes/userq/Loader/loadingIcon";
import { Accordian } from './../../themes/userq/Accordion/Accordion';
import { Icon } from './../../themes/userq/Icon/Icon';
import Header from "./components/header";
import { Text } from "../../themes/userq/Text/Text";
import { RadioButton } from "../../themes/userq/RadioButton/RadioButton";
import { ToggleButton } from "../../themes/userq/ToggleButton/ToggleButton";
import { Chip } from "../../themes/userq/Chips/Chips";
import Radiobtn from "../../layouts/elements/fields/radiobtn";
import { Input } from "../../themes/userq/Input/Input";
import InputField from "../../layouts/elements/fields/input";
import { encryptId, getFileType, matchExtensions, maxLengthCheck, renderTestTitleCreate } from "../../../lib/helpers";
import { FormattedMessage } from "react-intl";
import ConfirmationModal from "../../themes/userq/Modal/ConfirmationModal";
import { QuestionInput } from "../../themes/userq/Question/QuestionInput";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { ToastMessage } from "../../themes/userq/ToastMessage/ToastMessage";
import { IntroductionQuestions } from "./introduction_questions.js";
import EditQuestion from "./survey/editQuestion";
import Frame from "../study/frame/frame";
import Questions from "./survey/questions";
import _,{ isEmpty } from "lodash";
import { QuestionCard } from "../../themes/userq/QuestionCard/QuestionCard";
import { Question } from "../../themes/userq/Question/Question";
import {EditQuestionInput} from "../../themes/userq/Question/EditQuestionInput";
import FiveSecondsTestDesignModal from "./five_seconds_test/five_seconds_test_design_modal";
import EditPreferenceTestDesignModal from "./five_seconds_test/edit_five_seconds_test_design_modal";
import LayoutResearcherFullWidth from "../../layouts/layout_researcher_full_width";
import { ImageUpload } from "./five_seconds_test/image_upload_design";
import { settings } from '../../../data/settings.js';
import { ConclusionQuestions } from './conclusion_questions';
import { GuideLink } from '../../themes/userq/GuideLink/GuideLink.js';
import { Helmet } from 'react-helmet';
import { useTestBuilderData } from "./contexts/TestBuilderContext.js";

export default function FiveSecondsTest() {

    let { test_id } = useParams();
    const nodeRef = useRef(null);
    const navigate = useNavigate();
    ReactSession.setStoreType("localStorage");

    // check question validate error ref
    const questionInputValidateRef = useRef(null);
    const introductionQuestionRef = useRef(null); // introduction ref
    const conclusionQuestionRef = useRef(null); // conclusion ref

    const { updateTestLength, getTestLength } = useTestBuilderData();

    const [pageLoading, setPageLoading] = useState(false);
    const [formValues, setFormValues] = useState({
        task: "",
        image: null,
        design: null
    });
    const [formErrors, setFormErrors] = useState({
        task: null,
        image: null,
        "error_class": null,
    });

    const [surveyStepLoading, setSurveyStepLoading] = useState(false);
    const [language, setLanguage] = useState("en");
    const [questionType, setQuestionType] = useState("");
    const [surveyQuestions, setSurveyQuestions] = useState([]);

    const [lastSaved, setLastSaved] = useState(null);

    const [isLoading, setLoading] = useState(false);

    const [addingSurveyQuestions, setAddingSurveyQuestions] = useState(false);
    const [edittingSurveyQuestions, setEdittingSurveyQuestions] = useState(false);
    const [editQuestionId, setEditQuestionId] = useState(null);

    const [confirmModal, setConfirmModal] = useState({ open: false });


    const [test, setTest] = useState("");

    const queryAttr = "data-rbd-drag-handle-draggable-id";
    const [placeholderProps, setPlaceholderProps] = useState({});

    const [previewUrl, setPreviewUrl] = useState(null);
    const [image, setImage] = useState(null);
    const [testImage, setTestImage] = useState(null);
    const [imageDeleted, setImageDeleted] = useState(false);
    const [fileType, setFileType] = useState("image");
    const [designTitle, setDesignTitle] = useState("");
    const [backgroundColor, setBackgroundColor] = useState("#000000");
    const [addDesignModal, setAddDesignModal] = useState({ open: false });
    const [editDesignModal, setEditDesignModal] = useState({ open: false, design: null });
    const [viewUrl, setViewUrl] = useState(null);

    const [isSaved, setIsSaved] = useState({ 'status': '', 'message': '' });
    const [credits, setCredits] = useState(0);

    const [stepperError, setStepperError] = useState(false);
    const [searchParams] = useSearchParams()

    const [introCount, setIntroCount] = useState(0);
    const [concCount, setConcCount] = useState(0);
    const [introductionQuestions, setIntroductionQuestions] = useState(null);
    const [conclusionQuestions, setConclusionQuestions] = useState(null);

    const [accordians, setAccordians] = useState({ 'introduction': false, fivesecondtest: searchParams.get("error") == "true"?true:false, conclusion: false })

    let testController = useRef();

    const [duplicateProgress, setDuplicateProgress] = useState(false);

    let duplicateController = useRef();

    const disableNextButton = useRef(false);
    const questionsArraywithErrorLogics = useRef([]);
    const initialSave = useRef(true);

    const [show_support_panel, setShowSupportPanel] = useState(false);


    const showError = (error) => {
        toast(
            <ToastMessage type={"error"} message={error} closable={false} onClose={() => { toast.dismiss(); }} />,
            {
                id:"error",
                className: "errortoast",
                position: "bottom-center",
                duration: settings.toast_duration,
            }
        );
    };

    function Position(obj) {
        var currenttop = 0;
        if (obj.offsetParent) {
            do {
                currenttop += obj.offsetTop;
            } while ((obj = obj.offsetParent));
            return [currenttop];
        }
    }
    const scrollto = (area) => {

        var fixedElementHeight = document.querySelector('.researcher-header').offsetHeight + document.querySelector('.three-column-layout-top-header').offsetHeight;
        
        var offsetTop = Position(document.getElementById(area));
        window.scrollTo({ top: offsetTop - fixedElementHeight + 0, behavior: "smooth" });

    }

    const toggleLogicHandler = (logicstatus, questionindex) => {
        surveyQuestions[questionindex]['is_logic'] = logicstatus;
        surveyQuestions[questionindex]['jumpTo'] = [];
        var jumptolist = [];
        for (var i = 0; i < surveyQuestions[questionindex]['options'].length; i++) {
            jumptolist.push(questionindex + 1 < surveyQuestions.length ? questionindex + 2 : "End Survey");
        }
        surveyQuestions[questionindex]['jumpTo'] = jumptolist;

        surveyQuestions.splice(
            questionindex,
            1,
            surveyQuestions[questionindex]
        );
        setSurveyQuestions([
            ...surveyQuestions,
        ]);
    };

    const saveOptionJumpToHandler = (optionindex, value, questionindex) => {
        surveyQuestions[questionindex]['jumpTo'][optionindex] = value;
        surveyQuestions.splice(
            questionindex,
            1,
            surveyQuestions[questionindex]
        );
        setSurveyQuestions([
            ...surveyQuestions,
        ]);
    };

    const resetFormErrors = () => {

        let formErrorsLocal = Object.assign({}, formErrors);

        for (var key in formErrors) {
            formErrorsLocal[key] = null;
        }
        setFormErrors(formErrorsLocal);

        return formErrorsLocal;
    };
    const handleFile = (file) => {

        resetFormErrors();
        //let's grab the image file
        var error = false;

        var fileType = getFileType(file);

        if (fileType == "image" && file.size / 1024 > 2048) {

            setFormErrors({ ...formErrors, image: "Please select file <= 2MB.", "error_class": "input_error" });

            error = true;
        }
        if (!matchExtensions(["mp4", "jpg", "jpeg", "gif", "png", "MP4", "JPG", "JPEG", "GIF", "PNG"], file)) {


            setFormErrors({ ...formErrors, image: "Only video and image files are accepted with extensions mp4, jpg, jpeg, gif and png.", "error_class": "input_error" });
            error = true;
        }
        if (fileType == "video" && file.size / 1024 > 4096) {

            setFormErrors({ ...formErrors, image: "Please select file <= 4MB.", "error_class": "input_error" });

            error = true;
        }


        if (fileType != "image" && fileType != "video") {


            setFormErrors({ ...formErrors, image: "Only video and image files are accepted.", "error_class": "input_error" });
            error = true;
        }

        if (!error) {

            if (fileType == "image") {
                setFileType("image");

                setFormValues({ ...formValues, file_type: "image" });
            }
            if (fileType == "video") {
                setFileType("video");

                setFormValues({ ...formValues, file_type: "video" });
            }
            setImage(file);

            setPreviewUrl(URL.createObjectURL(file));
        }

    };

    const removeImage = (e) => {
        let token = ReactSession.get("token");
        var data = new FormData();

        data.append("test_id", test_id);

        setIsSaved({ 'status': 'loading', 'message': '' });
        removeFiveSecondsDesignService(data, token).then((response) => {
            if (response.success) {

                setFormValues({ ...formValues, design: null });
                setLastSaved(moment.now());
                setImage(null);
                setPreviewUrl(null);
                setTestImage(null);
                setImageDeleted(true);
                setIsSaved({ 'status': 'success', 'message': '' });
                // setFormErrors({
                //     ...setFormErrors,
                //     image: "A design is required",
                //     error_class: "input_error"
                // });


            } else {
                setIsSaved({ 'status': 'error', 'message': response.message });
                showError(response.message);
            }
        });

    };

    const handleOnDragOver = (event) => {
        event.preventDefault();
    };

    const handleOnDrop = (event) => {
        //prevent the browser from opening the image
        event.preventDefault();
        event.stopPropagation();
        //let's grab the image file
        let imageFile = event.dataTransfer.files[0];

        handleFile(imageFile);
    };

    const handleFileInput = (event) => {
        //prevent the browser from opening the image
        event.preventDefault();
        event.stopPropagation();
        //let's grab the image file

        let imageFile = event.target.files[0];
        handleFile(imageFile);
    };

    const setTask = (name) => {
        return ({ target: { value } }) => {
            setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
            if (value.length > 0) {
                setFormErrors({ task: null, error_class: null });
                setStepperError(false);
                save5SecondsTestTask(value);
            }
        };
    };

    const cancelQuestionHandler = () => {
        if (surveyQuestions.length != 0) {
            setAddingSurveyQuestions(true);
        }
        setQuestionType("");
        setEdittingSurveyQuestions(false);
    };
    const cancelEditQuestionHandler = () => {
        setEdittingSurveyQuestions(false);
        setEditQuestionId(null);
    };

    const removequestion = (index) => {
        const list = [...surveyQuestions];
            list.splice(index, 1);
            setSurveyQuestions(list);
            if (list.length == 0) {
                setAddingSurveyQuestions(true);
            }
        // if (!edittingSurveyQuestions) {
        //     const list = [...surveyQuestions];
        //     list.splice(index, 1);
        //     setSurveyQuestions(list);
        //     if (list.length == 0) {
        //         setAddingSurveyQuestions(true);
        //     }
        // } else {
        //     showError("Finish editing question");
        // }
    };

    function Position(obj) {
        var currenttop = 0;
        if (obj.offsetParent) {
            do {
                currenttop += obj.offsetTop;
            } while ((obj = obj.offsetParent));
            return [currenttop];
        }
    }

    const duplicatequestion = (index) => {
        if (!duplicateProgress && !edittingSurveyQuestions) {
            if(surveyQuestions.length<3){
                let list = [...surveyQuestions];
                // let question = Object.assign({},surveyQuestions[index]);
                let question = _.cloneDeep(surveyQuestions[index]);

                if (duplicateController.current) {
                    duplicateController.current.abort();
        
                }
                const controller = new AbortController();
        
                duplicateController.current = controller;

                setDuplicateProgress(true);
                
                setLoading({ 'status': 'loading', 'message': '' });
                
                let token = ReactSession.get("token");

                var data = {};

                data.test_id = test.id;

                data.question = question;

                duplicateQuestion(data, token, duplicateController.current?.signal).then((response) => {

                    setDuplicateProgress(false);

                    if (response.success) {

                        setLoading({ 'status': 'success', 'message': '' });
                    
                        list.splice(index, 0, response.question);
                        
                        setSurveyQuestions(list);

                        //scrolling to duplicated question

                        var fixedElementHeight = document.querySelector('.researcher-header').offsetHeight+document.querySelector('.three-column-layout-top-header').offsetHeight;
                        var offsetTop = Position(document.getElementById("ques-"+(index)));
                        window.scrollTo({ top: offsetTop - fixedElementHeight + 0, behavior: "smooth" });

                        setTimeout(function () {
                            var duplicateQuestionElement = document.getElementById("question-"+(index+1));
                            //console.log(duplicateQuestionElement)
                            if(duplicateQuestionElement){

                                duplicateQuestionElement.classList.add("question-duplicate-animation");

                                setTimeout(function () {

                                    duplicateQuestionElement.classList.remove("question-duplicate-animation");
                                    // console.log(duplicateQuestionElement);
                                }, 5000)
                            }
                        }, 100)
                            
                    } else {
                        setLoading({ 'status': 'error', 'message': response.message });
                    }
                });
            
                

            }   else{
                showError("You've hit the maximum amount of follow up questions! If you need to add more, consider removing earlier ones.");
            }
        } else {
            showError("Finish editing question");
        }
    };

    const edittingQuestionsHandler = (index) => {
        if (!edittingSurveyQuestions) {
            setEdittingSurveyQuestions(true);
            setEditQuestionId(index);
        } else {
            showError("Finish editing earlier question.");
        }
    };

    const validateFullForm = (task,design,surveyQuestions,showToast) => {
        var error = false;

        var form_errors = resetFormErrors();
        var firsterrorid = null;

        if (task == '') {

            form_errors = {
                ...form_errors,
                task: "A task description is required",
                error_class: "input_error"
            };
            error = true;
            firsterrorid = firsterrorid == null ? "task" : firsterrorid;

            // showError("You missed some key information. Please check again and fill out any missing fields.");

        }
        //if (!(previewUrl != null || testImage != null)) {
        else if (!(design)) {

            form_errors = {
                ...form_errors,
                image: "A design is required",
                error_class: "input_error"
            };
            error = true;
            firsterrorid = firsterrorid == null ? "design" : firsterrorid;
        }
        setFormErrors(form_errors);

        if (firsterrorid != null) {
            if(showToast){
            if(firsterrorid === "task"){
                showError("Looks like you haven't added any task for this test! Please add task to move to the next steps.");
                localStorage.setItem("stepper_error", "Looks like you haven't added any task for this test! Please add task to move to the next steps.");
                document.getElementById(firsterrorid).scrollIntoView({
                behavior: "smooth",
                block: "center",
            });

            }else if(firsterrorid === "design"){
                showError("Looks like you haven't added design for this test! Please add design to move to the next steps.");
                localStorage.setItem("stepper_error", "Looks like you haven't added design for this test! Please add design to move to the next steps.");
                scrollto(firsterrorid);

            }else{
                showError("You missed some key information. Please check again and fill out any missing fields.");
                localStorage.setItem("stepper_error", "You missed some key information. Please check again and fill out any missing fields.");
                scrollto(firsterrorid);

            }
            }

            // document.getElementById(firsterrorid).scrollIntoView({
            //     behavior: "smooth",
            //     block: "center",
            // });
            // scrollto(firsterrorid);

        }
        else{
        let nonoptionalcount = 0;
        surveyQuestions.forEach((question) => {
            if (question.is_optional == 0) {
                nonoptionalcount = 1
            }
        }
        );
        if (nonoptionalcount == 0) {
            error = true;
            if (firsterrorid == null){
                if(surveyQuestions.length==0){

                    if(edittingSurveyQuestions){

                        var checkIfErrorExits = questionInputValidateRef.current && questionInputValidateRef.current.handleQuestionSubmit(true)

                        console.log(checkIfErrorExits)
                        if(!checkIfErrorExits){

                            handleSubmitTest(true);

                        }
                        return false;
                    }

                    form_errors = {
                        ...form_errors,
                        questions: "A follow up question is required",
                        error_class: "input_error"
                    };
                    showError("Looks like you haven't added questions for this test! Please add at least 1 question to move to the next steps.");
                    localStorage.setItem("stepper_error", "Looks like you haven't added questions for this test! Please add at least 1 question to move to the next steps.");
                    //showError("You missed some key information. Please check again and fill out any missing fields.");
                }
                else{
                    form_errors = {
                        ...form_errors,
                        questions: "At least one question should not be optional.",
                        error_class: "input_error"
                    };
                    showError("At least one question should not be optional.");
                    localStorage.setItem("stepper_error", "At least one question should not be optional.");
                    //showError("You missed some key information. Please check again and fill out any missing fields.");
                }
                setFormErrors(form_errors);
                // document.getElementById("questions") && document.getElementById("questions").scrollIntoView({
                //     behavior: "smooth",
                //     block: "center",
                // });
                scrollto("questions");
            }
        }
        }
        if(error){
            setStepperError(true);
        }

        return error;
    };

    const save5SecondsTest = (saveUsingNavigation) => {
        if (testController.current) {
            testController.current.abort();

        }
        const controller = new AbortController();

        testController.current = controller;

        var checkIntroductionQuestionIfErrorExits = true;
        if(introductionQuestionRef.current){


            checkIntroductionQuestionIfErrorExits = introductionQuestionRef.current.questionValidateForm(true);
        }

        // check if any error exists in introduction question
        if(!checkIntroductionQuestionIfErrorExits && checkIntroductionQuestionIfErrorExits != undefined ){
            console.log('error exits intro')

            return false;
        }



        setFormErrors({ task: null, task_image: null, "error_class": null });

        var error = validateFullForm(formValues.task,formValues.design,surveyQuestions,true);

        if(error){
            return  false;
        }

        // check if any question has enable editing then show error
        if(edittingSurveyQuestions){

            var checkIfErrorExits =  questionInputValidateRef.current.handleQuestionSubmit(true)

             if(!checkIfErrorExits){

                 handleSubmitTest(saveUsingNavigation);

             }
            return false;
        }else{

            handleSubmitTest(saveUsingNavigation);

            // var checkErrors  = checkIntroductionConclusionError();
            // console.log(checkErrors)
            // if(checkErrors){
            //     if (!saveUsingNavigation) {
            //         window.dataLayer = window.dataLayer || [];
            //         window.dataLayer.push({
            //             'event' : 'create_test_step2',
            //             'type': test.methodology,
            //             'userID' : ReactSession.get("user") ? ReactSession.get("user").user_id : ""
            //         });
            //         navigate("/r/test/" + test_id + "/thank-you/");
            //         setIsSaved({ 'status': 'success', 'message': '' });
            //     }
            // }
        }


    };

    const checkIntroductionConclusionError = ()=>{

        console.log('testing 1')
        var checkConclusionQuestionIfErrorExits = true;

        //

        if(conclusionQuestionRef.current){

            checkConclusionQuestionIfErrorExits = conclusionQuestionRef.current.questionValidateForm(true)
        }

        // check if any error exists in conclusion question
        if(!checkConclusionQuestionIfErrorExits && checkConclusionQuestionIfErrorExits != undefined){
            console.log('error exits conclusion')

            return false;
        }

    }

    const handleSubmitTest = (saveUsingNavigation)=>{

        var error = validateFullForm(formValues.task,formValues.design,surveyQuestions,true);




        if (!error) {

            var checkError  = checkIntroductionConclusionError();
            console.log(checkError)
            if(checkError || checkError === undefined) {



                let token = ReactSession.get("token");
                if (!saveUsingNavigation) {
                    setSurveyStepLoading(true);
                }

                setIsSaved({ 'status': 'loading', 'message': '' });

                var data = new FormData();

                data.append("test_id", test_id);
                /*if (image) {
                    data.append("task_file", image);
                }
                data.append("file_type", fileType);*/
                data.append("task", formValues.task);

                if (!saveUsingNavigation) {
                    data.append("saved_step", 2);
                }

                surveyQuestions.forEach((item) => {
                    data.append("survey_questions[]", JSON.stringify(item));
                });

                if (!saveUsingNavigation) {
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        'event' : 'create_test_step2',
                        'type': test.methodology,
                        'userID' : ReactSession.get("user") ? ReactSession.get("user").user_id : ""
                    });
                    navigate("/r/test/" + test_id + "/thank-you/");
                    setIsSaved({ 'status': 'success', 'message': '' });
                }

            }

        }
    }



    const save5SecondsTestEveryChange = (saveUsingNavigation,taskName) => {

        if (testController.current) {
            testController.current.abort();

        }
        const controller = new AbortController();

        testController.current = controller;

        let token = ReactSession.get("token");
        if (!saveUsingNavigation) {
            setSurveyStepLoading(true);
        }

        setIsSaved({ 'status': 'loading', 'message': '' });
        var data = new FormData();

        data.append("test_id", test_id);
        // if (image) {
        //     data.append("task_file", image);
        // }
        // data.append("file_type", fileType);
        // set blank task name if clear input field
        if(taskName){
            data.append("task", "");
        }else{
            data.append("task", formValues.task);
        }


        if (!saveUsingNavigation) {
            data.append("saved_step", 2);
        }

        surveyQuestions.forEach((item) => {
            data.append("survey_questions[]", JSON.stringify(item));
        });

        saveFiveSecondsTestData(data, token, testController.current?.signal).then((response) => {
            setSurveyStepLoading(false);
            if (response.success) {

                getTestLength(test_id);
                setIsSaved({ 'status': 'success', 'message': '' });

                setLastSaved(moment.now());
                if (!saveUsingNavigation) {
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        'event' : 'create_test_step2',
                        'type': test.methodology,
                        'userID' : ReactSession.get("user") ? ReactSession.get("user").user_id : ""
                    });
                    navigate("/r/test/" + test_id + "/thank-you/");
                }
            } else {
                setIsSaved({ 'status': 'error', 'message': response.message });
            }
        });

    };


    const save5SecondsTestTask = (taskName) => {

        if (testController.current) {
            testController.current.abort();

        }
        const controller = new AbortController();

        testController.current = controller;

        let token = ReactSession.get("token");

        var data = new FormData();

        data.append("test_id", test_id);
        if(taskName){
            data.append("task", taskName);
        }else{
            data.append("task", formValues.task);
        }

        saveFiveSecondsTestTask(data, token, testController.current?.signal).then((response) => {
            setSurveyStepLoading(false);
            if (response.success) {

                // setIsSaved({ 'status': 'success', 'message': '' });

                // setLastSaved(moment.now());
                
            } else {
                // setIsSaved({ 'status': 'error', 'message': response.message });
            }
        });

    };

    const getTestApi = () => {
        let token = ReactSession.get("token");

        setLoading(true);

        getTestData({ test_id: test_id }, token).then((response) => {
            setLoading(false);

            if (response.success) {

                getTestLength(test_id);

                setTest(response.test);
                setLanguage(response.test.language);
                setCredits(response.settings.publishing_credits);
                setIntroCount(response.test.briefingQuestion.length);
                setConcCount(response.test.debriefingQuestion.length);
                setIntroductionQuestions(response.test.briefingQuestion);
                setConclusionQuestions(response.test.debriefingQuestion);
                if (response.test.fiveSecondsTestTask) {
                    setFormValues({ ...formValues, design: response.test.fiveSecondsTestDesign, task: response.test.fiveSecondsTestTask.task });
                    setTestImage(response.test.fiveSecondsTestTask.task_file);
                    setFileType(response.test.fiveSecondsTestTask.file_type);
                    setDesignTitle(response.test.fiveSecondsTestTask.design_title);
                    setBackgroundColor(response.test.fiveSecondsTestTask.background);
                }

                if (response.test.status) {
                    if (response.test.status == "published") {
                        navigate("/r/project/" + response.test.project_id);
                    } else {

                        if (
                            response.test.welcome_title == "" ||
                            response.test.welcome_description == ""
                        ) {
                            localStorage.setItem("stepper_error", "The welcome page cannot be empty! Please enter a title and a description.");
                            navigate("/r/test/" + test_id + "/welcome?error=true");
                            showError("The welcome page cannot be empty! Please enter a title and a description.");
                        }

                    }
                }


                setLastSaved(moment(response.test.updated_at));

                let questions = [];
                response.test.fiveSecondsTestQuestion.forEach((question) => {
                    questions.push(JSON.parse(question));
                });
                // if (questions.length < 1) {
                    setAddingSurveyQuestions(true);
                // }
                
                setSurveyQuestions(questions);
                if (searchParams.get("error") == "true") {
                validateFullForm(response.test.fiveSecondsTestTask.task,response.test.fiveSecondsTestDesign,questions,false);
                }
                // setFormValues({ tree: response.test.tree, tasks: response.test.tasks });
            } else {
                showError(response.message)

            }
        });
    };

    useEffect(() => {
        //document.title = process.env.REACT_APP_NAME + " - 5 second test";

        getTestApi();

        if (searchParams.get("error") == "true") {
            setStepperError(true);
        }


    }, []);


    useEffect(() => {
        if (nodeRef.current) {
            nodeRef.current.focus();
        }
        // const timer = setInterval(() => {
        //     if (test && test.status != "published" && !edittingSurveyQuestions) {
        //         save5SecondsTestEveryChange(true);
        //     }
        // }, 30000);
        //
        // return () => {
        //     clearTimeout(timer);
        // };
        if(initialSave.current!=true){
        save5SecondsTestEveryChange(true,false);
        }else{
            initialSave.current=false ;
        }
        resetFormErrors();
    }, [surveyQuestions, edittingSurveyQuestions]);

    const set = (name) => {
        return ({ target: { value } }) => {
            setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
        };
    };

    const setFormValue = (name, value) => {
        setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
    };
    const handleDragStart = (event) => {
        const draggedDOM = getDraggedDom(event.draggableId);

        if (!draggedDOM) {
            return;
        }

        const { clientHeight, clientWidth } = draggedDOM;
        const sourceIndex = event.source.index;
        var clientY =
            parseFloat(window.getComputedStyle(draggedDOM.parentNode.parentNode).paddingTop) +
            [...draggedDOM.parentNode.parentNode.children]
                .slice(0, sourceIndex)
                .reduce((total, curr) => {
                    const style = curr.currentStyle || window.getComputedStyle(curr);
                    const marginBottom = parseFloat(style.marginBottom);
                    const marginTop = parseFloat(style.marginTop);
                    return total + curr.clientHeight + marginTop;
                }, 0);

        setPlaceholderProps({
            clientHeight,
            clientWidth,
            clientY,
            clientX: parseFloat(
                window.getComputedStyle(draggedDOM.parentNode).paddingLeft
            ),
            background:'#CCCCCC',
             border:'none'
        });
    };

    const handleDragUpdate = (event) => {
        if (!event.destination) {
            return;
        }

        const draggedDOM = getDraggedDom(event.draggableId);

        if (!draggedDOM) {
            return;
        }

        const { clientHeight, clientWidth } = draggedDOM;
        const destinationIndex = event.destination.index;

        const sourceIndex = event.source.index;

        const childrenArray = [...draggedDOM.parentNode.parentNode.children];
        const movedItem = childrenArray[sourceIndex];
        childrenArray.splice(sourceIndex, 1);

        const updatedArray = [
            ...childrenArray.slice(0, destinationIndex),
            movedItem,
            ...childrenArray.slice(destinationIndex + 1),
        ];


        var clientY =
            parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
            updatedArray.slice(0, destinationIndex).reduce((total, curr) => {
                const style = curr.currentStyle || window.getComputedStyle(curr);
                const marginBottom = parseFloat(style.marginBottom);
                const marginTop = parseFloat(style.marginTop);
                return total + curr.clientHeight + marginTop;
            }, 0);

        setPlaceholderProps({
            clientHeight,
            clientWidth,
            clientY,
            clientX: parseFloat(
                window.getComputedStyle(draggedDOM.parentNode).paddingLeft
            ),
            background:'none',
             border:'1px dashed'
        });
    };

    const getDraggedDom = (draggableId) => {
        const domQuery = `[${queryAttr}='${draggableId}']`;
        const draggedDOM = document.querySelector(domQuery);

        return draggedDOM;
    };

    return (
        <LayoutResearcher wrapClass={`create-test-data-wrapper ${test && test.language == "ar" ? "arabic_wrapper_main" : ""}`}
            openSupportPanel={show_support_panel} hideSupportPanel={()=>{setShowSupportPanel(false)}}
        >
            <Helmet>
                <title>Create 5 second test - step 2 | UserQ</title>
            </Helmet>
            <div className="three-column-layout">
                <Header
                    test={test}
                    isLoading={isLoading}
                    isSaved={isSaved}
                    credits={credits}
                />
                <div className="three-column-layout-body">
                    <div className={`three-column-layout-left ${addingSurveyQuestions && questionType != ""?"disable-stepper":""}`}>
                        <Stepper
                            test={test}
                            completed_step={1}
                            stepCallback={() => {
                                // introductionsubmitHandler(true);
                            }}
                            error={stepperError}
                        />
                    </div>
                    {isLoading &&
                        <div className="test-section-loader"> <LoadingIcon /></div>
                    }
                    {!isLoading && (
                        <>
                            <div className="three-column-layout-center">
                                <div className="card-sorting-creating-test-wrap create_five_second_test_wrap">
                                    <div className='section-minimum-height'>
                                        <Accordian
                                            title={"Introduction questions"}
                                            subtitle={"(Optional)"}
                                            tooltip={"Some cool text"}
                                            open={accordians['introduction'] ? true : false}
                                            onChange={() => {
                                                let new_accordians = Object.assign({}, accordians);

                                                for (var key in accordians) {
                                                    if (key == "introduction") {
                                                        if (accordians[key]) {
                                                            new_accordians[key] = false;
                                                        } else {
                                                            new_accordians[key] = true;
                                                        }
                                                    }
                                                    // else {
                                                    //     new_accordians[key] = false;
                                                    // }

                                                }
                                                setAccordians(new_accordians);
                                            }}
                                            questionCount={introCount}
                                        >
                                            <IntroductionQuestions ref={introductionQuestionRef} questions={introductionQuestions} test={test} setLoading={(status) => setIsSaved(status)} changeCount={(newCount)=>{setIntroCount(newCount)}} updateQuestions={(questions)=>{setIntroductionQuestions([...questions])}}/>
                                        </Accordian>

                                        <Accordian
                                            title={"5 second test"}
                                            tooltip={"Some cool text"}
                                            open={accordians['fivesecondtest'] ? true : false}
                                            onChange={() => {
                                                let new_accordians = Object.assign({}, accordians);

                                                for (var key in accordians) {
                                                    if (key == "fivesecondtest") {
                                                        if (accordians[key]) {
                                                            new_accordians[key] = false;
                                                        } else {
                                                            new_accordians[key] = true;
                                                        }
                                                    }
                                                    // else {
                                                    //     new_accordians[key] = false;
                                                    // }

                                                }
                                                setAccordians(new_accordians);
                                            }}
                                        >
                                            <div className="five-second-mid-data-wrap">

                                                <div className="card-sorting-test-wrap">
                                                    <div className='cardsorting-wrap-top'>
                                                        <Text type={'subtitle-2'} fontWeight={'medium-font'} >
                                                            Task
                                                        </Text>
                                                        <Text
                                                            type={'body-text-2'}
                                                            fontWeight={'medium-font'}
                                                        >
                                                            Describe the task for your 5 second test. This will be shown to the participant prior to introducing the design.




                                                        </Text>

                                                    </div>
                                                    <div className='cardsorting-wrap-mid-data '>
                                                        <div className='cat-add-data-wrap'>
                                                            <Input
                                                                required={true}
                                                                label="Task description"
                                                                onChange={setTask("task")}
                                                                onClear={() => {
                                                                    setFormValues((oldValues) => ({
                                                                        ...oldValues,
                                                                        task: "",
                                                                    }));
                                                                    save5SecondsTestEveryChange(true,true);
                                                                    // setTimeout(() => {
                                                                    //     setFormValues((oldValues) => ({
                                                                    //         ...oldValues,
                                                                    //         task: "",
                                                                    //     }));
                                                                    // }, 1000);
                                                                }}
                                                                onInput={(e) => {
                                                                    maxLengthCheck(e);
                                                                    if (e.target.value.length > 0) {
                                                                        setFormErrors({ ...formErrors, title: null });
                                                                    }
                                                                }}
                                                                onBlur={(e) => {
                                                                    maxLengthCheck(e);
                                                                    // save5SecondsTestEveryChange(true,false);
                                                                }}
                                                                value={formValues.task}
                                                                maxLength="150"
                                                                error={formErrors.task}
                                                                error_class={formErrors.error_class}
                                                                id="task"
                                                                rtl={test.language == "ar" ? true : false}
                                                            />
                                                        </div>
                                                    </div>



                                                    <div className="create-test-preview-area">
                                                        <Text
                                                            type={'overline'}
                                                            children={'Preview'}
                                                        />
                                                        <div className="create-test-preview-area-inner">
                                                            <img src={
                                                                process.env.REACT_APP_URL + "img/five-second-preview.svg"}
                                                                alt="img" />
                                                        </div> 
                                                        <GuideLink methodology={"5 second test"}/>
                                                    </div>

                                                </div>

                                                <div className="card-sorting-test-wrap five-second-updesign">
                                                    <div className='cardsorting-wrap-top' id="design">
                                                        <Text type={'subtitle-2'} fontWeight={'medium-font'} >
                                                            Upload your design
                                                        </Text>
                                                        <Text
                                                            type={'body-text-2'}
                                                            fontWeight={'medium-font'}
                                                        >
                                                             The mockup, image or design you choose will be shown to your testers for 5 seconds only.
                                                        </Text>

                                                        <Text
                                                            type={'body-text-2'}
                                                            fontWeight={'medium-font'}
                                                        >
                                                            We always suggest to keep the default 5 seconds duration as it is proven to be long enough for a good design to communicate its primary message and understand what the users remember about it.
                                                        </Text> 

                                                    </div>

                                                   



                                                    <div className='cardsorting-wrap-mid-data '>
                                                        <div
                                                            className="five-second-test-img"
                                                            
                                                        >

                                                            {(!formValues.design) ?
                                                                <ImageUpload design={null} index={1} test={test} onDesignAdded={(design) => {
                                                                    setFormValues({ ...formValues, design: design })

                                                                    setFormErrors({
                                                                        ...setFormErrors,
                                                                        image: null,
                                                                        error_class: "input_error"
                                                                    });

                                                                    setStepperError(false);

                                                                }}
                                                                    isPreview={false}
                                                                    updateHeaderLoader={(isSaved) => {
                                                                        setIsSaved(isSaved);
                                                                    }}
                                                                    taskErrors={formErrors}
                                                                    setImageError={(error)=>{
                                                                        setFormErrors({
                                                                        ...setFormErrors,
                                                                        image: error,
                                                                        error_class: "input_error"
                                                                    });
                                                                    }}
                                                                />
                                                                :
                                                                <ImageUpload onDesignUpdated={(design, index) => {
                                                                    setFormValues({ ...formValues, design: design });
                                                                }} index={1}
                                                                    design={formValues.design} test={test}
                                                                    setEditDesignModal={setEditDesignModal}
                                                                    setConfirmModal={setConfirmModal}
                                                                    updateHeaderLoader={(isSaved) => {
                                                                        setIsSaved(isSaved);
                                                                    }}
                                                                    isPreview={true}
                                                                    taskErrors={formErrors}
                                                                    setImageError={(error)=>{
                                                                        setFormErrors({
                                                                        ...setFormErrors,
                                                                        image: error,
                                                                        error_class: "input_error"
                                                                    });
                                                                    }}
                                                                />
                                                            }

                                                            {/* {formErrors.image != null && (
                                                                <span className={formErrors.error_class}>
                                                                    {formErrors.image}
                                                                </span>
                                                            )} */}

                                                        </div>
                                                        <span className={"input_error followup-question-error"} >
                                                            {formErrors.image}
                                                        </span>
                                                    </div>






                                                </div>

                                                <div className="card-sorting-test-wrap five-second-follw-up-questions"> 
                                                    <div className='cardsorting-wrap-top' id="questions">
                                                        <Text type={'subtitle-2'}  fontWeight={'medium-font'} >
                                                            Follow up questions 
                                                        </Text>
                                                        <Text
                                                            type={'body-text-2'}
                                                            fontWeight={'medium-font'}
                                                        >
                                                            Add up to 3 questions you want to ask the participant regarding your design. We suggest adding open-ended questions to understand what they can recall or what grabbed their attention.
                                                        </Text>
                                                    </div>


                                                    <div className="cardsorting-wrap-mid-data">



                                                        {surveyQuestions.length === 0 ? (
                                                            <div className="nodata-wrap"></div>
                                                        ) : (
                                                            <div className="added-question-wrap mb-32"
                                                                style={{ position: 'relative' }}>
                                                                <DragDropContext
                                                                    onDragEnd={(param) => {
                                                                        setPlaceholderProps({});
                                                                        if (edittingSurveyQuestions) {
                                                                            showError("Finish editing your question first.");
                                                                        } else {
                                                                            const srcI = param.source.index;
                                                                            const desI = param.destination.index;
                                                                            surveyQuestions.splice(
                                                                                desI,
                                                                                0,
                                                                                surveyQuestions.splice(srcI, 1)[0]
                                                                            );
                                                                            // updateLogic(srcI,desI);
                                                                            save5SecondsTestEveryChange(true,false);
                                                                        }
                                                                    }}
                                                                    onDragStart={handleDragStart}
                                                                    onDragUpdate={handleDragUpdate}
                                                                >
                                                                    <Droppable droppableId="droppable-1" isCombineEnabled>
                                                                        {(provided, snapshot) => (
                                                                            <div
                                                                                ref={provided.innerRef}
                                                                                {...provided.droppableProps}
                                                                            >
                                                                                {surveyQuestions.map((question, index) => (
                                                                                    <div
                                                                                        key={index}
                                                                                        className="survey-added-question-repeat"
                                                                                    >
                                                                                        <Draggable
                                                                                            draggableId={"draggable-" + index}
                                                                                            index={index}
                                                                                        >
                                                                                            {(provided, snapshot) => (
                                                                                                <div
                                                                                                    ref={provided.innerRef}
                                                                                                    {...provided.draggableProps}
                                                                                                    {...provided.dragHandleProps}
                                                                                                    className={
                                                                                                        snapshot.isDragging
                                                                                                            ? "question-answers-dragging draggable-element-start"
                                                                                                            : ""
                                                                                                    }
                                                                                                >
                                                                                                    {editQuestionId === index ? (
                                                                                                        <></>
                                                                                                    ) : (
                                                                                                        <div
                                                                                                            key={index}
                                                                                                            id={'question-'+index}
                                                                                                            className={`survey-question-preview ${snapshot.isDragging?'draggable-inner-element-start':'grey-bg'}`}
                                                                                                        >
                                                                                                            <div
                                                                                                                className={`question-answer-holder ${(test.language == "en") ? "" : "arabic_wrapper"}`}>
                                                                                                                <div {...provided.dragHandleProps}>
                                                                                                                    <Question
                                                                                                                        duplicateProgress={duplicateProgress}
                                                                                                                        index={index}
                                                                                                                        question={question}
                                                                                                                        onCopy={() => duplicatequestion(index)}
                                                                                                                        onEdit={() => edittingQuestionsHandler(index)}
                                                                                                                        onDelete={() => removequestion(index)}
                                                                                                                        allQuestions={surveyQuestions}
                                                                                                                        saveJumpTo={(optionindex, value) => saveOptionJumpToHandler(optionindex, value, index)}
                                                                                                                        onToggleLogic={(logicstatus, indexQ) => toggleLogicHandler(logicstatus, indexQ)}
                                                                                                                        type={""}
                                                                                                                        language={test.language}
                                                                                                                    />
                                                                                                                </div>
                                                                                                                <div id={"ques-"+index}></div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    )}
                                                                                                </div>
                                                                                            )}
                                                                                        </Draggable>
                                                                                        <div
                                                                                            className={`question-edit ${test.language == "ar" ? "arabic_wrapper" : ""}`}>
                                                                                            {edittingSurveyQuestions &&
                                                                                                editQuestionId === index && (
                                                                                                    <EditQuestionInput
                                                                                                        questionSection={"five_secs_test"}
                                                                                                        questionDetails={
                                                                                                            surveyQuestions[index]
                                                                                                        }
                                                                                                        cancelEditQuestion={
                                                                                                            cancelEditQuestionHandler
                                                                                                        }
                                                                                                        editQuestions={(question) => {
                                                                                                            surveyQuestions.splice(
                                                                                                                index,
                                                                                                                1,
                                                                                                                question
                                                                                                            );
                                                                                                            setSurveyQuestions([
                                                                                                                ...surveyQuestions,
                                                                                                            ]);
                                                                                                            setStepperError(false);
                                                                                                        }}
                                                                                                        ref={questionInputValidateRef}
                                                                                                        language={language}
                                                                                                        other={
                                                                                                            test.language == "en" ?
                                                                                                                question.questionType == "free"
                                                                                                                    ? "Other"
                                                                                                                    : surveyQuestions[
                                                                                                                        index
                                                                                                                    ].options.slice(-1) == "Other"
                                                                                                                        ? true
                                                                                                                        : false : question.questionType == "free"
                                                                                                                    ? "آخر"
                                                                                                                    : surveyQuestions[
                                                                                                                        index
                                                                                                                    ].options.slice(-1) == "آخر"
                                                                                                                        ? true
                                                                                                                        : false
                                                                                                        }
                                                                                                        questionno={index + 1}
                                                                                                        questionlist={surveyQuestions}
                                                                                                        changeQuestionType={(questionType) => {
                                                                                                            surveyQuestions[index].questionType = questionType
                                                                                                        }}
                                                                                                        addLikertSubquestions={() => {
                                                                                                            surveyQuestions[index].subQuestions = [""]
                                                                                                        }}
                                                                                                        // islogic={true}
                                                                                                        onDelete={()=>removequestion(index) }
                                                                                                    />
                                                                                                )}
                                                                                        </div>
                                                                                    </div>
                                                                                ))}
                                                                                {provided.placeholder}
                                                                                {!isEmpty(placeholderProps) && (
                                                                                    <div
                                                                                        className="placeholder"
                                                                                        style={{
                                                                                            top: placeholderProps.clientY,
                                                                                            left: placeholderProps.clientX,
                                                                                            height: placeholderProps.clientHeight,
                                                                                            width: placeholderProps.clientWidth,
                                                                                            position: "absolute",
                                                                                            borderColor: "#000000",
                                                                                            background:placeholderProps.background,
                                                                                            borderRadius:'10px',
                                                                                            border:placeholderProps.border,
                                                                                            margin: '-15px 0px 10px'
                                                                                        }}
                                                                                    />
                                                                                )}

                                                                            </div>
                                                                        )}
                                                                    </Droppable>
                                                                </DragDropContext>
                                                            </div>
                                                        )}
                                                        {!edittingSurveyQuestions &&
                                                            (!addingSurveyQuestions ? (
                                                                <Button
                                                                    iconLeft={<Icon value="add" />}
                                                                    label="Add question"
                                                                    size={"medium"}
                                                                    type={'ghost'}
                                                                    onClick={() => {
                                                                        if (surveyQuestions.length < 5000) {
                                                                            setAddingSurveyQuestions(true);
                                                                        } else {
                                                                            showError("You've hit the maximum amount of test questions! If you need to add more, consider removing earlier ones.")

                                                                        }
                                                                    }}
                                                                />

                                                            ) : (
                                                                <></>
                                                            ))}
                                                        {(addingSurveyQuestions && questionType === "") ? (
                                                            <div
                                                                className={`ans-list-wrap question-prev-wrap  survey-test-option-wrap mb-0 `}>
                                                                {/* <h3 className="mt-32 mb-0 d-flex justify-content-between">
                                                                    Question {surveyQuestions.length + 1}
                                                                    {surveyQuestions.length > 0 && (
                                                                        <span className="close-question-options"
                                                                            onClick={() => {
                                                                                setAddingSurveyQuestions(false)
                                                                            }}>
                                                                            <img alt="close-icon"
                                                                                src={process.env.REACT_APP_URL + "images/cross.svg"} />
                                                                        </span>
                                                                    )}
                                                                </h3> */}
                                                                {surveyQuestions.length<3 && <>
                                                                {surveyQuestions.length>0 &&
																<div className="add-more-question-heading"><Text type="body-text-2" fontWeight="medium">Add more question(s)</Text></div>}
                                                                <div className={"add-more-question-option"}>
                                                                    <div className="stud-tiles-hold">
                                                                        <QuestionCard
                                                                            questiontype="Free Text"
                                                                            onClick={() => {
                                                                                setQuestionType("free");
                                                                                setEdittingSurveyQuestions(true);
                                                                            }}
                                                                        />
                                                                        <QuestionCard
                                                                            questiontype="Single choice"
                                                                            onClick={() => {
                                                                                setQuestionType("singlechoice");
                                                                                setEdittingSurveyQuestions(true);
                                                                            }}
                                                                        />
                                                                        <QuestionCard
                                                                            questiontype="Multiple-choice"
                                                                            onClick={() => {
                                                                                setQuestionType("multiplechoice");
                                                                                setEdittingSurveyQuestions(true);
                                                                            }}
                                                                        />
                                                                        <QuestionCard
                                                                            questiontype="Likert scale"
                                                                            onClick={() => {
                                                                                setQuestionType("likertscale");
                                                                                setEdittingSurveyQuestions(true);
                                                                            }}
                                                                        />
                                                                        <QuestionCard
                            											    questiontype="Rating scale"
                            											    onClick={() => {
                            											        setQuestionType("ratingscale");
                                                                                setEdittingSurveyQuestions(true);
                            											    }}
                            											/>
                                                                        <QuestionCard
                                                                            questiontype="Ranking scale"
                                                                            onClick={() => {
                                                                                setQuestionType("rankingscale");
                                                                                setEdittingSurveyQuestions(true);
                                                                            }}
                                                                        />
                                                                    </div>

                                                                </div>
                                                                </>}

                                                                

                                                            </div>
                                                        ) : (
                                                            <div
                                                                className={`${(test.language == "en") ? "" : "arabic_wrapper"}`}>
                                                                <QuestionInput
                                                                    questionType={questionType}
                                                                    cancelQuestion={cancelQuestionHandler}
                                                                    ref={questionInputValidateRef}
                                                                    addQuestions={(question) => {
                                                                        setSurveyQuestions([...surveyQuestions, question]);
                                                                        setAddingSurveyQuestions(true);
                                                                        setQuestionType("");
                                                                        setStepperError(false);
                                                                        setEdittingSurveyQuestions(false);
                                                                    }}
                                                                    language={language}
                                                                    questionno={surveyQuestions.length + 1}
                                                                    questionlist={surveyQuestions}
                                                                    changeQuestionType={(questionType) => {
                                                                        setQuestionType(questionType)
                                                                    }}
                                                                    // islogic={true}
                                                                />
                                                            </div>
                                                        )}
                                                    {formErrors.questions != null && (
                                                                <span className={formErrors.error_class+" followup-question-error"}>
                                                                    {formErrors.questions}
                                                                </span>
                                                    )}
                                                    </div>

                                                    
                                                    <div className="create-test-preview-area">
                                                        <Text
                                                            type={'overline'}
                                                            children={'Preview'}
                                                        />
                                                        <div
                                                            className="create-test-preview-area-inner questionpreviewinner">
                                                            choose a briefing question <br /> to preview
                                                        </div>
                                                        <GuideLink methodology={"5 second test"}/>
                                                    </div>

                                                </div>

                                                
                                            </div>
                                        </Accordian>
                                        <Accordian
												title={"Conclusion questions"}
												subtitle={"(Optional)"}
												tooltip={"Some cool text"}
												open={accordians['conclusion'] ? true : false}
												onChange={() => {
													let new_accordians = Object.assign({}, accordians);

													for (var key in accordians) {
														if (key == "conclusion") {
															if (accordians[key]) {
																new_accordians[key] = false;
															} else {
																new_accordians[key] = true;
															}
														}

													}
													setAccordians(new_accordians);
												}}
                                                questionCount={concCount}
											>
												<ConclusionQuestions ref={conclusionQuestionRef} questions={conclusionQuestions} test={test} setLoading={(status)=>setIsSaved(status)} changeCount={(newCount)=>{setConcCount(newCount)}} updateQuestions={(questions)=>{setConclusionQuestions([...questions])}}/>
											</Accordian>
                                    </div>


                                    <div class="btns-wrap-bottom">
                                        <Button
                                            onClick={() => { navigate("/r/test/" + test_id + "/welcome"); }}
                                            type={"ghost"}
                                            label={"Previous"}
                                            size={"large"}
                                            iconLeft={
                                                <Icon value="back-arrow" colorClass="gray-900-svg" size={"large"} />
                                            }
                                        />
                                        {surveyQuestions.length>0 ? (
                                            <Button
                                                onClick={() => save5SecondsTest(false)}
                                                iconRight={<Icon value="forward-arrow" colorClass="gray-50-svg" />}
                                                label="Next"
                                                size={"large"}
                                            />
                                        ) : (
                                            <Button
                                                onClick={() => save5SecondsTest(false)}
                                                iconRight={<Icon value="forward-arrow" colorClass="gray-50-svg" />}
                                                label="Next"
                                                size={"large"}
                                                state={"disabled"}
                                            />
                                        )}
                                    </div>
                                </div>

                            </div>
                            <div className="three-column-layout-right">
                            <a  onClick={(e)=>{
                                    e.preventDefault();

                                    setShowSupportPanel(true);

                                }}
                                href={process.env.REACT_APP_URL+"r/support"} target="_blank" className='support-button'><svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.879 8.2817C11.05 7.2567 12.95 7.2567 14.121 8.2817C15.293 9.3067 15.293 10.9687 14.121 11.9937C13.918 12.1727 13.691 12.3197 13.451 12.4357C12.706 12.7967 12.001 13.4347 12.001 14.2627V15.0127M21 12.7627C21 13.9446 20.7672 15.1149 20.3149 16.2068C19.8626 17.2988 19.1997 18.2909 18.364 19.1267C17.5282 19.9624 16.5361 20.6253 15.4442 21.0776C14.3522 21.5299 13.1819 21.7627 12 21.7627C10.8181 21.7627 9.64778 21.5299 8.55585 21.0776C7.46392 20.6253 6.47177 19.9624 5.63604 19.1267C4.80031 18.2909 4.13738 17.2988 3.68508 16.2068C3.23279 15.1149 3 13.9446 3 12.7627C3 10.3757 3.94821 8.08656 5.63604 6.39873C7.32387 4.71091 9.61305 3.7627 12 3.7627C14.3869 3.7627 16.6761 4.71091 18.364 6.39873C20.0518 8.08656 21 10.3757 21 12.7627ZM12 18.0127H12.008V18.0207H12V18.0127Z" stroke="#9759CB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                        Support
                                    </a>
                            </div>
                        </>
                    )}
                </div>
            </div>

            <ConfirmationModal
                confirm_message="Are you sure you want to delete this design?"
                confirm_btn_title="Delete"
                confirm_title="Delete Design"
                confirm_title_class={"text-danger"}
                openModal={confirmModal.open}
                close={() => {
                    setConfirmModal({ ...confirmModal, open: false });
                }}
                confirm={() => {
                    setConfirmModal({ ...confirmModal, open: false });

                    removeImage();
                }}
            />
            <FiveSecondsTestDesignModal
                test={test}
                open={addDesignModal.open}
                close={() => {
                    setAddDesignModal({ ...addDesignModal, open: false });
                }}
                confirm={(preview, view, typeOfFile, title, color) => {
                    setAddDesignModal({ ...addDesignModal, open: false });
                    setPreviewUrl(preview);
                    setViewUrl(view);
                    setFileType(typeOfFile);
                    setBackgroundColor(color);
                    setDesignTitle(title);
                    setFormErrors({ ...formErrors, image: null, "error_class": null });

                }}
            />
            <EditPreferenceTestDesignModal
                design={editDesignModal.design}
                designPreview={previewUrl ? previewUrl : process.env.REACT_APP_IMG_URL + testImage}
                typeOfFile={fileType}
                titleOfDesign={designTitle}
                colorOfBackground={backgroundColor}
                test={test}
                open={editDesignModal.open}
                close={() => {
                    setEditDesignModal({ ...editDesignModal, open: false });
                }}
                confirm={(preview, view, typeOfFile, title, color) => {
                    setEditDesignModal({ ...editDesignModal, open: false });
                    setPreviewUrl(preview);
                    if (view != null) {
                        setViewUrl(view);
                    }
                    setFileType(typeOfFile);
                    setBackgroundColor(color);
                    setDesignTitle(title);
                }}
            />
        </LayoutResearcher>
    );
}
