import React from 'react';
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ReactSession } from '../../../../lib/secure_reactsession';
import { useState, useEffect, useRef } from "react";
import { getProfile, researcherTesterSupportContact } from "../../../../services/user";
import { LoadingIcon } from '../../../loader/loadingIcon';
import { Text } from '../../../themes/userq/Text/Text';
import { DropdownComponent } from '../../../themes/userq/Dropdown/Dropdown';
import { TextArea } from '../../../themes/userq/TextArea/TextArea';
import { Button } from '../../../themes/userq/Button/Button';
import { showToast } from '../../../../lib/helpers';
import { settings as globalsettings } from "../../../../data/settings";
import { ToastMessage } from '../../../themes/userq/ToastMessage/ToastMessage';



export default function ContactUsForm({section,...props}) {

    const [formErrors, setFormErrors] = useState({ name: null, error_class: "input_error", email: null, phone_no: null, reason: null, message: null, terms:null });

    const [formValues, setFormValues] = useState({ "name": "", "email": "", "phone_number": "", "reason": '', 'message': '','terms':true })

    const [reasonLabelClass, setReasonLabelClass] = useState('inactive_label');

    const [isLoading, setLoading] = useState(false);
    const [profile_loaded, setProfileLoaded] = useState(false);

    const [formLoading, setFormLoading] = useState(false);

    let reasonSelect = useRef();
    const navigate = useNavigate();
    const toggleCheck = (name, value) => {
        setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
    };

    const [toastError, setToastError] = useState(null);
    const [toastSuccess, setToastSuccess] = useState(null);
    // const useQuery= () => {
    //     return new URLSearchParams(useLocation().search);
    // }

    const [searchParams] = useSearchParams()

    const reasonOptions = [
        { "label": "Suggest an improvement", "value": "Suggest an improvement" },
        { "label": "Help with recruitment", "value": "Help with recruitment" },
        { "label": "Problem with the platform", "value": "Problem with the platform" },
        { "label": "Other", "value": "Other" }
    ];
    var timeout_error;

    var timeout_success;

    const ReasonChange = (item) => {
        setFormValues({ ...formValues, "reason": item.value });
        setFormErrors((oldValues) => ({ ...oldValues, reason: null }));
        setReasonLabelClass('active_label')
        window.setLabels()
    }
    const showError = (error) => {
       
        setToastError(error);

        if(timeout_error){
            clearTimeout(timeout_error);
        }
        timeout_error = setTimeout(() => {
            setToastError(null);
        }, globalsettings.toast_duration);
        
        
    };
    const showSuccess = (message) => {
       
        setToastSuccess(message);

        if(timeout_success){
            clearTimeout(timeout_success);
        }
       timeout_success = setTimeout(() => {
            setToastSuccess(null);
        }, globalsettings.toast_duration);
        
        setTimeout(()=>{
            document.getElementById("tags-success").scrollIntoView({
                behavior: "smooth",
                block: "center",
            });
        },50)
        
    };


    const getProfileResponse = () => {
        let token = ReactSession.get("token");

        setLoading(true);

        getProfile(token).then(response => {
            setLoading(false);


            if (response.success) {

                // setUserProfile(response.data.user)
                if (response.data.user) {

                    if (searchParams.get("reason")) {
                        let reasonParam = searchParams.get("reason");
                        setFormValues({
                            ...formValues, 'name': response.data.user.first_name + ' ' + response.data.user.last_name, 'email': response.data.user.email, "phone_number":
                                response.data.user.phone_number != null
                                    ? response.data.user.phone_number
                                    : "",
                            "dialcode_phone":
                                response.data.user.dialcode_phone == null
                                    ? "us"
                                    : response.data.user.dialcode_phone,
                            "country_phone": response.data.user.country_phone, 'reason': reasonParam
                        });

                        reasonSelect.current.selectSingleItem({ value: reasonParam });
                        setReasonLabelClass('active_label')
                        window.setLabels()
                    } else {
                        setFormValues({
                            ...formValues, 'name': response.data.user.first_name + ' ' + response.data.user.last_name, 'email': response.data.user.email, "phone_number":
                                response.data.user.phone_number != null
                                    ? response.data.user.phone_number
                                    : "",
                            "dialcode_phone":
                                response.data.user.dialcode_phone == null
                                    ? "us"
                                    : response.data.user.dialcode_phone,
                            "country_phone": response.data.user.country_phone
                        });

                    }


                }
                setProfileLoaded(true);

            }
        });
    }



    useEffect(() => {
        
        getProfileResponse();


    }, []);


    const resetFormErrors = (name, value) => {
        let formErrorsLocal = Object.assign({}, formErrors);

        for (var key in formErrors) {
            formErrorsLocal[key] = null;
        }
        setFormErrors(formErrorsLocal);

        return formErrorsLocal;
    };

    const handleSubmit = () => {

        var error = false;
        var form_errors = resetFormErrors();
        if (formValues.reason == "") {
            form_errors = {
                ...form_errors,
                reason: "Required field",
                error_class: "input_error",
            };

            error = true;
        }

        if (formValues.message == "") {
            form_errors = {
                ...form_errors,
                message: "Required field",
                error_class: "input_error",
            };

            error = true;
        }
        if (formValues.terms == false) {
            form_errors = {
                ...form_errors,
                terms: "Required field",
                error_class: "input_error",
            };

            error = true;
        }

        setFormErrors(form_errors);

        if (!error) {
            setFormLoading(true);

            const token = ReactSession.get("token");

            var formData = { ...formValues };
            researcherTesterSupportContact(formData, token).then((response) => {
                setFormLoading(false);

                if (response.success) {
                    setFormValues({ ...formValues, 'message': '','reason':'', terms:true });
                    if(section){    
                        showSuccess(response.message);
                    }   else {
                        showToast(response.message,"success");
                    }
                }
                else{
                    if(section){
                        showError(response.message);
                    }   else {
                        showToast(response.message,"error");
                    }
                    
                }
            });
        }

    }
    const validateBtnState = () => {

        var error = false;

        if (formValues.reason == '' || formValues.reason == null) {
            error = true;
        }
        else if (formValues.message==null || formValues.message == '') {

            error = true;
        }        
        if (!formValues.terms) {

            error = true;
        }
        return error;
    }





    return (
        <>
            {isLoading &&
            <div class="accountloading-box"> <LoadingIcon /></div>
            }
            {!isLoading &&
            <div className='contact-section researcher-contact-section'>
                <Text type="body-text-2" fontWeight="medium-font">Use the contact form below to get in touch with our support team. We will review and respond to your inquiry within 2 working days by sending a reply to your registered email address: <b>{formValues.email}</b>. </Text>
                <DropdownComponent
                    name="reason"
                    label="Reason of enquiry"
                    onChange={ReasonChange}
                    options={reasonOptions}
                    value={formValues.reason}
                    isClearable={false}
                    error={formErrors.reason}
                />
                <TextArea
                    autosize={true}
                    value={formValues.message}
                    onClear={() => {
                        setTimeout(() => {
                            setFormValues((oldValues) => ({
                                ...oldValues,
                                message: "",
                            }));
                        }, 1000);
                    }}
                    error={formErrors.message}
                    error_class={formErrors.error_class}
                    onChange={(e) => {
                        setFormValues((oldValues) => ({
                            ...oldValues,
                            "message": e.target.value
                        }))
                        setFormErrors({ ...setFormErrors, "message": '' });

                    }}
                    maxLength={350}
                    type="text"
                    label={"Your message"}
                    wrapClass="tester-text-area"
                />
                {/* <CheckBox checked={formValues.terms}
                    onChange={(event) => { toggleCheck('terms', event.currentTarget.checked) }}
                    id="termsCheckBox"
                    label={
                        <div className='terms-checkbox-label'>
                            I agree to the{" "}
                            <Link url={process.env.REACT_APP_SITE_URL +"terms-of-use"} target="_blank">Terms of Use</Link>
                            {" "}
                            and have read the{" "}
                            <Link url={process.env.REACT_APP_SITE_URL +"privacy-policy"} target="_blank">Privacy Policy</Link>
                        </div>
                    }
                /> */}
                <Button
                    label={"Submit"}
                    state={!validateBtnState()?"enabled":"disabled"}
                    microLoading={formLoading}
                    type={"primary"}
                    size={"large"}
                    onClick={()=>handleSubmit()}
                />
                {toastError &&
                <div className='mt-32 toast-error-sidebar'>
                    <ToastMessage type={"error"} message={toastError} closable={false} onClose={() => { setToastError(null); }} />
                </div>
                }
                <div id="tags-success">
                {toastSuccess &&
                <div className='mt-32 toast-success-sidebar'>
                    <ToastMessage type={"success"} message={toastSuccess} closable={false} onClose={() => { setToastSuccess(null); }} />
                </div>
                }
                </div>
            </div>
            }
        </>
    )
}