import React, { useEffect, useState } from "react";
import Layout from "../layouts/layout";
import { useNavigate } from "react-router-dom";
//import { ReactSession } from "react-client-session";
import { ReactSession } from "../../lib/secure_reactsession";
import { getProfile } from "../../services/user";
import {getTesterCreditSummaryService} from "../../services/credits";
import {researcherEarnInviteInvitation} from "../../services/user";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {FullPageLoadingIcon} from "../loader/full_page_loader_lcon";
import {roundNumber, showToast} from "../../lib/helpers";
import {LayoutResearcher} from "../themes/userq/Layouts/layout_researcher";
import {Text} from "../themes/userq/Text/Text";
import {Icon} from "../themes/userq/Icon/Icon";
import {Input} from "../themes/userq/Input/Input";
import {Button} from "../themes/userq/Button/Button";
import {ShareLink} from "../themes/userq/ShareLink/ShareLink";
import {
    FacebookIcon,
    FacebookShareButton,
    TwitterIcon,
    TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton
} from "react-share";
import {LoadingIcon} from "../themes/userq/Loader/loadingIcon";

export default function EarnAndInvite() {
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [btnLoading, setBtnLoading] = useState(false);
    const [linkCopied, setLinkCopied] = useState(false);

    const [emailValues, setEmailValues] = useState({subject:'',body:''});

    const [formValue, setFormValue] = useState({email:'',error:'',"error_class": ""});

    const [user, setUser] = useState(null);
    const [credits, setCredits] = useState(null);
    const [show_support_panel, setShowSupportPanel] = useState(false);

    const getProfileData = () => {
        let token = ReactSession.get("token");

        setIsLoading(true);

        getProfile(token).then((response) => {
            setIsLoading(false);
            if (response.success) {

                if(response.data.user.role !== 'Researcher'){
                    navigate('/404');
                }
                setUser(response.data.user);

                // set email data
                setEmailValues({
                    "subject":"UserQ - Join MENA's First UI/UX Research Platform",
                    "body":

                        "Hey, %0D%0A %0D%0AWant to make some extra cash?" +
                        " Become a researcher with UserQ and get paid to help brands" +
                        " in the MENA's region shape their websites, apps and other digital products. %0D%0A %0D%0A"+
                        process.env.REACT_APP_URL + "researcher/sign-up?refID=" + response.data.user.referral_code+
                        "\n \nRegards,%0D%0A" + response.data.user.first_name+ " " +response.data.user.last_name

                });
            }
        });
    };

    const fetchCreditsSummary = () => {
        setIsLoading(true);
        if(!credits){
            let token = ReactSession.get("token");

            getTesterCreditSummaryService(token).then((response) => {
                setIsLoading(false);
                if (response.success) {
                    setCredits(response.summary.credits_balance);
                }else {
                    showToast(response.message,"error");
                }
            });
        }
    };

    const sendInvitationRequest = () => {
        setBtnLoading(true);
        if(!btnLoading){
            let token = ReactSession.get("token");

            var formData = { ...formValue }

            researcherEarnInviteInvitation(formData,token).then((response) => {
                setBtnLoading(false);
                if (response.success) {
                    setFormValue({email:'',error:'',"error_class": ""});
                    showToast(response.message,"success");
                }else{
                    showToast(response.message,"error");
                }
            });
        }
    };


    useEffect(() => {
        document.title = "Invite & Earn | "+process.env.REACT_APP_NAME;
        getProfileData();

        fetchCreditsSummary(); // get total credits of testers

    }, []);

    return (
        <LayoutResearcher
            isLoading={false}
            skipCheck={false}
            activeMenu={"invite-and-earn"}
            openSupportPanel={show_support_panel} hideSupportPanel={()=>{setShowSupportPanel(false)}}
        >

            <div className="earn-page-wrapper">
                {isLoading &&
                <div className={'page-loader  accountloading-box earn-page-loader'}>
                    <LoadingIcon/>
                </div>
                }
                {!isLoading && user &&
                <div className="profile-earn-invite">

                    <div className="row align-items-center">
                        <div className="col-md-6 invite-earn-left">
                            <div className="invite-earn-data-text">
                                <Text type={'h1'}>
                                    Invite friends & earn{" "}
                                    {user &&
                                    roundNumber(user.config_credit_amount,2)
                                    }
                                    {" "}credits!
                                </Text>

                                <div className={'earn-invite-left-info-icons'}>
                                    <Icon value="earn-invite-profile" size="small" />
                                    <Icon value="earn-invite-share" size="small" />
                                    <Icon value="earn-invite-credit" size="small" />
                                </div>



                                <div className="earn-invite-info">
                                    <Text type={"body-text-1"} fontWeight={"medium-font"}>
                                        Invite researchers to register with UserQ.
                                        With their first purchase,
                                        you will receive {user && user.config_credit_amount} credits into your account to use on tests and recruitment.
                                        Visit our <a href="https://userq.com/terms-of-use/" target="_blank">Terms of use</a> for more info.
                                    </Text>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 invite-earn-right">
                            <div className="invite-earn-form-right">
                                <div className="invite-earn-balancebox">
                                    <Text type={'h2'}>Total earnings:</Text>
                                    <div className="earn-pricebox">
                                        {/*user.total_earning_referral_user*/}
                                        <Text type={"h1"}>
                                            {roundNumber(user.total_earning_referral_user,2)}
                                        </Text>
                                        <Text type={'body-text-2'} fontWeight={"medium-font"}>credits</Text>

                                    </div>
                                </div>

                                <div className="profile-info-personal-link">
                                    <Text type={'body-text-1'} fontWeight={"medium-font"}>Invite your friend</Text>

                                    <Input
                                        label="Email address"
                                        onChange={(e) => {
                                            //console.log(e.target.value)
                                            //if(e.target.value=='' || !e.target.value.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)){
                                            if(e.target.value==''){
                                               // setFormValue({email:e.target.value,error: 'Please enter a valid email', "error_class": "input_error"});
                                               setFormValue({email:e.target.value,error: '', "error_class": ""});

                                            }else{
                                                setFormValue({...formValue,email:e.target.value,error: '', "error_class": ""});
                                            }

                                        }}
                                        onBlur={(e)=>{
                                            if(e.target.value!=='' || !e.target.value.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)){
                                                // setFormValue({email:e.target.value,error: 'Please enter a valid email', "error_class": "input_error"});
                                                setFormValue({email:e.target.value,error: 'Please enter a valid email', "error_class": "input_error"});

                                            }else{
                                                setFormValue({...formValue,email:e.target.value,error: '', "error_class": ""});
                                            }
                                        }}
                                        error={formValue.error}
                                        error_class={formValue.error_class}
                                        required
                                        value={formValue.email}
                                        clearField={false}
                                    />

                                    <Button
                                        label="Send"
                                        type={"primary"}
                                        size={"large"}
                                        state={(formValue.email)?'active':'disabled'}
                                        microLoading={btnLoading}
                                        onClick={()=>{

                                            setFormValue({...formValue,error: '', "error_class": ""});
                                            if(formValue.email=='' || !formValue.email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)){
                                                setFormValue({...formValue,error: 'Please enter a valid email', "error_class": "input_error"});

                                            }else{
                                                sendInvitationRequest();
                                                //window.location = `mailto:?subject=${emailValues.subject}&body=${emailValues.body}&to=${formValue.email}`
                                            }

                                        }}
                                    />  


                                    <div className="personal-link-box">

                                        <Text type={'body-text-1'} fontWeight={"medium-font"}>Or share your personal link</Text>

                                        <ShareLink  link={process.env.REACT_APP_URL + "researcher/sign-up?refID=" + user.referral_code}/>

                                    </div>

                                    <div className={"share-social-links text-left"}>
                                        <FacebookShareButton
                                            url={process.env.REACT_APP_URL + "researcher/sign-up?refID=" + user.referral_code}
                                            quote={"Hi, click on my personal link to register to UserQ.com. UserQ is MENA's first pay-as-you-go remote user testing platform."}
                                            className="facebook__some-network__share-button"
                                        >
                                            <FacebookIcon size={32} round />
                                        </FacebookShareButton>

                                        <TwitterShareButton
                                            url={process.env.REACT_APP_URL + "researcher/sign-up?refID=" + user.referral_code}
                                            title={"Hi, click on my personal link to register to UserQ.com. UserQ is MENA's first pay-as-you-go remote user testing platform."}
                                            className="twitter__some-network__share-button"
                                        >
                                            <TwitterIcon size={32} round />
                                        </TwitterShareButton>

                                        <WhatsappShareButton
                                            url={process.env.REACT_APP_URL + "researcher/sign-up?refID=" + user.referral_code}
                                            title={"Hi, click on my personal link to register to UserQ.com. UserQ is MENA's first pay-as-you-go remote user testing platform."}
                                            //separator=":: "
                                            className="whatsapp__some-network__share-button"
                                        >
                                            <WhatsappIcon size={32} round />
                                        </WhatsappShareButton>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                }
            </div>
            <a onClick={(e)=>{
                                    e.preventDefault();

                                    setShowSupportPanel(true);

                                }}  href={process.env.REACT_APP_URL+"r/support"} target="_blank" className='support-button'><svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.879 8.2817C11.05 7.2567 12.95 7.2567 14.121 8.2817C15.293 9.3067 15.293 10.9687 14.121 11.9937C13.918 12.1727 13.691 12.3197 13.451 12.4357C12.706 12.7967 12.001 13.4347 12.001 14.2627V15.0127M21 12.7627C21 13.9446 20.7672 15.1149 20.3149 16.2068C19.8626 17.2988 19.1997 18.2909 18.364 19.1267C17.5282 19.9624 16.5361 20.6253 15.4442 21.0776C14.3522 21.5299 13.1819 21.7627 12 21.7627C10.8181 21.7627 9.64778 21.5299 8.55585 21.0776C7.46392 20.6253 6.47177 19.9624 5.63604 19.1267C4.80031 18.2909 4.13738 17.2988 3.68508 16.2068C3.23279 15.1149 3 13.9446 3 12.7627C3 10.3757 3.94821 8.08656 5.63604 6.39873C7.32387 4.71091 9.61305 3.7627 12 3.7627C14.3869 3.7627 16.6761 4.71091 18.364 6.39873C20.0518 8.08656 21 10.3757 21 12.7627ZM12 18.0127H12.008V18.0207H12V18.0127Z" stroke="#9759CB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                                    Support
                                </a>
        </LayoutResearcher>
    );
}
