import PropTypes from 'prop-types';
import { Icon } from '../Icon/Icon';
import { CircularNameIcon } from './CircularNameIcon';
export const CircularNameIconGroup = ({circularIconsArray, onClick,... props})=>{
    
    const renderCircularIcons = () => {

        var render = [];

        var till = circularIconsArray.length > 3 ? 3 :  circularIconsArray.length;

        for(var i=0; i < till; i++){
            render.push((
                <CircularNameIcon size={"small"} color={circularIconsArray[i].color} label={circularIconsArray[i].label} />
            ));
        }
        if(circularIconsArray.length > 3){
            render.push((
                <CircularNameIcon size={"small"} color={"gray-300"} label={<>+{circularIconsArray.length-3}</>}  />
            ));
            
        }
        if(circularIconsArray.length > 0){
            render.push((
                <CircularNameIcon size={"small"} color={"gray-50"} label={<><Icon value={"add"} size="small" /> </>}  />
            ));
        }
        return render;
    }
    
    return (
        <div className='circular-name-icon-wrap'>
            {renderCircularIcons()}
        </div>
    )
    
    
}
CircularNameIconGroup.propTypes = {
    circularIconsArray: PropTypes.array,
    onClick: PropTypes.func
}
CircularNameIconGroup.defaultProps = {
    circularIconsArray: [],
    onClick: undefined
}