import { useEffect, useRef, useState } from "react";
import { Box } from "../../../themes/userq/Box/Box";
import { Text } from "../../../themes/userq/Text/Text";
import BarChart from "./bar_chart_v2";
import { Button } from "../../../themes/userq/Button/Button";
import { Modal } from "react-bootstrap";
import { autoType } from "d3";
import { roundAnlyticsNumber } from "../../../../lib/helpers";
import exportAsImage from "../../../../lib/exportAsImage";
import { Icon } from "../../../themes/userq/Icon/Icon";

export const VerticalBarChartStatsBox = ({title, data, marginLeft, marginRight, id}) =>{

    const [otherModal, setOtherModal] = useState({open:false});

    const [width, setWidth] = useState(0);

    const divref = useRef();

    const modref = useRef();
    const exportPngRef = useRef();

    let chart_main = [];

    let chart_others = [];

    useEffect(()=>{
        if(divref && divref.current ){

            setWidth(divref.current.getBoundingClientRect().width-48);
        }
    },[divref]);

    useEffect(() => {
        function handleResize() {

            if(divref && divref.current ){

                setWidth(divref.current.getBoundingClientRect().width-48);

            }

        }

        window.addEventListener('resize', handleResize)
    })

    var colors = {};
    const color_unknown = "rgba(156, 156, 156, 1)";

    const colorsPallete = [
        "rgba(255, 109, 177, 1)",
        "rgba(151, 89, 203, 1)",
        "rgba(242, 198, 144, 1)",
        "rgba(105, 140, 231, 1)",
        "rgba(49, 240, 229, 1)",
        "rgba(55, 139, 16, 1)",
        "rgba(227, 47, 131, 1)",
        "rgba(121, 62, 171, 1)",
        "rgba(215, 165, 106, 1)",
        "rgba(54, 104, 231, 1)",
        "rgba(17, 191, 180, 1)",
        "rgba(22, 65, 3, 1)",
        "rgba(255, 155, 202, 1)",
        "rgba(191, 142, 232, 1)",
        "rgba(158, 180, 237, 1)",
        "rgba(144, 242, 236, 1)",
        "rgba(191, 240, 168, 1)",
        "rgba(167, 37, 97, 1)",
        "rgba(92, 32, 141, 1)",
        "rgba(195, 141, 77, 1)",
        "rgba(13, 59, 176, 1)",
        "rgba(10, 135, 127, 1)",
        "rgba(87, 12, 47, 1)",
        "rgba(67, 23, 105, 1)",
        "rgba(116, 76, 28, 1)",
        "rgba(6, 35, 108, 1)"

    ];

    var color_index = 0;

    for(var key in data){

        if(key=="Unknown"){
            colors["Unknown"] = color_unknown;
        }  else {
            if(color_index==18){
                color_index++;
            }
            colors[key] = colorsPallete[color_index];
            color_index++;
        }
    }


    const renderVerticalBarChart = (ref) => {
        var lChartData = [];

        var index = 0;




        var max = 0;
        for (var key in data) {

            if (data[key] > max) {
                max = data[key];
            }
        }
        var sum = 0;
        for (var key in data) {

            sum += data[key];
        }
        var bars = [];

        for (var key in data) {

            //Math.round(((data[key] / sum) * 100))
            var percentage_label = sum > 0 ? roundAnlyticsNumber(((data[key] / sum) * 100), false) : 0;
            var color = colors[key];


            lChartData.push({
                label: key,
                percentage: percentage_label,
                value: data[key],
                color: color,
                percentage_label: percentage_label + "%"
            });

            index++;

        }


        if(lChartData.length > 19){

            for(var i=0; i< 18; i++){
                chart_main.push(lChartData[i]);
            }
            var others = 0;

            for(var j=i; j< lChartData.length; j++){
                others += lChartData[j]["value"];

                chart_others.push(lChartData[j]);
            }
            percentage_label = sum > 0 ? Math.round(((others / sum) * 100)) : 0;

            chart_main.push({label: "More",percentage: percentage_label , value:others, color:colorsPallete[18], percentage_label:percentage_label+"%"});
        } else {
            chart_main = lChartData;
        }
        console.log(chart_main);


        return renderBarChart(chart_main, "main"+id, ref);
    }

    const renderBarChart = (barData,id, local_ref)=>{
        if (barData.length > 0 && width > 0) {
            return<> <div className="download-chart-png cursor-pointer" onClick={() => exportAsImage(exportPngRef.current, title)}>
                <Icon value={'download'} size={'small'} hover={'true'}></Icon>
                <div className="tooltip-wrapper top-tooltip">
                    <p className="body-text body-text-2 normal-font">Download</p>
                </div>
            </div><div style={ {margin:"0 auto", width: barData.length*80+(barData.length)*60 +(marginRight+marginLeft) < local_ref.current.getBoundingClientRect().width ? barData.length*80+(barData.length)*60 +(marginRight+marginLeft) : "100%" }} className="vertical-bar-chart-inner"><BarChart barpadding={barData.length*80+(barData.length)*60 < local_ref.current.getBoundingClientRect().width ? 0 : 0.3} onClick={()=>{ setOtherModal({...otherModal, open:true}); setTimeout(()=>{setOtherModal({...otherModal, open:true}); },0) }} id={"vbar"+id} width={width} data={barData} marginLeft={marginLeft} marginRight={marginRight} /></div></>;

        } else {
            return <div className="info-not-available">No info available</div>
        }

    }

    const wrapperRef = useRef(null);

    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    if(event.target.nodeName =="DIV"){
                        setOtherModal({...otherModal, open:false});
                    }
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    useOutsideAlerter(wrapperRef);

    return <div className="position-relative chart-download-with-heading">
        <div className="mb-20 box-outer-heading">
            <Text type={"h4"} fontWeight={"medium-font"}>{title}</Text>
        </div>


        <div ref={exportPngRef}>
            <Box  size={"large"}>
                <div ref={divref}  className="vertical-bar-chart">
                    {renderVerticalBarChart(divref)}
                </div>
            </Box>
            <Modal
                show={otherModal.open}
                centered
                className="fade custom-modal-wrap  confirmation-modal-wrap nationality-modal-wrap"
            >

                <Modal.Body className="" ref={wrapperRef}>
                    <Box  size={"large"}>
                        <div ref={modref}  className="vertical-bar-chart">
                            {modref && modref.current && renderBarChart(chart_others, "other"+id, modref)}
                        </div>
                    </Box>
                    <Button
                        type={"primary"}
                        size={"medium"}
                        label={"Close"}
                        onClick={()=>{
                            setOtherModal({...otherModal, open:false});
                        }}
                    />
                </Modal.Body>
            </Modal>
        </div></div>;
}