import { useNavigate } from 'react-router-dom';
import { ReactSession } from '../../lib/secure_reactsession.js';
import React, { useEffect, useState, useRef } from "react";
import { LayoutResearcher } from "../themes/userq/Layouts/layout_researcher";
import { getProfile, deleteAccount, updateResearcherProfile, sendChangeEmailToUser, disconnectSocialAccountService } from "../../services/user.js";
import ChangePasswordModal from './reset_password_modal';
// import IntlTelInput from 'react-intl-tel-input';
// import 'react-intl-tel-input/dist/main.css'
import { PhoneNumberUtil } from 'google-libphonenumber';
import { LoadingIcon } from "../loader/loadingIconNew";
import { Text } from './../themes/userq/Text/Text';
import ResearcherAccountNavigation from './researcher_account_navigation';
import { Input } from './../themes/userq/Input/Input';
import { Button } from '../themes/userq/Button/Button.js';
import { DropdownComponent } from './../themes/userq/Dropdown/Dropdown';
import { Icon } from './../themes/userq/Icon/Icon';
import { showToast } from '../../lib/helpers.js';
import ConfirmationModal from '../themes/userq/Modal/ConfirmationModal.js';
import DeleteAccountModal from '../themes/userq/Modal/DeleteAccountModal.js';
import PhoneInput from 'react-phone-number-input';
import { isValidPhoneNumber } from 'react-phone-number-input'
import { PhoneNumberInput } from '../themes/userq/PhoneNumberInput/PhoneNumberInput.js';
import { CheckBox } from "../themes/userq/CheckBox/CheckBox.js";
import SetPasswordModal from './set_password_modal.js';
import moment from 'moment';
import toast from "react-hot-toast";
import { ToastMessage } from '../themes/userq/ToastMessage/ToastMessage.js';
import { settings } from "../../data/settings.js";
import Cookies from 'js-cookie';

export default function ProfileResearcher() {

    ReactSession.setStoreType("localStorage");

    const [confirm, setConfirm] = useState({ open: false });

    const jobRoleOptions = [{ "label": "UX/UI designer", "value": "UX/UI designer" }, { "label": "UX researcher", "value": "UX researcher" }, { "label": "Product owner", "value": "Product owner" }, { "label": "Product manager", "value": "Product manager" }, { "label": "Social media manager", "value": "Social media manager" }, { "label": "Marketing manager", "value": "Marketing manager" }, { "label": "Entrepreneur", "value": "Entrepreneur" }, { "label": "Other...", "value": "Other..." }];

    const teamSizeOptions = [{ "label": "1", "value": "1" }, { "label": "2-5", "value": "2-5" }, { "label": "6-10", "value": "6-10" }, { "label": "11-20", "value": "11-20" }, { "label": "21-50", "value": "21-50" }, { "label": "51-100", "value": "51-100" }, { "label": "100+", "value": "100+" }];
    
    const [formLoading, setFormLoading] = useState(false);

    const [socialDisconnectLoading, setSocialDisconnectLoading] = useState(false);

    const [formValues, setFormValues] = useState({
        phone_number: "", dialcode_phone: "", country_phone
            : "", "first_name": "", "last_name": "", "company_name": "", "job_role": "", "team_size": "" 
    });

    const [whatsapp_country, setWhatsAppCountry] = useState("+971");

    var whatsapp_country_2 = "+971";

    const [formErrors, setFormErrors] = useState({ phone_no: null, first_name: null, last_name: null, company_name: null, job_role: null, team_size: null, "error_class": null });
    const [isLoading, setLoading] = useState(false);
    const [username, setUsername] = useState('');
    const [userProfile, setUserProfile] = useState(null);
    const [accountLoading, setAccountLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [openSetPasswordModal, setOpenSetPasswordModal] = useState(false);
    const [changeEmailModal, setChangeEmailModal] = useState({ open: false });
    const [profileChanged, setProfileChanged] = useState(false);
    const [show_support_panel, setShowSupportPanel] = useState(false);

    let user = ReactSession.get("user");

    const navigate = useNavigate();

    useEffect(() => {

        document.title = "Your profile | "+process.env.REACT_APP_NAME;

        let token = ReactSession.get("token");

        setLoading(true);

        getProfile(token).then(response => {
            setLoading(false);

            if (response.success) {
                setUserProfile(response.data.user)
                var other = false;

                if (response.data.user.job_role != null && response.data.user.job_role != '') {

                    var found = false;
                    for (var i = 0; i < jobRoleOptions.length; i++) {
                        if (jobRoleOptions[i].label == response.data.user.job_role) {
                            found = true;
                            break;
                        }
                    }
                    if (!found) {
                        other = true;
                    }
                }



                setFormValues({
                    "first_name": response.data.user.first_name,
                    "last_name": response.data.user.last_name,
                    "company_name": response.data.user.company_name,
                    "job_role": other ? "Other..." : response.data.user.job_role,
                    "job_role_other": other ? response.data.user.job_role : '',
                    "team_size": response.data.user.team_size,
                    "dialcode_phone": response.data.user.dialcode_phone == null ? '' : response.data.user.dialcode_phone,
                    "phone_number": response.data.user.phone_number == null ? '' : response.data.user.phone_number,
                    "whatsapp_phone_number": response.data.user.whatsapp_phone_number != null ? response.data.user.whatsapp_phone_number: "",
                    "whatsapp_checkbox": response.data.user.whatsapp_phone_number == response.data.user.phone_number ? true: false,
                    "country_phone": response.data.user.country_phone == null ? '' : response.data.user.country_phone,
                })

                window.setLabels();
                window.animate();

            }
        });
    }, []);

    const deleteUserAccount = (event) => {
        setAccountLoading(false);

        if (!accountLoading) {

            setAccountLoading(true);

            const token = ReactSession.get("token");

            deleteAccount(token)
                .then(response => {
                    setAccountLoading(false);

                    if (response.success) {
                        Cookies.set("last_login_method_researcher",null);
                        setConfirm({ ...confirm, open: false });
                        ReactSession.set("token", null);
                        showToast("Account successfully deleted", "success");
                        navigate("/researcher/sign-in");
                        ReactSession.set("user", {});


                    } else {
                        showToast(response.message, "error");
                    }
                })

        }
    }

    const sendChangeEmail = (event) => {
        // setAccountLoading(false);
        if (!accountLoading) {

            setAccountLoading(true);

            const token = ReactSession.get("token");

            sendChangeEmailToUser(token)
                .then(response => {
                    setAccountLoading(false);

                    if (response.success) {

                        setChangeEmailModal({ ...changeEmailModal, open: false });
                        showToast(response.message, "success");

                    } else {
                        showToast(response.message, "error");
                    }
                })

        }
    }
    const closeModal = (reload) => {
        setOpenModal(false);
    }
    const resetFormErrors = () => {
        let formErrorsLocal = Object.assign({}, formErrors);

        for (var key in formErrors) {
            formErrorsLocal[key] = null;
        }
        setFormErrors(formErrorsLocal);

        return formErrorsLocal;
    };

    // const onPhoneNumberChange = name => {
    //     return (status, phone_number, country) => {
    //         if (name == "phone_number") {

    //             setFormValues(oldValues => ({ ...oldValues, [name]: phone_number, ['dialcode_phone']: country.dialCode, ['country_phone']: country.iso2 }));
    //         } else {
    //             setFormValues(oldValues => ({ ...oldValues, [name]: phone_number, ['dialcode_whatsapp']: country.dialCode, ['country_whatsapp']: country.iso2 }));
    //         }
    //         setFormErrors((oldValues) => ({ ...oldValues, phone_no: null }));

    //     }
    // };
    // const onFlagChange = name => {
    //     return (status, country) => {

    //         setFormValues(oldValues => ({ ...oldValues, ['dialcode_phone']: country.dialCode, ['country_phone']: country.iso2 }));

    //     }
    // };

    const validateFullForm = () => {
        var error = false;

        var form_errors = resetFormErrors();
        var firsterrorid = null;

        if (formValues.first_name == '') {

            form_errors = {
                ...form_errors,
                first_name: "Required field",
                error_class: "input_error"
            };
            error = true;
            firsterrorid = firsterrorid == null ? "first_name" : firsterrorid;

        }
        if (formValues.last_name == '') {

            form_errors = {
                ...form_errors,
                last_name: "Required field",
                error_class: "input_error"
            };
            error = true;
            firsterrorid = firsterrorid == null ? "last_name" : firsterrorid;
        }
        if (formValues.company_name == '') {

            form_errors = {
                ...form_errors,
                company_name: "Required field",
                error_class: "input_error"
            };
            error = true;
            firsterrorid = firsterrorid == null ? "company-name" : firsterrorid;
        }

        if (formValues.job_role == '') {

            form_errors = {
                ...form_errors,
                job_role: "Required field",
                error_class: "input_error"
            };
            error = true;
            firsterrorid = firsterrorid == null ? "job_role" : firsterrorid;
        }

       

        if (formValues.job_role == 'Other...' && formValues.job_role_other == '') {

            form_errors = {
                ...form_errors,
                job_role_other: "Required field",
                error_class: "input_error"
            };
            error = true;
            firsterrorid = firsterrorid == null ? "job_role_other" : firsterrorid;
        }

        if (formValues.team_size == '') {

            form_errors = {
                ...form_errors,
                team_size: "Required field",
                error_class: "input_error"
            };
            error = true;
            firsterrorid = firsterrorid == null ? "team_size" : firsterrorid;
        }

        if (formValues.phone_number == '') {
            form_errors = {
                ...form_errors,
                phone_no: "Required field",
                error_class: "input_error"
            };

            error = true;

        } else if (formValues.phone_number != '') {

            /*let valid = false;
            try {

                const phoneUtil = PhoneNumberUtil.getInstance();
                // valid = phoneUtil.isValidNumber(phoneUtil.parse("+" + formValues.dialcode_phone + formValues.phone_number));
                valid = phoneUtil.isValidNumber(phoneUtil.parse(formValues.phone_number));

            } catch (e) {

                valid = false;

                error = true;
            }
            if (!valid) {
                form_errors = {
                    ...form_errors,
                    phone_no: "Phone number is not valid",
                    error_class: "input_error"
                };
                error = true;
            }*/
            if(!isValidPhoneNumber('+'+formValues.phone_number.toString())){
                form_errors = {
                    ...form_errors,
                    phone_no: "Phone number is not valid",
                    error_class: "input_error"
                };
                error = true;
            }
        }
        if(formValues.whatsapp_phone_number!='' && formValues.whatsapp_phone_number!=undefined && formValues.whatsapp_phone_number!="+"+whatsapp_country){
            /* let valid = false;
             try {

                 const phoneUtil = PhoneNumberUtil.getInstance();
                 // valid = phoneUtil.isValidNumber(phoneUtil.parse("+" + formValues.dialcode_phone + formValues.phone_number));
                 valid = phoneUtil.isValidNumber(phoneUtil.parse('+'+formValues.whatsapp_phone_number));

             } catch (e) {

                 valid = false;

                 error = true;
             }
             if (!valid) {
                 form_errors = {
                     ...form_errors,
                     whatsapp_phone_number: "WhatsApp number is not valid",
                     error_class: "input_error"
                 };
                 error = true;
             }*/

            if(!isValidPhoneNumber('+'+formValues.whatsapp_phone_number.toString())){
                form_errors = {
                    ...form_errors,
                    whatsapp_phone_number: "WhatsApp number is not valid",
                    error_class: "input_error"
                };
                error = true;
            }
        }
        if (formValues.email == '') {

            form_errors = {
                ...form_errors,
                email: "Required field",
                error_class: "input_error"
            };
            error = true;
            firsterrorid = firsterrorid == null ? "email" : firsterrorid;
        }
        setFormErrors(form_errors);

        if (firsterrorid != null) {
            document.getElementById(firsterrorid).scrollIntoView({
                behavior: "smooth",
                block: "center",
            });
        }

        return error;
    };
    const disconnectSocialAccount = async (provider) => {


        const token = ReactSession.get("token");

        let body = Object.assign({}, {provider:provider});

        setSocialDisconnectLoading(true);

        disconnectSocialAccountService(body, token)
            .then(response => {
                setSocialDisconnectLoading(false);

                if (response.success) {

                    Cookies.set("last_login_method_researcher",null);

                    let userProfileLocal = {...userProfile};

                    userProfileLocal.provider = null;

                    setUserProfile(userProfileLocal);

                    showToast(response.message, "success");


                } else {

                    showToast(response.message, "error");

                }
            })
        return false;
    }
    const onSubmit = async (event) => {
        event.preventDefault();

        setFormErrors({ first_name: null, last_name: null, company_name: null, job_role: null, job_role_other: null, team_size: null, password: null, email: null, terms: false, password_error_class: null, "error_class": null });

        if (!formLoading) {

            var error = validateFullForm();


            if (!error) {
                setFormLoading(true);

                const token = ReactSession.get("token");

                let body = Object.assign({}, formValues);

                if (body['job_role'] == "Other...") {
                    body['job_role'] = body['job_role_other'];
                }

                updateResearcherProfile(body, token)
                    .then(response => {
                        setFormLoading(false);

                        if (response.success) {

                            setProfileChanged(false);

                            let user = ReactSession.get("user");

                            user.first_name = response.user.first_name;

                            user.last_name = response.user.last_name;

                            user.phone_number = response.user.phone_number;

                            user.job_role = response.user.job_role;

                            user.team_size = response.user.team_size;

                            user.company = response.user.company;

                            ReactSession.set("user", user);

                            showToast(response.message, "success");

                            setUsername(user.first_name + " " + user.last_name)

                            setUserProfile({...userProfile,
                                first_name: response.user.first_name,
                                last_name: response.user.last_name,
                                phone_number: response.user.phone_number,
                                company_name: response.user.company_name,
                                job_role: response.user.job_role,
    
                                team_size: response.user.team_size
                            });

                        } else {

                            showToast(response.message, "error");

                        }
                    })
            }
        }
        return false;
    }
    const set = name => {
        return ({ target: { value } }) => {
            setFormValues(oldValues => ({ ...oldValues, [name]: value }));
            setFormErrors((oldValues) => ({ ...oldValues, [name]: null }));
            setProfileChanged(true);
        }
    };

    const jobRoleChange = (item) => {
        setFormValue("job_role", item ? item.value : '');
        if(item){
            setFormErrors((oldValues) => ({ ...oldValues, job_role: null }));
        } else {
            setFormErrors((oldValues) => ({ ...oldValues, job_role: 'Required field' }));
        }
        setProfileChanged(true);
        window.setLabels()
    }

    const teamSizeChange = (item) => {
        setFormValue("team_size", item ? item.value : '');
        if(item){
            setFormErrors((oldValues) => ({ ...oldValues, team_size: null }));
        } else {
            setFormErrors((oldValues) => ({ ...oldValues, team_size: 'Required field' }));
        }
        setProfileChanged(true);
        window.setLabels()
    } 
    
    const setFormValue = (name, value) => {
        setFormValues(oldValues => ({ ...oldValues, [name]: value }));
    };
    const toggleCheck = (name, value) => {
        setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
    };
    const showError = (error) => {

        toast(
            <ToastMessage type={"error"} message={error} closable={false} onClose={() => { toast.dismiss(); }} />,
            {
                className: "errortoast",
                position: "bottom-center",
                duration: settings.toast_duration,
            }
        );
    };
    return (
        <LayoutResearcher
            isLoading={false}
            wrapClass="rs-inner-profile-wrapper"
            username={username}
            researcher={userProfile}
            skipCheck={true}
            extendedFooter={false}
            activeMenu={"profile"}
            openSupportPanel={show_support_panel} hideSupportPanel={()=>{setShowSupportPanel(false)}}
        >
            <div className='page-heading-wrap accounts-page--heading'>
                <Text type="h1">Account</Text>
            </div>
            <ResearcherAccountNavigation activeMenu="profile" />

            {isLoading ? (
                <div className="accountloading-box"> <LoadingIcon /></div>
            ) : (
                <>
                    <div className='account-subtitle'>
                        <Text type="subtitle-2" fontWeight="semi-bold-font">Personal details</Text>
                    </div>
                    <div className="profile-form-wrap">
                        <div className="profile-form-repeat pb-0">
                            <div className="row">
                                <div className="col-md-8">
                                    <div className="row">
                                        <div className="formbox col-md-6">
                                            <div className="profile-form-group">
                                                <Input
                                                    label="First name"
                                                    onChange={set("first_name")}
                                                    onClear={() => {
                                                        setFormValues((oldValues) => ({
                                                            ...oldValues,
                                                            first_name: "",
                                                        }));
                                                    }}
                                                    value={formValues.first_name}
                                                    maxLength="45"
                                                    hideMaxChars={true}
                                                    error={formErrors.first_name}
                                                    error_class={formErrors.error_class}
                                                    id="first_name"
                                                    clearField={false}
                                                    required={true}
                                                />
                                            </div>
                                        </div>
                                        <div className="formbox col-md-6">
                                            <div className="profile-form-group">
                                                <Input
                                                    label="Last name"
                                                    onChange={set("last_name")}
                                                    onClear={() => {
                                                        setFormValues((oldValues) => ({
                                                            ...oldValues,
                                                            last_name: "",
                                                        }));
                                                    }}
                                                    value={formValues.last_name}
                                                    maxLength="45"
                                                    hideMaxChars={true}
                                                    error={formErrors.last_name}
                                                    error_class={formErrors.error_class}
                                                    id="last_name"
                                                    clearField={false}
                                                    required={true}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-md-6 formbox'>
                                            <div className="profile-form-group">
                                                {/* <div className="input-field static-label multi-field-wrap phone-number-field">
                                                    <label className="focusedlabel" htmlFor="phone">Phone number</label>

                                                    <PhoneInput
                                                        international
                                                        countryCallingCodeEditable={false}
                                                        defaultCountry="AE"
                                                        value={formValues.phone_number}
                                                        onBlur={()=>{
                                                            if(formValues.phone_number){
                                                            if(isValidPhoneNumber(formValues.phone_number)){
                                                                setFormErrors({
                                                                    ...formErrors,
                                                                    phone_no: null,
                                                                    error_class: "input_error"
                                                                });
                                                            }else{
                                                                setFormErrors({
                                                                    ...formErrors,
                                                                    phone_no: "Phone no is not valid",
                                                                    error_class: "input_error"
                                                                });
                                                            }
                                                            }
                                                        }}
                                                        onCountryChange={()=>{
                                                            setFormErrors({
                                                                ...formErrors,
                                                                phone_no: null,
                                                                error_class: "input_error"
                                                            });
                                                        }}
                                                        onChange={(value) => {
                                                            setFormValues(oldValues => ({ ...oldValues, phone_number: value }));
                                                            if(value){
                                                            if (isValidPhoneNumber(value)) {
                                                                setProfileChanged(true);
                                                                setFormErrors({
                                                                    ...formErrors,
                                                                    phone_no: null,
                                                                    error_class: "input_error"
                                                                });
                                                            } else {
                                                                setProfileChanged(false);
                                                                // setFormErrors({
                                                                //     ...formErrors,
                                                                //     phone_no: "Phone no is not valid",
                                                                //     error_class: "input_error"
                                                                // });
                                                            }
                                                        }
                                                        }} />

                                                        {formErrors.phone_no != null && (
                                                            <span className={formErrors.error_class}>
                                                                {formErrors.phone_no}
                                                            </span>
                                                        )}

                                                </div> */}
                                                <PhoneNumberInput
                                                    value={formValues.phone_number}
                                                    onBlur={()=>{
                                                        if(formValues.phone_number){
                                                            if(isValidPhoneNumber('+'+formValues.phone_number)){
                                                                setFormErrors({
                                                                    ...formErrors,
                                                                    phone_no: null,
                                                                    error_class: "input_error"
                                                                });
                                                            }else{
                                                                setFormErrors({
                                                                    ...formErrors,
                                                                    phone_no: "Phone number is not valid",
                                                                    error_class: "input_error"
                                                                });
                                                            }
                                                        }
                                                    }}
                                                    onCountryChange={()=>{

                                                        setFormErrors({
                                                            ...formErrors,
                                                            phone_no: null,
                                                            error_class: "input_error"
                                                        });
                                                    }}
                                                    onChange={(value) => {
                                                        var form_values = Object.assign({}, formValues);

                                                        if(value!=formValues.whatsapp_phone_number){
                                                            form_values = {...form_values, whatsapp_checkbox:false};
                                                        } else {
                                                            form_values = {...form_values, whatsapp_checkbox:true};
                                                        }
                                                        setFormValues({ ...form_values, phone_number: value });
                                                        if(value){
                                                            if (isValidPhoneNumber('+'+value)) {
                                                                setProfileChanged(true);
                                                                setFormErrors({
                                                                    ...formErrors,
                                                                    phone_no: null,
                                                                    error_class: "input_error"
                                                                });
                                                            } else {
                                                                setProfileChanged(false);
                                                            }
                                                        } else {
                                                            setProfileChanged(true);
                                                        }
                                                    }}
                                                    error={formErrors.phone_no}
                                                    error_class={formErrors.error_class}

                                                />
                                                <div className="whatsupplabel">
                                                    <CheckBox
                                                        checked={formValues.whatsapp_checkbox}
                                                        onChange={(event) => {
                                                            var form_values = Object.assign({}, formValues);

                                                            if(event.currentTarget.checked){
                                                                form_values = {...form_values, whatsapp_phone_number:form_values.phone_number, whatsapp_checkbox: true};

                                                                if(formValues.phone_number){
                                                                    if(isValidPhoneNumber('+'+formValues.phone_number)){
                                                                        setFormErrors({
                                                                            ...formErrors,
                                                                            whatsapp_phone_number: null,
                                                                            error_class: "input_error"
                                                                        });
                                                                    }else{

                                                                        setFormErrors({
                                                                            ...formErrors,
                                                                            whatsapp_phone_number: "WhatsApp number is not valid",
                                                                            error_class: "input_error"
                                                                        });
                                                                    }
                                                                } else {
                                                                    setFormErrors({
                                                                        ...formErrors,
                                                                        whatsapp_phone_number: null,
                                                                        error_class: "input_error"
                                                                    });
                                                                }

                                                            }  else {
                                                                form_values = {...form_values, whatsapp_phone_number:'', whatsapp_checkbox: false};

                                                                setFormErrors({
                                                                    ...formErrors,
                                                                    whatsapp_phone_number: null,
                                                                    error_class: "input_error"
                                                                });

                                                            }
                                                            setFormValues(form_values);



                                                            setProfileChanged(true);
                                                        }}
                                                        id="whatsapp_number"
                                                        label={"I use WhatsApp on this number"}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="formbox col-md-6  " id="whatsapp_phone_number"  style={{position:"relative"}}>
                                            <div className="profile-form-group">
                                                <PhoneNumberInput
                                                    value={formValues.whatsapp_phone_number}
                                                    onBlur={()=>{

                                                        if(formValues.whatsapp_phone_number && formValues.whatsapp_phone_number!="+"+whatsapp_country){
                                                            if(isValidPhoneNumber('+'+formValues.whatsapp_phone_number)){
                                                                setFormErrors({
                                                                    ...formErrors,
                                                                    whatsapp_phone_number: null,
                                                                    error_class: "input_error"
                                                                });
                                                                setProfileChanged(true);
                                                            }else{

                                                                setFormErrors({
                                                                    ...formErrors,
                                                                    whatsapp_phone_number: "WhatsApp number is not valid",
                                                                    error_class: "input_error"
                                                                });
                                                                console.log("reached over here")
                                                                setProfileChanged(false);
                                                            }
                                                        } else {
                                                            setFormErrors({
                                                                ...formErrors,
                                                                whatsapp_phone_number: null,
                                                                error_class: "input_error"
                                                            });
                                                            setProfileChanged(true);
                                                        }
                                                    }}
                                                    label={"WhatsApp number"}
                                                    onCountryChange={(country)=>{
                                                        setWhatsAppCountry(country);
                                                        whatsapp_country_2 = country;
                                                        setFormErrors({
                                                            ...formErrors,
                                                            whatsapp_phone_number: null,
                                                            error_class: "input_error"
                                                        });
                                                        setProfileChanged(true);
                                                    }}
                                                    onChange={(value) => {

                                                        var form_values = Object.assign({}, formValues);

                                                        if(value!=formValues.phone_number){
                                                            form_values = {...form_values, whatsapp_checkbox:false};
                                                        } else {
                                                            form_values = {...form_values, whatsapp_checkbox:true};
                                                        }

                                                        if(value && value!="+"+whatsapp_country_2){
                                                            if (isValidPhoneNumber('+'+value)) {
                                                                setProfileChanged(true);
                                                                setFormErrors({
                                                                    ...formErrors,
                                                                    whatsapp_phone_number: null,
                                                                    error_class: "input_error"
                                                                });
                                                            } else {
                                                                setProfileChanged(false);
                                                            }
                                                        } else {

                                                            setFormErrors({
                                                                ...formErrors,
                                                                whatsapp_phone_number: null,
                                                                error_class: "input_error"
                                                            });
                                                            setProfileChanged(true);
                                                        }
                                                        //setProfileChanged(true);

                                                        setFormValues({ ...form_values, whatsapp_phone_number: value });

                                                    }}
                                                    error={formErrors.whatsapp_phone_number}
                                                    error_class={formErrors.error_class}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-md-12'>
                                            <div className='row align-items-center'>

                                                <div className="col-md-6 formbox fullformbox">
                                                    <div className="profile-form-group ">
                                                        <div className="input input-field form-group read-only-field mb-0 change-email-option">
                                                            <Input
                                                                label="Email address"
                                                                onChange={() => { }}
                                                                onClear={() => { }}
                                                                value={user.email}
                                                                error={formErrors.email}
                                                                error_class={formErrors.error_class}
                                                                id="email"
                                                                clearField={false}
                                                                required={true}
                                                                readOnly={true}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 formbox fullformbox">
                                                    <div className="profile-form-group ">
                                                        <div className="input input-field form-group read-only-field mb-0 change-email-option">

                                                            <Button type="primary" size="small" label="Change email" onClick={() => {
                                                                if(userProfile.provider){
                                                                    showError("You can’t change your email because your account is authenticated with "+userProfile.provider+". For more information, contact our support team.");
                                                                    return;
                                                                }
                                                                setChangeEmailModal({ ...changeEmailModal, open: true })
                                                            }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 formbox">
                                            <div className="profile-form-group">
                                                <Input
                                                    label="Company"
                                                    onChange={set("company_name")}
                                                    onClear={() => {
                                                        setFormValues((oldValues) => ({
                                                            ...oldValues,
                                                            company_name: "",
                                                        }));
                                                    }}
                                                    value={formValues.company_name}
                                                    maxLength="45"
                                                    hideMaxChars={true}
                                                    error={formErrors.company_name}
                                                    error_class={formErrors.error_class}
                                                    id="company_name"
                                                    clearField={false}
                                                    required={true}
                                                    readOnly={userProfile && userProfile.role === 'Collaborator' && formValues.company_name && 'readonly'}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6 formbox">
                                            <div className="profile-form-group">
                                                <DropdownComponent
                                                    id={"job_role"}
                                                    label="Job role"
                                                    options={jobRoleOptions}
                                                    value={formValues.job_role}
                                                    onChange={(item) => {
                                                        jobRoleChange(item)
                                                    }}
                                                    isClearable={true}
                                                    error={formErrors.job_role}
                                                />
                                            </div>
                                        </div>
                                        {formValues.job_role == "Other..." && (
                                            <div className="col-md-6 formbox">
                                                <div className="profile-form-group">
                                                    <Input
                                                        label="Other job role "
                                                        onChange={set("job_role_other")}
                                                        onClear={() => {
                                                            setFormValues((oldValues) => ({
                                                                ...oldValues,
                                                                job_role_other: "",
                                                            }));
                                                        }}
                                                        value={formValues.job_role_other}
                                                        maxLength="45"
                                                        error={formErrors.job_role_other}
                                                        error_class={formErrors.error_class}
                                                        id="job_role_other"
                                                        clearField={false}
                                                        required={true}
                                                    />
                                                </div>
                                            </div>
                                        )}

                                            <div className="col-md-6 formbox">
                                            <div className="profile-form-group">
                                                <DropdownComponent
                                                    id={"team_size"} 
                                                    label="Research & design team size"
                                                    options={teamSizeOptions}
                                                    value={formValues.team_size}
                                                    onChange={(item) => {
                                                        teamSizeChange(item) 
                                                    }}
                                                    isClearable={true}
                                                    error={formErrors.team_size}
                                                />
                                            </div>
                                            </div>



                                        <div className="col-md-12">
                                            <div className="researcher-profile-form-btn">
                                                {user.role == "Researcher" && (
                                                    <div className="change-email-button">
                                                        {profileChanged ?
                                                            <><Button type="primary" size="medium" label="Save updates" state={"active"} onClick={onSubmit} microLoading={formLoading} /></>
                                                            :
                                                            <Button type="primary" size="medium" label="Save updates" state={"disabled"} />
                                                        }
                                                    </div>
                                                )}

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="change-password-section profile-details">
                            <Text type="subtitle-2" fontWeight="semi-bold-font">
                                {userProfile && !userProfile.password_set  ?
                                    <>Password settings</>
                                    :
                                    <>Password settings</>
                                }
                            </Text>
                            <div>
                                {userProfile && userProfile.provider
                                    ?
                                    <>
                                        <div className='profile-connected-with-social mt-32'>
                                            <div className={`profile-social-login-btn mb-20 d-flex align-items-center ${userProfile.provider.toLowerCase()}-login`}>
                                                <div className="social-btn-icon">
                                                    <Icon value={userProfile.provider} size={"extralarge"} />
                                                </div>
                                                <div className="social-btn-info">
                                                    <Text type={"body-text-2"} fontWeight={"semi-bold-font"}>You are connected with {userProfile.provider}</Text>
                                                    <Text type={"body-text-2"} fontWeight={"medium-font"} cssClasses={"gray-color"}>{userProfile.email}</Text>
                                                </div>
                                            </div>
                                            {userProfile.password_set
                                                ?
                                                <>
                                                    <Button type="secondary" microLoading={socialDisconnectLoading}  size="medium" label={"Disconnect "+userProfile.provider} onClick={() => { disconnectSocialAccount(userProfile.provider); }}  />
                                                </>
                                                :
                                                <></>
                                            }
                                        </div>
                                        {userProfile.password_set
                                            ?
                                            <>
                                                <div className='profile-change-password-btn-wrap d-flex align-items-center mt-32'>
                                                    <Text type="body-text-2" fontWeight="medium-font" cssClasses={"mr-4"}>Password created on {moment(userProfile.password_updated_at,"YYYY-MM-DD").format("DD/MM/YYYY")}</Text>
                                                    <Button type="secondary"  size="medium" label="Change password" onClick={() => { setOpenModal(true); }} iconLeft={<Icon value={"lock"} size={"small"} />} />
                                                </div>
                                            </>
                                            :
                                            <div className='mt-32 setapassword-info'>
                                                <Text type="body-text-2" cssClasses={"mb-8"} fontWeight="medium-font">Do you want to enable a password?</Text>
                                                <Text type="body-text-3" cssClasses={"gray-text"} fontWeight="medium-font">Once you set a password for your registered email, you'll have the option to log in using either your {userProfile.provider} account or your<br/> email and password.</Text>
                                                <Button type="secondary" cssclass={"mt-20"} size="medium" label="Set a password" onClick={() => { setOpenSetPasswordModal(true); }} iconLeft={<Icon value={"lock"} size={"small"} />} />
                                            </div>
                                        }

                                    </>
                                    :
                                    <Button type="secondary" cssclass={"mt-20"} size="large" label="Change password" onClick={() => { setOpenModal(true); }} iconLeft={<Icon value={"lock"} size={"small"} />} />
                                }
                            </div>
                        </div>

                        {user.role == "Researcher" && (
                            <div className="delete-account-section">
                                <Text type="subtitle-2" fontWeight="semi-bold-font">Delete account</Text>
                                <p className={`body-text body-text-2`}>Once you delete your account, all tests and unused credits will be removed.  <b>This action cannot be undone.</b></p>
                                <Button type="secondary" size="medium" label="Delete account" onClick={() => { setConfirm({ open: true }); }} />
                            </div>
                        )}

                        <ChangePasswordModal
                            openModal={openModal}
                            closeModal={closeModal}
                        />
                        <SetPasswordModal
                            openModal={openSetPasswordModal}
                            closeModal={(user)=>{
                                setOpenSetPasswordModal(false);

                                if(user){
                                    let userProfileLocal = {...userProfile};

                                    userProfileLocal.password_updated_at = user.password_updated_at;

                                    userProfileLocal.password_set = user.password_set;

                                    setUserProfile(userProfileLocal);
                                }
                            }}
                        />
                        <DeleteAccountModal
                            openModal={confirm.open}
                            confirm_title="Delete account"
                            confirm_title_class='text-danger'
                            cancel_btn_title={"No, keep"}
                            confirm_btn_title={"Yes, delete"}
                            close={() => {
                                setConfirm({ ...confirm, open: false });
                            }}
                            confirm={() => {
                                deleteUserAccount();


                            }}
                            btnLoading={accountLoading}
                        />

                        <ConfirmationModal
                            openModal={changeEmailModal.open}
                            confirm_title="Change email"
                            confirm_message="An email will be sent to current email address for you to verify."
                            confirm_btn_title={"Confirm"}
                            cancel_btn_title={"Cancel"}
                            close={() => {
                                setChangeEmailModal({ ...changeEmailModal, open: false });
                            }}
                            confirm={() => {
                                sendChangeEmail();
                            }}
                            btnLoading={accountLoading}
                        />
                    </div>
                </>
            )}
            <a onClick={(e)=>{
                e.preventDefault();

                setShowSupportPanel(true);

            }}  href={process.env.REACT_APP_URL+"r/support"} target="_blank" className='support-button'><svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.879 8.2817C11.05 7.2567 12.95 7.2567 14.121 8.2817C15.293 9.3067 15.293 10.9687 14.121 11.9937C13.918 12.1727 13.691 12.3197 13.451 12.4357C12.706 12.7967 12.001 13.4347 12.001 14.2627V15.0127M21 12.7627C21 13.9446 20.7672 15.1149 20.3149 16.2068C19.8626 17.2988 19.1997 18.2909 18.364 19.1267C17.5282 19.9624 16.5361 20.6253 15.4442 21.0776C14.3522 21.5299 13.1819 21.7627 12 21.7627C10.8181 21.7627 9.64778 21.5299 8.55585 21.0776C7.46392 20.6253 6.47177 19.9624 5.63604 19.1267C4.80031 18.2909 4.13738 17.2988 3.68508 16.2068C3.23279 15.1149 3 13.9446 3 12.7627C3 10.3757 3.94821 8.08656 5.63604 6.39873C7.32387 4.71091 9.61305 3.7627 12 3.7627C14.3869 3.7627 16.6761 4.71091 18.364 6.39873C20.0518 8.08656 21 10.3757 21 12.7627ZM12 18.0127H12.008V18.0207H12V18.0127Z" stroke="#9759CB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
                Support
            </a>
        </LayoutResearcher>
    );
}