import React, { useState, useEffect } from 'react';
import { useNavigate , Link, useParams } from 'react-router-dom';
import { encryptClient, preventClick } from '../../../lib/helpers';
import { Modal } from 'react-bootstrap';
import { GuestHeader } from '../../themes/userq/Header/GuestHeader';
import { Footer } from '../../themes/userq/Footer/Footer';
import { Text } from '../../themes/userq/Text/Text';
import { Input } from '../../themes/userq/Input/Input';
import { Button } from '../../themes/userq/Button/Button';
import SuccessDefaultModal from '../../themes/userq/SuccessMessageModal/success_default_modal';
import { Icon } from '../../themes/userq/Icon/Icon';



export default function ResetPassword() {
    
    let { code } = useParams();

    const navigate = useNavigate();

    const [errorMsg, setErrorMessage] = useState(null);

    const [passwordVisible, setPasswordVisibility] = useState(false);

    const [confirmPasswordVisible, setConfirmPasswordVisibility] = useState(false);

    const [isLoading, setLoading] = useState(false);

    const [formValues, setFormValues] = useState({password:'', confirm_password:'', code: code});

    const [formErrors, setFormErrors] = useState({password:null, passwordErrorStyle: {color:'#000'}, terms:null});

    const [modal, setModal] = useState({ modalOpen:false});

    const [newPasswordError, setNewPasswordError] = useState({error:false, length:0, letters:0, numbers:0, special:0});

    useEffect(() => {
        setTimeout(function(){
            window.animate();
        },1000);
        document.title = "Reset Password | "+process.env.REACT_APP_NAME;
    }, []);

    const resetPassword = () => {
        
        setLoading(true);

        setErrorMessage(null);

        var body = Object.assign({},formValues);

        

        body['password'] = encryptClient(body.password);

        body['confirm_password'] = encryptClient(body.confirm_password);

        body['role'] = 'Researcher';
        
        fetch(process.env.REACT_APP_API_END_POINT+'reset-password', {
            method: 'POST',
            body: JSON.stringify(body),
            headers:{"Content-Type":"application/json"}
        })
        .then(res => res.json())
        .then(response => {
            setLoading(false);
            if(response.success){
                setFormValues({password:'', confirm_password:'', code: code } );
                setModal({modalHeading:"Password Updated", modalMessage:response.message, modalOpen: true});                

            } else {
                setErrorMessage(response.message);
            }
        });
    }
    const validateForm = (name) => {
        
        
        return (event)=>{
            
            setFormValues(oldValues => ({...oldValues, [name]: event.target.value }));


            var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-zA-Z]).{6,}$/;

            if(formValues.password.length<6 || !formValues.password.match(re)){
                setFormErrors(oldValues => ({...oldValues, ['password_error_class']:  "password_error" }));    
            } else {
                setFormErrors(oldValues => ({...oldValues, ['password_error_class']:  'none' }));   
            } 
        }
        
    }
    const resetFormErrors = () => {

        let formErrorsLocal = Object.assign({}, formErrors);
        
        for(var key in formErrors){
            formErrorsLocal[key] = null;
        }
        setFormErrors(formErrorsLocal);

        return formErrorsLocal;
    }
    const validateFullForm = (focus, key, value=null, inline=false) => {
        
        var form_errors = Object.assign([],formErrors) ;

        if(key){
            form_errors[key] = null;
        }
        
        if(focus){

            form_errors = resetFormErrors();
        }
        var error = false;
            
        var re = /^(?=.*\d)(?=.*[@$!%*#?&])(?=.*[a-zA-Z]).{6,}$/;

        if(!key || key=="password"){

            var field_value = key && inline ? value : formValues.password;

            if(!inline){
                validateNewPassword(field_value,true);
            } else {
                validateNewPassword(field_value,false);
            }
                
            /*if(field_value==''){

                form_errors = {
                    ...form_errors,
                    password: "Required field",
                    error_class: "input_error"
                };
                error = true;
            }
            else*/ if(field_value.length<6 || !field_value.match(re)){
                
                form_errors = {
                    ...form_errors,
                    //password:"Password must contain at least 6 characters, and a combination of letters, numbers and special characters (e.g !@#$^&*)",
                    password_error_class:"password_error"
                };
                error = true;

                if(!field_value){
                    form_errors = {
                        ...form_errors,
                        password: "Required field",
                        error_class: "input_error",
                        password_error_class: "password_error",
                    };
                    
                }
                
            } else {
                form_errors ={
                    ...form_errors,
                    password: null,  
                    error_class: "input_error",
                    password_error_class:"none", 
                };
            } 
        }
        if(!key || key=="confirm_password"){

            var field_value = key && inline ? value : formValues.confirm_password;

            if(field_value==''){
                
                form_errors = {
                    ...form_errors,
                    confirm_password: "Required field",
                    error_class: "input_error"
                };
                error = true;
                
            }
            else if(formValues.password!=field_value){
            
                form_errors = {
                    ...form_errors,
                    confirm_password: "Confirm password must be same as new password.",
                    error_class: "input_error"
                };
                error = true;
                
            }
        }
        
        if((inline && formErrors[key] ) || !inline){

            console.log(formErrors)
            
            setFormErrors(form_errors);
        }

        return error;
    }
    const checkBtnState  = () => {
        
        
        var error = false;
            
        var re = /^(?=.*\d)(?=.*[@$!%*#?&])(?=.*[a-zA-Z]).{6,}$/;
            
        if(formValues.password.length<6 || !formValues.password.match(re)){
            
            error = true;
            
        }
        if(formValues.confirm_password==''){
            
            error = true;
            
        }
        else if(formValues.password!=formValues.confirm_password){
        
            error = true;
            
        }
        
        return error;
    }
    const onSubmit =  async (event) => {
        event.preventDefault();

        setErrorMessage(null);

        if(!isLoading){

            var error = validateFullForm();
            
            if(!error){
                resetPassword();
            }
        }
        return false;
    }
    const set = name => {
        return ({ target: { value } }) => {
            setFormValues(oldValues => ({...oldValues, [name]: value }));
            setFormErrors((oldValues) => ({ ...oldValues, [name]: null }));

            validateFullForm(false,name, value ? value : '', true)
        }
    };
    const clearFormValue = name => {
        return ({ target: { value } }) => {
            setTimeout(function(){
                setFormValues(oldValues => ({...oldValues, [name]: '' }));
            },1000)
            
        }
    };
    const togglePassword = () => {
        setPasswordVisibility(!passwordVisible);
    };
    const toggleConfirmPassword = () => {
        setConfirmPasswordVisibility(!confirmPasswordVisible);
    };
    const clearErrorMessage = () => {
        setErrorMessage(null);
    }
    const validateNewPassword = (password, blur) =>{

        var new_password_error = Object.assign({},newPasswordError);

        if(password.length>0){
            
            new_password_error = {...new_password_error, error: true};

            
            if(password.length >= 6){

                new_password_error = {...new_password_error, length: 1};
                
            } else {
                
                if(blur){
                    new_password_error = {...new_password_error, length: 2};
                } else {
                    if(new_password_error.length!=2 && new_password_error.special!=2  && new_password_error.numbers!=2 && new_password_error.letters!=2){
                        new_password_error = {...new_password_error, length: 0};
                    } else {
                        new_password_error = {...new_password_error, length: 2};
                    }
                }
            }
            const specialChars =/[@$!%*#?&]/;

            if(specialChars.test(password)){
                new_password_error = {...new_password_error, special: 1};
            } else {

                if(blur){
                    new_password_error = {...new_password_error, special: 2};
                }  else {
                    if(new_password_error.length!=2 && new_password_error.special!=2  && new_password_error.numbers!=2 && new_password_error.letters!=2){
                        new_password_error = {...new_password_error, special: 0};
                    } else {
                        new_password_error = {...new_password_error, special: 2};
                    }
                }
            }
            const numbers =/[0-9]/;

            if(numbers.test(password)){
                new_password_error = {...new_password_error, numbers: 1};
            } else {

                if(blur){
                    new_password_error = {...new_password_error, numbers: 2};
                } else {
                    if(new_password_error.length!=2 && new_password_error.special!=2  && new_password_error.numbers!=2 && new_password_error.letters!=2){
                        new_password_error = {...new_password_error, numbers: 0};
                    } else {
                        new_password_error = {...new_password_error, numbers: 2};
                    }
                }
            }
            const letters =/[a-zA-Z]/;

            if(letters.test(password)){
                new_password_error = {...new_password_error, letters: 1};
            } else {

                if(blur){
                    new_password_error = {...new_password_error, letters: 2};
                } else {
                    if(new_password_error.length!=2 && new_password_error.special!=2  && new_password_error.numbers!=2 && new_password_error.letters!=2){
                        new_password_error = {...new_password_error, letters: 0};
                    } else {
                        new_password_error = {...new_password_error, letters: 2};
                    }
                }
            }
            setNewPasswordError(new_password_error);

        } else {

            var new_password_error = Object.assign({},{error:false, length:0, letters:0, numbers:0, special:0});

            setNewPasswordError({...new_password_error, error: false});
        }
    }
    return (
       <div className="reset-pass-wrapper">
            <GuestHeader from_researcher={true} />
            <div className="login-data-wrap reset-pass-innerwrap">
                <div className="container">
                 <div className="row justify-content-center">
                    <div className="resetwrap">
                        <Text type="h2" fontWeight="medium-font">Reset password</Text>
                        
                        <div className="login-form-inner-data researcher-register-wrap">
                            
                            <form action="#" onSubmit={onSubmit}>
                                <div className="formbox">
                                    <Text type={"body-text-3"} fontWeight="medium-font">Password must contain at least 6 characters, and a combination of letters, numbers and special characters (e.g !@#$^&*)</Text>
                                </div>    
                                <div className="formbox">
                                    <Input 
                                        passwordField={true}
                                        clearField={false}
                                        type={"password"}
                                        required={true}
                                        passwordVisible={passwordVisible}
                                        id="pass_log_id"
                                        error={formErrors.password}
                                        error_class={formErrors.error_class}
                                        label={"New password"}
                                        value={formValues.password}
                                        onChange={(e)=>{ setFormValues({...formValues, password:e.target.value}); validateFullForm(false,'password', e.target.value ? e.target.value:'', true); }}
                                        onBlur={()=>{ validateFullForm(false, "password") }} 
                                        newPasswordError={newPasswordError}
                                    />
                                   
                                </div>
                                <div className="formbox">
                                    <Input
                                        passwordField={true}
                                        clearField={false}
                                        type={"password"}
                                        required={true}
                                        passwordVisible={confirmPasswordVisible}
                                        id="pass_log_id"
                                        error={formErrors.confirm_password}
                                        error_class={formErrors.error_class}
                                        label={"Confirm password"}
                                        value={formValues.confirm_password}
                                        onChange={(e)=>{ setFormValues({...formValues, confirm_password:e.target.value}); validateFullForm(false,'confirm_password', e.target.value ? e.target.value:'', true); }}
                                        onBlur={()=>{ validateFullForm(false, "confirm_password") }}
                                    />
                                </div>
                               <div className="full createaccount-btnbox">
                                    <Button
                                        type={"primary"}
                                        size={"large"}
                                        label="Reset password"
                                        microLoading={isLoading}
                                        state={checkBtnState()?"disabled":"active"}
                                    />
                                    
                                </div>
                                
                            </form>
                            <SuccessDefaultModal 
                                open={modal.modalOpen}
                                icon={<Icon value={"success-gn"} size={"medium"} />}
                                message={<Text type={"h3"} fontWeight={"semi-bold-font"}>Password has been changed successfully</Text> }
                               cssClass={"passwordchangedsuccess"} 
                                btn={
                                    <Button 
                                        label={"Sign in"}
                                        type="primary"
                                        size={"large"}
                                        onClick={()=>{navigate("/researcher/sign-in"); setModal({...modal, openModal:false})  } }
                                        
                                    />
                                    
                                }
                            />
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
        
    )
}