import React, { Children, useEffect } from "react";
import {useLayoutEffect, useRef, useState} from 'react';
import { Button } from "../Button/Button";
import { Text } from "../Text/Text";
import { Icon } from "../Icon/Icon";

export const Tag = ({title, type,cssClasses, onClose, onAdd, iconLeft, colorClass}) => {

    return (
        <span className={`tag ${type? type : ''} ${cssClasses?cssClasses:''} ${iconLeft?"tag-icon-left":''}`}>
            {iconLeft &&
            <Icon colorClass={colorClass} value={iconLeft} size={"medium"} />
            }
           {title}
           {onClose && 
           <span onClick={onClose}>
           <Icon value="close" colorClass={'gray-50-svg'} />
           </span>
            }
            {onAdd && 
           <span onClick={onAdd}>
           <Icon value="add" colorClass={'gray-50-svg'} hover={true} />
           </span>
            }
        </span>        
    );
};
export const TagGroup = ({children}) => {

    return (
        <div className="tags-wrap">
            {children}
        </div>        
    );
};