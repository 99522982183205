import React from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
//import { ReactSession } from "react-client-session";
import { ReactSession } from '../../../lib/secure_reactsession';
import { LayoutResearcher } from '../../themes/userq/Layouts/layout_researcher';
import { useState, useEffect, useRef } from "react";
import { Text } from '../../themes/userq/Text/Text';
import ResearcherSupportNavigation from './researcher_support_navigation';
import ContactUsForm from './elements/contact_us_form';
import { Input } from '../../themes/userq/Input/Input';
import { Button } from '../../themes/userq/Button/Button';
import { ToastMessage } from '../../themes/userq/ToastMessage/ToastMessage';
import toast from 'react-hot-toast';

import { settings } from "../../../data/settings.js";

let success_messages = [];
let error_messages = [];
export default function ResearcherSupportContact(props) {


    useEffect(() => {
        document.title =  "Contact us | "+process.env.REACT_APP_NAME;

    

    }, []);

    const showSuccessV2 = ()=>{

    }
    const showErrorV2 = (error) => {

        let shake_effect = true;
       /* if(error_messages.search(error)==-1){
            error_messages.push(error);
        } else {
            shake_effect = true;
        }*/
        
        toast(
            <ToastMessage cssClasses={shake_effect?"shake-toast":""} type={"error"} message={error} closable={false} onClose={() => { toast.dismiss(); }} />,
            {
                className: "errortoast",
                position: "bottom-center",
                duration: settings.toast_duration,
                onClose: () => { 
                    alert()
                }

            }
        );
    };

    

    return (
        <LayoutResearcher fixed_header_target={true} isLoading={false} wrapClass={"rs-inner-profile-wrapper"} skipCheck={false} extendedFooter={false} activeMenu={""}>
            <div className='page-heading-wrap accounts-page--heading'>
                <Text type="h1">Support</Text>
            </div>
            <ResearcherSupportNavigation activeMenu="contact" />
            <ContactUsForm />
        </LayoutResearcher>
    )
}