import { useEffect, useRef, useState } from "react";
import './MultiSelecteDropdownList.css';
import { CheckBox } from "../CheckBox/CheckBox.js";
import { RatingScaleIcon } from "../RatingScaleIcon/RatingScaleIcon";
import { Icon } from "../Icon/Icon";
import { Text } from "../Text/Text";


  
export const  MultiSelecteDropdownList = ({label, options, type, readOnly, onChange, value, className}) =>{
    
    const [selected, setSelected] = useState([]);

    const [showDropdown, setShowDropDown] = useState(false);

    const ref = useRef(null);

    useEffect(()=>{
        if(value){
            let locValue = [];

            value.forEach(function(item){
                if(item!=""){
                    locValue.push(item);
                }
            })
            setSelected(locValue);
        }
    },[value])

    

      useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
          if (ref.current && !ref.current.contains(event.target)) {
            setShowDropDown(false);
          }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          // Unbind the event listener on clean up
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }, [ref]);

    const renderSelectedValues=() =>{
        var extra = "";
        
        var optionsRender = [];

        //selected.sort();


        if(type=="emoticons"){

            
            //selected.sort(function(a, b){return parseInt(a)-parseInt(b)});

            var option_length = selected.length > 4 ? 4 : selected.length;

            if(selected.length>4){

                extra = " +" + (selected.length -4);
            }
            

            for(var i=0; i<option_length; i++){
                optionsRender.push(<RatingScaleIcon scale={options.length} type={type} value={selected[i]} />);
            }
            if(selected.length==0){
                return <></>;
            }
            return <>
                {optionsRender.map(function(item){
                    return item;
                })}
                {extra}
            </>;

        } else if(type=="stars"){

            //selected.sort(function(a, b){return parseInt(a)-parseInt(b)});

            var suffix = " stars";

            if(selected.length==1){
                if(selected[0]==1){
                    suffix = " star";
                }
            }

            for(var i=0; i<selected.length; i++){
                optionsRender.push(selected[i]);
            }
            if(selected.length==0){
                return <></>;
            }
            return <>{optionsRender.join(",")+suffix}</>;
            
        } else if(type=="numeric"){

            //selected.sort(function(a, b){return parseInt(a)-parseInt(b)});

            for(var i=0; i<selected.length; i++){
                optionsRender.push(selected[i]);
            }
            if(selected.length==0){
                return <></>;
            }
            return <>{optionsRender.join(",")}</>;
            
        } else {

            
            var option_length = selected.length > 1 ? 1 : selected.length;

            if(selected.length>1){

                extra = " +" + (selected.length -1);
            }
            

            for(var i=0; i<option_length; i++){
                optionsRender.push(selected[i]);
            }
            if(selected.length==0){
                return <></>;
            }
            return <>
                {optionsRender.map(function(item){
                    return item;
                })}
                {extra}
            </>
            
        }
    }
    const renderSelectBox = ()=>{
        return <div  className={`multiselectlist-dropdown-box-container ${selected.length>0?"focussed":""}`} onClick={(e)=>{ e.preventDefault(); setShowDropDown(!showDropdown)}}>
            <div className={`multiselectlist-dropdown-selected-values-container`}>
                {renderSelectedValues()}
            </div>
            
            <Text type={"body-text-2"} fontWeight={"normal-font"}>{label}</Text>
            <div className={`multiselectlist-dropdown-dropdown-icon-container`}>
                {selected.length > 0 &&
                <span onClick={(e)=>{ 
                    e.preventDefault();
                    e.stopPropagation();
                    setShowDropDown(false);
                    setSelected([]); 
                    if(onChange){
                        onChange([]);
                    } 
                }} >
                <Icon 
                    
                    colorClass={"gray-900-svg"} 
                    value={"close"} 
                    hover={true}
                />
                </span>
                }
                {selected.length == 0 &&
                <Icon colorClass={"gray-900-svg"} value={showDropdown? "Chevron up" : "Chevron"} hover={true}/>
                }
            </div>
        </div>
    }
    const renderDropDown = ()=>{

        if(showDropdown){
            return <div className="multiselectlist-dropdown-options" style={{position:"absolute", background:"#fff", width:"200px"}}>
                {options.map((option, index)=>{
                    return <div key={"select-option"+index} className="multiselectlist-dropdown-option">
                        <CheckBox 
                            label={option.label}
                            value={option.value}
                            checked={selected.includes(option.value)?true:false}
                            onChange={(e)=>{

                                var locSelected = [...selected];

                                
                                const index = locSelected.indexOf(option.value);
                                if (index > -1) { // only splice array when item is found
                                    locSelected.splice(index, 1); // 2nd parameter means remove one item only
                                } else{
                                    locSelected.push(option.value);  
                                }
                                setSelected(locSelected);

                                if(onChange){
                                    onChange(locSelected);
                                }
                            }}

                        />
                    </div>
                })}
            </div>
        } else  {
            return <></>
        }
    }
    return <div ref={ref} className={`multiselectlist-dropdown-box position-relative ${className} ${readOnly?"multiselectlist-dropdown-readonly":""}`}>
        {renderSelectBox()}
        {renderDropDown()}
    </div>;
}