import { useNavigate, useParams, Link, useSearchParams } from "react-router-dom";
import React, { useEffect, useState, useRef } from "react";
import { ReactSession } from "../../../lib/secure_reactsession.js";
import { getTestData } from "../../../services/test";
import toast from "react-hot-toast";
import { saveConclusion } from "../../../services/test";
import moment from "moment";
import { Stepper } from './../../themes/userq/Stepper/Stepper';
import { LayoutResearcher } from './../../themes/userq/Layouts/layout_researcher';
import { Text } from './../../themes/userq/Text/Text';
import { Input } from './../../themes/userq/Input/Input';
import { TextArea } from './../../themes/userq/TextArea/TextArea';
import { Icon } from './../../themes/userq/Icon/Icon';
import { Button } from './../../themes/userq/Button/Button';
import Header from './components/header';
import { LoadingIcon } from './../../loader/loadingIconNew';
import { ToastMessage } from './../../themes/userq/ToastMessage/ToastMessage';
import { settings } from "../../../data/settings.js";
import { Helmet } from "react-helmet";
import { renderTestTitle, renderTestTitleCreate } from "../../../lib/helpers.js";
import { useTestBuilderData } from "./contexts/TestBuilderContext.js";

export function getFileType(file) {

    if (file.type.match('image.*'))
        return 'image';

    return 'other';
}
export function matchExtensions(extns, file) {
    return extns.indexOf(file.name.split('.').pop()) != -1 ? true : false;
}

export default function Conclusions() {

    ReactSession.setStoreType("localStorage");
    let { test_id } = useParams();
    const navigate = useNavigate();

    const { updateTestLength, getTestLength } = useTestBuilderData();

    const [test, setTest] = useState("");
    const [lastSaved, setLastSaved] = useState(null);
    const [formLoading, setFormLoading] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [language, setLanguage] = useState("en");
    const [image, setImage] = useState(null);
    const [previewUrl, setPreviewUrl] = useState("");
    const [thankyouImage, setThankyouImage] = useState(null);
    const [imageDeleted, setImageDeleted] = useState(false);
    const [isSaved, setIsSaved] = useState({ 'status': '', 'message': '' });
    const [stepperError, setStepperError] = useState(false);

    const [formValues, setFormValues] = useState({
        thankyou_title: "",
        thankyou_description: "",
    });

    const [formErrors, setFormErrors] = useState({
        image: null,
        thankyou_title: null,
        thankyou_description: null,
        error_class: null,
    });

    const [searchParams] = useSearchParams()

    let treeEmptyNodeError = false;

    const [show_support_panel, setShowSupportPanel] = useState(false);

    const showError = (error) => {
        localStorage.setItem("stepper_error", error);
        toast(
            <ToastMessage type={"error"} message={error} closable={false} onClose={() => { toast.dismiss(); }} />,
            {
                id:"error",
                className: "errortoast",
                position: "bottom-center",
                duration: settings.toast_duration,
            }
        );

    };

    const resetFormErrors = () => {

        let formErrorsLocal = Object.assign({}, formErrors);
        for (var key in formErrors) {
            formErrorsLocal[key] = null;
        }
        setFormErrors(formErrorsLocal);

        return formErrorsLocal;
    };

    const handleFile = (file) => {

        resetFormErrors();
        //let's grab the image file
        var error = false;

        var fileType = getFileType(file);

        if (fileType == "image" && file.size / 1024 > 2048) {
            setFormErrors({ ...formErrors, image: "Please select file <= 2MB.", "error_class": "input_error" });
            error = true;
        }
        if (!matchExtensions(["jpg", "jpeg", "gif", "png", "JPG", "JPEG", "GIF", "PNG"], file)) {
            setFormErrors({ ...formErrors, image: "Only image files are accepted with extensions  jpg, jpeg, gif and png.", "error_class": "input_error" });
            error = true;
        }
        if (fileType != "image") {
            setFormErrors({ ...formErrors, image: "Only image files are accepted.", "error_class": "input_error" });
            error = true;
        }
        if (!error) {
            setImage(file);
            setPreviewUrl(URL.createObjectURL(file));
        }

    };

    const handleOnDragOver = (event) => {
        event.preventDefault();
    };
    const handleOnDrop = (event) => {
        //prevent the browser from opening the image
        event.preventDefault();
        event.stopPropagation();
        //let's grab the image file
        let imageFile = event.dataTransfer.files[0];

        handleFile(imageFile);
    };
    const removeImage = (e) => {
        setImage(null);
        setPreviewUrl(null);
        setThankyouImage(null);
        setImageDeleted(true);
    };
    const handleFileInput = (event) => {
        //prevent the browser from opening the image
        event.preventDefault();
        event.stopPropagation();
        //let's grab the image file
        let imageFile = event.target.files[0];

        handleFile(imageFile);
    };



    const set = (name) => {
        return ({ target: { value } }) => {
            setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
            setFormErrors((oldValues) => ({ ...oldValues, [name]: null }));
            setStepperError(false);
        };
    };
    function checkEmptyNodeInTree(tree){
        tree.forEach(function (item) {
          
            if (!item.title) {
              treeEmptyNodeError = true;
      
              item["error"] = true;
            } else {
              item["error"] = false;
            }
            if (item.children.length > 0) {
            
                checkEmptyNodeInTree(item.children);
            }
        });
      };

    const getTest = () => {
        let token = ReactSession.get("token");

        setLoading(true);

        getTestData({ test_id: test_id }, token).then((response) => {
            setLoading(false);
            if (response.success) {
                if (response.test.status) {
                    if (response.test.status == "published") {
                        navigate("/r/project/" + response.test.project_id);
                    } else {
                        getTestLength(test_id);
                        if (
                            response.test.welcome_title == "" ||
                            response.test.welcome_description == ""
                        ) {
                            showError("The welcome page cannot be empty! Please enter a title and a description.");
                            navigate("/r/test/" + test_id + "/welcome?error=true");

                        } else if (
                            response.test.methodology == "Tree Test" &&
                            response.test.tasks.length == 0
                        ) {
                            showError("Please add tasks");
                            navigate("/r/test/" + test_id + "/tree-test?error=true");

                        } else if (response.test.methodology === "Card Sorting") {
                            if (response.test.card_sorting === null || !response.test.card_sorting.title) {
                                showError(
                                    "You missed some key information. Please check again and fill out any missing fields."
                                );
                                navigate(
                                    "/r/test/" + test_id + "/card-sorting-test?error=true"
                                );

                            }else if (response.test.card_sorting && response.test.card_sorting_card.length === 0) {
                                showError(
                                    "Looks like you haven't created enough cards for this test. Please add at least two cards to move to the next steps."
                                );
                                navigate(
                                    "/r/test/" + test_id + "/card-sorting-test?error=true"
                                );

                            }
                            else if (
                                response.test.card_sorting.card_sorting === "close" &&
                                response.test.card_sorting_category.length < 2
                            ) {
                                showError(
                                    "Looks like you haven't created enough categories for this test. Please add at least two categories to move to the next steps."
                                );
                                navigate(
                                    "/r/test/" + test_id + "/card-sorting-test?error=true"
                                );

                            }else if (response.test.card_sorting_card.length < 2) {
                                showError(
                                    "Looks like you haven't created enough cards for this test. Please add at least two cards to move to the next steps."
                                );
                                navigate(
                                    "/r/test/" + test_id + "/card-sorting-test?error=true"
                                );

                            }
                        } else if (response.test.methodology === "Five Seconds Test") {
                            console.log(response.test.fiveSecondsTestTask)
                            if (response.test.fiveSecondsTestTask == null) {
                                showError(
                                    "Looks like you haven't added any task for this test! Please add task to move to the next steps."
                                );
                                navigate(
                                    "/r/test/" + test_id + "/five-seconds-test?error=true"
                                );

                            }else if ((response.test.fiveSecondsTestTask.task == "" || response.test.fiveSecondsTestTask.task == null) && response.test.fiveSecondsTestTask.task_file == null && response.test.fiveSecondsTestQuestion.length === 0) {
                                showError(
                                    "Looks like you haven't added any task for this test! Please add task to move to the next steps."
                                );
                                navigate(
                                    "/r/test/" + test_id + "/five-seconds-test?error=true"
                                );

                            }
                            else if (response.test.fiveSecondsTestTask.task == "" || response.test.fiveSecondsTestTask.task == null) {
                                showError(
                                    "Looks like you haven't added any task for this test! Please add task to move to the next steps."
                                );
                                navigate(
                                    "/r/test/" + test_id + "/five-seconds-test?error=true"
                                );

                            } else if (response.test.fiveSecondsTestTask.task_file == null) {
                                showError(
                                    "Looks like you haven't added design for this test! Please add design to move to the next steps."
                                );
                                navigate(
                                    "/r/test/" + test_id + "/five-seconds-test?error=true"
                                );

                            }
                            else if (response.test.fiveSecondsTestQuestion.length === 0) {
                                showError(
                                    "Looks like you haven't added questions for this test! Please add at least 1 question to move to the next steps."
                                );
                                navigate(
                                    "/r/test/" + test_id + "/five-seconds-test?error=true"
                                );

                            }
                            else if (response.test.fiveSecondsTestQuestion.length > 0) {
                                let nonoptionalcount = 0;
                                response.test.fiveSecondsTestQuestion.forEach((question) => {
                                    if (JSON.parse(question).is_optional == 0) {
                                        nonoptionalcount = 1
                                    }
                                }
                                );

                                if (nonoptionalcount == 0) {
                                    showError(
                                        "At least one question should not be optional."
                                    );
                                    navigate(
                                        "/r/test/" + test_id + "/five-seconds-test?error=true"
                                    );

                                }

                            }
                        } else if (
                            response.test.methodology == "Survey"
                        ) {
                            if (response.test.surveyQuestion.length === 0) {
                                showError(
                                    "Please add at least one question for the survey before proceeding."
                                );
                                navigate(
                                    "/r/test/" + test_id + "/survey?error=true"
                                );

                            }
                            else if (response.test.surveyQuestion.length > 0) {
                                let nonoptionalcount = 0;
                                validatelogic(response.test.surveyQuestion);
                                response.test.surveyQuestion.forEach((question) => {
                                    if (JSON.parse(question).is_optional == 0) {
                                        nonoptionalcount = 1
                                    }
                                }
                                );

                                if (nonoptionalcount == 0) {
                                    showError(
                                        "At least one question should not be optional."
                                    );
                                    navigate(
                                        "/r/test/" + test_id + "/survey?error=true"
                                    );

                                }

                            }

                        } else if (
                            response.test.methodology == "Tree Test"
                        ) {

                            var tree_error = false;
                            
                            if(response.test.tree){

                                if(response.test.tree.length==0){
                                    tree_error = true;
                                    showError("Some required information for the tree testing is missing or incomplete. Please review and try again.");
                                    navigate("/r/test/" + test_id + "/tree-test?error=true");    
                                } else {
                                    treeEmptyNodeError = false;

                                    checkEmptyNodeInTree(response.test.tree);

                                    if(treeEmptyNodeError){
                                        tree_error = true;
                                        showError("Some required information for the tree testing is missing or incomplete. Please review and try again.");
                                        navigate("/r/test/" + test_id + "/tree-test?error=true");    
                                    }
                                }
                                
                            }
                            if(!tree_error){
                                if (response.test.tasks.length === 0) {
                                    showError("Some required information for the tree testing is missing or incomplete. Please review and try again.");
                                    navigate("/r/test/" + test_id + "/tree-test?error=true");

                                } else {
                                    
                                    for (var i = 0; i < response.test.tasks.length; i++) {
                                        if (!response.test.tasks[i].title || response.test.tasks[i].title == null) {
                                            showError("Some required information for the tree testing is missing or incomplete. Please review and try again.");
                                            navigate("/r/test/" + test_id + "/tree-test?error=true");
                                            break;
                                        }
                                        else if (response.test.tasks[i].correct_answer_id == null) {
                                            showError("Some required information for the tree testing is missing or incomplete. Please review and try again.");
                                            navigate("/r/test/" + test_id + "/tree-test?error=true");
                                            break;
                                        }
                                    }

                                }
                            }
                        } else if (
                            response.test.methodology == "Preference Test"
                        ) {
                            if (response.test.preference_test_setting.preference_test_title == "" || response.test.preference_test_setting.preference_test_title == null) {
                                showError("You missed some key information. Please check again and fill out any missing fields.");
                                navigate("/r/test/" + test_id + "/preference-test?error=true");

                            }
                            else if (response.test.preference_test_designs.length < 2) {
                                showError("You missed some key information. Please check again and fill out any missing fields.");
                                navigate("/r/test/" + test_id + "/preference-test?error=true");

                            }
                        } else if(response.test.methodology=="Prototype test"){

                            var error = false;

                            for(var i=0; i<response.test.prototype_test_tasks.length; i++){
			
                                var task = response.test.prototype_test_tasks[i];
                    
                                
                                if(!task.prototype_link){
                                    
                                    error = true;
                                    break;
                                }
                                else if(!task.is_synced){
                                    error = true;
                                    break;
                                }
                                else if(!task.description){
                                    error = true;
                                    break;
                                }
                                else if(response.test.prototype_test_setting.objective=="closed" && !task.goal_screen_node_id){
                                    error = true;
                                    break;
                                }
                                else if(response.test.prototype_test_setting.objective=="open" && task.questions.length==0){
                                    error = true; 
                                    break;
                                }
                                else if(response.test.prototype_test_setting.objective=="open" && task.questions.length>0){
                                    var no_of_optional = 0;
                    
                                    task.questions.forEach(function(item){
                                        if(item.is_optional){
                                            no_of_optional++;
                                        }
                                    })
                                    if(no_of_optional==task.questions.length){
                                        error=true;
                                        break;
                                    }
                                    
                                }
                            }
                            if(error){
                                showError("You missed some key information. Please check again and fill out any missing fields.");
                                navigate("/r/test/" + test_id + "/prototype-test?error=true");
                            }

                        } else if(response.test.methodology=="First click"){

                            var error = false;

                            for(var i=0; i<response.test.firstclick_test_tasks.length; i++){
			
                                var task = response.test.firstclick_test_tasks[i];
                    
                                
                                if(!task.description){
                                    error = true;
                                    break;
                                }
                                else if(!task.design_file){
                                    error = true;
                                    break;
                                }
                            
                            }
                            if(error){
                                showError("You missed some key information. Please check again and fill out any missing fields.");
                                navigate("/r/test/" + test_id + "/first-click?error=true");
                            }

                        }
                    }
                }

                setTest(response.test);
                setLastSaved(moment(response.test.updated_at));
                const {
                    thankyou_title,
                    thankyou_title_arabic,
                    thankyou_description,
                    thankyou_description_arabic,
                    language,
                    test_name,
                    conclusion_image,
                } = response.test;

                setFormValues((oldValues) => ({
                    ...oldValues,
                    thankyou_title: thankyou_title,
                    thankyou_description: thankyou_description,
                }));

                setLanguage(language);
                setThankyouImage(conclusion_image);
                if (searchParams.get("error") == "true") {
                validateFullForm(thankyou_title,thankyou_description,false);
                }
            } else {
                //   setErrorMessage(response.setErrorMessage);
            }
        });
    };

    const validateFullForm = (thankyou_title,thankyou_description,showToast) => {
        var error = false;
        var form_errors = resetFormErrors();
        var firsterrorid = null;

        if (thankyou_title == '') {

            form_errors = {
                ...form_errors,
                thankyou_title: "A page title is required",
                error_class: "input_error"
            };
            error = true;
            firsterrorid = firsterrorid == null ? "title" : firsterrorid;

            setFormErrors(form_errors);
            if(showToast){
            showError("The thank you page cannot be empty! Please enter a title and a description.");
            }
        }
        if(thankyou_description == ''){
            form_errors = {
                ...form_errors,
                thankyou_description: "A thank you message is required",
                error_class: "input_error"
            };
            error = true;
            firsterrorid = firsterrorid == null ? "message" : firsterrorid;

            setFormErrors(form_errors);
            if(showToast){
                showError("The thank you page cannot be empty! Please enter a title and a description.");
            }
        }
        if(error){
            localStorage.setItem("stepper_error", "The thank you page cannot be empty! Please enter a title and a description.");
            setStepperError(true);
            document.getElementById(firsterrorid).scrollIntoView({
                behavior: "smooth",
                block: "center",
            });
        }
        
        
        return error;
        
    }

    useEffect(() => {
        document.title = process.env.REACT_APP_NAME + " - Summary";
        getTest();
        if (searchParams.get("error") == "true") {
            setStepperError(true);
        }
    }, []);

    const saveConclusionData = () => {
        const token = ReactSession.get("token");


        var data = new FormData();
        setIsSaved({ 'status': 'loading', 'message': '' });
        data.append("test_id", test_id);
        data.append("thankyou_title", formValues.thankyou_title);
        data.append("thankyou_description", formValues.thankyou_description);

        if (image) {
            data.append("conclusion_image", image);
        }
        if (imageDeleted) {
            data.append("imageDeleted", imageDeleted);
        }

        saveConclusion(data, token).then((response) => {
            if (response.success) {
                setIsSaved({ 'status': 'success', 'message': '' });
            } else {
                setIsSaved({ 'status': 'error', 'message': response.message });
            }
        });
    };

    useEffect(() => {
        if (test.status === "draft") {
            saveConclusionData();
        }

    }, [image, imageDeleted]);

    const conclusionSubmitHandler = (saveUsingNavigation) => {
        var error=false
        if (!saveUsingNavigation) {
        error = validateFullForm(formValues.thankyou_title,formValues.thankyou_description,true);
        }
        if (!error) {
        const token = ReactSession.get("token");
        setFormLoading(true);
        setIsSaved({ 'status': 'loading', 'message': '' });
        var data = new FormData();

        data.append("test_id", test_id);
        data.append("language", language);
        data.append("thankyou_title", formValues.thankyou_title);
        data.append("thankyou_description", formValues.thankyou_description);


        if (image) {
            data.append("conclusion_image", image);
        }
        if (imageDeleted) {
            data.append("imageDeleted", imageDeleted);
        }
        if (!saveUsingNavigation) {
            data.append("saved_step", 3);
        }

        saveConclusion(data, token).then((response) => {
            setFormLoading(false);
            if (response.success) {
                setIsSaved({ 'status': 'success', 'message': '' });
                if (!saveUsingNavigation) {
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        'event' : 'create_test_step3',
                        'type': test.methodology,
                        'userID' : test.user_id
                    });
                    navigate("/r/test/" + test_id + "/recruit");
                }
            } else {
                setIsSaved({ 'status': 'error', 'message': response.message });
                if (response.message == "The image must be an image.") {
                    setImage(null);
                    setPreviewUrl("");
                    setFormErrors({ ...formErrors, image: "The image must be an image.", "error_class": "input_error" });
                } else {
                    showError(response.message);
                }
            }
        });
        }
    };

    const backPath = (test) => {
        if (test.methodology == "Preference Test") {
            return "/r/test/" + test_id + "/preference-test";
        }
        if (test.methodology == "Card Sorting") {
            return "/r/test/" + test_id + "/card-sorting-test";
        }
        if (test.methodology == "Five Seconds Test") {
            return "/r/test/" + test_id + "/five-seconds-test";
        }
        if (test.methodology == "Survey") {
            return "/r/test/" + test_id + "/survey";
        }
        if (test.methodology == "Prototype test") {
            return "/r/test/" + test_id + "/prototype-test";
        }
        return "/r/test/" + test_id + "/tree-test";
    };

    const validatelogic = (surveyQuestions) => {
        // console.log(surveyQuestions);
        // console.log("validatelogic");
        var error=false;
        surveyQuestions.forEach((question,index) => {
            if (JSON.parse(question).questionType != "singlechoice") {
                if(JSON.parse(question).is_logic==1 && JSON.parse(question).jump_to_question<index+2){
                    
                    error=true;
                }
                if(JSON.parse(question).is_logic==1 && JSON.parse(question).jump_to_question=="End survey"){
                    error=false;
                }
                //
                if(JSON.parse(question).error && JSON.parse(question).error==1){
                    // console.log(JSON.parse(question));
                    // console.log("logictobeupdated");
                    error=true;
                }
            }else{
                if(JSON.parse(question).is_logic==1){
                    JSON.parse(question).jumpTo.forEach((jumptoOption,i)=>{
                        if(jumptoOption!="End survey" && jumptoOption<index+2){
                            // console.log(JSON.parse(question));
                            // console.log("jumptoless");
                            error=true;
                        }
                        //
                        if(JSON.parse(question).error && JSON.parse(question).error[i]==1){
                            // console.log(JSON.parse(question));
                            // console.log("logictobeupdated");
                            error=true;
                        }
                    })
                }
            }
        }
        );

        if(error==true){
            showError(
                "Update the correct logic to proceed further."
            );
            navigate(
                "/r/test/" + test_id + "/survey?error=true"
            );
        }
    }


    return (
        <LayoutResearcher wrapClass={"create-test-data-wrapper"} openSupportPanel={show_support_panel} hideSupportPanel={()=>{setShowSupportPanel(false)}}>
            <Helmet>
                <title>Create {test && test.methodology ? renderTestTitleCreate(test.methodology):"test"}  - step 3  | UserQ</title>
            </Helmet>
            <div className="three-column-layout">
                <Header
                    test={test}
                    isLoading={isLoading}
                    isSaved={isSaved}
                />
                <div className="three-column-layout-body">
                    <div className="three-column-layout-left">
                        <Stepper
                            test={test}
                            completed_step={2}
                            stepCallback={() => {
                                conclusionSubmitHandler(true);
                            }}
                            error={stepperError}
                        />
                    </div>
                    {isLoading &&
                        <div className="test-section-loader"><LoadingIcon /></div>
                    }
                    {!isLoading && (
                        <>
                            <div className="three-column-layout-center">
                                <div class="test-welcome-page-wrap">
                                    <div class={`test-welcome-inner-data-hold ${test.language == "ar" ? "arabic_wrapper" : ""}`}>
                                        <div class="test-welcome-page-left">
                                            <div class="test-welcome-top-head">
                                                <Text type='subtitle-1' fontWeight='medium' children="Thank you page" />
                                                <Text type='body-text-2' fontWeight='medium' children="You can create a customised message to thank your participants." />
                                            </div>
                                            <div class="test-welcome-form-wrap">
                                                <Input
                                                    label="Page title"
                                                    onChange={set("thankyou_title")}
                                                    onClear={() => {
                                                        setTimeout(() => {
                                                            setFormValues((oldValues) => ({
                                                                ...oldValues,
                                                                thankyou_title: "",
                                                            }));
                                                        }, 1000);
                                                    }}
                                                    value={formValues.thankyou_title}
                                                    maxLength="45"
                                                    error={formErrors.thankyou_title}
                                                    error_class={formErrors.error_class}
                                                    id="title"
                                                    clearField={false}
                                                    required={true}
                                                    onBlur={() => saveConclusionData()}
                                                    rtl={test.language == "ar" ? true : false}
                                                />
                                                <TextArea
                                                    id={"message"}
                                                    autosize={true}
                                                    value={formValues.thankyou_description}
                                                    onChange={set("thankyou_description")}
                                                    maxLength="300"
                                                    label="Thank you message"
                                                    language={language}
                                                    onClear={() => {
                                                        setTimeout(() => {
                                                            setFormValues((oldValues) => ({
                                                                ...oldValues,
                                                                thankyou_description: "",
                                                            }));
                                                        }, 1000);
                                                    }}
                                                    onBlur={() => saveConclusionData()}
                                                    labelfont="english-font"
                                                    wrapClass="tester-text-area"
                                                    error={formErrors.thankyou_description}
                                                    error_class={formErrors.error_class}
                                                />
                                            </div>
                                        </div>
                                        <div class="test-welcome-right">
                                            <div class="test-welcome-image-wrap">
                                                <div
                                                    className={`project-modal-img`}
                                                >

                                                    {!previewUrl &&
                                                        (thankyouImage ? (
                                                            <>
                                                                <div className="uploaded-project-img">
                                                                    <img
                                                                        src={
                                                                            process.env.REACT_APP_IMG_URL + thankyouImage
                                                                        }
                                                                        alt="img"
                                                                    />
                                                                </div>
                                                                <div className="remove-img"
                                                                    onClick={removeImage}>
                                                                    Remove Image
                                                                </div>
                                                            </>
                                                        ) : (

                                                            <div
                                                                className="upload-project-img"
                                                                onDragOver={handleOnDragOver}
                                                                onDrop={handleOnDrop}
                                                            >
                                                                <img
                                                                    className="upload-image-placeholder"
                                                                    src={
                                                                        process.env.REACT_APP_URL +
                                                                        "img/welcome-img.svg"
                                                                    }
                                                                    alt="img"
                                                                />


                                                            </div>
                                                        ))}
                                                    {previewUrl && (
                                                        <>
                                                            <div className="uploaded-project-img">
                                                                <img src={previewUrl} alt="img" />
                                                            </div>
                                                            <div className="remove-img"
                                                                onClick={removeImage}>
                                                                Remove Image
                                                            </div>
                                                        </>
                                                    )}

                                                    {formErrors.image != null && (
                                                        <span className={formErrors.error_class}>
                                                            {formErrors.image}
                                                        </span>
                                                    )}
                                                </div>
                                                <div class="change-welcome-image">
                                                    {/* <span class="change-image-link">Change photo
                                                        <input
                                                            type="file"
                                                            onChange={handleFileInput}
                                                            onClick={(event)=> { 
                                                                event.target.value = null
                                                           }}
                                                        /></span> */}

                                                    <label class="label upload-image-label">
                                                        <span class="change-image-link">
                                                            <input
                                                                type="file"
                                                                class="default-file-input"
                                                                onChange={handleFileInput}
                                                                onClick={(event) => {
                                                                    event.target.value = null
                                                                }}
                                                                style={{ display: "none" }}
                                                            />
                                                            <span class="browse-files-text">Change photo</span>
                                                        </span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="btns-wrap-bottom">
                                        <Button
                                            onClick={() => { navigate(backPath(test)); }}
                                            type={"ghost"}
                                            label={"Previous"}
                                            size={"large"}
                                            iconLeft={
                                                <Icon value="back-arrow" colorClass="gray-900-svg" size={"large"} />
                                            }
                                        />
                                        <Button
                                            onClick={() => conclusionSubmitHandler(false)}
                                            iconRight={<Icon value="forward-arrow" colorClass="gray-50-svg" />}
                                            label="Next"
                                            size={"large"}
                                            state={(formValues.thankyou_title==""||formValues.thankyou_description=="")?"disabled":""}
                                        />
                                    </div>


                                </div>

                            </div>
                            <div className="three-column-layout-right">

                                <div class="welcome-test-preview-area">
                                    <Text
                                        type={'overline'}
                                        children={'Preview'}
                                    />
                                    <div className="welcome-test-preview-area-inner">
                                        {test.language == "ar" ?
                                            <img src={
                                                process.env.REACT_APP_URL + "img/thankyoupreviewarabic.svg"}
                                                alt="img" /> : <img src={
                                                    process.env.REACT_APP_URL + "img/thankyoupreview.svg"}
                                                    alt="img" />}
                                    </div>
                                </div>
                                <a onClick={(e)=>{
                                    e.preventDefault();

                                    setShowSupportPanel(true);

                                }} href={process.env.REACT_APP_URL+"r/support"} target="_blank" className='support-button'><svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.879 8.2817C11.05 7.2567 12.95 7.2567 14.121 8.2817C15.293 9.3067 15.293 10.9687 14.121 11.9937C13.918 12.1727 13.691 12.3197 13.451 12.4357C12.706 12.7967 12.001 13.4347 12.001 14.2627V15.0127M21 12.7627C21 13.9446 20.7672 15.1149 20.3149 16.2068C19.8626 17.2988 19.1997 18.2909 18.364 19.1267C17.5282 19.9624 16.5361 20.6253 15.4442 21.0776C14.3522 21.5299 13.1819 21.7627 12 21.7627C10.8181 21.7627 9.64778 21.5299 8.55585 21.0776C7.46392 20.6253 6.47177 19.9624 5.63604 19.1267C4.80031 18.2909 4.13738 17.2988 3.68508 16.2068C3.23279 15.1149 3 13.9446 3 12.7627C3 10.3757 3.94821 8.08656 5.63604 6.39873C7.32387 4.71091 9.61305 3.7627 12 3.7627C14.3869 3.7627 16.6761 4.71091 18.364 6.39873C20.0518 8.08656 21 10.3757 21 12.7627ZM12 18.0127H12.008V18.0207H12V18.0127Z" stroke="#9759CB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                                    Support
                                </a>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </LayoutResearcher>
    );
}
