import { useNavigate, useParams, Link,useSearchParams } from 'react-router-dom';
import { ReactSession } from "../../../lib/secure_reactsession.js";
import React, { useEffect, useRef, useState } from "react";
import {
    getTestData,
    importCardSortingCardCSVService,
    importCardSortingCategoryCSVService, saveCardSortingTestService
} from "../../../services/test";
import moment from "moment";
import toast from "react-hot-toast";
import { LayoutResearcher } from './../../themes/userq/Layouts/layout_researcher';
import { Stepper } from './../../themes/userq/Stepper/Stepper';
import { Button } from './../../themes/userq/Button/Button';
import { LoadingIcon } from "./../../themes/userq/Loader/loadingIcon";
import { Accordian } from './../../themes/userq/Accordion/Accordion';
import { Icon } from './../../themes/userq/Icon/Icon';
import Header from "./components/header";
import { Text } from "../../themes/userq/Text/Text";
import { RadioButton } from "../../themes/userq/RadioButton/RadioButton";
import { ToggleButton } from "../../themes/userq/ToggleButton/ToggleButton";
import { Chip } from "../../themes/userq/Chips/Chips";
import Radiobtn from "../../layouts/elements/fields/radiobtn";
import { Input } from "../../themes/userq/Input/Input";
import InputField from "../../layouts/elements/fields/input";
import {encryptId, maxLengthCheck} from "../../../lib/helpers";
import { FormattedMessage } from "react-intl";
import ConfirmationModal from "../../themes/userq/Modal/ConfirmationModal";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { ToastMessage } from "../../themes/userq/ToastMessage/ToastMessage";
import { IntroductionQuestions } from "./introduction_questions.js";
import { ConclusionQuestions } from './conclusion_questions.js';
import { settings } from '../../../data/settings.js';
import {Tooltip} from "../../themes/userq/Tooltip/Tooltip";
import { isEmpty } from "lodash";
import AutosizeInput from 'react-input-autosize';
import { GuideLink } from '../../themes/userq/GuideLink/GuideLink.js';
import { Helmet } from 'react-helmet';
import { InputPlaceholder } from '../../themes/userq/Input/InputPlaceholder.js';
import { useTestBuilderData } from "./contexts/TestBuilderContext.js";

export default function CardSortingTest() {
    let { test_id } = useParams();
    const navigate = useNavigate();
    const fileRef = useRef(null);
    const inputref = useRef(null);
    const dataFetchedRef = useRef(false);

    const introductionQuestionRef = useRef(null); // introduction ref
    const conclusionQuestionRef = useRef(null); // conclusion ref

    const ref = useRef();
    const tipref = useRef();

    const refs = useRef([]);
    const categoryInputrefs = useRef([]);

    ReactSession.setStoreType("localStorage");

    const { updateTestLength, getTestLength } = useTestBuilderData();

    const queryAttr = "data-rbd-drag-handle-draggable-id";

    const [isLoading, setLoading] = useState(false);
    const [lastSaved, setLastSaved] = useState(null);
    const [pageLoading, setPageLoading] = useState(false);
    const [language, setLanguage] = useState("en");
    const [credits, setCredits] = useState(0);
    const [searchParams] = useSearchParams();
    const [accordians, setAccordians] = useState({ 'introduction': false, cardsorting: searchParams.get("error") == "true"?true:false, conclusion: false })

    const [formValues, setFormValues] = useState({ card_sorting_id: "", cardSorting: 'open', isRequiredCategories: 0, isAddNewCategory: 0,is_randomize:0, loading: false });
    const [formError, setFormError] = useState({ title:'',category: '', card: "", error_class: "" });


    const [cardTitle,setCardTitle] = useState('');
    const [formCustomField, setFormCustomField] = useState({ category: '', card: "" });
    const [initialLoadingApi, setInitialLoadingApi] = useState(false);
    const [isSaved, setIsSaved] = useState({ 'status': '', 'message': '' });
    const [cardArray, setCardArray] = useState([]);
    const [categoryArray, setCategoryArray] = useState([]);
    const [confirmModal, setConfirmModal] = useState({
        open: false
    });
    const [confirmationBtnLoading, setConfirmationBtnLoading] = useState(false);
    const [editCategoryIndex, setEditCategoryIndex] = useState(null);

    const [test, setTest] = useState("");
    const [isTyping, setTyping] = useState(false);
    const [focusCategoryId, setFocusCategoryId] = useState('');

    const [stepperError, setStepperError] = useState(false);
    
    const [introCount, setIntroCount] = useState(0);
    const [concCount, setConcCount] = useState(0);
    const [introductionQuestions, setIntroductionQuestions] = useState(null);
    const [conclusionQuestions, setConclusionQuestions] = useState(null);

    const [placeholderProps, setPlaceholderProps] = useState({});

    const [show_support_panel, setShowSupportPanel] = useState(false);

    const showError = (error) => {
        toast(
            <ToastMessage type={"error"} message={error} closable={false} onClose={() => { toast.dismiss(); }} />,
            {
                id:"error",
                className: "errortoast",
                position: "bottom-center",
                duration: settings.toast_duration,
            }
        );
    };

    const [tooltip, showTooltip] = useState({
        tooltipLeft:0,
        tooltipTop:0,
        tooltipData:"",
        open: false,
        show:false
    });

    const handlePointerMove = (data,currentEle) => {


        return (
            (event) => {


                let tipPosition = "bottom";

                var tool_width = tipref && tipref.current ?  tipref.current.clientWidth : 260;


                var tool_height =  tipref && tipref.current ?  tipref.current.clientHeight + 12 : 800;



                let containerX = ('clientX' in event ? event.clientX : 0) - ref.current.getBoundingClientRect().left - tool_width/2;
                let containerY = ('clientY' in event ? event.clientY : 0)  - ref.current.getBoundingClientRect().top - tool_height - 10 ;


                var window_width = window.innerWidth;

                var window_height = window.innerHeight;

                if(event.clientX + tool_width/2 > window_width){
                    containerX = ('clientX' in event ? event.clientX : 0) - ref.current.getBoundingClientRect().left - tool_width/2 - (event.clientX + tool_width/2 - window_width) ;

                }
                if(event.clientX - tool_width/2 < 0){
                    containerX = ('clientX' in event ? event.clientX : 0) - ref.current.getBoundingClientRect().left - tool_width/2 + (tool_width/2 - event.clientX) ;
                }

                // check if content is more then tooltip is shown
                if(currentEle.scrollWidth > currentEle.clientWidth){
                    showTooltip({
                        tooltipLeft: containerX,
                        tooltipTop: containerY,
                        tooltipData: data,
                        open:true,
                        show: tooltip.open && tipref && tipref.current ? true: false,
                        tipPostion: tipPosition
                    });
                }

            });
    }

    const showSuccess = (success) => {
        toast(
            <ToastMessage type={"success"} message={success} closable={true} onClose={() => { toast.dismiss(); }} />,
            {
                className: "successtoast",
                position: "bottom-center",
                duration: settings.toast_duration,
            }
        );
    };

    const getTestApi = () => {
        let token = ReactSession.get("token");

        setLoading(true);

        getTestData({ test_id: test_id }, token).then((response) => {
            setLoading(false);

            if (response.success) {

                getTestLength(test_id);

                if (response.test.status == "published") {
                    navigate("/r/project/" + response.test.project_id);
                }

                if (response.test.methodology !== 'Card Sorting') {
                    navigate("/r/test/" + test_id + "/welcome");
                }

                if (
                    response.test.welcome_title == "" ||
                    response.test.welcome_description == ""
                ) {
                    localStorage.setItem("stepper_error", "The welcome page cannot be empty! Please enter a title and a description.");
                    navigate("/r/test/" + test_id + "/welcome?error=true");
                    showError("The welcome page cannot be empty! Please enter a title and a description.");
                    
                }

                setTest(response.test);
                setLanguage(response.test.language);
                setLastSaved(moment(response.test.updated_at));
                setCredits(response.settings.publishing_credits);
                setIntroCount(response.test.briefingQuestion.length);
                setConcCount(response.test.debriefingQuestion.length);
                setIntroductionQuestions(response.test.briefingQuestion);
                setConclusionQuestions(response.test.debriefingQuestion);

                if (response.test.card_sorting) {
                    setFormValues({ title:response.test.card_sorting.title,card_sorting_id: response.test.card_sorting.id, 'cardSorting': response.test.card_sorting.card_sorting, 'isRequiredCategories': response.test.card_sorting.is_required_categories, 'isAddNewCategory': response.test.card_sorting.is_add_new_category,is_randomize:response.test.card_sorting.is_randomize });
                    setCardTitle(response.test.card_sorting.title);
                }

                if (response.test.card_sorting_card) {
                    setCardArray(response.test.card_sorting_card);
                }

                if (response.test.card_sorting_category) {

                    var CategoryArrayData = [];
                    var CategoryArray = response.test.card_sorting_category.forEach((items)=>{
                        CategoryArrayData.push({...items,uniqueId:Date.now()+Math.floor((Math.random() * 100000) + 1),isEdit:false,isError:''})
                    })

                    setCategoryArray(CategoryArrayData);
                }

                //console.log(response.test.card_sorting)
                // check error when come from next step
                // if(searchParams.get("error")=="true") {
                //     if (response.test.card_sorting_card.length === 0) {
                //         document.getElementById("card-section").scrollIntoView({
                //             behavior: "smooth",
                //             block: "center",
                //         });
                //
                //         setFormError({
                //             ...formError,
                //             'category': '',
                //             'card': 'Looks like you have not created card for this test! Please add a card to move to the next step.',
                //             error_class: "input_error"
                //         });
                //
                //     } else if (response.test.card_sorting_card.length < 2) {
                //         document.getElementById("card-section").scrollIntoView({
                //             behavior: "smooth",
                //             block: "center",
                //         });
                //
                //         setFormError({
                //             ...formError,
                //             'category': '',
                //             'card': 'You need to add a minimum of two cards.',
                //             error_class: "input_error"
                //         });
                //
                //     } else if (response.test.card_sorting_category.length < 2 && response.test.card_sorting && response.test.card_sorting.card_sorting === 'close') {
                //         document.getElementById("category-section").scrollIntoView({
                //             behavior: "smooth",
                //             block: "center",
                //         });
                //
                //         setFormError({
                //             ...formError,
                //             'category': "Looks like you haven't created enough categories (at least 2 are required) for this test! Please add a category to move to the next steps.",
                //             'card': '',
                //             error_class: "input_error"
                //         });
                //
                //     }
                // }
                if (searchParams.get("error") == "true") {
                validate(response.test.card_sorting?response.test.card_sorting.title:'',CategoryArrayData,response.test.card_sorting?response.test.card_sorting.card_sorting:"open");
                }
                setInitialLoadingApi(true);
            } else {
                showError(response.message);
            }
        });
    };

    const validate=(title,categoryArray,cardSorting)=>{
        if (!title) {
            if(document.getElementById("title-section")){
                document.getElementById("title-section").scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                });
            }
            setFormError({
                ...formError,
                'title':"A task description is required",
                error_class:"input_error"
            });

             setIsSaved({ 'status': 'error', 'message': "You missed some key information. Please check again and fill out any missing fields." });

        } else if (categoryArray.length < 2 && cardSorting === 'close') {
            if(document.getElementById("category-section")){
            document.getElementById("category-section").scrollIntoView({
                behavior: "smooth",
                block: "center",
            });
            }
            setFormError({
                ...formError,
                'category':"You need to add a minimum of two categories.",
                'card':'',
                error_class:"input_error"
            });

            setIsSaved({ 'status': 'error', 'message': "Looks like you haven't created enough categories for this test. Please add at least two categories to move to the next steps." });
            // showError("Looks like you haven't created enough categories for this test. Please add at least two categories to move to the next steps.");

        }
        else if (cardArray.length === 0) {
            if(document.getElementById("card-section")){
            document.getElementById("card-section").scrollIntoView({
                behavior: "smooth",
                block: "center",
            });
            }
            setFormError({
                ...formError,
                'category':'',
                'card':'You need to add a minimum of two cards.',
                error_class:"input_error"
            });

            setIsSaved({ 'status': 'error', 'message': "You need to add a minimum of two cards." });
            // showError("Looks like you haven't created enough cards for this test. Please add at least two cards to move to the next steps.");

        }else if (cardArray.length < 2) {
            if(document.getElementById("card-section")){
            document.getElementById("card-section").scrollIntoView({
                behavior: "smooth",
                block: "center",
            });
            }
            setFormError({
                ...formError,
                'category':'',
                'card':'You need to add a minimum of two cards.',
                error_class:"input_error"
            });

            setIsSaved({ 'status': 'error', 'message': "You need to add a minimum of two cards." });
            // showError("You need to add a minimum of two cards.");

        }
    }

    useEffect(() => {
        //document.title = process.env.REACT_APP_NAME + " - Card sorting";

        getTestApi();

        if(searchParams.get("error")=="true"){
            setStepperError(true);
        }


    }, []);

    useEffect(() => {

        if (initialLoadingApi) {
            autosaveData(false)
        }


    }, [formValues, cardArray, categoryArray]);

    useEffect(()=>{

        //console.log('focusCategoryId')
         //console.log(categoryInputrefs.current[focusCategoryId])
        if(categoryInputrefs.current[focusCategoryId]){
           // console.log('ads')
            categoryInputrefs.current[focusCategoryId].focus()
        }
    },[focusCategoryId,isTyping])

    // useEffect(() => {
    //
    //     if (formCustomField) {
    //         if (inputref.current) {
    //             inputref.current.focus()
    //         }
    //     }
    // }, [formCustomField]);

    const autosaveData = (shouldNavigate) => {

        if (dataFetchedRef.current) {
            dataFetchedRef.current.abort();

        }

        // if user is typing then no data will be save
        if(isTyping){
            return false;
        }
        setTyping(false); // set typing false
        const controller = new AbortController();

        dataFetchedRef.current = controller;

        const token = ReactSession.get("token");
        let data = Object.assign({}, '');

        data["test_id"] = test_id;
        data["card_sorting"] = formValues.cardSorting;
        data["title"] = formValues.title;
        data["card_sorting_id"] = formValues.card_sorting_id;
        data["is_required_categories"] = formValues.isRequiredCategories;
        data["is_add_new_category"] = formValues.isAddNewCategory;
        data["is_randomize"] = formValues.is_randomize;
        data["cards"] = cardArray;
        data["category"] = categoryArray;

        data["autosave"] = 1;

        setIsSaved({ 'status': 'loading', 'message': '' });


        saveCardSortingTestService(data, token,dataFetchedRef.current?.signal).then((response) => {

            if(response){
            if (response.success) {

                getTestLength(test_id);

                setIsSaved({ 'status': 'success', 'message': '' });

                // navigate to next steps
                if (shouldNavigate) {

                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        'event' : 'create_test_step2',
                        'type': test.methodology,
                        'userID' : ReactSession.get("user") ? ReactSession.get("user").user_id : ""
                    });
                    navigate("/r/test/" + test_id + "/thank-you");
                }
            } else {
                setIsSaved({ 'status': 'error', 'message': response.message });
                showError(response.message);
            }
        }
        });
    }

    // add new category
    const addCategory = (e) => {
        
        e.preventDefault();
        setTyping(false); // set typing false

        // setFormError({
        //     ...formError,
        //     category:'',
        //     error_class:""
        // })

        // check value is not blank
        const value = formCustomField.category.trim();
        if (value) {

            if(categoryArray.length < 50) {
                // check value is already exists or not
                const checkCategory = categoryArray.findIndex(item => item.name === value);

                // check edit category exits
                const checkEditCategoryIndex = categoryArray.findIndex((item, index) => item.uniqueId === editCategoryIndex);
                // console.log(checkEditCategoryIndex)
                if (editCategoryIndex !== null && checkEditCategoryIndex !== -1) {
                    // console.log('khkjhkj')
                    const checkCategoryExistingArray = categoryArray.find((item, index) => (item.name === value));

                    //  console.log(checkCategoryExistingArray)
                    if (!checkCategoryExistingArray || (checkCategoryExistingArray && checkCategoryExistingArray.uniqueId === editCategoryIndex)) {
                        const newCategoryArray = categoryArray.map((p, index) =>
                            p.uniqueId === editCategoryIndex
                                ? {...p, name: value}
                                : p
                        );

                        setCategoryArray(newCategoryArray);
                        setEditCategoryIndex(null);
                        setFormCustomField({...formCustomField, "category": ''});
                    } else {
                        setFormError({
                            ...formError,
                            category: 'A category with the same name already exists.',
                            error_class: "input_error"
                        })
                        //setIsSaved({ 'status': 'error', 'message': 'A category with the same name already exists.' });
                        showError('A category with the same name already exists.');

                    }


                } else {
                    if (checkCategory === -1) {
                        setFormCustomField({...formCustomField, "category": ''});
                        setCategoryArray(categoryArray.concat({
                            'id': '',
                            'name': value,
                            uniqueId: Date.now() + Math.floor((Math.random() * 100000) + 1),
                            isEdit: false,
                            isError: ''
                        }));
                        //removing errors after two
                        if (categoryArray.length > 0) {
                            setFormError({
                                ...formError,
                                category: '',
                                error_class: ""
                            })
                            setStepperError(false);
                        }
                    } else {
                        setFormError({
                            ...formError,
                            category: 'A category with the same name already exists.',
                            error_class: "input_error"
                        })
                        //setIsSaved({ 'status': 'error', 'message': 'A category with the same name already exists.' });
                        showError('A category with the same name already exists.');
                    }
                }

            }else{
                showError('You\'ve reached the maximum number of categories. If you wish to add more, please consider removing some first.');
            }

            //setEditCategoryIndex(null);

        }else{
            // check edit category exits
            const checkEditCategoryIndex = categoryArray.findIndex((item, index) => item.uniqueId === editCategoryIndex);
            // console.log(checkEditCategoryIndex)
            if (editCategoryIndex !== null && checkEditCategoryIndex !== -1) {

                const newCategoryArray = categoryArray.filter((item, indexItems) => item.uniqueId !== editCategoryIndex);
                //console.log(newCategoryArray)
                setCategoryArray(newCategoryArray);

                setEditCategoryIndex(null);
                setFormError({...formError,'category':'','error_class':''});
                setFormCustomField({ ...formCustomField, category: '' })
            }
        }
        if(e.relatedTarget.classList.contains('btn')){
            console.log(value);
            if(value!='' && categoryArray.length==0){
                document.getElementById("category-section").scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                });
                setFormError({
                    ...formError,
                    'category':"You need to add a minimum of two categories.",
                    'card':'',
                    error_class:"input_error"
                });
                setStepperError(true);
                setIsSaved({ 'status': 'error', 'message': "Looks like you haven't created enough categories for this test. Please add at least two categories to move to the next steps." });
                showError("Looks like you haven't created enough categories for this test. Please add at least two categories to move to the next steps.");
                localStorage.setItem("stepper_error", "Looks like you haven't created enough categories for this test. Please add at least two categories to move to the next steps.");
            }
            else if(value!='' && categoryArray.length==1 && cardArray.length<2){
                if(document.getElementById("card-section")){
                    document.getElementById("card-section").scrollIntoView({
                        behavior: "smooth",
                        block: "center",
                    });
                }
                setFormError({
                    ...formError,
                    'category':'',
                    'card':'You need to add a minimum of two cards.',
                    error_class:"input_error"
                });
                setStepperError(true);
                setIsSaved({ 'status': 'error', 'message': "You need to add a minimum of two cards." });
                showError("Looks like you haven't created enough cards for this test. Please add at least two cards to move to the next steps.");
                localStorage.setItem("stepper_error", "Looks like you haven't created enough cards for this test. Please add at least two cards to move to the next steps.");
            }
            // handleNextStep();
        }
    }

    // add new card
    const addCard = (e) => {
        e.preventDefault();
        setTyping(false); // set typing false

        // check value is not blank
        const value = formCustomField.card.trim();
        if (value) {

            if(cardArray.length < 100) {

                // check value is already exists or not
                const checkCard = cardArray.findIndex(item => item.name === value);
                if (checkCard === -1) {
                    setFormCustomField({...formCustomField, "card": ''});
                    setCardArray(cardArray.concat({'id': '', 'name': value}));
                    if (cardArray.length > 0) {
                        setFormError({
                            ...formError,
                            card: '',
                            error_class: ""
                        })
                        setStepperError(false);
                    }
                } else {
                    //setIsSaved({ 'status': 'error', 'message': 'A card with the same name already exists.' });
                    setFormError({
                        ...formError,
                        card: 'A card with the same name already exists.',
                        error_class: "input_error"
                    })
                    showError('A card with the same name already exists.');
                }
            }else{
                showError('You\'ve reached the maximum number of cards. If you wish to add more, please consider removing some first.');
            }

        }
        if(e.relatedTarget.classList.contains('btn')){
            if(value!='' && cardArray.length==0){
                if(document.getElementById("card-section")){
                    document.getElementById("card-section").scrollIntoView({
                        behavior: "smooth",
                        block: "center",
                    });
                }
                setFormError({
                    ...formError,
                    'category':'',
                    'card':'You need to add a minimum of two cards.',
                    error_class:"input_error"
                });
                setStepperError(true);
                setIsSaved({ 'status': 'error', 'message': "You need to add a minimum of two cards." });
                showError("Looks like you haven't created enough cards for this test. Please add at least two cards to move to the next steps.");
                localStorage.setItem("stepper_error", "Looks like you haven't created enough cards for this test. Please add at least two cards to move to the next steps.");
            }
        }
    }

    const handleFileInput = (event, type) => {
        //prevent the browser from opening the image
        event.preventDefault();
        event.stopPropagation();
        //let's grab the image file
        let csvFile = event.target.files[0];

        //console.log(event)
        //console.log(type)
        if (type === 'card') {
            //setImportCardModal({ open: true, csvFile: csvFile });

            setConfirmModal({
                open: true,
                confirm_btn_title: "Yes",
                confirm_title: "Import CSV",
                confirm_message:
                    "Are you sure you want to import this file? This will remove all the cards that you've added.",
                confirmation_for: "importCard",
                csvFile: csvFile
            });
            if(cardArray.length === 0){
                setConfirmationBtnLoading(true);
                importCSVApi(csvFile, 'card');
                fileRef.current.value = "";
                setFormError({...formError,'card':'','error_class':''});
                setFormCustomField({ ...formCustomField, card: '' })
            }

        } else if (type === 'category') {
            //  setImportModal({ open: true, csvFile: csvFile });
            setConfirmModal({
                open: true,
                confirm_btn_title: "Yes",
                confirm_title: "Import CSV",
                confirm_message:
                    "Are you sure you want to import this file? This will remove all the categories that you've added.",
                confirmation_for: "importCategory",
                csvFile: csvFile
            });

            if(categoryArray.length === 0){
                setConfirmationBtnLoading(true);
                importCSVApi(csvFile, 'category');
                fileRef.current.value = "";
                setEditCategoryIndex(null);
                setFormError({...formError,'category':'','error_class':''});
                setFormCustomField({ ...formCustomField, category: '' })
            }
        }

    };


    const importCSVApi = (csvFile, type) => {
        let token = ReactSession.get("token");

        var data = new FormData();

        data.append("file", csvFile);

        data.append("test_id", test_id);
        data.append("card_sorting_id", formValues.card_sorting_id);
        data.append("card_sorting", formValues.cardSorting);
        data.append("is_required_categories", formValues.isRequiredCategories);
        data.append("is_add_new_category", formValues.isAddNewCategory);


        if (type === 'card') {
            setConfirmationBtnLoading(true);

            importCardSortingCardCSVService(data, token).then((response) => {
                setConfirmationBtnLoading(false);
                setTyping(false); // set typing false
                setConfirmModal({ ...confirmModal, open: false });

                if (response.success) {

                    getTestLength(test_id);
                    
                    setCardArray(response.data.cards);

                    showSuccess(response.message);
                } else {
                    setIsSaved({ 'status': 'error', 'message': response.message });
                    showError(response.message);

                }
            });
        } else {
            setConfirmationBtnLoading(true);

            importCardSortingCategoryCSVService(data, token).then((response) => {
                setConfirmationBtnLoading(false);
                setTyping(false); // set typing false
                setConfirmModal({ ...confirmModal, open: false });

                if (response.success) {
                    //setCategoryArray(response.data.categories);

                    var CategoryArrayData = [];
                    var CategoryArray = response.data.categories.forEach((items)=>{
                        CategoryArrayData.push({...items,uniqueId:Date.now()+Math.floor((Math.random() * 100000) + 1),isEdit:false,isError:''})
                    })

                    setCategoryArray(CategoryArrayData);
                    setConfirmModal({ ...confirmModal, open: false });

                    showSuccess(response.message);
                } else {
                    setIsSaved({ 'status': 'error', 'message': response.message });
                    showError(response.message);
                }
            });
        }




    };

    // delete category
    const deleteCategory = (uniqueId) => {
        //console.log(uniqueId)
        //console.log(categoryArray)
        //return false
        setTyping(false); // set typing false
        const newCategoryArray = categoryArray.filter((item, indexItems) => item.uniqueId !== uniqueId);
        //console.log(newCategoryArray)
        setCategoryArray(newCategoryArray);
    }

    const handleNextStep = () => {

        var checkIntroductionQuestionIfErrorExits = true;

        //
        if(introductionQuestionRef.current){

            checkIntroductionQuestionIfErrorExits = introductionQuestionRef.current.questionValidateForm(true);
        }


        // check if any error exists in introduction question
        if(!checkIntroductionQuestionIfErrorExits && checkIntroductionQuestionIfErrorExits != undefined ){
            console.log('error exits intro')

            return false;
        }

          if(!formValues.title){

              document.getElementById("title-section").scrollIntoView({
                  behavior: "smooth",
                  block: "center",
              });
              setFormError({
                  ...formError,
                  'title':"A task description is required",
                  error_class:"input_error"
              });

          } else if (categoryArray.length < 2 && formValues.cardSorting === 'close') {
            document.getElementById("category-section").scrollIntoView({
                behavior: "smooth",
                block: "center",
            });
            setFormError({
                ...formError,
                'category':"You need to add a minimum of two categories.",
                'card':'',
                error_class:"input_error"
            });
            setStepperError(true);
            setIsSaved({ 'status': 'error', 'message': "Looks like you haven't created enough categories for this test. Please add at least two categories to move to the next steps." });
            showError("Looks like you haven't created enough categories for this test. Please add at least two categories to move to the next steps.");
            localStorage.setItem("stepper_error", "Looks like you haven't created enough categories for this test. Please add at least two categories to move to the next steps.");

        }
        else if (cardArray.length === 0) {
            if(document.getElementById("card-section")){
                console.log("cccccc");
            document.getElementById("card-section").scrollIntoView({
                behavior: "smooth",
                block: "center",
            });
            }
            setFormError({
                ...formError,
                'category':'',
                'card':'You need to add a minimum of two cards.',
                error_class:"input_error"
            });
            setStepperError(true);
            setIsSaved({ 'status': 'error', 'message': "You need to add a minimum of two cards." });
            showError("Looks like you haven't created enough cards for this test. Please add at least two cards to move to the next steps.");
            localStorage.setItem("stepper_error", "Looks like you haven't created enough cards for this test. Please add at least two cards to move to the next steps.");

        }else if (cardArray.length < 2) {
            console.log("aaaaaa");
            if(document.getElementById("card-section")){
            document.getElementById("card-section").scrollIntoView({
                behavior: "smooth",
                block: "center",
            });
            }
            setFormError({
                ...formError,
                'category':'',
                'card':'You need to add a minimum of two cards.',
                error_class:"input_error"
            });
            setStepperError(true);
            setIsSaved({ 'status': 'error', 'message': "You need to add a minimum of two cards." });
            showError("Looks like you haven't created enough cards for this test. Please add at least two cards to move to the next steps.");
            localStorage.setItem("stepper_error", "Looks like you haven't created enough cards for this test. Please add at least two cards to move to the next steps.");

        }  else {
            //autosaveData(true);


            var checkConclusionQuestionIfErrorExits = true;

            console.log('yes 1')
            console.log(introductionQuestionRef.current)
            console.log(conclusionQuestionRef.current)



            if(conclusionQuestionRef.current){
                checkConclusionQuestionIfErrorExits = conclusionQuestionRef.current.questionValidateForm(true)
            }

            console.log(checkIntroductionQuestionIfErrorExits)
            console.log(checkConclusionQuestionIfErrorExits)



            // check if any error exists in conclusion question
            if(!checkConclusionQuestionIfErrorExits && checkConclusionQuestionIfErrorExits != undefined){
                console.log('error exits conclusion')

                return false;
            }



            window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({
				'event' : 'create_test_step2',
				'type': test.methodology,
				'userID' : ReactSession.get("user") ? ReactSession.get("user").user_id : ""
			});

            navigate("/r/test/" + test_id + "/thank-you");
        }
    }

    const handleDragStart = (event) => {
        const draggedDOM = getDraggedDom(event.draggableId);

        if (!draggedDOM) {
            return;
        }

        const { clientHeight, clientWidth } = draggedDOM;
        const sourceIndex = event.source.index;
        var clientY =
            parseFloat(window.getComputedStyle(draggedDOM.parentNode.parentNode).paddingTop) +
            [...draggedDOM.parentNode.parentNode.children]
                .slice(0, sourceIndex)
                .reduce((total, curr) => {
                    console.log(curr)
                    const style = curr.currentStyle || window.getComputedStyle(curr);
                    const marginBottom = parseFloat(style.marginBottom);
                    return total + curr.clientHeight + marginBottom;
                }, 0);

        setPlaceholderProps({
            clientHeight,
            clientWidth,
            clientY,
            clientX: parseFloat(
                window.getComputedStyle(draggedDOM.parentNode.parentNode).paddingLeft
            ),
           // classValue:'draggable-element-start',
             background:'#CCCCCC',
             border:'none'
        });
    };

    const handleDragUpdate = (event) => {
        if (!event.destination) {
            return;
        }

        const draggedDOM = getDraggedDom(event.draggableId);

        if (!draggedDOM) {
            return;
        }

        const { clientHeight, clientWidth } = draggedDOM;
        const destinationIndex = event.destination.index;

        const sourceIndex = event.source.index;

        const childrenArray = [...draggedDOM.parentNode.parentNode.children];
        const movedItem = childrenArray[sourceIndex];
        childrenArray.splice(sourceIndex, 1);

        const updatedArray = [
            ...childrenArray.slice(0, destinationIndex),
            movedItem,
            ...childrenArray.slice(destinationIndex + 1),
        ];


        var clientY =
            parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
            updatedArray.slice(0, destinationIndex).reduce((total, curr) => {
                console.log(curr)
                const style = curr.currentStyle || window.getComputedStyle(curr);
                const marginBottom = parseFloat(style.marginBottom);
                return total + curr.clientHeight + marginBottom;
            }, 0);

        setPlaceholderProps({
            clientHeight,
            clientWidth,
            clientY,
            clientX: parseFloat(
                window.getComputedStyle(draggedDOM.parentNode).paddingLeft
            ),
           // classValue:'draggable-element-move',
             background:'none',
             border:'1px dashed'
        });
    };

    const getDraggedDom = (draggableId) => {
        const domQuery = `[${queryAttr}='${draggableId}']`;
        const draggedDOM = document.querySelector(domQuery);

        return draggedDOM;
    };

    const editCategory = (uniqueId) =>{

        const newCategoryArray = categoryArray.map((p, index) =>
            p.uniqueId === uniqueId
                ? { ...p, isEdit: true }
                : p
        );

        setCategoryArray(newCategoryArray);
    }

    const renameCategory = (uniqueId) =>{

        setTyping(false);

        const checkCategoryExits = categoryArray.find(item => item.uniqueId === uniqueId);
        if(checkCategoryExits){

            console.log(checkCategoryExits)
            const checkCategory = categoryArray.findIndex(item => item.name === checkCategoryExits.name && item.uniqueId !== uniqueId);
            console.log(checkCategory)
            if(checkCategory === -1){
                const newCategoryArray = categoryArray.map((p, index) =>
                    p.uniqueId === uniqueId
                        ? { ...p, isEdit: false }
                        : p
                );

                setCategoryArray(newCategoryArray);
            }else{

                showError("A category with the same name already exists.");

            }

        }

    }


    const clearFormValue = (name) => {
        setFormValues({ ...formValues, [name]: "" });
    };

    const saveCardTitle= (val)=>{
        setFormValues({
            ...formValues,
            title:val
        });
    }

    return (
        <LayoutResearcher
            wrapClass={`create-test-data-wrapper ${test && test.language == "ar" ? "arabic_wrapper_main" : ""}`}
            openSupportPanel={show_support_panel} hideSupportPanel={()=>{setShowSupportPanel(false)}}
        >
            <Helmet>
                <title>Create card sorting - step 2 | UserQ</title>
            </Helmet>
            <div className="three-column-layout">
                <Header
                    test={test}
                    isLoading={isLoading}
                    isSaved={isSaved}
                    credits={credits}
                />
                <div className="three-column-layout-body">
                    <div className={`three-column-layout-left ${formCustomField.category!=''?"disable-stepper":""}`}>
                        <Stepper
                            test={test}
                            completed_step={1}
                            stepCallback={() => {
                                // introductionsubmitHandler(true);
                            }}
                            error={stepperError}
                        />
                    </div>
                    {isLoading &&
                        <div className="test-section-loader"> <LoadingIcon /></div>
                    }
                    {!isLoading && (
                        <>
                            <div className="three-column-layout-center">
                                <div className="card-sorting-creating-test-wrap">
                                   <div className='section-minimum-height'>
                                    <Accordian
                                        title={"Introduction questions"}
                                        subtitle={"(Optional)"}
                                        tooltip={"Some cool text"}
                                        open={accordians['introduction'] ? true : false}
                                        onChange={() => {
                                            let new_accordians = Object.assign({}, accordians);

                                            for (var key in accordians) {
                                                if (key == "introduction") {
                                                    if (accordians[key]) {
                                                        new_accordians[key] = false;
                                                    } else {
                                                        new_accordians[key] = true;
                                                    }
                                                }
                                                // else {
                                                //     new_accordians[key] = false;
                                                // }

                                            }
                                            setAccordians(new_accordians);
                                        }}
                                        questionCount={introCount}
                                    >
                                        <IntroductionQuestions ref={introductionQuestionRef} questions={introductionQuestions} test={test} setLoading={(status)=>setIsSaved(status)} changeCount={(newCount)=>{setIntroCount(newCount)}} updateQuestions={(questions)=>{setIntroductionQuestions([...questions])}}/>
                                    </Accordian>
                                    <Accordian
                                        title={"Card sorting"}
                                        tooltip={"Some cool text"}
                                        open={accordians['cardsorting'] ? true : false}
                                        onChange={() => {
                                            let new_accordians = Object.assign({}, accordians);

                                            for (var key in accordians) {
                                                if (key == "cardsorting") {
                                                    if (accordians[key]) {
                                                        new_accordians[key] = false;
                                                    } else {
                                                        new_accordians[key] = true;
                                                    }
                                                }
                                                // else {
                                                //     new_accordians[key] = false;
                                                // }

                                            }
                                            setAccordians(new_accordians);
                                        }}
                                    >
                                        <div className="card-sorting-test-wrap prefer-design-wrap" id={'title-section'}>
                                            <div className="cardsorting-wrap-top">
                                                <Text type={'subtitle-2'} fontWeight={'medium-font'}>
                                                    Task
                                                </Text>
                                                <Text
                                                    type={'body-text-2'}
                                                    fontWeight={'medium-font'}
                                                    cssClasses={'gray-color'}
                                                >
                                                    Provide the tester with contextualized instructions on how to categorize the content on your cards.
                                                </Text>


                                            </div>
                                            <div
                                                className={`cardsorting-wrap-mid-data card-sorting-create-test-title ${test.language == "ar" ? "arabic_wrapper" : ""}`}
                                                id="title">
                                                <InputPlaceholder
                                                    id={"Add Task"}
                                                    rtl={test.language == "ar" ? true : false}
                                                    placeholder={document.body.clientWidth > 1023?"E.g. We'd like to understand how you group items and what names you would give to those groups":'Task description'}
                                                    label="Task description"
                                                    maxLength="200"
                                                    error={formError.title}
                                                    error_class={formError.error_class}
                                                    onClear={() => {
                                                        setCardTitle('');
                                                        saveCardTitle('');
                                                    }}
                                                    value={cardTitle}
                                                    onChange={(e) => {
                                                        setStepperError(false);
                                                         setFormError({...formError, title: null});
                                                         
                                                        setCardTitle(e.target.value ? e.target.value : '');

                                                        saveCardTitle(e.target.value ? e.target.value : '');

                                                    }}
                                                    onBlur={(e) => {
                                                        if (e.target.value) {
                                                            setStepperError(false);
                                                        }
                                                        maxLengthCheck(e);

                                                        saveCardTitle(e.target.value ? e.target.value : '');

                                                    }}
                                                    required={true}
                                                />
                                            </div>
                                        </div>

                                        <div className="card-sorting-test-wrap">

                                            <div className="cardsorting-wrap-top">

                                                <Text children={'Type'} type={'subtitle-2'} fontWeight={'medium-font'} />
                                                <Text
                                                    children={'Choose between open and close card sorting.'}
                                                    type={'body-text-2'}
                                                    fontWeight={'medium-font'}
                                                />
                                            </div>

                                            <div className={`cardsorting-wrap-mid-data `}>

                                                <div className="cardsort-radio-wrap">
                                                    <RadioButton
                                                        id="open_card_toggle"
                                                        isChecked={formValues.cardSorting === "open"
                                                            ? true
                                                            : false}
                                                        label={<Text display="inline" type="body-text-2">Open card sorting</Text>}
                                                        onChange={(e) => {
                                                            setFormValues({
                                                                ...formValues,
                                                                "isRequiredCategories": 0,
                                                                "isAddNewCategory": 0,
                                                                "cardSorting": e.target.value
                                                            });
                                                            setCategoryArray([]);
                                                            setFormCustomField({ 'card': '', 'category': '' });
                                                            setEditCategoryIndex(null);
                                                            setFormError({...formError,'card':'','category':'','error_class':''});

                                                        }}
                                                        value={"open"}
                                                        name={"card-sorting"}
                                                    />

                                                    <Text id="rdio-more-data"
                                                        type={'body-text-2'}
                                                        fontWeight={'medium-font'}
                                                        children={'Participants create and name their own categories'}
                                                    />
                                                </div>


                                                {formValues.cardSorting === 'open' &&
                                                    <div className="selected-card-option field1">
                                                        <div className="select-card-option-repeat">

                                                            <ToggleButton
                                                                id="terms_accept_toggle"
                                                                isChecked={formValues.isRequiredCategories == 1 ? true : false}
                                                                label={<Text display="inline" fontWeight={'medium-font'} type="body-text-2">Require categories
                                                                    for all cards in this test</Text>}
                                                                onChange={(e) => {
                                                                    setFormValues({
                                                                        ...formValues,
                                                                        "isRequiredCategories":
                                                                            e.currentTarget.checked ? 1 : 0
                                                                    });
                                                                }}
                                                            />
                                                        </div>

                                                    </div>
                                                }

                                                <div className="cardsort-radio-wrap">
                                                    <RadioButton
                                                        id="close_card_toggle"
                                                        isChecked={formValues.cardSorting === "close"
                                                            ? true
                                                            : false}
                                                        label={<Text display="inline" type="body-text-2">Closed card sorting</Text>}
                                                        onChange={(e) => {
                                                            setFormValues({
                                                                ...formValues,
                                                                "isRequiredCategories": 0,
                                                                "isAddNewCategory": 0,
                                                                "cardSorting": e.target.value
                                                            });
                                                            setCategoryArray([]);
                                                            setFormCustomField({ 'card': '', 'category': '' });
                                                            setEditCategoryIndex(null);
                                                            setFormError({...formError,'card':'','category':'','error_class':''});
                                                        }}
                                                        value={"close"}
                                                    />
                                                    <Text
                                                        type={'body-text-2'}
                                                        fontWeight={'medium-font'}
                                                        children={'You choose the categories that participants see'}
                                                    />
                                                </div>

                                                {formValues.cardSorting === 'close' &&
                                                    <div className="selected-card-option field1">

                                                        <div className="select-card-option-repeat">
                                                            <ToggleButton
                                                                id="isAdd_new_category_toggle"
                                                                isChecked={formValues.isAddNewCategory == 1 ? true : false}
                                                                label={<Text display="inline" fontWeight={'medium-font'} type="body-text-2">Let participants add their own additional categories</Text>}
                                                                onChange={(e) => {
                                                                    setFormValues({
                                                                        ...formValues,
                                                                        "isAddNewCategory":
                                                                            e.currentTarget.checked ? 1 : 0
                                                                    });
                                                                }}
                                                            />
                                                        </div>

                                                        <div className="select-card-option-repeat">
                                                            <ToggleButton
                                                                id="is_required_categories_toggle"
                                                                isChecked={formValues.isRequiredCategories == 1 ? true : false}
                                                                label={<Text display="inline" fontWeight={'medium-font'} type="body-text-2">Require participants to sort all cards in this test</Text>}
                                                                onChange={(e) => {
                                                                    setFormValues({
                                                                        ...formValues,
                                                                        "isRequiredCategories":
                                                                            e.currentTarget.checked ? 1 : 0
                                                                    });
                                                                }}
                                                            />
                                                        </div>

                                                    </div>


                                                }
                                            </div>

                                            <div className="cardsorting-wrap-bottom">

                                                <Text children={'Card sorting settings'} type={'subtitle-2'} cssClasses={'cardsorting-wrap-bottom-text'} fontWeight={'medium-font'} />

                                                <div className="cardsorting-wrap-bottom-data select-card-option-repeat">
                                                    <ToggleButton
                                                        id="is_randomize_toggle"
                                                        isChecked={formValues.is_randomize == 1 ? true : false}
                                                        label={<Text display="inline" fontWeight={'medium-font'} type="body-text-2">Randomize the order in which the cards will be shown to the testers</Text>}
                                                        onChange={(e) => {
                                                            setFormValues({
                                                                ...formValues,
                                                                "is_randomize":
                                                                    e.currentTarget.checked ? 1 : 0
                                                            });
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            <div class="create-test-preview-area">
                                                    <Text
                                                        type={'overline'}
                                                        children={'Preview'}
                                                    />
                                                    <div className="create-test-preview-area-inner">
                                                        <img src={
                                                            process.env.REACT_APP_URL + "img/card-preview.svg"}
                                                            alt="img" />
                                                    </div>
                                                    <GuideLink methodology={"card sorting test"}/>
                                                </div>
                                        </div>



                                        {formValues.cardSorting === 'close' &&

                                            <div className="card-sorting-test-wrap">


                                                <div className="cardsorting-wrap-top" id={"category-section"}>
                                                    <div className={"heading-with-import-btn d-flex align-items-center"}>
                                                    <Text
                                                        type={'subtitle-2'}
                                                        fontWeight={'medium-fone'}
                                                        children={'Categories'}
                                                    />

                                                    <button
                                                        type="button"
                                                        className="secondary-btn secondary-small btn-with-icon  btn-with-icon-right"
                                                    >
                                                        <label className="label upload-image-label">
                                                            <span className="change-image-link">
                                                                <input
                                                                    type="file"
                                                                    ref={fileRef}
                                                                    className="default-file-input"
                                                                    onChange={(e) => handleFileInput(e, 'category')}
                                                                    onClick={(event) => {
                                                                        event.currentTarget.value = null
                                                                    }}
                                                                    style={{display: "none"}}
                                                                />
                                                                <span className="browse-files-text">Import</span>
                                                                <img
                                                                    src={
                                                                        process.env.REACT_APP_URL +
                                                                        "img/import.svg"
                                                                    }
                                                                    alt="img"
                                                                />
                                                            </span>
                                                        </label>
                                                    </button>
                                                    </div>

                                                    <div className="cat-card-top-sub-data body-text body-text-2 medium-font">
                                                        Create categories you would like your participants to group the cards into.
                                                        Make sure to have a separate category name per row.
                                                        You can also&nbsp;
                                                        {/*<label className="label upload-image-label">*/}
                                                        {/*<span className="change-image-link">*/}
                                                        {/*    <input*/}
                                                        {/*        type="file"*/}
                                                        {/*        className="default-file-input"*/}
                                                        {/*        onChange={(e) => handleFileInput(e, 'category')}*/}
                                                        {/*        onClick={(event) => {*/}
                                                        {/*            event.currentTarget.value = null*/}
                                                        {/*        }}*/}
                                                        {/*        ref={fileRef}*/}
                                                        {/*        style={{display: "none"}}*/}
                                                        {/*    />*/}
                                                        {/*    <span className="browse-files-text"></span>*/}
                                                        {/*</span>*/}
                                                        {/*</label>*/}
                                                        import
                                                        {/*<button type="button" className="csv-import-btn">*/}
                                                        {/*    <span>Import</span>*/}
                                                        {/*    <input type="file" ref={fileRef} onClick={(e)=> {e.currentTarget.value = null}} onChange={(e) => handleFileInput(e, 'category')} />*/}
                                                        {/*</button>*/}
                                                        &nbsp;the categories from a CSV file. <a
                                                            href={
                                                                process.env.REACT_APP_IMG_URL+'download-data.php?fileSource='+process.env.REACT_APP_IMG_URL +
                                                                "/docs/import-categorys-example.csv"
                                                            }
                                                            target="_blank"
                                                            className="preview-example-link"
                                                        >Download CSV template</a>.



                                                    </div>

                                                </div>






                                                <div className={`cardsorting-wrap-mid-data ${language=="ar"?"arabic_wrapper":""}`}>
                                                    <div className="cat-add-data-wrap">


                                                        <Input
                                                            label="Category name"
                                                            onChange={(e) => {
                                                                if(editCategoryIndex && !e.target.value){
                                                                    setFormError({
                                                                        ...formError,
                                                                        category:'Required field',
                                                                        error_class:"input_error"
                                                                    })
                                                                }else{
                                                                    // setFormError({
                                                                    //     ...formError,
                                                                    //     category:'',
                                                                    //     error_class:""
                                                                    // })
                                                                }
                                                                setFormCustomField({ ...formCustomField, category: e.target.value })

                                                            }}
                                                            onKeyDown={(e) => {
                                                                if (e.key === 'Enter') {
                                                                    addCategory(e);
                                                                }
                                                            }}
                                                            onBlur={(e) => {
                                                                //console.log('dasd')
                                                                addCategory(e);
                                                            }}
                                                            onClear={() => {
                                                                setTimeout(() => {
                                                                    setFormValues((oldValues) => ({
                                                                        ...oldValues,
                                                                        test_name: ""
                                                                    }));
                                                                }, 1000);
                                                            }}
                                                            value={formCustomField.category}
                                                            maxLength="100"
                                                            error={formError.category}
                                                            error_class={formError.error_class}
                                                            clearField={false}
                                                            required={true}
                                                            ref={inputref}
                                                            id={'category_input'}
                                                            rtl={language=="ar"?true:false}
                                                        />
                                                    </div>

                                                    <div className="add-cat-data-btn">
                                                        <Button
                                                            size={'medium'}
                                                            type={'secondary'}
                                                            iconLeft={<Icon value={'add'} />}
                                                            label={(editCategoryIndex)?'Update category':'Add category'}
                                                            onClick={(e) => {
                                                                addCategory(e);
                                                            }}
                                                            state={(formCustomField.category)?'':(editCategoryIndex)?'':'disabled'}
                                                        />
                                                    </div>


                                                    <div className="added-cardsorting-category-wrap" ref={ref} style={{position:'relative'}}>

                                                        {tooltip.open ? (
                                                            <>
                                                                <Tooltip
                                                                    ref={tipref}
                                                                    type={tooltip.tipPosition}
                                                                    key={Math.random()} // needed for bounds to update correctly
                                                                    style={{ zIndex:2, display: tooltip.show? "block" : 'block', whiteSpace:"normal", width:"260px", minWidth:"150px", margin:0, position:"absolute",left:(tooltip.tooltipLeft)+"px",top:(tooltip.tooltipTop)+"px"}}
                                                                    //style={{ zIndex:9999, display: tooltip.show? "block" : 'block', whiteSpace:"normal",minHeight:'15px', margin:0,left:(tooltip.tooltipLeft)+"px",top:(tooltip.tooltipTop)+"px"}}
                                                                    data={tooltip.tooltipData}
                                                                    className="tooltipChart"

                                                                />
                                                            </>
                                                        ): (
                                                            <></>
                                                        )}

                                                        <DragDropContext
                                                            // onDragStart={(e) => {
                                                            //
                                                            //     console.log(e.draggableId);
                                                            //     // if(e.draggableId){
                                                            //     //     document.getElementById(e.draggableId).classList.add("mystyle");
                                                            //     // }
                                                            //
                                                            //
                                                            //
                                                            // }}
                                                            // onDragUpdate={(e)=>{
                                                            //     console.log(e.draggableId);
                                                            //     if (e.destination) {
                                                            //         document.getElementById(e.draggableId).classList.remove("draggable-element-start");
                                                            //         document.getElementById(e.draggableId).classList.add("draggable-element-move");
                                                            //     }
                                                            // }}
                                                            onDragStart={handleDragStart}
                                                            onDragUpdate={handleDragUpdate}
                                                            onDragEnd={(param) => {
                                                                setPlaceholderProps({});
                                                            const srcI =
                                                                param.source.index;
                                                            const desI =
                                                                param.destination.index;
                                                            categoryArray.splice(
                                                                desI,
                                                                0,
                                                                categoryArray.splice(
                                                                    srcI,
                                                                    1
                                                                )[0]
                                                            );
                                                            autosaveData(false);
                                                        }}>
                                                            <Droppable droppableId="droppable-1">
                                                                {(provided, snapshot) => (
                                                                    <div
                                                                        ref={provided.innerRef}
                                                                        {...provided.droppableProps}
                                                                    >
                                                                        {categoryArray && categoryArray.map((items, index) => {

                                                                            return (
                                                                                <div
                                                                                    key={index}
                                                                                    className="w-100"
                                                                                >
                                                                                <Draggable
                                                                                    key={index}
                                                                                    draggableId={
                                                                                        "draggable-" +
                                                                                        index
                                                                                    }
                                                                                    index={index}
                                                                                >
                                                                                    {(
                                                                                        provided,
                                                                                        snapshot
                                                                                    ) => (
                                                                                        <div
                                                                                            id={"draggable-"+index}
                                                                                           // className={'added-card-cat-repeat'}
                                                                                            // className={
                                                                                            //     snapshot.isDragging
                                                                                            //         ? "draggable-element-start added-card-cat-repeat"
                                                                                            //         : "added-card-cat-repeat"
                                                                                            // }
                                                                                            //question-answers-dragging
                                                                                            className={`added-card-cat-repeat ${snapshot.isDragging?'draggable-element-start':''}`}

                                                                                            ref={
                                                                                                provided.innerRef
                                                                                            }
                                                                                            {...provided.draggableProps}
                                                                                            {...provided.dragHandleProps}


                                                                                        >
                                                                                        <div className={`added-card-cat-repeat-sub-items ${snapshot.isDragging?'draggable-inner-element-start':''}`}
                                                                                        >

                                                                                            <div  className="drag-area-top"
                                                                                                 onPointerMove={handlePointerMove(((editCategoryIndex === items.uniqueId) ?
                                                                                                     formCustomField.category
                                                                                                     :
                                                                                                     items.name),refs.current[index]) }
                                                                                                 onPointerLeave={()=>{ showTooltip({...tooltip, open:false, show:false}) } }

                                                                                            >
                                                                                                <div
                                                                                                    className="ans-icon " {...provided.dragHandleProps}>
                                                                                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                        <path d="M4.66699 4.5C4.53438 4.5 4.40721 4.44732 4.31344 4.35355C4.21967 4.25979 4.16699 4.13261 4.16699 4C4.16699 3.86739 4.21967 3.74021 4.31344 3.64645C4.40721 3.55268 4.53438 3.5 4.66699 3.5C4.7996 3.5 4.92678 3.55268 5.02055 3.64645C5.11431 3.74021 5.16699 3.86739 5.16699 4C5.16699 4.13261 5.11431 4.25979 5.02055 4.35355C4.92678 4.44732 4.7996 4.5 4.66699 4.5ZM4.66699 8.5C4.53438 8.5 4.40721 8.44732 4.31344 8.35355C4.21967 8.25979 4.16699 8.13261 4.16699 8C4.16699 7.86739 4.21967 7.74022 4.31344 7.64645C4.40721 7.55268 4.53438 7.5 4.66699 7.5C4.7996 7.5 4.92678 7.55268 5.02055 7.64645C5.11431 7.74022 5.16699 7.86739 5.16699 8C5.16699 8.13261 5.11431 8.25979 5.02055 8.35355C4.92678 8.44732 4.7996 8.5 4.66699 8.5ZM4.66699 12.5C4.53438 12.5 4.40721 12.4473 4.31344 12.3536C4.21967 12.2598 4.16699 12.1326 4.16699 12C4.16699 11.8674 4.21967 11.7402 4.31344 11.6464C4.40721 11.5527 4.53438 11.5 4.66699 11.5C4.7996 11.5 4.92678 11.5527 5.02055 11.6464C5.11431 11.7402 5.16699 11.8674 5.16699 12C5.16699 12.1326 5.11431 12.2598 5.02055 12.3536C4.92678 12.4473 4.7996 12.5 4.66699 12.5Z" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                        <path d="M11.333 4.5C11.2004 4.5 11.0732 4.44732 10.9795 4.35355C10.8857 4.25979 10.833 4.13261 10.833 4C10.833 3.86739 10.8857 3.74021 10.9795 3.64645C11.0732 3.55268 11.2004 3.5 11.333 3.5C11.4656 3.5 11.5928 3.55268 11.6866 3.64645C11.7803 3.74021 11.833 3.86739 11.833 4C11.833 4.13261 11.7803 4.25979 11.6866 4.35355C11.5928 4.44732 11.4656 4.5 11.333 4.5ZM11.333 8.5C11.2004 8.5 11.0732 8.44732 10.9795 8.35355C10.8857 8.25979 10.833 8.13261 10.833 8C10.833 7.86739 10.8857 7.74022 10.9795 7.64645C11.0732 7.55268 11.2004 7.5 11.333 7.5C11.4656 7.5 11.5928 7.55268 11.6866 7.64645C11.7803 7.74022 11.833 7.86739 11.833 8C11.833 8.13261 11.7803 8.25979 11.6866 8.35355C11.5928 8.44732 11.4656 8.5 11.333 8.5ZM11.333 12.5C11.2004 12.5 11.0732 12.4473 10.9795 12.3536C10.8857 12.2598 10.833 12.1326 10.833 12C10.833 11.8674 10.8857 11.7402 10.9795 11.6464C11.0732 11.5527 11.2004 11.5 11.333 11.5C11.4656 11.5 11.5928 11.5527 11.6866 11.6464C11.7803 11.7402 11.833 11.8674 11.833 12C11.833 12.1326 11.7803 12.2598 11.6866 12.3536C11.5928 12.4473 11.4656 12.5 11.333 12.5Z" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                    </svg>

                                                                                                </div>
                                                                                                {/*<span*/}
                                                                                                {/*    className={`card-cat-count`}>{index + 1}</span>*/}
                                                                                                {items.isEdit ?
                                                                                                    <div ref={(element) => {
                                                                                                        refs.current[index] = element;
                                                                                                    }}  className={`ans-data enable-edit-category-input ${(language == 'ar') ? 'cairo-font' : ''}`}>
                                                                                                        <AutosizeInput
                                                                                                            className={`auto-width-input ${(language === 'ar')?'arabic_wrapper':''}`}
                                                                                                            value={items.name}
                                                                                                            placeholder={'Enter category name'}
                                                                                                            onChange={(e)=>{
                                                                                                                setTyping(true);
                                                                                                                const newCategoryArray = categoryArray.map((p, index) =>
                                                                                                                    p.uniqueId === items.uniqueId
                                                                                                                        ? { ...p, name: e.target.value }
                                                                                                                        : p
                                                                                                                );

                                                                                                                setCategoryArray(newCategoryArray);
                                                                                                            }}
                                                                                                            onKeyDown={(e)=>{
                                                                                                                if (e.key === 'Enter') {
                                                                                                                    if(!items.name){
                                                                                                                        showError('Category name field is required')
                                                                                                                        return false;
                                                                                                                    }else{
                                                                                                                        renameCategory(items.uniqueId);
                                                                                                                    }
                                                                                                                }
                                                                                                            }}
                                                                                                            onBlur={()=>{
                                                                                                                if(!items.name){
                                                                                                                    showError('Category name field is required')
                                                                                                                    return false;
                                                                                                                }else{
                                                                                                                    renameCategory(items.uniqueId);
                                                                                                                }
                                                                                                            }}
                                                                                                            maxLength={100}
                                                                                                            //ref={inputref}
                                                                                                            ref={el => categoryInputrefs.current[index] = el}
                                                                                                        />
                                                                                                    </div>
                                                                                                    :
                                                                                                    <div ref={(element) => {
                                                                                                        refs.current[index] = element;
                                                                                                    }}  className={`ans-data ${(language == 'ar') ? 'cairo-font' : ''}`}>
                                                                                                        {(editCategoryIndex === items.uniqueId) ?
                                                                                                            formCustomField.category
                                                                                                            :
                                                                                                            items.name
                                                                                                        }
                                                                                                    </div>
                                                                                                }

                                                                                            </div>

                                                                                            <div className="drag-area-bottom">

                                                                                                <span className="category-action-btn"
                                                                                                    onClick={() => {
                                                                                                        if(items.isEdit){
                                                                                                           if(!items.name){
                                                                                                               showError('Category name field is required')
                                                                                                               return false;
                                                                                                           }else{
                                                                                                               renameCategory(items.uniqueId);
                                                                                                           }
                                                                                                        }else{
                                                                                                            setTyping(true);
                                                                                                            setFocusCategoryId(index)


                                                                                                            editCategory(items.uniqueId);
                                                                                                        }

                                                                                                        // if(editCategoryIndex && editCategoryIndex !== items.uniqueId){
                                                                                                        //     showError('Finish editing of category')
                                                                                                        // }else {
                                                                                                        //     setEditCategoryIndex(items.uniqueId)
                                                                                                        //     setFormCustomField({
                                                                                                        //         ...formCustomField,
                                                                                                        //         category: items.name
                                                                                                        //     })
                                                                                                        //     document.getElementById("category_input").scrollIntoView({
                                                                                                        //         behavior: "smooth",
                                                                                                        //         block: "center",
                                                                                                        //     });
                                                                                                        // }
                                                                                                    }}>

                                                                                                    {items.isEdit ?
                                                                                                        <Icon
                                                                                                            value={'check-mark'}
                                                                                                            size={'medium'} hover={true}/>
                                                                                                        :
                                                                                                        <>
                                                                                                        <Icon value={'edit'} size={'medium'} hover={true} />
                                                                                                        {/*<img src={*/}
                                                                                                        {/*    process.env.REACT_APP_URL + "img/edit.svg"}*/}
                                                                                                        {/*     alt="img"/>*/}
                                                                                                        </>
                                                                                                    }
                                                                                                </span>
                                                                                                <span className="category-action-btn"
                                                                                                    onClick={() => {
                                                                                                        if(editCategoryIndex === items.uniqueId){
                                                                                                            //showError('Finish editing of category')
                                                                                                            setEditCategoryIndex(null);
                                                                                                            setFormError({...formError,'category':'','error_class':''});
                                                                                                            setFormCustomField({ ...formCustomField, category: '' })
                                                                                                            deleteCategory(items.uniqueId)
                                                                                                        }else{
                                                                                                            deleteCategory(items.uniqueId)
                                                                                                        }

                                                                                                    }}>
                                                                                                    <Icon value={'delete'} size={'medium'} hover={true} />

                                                                                                </span>
                                                                                            </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    )}
                                                                                </Draggable>
                                                                                </div>
                                                                            );
                                                                        })}
                                                                        {provided.placeholder}

                                                                        {!isEmpty(placeholderProps) && (
                                                                            <div
                                                                                className={`placeholder`}
                                                                                style={{
                                                                                    top: placeholderProps.clientY,
                                                                                    left: placeholderProps.clientX,
                                                                                    height: placeholderProps.clientHeight,
                                                                                    width: placeholderProps.clientWidth,
                                                                                    position: "absolute",
                                                                                    borderColor: "#000000",
                                                                                    background:placeholderProps.background,
                                                                                    borderRadius:'10px',
                                                                                    border:placeholderProps.border,
                                                                                    margin: '10px 0px 10px'
                                                                                }}
                                                                            />
                                                                        )}
                                                                    </div>
                                                                )}
                                                            </Droppable>
                                                        </DragDropContext>
                                                    </div>


                                                    <div className="added-cat-count-data">
                                                        {categoryArray.length > 0 &&
                                                            <>
                                                                <span className="cardsort-data-count">
                                                                    {categoryArray.length}{" "}

                                                                    {categoryArray.length == 1 ?
                                                                        'category'
                                                                        : 'categories'
                                                                    }{" "}
                                                                    added
                                                                </span>

                                                                <Button
                                                                    size={'medium'}
                                                                    type={'ghost'}
                                                                    iconRight={<Icon value={'delete'} />}
                                                                    label={'Delete all categories'}
                                                                    onClick={(e) => {
                                                                        setConfirmModal({
                                                                            open: true,
                                                                            btn_reverse:true,
                                                                            confirm_btn_title: "Yes, delete",
                                                                            cancel_btn_title: "No, keep",
                                                                            confirm_title: "Delete all categories",
                                                                            confirm_message:
                                                                                "Are you sure you want to delete all categories? This cannot be undone",
                                                                            confirmation_for: "deleteCategory",
                                                                            confirm_title_class: 'text-danger',


                                                                        });
                                                                    }}
                                                                />
                                                            </>
                                                        }
                                                    </div>
                                                </div>

                                                

                                            </div>
                                        }



                                        <div className="card-sorting-test-wrap">
                                            <div className="cardsorting-wrap-top" id={"card-section"}>
                                                <div className={"heading-with-import-btn d-flex align-items-center"}>
                                                    <Text
                                                        type={'subtitle-2'}
                                                        fontWeight={'medium-font'}
                                                        children={'Cards'}
                                                    />

                                                    <button
                                                        type="button"
                                                        className="secondary-btn secondary-small btn-with-icon  btn-with-icon-right"
                                                    >
                                                        <label className="label upload-image-label">
                                                            <span className="change-image-link">
                                                                <input
                                                                    type="file"
                                                                    ref={fileRef}
                                                                    className="default-file-input"
                                                                    onChange={(e) => handleFileInput(e, 'card')}
                                                                    onClick={(event) => {
                                                                        event.currentTarget.value = null
                                                                    }}
                                                                    style={{display: "none"}}
                                                                />
                                                                <span className="browse-files-text">Import</span>
                                                                <img
                                                                    src={
                                                                        process.env.REACT_APP_URL +
                                                                        "img/import.svg"
                                                                    }
                                                                    alt="img"
                                                                />
                                                            </span>
                                                        </label>
                                                    </button>
                                                </div>

                                                <div className="cat-card-top-sub-data body-text body-text-2 medium-font">
                                                    Create the cards you want participants to categorise.
                                                    Make sure to have a separate card name per row.
                                                    You can also&nbsp;
                                                    {/*<label className="label upload-image-label">*/}
                                                    {/*    <span className="change-image-link">*/}
                                                    {/*        <input*/}
                                                    {/*            type="file"*/}
                                                    {/*            className="default-file-input"*/}
                                                    {/*            onChange={(e) => handleFileInput(e, 'card')}*/}
                                                    {/*            onClick={(event) => {*/}
                                                    {/*                event.currentTarget.value = null*/}
                                                    {/*            }}*/}
                                                    {/*            ref={fileRef}*/}
                                                    {/*            style={{display: "none"}}*/}
                                                    {/*        />*/}
                                                    {/*        <span className="browse-files-text"></span>*/}
                                                    {/*    </span>*/}
                                                    {/*</label>*/}
                                                    import
                                                    {/*<button type="button" className="create-btn csv-import-btn">*/}
                                                    {/*    <span>Import</span>*/}
                                                    {/*    <input type="file" ref={fileRef} onClick={(e)=> {e.currentTarget.value = null}} onChange={(e) => handleFileInput(e, 'card')} />*/}
                                                    {/*</button>*/}
                                                    &nbsp;the cards from a CSV file.

&nbsp;
                                                    <a
                                                        href={
                                                            process.env.REACT_APP_IMG_URL+'download-data.php?fileSource='+process.env.REACT_APP_IMG_URL +
                                                            "/docs/import-card-example.csv"
                                                        }
                                                        target="_blank"
                                                        className="preview-example-link"
                                                    >Download CSV template</a>.



                                                </div>
                                            </div>


                                            <div className={`cardsorting-wrap-mid-data ${language=="ar"?"arabic_wrapper":""}`}>
                                                <div className="cat-add-data-wrap">

                                                    <Input
                                                        label="Card name"
                                                        onChange={(e) => {

                                                                // setFormError({
                                                                //     ...formError,
                                                                //     card:'',
                                                                //     error_class:""
                                                                // })
                                                            setFormCustomField({ ...formCustomField, card: e.target.value })
                                                        }}
                                                        onBlur={(e) => {
                                                            addCard(e);
                                                        }}
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter') {
                                                                addCard(e);
                                                            }
                                                        }}
                                                        onClear={() => {
                                                            setTimeout(() => {
                                                                setFormValues((oldValues) => ({
                                                                    ...oldValues,
                                                                    test_name: ""
                                                                }));
                                                            }, 1000);
                                                        }}
                                                        value={formCustomField.card}
                                                        maxLength="100"
                                                        error={formError.card}
                                                        error_class={formError.error_class}
                                                        clearField={false}
                                                        required={true}
                                                        rtl={language=="ar"?true:false}
                                                    />
                                                </div>

                                                <div className="add-cat-data-btn">
                                                    <Button
                                                        size={'medium'}
                                                        type={'secondary'}
                                                        iconLeft={<Icon value={'add'} />}
                                                        label={'Add Card'}
                                                        state={(formCustomField.card)?'active':'disabled'}
                                                        onClick={(e) => {
                                                            addCard(e);
                                                        }}
                                                    />
                                                </div>

                                                <div className="cards-chips-wrap">
                                                    {cardArray && cardArray.map((items, index) => {
                                                        return (
                                                            <Chip
                                                                key={index}
                                                                title={items.name}
                                                                value={index}
                                                                onRemove={(value) => {
                                                                    const newCardArray = cardArray.filter((item, indexItems) => indexItems !== value);
                                                                    setCardArray(newCardArray)
                                                                }}
                                                                type="icon-right"
                                                            />
                                                        )
                                                    })
                                                    }
                                                </div>

                                                <div className="added-cat-count-data">
                                                    {cardArray.length > 0 &&
                                                        <>
                                                            <span className="cardsort-data-count">{cardArray.length}{" "}
                                                                {cardArray.length == 1 ?
                                                                    'card'
                                                                    : 'cards'
                                                                }{" "}
                                                                added</span>

                                                            <Button
                                                                size={'medium'}
                                                                type={'ghost'}
                                                                iconRight={<Icon value={'delete'} />}
                                                                label={'Delete all cards'} 
                                                                onClick={(e) => {
                                                                    setConfirmModal({
                                                                        open: true,
                                                                        btn_reverse:true,
                                                                        confirm_btn_title: "Yes, delete",
                                                                        cancel_btn_title: "No, keep",
                                                                        confirm_title: "Delete all cards",
                                                                        confirm_message:
                                                                            "Are you sure you want to delete all cards? This cannot be undone",
                                                                        confirmation_for: "deleteCard",
                                                                        confirm_title_class: 'text-danger',


                                                                    });
                                                                }}
                                                            />
                                                        </>
                                                    }
                                                </div>
                                            </div>



                                        </div>


                                    </Accordian>
                                    <Accordian
                                        title={"Conclusion questions"}
                                        subtitle={"(Optional)"}
                                        tooltip={"Some cool text"}
                                        open={accordians['conclusion'] ? true : false}
                                        onChange={() => {
                                            let new_accordians = Object.assign({}, accordians);

                                            for (var key in accordians) {
                                                if (key == "conclusion") {
                                                    if (accordians[key]) {
                                                        new_accordians[key] = false;
                                                    } else {
                                                        new_accordians[key] = true;
                                                    }
                                                }
                                                // else {
                                                //     new_accordians[key] = false;
                                                // }

                                            }
                                            setAccordians(new_accordians);
                                        }}
                                        questionCount={concCount}
                                    >
                                        <ConclusionQuestions ref={conclusionQuestionRef} questions={conclusionQuestions} test={test} setLoading={(status)=>setIsSaved(status)} changeCount={(newCount)=>{setConcCount(newCount)}} updateQuestions={(questions)=>{setConclusionQuestions([...questions])}}/>
                                    </Accordian>
                                    </div> 
                                    <div class="btns-wrap-bottom">
                                        <Button
                                            onClick={() => { navigate("/r/test/" + test_id + "/welcome"); }}
                                            type={"ghost"}
                                            label={"Previous"}
                                            size={"large"}
                                            iconLeft={
                                                <Icon value="back-arrow" colorClass="gray-900-svg" size={"large"} />
                                            }
                                        />
                                        <Button
                                            onClick={handleNextStep}
                                            iconRight={<Icon value="forward-arrow" colorClass="gray-50-svg" />}
                                            label="Next"
                                            size={"large"}
                                            state={(!formValues.title || cardArray.length < 2 || (formValues.cardSorting === 'close' && categoryArray.length < 2)) ? 'disabled' : ''}
                                        />
                                        {/*{console.log(formValues.title )}*/}
                                    </div>
                                </div>



                            </div>
                            <div className="three-column-layout-right">
                            <a  onClick={(e)=>{
                                    e.preventDefault();

                                    setShowSupportPanel(true);

                                }}
                                href={process.env.REACT_APP_URL+"r/support"} target="_blank" className='support-button'><svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.879 8.2817C11.05 7.2567 12.95 7.2567 14.121 8.2817C15.293 9.3067 15.293 10.9687 14.121 11.9937C13.918 12.1727 13.691 12.3197 13.451 12.4357C12.706 12.7967 12.001 13.4347 12.001 14.2627V15.0127M21 12.7627C21 13.9446 20.7672 15.1149 20.3149 16.2068C19.8626 17.2988 19.1997 18.2909 18.364 19.1267C17.5282 19.9624 16.5361 20.6253 15.4442 21.0776C14.3522 21.5299 13.1819 21.7627 12 21.7627C10.8181 21.7627 9.64778 21.5299 8.55585 21.0776C7.46392 20.6253 6.47177 19.9624 5.63604 19.1267C4.80031 18.2909 4.13738 17.2988 3.68508 16.2068C3.23279 15.1149 3 13.9446 3 12.7627C3 10.3757 3.94821 8.08656 5.63604 6.39873C7.32387 4.71091 9.61305 3.7627 12 3.7627C14.3869 3.7627 16.6761 4.71091 18.364 6.39873C20.0518 8.08656 21 10.3757 21 12.7627ZM12 18.0127H12.008V18.0207H12V18.0127Z" stroke="#9759CB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                        Support
                                    </a>
                            </div>
                        </>
                    )}
                </div>
            </div>

            <ConfirmationModal
                confirm_message={confirmModal.confirm_message}
                confirm_btn_title={confirmModal.confirm_btn_title}
                confirm_title={confirmModal.confirm_title}
                openModal={confirmModal.open}
                cancel_btn_title={confirmModal.cancel_btn_title}
                confirm_title_class={confirmModal.confirm_title_class}
                btn_reverse={confirmModal.btn_reverse}


                btnLoading={confirmationBtnLoading}
                close={() => {
                    setConfirmModal({ ...confirmModal, open: false });
                     if (confirmModal.confirmation_for === "importCard") {
                        fileRef.current.value = "";
                    } else if (confirmModal.confirmation_for === "importCategory") {
                        fileRef.current.value = "";
                    }else {
                        setConfirmModal({ ...confirmModal, open: false });
                    }
                }}
                confirm={() => {

                    if (confirmModal.confirmation_for === "importCard") {
                        importCSVApi(confirmModal.csvFile, 'card');
                        fileRef.current.value = "";
                        setFormError({...formError,'card':'','error_class':''});
                        setFormCustomField({ ...formCustomField, card: '' })
                    } else if (confirmModal.confirmation_for === "importCategory") {
                        importCSVApi(confirmModal.csvFile, 'category');
                        fileRef.current.value = "";
                        setEditCategoryIndex(null);
                        setFormError({...formError,'category':'','error_class':''});
                        setFormCustomField({ ...formCustomField, category: '' })
                    }else if (confirmModal.confirmation_for === "deleteCard") {
                        setConfirmModal({ ...confirmModal, open: false });
                        setCardArray([])
                        setFormError({...formError,'card':'','error_class':''});
                        setFormCustomField({ ...formCustomField, card: '' })
                    } else if (confirmModal.confirmation_for === "deleteCategory") {
                        setCategoryArray([])
                        setEditCategoryIndex(null);
                        setFormError({...formError,'category':'','error_class':''});
                        setConfirmModal({ ...confirmModal, open: false });
                        setFormCustomField({ ...formCustomField, category: '' })
                    }
                }}
            />
        </LayoutResearcher>
    );
}
