import { useNavigate, Link } from 'react-router-dom';
import React, { useEffect, useState } from "react";
import {LayoutResearcher} from "../../themes/userq/Layouts/layout_researcher";
import BillingNavigation from './billing-navigation';
import PaymentMethods from './payment-methods.js';
import { ReactSession } from '../../../lib/secure_reactsession.js';
import {encryptClient, showToast} from '../../../lib/helpers';
import { getInvoicesService } from '../../../services/invoices.js';
import {LoadingIcon} from "../../themes/userq/Loader/loadingIcon";
import {Text} from "../../themes/userq/Text/Text";
import {ErrorIcon} from "../../themes/userq/ErrorIcon/ErrorIcon";
import {Button} from "../../themes/userq/Button/Button";
import {RadioButton} from "../../themes/userq/RadioButton/RadioButton";
import {Tag} from "../../themes/userq/Tag/Tag";
import validator from "validator";
import {
    addNewPaymentMethodService,
    deletePaymentMethodService,
    getPaymentMethodsService,
    setDefaultCard
} from "../../../services/payment_methods";
import Cleave, {propTypes} from "cleave.js/react";
import {Icon} from "../../themes/userq/Icon/Icon";
import ConfirmationModal from "../../themes/userq/Modal/ConfirmationModal";
import {CreditCardForm} from "../tests/components/credit_card_form";

export default function Payment(props) {

    const navigate = useNavigate();

    const user = ReactSession.get("user");
    ReactSession.setStoreType("localStorage");

    const [isLoading, setLoading] = useState(false);

    const [cardFormLoading, setCardFormLoading] = useState(false);

    const [confirmModal, setConfirmModal] = useState({ open: false, target_id: null });

    const [paymentMethods, setPaymentMethods] = useState([]);

    const [addNewCard, setAddNewCard] = useState(false);
    const [makePrimaryLoading, setMakePrimaryLoading] = useState(false);
    const [makePrimaryLoadingId, setMakePrimaryLoadingId] = useState(null);

    const [formValues, setFormValues] = useState({ "card_no": "", "card_name": "", "exp_date": "", "cvv": "", "payment_method_id": '' });

    const [formErrors, setFormErrors] = useState({ "card_no": null, "card_name": null, "expiration_date": null, "cvv": null, "error_class": null });

    const [show_support_panel, setShowSupportPanel] = useState(false);
    
    const set = name => {
        return ({ target: { value } }) => {
            setFormValues(oldValues => ({ ...oldValues, [name]: value }));
            setFormErrors((oldValues) => ({ ...oldValues, [name]: null }));
        }
    };
    const clearFormValue = name => {
        return ({ target: { value } }) => {
            setTimeout(function () {
                setFormValues(oldValues => ({ ...oldValues, [name]: '' }));
            }, 1000)

        }
    };
    const setFormValue = (name, value) => {
        setFormValues(oldValues => ({ ...oldValues, [name]: value }));



    };
    // const validateCreditCard = (value) => {
    //
    //     if (!validator.isCreditCard(value)) {
    //
    //         showFormError('card_no', 'Enter valid Credit Card Number!')
    //     } else {
    //         showFormError('card_no', null)
    //     }
    // }
    const resetFormErrors = () => {

        let formErrorsLocal = Object.assign({}, formErrors);

        for (var key in formErrors) {
            formErrorsLocal[key] = null;
        }
        setFormErrors(formErrorsLocal);
    }
    const showFormError = (name, value) => {
        let formErrorsLocal = Object.assign({}, formErrors);

        for (var key in formErrors) {
            formErrorsLocal[key] = null;
        }
        formErrorsLocal[name] = value;

        formErrorsLocal['error_class'] = 'input_error';

        setFormErrors(formErrorsLocal);

    }
    const setDefaultCardValue = (value) => {

        let token = ReactSession.get("token");
        var data = new FormData();
        setMakePrimaryLoading(true);

        setMakePrimaryLoadingId(value);

        if(!makePrimaryLoading) {
            data.append('stripe_id', value);
            setDefaultCard(data, token).then(response => {

                setMakePrimaryLoading(false);
                setMakePrimaryLoadingId(null);
                if (response.success) {
                    getPaymentMethods(false);


                } else {
                    showToast(response.message, "error");
                }
            });
        }

    }
    const renderPaymentMethods = () => {
        var render = [];

        paymentMethods.forEach(function (method) {
            render.push((
                <div key={"methods" + method.id} className="payments-cards d-flex">
                    <div className="card-left-side">
                        <label htmlFor={method.stripe_payment_method_id} className="mt-0 d-flex">
                                <img src={process.env.REACT_APP_URL + "images/" + method.brand + "-icon.svg"} />
                        </label>
                      
                    </div>
                    {user.permission != "Editor" && (
                        <div className="card-right-side">
                            {method.default == 1 &&
                                <Tag title="Primary" />
                            }

                            <Text type={"body-text-3"}>**** {method.last_4}</Text>


                            <span className="delete-account-btn" onClick={() => { setConfirmModal({ open: true, target_id: method.id, confirm_btn_title: "Delete", confirm_title: "Delete Card", confirm_message: "Are you sure you want to delete this card?" }) }}>
                               <Icon value={"delete"} size={"medium"} hover={true} />
                            </span>
                            {method.default == 0 &&
                                <div className={"make-primary-card"}>
                                    <Button type={"secondary"} size={"small"} onClick={() => { setDefaultCardValue(method.stripe_payment_method_id) }} label={"Make primary"}
                                            microLoading={(makePrimaryLoading && makePrimaryLoadingId === method.stripe_payment_method_id)?true:false}
                                    />
                                </div>
                            }
                        </div>)}
                </div>
            ))
        })
        if (render.length > 0) {
            return (
                <>
        <div className="cardlabel-box">
                <Text type={"overline"} cssClasses={'saved-card'}>Saved card</Text>
                    {render}
                     </div>
                </>
                       
            );
        } else {
            return (
                    <div className="no_payment_add_box">
                <Text type={"subtitle-2"} fontWeight={"medium-font"} cssClasses={'no_payment_add'}>
                    <Icon value="attention" colorClass={'gray-700-svg attention-payment'} />
                     No payment method added.
                </Text>
                </div>
            );
        }

    }
    const getPaymentMethods = (loadingState) => {
        let token = ReactSession.get("token");

        if(loadingState) {
            setLoading(true);
        }

        getPaymentMethodsService(token).then(response => {
            setLoading(false);

            if (response.success) {

                setPaymentMethods(response.payment_methods);

                // check if user have not added any card then adding new card will be enable automatically
                if(response.payment_methods.length === 0){
                    setAddNewCard(true);
                }


            } else {

                showToast(response.message,"error");

            }
        });
    }
    const deletePaymentMethod = (id) => {

        if (formValues.payment_method_id == id) {

            showToast("You cannot delete selected payment method.","error");

            return;
        }
        let token = ReactSession.get("token");

        setLoading(true);


        deletePaymentMethodService({ "id": id }, token).then(response => {
            setLoading(false);

            if (response.success) {

                getPaymentMethods(true);

            } else {
                showToast(response.message,"error");
            }
        });
    }




    useEffect(() => {

        document.title =  "Payment methods | "+process.env.REACT_APP_NAME;

        getPaymentMethods(true);

    }, []);

    const validatePaymentMethodForm = () => {
        var error = false;

        var form_errors = formErrors;

        var firsterrorid = null;

        if (formValues.card_no == "") {
            form_errors = {
                ...form_errors,
                card_no: "Required field",
                error_class: "input_error",
            };
            error = true;
            //firsterrorid = firsterrorid == null ? "card-number" : firsterrorid;
        } else if (!validator.isCreditCard(formValues.card_no)) {
            showFormError("card_no", "Enter valid Credit Card Number!");
            form_errors = {
                ...form_errors,
                card_no: "Enter valid Credit Card Number!",
                error_class: "input_error",
            };
            error = true;
            //firsterrorid = firsterrorid == null ? "card-number" : firsterrorid;
        }
        // if (formValues.card_name == "") {
        //     form_errors = {
        //         ...form_errors,
        //         card_name: "Required field",
        //         error_class: "input_error",
        //     };
        //     error = true;
        //     //firsterrorid = firsterrorid == null ? "cardholder-name" : firsterrorid;
        // }
        if (formValues.exp_date == "") {
            form_errors = {
                ...form_errors,
                exp_date: "Required field",
                error_class: "input_error",
            };
            error = true;
           // firsterrorid = firsterrorid == null ? "expiration-date" : firsterrorid;
        }
        if (formValues.cvv == "") {
            form_errors = {
                ...form_errors,
                cvv: "Required field",
                error_class: "input_error",
            };
            error = true;
            //firsterrorid = firsterrorid == null ? "cvv" : firsterrorid;
        }

        setFormErrors(form_errors);
        //
        // if (firsterrorid != null) {
        //     document.getElementById(firsterrorid).scrollIntoView({
        //         behavior: "smooth",
        //         block: "center",
        //     });
        // }

        return error;
    };

    const addNewPaymentMethod = () => {


        resetFormErrors();


        if (!cardFormLoading) {

            var error = false;

            //  resetFormErrors();

            error = validatePaymentMethodForm();

            if (!error) {
                setCardFormLoading(true);

                const token = ReactSession.get("token");

                var formData = {};

                formData["card_no"] = encryptClient(formValues.card_no);

                formData["exp_date"] = formValues.exp_date;

                formData["cvv"] = encryptClient(formValues.cvv);

                formData["card_name"] = formValues.card_name;

                addNewPaymentMethodService(formData, token)
                    .then(response => {

                        setCardFormLoading(false);

                        if (response.success) {

                            setFormValue("card_name", "");
                            setFormValue("card_no", "");
                            setFormValue("cvv", "");
                            setFormValue("exp_date", "");

                            getPaymentMethods(true);

                            setAddNewCard(false);

                            window.setLabels();
                        } else {
                            showToast(response.message,"error");
                        }
                    })
            }
        }
    }

    const validatePaymentForm = () => {
        var error = false;


        if (formValues.card_no == "") {
            error = true;

        } else if (!validator.isCreditCard(formValues.card_no)) {
            error = true;

        }

        if (formValues.exp_date == "") {
            error = true;

        }
        var new_exp_date = formValues.exp_date.replaceAll(" ","");

        var new_exp_date_arr = new_exp_date.split("/");

        if (new_exp_date.length == 5) {

            if (new Date().getTime() > new Date("20"+new_exp_date_arr[1]+"-"+new_exp_date_arr[0]+"-01").getTime() || isNaN(new Date("20"+new_exp_date_arr[1]+"-"+new_exp_date_arr[0]+"-01").getTime())
            ) {

                error = true;

            }

        } else if (new_exp_date.length > 0) {

            error = true;

        }
        if (formValues.cvv == "") {

            error = true;

        }
        return error;
    };

    const checkSaveBtnState = () => {
        var error = validatePaymentForm();

        if(error){
            return "disabled";
        } else {
            return "active";
        }
    }



    return (
        <LayoutResearcher
            isLoading={false}
            wrapclassName="rs-inner-profile-wrapper"
            skipCheck={false}
            extendedFooter={false}
            activeMenu={"payment"}
            openSupportPanel={show_support_panel} hideSupportPanel={()=>{setShowSupportPanel(false)}}
        >
            <div className="billing-plan-page-wrapper">

                <div className="page-heading-wrap accounts-page--heading billing-info-section">
                    <Text type={"h1"}>Billing</Text>
                </div>

                <BillingNavigation activeMenu="payment" />


                {isLoading && (
                    <div className={'page-loader accountloading-box'}>
                        <LoadingIcon/>
                    </div>
                )}
                {!isLoading && (
                    <>

                        <div className="payment-form-repeat">
                            <div className="row">
                                <div className="col-md-12 addcard-outer">
                                    {renderPaymentMethods()}
                                    {user.permission != "Editor" && (
                                        <>
                                            {!addNewCard && (
                                                    <div className="addnewbutton-box">     
                                                <Button
                                                    size={'large'}
                                                    type={"primary"}
                                                    iconLeft={<Icon value={'add'} colorClass={'gray-50-svg add-icon'} />}
                                                    label={'Add new card'}
                                                    onClick={() => {
                                                        setAddNewCard(true);
                                                    }}
                                                />
                                                </div>
                                                     
                                            )}
                                        </>
                                    )}
                                    {addNewCard == true && (
                                        <>
                                            <CreditCardForm isSavedForFuture={false}  isValid={(!validatePaymentForm() && formValues.payment_method_id=="")?true:false} formValues={formValues} setFormValues={(formValues)=>{   setFormValues(formValues) } }/>

                                            <div className="card-secure-text">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M16.5 10.5V6.75C16.5 5.55653 16.0259 4.41193 15.182 3.56802C14.3381 2.72411 13.1935 2.25 12 2.25C10.8065 2.25 9.66193 2.72411 8.81802 3.56802C7.97411 4.41193 7.5 5.55653 7.5 6.75V10.5M6.75 21.75H17.25C17.8467 21.75 18.419 21.5129 18.841 21.091C19.2629 20.669 19.5 20.0967 19.5 19.5V12.75C19.5 12.1533 19.2629 11.581 18.841 11.159C18.419 10.7371 17.8467 10.5 17.25 10.5H6.75C6.15326 10.5 5.58097 10.7371 5.15901 11.159C4.73705 11.581 4.5 12.1533 4.5 12.75V19.5C4.5 20.0967 4.73705 20.669 5.15901 21.091C5.58097 21.5129 6.15326 21.75 6.75 21.75Z" stroke="#676767" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>

                                                <span>This info is 128-bit SSL secured.</span>
                                            </div>

                                            <div className={"addcardbuttonbox"}>
                                                <div className="full addcardbuttonbox-inner">
                                                    <div className="flex-buttons-wrap justify-content-end">
                                                        <Button
                                                            type="primary"
                                                            size={"large"}
                                                            onClick={() => { addNewPaymentMethod() }}
                                                            microLoading={cardFormLoading}
                                                            label={"Save"}
                                                            state={checkSaveBtnState()}
                                                        />

                                                        <Button
                                                            type="secondary"
                                                            size={"large"}
                                                            onClick={() => {
                                                                setFormValue("card_name", "");
                                                                setFormValue("card_no", "");
                                                                setFormValue("cvv", "");
                                                                setFormValue("exp_date", "");
                                                                resetFormErrors();
                                                                setAddNewCard(false);
                                                            }}
                                                            label={"Cancel"}
                                                            state={"disabled"}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>

                    </>

                )}
                <a onClick={(e)=>{
                                    e.preventDefault();

                                    setShowSupportPanel(true);

                                }}  href={process.env.REACT_APP_URL+"r/support"} target="_blank" className='support-button'><svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.879 8.2817C11.05 7.2567 12.95 7.2567 14.121 8.2817C15.293 9.3067 15.293 10.9687 14.121 11.9937C13.918 12.1727 13.691 12.3197 13.451 12.4357C12.706 12.7967 12.001 13.4347 12.001 14.2627V15.0127M21 12.7627C21 13.9446 20.7672 15.1149 20.3149 16.2068C19.8626 17.2988 19.1997 18.2909 18.364 19.1267C17.5282 19.9624 16.5361 20.6253 15.4442 21.0776C14.3522 21.5299 13.1819 21.7627 12 21.7627C10.8181 21.7627 9.64778 21.5299 8.55585 21.0776C7.46392 20.6253 6.47177 19.9624 5.63604 19.1267C4.80031 18.2909 4.13738 17.2988 3.68508 16.2068C3.23279 15.1149 3 13.9446 3 12.7627C3 10.3757 3.94821 8.08656 5.63604 6.39873C7.32387 4.71091 9.61305 3.7627 12 3.7627C14.3869 3.7627 16.6761 4.71091 18.364 6.39873C20.0518 8.08656 21 10.3757 21 12.7627ZM12 18.0127H12.008V18.0207H12V18.0127Z" stroke="#9759CB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                                    Support
                                </a>
            </div>
            <ConfirmationModal
                confirm_message={confirmModal.confirm_message}
                confirm_btn_title="Delete"
                confirm_title="Delete Card"
                confirm_title_class={"text-danger"}
                openModal={confirmModal.open}
                close={() => {
                    setConfirmModal({ ...confirmModal, open: false });
                }}
                confirm={() => {
                    setConfirmModal({ ...confirmModal, open: false });
                    deletePaymentMethod(confirmModal.target_id);
                    if (formValues.payment_method_id == confirmModal.target_id) {
                        setFormValue("payment_method_id", "");
                    }
                }}
            />
        </LayoutResearcher>
    );
}