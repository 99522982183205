import PropTypes from 'prop-types';
import { theme } from '../theme';
export const CircularNameIcon = ({color, size, label, onClick, ... props})=>{
    
    
    return (
        <span onClick={props.onClick}  className={`circular-name-icon circular-name-icon-${color} circular-name-icon-${size} `}>
            {label}
        </span>
    )
    
    
}
CircularNameIcon.propTypes = {
    color: PropTypes.oneOf(["primary-pink-300", 'secondary-purple-300', 'tertiary-gold-300']),
    size: PropTypes.oneOf(theme.sizes),
    onClick: PropTypes.func,
    label:PropTypes.node
}
CircularNameIcon.defaultProps = {
    color: "primary-pink-300",
    size: "small",
    onClick:undefined
}