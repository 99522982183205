import React from "react";
import { Link } from 'react-router-dom';

export default function ResearcherAccountNavigation(props) {

    return (
        <div className="accountnaviagtion-outer">
            <span className={`${props.activeMenu=='profile' ? "active" :''}`}>
                <Link to={"/r/profile"}>
                    Your profile
                </Link>
            </span>
            <span className={`${props.activeMenu=='settings' ? "active" :''}`}>
                <Link to={"/r/settings"}>
                    Notification settings
                </Link>
            </span>
        </div>
    )
}
