import React, {useEffect, useState} from "react";
import { ReactSession } from "../../../../lib/secure_reactsession";
import PropTypes from 'prop-types';
import { Tag } from '../Tag/Tag';
import { Icon } from "../Icon/Icon";
import { Button } from "../Button/Button";
import { Text } from "../Text/Text";
import Skeleton from "react-loading-skeleton";
import moment from "moment";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { encryptId, showToast } from './../../../../lib/helpers';
import EndTestModal from './../Modal/EndTestModal';
import {
    ExportTestResult,
    getResearcherTestResultData,
    ReportTesterIntructionTestResult
} from "../../../../services/researcher_test_results";
import DownloadXlsxModal from "../Modal/DownloadXlsxModal";
import CopyLinkModal from "../../../dialog/copy_link_modal";
import ReportTesterInstructionModal from "../Modal/report_tester_instruction";
import ReportTesterModal from "../Modal/report_tester";
import {LayoutResearcher} from "../Layouts/layout_researcher";
import {Dropdown} from "react-bootstrap";
import {atom, useRecoilState, useResetRecoilState} from "recoil";
import toast from "react-hot-toast";

export const TestResultsHeader = ({ test, isSharedLink,  endedOn, isLoading, onDownloadReport,  callbackTopbar, rtl, testStatus, isStorybook}) => {
    const [linkCopied, setLinkCopied] = useState(false);
    const [openUnpublishedModal, setOpenUnpublishedModal] = useState(false );
    const [downloadTestLoading, setDownloadTestLoading] = useState(false);
    const [testerReportBtnLoading, setTesterReportBtnLoading] = useState(false);
    const [reportTesterInstructionTestLoading, setReportTesterInstructionTestLoading] = useState(false);

    const [isConfirmedTesterReportModal, setConfirmedTesterReportModal] = useState(false);
    const [reportedData, setReportedData] = useState({
        'status':'',
        'totalSelectedParticipant':0
    });

    /// react recoil state
    const reportTesterState = atom({
        key: 'reportTesterData-' + test.id,
        default: 0
    });
    const [reportTesterData, setReportTesterData] = useRecoilState(reportTesterState);


    const resetReportTesterState = useResetRecoilState(reportTesterState);

    resetReportTesterState();

    const [confirmModal, setConfirmModal] = useState({
        open: false
    });

    useEffect(() => {

        // checked
        if(test){

            // check already true
            if(!isConfirmedTesterReportModal){

                setConfirmedTesterReportModal((test.report_info_popup === 0)?true:false);
            }

           // check reported type and status
            if(!reportedData.status){

                // set reported data
                if(!isStorybook)
                setReportedData({
                    'status':test.reported_tester.status,
                    'totalSelectedParticipant':test.reported_tester.totalSelectedParticipant
                });
            }
        }

    },[test]);

    useEffect(() => {

        //if(reportedData.status === '' || reportedData.status === 'draft'){
        if(!isStorybook)
            getActiveReportedTester();
        //}
        //console.log(reportedData.status)


    }, [reportTesterData]);

    const getActiveReportedTester = ()=>{
        let token = ReactSession.get("token");

        setTesterReportBtnLoading(true);

        getResearcherTestResultData({ test_id: test.id }, token).then((response) => {

            setTesterReportBtnLoading(false);

            if (response.success) {

                // setTest(response.test);
                setReportedData({
                    'status':response.test.reported_tester.status,
                    'totalSelectedParticipant':response.test.reported_tester.totalSelectedParticipant
                });

                setConfirmedTesterReportModal((response.test.is_reported === 0)?true:false);

            } else {

                showToast(response.message,'error')

            }
        })
    }

    useEffect(()=>{

        let timeData = setInterval(() => {

            // check if tester report update from breifing & debfiefing question
            if(sessionStorage.getItem("is_reported_tester")){


                getActiveReportedTester();
                sessionStorage.setItem("is_reported_tester", "");
            }

        }, 1000);
        return () => clearInterval(timeData);

    })


    const [reportTesterInstructionModal, setReportTesterInstructionModal] = useState({
        open: false
    });

    const [reportTesterModal, setReportTesterModal] = useState({
        open: false
    });

    const [copyLinkModal, setCopyLinkModal] = useState({
        open: false
    });

    const closeUnpublishedModal = (data)=>{
        setOpenUnpublishedModal(false);
        if(data === true){
            callbackTopbar(true);
        }
    }

    const downTestHandler = ()=>{
        const token = ReactSession.get("token");
        const data = new FormData();
        data.append("test_id", test.id);

        if(downloadTestLoading){
            return false;
        }
        setDownloadTestLoading(true);
       // setConfirmModal({ ...confirmModal, open: false });
        //showToast('Your download has started. Please check your inbox in a couple of minutes.',"success");
        ExportTestResult(data, token).then((response) => {
            setDownloadTestLoading(false);
            setConfirmModal({ ...confirmModal, open: false });


            showToast(response.message,(response.success ? "success" : "error"));
        });
    }

    const reportTesterIntructionHandler = (isChecked)=>{

        if(isChecked){
            const token = ReactSession.get("token");
            const data = new FormData();
            data.append("test_id", test.id);

            //report-tester-instruction
            setReportTesterInstructionTestLoading(true);
            ReportTesterIntructionTestResult(data, token).then((response) => {
                setReportTesterInstructionTestLoading(false);
                setReportTesterInstructionModal({ ...reportTesterInstructionModal, open: false });

                if (response.success) {

                    // open report tester modal
                    setReportTesterModal({
                        open: true
                    });


                    setConfirmedTesterReportModal(true); // donot show report tester instruction modal if user has accept checkbox
                }else{
                    showToast(response.message,(response.success ? "success" : "error"));
                }


            });
        }else{

            // open report tester modal
            setReportTesterModal({
                open: true
            });

            setReportTesterInstructionModal({ ...reportTesterInstructionModal, open: false });
        }

    }

    return (
        <div className={`three-column-layout-top-header test-result-header-wrapper test-results-header-researcher ${test.status=='published'?'live-test-header':'completed-test-header'}`}>
            
            <div className="test-header-tags test-header-tag-for-mobile none-for-desktop">

                
                        {isLoading ?
                            <Skeleton width={130} height={40} style={{ marginRight: '20px', marginLeft: '20px' }} />
                            :
                            <Tag
                                title={test.methodology=="Five Seconds Test"?"5 second test":test.methodology}
                                type="pink-tag"
                            />
                        }

                        {!isSharedLink &&
                            <Text type='caption' children={test.status == 'published' ? 'Live' : 'Completed'}
                                  cssClasses={`status-tag ${'status-' + (test.status == 'published' ? 'live' : test.status)} text-capitalize`}
                            />
                        }


                        {!isSharedLink &&
                        <>
                            {test && test.status === 'completed' && test.ended_on &&

                            // <p>Ended on  <b className="font-weight-600">
                            //     {moment.utc(test.ended_on).format('DD MMM, Y')}
                            // </b></p>
                            <Text type={'caption'} cssClasses="completed-date-text none-for-mobile"
                                  fontWeight="medium-font">
                                Ended on {" "}
                                <b>
                                    {endedOn}
                                </b>
                            </Text>
                            }
                        </>
                        }

                    </div>

            <div className="d-flex test-result-inner-hdr w-100 justify-content-between">
                <div className="test-header-left-data">
                    <div className="test-name-wrap result-test-name">

                        {isLoading ?
                            <Skeleton width={150} height={40} />
                            :
                            <>

                                <Text type={'h2'} cssClasses={`${test.language=="ar"?"arabic-font":""}`}
                                    children={test.test_name}
                                />
                            </>
                        }
                    </div>

                    <div className="test-header-tags">
                        {isLoading ?
                            <Skeleton width={130} height={40} style={{ marginRight: '20px', marginLeft: '20px' }} />
                            :
                            <Tag
                                title={test.methodology=="Five Seconds Test"?"5 second test":test.methodology}
                                type="pink-tag"
                                cssClasses={'none-for-mobile'}
                            />
                        }
                        {!isSharedLink &&
                            <Text type='caption'  children={test.status == 'published' ? 'Live' : 'Completed'}
                                  cssClasses={`status-tag ${'status-' + (test.status == 'published' ? 'live' : test.status)} text-capitalize none-for-mobile`}
                            />
                        }

                        {!isSharedLink &&
                        <>
                            {test && test.status === 'completed' && test.ended_on &&

                            // <p>Ended on  <b className="font-weight-600">
                            //     {moment.utc(test.ended_on).format('DD MMM, Y')}
                            // </b></p>
                            <Text type={'caption'} cssClasses="completed-date-text none-for-mobile"
                                  fontWeight="medium-font">
                                Ended on {" "}
                                <b>
                                    {endedOn}
                                </b>
                            </Text>
                            }

                            <span
                                className="hamburger-menu test-result-header-hamburger-menu hide-for-desktop">

                        <Dropdown className="dropdown">
                            <Dropdown.Toggle
                                id={"dropdownMenuButton"}
                                className="dropdown-toggle"
                            >
                                <span className="menu-item">
                                   <Icon value={'Quick menu'} colorClass={'gray-900-svg hamburger-menu-img'}/>
                                </span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className={'hamburger-items'}>
                                <div className="drop-down-link-hold byuser-table-link-hold">
                                    {test.status === 'published' &&
                                    <Dropdown.Item onClick={() => {
                                        if (test) {
                                            setCopyLinkModal({
                                                open: true,
                                                test_id: test.id,
                                                test: test,
                                                password: test.password,
                                                link: process.env.REACT_APP_URL + "t/" + encryptId(test.id, test),
                                                resultLink: process.env.REACT_APP_URL + "v/results/" + encryptId(test.id, test) + "/overview",
                                            });
                                        }
                                    }}>
                                        <Icon value="share" size="medium"/>
                                        <span>Share link</span>

                                    </Dropdown.Item>

                                    }

                                    <Dropdown.Item onClick={() => {
                                        if (test && !isStorybook) {
                                            if(test.target_group.is_screening_questions && test.target_group.is_screening_questions==1){

                                                window.open(process.env.REACT_APP_URL + "p/screening/" + test.id,'_blank')

                                                // }
                                            }else{
                                                window.open(process.env.REACT_APP_URL + "p/" + encryptId(test.id, test),'_blank')
                                            }
                                        }
                                    }}>
                                        <Icon value="eye" size="medium"/>
                                        <span>Preview test</span>

                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={() => {
                                        setConfirmModal({
                                            open: true
                                        });
                                    }}>
                                        <Icon value="download" size="medium"/>
                                        <span>Download xlsx report</span>

                                    </Dropdown.Item>


                                    <Dropdown.Item className={(test.test_results_tester_count) ? 'active' : 'disabled'}
                                                   onClick={() => {
                                                       // check if any tester has given test
                                                       if (test.test_results_tester_count) {
                                                           if (!isConfirmedTesterReportModal) {
                                                               setReportTesterInstructionModal({
                                                                   open: true
                                                               });
                                                           } else {

                                                               setReportTesterModal({
                                                                   open: true
                                                               });
                                                           }
                                                       }
                                                   }}>
                                        <Icon value="flag" size="medium"/>
                                        <span>
                                            {(reportedData.status === 'draft') ? `(${reportedData.totalSelectedParticipant}) Reports in draft` : ((reportedData.status === 'to_review' || reportedData.status === 'reviewed')) ? `(${reportedData.totalSelectedParticipant}) Reported participants` : 'Report participants'}
                                        </span>

                                    </Dropdown.Item>


                                    {test && test.status === 'completed' && test.ended_on &&

                                    // <p>Ended on  <b className="font-weight-600">
                                    //     {moment.utc(test.ended_on).format('DD MMM, Y')}
                                    // </b></p>
                                    <Text type={'caption'} cssClasses="completed-ended-data" fontWeight="medium-font">
                                        Ended on {" "}
                                        <b>
                                            {endedOn}
                                        </b>
                                    </Text>
                                    }


                                    {test.status === 'published' &&
                                    <Dropdown.Item onClick={() => setOpenUnpublishedModal(true)}>
                                        <Icon value="exit" colorClass={'danger-red-200-svg'} size="medium"/>
                                        <span
                                            className={"text-danger"}>{test && test.methodology == 'Survey' ? 'End survey' : 'End test'}</span>

                                    </Dropdown.Item>
                                    }
                                </div>

                            </Dropdown.Menu>
                        </Dropdown>
                    </span>

                        </>
                        }

                    </div>

                </div>
                {!isSharedLink &&
                <div className="test-header-right-data">
                    {test.status === 'published' &&
                    <div className="test-result-published">
                        <Button
                            size={'medium'}
                            type={'secondary'}
                            iconLeft={<Icon value={'share'}/>}
                            label={'Share link'}
                            onClick={(e) => {
                                if (test) {
                                    setCopyLinkModal({
                                        open: true,
                                        test_id: test.id,
                                        password: test.password,
                                        link: process.env.REACT_APP_URL + "t/" + encryptId(test.id, test),
                                    });
                                }
                            }}
                            iconOnly={true}
                        />
                        <Button
                            size={'medium'}
                            type={'secondary'}
                            iconLeft={<Icon value={'eye'} />}
                            label={'Preview test'}
                            onClick={(e)=>{
                                if(test && !isStorybook){

                                    if(test.target_group.is_screening_questions && test.target_group.is_screening_questions==1){

                                        window.open(process.env.REACT_APP_URL + "p/screening/" + test.id,'_blank')

                                        // }
                                    }else{
                                        window.open(process.env.REACT_APP_URL + "p/" + encryptId(test.id, test),'_blank')
                                    }
                                }
                            }}
                            iconOnly={true}
                        />

                        <Button
                            size={'medium'}
                            type={'secondary'}
                            iconLeft={<Icon value={'download'} />}
                            label={'Download xlsx report'}
                            onClick={(e)=>{
                                setConfirmModal({
                                    open: true
                                });
                            }}
                            iconOnly={true}
                        />

                        {testerReportBtnLoading && (reportedData.status === '' || reportedData.status === 'draft')  ?
                            <Skeleton width={194} height={42} style={{borderRadius: '50px', marginLeft: '10px'}}/>
                            :
                            <Button
                                size={'medium'}
                                type={'secondary'}
                                iconLeft={<Icon value={'flag'}/>}
                                state={(test.test_results_tester_count) ? '' : 'disabled'}
                                label={
                                    (reportedData.status === 'draft') ? `(${reportedData.totalSelectedParticipant}) Reports in draft` : ((reportedData.status === 'to_review' || reportedData.status === 'reviewed')) ? `(${reportedData.totalSelectedParticipant}) Reported participants` : 'Report participants'
                                }
                                onClick={(e) => {

                                    // check if any tester has given test
                                    if (test.test_results_tester_count) {
                                        if (!isConfirmedTesterReportModal) {
                                            setReportTesterInstructionModal({
                                                open: true
                                            });
                                        } else {

                                            setReportTesterModal({
                                                open: true
                                            });
                                        }
                                    }


                                }}
                                iconOnly={true}
                            />
                        }
                        
                        <Button
                            size={'medium'}
                            type={'primary'}
                            label={test.methodology=='Survey'?'End survey':'End test'}
                            onClick={()=>setOpenUnpublishedModal(true)}
                        />
                    </div>
                    }
                    {test.status === 'completed' &&
                    <>
                        <Button
                            size={'medium'}
                            type={'secondary'}
                            iconLeft={<Icon value={'share'}/>}
                            label={'Share link'}
                            onClick={(e) => {
                                if (test) {
                                    setCopyLinkModal({
                                        open: true,
                                        test_id: test.id,
                                        password: test.password,
                                        link: process.env.REACT_APP_URL + "t/" + encryptId(test.id, test),
                                    });
                                }
                            }}
                            iconOnly={true}
                        />
                        <Button
                            size={'medium'}
                            type={'secondary'}
                            iconLeft={<Icon value={'eye'} />}
                            label={'Preview test'}
                            onClick={(e)=>{
                                if(test && !isStorybook){
                                    if(test.target_group.is_screening_questions && test.target_group.is_screening_questions==1){

                                        window.open(process.env.REACT_APP_URL + "p/screening/" + test.id,'_blank')

                                        // }
                                    }else{
                                        window.open(process.env.REACT_APP_URL + "p/" + encryptId(test.id, test),'_blank')
                                    }

                                }
                            }}
                            iconOnly={true}
                        />

                        <Button
                            size={'medium'}
                            type={'secondary'}
                            iconLeft={<Icon value={'download'} />}
                            label={'Download xlsx report'}
                            onClick={(e)=>{
                                setConfirmModal({
                                    open: true
                                });
                            }}
                            iconOnly={true}
                        />

                        {testerReportBtnLoading && (reportedData.status === '' || reportedData.status === 'draft') ?
                            <Skeleton width={194} height={42} style={{borderRadius:'50px',marginLeft:'10px'}} />
                            :
                            <Button
                                size={'medium'}
                                type={'secondary'}
                                iconLeft={<Icon value={'flag'}/>}
                                state={(test.test_results_tester_count)?'':'disabled'}
                                label={
                                    (reportedData.status === 'draft')?`(${reportedData.totalSelectedParticipant}) Reports in draft`:((reportedData.status === 'to_review' || reportedData.status === 'reviewed'))?`(${reportedData.totalSelectedParticipant}) Reported participants`:'Report participants'
                                }
                                onClick={(e)=>{

                                    // check if any tester has given test
                                    if(test.test_results_tester_count){
                                        if(!isConfirmedTesterReportModal){
                                            setReportTesterInstructionModal({
                                                open: true
                                            });
                                        }else{

                                            setReportTesterModal({
                                                open: true
                                            });
                                        }
                                    }


                                }}
                                iconOnly={true}
                            />

                        }

                    </>
                    }
                </div>
                }
            </div>
            {(!isStorybook) &&
            <>
                <EndTestModal
                    test={test}
                    openModal={openUnpublishedModal}
                    closeModal={closeUnpublishedModal}/>

                <DownloadXlsxModal
                    confirm_message={confirmModal.confirm_message}
                    confirm_btn_title={confirmModal.confirm_btn_title}
                    confirm_title={confirmModal.confirm_title}
                    confirm_title_class={confirmModal.confirm_title_class}
                    openModal={confirmModal.open}
                    btnLoading={downloadTestLoading}
                    close={() => {
                        setConfirmModal({...confirmModal, open: false});
                    }}
                    confirm={(e) => {

                        downTestHandler(e);
                        // setConfirmModal({ ...confirmModal, open: false });
                        /*if (confirmModal.confirmation_for === "testdelete") {
                            deleteTestApi(confirmModal.test_id);
                        } else if (confirmModal.confirmation_for === "endtest") {
                            endTestApi(confirmModal.test_id);
                        }*/

                    }}
                />

                <ReportTesterInstructionModal
                    openModal={reportTesterInstructionModal.open}
                    btnLoading={reportTesterInstructionTestLoading}
                    close={() => {
                        setReportTesterInstructionModal({...reportTesterInstructionModal, open: false});
                    }}
                    confirm={(isChecked) => {

                        reportTesterIntructionHandler(isChecked);

                    }}
                />

                <ReportTesterModal
                    test={test}
                    openModal={reportTesterModal.open}
                    btnLoading={reportTesterInstructionTestLoading}
                    close={() => {

                        setReportTesterModal({...reportTesterModal, open: false});
                    }}
                    confirm={(type, totalParticipantSelected) => {

                        // set reported data
                        setReportedData({
                            'status': (totalParticipantSelected) ? type : '',
                            'totalSelectedParticipant': totalParticipantSelected
                        });
                        setReportTesterModal({...reportTesterModal, open: false});

                        // hide instruction model if user has submit request
                        if (type && type !== 'draft') {
                            setConfirmedTesterReportModal(true);
                        }
                    }}
                />

                <CopyLinkModal
                    link={copyLinkModal.link}
                    resultLink={copyLinkModal.resultLink}
                    password={copyLinkModal.password}
                    test_id={copyLinkModal.test_id}
                    test={copyLinkModal.test}
                    openModal={copyLinkModal.open}
                    close={() => {
                        setCopyLinkModal({...copyLinkModal, open: false});
                    }}
                />
            </>
            }

        </div>
    );
};



TestResultsHeader.defaultProps = {
    test: PropTypes.object,
    endedOn: PropTypes.string,
    isLoading: PropTypes.bool,
    onCopyLink: PropTypes.func,
    onDownloadReport: PropTypes.func,
    onEndTest: PropTypes.func,

};

TestResultsHeader.defaultProps = {
    // onChange: undefined

};