import { Box } from "../../../themes/userq/Box/Box";
import { Icon } from "../../../themes/userq/Icon/Icon";
import { Text } from "../../../themes/userq/Text/Text";
import { Tooltip } from "../../../themes/userq/Tooltip/Tooltip";
import BarChart from "./bar_chart_v2";
import { useRef, useState } from "react";
import exportAsImage from "../../../../lib/exportAsImage";
export const HorizontalBarChartStatsBoxRankingScale = ({ rankingchartData, test, marginLeft, marginRight }) => {
	const ref = useRef();
	const tipref = useRef();
	const exportPngRef = useRef();
	const [tooltip, showTooltip] = useState({
		tooltipLeft: 0,
		tooltipTop: 0,
		tooltipData: "",
		open: false,
		show: false
	});

	const handlePointerMove = (key, tooltipdata) => {


		return (
			(event) => {


				let tipPosition = "bottom";

				var tool_width = tipref && tipref.current ? tipref.current.clientWidth : 150;


				var tool_height = tipref && tipref.current ? tipref.current.clientHeight + 12 : 106;




				let containerX = ('clientX' in event ? event.clientX : 0) - ref.current.getBoundingClientRect().left - tool_width / 2;
				let containerY = ('clientY' in event ? event.clientY : 0) - ref.current.getBoundingClientRect().top - tool_height - 10;


				var window_width = window.innerWidth;

				var window_height = window.innerHeight;

				if (event.clientX + tool_width / 2 > window_width) {
					containerX = ('clientX' in event ? event.clientX : 0) - ref.current.getBoundingClientRect().left - tool_width / 2 - (event.clientX + tool_width / 2 - window_width);

				}
				if (event.clientX - tool_width / 2 < 0) {
					containerX = ('clientX' in event ? event.clientX : 0) - ref.current.getBoundingClientRect().left - tool_width / 2 + (tool_width / 2 - event.clientX);
				}

				showTooltip({
					tooltipLeft: containerX,
					tooltipTop: containerY,
					tooltipData: tooltipdata,
					open: true,
					show: tooltip.open && tipref && tipref.current ? true : false,
					tipPostion: tipPosition
				});
			});
	}

	var colors = [
		{ color: "#FF6DB1", text: "" },
		{ color: "#9759CB", text: "" },
		{ color: "#F2C690", text: "" },
		{ color: "#698CE7", text: "" },
		{ color: "#31F0E5", text: "" },
		{ color: "#378B10", text: "" },
		{ color: "#E32F83", text: "" },
		{ color: "#793EAB", text: "" },
		{ color: "#D7A56A", text: "" },
		{ color: "#3668E7", text: "" },
		{ color: "#11BFB4", text: "" },
		{ color: "#164103", text: "" },
		{ color: "#FF8AC1", text: "" },
		{ color: "#AC7AD5", text: "" },
		{ color: "#FFE2C0", text: "" },
		{ color: "#9EB4ED", text: "" },
		{ color: "#90F2EC", text: "" },
		{ color: "#BFF0A8", text: "" },
		{ color: "#A72561", text: "" },
		{ color: "#5C208D", text: "" },
		{ color: "#C38D4D", text: "" },
		{ color: "#0D3BB0", text: "" },
		{ color: "#0A877F", text: "" },
		{ color: "#A72561", text: "" },
		{ color: "#5C208D", text: "" },
		{ color: "#C38D4D", text: "" },
		{ color: "#06236C", text: "" },
		{ color: "#045A54", text: "" },
	];

	function sortByValue(jsObj) {
		var sortedArray = [];
		for (var i in jsObj) {
			// Push each JSON Object entry in array by [value, key]
			sortedArray.push([jsObj[i], i]);
		}
		// return sortedArray.sort(function(a, b){return a - b});
		return sortedArray.sort().reverse();
	}


	const renderHorizontalBarChart = () => {

		var lChartData = [];

		var index = 0;

		var data1 = rankingchartData;
		var data2 = sortByValue(data1);
		const data = {};
		data2.forEach((item) => {
			data[item[1]] = item[0]
		});

		var max = 0;
		for (var key in data) {

			max = max + 1;
		}
		var sum = 0;
		for (var key in data) {

			sum += data[key];
		}

		var bars = [];

		for (var key in data) {
			var percentage = max > 0 ? Math.round(((data[key] / max) * 100) * 100) / 100 : 0;

			var percentage_label = sum > 0 ? Math.round(((data[key] / sum) * 100) * 100) / 100 : 0;

			var color = colors[6];
			if (index < 29) {

				color = colors[index];
			}

			lChartData.push({
				label: key,
				value: data[key],
				color: color,
				percentage_label: percentage + "%"
			});
			// console.log(lChartData[0].label);

			index++;

			bars.push((
				<div className="horiz-chart-data-repeat">

					<div className={`horizonatal-chart-left-side`}>
						<div className="horiz-chart-top-info d-flex align-items-end">

							<h3  style={{ color: color.color }} className={`h3 heading ${data[key].value != 0 ? color.text : ''}`}>{Math.round(data[key] * 100) / 100}</h3>

							<Text type={'caption'} fontWeight={'medium-font'} cssClasses={'color-black ml-2'}>{"Average score: " + Math.round(data[key] * 100) / 100}</Text>

						</div>

						<div className="horiz-chart-hold-bg">
							<div class={`horiz-chart-wrap ${percentage <= 30 ? 'small-width-chart-area' : ''} ${percentage >= 1 ? 'small-width-chart-area-1' : ''}`}
								 style={{ width: percentage + "%", backgroundColor: color.color }}


							>
								<div
									className={`edu-graph-count ${percentage == 0 ? 'p-0' : ''}`}
									style={{ backgroundColor: color.color, width: percentage + "%" }}
								>

								</div>
							</div>
						</div>

					</div>

					<div className="horizonatal-chart-right-side">
						<div className="horiz-chart-text-wrap d-flex align-items-center">
							<Text type={'body-text-2'} fontWeight={'medium-font'} cssClasses={`color-black ${test.language == "ar" ? "arabic-font" : ""}`}>{key}</Text>
						</div>

					</div>

				</div>
			))
		}

		if (lChartData.length > 0) {
			return (<div className="barchart-ranking-scale-inner">
				<Text type="subtitle-1" fontWeight="medium-font">
					<div className="highest-ranked-info d-flex">
						{(lChartData[0].value != lChartData[1].value) &&
						<div className='info-right-highest'><span className={`highest-ranked-option ${test.language == "ar" ? "arabic-font" : ""}`}>{lChartData[0].label}</span> was ranked higher than the rest by the participants.</div>
						}
						{(lChartData[0].value == lChartData[1].value) &&
						<div className='info-right-highest'><span className={`highest-ranked-option ${test.language == "ar" ? "arabic-font" : ""}`}>{lChartData[0].label}</span> and <span className={`highest-ranked-option ${test.language == "ar" ? "cairo-font" : ""}`}>{lChartData[1].label}</span> were ranked higher than the rest by the participants.</div>
						}

						{(lChartData.length > 2 && lChartData[0].value == lChartData[1].value == lChartData[2].value) &&
						<div className='info-right-highest'><span className={`highest-ranked-option ${test.language == "ar" ? "arabic-font" : ""}`}>{lChartData[0].label}</span>, <span className='highest-ranked-option'>{lChartData[1].label}</span> and <span className='highest-ranked-option'>{lChartData[2].label}</span> were ranked higher than the rest by the participants.</div>
						}
					</div>
				</Text>

				<div className="mt-40 mb-40">
					<div className="position-relative">
						<div className="download-chart-png cursor-pointer" onClick={() => exportAsImage(exportPngRef.current, "Ranking scale")}>
							<Icon value={'download'} size={'small'} hover={'true'}></Icon>
							<div className="tooltip-wrapper top-tooltip">
								<p class="body-text body-text-2 normal-font ">Download</p>
							</div>
						</div>
 
						<div className="box-large" ref={exportPngRef}>
							<div className="edu-label-graph-bg" ref={ref} style={{ position: 'relative' }}>
								<div className="edu-graph-inner-hold"> {bars}</div>
								{tooltip.open ? (
									<>
										<Tooltip
											ref={tipref}
											type={tooltip.tipPosition}
											key={Math.random()} // needed for bounds to update correctly
											style={{ zIndex: 0, display: tooltip.show ? "block" : 'block', whiteSpace: "nowrap", width: "auto", minWidth: "150px", margin: 0, position: "absolute", left: (tooltip.tooltipLeft) + "px", top: (tooltip.tooltipTop) + "px" }}
											data={tooltip.tooltipData}
											className="tooltipChart"

										/>
									</>
								) : (
									<></>
								)}
							</div>
						</div>
					</div>
					{/* </Box> */}
				</div></div>);
		} else {
			return <div className="info-not-available">No info available</div>
		}
	}

	return <div>
		{renderHorizontalBarChart()}
	</div>;
}