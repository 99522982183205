import React, { useEffect, useState } from "react";
import Task from "./task";
import TreeSortIntroduction from "./tree_sort_introduction";
import {useLocation, useNavigate} from 'react-router-dom';
import toast from 'react-hot-toast';
import { saveTaskResultService } from "../../../../services/test_result";
//import { ReactSession } from "react-client-session";
import { ReactSession } from "../../../../lib/secure_reactsession";
import ConfirmModal from "../../../dialog/confirm_modal";
import { FormattedMessage } from "react-intl";
import ConfirmationModal from "../../../themes/userq/Modal/ConfirmationModal";
import { ToastMessage } from "../../../themes/userq/ToastMessage/ToastMessage";
import { settings } from "../../../../data/settings";
import TreeTestFollowupQuestion from "../tree_sort/tree_test_follow_up_question";
import { Icon } from "../../../themes/userq/Icon/Icon";
import { Text } from "../../../themes/userq/Text/Text";
import { Button } from "../../../themes/userq/Button/Button";

export default function TreeSort({test, result, callback,timeSpent}){
    const [initialScreen, setInitialScreen] = useState(true);
    const [tasks,setTasks] = useState([]);
    const [activeTask,setActiveTask] = useState({});
    const [activeTaskIndex,setActiveTaskIndex] = useState(0);
    const [tree,setTree] = useState([]);
    const [path,setPath] = useState([]);
    const [formLoading,setFormLoading] = useState(false);
    const [answers,setAnswers] = useState({});
    const [skippedTasks,setSkippedTasks] = useState([]);
    const location = useLocation();
    const [startTime, setStartTime] = useState(0);
    const [confirm, setConfirm] = useState({open:false});
    const [navPath, setNavPath] = useState([]);

    const [currentTaskQuestion,setCurrentTaskQuestion] = useState({});
    const [lastTask,setLastTask] = useState(false);


    const [questionScreen, setQuestionScreen] = useState(false);

    const navigate = useNavigate();

    useEffect(()=>{
        setTasks(test.tasks);
    },[test]);

    const showError = (error) => {
        
        
        
        toast(
            <ToastMessage type={"error"} message={error} closable={false} onClose={() => { toast.dismiss(); }} />,
            {
                className: "errortoast",
                position: "bottom-center",
                duration: settings.toast_duration,
            }
        );
    };

    const showSuccess = (success) => {
        toast(
            <ToastMessage type={"success"} message={success} closable={true} onClose={() => { toast.dismiss(); }} />,
            {
                className: "successtoast",
                position: "bottom-center",
                duration: settings.toast_duration,
            }
        );
    };
    const saveTreeTasksAnswers = (ans) => {
        var p = location.pathname.substr(1 ,1);


        if(p=="p"){
            callback();
        } else {

            setFormLoading(true);

            saveTaskResultService({
                test_id: test.id,
                result_id: result.id,
                guest_token:ReactSession.get("guest_token"),
                tasks:ans,
                skipped:skippedTasks,
                time_spent:timeSpent,
            }, ReactSession.get("token")).then((response) => {
    
                setFormLoading(false);
    
                if (response.success) {
                    callback();
    
                } else {

                    showError(response.message);
                    /*toast((
                        <div className='toastinner'>
                            {response.message}
                            <a  onClick={()=>{toast.dismiss(); } }>&times;</a>
                        </div>), {
                        className: 'errortoast',
                        position: 'bottom-center'
                    });*/
                }
            });
            
        }
    }
    const saveTreeTasks = () => {
        saveTreeTasksAnswers(answers);
    }
    const skipTreeTask = (task_id, nav_path) => {

        return () => {
            
            
            var answersLoc = {...answers};

            answersLoc[task_id] = {answer_id: null, time_spent: new Date().getTime() - startTime, path:nav_path } ;

            setAnswers(answersLoc);
            
            if(skippedTasks.length<2){

                if(navPath.length>0){
                    skippedTasks.push({"task_id":task_id,"is_direct":0});
                } else {
                    skippedTasks.push({"task_id":task_id,"is_direct":1});
                }
                
    
                setSkippedTasks(skippedTasks);
                
                if(activeTaskIndex < tasks.length-1){
                    
                    setAnswers(answersLoc);

                    //delete answers[task_id];

                    //setAnswers(answers);
                    
                    setStartTime(new Date().getTime());
    
                    setActiveTaskIndex(activeTaskIndex+1)
    
                    setActiveTask(tasks[activeTaskIndex+1]);
    
                    setTree(test.tree);

                    //var tree_loc = [{"id":-1,"title":test.language=="en"?"Home":"القائمة الرئيسية",children:test.tree}];
                      
                    var tree_loc = [{"id":-1,"title":test.home_name,children:test.tree}];
                      

                    setPath(tree_loc);
    
                    setNavPath([]);



                } else {
                    saveTreeTasksAnswers(answersLoc);
                }
    
            } else {
                
                showError(<>You can skip maximum of 2 tasks.</>)

                /*toast((
                    <div className='toastinner'>
                        <>You can skip maximum of 2 tasks.</>
                        <a  onClick={()=>{toast.dismiss(); } }>&times;</a>
                    </div>), {
                    className: 'errortoast',
                    position: 'bottom-center'
                });*/
            }
        };
        
    }
    return (
        <>
            {initialScreen &&
                <TreeSortIntroduction goToFirstTask={
                    ()=>{ 
                        setInitialScreen(false);

                        if(tasks.length > 0){
                            setStartTime(new Date().getTime());
                            setActiveTask(tasks[activeTaskIndex]);
                            //var tree_loc = [{"id":-1,"title":test.language=="en"?"Home":"القائمة الرئيسية",children:test.tree}];
                            var tree_loc = [{"id":-1,"title":test.home_name,children:test.tree}];
                            
                            setTree(test.tree);
                            setPath(tree_loc);
                        }
                        
                        
                    }
                } 
                test={test}
                />
            }
            {!initialScreen &&
                <>

                    {!questionScreen ?
                        <>
                            {tasks.length > 0
                            ?
                        <Task activeTaskIndex={activeTaskIndex} totalTasks={tasks.length} test={test} tree={tree}
                              answer={answers[activeTask.id] && answers[activeTask.id]['answer_id']}
                              fullTree={test.tree} navPath={navPath} path={path} task={activeTask}
                              next={
                                  (tree, path, nav_path) => {


                                      setTree(tree);

                                      setPath(path);

                                      setNavPath(nav_path);

                                  }
                              }
                              formLoading={formLoading}
                              nextTask={(task_id) => {
                                  if (answers[task_id]) {

                                      if (activeTaskIndex < tasks.length - 1) {

                                          var answer = answers[task_id];

                                          answer["time_spent"] = new Date().getTime() - startTime;
                                          setAnswers({...answers, [task_id]: answer});

                                          setStartTime(new Date().getTime())
                                          setActiveTaskIndex(activeTaskIndex + 1)

                                          setActiveTask(tasks[activeTaskIndex + 1]);

                                          // show question for given answer of task
                                          // if(tasks[activeTaskIndex + 1] && tasks[activeTaskIndex + 1].tree_test_task_question.length > 0){
                                          if(activeTask && activeTask.tree_test_task_question.length > 0){

                                              setQuestionScreen(true);
                                              setCurrentTaskQuestion(tasks[activeTaskIndex]);
                                          }



                                          setTree(test.tree);

                                          var tree_loc = [{
                                              "id": -1,
                                              "title": test.language == "en" ? "Home" : "القائمة الرئيسية",
                                              children: test.tree
                                          }];


                                          setPath(tree_loc);


                                          setNavPath([]);

                                          window.scrollTo(0, 0);

                                      } else {

                                          console.log(activeTask)
                                          if(activeTask.tree_test_task_question && activeTask.tree_test_task_question.length>0){

                                              setCurrentTaskQuestion(activeTask);
                                              setQuestionScreen(true);
                                              setLastTask(true);
                                          }else{

                                              saveTreeTasks();
                                          }

                                      }

                                  } else {

                                      showError(<><FormattedMessage id="Please select answer before going ahead."/></>);

                                      /*toast((
                                          <div className='toastinner'>
                                              <><FormattedMessage id="Please select answer before going ahead." /></>
                                              <a  onClick={()=>{toast.dismiss(); } }>&times;</a>
                                          </div>), {
                                          className: 'errortoast',
                                          position: 'bottom-center'
                                      });*/
                                  }
                              }}
                              skipTask={(task_id, nav_path) => {

                                  if (skippedTasks.length < 2) {
                                      if (tasks.length == skippedTasks.length + 1) {

                                          setConfirm({
                                              ...confirm,
                                              open: true,
                                              callback: skipTreeTask(task_id, nav_path)
                                          });
                                      } else {
                                          const skip = skipTreeTask(task_id, nav_path);

                                          skip();


                                          window.scrollTo(0, 0);
                                      }
                                  } else {

                                      showError("You can skip maximum of 2 tasks.");

                                      /*toast((
                                          <div className='toastinner'>
                                              <><FormattedMessage id="You can skip maximum of 2 tasks." /></>
                                              <a  onClick={()=>{toast.dismiss(); } }>&times;</a>
                                          </div>), {
                                          className: 'errortoast',
                                          position: 'bottom-center'
                                      });*/

                                  }
                              }}
                              setAnswer={
                                  (task_id, answer_id, nav_path) => {

                                      /* if(answers[task_id]){
                                              toast((
                                                  <div className='toastinner'>
                                                      <>You cannot change the answer once selected. Please click Next to Proceed.</>
                                                      <a  onClick={()=>{toast.dismiss(); } }>&times;</a>
                                                  </div>), {
                                                  className: 'errortoast',
                                                  position: 'bottom-center'
                                              });
                                          } else {*/
                                      setNavPath(nav_path);
                                      setAnswers({
                                          ...answers,
                                          [task_id]: {
                                              answer_id: answer_id,
                                              time_spent: new Date().getTime() - startTime,
                                              path: nav_path
                                          }
                                      });
                                      //}

                                  }
                              }
                              unsetAnswer={
                                  (task_id) => {

                                      var ansLoc = Object.assign({}, answers);
                                      delete ansLoc[task_id];
                                      setAnswers(ansLoc)

                                  }
                              }
                        />
                            :
                            <div className="login-data-wrap flex-wrap already-taken-testlogin-wrap">
                                <div className="container">
                                    <div
                                        className={`no-participants-data no-questions-data no-questions-added`}
                                    >
                                        <div
                                            className="no-page-data-wrap"
                                            style={{
                                                marginTop: "80px",
                                            }}
                                        >
                                            <div className="no-data-icon">
                                                <Icon
                                                    colorClass={
                                                        "gray-700-svg"
                                                    }
                                                    value={"attention"}
                                                    size={"large"}
                                                />
                                            </div>

                                            <Text
                                                type={"subtitle-1"}
                                                fontWeight="medium-font"
                                            >
                                                Please add a task to be
                                                able to see the tree
                                            </Text>
                                        </div>

                                        <div
                                            className={
                                                "fotter-wrap text-center"
                                            }
                                        >
                                            <Button
                                                type="primary"
                                                label="Add task"
                                                size={"large"}
                                                onClick={(e) => {
                                                    e.preventDefault();

                                                    let user =
                                                        ReactSession.get(
                                                            "user"
                                                        );

                                                    if (
                                                        user &&
                                                        user.role ==
                                                            "Researcher"
                                                    ) {
                                                        navigate(
                                                            "/r/test/" +
                                                                test.id +
                                                                "/tree-test"
                                                        );
                                                    } else if (!user) {
                                                        navigate(
                                                            "/researcher/login"
                                                        );
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            }
                        </>
                        :
                        <div className="mt-40 tree-test-follow-up-question">

                            {/*{console.log(activeTask)}*/}
                            <TreeTestFollowupQuestion
                                tree_test_task_question={currentTaskQuestion.tree_test_task_question}
                                test={test}
                                result={result}
                                questionType="Survey"
                                timeSpent={timeSpent}
                                moveToNextTask={() => {
                                    if(lastTask){
                                        saveTreeTasks();
                                    }else{
                                        setQuestionScreen(false);
                                    }
                                }}
                                 task={currentTaskQuestion}
                            />
                        </div>
                    }
                </>
            }
            <ConfirmationModal openModal={confirm.open} confirm_message="If you skip this task, you confirm you want to abandon the test. Click cancel to head back to the test or OK to exit." confirm_title="Are you sure?" close={()=>{setConfirm({...confirm, open:false})}} 
                confirm = {()=>{
                    confirm.callback();
                    setConfirm({...confirm, open:false});
                }}
             />
            
        </>

    );
}