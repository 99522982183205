import React from "react";
import { useNavigate, useParams, Link, useSearchParams } from "react-router-dom";
//import { ReactSession } from "react-client-session";
import { ReactSession } from "../../../lib/secure_reactsession";
//import LayoutResearcherFullWidth from "../../layouts/layout_researcher_full_width";
import { LayoutResearcher } from "../../themes/userq/Layouts/layout_researcher";
import { Stepper } from './../../themes/userq/Stepper/Stepper';
import { Button } from './../../themes/userq/Button/Button';
import { LoadingIcon } from "./../../themes/userq/Loader/loadingIcon";
import { Accordian } from './../../themes/userq/Accordion/Accordion';
import { Icon } from './../../themes/userq/Icon/Icon';
import { EditSortableTree } from '../../themes/userq/Tree/edit_sortable_tree';
import Header from "./components/header";

import Progress from "../study/progress/progress.js";
import Topbar from "../study/topbar/topbar.js";
import SortableTree from "react-sortable-tree";
import { maxLengthCheck, renderTestTitleCreate, showToast } from "../../../lib/helpers";
import {
	addNodeUnderParent,
	getNodeAtPath,
	removeNodeAtPath,
} from "react-sortable-tree";
import Task from "./tree_sort/task";
import { useState, useEffect, useRef } from "react";
import {
	addNodeService,
	getTestData,
	removeNodeService,
	removeNodesService,
	addTaskService,
	updateTreeService,
	importCSVService,
	updateTreeNodeService,
	updateTreeTaskService,
	removeTreeTaskService,
	updateHomeNodeService,
	updateNodePositionService,
	updateTreeTasksService,
} from "../../../services/test";
import toast from "react-hot-toast";
import ConfirmModal from "../../themes/userq/ConfirmModal/confirm_modal";
import ConfirmationModal from "../../themes/userq/Modal/ConfirmationModal";
import { useTestBuilderData } from "./contexts/TestBuilderContext.js";

import moment from "moment";
import redirectToStep from "./redirect_to_step";
import { FormattedMessage } from "react-intl";
import InputSearchField from "../../layouts/elements/fields/inputSearch";
import { count, tree } from "d3";
import { Text } from "../../themes/userq/Text/Text";
import { ToastMessage } from "../../themes/userq/ToastMessage/ToastMessage";
import { Input } from "../../themes/userq/Input/Input";
import { IntroductionQuestions } from "./introduction_questions.js";
import { ConclusionQuestions } from './conclusion_questions.js';
import { Search } from "../../themes/userq/Search/Search";
import { settings } from "../../../data/settings";
import { GuideLink } from "../../themes/userq/GuideLink/GuideLink";
import { Helmet } from "react-helmet";
import HelpModal from "../../themes/userq/Modal/HelpModal";
import { CheckBox } from "../../themes/userq/CheckBox/CheckBox";
import ChangeOrderModal from "./tree_sort/change_order.js";

//const div = document.querySelector('.target-group-data-wrapper.full');
//const resizeObserver = new ResizeObserver(() => {
//  console.log(div.clientHeight);
//});

//resizeObserver.observe(div);

var jump_to_error = false;
let tree_path_super = [];

export default function TreeSortTest() {
	let { test_id } = useParams();
	const nodeRef = useRef(null);
	const cnodeRef = useRef(null);
	const fileRef = useRef(null);
	const homeRef = useRef(null);
	const sectionsRef = useRef([]);
	const navigate = useNavigate();
	ReactSession.setStoreType("localStorage");
	const [formValues, setFormValues] = useState({
		tree: [],
		tasks: [],
		home_name: "Home",
		show_import_tree_instructions: 0,
		tree_error: null
	});
	const [show_import_tree_instructions, setShowImportTreeInstructions] = useState(false);
	const [validate_form, setValidateForm] = useState(false);

	const { updateTestLength, getTestLength } = useTestBuilderData();

	const [validate_form_after, setValidateFormAfter] = useState(false);

	let treeController = useRef();

	const [nodeUpdated, setNodeUpdated] = useState(0);

	const [answer_paths, setAnswerPaths] = useState({});

	const [treeSize, setTreeSize] = useState({ "nodes": 0, children: 0 });

	var tree_children = 0;

	var tree_nodes = 0;

	const [pageLoading, setPageLoading] = useState(false);

	const [isSaved, setIsSaved] = useState({ 'status': '', 'message': '' });

	const [treeLoading, setTreeLoading] = useState(false);

	const [treeStepLoading, setTreeStepLoading] = useState(false);

	const [lastSaved, setLastSaved] = useState(null);

	const [isLoading, setLoading] = useState(false);

	const [confirmModal, setConfirmModal] = useState({ open: false });

	const [confirmDeleteModal, setConfirmDeleteModal] = useState({ open: false });

	const [confirmDeleteTaskModal, setConfirmDeleteTaskModal] = useState({ open: false, task_index:null });

	const [confirmDeleteInnerModal, setConfirmDeleteInnerModal] = useState({ open: false, callback: null });

	const [searchParams] = useSearchParams()

	const [accordians, setAccordians] = useState({ 'introduction': false, treetest: searchParams.get("error") == "true"?true:false, conclusion: false })

	const [importModal, setImportModal] = useState({ open: false });

	const [search, setSearch] = useState("");

	const [test, setTest] = useState("");

	const [publishing_credits, setPublishingCredits] = useState(0);

	const [editable_text, setEditableText] = useState("");

	const [editable_home, setEditableHome] = useState(false);

	const [step, setStep] = useState(0);

	const [autoSave, setAutoSave] = useState(false);

    const [stepperError, setStepperError] = useState(false);

	const [changeOrderModal, setChangeOrderModal] = useState({open:false});

	const [addTaskLoading, setAddTaskLoading] = useState(false);

	let editable_node = false;
    
    const [introCount, setIntroCount] = useState(0);
    const [concCount, setConcCount] = useState(0);
    const [introductionQuestions, setIntroductionQuestions] = useState(null);
    const [conclusionQuestions, setConclusionQuestions] = useState(null);

	const [helpModal, setHelpModal] = useState({ open: false });

	const taskRefs = useRef({});

	const [firstErrorId, setFirstErrorId] = useState(null);

	let firsterrorid = null;

	function showmore(divID) {
		//document.getElementById(divID).style.height = '600px';
		document.getElementById(divID).classList.add("expendit");
	}

	function showless(divID) {
		document.getElementById(divID).classList.remove("expendit");
	}

	const [formErrors, setFormErrors] = useState({
		node: "",
		error_class: "input_error",
	});

	const [show_support_panel, setShowSupportPanel] = useState(false);

	let treeError = false;

	let treeAnswerError = false;

	let answerExist = false;

	let lessthan5children = true;

	let timer;
	

	const introductionRef = useRef(null);

	const conclusionRef = useRef(null);

	function autoAdjustHeight() {
		timer = setTimeout(() => {

			var slides = document.getElementsByClassName("target-group-data-repeat");
			for (var i = 0; i < slides.length; i++) {
				var bbheight = 0;
				var ccheight = 0;
				var bb = slides
					.item(i)
					.getElementsByClassName("targer-group-left-wrap")[0];
				var cc = slides
					.item(i)
					.getElementsByClassName("target-group-info-repeat")[0];
				if (bb) {
					bbheight = bb.offsetHeight;
				}
				if (cc) {
					ccheight = cc.offsetHeight;
				}
				//console.log('>>'+bbheight+'<><>'+ccheight);
				if (bbheight < ccheight) {
					cc.classList.add("lessshow");
					cc.style.height = bbheight + "px";
				} else {
					cc.classList.add("normalshow");
				}
			}
		}, 3000);

	}
	useEffect(() => {
		
		if(validate_form){

			jump_to_error = true;

			setValidateForm(false);

			if(searchParams.get("error")=="true"){
				
				setStepperError(true);
	
				validateFullForm();

				
			}
		}

	}, [validate_form]);

	useEffect(() => {
		
		if(validate_form_after){

			setValidateFormAfter(false);

			validateFullForm();
		}

	}, [validate_form_after]);
	/*useEffect(() => {
		
		if(document.getElementById(firstErrorId)){


			document.getElementById(firstErrorId).scrollIntoView({
				behavior: "smooth",
				block: "center",
			});
		}

	}, [firstErrorId]);*/
	/*useEffect(() => {
		
		autoAdjustHeight();

		return () => clearTimeout(timer);
	}, []);*/

	const showError = (error) => {

		toast(
			<ToastMessage type={"error"} message={error} closable={false} onClose={() => { toast.dismiss(); }} />,
			{
				className: "errortoast",
				position: "bottom-center",
				duration: settings.toast_duration,
				id:"error"
			}
		);
	};

	const navigateTree = (tree, task) => {
		tree.forEach(function (item) {
			if (task == "expand") {
				item["expanded"] = true;
			}
			if (task == "error") {
				if (!item.title) {
					treeError = true;

					item["error"] = true;
				} else {
					item["error"] = false;
				}
			}
			if (task == "error_btn") {
				if (!item.title) {
					treeError = true;

				}
			}
			if (task == "check_editable") {
				if (item["editable"]) {
					editable_node = true;

				}
			}
			if (task == "editable") {
				item["editable"] = false;
			}
			if (item.children.length > 0) {
				tree_children = tree_children + item.children.length;
				navigateTree(item.children, task);
			}
		});
	};
	const navigateTreeOther = (tree, task, node_id="") => {
		tree.forEach(function (item) {
			
			if (task == "editable" && item["id"]!=node_id) {
				//console.log(node_id);
				//console.log(item);
				item["editable"] = false;
			}
			if (item.children.length > 0) {
				tree_children = tree_children + item.children.length;
				navigateTreeOther(item.children, task,node_id);
			}
		});
	};

	const checkIfNonLeaf = (tree, answer_id) => {
		tree.forEach(function (item) {
			if (answer_id == item.id && item.children.length > 0) {
				treeAnswerError = true;

				//item["error"] = true;
			}

			if (item.children.length > 0) {
				checkIfNonLeaf(item.children, answer_id);
			}
		});
	};
	const checkIfNodeExist = (tree, answer_id) => {
		tree.forEach(function (item) {
			if (answer_id == item.id) {
				answerExist = true;

				//item["error"] = true;
			}

			if (item.children.length > 0) {
				checkIfNodeExist(item.children, answer_id);
			}
		});
	};
	
	
	
	

	const getPathOfNode = (tree, node_id, treePath,pathIndex) => {

		
		tree.forEach(function (item) {

			let tree_path = [...treePath];

			tree_path.push(pathIndex);

			//console.log(tree_path)

			if(item.id==node_id){
				tree_path_super = tree_path;
			}
			
			
			if (item.children.length > 0) {

				pathIndex = pathIndex+1;
				getPathOfNode(item.children, node_id, tree_path,pathIndex);
			}
			pathIndex = pathIndex+1;
		});
	};
	const getTestApi = () => {
		let token = ReactSession.get("token");

		setPageLoading(true);

		getTestData({ test_id: test_id }, token).then((response) => {
			setPageLoading(false);

			if (response.success) {

				getTestLength(test_id);

				setTest(response.test);
                setIntroCount(response.test.briefingQuestion.length);
                setConcCount(response.test.debriefingQuestion.length);
                setIntroductionQuestions(response.test.briefingQuestion);
                setConclusionQuestions(response.test.debriefingQuestion);

				setPublishingCredits(response.settings.publishing_credits);

				//setFormValues({...formValues, home_name:response.test.home_name});

				if (response.test.status) {
					if (response.test.status == "published") {
						navigate("/r/project/" + response.test.project_id);
					} else {
						if (
                            response.test.welcome_title == "" ||
                            response.test.welcome_description == ""
                        ) {
                            localStorage.setItem("stepper_error", "The welcome page cannot be empty! Please enter a title and a description.");
                            navigate("/r/test/" + test_id + "/welcome?error=true");
                            showError("The welcome page cannot be empty! Please enter a title and a description.");
                        }
					}
				}
				// redirectToStep({
				//   test_id: test_id,
				//   current_step: 2,
				//   saved_step: response.test.saved_step,
				//   navigate: navigate,
				// });
				/*if(response.test.saved_step || response.test.saved_step==0){
					
					if(response.test.saved_step<1){
					
						
						//navigate("/r/test/"+test_id+"/introduction");
					}
				}*/
				setLastSaved(moment(response.test.updated_at));

				tree_nodes = response.test.tree.length;

				tree_children = 0;

				navigateTree(response.test.tree, "expand");

				setTreeSize({ nodes: tree_nodes, children: tree_children });

				setFormValues({ tree: response.test.tree, tasks: response.test.tasks, home_name: response.test.home_name, show_import_tree_instructions: response.settings.show_import_tree_instructions==1 ? 1 : 0 });
//console.log({ tree: response.test.tree, tasks: response.test.tasks, home_name: response.test.home_name, show_import_tree_instructions: response.settings.show_import_tree_instructions==1 ? 1 : 0 })
				/*if (response.test.tasks.length == 0) {
					addTask();
				}*/
				setTimeout(()=>{
					jump_to_error = true;

					setValidateForm(true);
				},100)
				

				autoAdjustHeight();

			} else {
                showError(response.message);
			}
		});
	};
	const validateFullForm = ()=>{
		
		var error = false;

		treeError = false;

		treeAnswerError = false;

		var form_errors = {
			tree: [],
			tasks: [],
			home_name: "Home",
			tree_error: null
		};
		formValues.tasks.forEach(function (item) {
			item["errors"] = {
				title: null,
				answer_error: null,
				answer: null,
				error_class: "input_error",
			};
		});
		if(conclusionRef.current){
			conclusionRef.current.resetErrors();

		}
		if(introductionRef.current){
			introductionRef.current.resetErrors();
		}
		for(var i=0; i<formValues.tasks.length; i++){

			var task = formValues.tasks[i];
			
			if(taskRefs.current[task.id]){

				var task_ref = taskRefs.current[task.id];

				if(task_ref.followupRef){

					var followup_ref = task_ref.followupRef;

					if(followup_ref.questionRef){

						followup_ref.questionRef.resetErrors();

						
					}
					if(followup_ref.editQuestionRef){

						followup_ref.editQuestionRef.resetErrors();

						
					}
				}
			}
		}
		/*if (formValues.tasks.length == 0) {
			error = true;
		}*/
		firsterrorid=null;

		setFirstErrorId(null);

		
		var question_error = false;
		
		if(introductionRef.current){
			error = !introductionRef.current.questionValidateForm();

			question_error = error;
		}
		if(!error){
			if(formValues.tree.length==0){
				if(firsterrorid==null){
					firsterrorid = "treeSection";
				}
				form_errors = {...form_errors, tree_error: "Tree nodes are required"};
				
				error = true;

			} else if(formValues.tasks.length==0){
				if(firsterrorid==null){
					firsterrorid = "tasksSection";
				}
				form_errors = {...form_errors, tasks_error: "At least one task is required"};
				
				error = true;

			} else { 

				form_errors = {...form_errors, tree_error: null};

				navigateTree(formValues.tree, "error");

				if(treeError && firsterrorid==null){
					
					error = true;

					firsterrorid = "treeSection";
					
					form_errors = {...form_errors, tree_error: "Titles are missing for some of the tree nodes"};

				} else {

					var task_error = false;
					
					formValues.tasks.forEach(function (item) {

						if(task_error){
							return true;
						}
				
						item["errors"] = {
							title: null,
							answer_error: null,
							answer: null,
							error_class: "input_error",
						};
						
						if (!item.title) {
			
							item["errors"] = { ...item.errors, title: "A task description is required" };
			
							if(firsterrorid==null){
								firsterrorid = "task"+item.id;
							}
			
							task_error = true;
						}
						else if (!item.correct_answer_id) {
							item["errors"] = { ...item.errors, answer_error: "A correct answer for the task is required" };
			
							task_error = true;
			
							if(firsterrorid==null){
								firsterrorid = "task-answer"+item.id;
							}
			
						} else {
							/**/
							treeAnswerError = false;
							
							checkIfNonLeaf(formValues.tree, item.correct_answer_id);
			
							if(treeAnswerError){

								task_error = true;

								if(firsterrorid==null){
									firsterrorid = "task-answer"+item.id;
								}
							} else {
				
								answerExist = false;
								
								checkIfNodeExist(formValues.tree, item.correct_answer_id);
				
								if(!answerExist){
								
									task_error = true;
									if(firsterrorid==null){
										firsterrorid = "task-answer"+item.id;
									}
								}
							}
						}
						if(!task_error){
							if(taskRefs.current[task.id]){

								var task_ref = taskRefs.current[task.id];

								var question_error_trace = false;
		
								if(task_ref.followupRef){
		
									var followup_ref = task_ref.followupRef;
		
									if(followup_ref.questionRef){
		
										question_error_trace = followup_ref.questionRef.handleQuestionSubmit(true);
		
										
									}
									if(followup_ref.editQuestionRef){
		
										question_error_trace = followup_ref.editQuestionRef.handleQuestionSubmit(true);
										
									}
								}
							}
						}
						if(question_error_trace){
							task_error = true;
							question_error = question_error_trace;
						}
					});
					if(task_error){
						error = true;
					}
				}
			}
		}
		if(!error && conclusionRef.current){
			error = !conclusionRef.current.questionValidateForm();

			question_error = true;
		}
		setFormValues({ ...formValues, tasks: formValues.tasks });
		

		if (error && !question_error && firsterrorid != null && accordians["treetest"] && jump_to_error) {
			
            if(document.getElementById(firsterrorid)){

				document.getElementById(firsterrorid).scrollIntoView({
					behavior: "smooth",
					block: "center",
				});
			}
			
        }
		
		if (error && !question_error) {

			
			localStorage.setItem("stepper_error","Some required information for the tree testing is missing or incomplete. Please review and try again.");
            
			if(jump_to_error){
				setStepperError(true);
				showError("Some required information for the tree testing is missing or incomplete. Please review and try again.");
			} 
			
		} else {
			setStepperError(false);
			localStorage.setItem("stepper_error",null);
            
		}
		setFormErrors(form_errors);


		return error;
	}
	const saveTreeStep = () => {

		jump_to_error = true;

		var error = validateFullForm();

		/*if (error) {

			toast(
				<ToastMessage type={"error"} message={<FormattedMessage id="Some required information for the tree testing is missing or incomplete. Please review and try again." />} closable={true} onClose={() => { toast.dismiss(); }} />,
				{
					className: "errortoast",
					position: "bottom-center",
                    duration: settings.toast_duration,
				}
			);
		}*/
		if (!error) {

			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({
				'event' : 'create_test_step2',
				'type': test.methodology,
				'userID' : ReactSession.get("user") ? ReactSession.get("user").user_id : ""
			});
			navigate("/r/test/" + test_id + "/thank-you");
			//saveTreeData(formValues, true);
		}
	};
	const updateNodePosition = (form) => {

		if (treeController.current) {
			treeController.current.abort();

		}
		const controller = new AbortController();

		treeController.current = controller;

		

		let data = {};

		data["test_id"] = test_id;

		data["nextParent"] = form['nextParentNode'];

		data["node_id"] =  form['node_id'];

		

		setIsSaved({ status: "loading", message: "" })


		updateNodePositionService(data, ReactSession.get("token"), treeController.current?.signal).then((response) => {

			//setLoading(false);

			setTreeStepLoading(false);

			if (response.success) {

				setIsSaved({ status: "success", message: "" })
				setLastSaved(moment.now());
				
			} else {
				setIsSaved({ status: "error", message: response.message })
				
                showError(response.message);
			}
		});
	};
	const saveTreeData = (form, shouldnavigate, callback=null) => {

		if (treeController.current) {
			treeController.current.abort();

		}
		const controller = new AbortController();

		treeController.current = controller;

		if (shouldnavigate) {
			setTreeStepLoading(true);
		} else {
			//setLoading(true);
		}

		let new_tree = [];

		for (var i = 0; i < form.tree.length; i++) {

			let temp_tree = form.tree[i];

			delete temp_tree["test_id"];

			new_tree.push(temp_tree);

		}


		let data = Object.assign({}, form);

		data["test_id"] = test_id;

		data["tree"] = new_tree;

		data["autosave"] = 1;

		if (shouldnavigate) {
			data["autosave"] = 0;
		}

		setIsSaved({ status: "loading", message: "" })

		updateTreeService(data, ReactSession.get("token"), treeController.current?.signal).then((response) => {

			//setLoading(false);

			setTreeStepLoading(false);

			if (response.success) {

				getTestLength(test_id);

				setIsSaved({ status: "success", message: "" })
				setLastSaved(moment.now());
				
				if(callback){
					callback();
				}
				if (shouldnavigate) {

					window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        'event' : 'create_test_step2',
                        'type': test.methodology,
                        'userID' : ReactSession.get("user") ? ReactSession.get("user").user_id : ""
                    });
					navigate("/r/test/" + test_id + "/thank-you");
				}
			} else {
				setIsSaved({ status: "error", message: response.message })
				
                showError(response.message);
			}
		});
	};
	useEffect(() => {
		document.title = process.env.REACT_APP_NAME + " - Tree testing";

		//window.addEventListener('scroll', handleScroll);

		getTestApi();
        if(searchParams.get("error")=="true"){
            setStepperError(true);
        }
            
            
	}, []);

	useEffect(() => {



		if (nodeRef && nodeRef.current) {
			nodeRef.current.focus();

			/*cnodeRef.current.scrollIntoView({
				behavior: "smooth",
				block: "center",
			});*/
		}

		/*const timer = setInterval(() => {
			if (test && test.status != "published") {
				saveTreeData(formValues, false);
			}	
		}, 30000);*/

		tree_nodes = formValues.tree.length;

		tree_children = 0;

		navigateTree(formValues.tree, "");

		setTreeSize({ nodes: tree_nodes, children: tree_children });


		/*return () => {
			clearTimeout(timer);
		};*/


	}, [formValues]);
	useEffect(() => {
		if (homeRef && homeRef.current) {

			homeRef.current.focus();


		}

	}, [editable_home]);
	const set = (name) => {
		return ({ target: { value } }) => {
			setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
		};
	};
	const setFormValue = (name, value) => {
		setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
		//console.log(formValues)
	};
	const showFormError = (name, value) => {
		let formErrorsLocal = Object.assign({}, formErrors);
		for (var key in formErrors) {
			formErrorsLocal[key] = null;
		}
		formErrorsLocal[name] = value;
		formErrorsLocal["error_class"] = "input_error";
		setFormErrors(formErrorsLocal);
		//console.log(formErrorsLocal);
	};
	const clearFormValue = (name) => {
		return ({ target: { value } }) => {
			setTimeout(function () {
				setFormValues((oldValues) => ({ ...oldValues, [name]: "" }));
			}, 1000);
		};
	};
	const resetFormErrors = () => {
		let formErrorsLocal = Object.assign({}, formErrors);
		for (var key in formErrors) {
			formErrorsLocal[key] = null;
		}
		setFormErrors(formErrorsLocal);
	};
	const addNode = (e) => {
		if(e){

		e.preventDefault();
		}
		//if (node != "") {
		let token = ReactSession.get("token");

		setTreeLoading(true);

		setIsSaved({ status: "loading", message: "" })

		setEditableText("");

		addNodeService(
			{ test_id: test_id, parent_id: null, title: "", placeholder: "Level 1" },
			token
		).then((response) => {
			setTreeLoading(false);

			if (response.success) {

				setIsSaved({ status: "success", message: "" })

				var tree = [...formValues.tree];

				response.node.editable = true;

				tree.push(response.node);

				setFormValue("tree", tree);

				setFormValue("tree", tree);

				autoAdjustHeight();

				if(formErrors.tree_error){
					setStepperError(false);

					setFormErrors({...formErrors, tree_error:null});
				}

			} else {
				setIsSaved({ status: "error", message: response.message });

				/*toast(
					<div className="toastinner">
						{response.message}
						<a
							onClick={() => {
								toast.dismiss();
							}}
						>
							&times;
						</a>
					</div>,
					{
						className: "errortoast",
						position: "bottom-center",
					}
				);*/
			}
		});

		/*} else {
			showFormError("node", "Please enter name");
		}*/
	};
	const addTask = (e = null) => {
		if (e) {
			e.preventDefault();
		}
		let token = ReactSession.get("token");

		setFormErrors({...formErrors, tasks_error:null});

		//setLoading(true);

		if(formValues.tasks.length >= 15) {
			showToast("You've reached the maximum number of tasks. If you wish to add more, please consider removing some first.","error");
			return;
		}
		if(addTaskLoading){
			return;
		}
		setAddTaskLoading(true);

		setIsSaved({ status: "loading", message: "" })
		addTaskService({ test_id: test_id, title: "" }, token).then(
			(response) => {
				//setLoading(false);

				setAddTaskLoading(false);


				if (response.success) {

					getTestLength(test_id);
					setIsSaved({ status: "success", message: "" })
					var tasks = formValues.tasks;
					tasks.push({
						id: response.task.id,
						title: response.task.title,
						correct_answer: "",
						correct_answer_id: null,
						questions:[]
					});
					setFormValue("tasks", tasks);

					autoAdjustHeight();

				} else {
					setIsSaved({ status: "error", message: response.message })
					
					/*toast(
						
						<ToastMessage type={"error"} message={response.message} closable={true} onClose={() => { toast.dismiss(); }} />,
						{
							className: "errortoast",
							position: "bottom-center",
						}
					);*/
				}
			}
		);
	};
	const removeNodeApi = (node, path) => {
		//console.log(path);
		let token = ReactSession.get("token");

		setTreeLoading(true);

		setIsSaved({ status: "loading", message: "" });


		removeNodeService({ test_id: test_id, id: node.id }, token).then(
			(response) => {
				setTreeLoading(false);

				if (response.success) {

					setIsSaved({ status: "success", message: "" })

					var id = node.id;
					var tree = removeNodeAtPath({
						treeData: formValues.tree,
						path: path,
						getNodeKey: ({ treeIndex: number, node: TreeNode }) => {
							return number;
						},
						ignoreCollapsed: true,
					});
					setFormValue("tree", tree);

					var tasks = formValues.tasks;

					var to_update = false;
					tasks.forEach(function (test) {
						if (test.correct_answer_id == id) {
							test.correct_answer_id = null;
							test.correct_answer = "";

							to_update = true;

							//updateTask(test);
						}
					});
					
					if(to_update){
						updateAllTasks(tasks)
					}
					jump_to_error = false;

					treeError = false;

					navigateTree(tree, "error");

					if(!treeError){
						
						if(formErrors.tree_error){
							
							setStepperError(false);

							setFormErrors({...formErrors, tree_error:false});
						}
					}
					//setValidateFormAfter(true);

					
					
					autoAdjustHeight();

				} else {

					setIsSaved({ status: "error", message: response.message })

                    showError(response.message);
				}
			}
		);
	};
	const removeNode = (node, path) => {
		return (e) => {
			setConfirmModal({ open: true, node: node, path: path });
		};
	};
	const removeAllNodesApi = () => {

		let token = ReactSession.get("token");

		setTreeLoading(true);

		removeNodesService({ test_id: test_id }, token).then(
			(response) => {
				setTreeLoading(false);

				if (response.success) {

					setFormValue("tree", []);

					autoAdjustHeight();

				} else {
					toast(
						<div className="toastinner">
							{response.message}
							<a
								onClick={() => {
									toast.dismiss();
								}}
							>
								&times;
							</a>
						</div>,
						{
							className: "errortoast",
							position: "bottom-center",
						}
					);
				}
			}
		);
	};
	const makeEditableNode = (path) => {
		return (e) => {
			let found_node = getNodeAtPath({
				treeData: formValues.tree,
				path: path, // list of tree indexes on the editing node
				getNodeKey: ({ treeIndex }) => treeIndex,
			});
			if (!found_node.node.editable) {
				navigateTreeOther(formValues.tree,"editable",found_node.node.id);
				found_node.node.editable = true;

				setEditableText(found_node.node.title);

				//console.log()
				setFormValue("tree", formValues.tree);
			}
			tree_path_super = [];

			getPathOfNode(formValues.tree, found_node.node.id,[],0);
		};
	};
	const updateNodeTitle = (path , blur=true, text) => {
		//return (e) => {
		
		//tree_path_super = [];

		let found_node = getNodeAtPath({
			treeData: formValues.tree,
			path: path, // list of tree indexes on the editing node
			getNodeKey: ({ treeIndex }) => treeIndex,
		});
		if(blur){
			found_node.node.editable = false;
		}
		//if (editable_text != "") {
			
		//}
		if(text){
			found_node.node.title = text;
		} else {
			found_node.node.title = editable_text;
		}
		//console.log(answer_paths);

		let answer_paths_loc = {};

		for (var key in answer_paths) {

			answer_paths_loc[key] = Object.assign([],answer_paths[key]);

			for (var i = 0; i < answer_paths[key].length; i++) {

				if (answer_paths[key][i]["id"] == found_node.node.id) {
				
					answer_paths_loc[key][i]["title"] = found_node.node.title;
				}
			}
		}
		setAnswerPaths(answer_paths_loc);

		//setFormValue("tree", formValues.tree);

		setFormValues({...formValues, tree:formValues.tree});

		setNodeUpdated(nodeUpdated+1);
		
		treeError = false;

		navigateTree(formValues.tree, "error");

		if(!treeError){
			
			if(formErrors.tree_error){
				
				setStepperError(false);

				setFormErrors({...formErrors, tree_error:false});
			}
		}
		jump_to_error = false;

		//setValidateFormAfter(true);

		
		
		if (treeController.current) {
			treeController.current.abort();

		}
		const controller = new AbortController();

		treeController.current = controller;

		


		let data = {};

		data["test_id"] = test_id;

		data["node_id"] = found_node.node.id;

		data["title"] = found_node.node.title;


		setIsSaved({ status: "loading", message: "" })

		updateTreeNodeService(data, ReactSession.get("token"), treeController.current?.signal).then((response) => {

			if(!response){
				return false;
			}

			if (response.success) {

				setIsSaved({ status: "success", message: "" })
				setLastSaved(moment.now());
				
			} else {
				setIsSaved({ status: "error", message: response.message })
				
                showError(response.message);
			}
		});
		/*if(parentNode=="root"){

			saveTreeData({ ...formValues, tree: formValues.tree }, false, ()=>{
				addNode(null);
			});

		} else {

			saveTreeData({ ...formValues, tree: formValues.tree }, false, parentNode ? ()=>{
				addChildUnderParent(parentNode);
			}:null);
		}*/

		
		
		//updateTreeService({...formValues, tree:formValues.tree},false);
		//};
	};
	const updateTask = (task) => {
		
		

		let data = {};

		data["test_id"] = test_id;

		data["task_id"] = task.id;

		data["title"] = task.title;

		data["correct_answer_id"] = task.correct_answer_id;


		setIsSaved({ status: "loading", message: "" });

		updateTreeTaskService(data, ReactSession.get("token")).then((response) => {


			if (response.success) {

				getTestLength(test_id);
				setIsSaved({ status: "success", message: "" })
				setLastSaved(moment.now());
				
			} else {
				setIsSaved({ status: "error", message: response.message })
				
                showError(response.message);
			}
		});
		
	};
	const updateAllTasks = (tasks) => {
		
		

		let data = {};

		data["test_id"] = test_id;

		data["tasks"] = tasks;

		setIsSaved({ status: "loading", message: "" });

		updateTreeTasksService(data, ReactSession.get("token")).then((response) => {


			if (response.success) {

				setIsSaved({ status: "success", message: "" })
				setLastSaved(moment.now());
				
			} else {
				setIsSaved({ status: "error", message: response.message })
				
                showError(response.message);
			}
		});
		
	};
	const removeTask = (task) => {
		
		


		let data = {};

		data["test_id"] = test_id;

		data["id"] = task.id;

		setIsSaved({ status: "loading", message: "" });


		removeTreeTaskService(data, ReactSession.get("token")).then((response) => {


			if (response.success) {

				getTestLength(test_id);
				setIsSaved({ status: "success", message: "" })
				setLastSaved(moment.now());
				
			} else {
				setIsSaved({ status: "error", message: response.message })
				
                showError(response.message);
			}
		});
		
	};
	const updateHomeNode = (title) => {
		
		
		let data = {};

		data["test_id"] = test_id;

		data["home_name"] = title;

		setIsSaved({ status: "loading", message: "" });

		updateHomeNodeService(data, ReactSession.get("token")).then((response) => {


			if (response.success) {

				setIsSaved({ status: "success", message: "" })
				setLastSaved(moment.now());
				
			} else {
				setIsSaved({ status: "error", message: response.message })
				
                showError(response.message);
			}
		});
		
	};
	const addChildUnderParent = (parent_node) => {

		if (!treeLoading) {
			setTreeLoading(true);

			navigateTree(formValues.tree, "editable");

			setEditableText("");

			tree_path_super = [];


			getPathOfNode(formValues.tree, parent_node.id,[]	, 0);

			let parentNode = getNodeAtPath({
				treeData: formValues.tree,
				path: tree_path_super,
				getNodeKey: ({ treeIndex }) => treeIndex,
				//ignoreCollapsed: true,
			});
			
			setIsSaved({ status: "loading", message: "" })

			addNodeService(
				{ test_id: test_id, parent_id: parent_node.id, title: "" },
				ReactSession.get("token")
			).then((response) => {
				setTreeLoading(false);

				if (response.success) {
					setIsSaved({ status: "success", message: "" })

					let getNodeKey = ({ node: object, treeIndex: number }) => {
						return number;
					};


					response.node.editable = true;

					let parentKey = getNodeKey(parentNode);

					
					var tree = addNodeUnderParent({
						treeData: formValues.tree,
						newNode: response.node,
						expandParent: true,
						parentKey: parentKey,
						ignoreCollapsed: true,
						getNodeKey: ({ treeIndex }) => treeIndex,
					});
					setFormValue("tree", tree.treeData);

					if(formErrors.treeError){
						setStepperError(false);
					}
					setFormErrors({...formErrors, tree_error:null});

					autoAdjustHeight();


				} else {
					setIsSaved({ status: "error", message: response.message })

					toast(
						<div className="toastinner">
							{response.message}
							<a
								onClick={() => {
									toast.dismiss();
								}}
							>
								&times;
							</a>
						</div>,
						{
							className: "errortoast",
							position: "bottom-center",
						}
					);
				}
			});
		}
	};
	const addChild = (path) => {

		//return (e) => {

		if (path.length > 5) {
			toast(
				<ToastMessage type={"error"} message={<FormattedMessage id="You can add up to 5 child nodes only." />} closable={true} onClose={() => { toast.dismiss(); }} />,
				{
					className: "errortoast",
					position: "bottom-center",
                    duration: settings.toast_duration,
				}
			);

			return;
		}
		//console.log(tree_path_super)

		/*if(tree_path_super.length>0){
			

			let node = getNodeAtPath({
				treeData: formValues.tree,
				path: tree_path_super,
				getNodeKey: ({ treeIndex }) => treeIndex,
				ignoreCollapsed: true,
			});
			if(node){
				updateNodeTitle(tree_path_super);
			}
		}*/
		let parentNode = getNodeAtPath({
			treeData: formValues.tree,
			path: path,
			getNodeKey: ({ treeIndex }) => treeIndex,
			ignoreCollapsed: true,
		});
		

		if (!treeLoading) {
			setTreeLoading(true);

			navigateTree(formValues.tree, "editable");

			//setFormValues({...formValues, tree:formValues.tree});

			setEditableText("");


			setIsSaved({ status: "loading", message: "" })

			addNodeService(
				{ test_id: test_id, parent_id: parentNode.node.id, title: "" },
				ReactSession.get("token")
			).then((response) => {
				setTreeLoading(false);

				if (response.success) {
					setIsSaved({ status: "success", message: "" })

					let getNodeKey = ({ node: object, treeIndex: number }) => {
						return number;
					};


					response.node.editable = true;

					let parentKey = getNodeKey(parentNode);

					var tree = addNodeUnderParent({
						treeData: formValues.tree,
						newNode: response.node,
						expandParent: true,
						parentKey: parentKey,
						ignoreCollapsed: true,
						getNodeKey: ({ treeIndex }) => treeIndex,
					});
					tree_path_super = [];

					getPathOfNode(tree.treeData, response.node.id,[],0);

					navigateTreeOther(tree.treeData,"editable",response.node.id)

					//console.log(tree_path_super)

					//console.log(response.node)

					var new_tasks = [];

					var to_update = false;
		
					for (var i = 0; i < formValues.tasks.length; i++) {
						var temp_task = formValues.tasks[i];
						
						if (formValues.tasks[i]["correct_answer_id"] == parentNode.node.id) {
							temp_task["correct_answer_id"] = null;

							temp_task["correct_answer"] = null;

							to_update = true;

							//updateTask(temp_task);
						}
						
						new_tasks.push(temp_task);
					}
					//console.log({...formValues, tasks:new_tasks, "tree": tree.treeData})
					setFormValues({...formValues, tasks:new_tasks, "tree": tree.treeData});

					if(to_update){
						//console.log(new_tasks);
						updateAllTasks(new_tasks);
					}
					
					autoAdjustHeight();


				} else {
					setIsSaved({ status: "error", message: response.message })

					toast(
						<div className="toastinner">
							{response.message}
							<a
								onClick={() => {
									toast.dismiss();
								}}
							>
								&times;
							</a>
						</div>,
						{
							className: "errortoast",
							position: "bottom-center",
						}
					);
				}
			});
		}
		//};
	};
	const removeAnswer = (node_id) => {

		var tasks_to_remove = [];

		var new_tasks = [];


		for (var key in answer_paths) {
			for (var i = 0; i < answer_paths[key].length; i++) {

				if (answer_paths[key][i]["id"] == node_id) {
					tasks_to_remove.push(key);
				}
			}
		}
		for (var i = 0; i < formValues.tasks.length; i++) {
			var temp_task = formValues.tasks[i];
			for (var j = 0; j < tasks_to_remove.length; j++) {
				if (parseInt(tasks_to_remove[j]) == i) {
					temp_task["correct_answer_id"] = null;
				}
			}
			new_tasks.push(temp_task);
		}
		setFormValues({ ...formValues, tasks: new_tasks });

		autoAdjustHeight();
	}
	const renderTasks = () => {
		var tasks_render = [];
		for (var i = 0; i < formValues.tasks.length; i++) {
			//console.log(formValues.tree);
			let task = formValues.tasks[i];
			console.log(task);
			tasks_render.push(
				<Task
					ref={(element)=>{taskRefs.current[task.id] = element;}}
					nodeUpdated={nodeUpdated}
					setStepperError={setStepperError}
					errors={
						formValues.tasks[i].errors
							? formValues.tasks[i].errors
							: { title: null, answer_error: null, error_class: "input_error" }
					}
					updateTaskPath={(task_id, path) => {

						setAnswerPaths({ ...answer_paths, [task_id]: path })
					}}
					title={"Task " + (i + 1)}
					key={"task" + i}
					language={test ? test.language : ""}
					index={i}
					total_tasks={formValues.tasks.length}
					task={formValues.tasks[i]}
					tree={formValues.tree}
					onChange={(task, index) => {
						var tasks = formValues.tasks;
						tasks[index] = task;
						setFormValue("tasks", tasks);

						

						//saveTreeData({ ...formValues, tasks: tasks }, false);
					}}
					onBlur={(task, index) => {
						var tasks = formValues.tasks;
						tasks[index] = task;
						setFormValue("tasks", tasks);

						if(task["errors"] && task["errors"]["title"] && task.title){
							setStepperError(false);
						}
						if(task["errors"] && task["errors"]["answer_error"]  && task.correct_answer_id){
							setStepperError(false);
						}
						updateTask(task);

						//saveTreeData({ ...formValues, tasks: tasks }, false);
					}}
					removeTask={(index) => {

						if (formValues.tasks.length > 1) {
							var tasks = formValues.tasks;

							removeTask(formValues.tasks[index]);

							delete answer_paths[index];
							tasks.splice(index, 1);
							setFormValue("tasks", tasks);

							
							//saveTreeData({ ...formValues, tasks: tasks }, false);

						} else {
                            showError("You cannot remove the only task.");
						}
					}}
					setIsSaved={setIsSaved}
					test={test}
					updateTask={(task, index)=>{

						//setOverrideErrors(false);
	
						var tasksLoc = Object.assign([], formValues.tasks);
	
						tasksLoc[index] = task;
	
						setFormValues({...formValues, tasks: tasksLoc});
					}}
					changeOrder={()=>{
						setChangeOrderModal({...changeOrderModal, open:true});
	
					}} 
					setConfirmDeleteTaskModal={setConfirmDeleteTaskModal}
				/>
			);
		}
		return tasks_render;
	};
	const importCSVApi = (csvFile) => {
		let token = ReactSession.get("token");

		setLoading(true);

		var data = new FormData();

		data.append("file", csvFile);

		data.append("test_id", test_id);

		data.append("show_import_tree_instructions", show_import_tree_instructions==1 ? 0 : 1);

		importCSVService(data, token).then((response) => {
			setLoading(false);

			if (response.success) {

				getTestApi();
				fileRef.current.value = null;
			} else {
				if(response.import_error){

					setFormValues({...formValues, show_import_tree_instructions:0});

					showError(<>This file could not be imported correctly. Please review the <a target="_blank" href="https://userq.com/tree-testing-guide-how-to-create-a-tree-test/">guidelines</a> and try again.</>)

				} else {
                	showError(response.message);
				}
			}
		});
	};
	const handleFileInput = (event) => {
		//prevent the browser from opening the image
		event.preventDefault();
		event.stopPropagation();
		//let's grab the image file
		let csvFile = event.target.files[0];

		setHelpModal({...helpModal, open:false});

		//console.log(formValues)

		// does not show confirmation msg if tree is empty
		if (formValues.tree.length === 0) {
			importCSVApi(csvFile);
		} else {
			setImportModal({ open: true, csvFile: csvFile });
		}


	};
	const getNodeBg = (extendedNode) => {
		if (extendedNode.path.length == 2) {
			return "rgba(150, 255, 252, 0.8)";
		} else if (extendedNode.path.length == 3) {
			return "rgba(150, 255, 252, 0.35)";
		} else if (extendedNode.path.length >= 4) {
			return "rgba(150, 255, 252, 0.2)";
		} else {
			return "rgba(150, 255, 252, 1)";
		}
	};
	const checkIfMoreThan5Children = (tree, child_count) => {
		tree.forEach(function (item) {
			var child_count_loc = child_count + 1;

			if (child_count_loc > 6) {
				lessthan5children = false;

			}
			if (item.children.length > 0) {
				checkIfMoreThan5Children(item.children, child_count_loc);
			}
		})
	}
	const checkTreeBtnStatus = () => {

		var error = false;

		treeError = false;

		treeAnswerError = false;

		answerExist = false;

		if (formValues.tasks.length == 0) {
			error = true;
		}
		formValues.tasks.forEach(function (item) {

			if (!item.correct_answer_id) {
				error = true;
			} else {
				if (!error) {
					checkIfNonLeaf(formValues.tree, item.correct_answer_id);
				}
				if (!error) {
					checkIfNodeExist(formValues.tree, item.correct_answer_id);
				}
				
			}
			if (!error && !item.title) {

				error = true;
			}
		});
		navigateTree(formValues.tree, "error_btn");


		if (treeError) {
			error = true;
		}
		if (treeAnswerError) {
			error = true;
		}
		if (error) {
			return error;
		}
		return false;
	}

	return (
		<LayoutResearcher
			isLoading={false}
			fixed_header_target={true}
			wrapClass={`create-test-data-wrapper ${test && test.language == "ar" ? "arabic_wrapper_main" : ""}`}
			skipCheck={false}
			extendedFooter={false}
			activeMenu={""}
			openSupportPanel={show_support_panel} hideSupportPanel={()=>{setShowSupportPanel(false)}}
		>
			<Helmet>
                <title>Create tree test - step 2 | UserQ</title>
            </Helmet>
			<div className="three-column-layout">
				<Header
					test={test}
					isLoading={pageLoading}
					isSaved={isSaved}
					credits_required={publishing_credits}
				/>
				<div className="three-column-layout-body">
					<div className="three-column-layout-left">
						<Stepper
							test={test}
							completed_step={1}
							stepCallback={() => {
								saveTreeData(formValues, false);
							}}
                            error={stepperError}
						/>
					</div>
					{pageLoading &&
						<div className="test-section-loader"> <LoadingIcon /></div>
					}
					{!pageLoading &&
						<>
							<div className="three-column-layout-center">
								<div className="create-tree-test-page-wrap">
									<div className="section-minimum-height">
										<Accordian
											title={"Introduction questions"}
											subtitle={"(Optional)"}

											open={accordians['introduction'] ? true : false}
											onChange={() => {
												let new_accordians = Object.assign({}, accordians);

												for (var key in accordians) {
													if (key == "introduction") {
														if (accordians[key]) {
															new_accordians[key] = false;
														} else {
															new_accordians[key] = true;
														}
													}

												}
												setAccordians(new_accordians);
											}}
											questionCount={introCount}
										>
											<IntroductionQuestions ref={introductionRef} questions={introductionQuestions} test={test} setLoading={(status) => setIsSaved(status)} changeCount={(newCount)=>{setIntroCount(newCount)}} updateQuestions={(questions)=>{setIntroductionQuestions([...questions])}}/>
										</Accordian>
										<Accordian
											title={"Tree test"}
											open={accordians['treetest'] ? true : false}
											onChange={() => {
												let new_accordians = Object.assign({}, accordians);

												for (var key in accordians) {
													if (key == "treetest") {
														if (accordians[key]) {
															new_accordians[key] = false;
														} else {
															new_accordians[key] = true;
														}
													}

												}
												setAccordians(new_accordians);
											}}
										>
											<div className="card-sorting-test-wrap tree-test-section-wrap">
												<div className="cardsorting-wrap-top ">
													<Text type={'subtitle-2'} fontWeight={'medium-font'} >
														Creating the tree
													</Text>
													<div className="cat-card-top-sub-data tree-test-sub-head">
														<Text
															type={'body-text-2'}
															fontWeight={'medium-font'}
														>
															Your Tree is a text-only version of your website hierarchy. Your category labels (first level, second level, etc.) are called 'nodes', and subcategories are known as 'child nodes'.



														</Text>
														<Text
															type={'body-text-2'}
															fontWeight={'medium-font'}
														>

																You can also import a CSV file to automatically create your tree.
															<label className="label upload-image-label">
																<span className="change-image-link">
																	<input
																		type="file"
																		className="default-file-input"
																		onChange={handleFileInput}
																		ref={fileRef}
																		onClick={(event) => {
																			event.target.value = null
																		}}
																		style={{display: "none"}}
																	/>
																	
																</span>
															</label>
															
															<a
																href={
																	process.env.REACT_APP_IMG_URL+'download-data.php?fileSource='+process.env.REACT_APP_IMG_URL +
																	"/docs/import-tree-example.csv"
																}
																target="_blank"
															>
																Download CSV template.
															</a>

														</Text>
													</div>
												</div>

												<div className="cardsorting-wrap-mid-data">

													<div
														id="importSection"
														ref={(element) =>
															sectionsRef.current[0] ??
															sectionsRef.current.push(element)
														}
														target="sidebarMenu1"
														className="sidebarSection"
													>
														<div className="tree-test-top-search">

															<div className="d-flex align-items-center justify-content-between tree-test-top-wrap">
																<div className="build-tree-top-left">
																	<Text type={'body-text-1'} fontWeight={'medium-font'} >
																		Build the tree
																	</Text>
																	{treeLoading && (
																		<i
																			className="fa fa-spinner fa-spin"
																			aria-hidden="true"
																			style={{ marginLeft: "5px" }}
																		></i>
																	)}
																</div>
																<div className="build-tree-top-right">
																	{formValues.show_import_tree_instructions==1 ?
																	<button
																		type="button"
																		className="secondary-btn secondary-small btn-with-icon  btn-with-icon-right"
																		onClick={()=>{setHelpModal({...helpModal, open:true})}}
																	>
																		<label className="label upload-image-label">
																		<span className="change-image-link">
																			<span className="browse-files-text">Import</span>
																			<Icon
																				value="import"
																				size="small"
																			/>
																			</span>
																		</label>
																	</button>
																	:
																	<button
																		type="button"
																		className="secondary-btn secondary-small btn-with-icon  btn-with-icon-right"
																	>
																		<label className="label upload-image-label">
																		<span className="change-image-link">
																			<input
																				type="file"
																				ref={fileRef}
																				className="default-file-input"
																				onChange={handleFileInput}
																				style={{display: "none"}}
																			/>
																			<span className="browse-files-text">Import</span>
																			<img
																				src={
																					process.env.REACT_APP_URL +
																					"img/import.svg"
																				}
																				alt="img"
																			/>
																		</span>
																		</label>
																	</button>
																	}
																	
																	
																</div>

															</div>


															{formValues.tree.length > 0 && (
																<div className="build-tree-search-wrap">

																	<div
																		className={`tree-search-inner ${test.language == "ar" ? "arabic_wrapper" : ""
																			}`}
																	>
																		<Search
																			className={`${test.language == "ar"
																				? "arabic_wrapper"
																				: ""
																				}`}
																			type="text"
																			placeholder="Search for node or child"
																			value={search}
																			onChange={(event) => {
																				setSearch(event.target.value);
																			}}
																			onClear={(event) => {
																				setSearch('');
																			}}
																		/>
																	</div>
																</div>
															)}
														</div>
													</div>
													<div
														id="treeSection"
														ref={(element) =>
															sectionsRef.current[1] ??
															sectionsRef.current.push(element)
														}
														target="sidebarMenu2"
														className="sidebarSection"
													>

														<EditSortableTree
															search={search}
															test={test}
															addNode={addNode}
															treeSize={treeSize}
															updateNodeTitle={updateNodeTitle}
															updateHomeNode={updateHomeNode}
															checkIfMoreThan5Children={checkIfMoreThan5Children}
															formValues={formValues}
															setFormValues={setFormValues}
															addChild={addChild}
															editable_home={editable_home}
															setEditableHome={setEditableHome}
															setConfirmDeleteModal={setConfirmDeleteModal}
															setConfirmModal={setConfirmModal}
															removeAnswer={removeAnswer}
															removeNodeApi={removeNodeApi}
															makeEditableNode={makeEditableNode}
															saveTreeData={saveTreeData}
															updateNodePosition={updateNodePosition}
															editable_text={editable_text}
															setEditableText={setEditableText}
															homeRef={homeRef}
															nodeRef={nodeRef}
															cnodeRef={cnodeRef}
														/>
														{formErrors && formErrors.tree_error &&
														<span className="error red-text">
															{formErrors.tree_error}
														</span>
														}
													</div>
												</div>


												<div className="create-test-preview-area">
													<Text
														type={'overline'}
														children={'Preview'}
													/>
													<div className="create-test-preview-area-inner">
														<img src={
															process.env.REACT_APP_URL + "img/treetest-preview.svg"}
															alt="img" />
													</div>
													<GuideLink methodology={"tree test"}/>
												</div>

											</div>
											<div className="tree-test-task-information" id="tasksSection">
												<div className="cardsorting-wrap-top">
													<div className="d-flex align-items-center justify-content-between change-order-btn-wrap">
														<Text type={'subtitle-2'} fontWeight={'medium-font'} >
															Tasks
														</Text>
														{formValues.tasks.length>1 &&
														
															<Button
																type={"secondary"}
																size="small"
																onClick={() => {
																	setChangeOrderModal({...changeOrderModal, open:true});
																}}
																cssclass={"change-order-btn"}   
																label="Change order"
																iconLeft={<Icon value={"sort-icon"} size="medium" />}
															/>
														}
													</div>
													<Text
														type={'body-text-2'}
														fontWeight={'medium-font'}
													>
														Your tasks should reflect how your participants naturally approach your website and be linked to your testing objectives.




													</Text>
													
													<div className="tree-task-tip">
														<Text
															type={'body-text-2'}
															fontWeight={'medium-font'}
														>

															<strong className="color-black">Tip:</strong> Use a hypothetical scenario. For example "If you're hosting a dinner party, where would you find glassware?"

														</Text>
													</div>
													{formErrors && formErrors.tasks_error &&
													<span className="error red-text">
														{formErrors.tasks_error}
													</span>
													}
												</div>
											</div>
											<div
												id="taskSection"
												ref={(element) =>
													sectionsRef.current[2] ??
													sectionsRef.current.push(element)
												}
												target="sidebarMenu3"
												className="row sidebarSection"
											>
												
													
														<div className="col-md-12">{renderTasks()}</div>
													
											</div>
											
											
											<div className="add-new-task-btn-wrap">

												<Button
													type={"secondary"}
													size={"medium"}
													onClick={addTask}
													iconLeft={
														<Icon value={"add"} size={"small"} />
													}
													label={"Add Task"}
													microLoading={addTaskLoading}
													state={formValues.tasks.length>=15?"disabled":""}
												/>



											</div>
											
										</Accordian>
										<Accordian
											title={"Conclusion questions"}
											subtitle={"(Optional)"}
											
											open={accordians['conclusion'] ? true : false}
											onChange={() => {
												let new_accordians = Object.assign({}, accordians);

												for (var key in accordians) {
													if (key == "conclusion") {
														if (accordians[key]) {
															new_accordians[key] = false;
														} else {
															new_accordians[key] = true;
														}
													}

												}
												setAccordians(new_accordians);
											}}
											questionCount={concCount}
										>
											<ConclusionQuestions ref={conclusionRef} questions={conclusionQuestions} test={test} setLoading={(status) => setIsSaved(status)} changeCount={(newCount)=>{setConcCount(newCount)}} updateQuestions={(questions)=>{setConclusionQuestions([...questions])}}/>
										</Accordian>
									</div>
									<div className="btns-wrap-bottom">
										<Button
											onClick={() => { navigate("/r/test/" + test_id + "/welcome"); }}
											type={"ghost"}
											label={"Previous"}
											size={"large"}
											iconLeft={
												<Icon value="back-arrow" colorClass="gray-900-svg" size={"large"} />
											}
										/>
										<Button
											id="r_create_step_2_build_next_btn"
											onClick={saveTreeStep}
											iconRight={<Icon value="forward-arrow" colorClass="gray-50-svg" />}
											label="Next"
											size={"large"}
											state={checkTreeBtnStatus() ? "disabled" : "active"}
										/>
									</div>
								</div>
							</div>
							<div className="three-column-layout-right">
							<a  onClick={(e)=>{
                                    e.preventDefault();

                                    setShowSupportPanel(true);

                                }}
								href={process.env.REACT_APP_URL+"r/support"} target="_blank" className='support-button'>
										<Icon colorClass={"gray-50-svg"} value={"support"} />
                                        Support
                                    </a>
							</div>
						</>
					}
				</div>
			</div>
			<HelpModal 
				open={helpModal.open}
				cssClass={"tree-import-modal"}
				close={()=>{
					setHelpModal({...helpModal, open:false});

					setShowImportTreeInstructions(false);
				}}
				heading={"Instructions before you import"}
				body={
					<div className="prototype-criteria-modal-data">
						<ol className="modal-ol-list">
							<li>File Format: Please ensure that the file you upload is in CSV format.</li>
							<li>Blank Rows/Columns: Avoid blank rows or columns in the file, as they can prevent the import of subsequent nodes and child nodes.</li>
							{/*<li>Separators: For TXT files, use either a comma or semicolon as the separator.</li>*/}
							<li>Child Node Level Limit: Keep the number of levels below each child node to a maximum of 5.</li>
						</ol>
						<div className="formbox mt-20">
                        	<CheckBox name="dont_ask_again" label="Don’t show this message again" checked={show_import_tree_instructions} onChange={()=>{setShowImportTreeInstructions(!show_import_tree_instructions)} }  />
                        </div>
						<button
							type="button"
							className="btn primary-btn primary-large btn-with-icon  btn-with-icon-left"
						>
							<label className="label upload-image-label">
							<span className="change-image-link">
								<input
									type="file"
									ref={fileRef}
									className="default-file-input"
									onChange={handleFileInput}
									style={{display: "none"}}
								/>
								<img
									src={
										process.env.REACT_APP_URL +
										"img/import-white.svg"
									}
									alt="img"
								/>
								<span className="browse-files-text">Import file</span>
								
							</span>
							</label>
						</button>
					</div>

				}
			/>
			<ConfirmationModal
				confirm_message="Are you sure you want to delete this task?"
				confirm_btn_title="Delete"
				confirm_title="Delete Task"
				confirm_title_class={"text-danger"}
				openModal={confirmDeleteTaskModal.open}
				close={() => {
					setConfirmDeleteTaskModal({ ...confirmDeleteTaskModal, open: false });
				}}
				confirm={() => {
					setConfirmDeleteTaskModal({ ...confirmDeleteTaskModal, open: false });

					if (formValues.tasks.length > 1) {
						var tasks = formValues.tasks;

						removeTask(formValues.tasks[confirmDeleteTaskModal.task_index]);

						delete answer_paths[confirmDeleteTaskModal.task_index];
						tasks.splice(confirmDeleteTaskModal.task_index, 1);
						setFormValue("tasks", tasks);

						
						//saveTreeData({ ...formValues, tasks: tasks }, false);

					} else {
						showError("You cannot remove the only task.");
					}

					
				}}
			/>
			<ConfirmationModal
				confirm_message="Are you sure you want to delete this parent node? All related children nodes will be deleted as well."
				confirm_btn_title="Delete"
				confirm_title="Delete"
				confirm_title_class="text-danger"
				openModal={confirmModal.open}
				close={() => {
					setConfirmModal({ ...confirmModal, open: false });
				}}
				confirm={() => {

					setConfirmModal({ ...confirmModal, open: false });

					removeAnswer(confirmModal.node.id);
					removeNodeApi(confirmModal.node, confirmModal.path);
				}}
			/>

			<ConfirmationModal
				confirm_message="Are you sure you want to delete this parent node? All related children nodes will be deleted as well."
				confirm_btn_title="Delete"
				confirm_title="Delete"
				confirm_title_class="text-danger"
				openModal={confirmDeleteInnerModal.open}
				close={() => {
					setConfirmDeleteInnerModal({ ...confirmDeleteInnerModal, open: false });
				}}
				confirm={() => {
					setConfirmDeleteInnerModal({ ...confirmDeleteInnerModal, open: false });
					confirmDeleteInnerModal.callback();
				}}
			/>
			<ConfirmationModal
				confirm_message="Are you sure you want to delete all nodes?"
				confirm_btn_title="Delete"
				confirm_title="Delete"
				confirm_title_class="text-danger"
				openModal={confirmDeleteModal.open}
				close={() => {

					setConfirmDeleteModal({ ...confirmDeleteModal, open: false });
				}}
				confirm={() => {
					var new_tasks = Object.assign([], formValues.tasks);
					for (var i = 0; i < new_tasks.length; i++) {
						new_tasks[i]["correct_answer_id"] = null;
					}
					setFormValues({ ...formValues, tasks: new_tasks });
					setConfirmDeleteModal({ ...confirmDeleteModal, open: false });
					removeAllNodesApi();
				}}
			/>
			<ConfirmationModal
				confirm_message="Are you sure you want to import this file? This will remove all the nodes that you've added."
				confirm_btn_title="YES"
				confirm_title="Import CSV"
				openModal={importModal.open}
				close={() => {
					setImportModal({ ...importModal, open: false });
					fileRef.current.value = "";
				}}
				confirm={() => {
					setImportModal({ ...importModal, open: false });
					importCSVApi(importModal.csvFile);
					fileRef.current.value = "";
				}}
			/>
			<ChangeOrderModal 
				tree = {formValues.tree}
				tasks={formValues.tasks}
				open={changeOrderModal.open}
				rtl={test.language=="ar"?true:false}
				close={()=>{
                    setChangeOrderModal({...changeOrderModal, open:false})
                }}
				updateOrder={(tasks)=>{
					setChangeOrderModal({...changeOrderModal, open:false})
					setFormValues({...formValues, tasks: tasks});
				}}
			/>
		</LayoutResearcher>
	);
}
