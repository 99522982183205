import React, { useEffect, useState, useRef } from "react";
import { Modal } from "react-bootstrap";
import { Button } from "../../themes/userq/Button/Button";
import { Text } from "../../themes/userq/Text/Text";
import { Input } from './../../themes/userq/Input/Input';
import { RadioButton } from './../../themes/userq/RadioButton/RadioButton';
import Methodology from './../../tests/methodology/methodology';
import toast from "react-hot-toast";
import { FormattedMessage } from "react-intl";
import { ReactSession } from "../../../lib/secure_reactsession";
import { useNavigate } from "react-router-dom";
import { createTest, updateTestSettingsService } from "../../../services/test";
import { LoadingIcon } from './../../loader/loadingIconNew';
import { ToastMessage } from './../../themes/userq/ToastMessage/ToastMessage';
import { ArabicNumbers } from 'react-native-arabic-numbers';
import { settings } from "../../../data/settings";
import { Icon } from "../../themes/userq/Icon/Icon";


export default function TestSettingsModal(props) {
    const wrapperRef = useRef(null);
    const [formLoading, setFormLoading] = useState(false);
    const navigate = useNavigate();
    const [language, setLanguage] = useState("en");
    const [methodology, setMethodology] = useState(props.selectedMethodology);
    const [formValues, setFormValues] = useState({
        test_name: props.selectedMethodology=="Five Seconds Test" ? "5 second test " + props.totalTests : props.selectedMethodology + " " + props.totalTests,
        test_language: "en",
        device:"all"
    });
    const [formErrors, setFormErrors] = useState({
        test_name: null,
        test_language: null,
        device: null,
    });
    const lang = useRef("en");

    useEffect(()=>{

        if(props.read_mode){

            setFormValues({
                ...formValues,
                test_name: props.test.test_name,
                test_language:props.test.language,
                device:props.test.device
            });

            if(props.test.methodology=="Prototype test"){
                setMethodology("Prototype test")
            }
            if(props.test.methodology=="First click"){
                setMethodology("First click")
            }

        }
    },[props.openModal])

    const closeModal = () => {
        setFormValues({ test_name: "" });
        props.closeModal(false);
    };

    const set = (name) => {
        return ({ target: { value } }) => {
            setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
            setFormErrors((oldValues) => ({ ...oldValues, [name]: null }));
        };
    };

    const showFormError = (name, value) => {
        let formErrorsLocal = Object.assign({}, formErrors);

        for (var key in formErrors) {
            formErrorsLocal[key] = null;
        }
        formErrorsLocal[name] = value;

        formErrorsLocal["error_class"] = "input_error";

        setFormErrors(formErrorsLocal);
    };

    const onSubmit = async (event) => {
        event.preventDefault();


        if (!formLoading) {
            var error = false;
            if (formValues.test_name === "") {
                showFormError("test_name", "Required field");
                error = true;
            } else {

                if (
                    methodology.indexOf("Tree Test") == -1 &&
                    methodology.indexOf("Preference Test") == -1 &&
                    methodology.indexOf("Survey") == -1 &&
                    methodology.indexOf("Card Sorting") == -1 &&
                    methodology.indexOf("Five Seconds Test") == -1 &&
                    methodology.indexOf("Prototype test") == -1
                    && methodology.indexOf("First click") == -1
                ) {
                    toast(
                        <ToastMessage type={"error"} message={<FormattedMessage id="Selected Methodology Not Available" />} closable={true} onClose={() => { toast.dismiss(); }} />,
                        {
                            className: "errortoast",
                            position: "bottom-center",
                            duration: settings.toast_duration,
                        }
                    );
                }
                else {

                    if (!error) {
                        setFormLoading(true);

                        const token = ReactSession.get("token");

                        var data = new FormData();

                        data.append("test_name", formValues.test_name);
                        data.append("methodology", methodology);
                        data.append("device", formValues.device);
                        data.append("language", language);

                        if(props.read_mode){

                            data.append("test_id", props.test.id);


                            updateTestSettingsService(data, token).then((response) => {
                                setFormLoading(false);

                                if (response.success) {

                                    toast(
                                        <ToastMessage type={"success"} message={response.message} closable={true} onClose={() => { toast.dismiss(); }} />,

                                        {
                                            className: "errortoast",
                                            position: "bottom-center",
                                            duration: settings.toast_duration,
                                        }
                                    );

                                    props.updateTestDevice(formValues.device)


                                } else {

                                    toast(
                                        <ToastMessage type={"error"} message={response.message} closable={true} onClose={() => { toast.dismiss(); }} />,

                                        {
                                            className: "errortoast",
                                            position: "bottom-center",
                                            duration: settings.toast_duration,
                                        }
                                    );
                                }
                            });

                        } else {

                            createTest(data, token).then((response) => {
                                setFormLoading(false);

                                if (response.success) {
                                    setFormValues({
                                        test_name: "",
                                    });

                                    props.closeModal(true);
                                    navigate(
                                        "/r/test/" + response.test_id + "/welcome/"
                                    );
                                    toast(
                                        <ToastMessage type={"success"} message={response.message} closable={true} onClose={() => { toast.dismiss(); }} />,
                                        {
                                            className: "successtoast",
                                            position: "bottom-center",
                                            duration: settings.toast_duration,
                                        }
                                    );
                                    // setSuccessMessage(response.message);
                                } else {
                                    // setErrorMessage(response.message);
                                    toast(
                                        <ToastMessage type={"error"} message={response.message} closable={true} onClose={() => { toast.dismiss(); }} />,

                                        {
                                            className: "errortoast",
                                            position: "bottom-center",
                                            duration: settings.toast_duration,
                                        }
                                    );
                                }
                            });
                        }
                    }

                }
            }
        }
        return false;
    };

    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    if(event.target.nodeName =="DIV"){
                        closeModal();
                    }
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    useOutsideAlerter(wrapperRef);


    return (
        <Modal
            show={props.openModal}
            centered
            size="lg"
            className="fade custom-modal-wrap test-setting-modal "
        >
            <Modal.Body ref={wrapperRef}>
                {formLoading ?
                    <div class="modal-loader test-setting-modal-loader">
                        <LoadingIcon />
                    </div>
                    :
                    <div className={`modal-inner-text ${props.read_mode?"test-setting-read-mode":""}`}>
                        <div className="modal-header-top">
                            <Text type="h3">Test settings</Text>
                            <button
                                type="button"
                                className="close"
                                onClick={() => {
                                    closeModal();
                                }}
                                aria-label="Close"
                            >
                                <span aria-hidden="true">
                                    
                                    <Icon value="Close" size="medium" hover={true} />
                                </span>
                            </button>
                        </div>


                        <div className="test-setting-middle-data">
                            <div className="test-setting-label test-language-label">
                                <Text type="body-text-2" cssClasses={'black-color mt-0 mb-0'} fontWeight="medium-font">Test language</Text>

                                <Text type="body-text-3" fontWeight="medium-font">The test language will be what the participants see. Remember to write questions in the language you want your participants to see.</Text>
                            </div>

                            <div className={`language-selection-radio`}>
                                <RadioButton
                                    name="test-language"
                                    label="English"
                                    id="English"
                                    value="English"
                                    isChecked={language === "en" ? true : false}
                                    onChange={(e) => {
                                        if(props.read_mode){
                                            return;
                                        }
                                        setLanguage("en");
                                        lang.current = "en";

                                        setFormValues({
                                            ...formValues,
                                            test_name: props.selectedMethodology + " " + props.totalTests,
                                            test_language: "ar"
                                        });
                                    }}
                                />
                                <RadioButton
                                    name="test-language"
                                    label={<>Arabic (<span className="arabic-font">عربي</span>)</>}
                                    id="Arabic"
                                    value="Arabic"
                                    isChecked={language === "ar" ? true : false}
                                    onChange={() => {
                                        if(props.read_mode){
                                            return;
                                        }
                                        setLanguage("ar");
                                        lang.current = "ar";
                                        const testname = props.selectedMethodology == "Tree Test" ?
                                            "الاختبار الشجري" : (props.selectedMethodology == "Card Sorting" ? "فرز البطاقات" : (
                                                props.selectedMethodology == "Preference Test" ? "اختبار التفضيل" : (
                                                    props.selectedMethodology == "Survey" ? "استطلاع الرأي" : (
                                                        props.selectedMethodology == "Five Seconds Test" ? "اختبار الخمس ثوانٍ" : (
                                                            props.selectedMethodology == "Prototype test" ? "النموذج المبدئيٍ" : (
                                                                props.selectedMethodology == "First click" ? "اختبار النقرة الأولى" : "امتحان"
                                                            )
                                                        )
                                                    )
                                                )
                                            ))
                                        setFormValues({
                                            ...formValues,
                                            test_name: testname + " " + ArabicNumbers(props.totalTests),
                                            test_language: "ar"
                                        });
                                    }}
                                />
                            </div>
                            <div className="test-setting-label test-name-label">
                                <Text type="body-text-2" fontWeight="medium-font" cssClasses={'black-color mt-0 mb-0'}>Give your test a name</Text>
                            </div>
                            <div className="test-setting-hint">
                                <Text type="body-text-3" fontWeight="medium-font">This title will be visible to participants</Text>
                            </div>
                            <Input
                                label="Test name"
                                onChange={set("test_name")}
                                readOnly={props.read_mode?true:false}
                                onClear={() => {
                                    setTimeout(() => {
                                        setFormValues((oldValues) => ({
                                            ...oldValues,
                                            test_name: "",
                                        }));
                                    }, 1000);
                                }}
                                value={formValues.test_name}
                                maxLength="45"
                                error={formErrors.test_name}
                                error_class={formErrors.error_class}
                                id="test_name"
                                clearField={false}
                                required={true}
                                rtl={lang.current == "ar" ? true : false}
                            />
                            {(methodology === 'Prototype test' || methodology === 'First click') &&
                            <>
                                <div className="allow-tester-label">
                                    <Text type="body-text-2" fontWeight="medium-font">Allow testers to participate on</Text>

                                </div>

                                <div className={`device-selection-radio d-flex`}>
                                    <RadioButton
                                        name="test-device"
                                        label="All devices"
                                        id="allDevices"

                                        value="all"
                                        img={'desktop-and-phone'}
                                        isChecked={formValues.device === "all" ? true : false}
                                        onChange={(e) => {

                                            setFormValues({
                                                ...formValues,
                                                device: "all"
                                            });
                                        }}
                                    />
                                    <RadioButton
                                        name="test-device"
                                        label={"Mobile only"}
                                        id="mobileOnly"
                                        value="mobile"
                                        img={'Phone'}
                                        isChecked={formValues.device === "mobile" ? true : false}
                                        onChange={() => {
                                            setFormValues({
                                                ...formValues,
                                                device: "mobile"
                                            });
                                        }}
                                    />

                                    <RadioButton
                                        name="test-device"
                                        label={"Desktop only"}
                                        id="desktopOnly"
                                        img={'Desktop'}
                                        value="Mobile only"
                                        isChecked={formValues.device === "desktop" ? true : false}
                                        onChange={() => {
                                            setFormValues({
                                                ...formValues,
                                                device: "desktop"
                                            });
                                        }}
                                    />
                                </div>
                            </>
                            }

                        </div>

                        <Button type="primary" size="large" label={props.read_mode?"Save":"Create"} onClick={onSubmit} />
                    </div>
                }
            </Modal.Body>
        </Modal>
    )
}