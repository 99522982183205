import React, {useState, useEffect, useRef} from "react";
import { Modal } from "react-bootstrap";
import {Icon} from "../themes/userq/Icon/Icon";
import {Text} from "../themes/userq/Text/Text";
import {ShareLink} from "../themes/userq/ShareLink/ShareLink";
import {
    FacebookShareButton,
    FacebookIcon,
    TwitterShareButton,
    TwitterIcon,
    WhatsappShareButton,
    WhatsappIcon
} from "react-share";
import {FilterButtons} from "../themes/userq/FilterButtons/FilterButtons";
import {ReactSession} from "../../lib/secure_reactsession";
import {encryptId, showToast} from "../../lib/helpers";
import {getTestData, saveTestResultPasswordService} from "../../services/test";
import {LoadingIcon} from "../themes/userq/Loader/loadingIcon";
import {ToggleButton} from "../themes/userq/ToggleButton/ToggleButton";
import {ToastMessage} from "../themes/userq/ToastMessage/ToastMessage";
import {Input} from "../themes/userq/Input/Input";
import {Button} from "../themes/userq/Button/Button";

export default function CopyLinkModal(props) {

  const closeModal = () => {
    props.close();
  };
  const wrapperRef = useRef(null);
    const dataFetchedRef = useRef(false);


    const [filterOption, setFilterOption] = useState("Test");

    const [isLoading, setLoading] = useState(false);
    const [testLink, setTestLink] = useState('');
    const [isFormLoading, setFormLoading] = useState(false);
    const [isPasswordSave, setPasswordSave] = useState(false);
    const [isTyping, setTyping] = useState(false);
    const [isMessage, setMessage] = useState('');
    const [test, setTest] = useState("");

    const [formValues, setFormValues] = useState({'is_share_results':0,'is_password_required':false,'password':''});

    const [formErrors, setFormErrors] = useState({password:null, error_class:null});

    const [passwordVisible, setPasswordVisibility] = useState(false);


    useEffect(() => {
        if (props.test_id && props.openModal) {

            getTestApi();
            setFilterOption("Test");
            resetFormErrors();
            setMessage('');
            setTyping(false);
        }
    }, [props.openModal]);

    const getTestApi = () => {
        let token = ReactSession.get("token");

        setLoading(true);

        getTestData({ test_id: props.test_id }, token).then((response) => {
            setLoading(false);

            if (response.success) {


                setTest(response.test);


                var testLink = '';
                if(response.test.target_group.is_screening_questions && response.test.target_group.is_screening_questions==1){
                    // if(response.screeningattemptedTests.includes(response.test.id)){
                    //     testLink = process.env.REACT_APP_URL+"t/" + encryptId(response.test.id, response.test);
                    // }else{

                        testLink = process.env.REACT_APP_URL+"t/screening/" + response.test.id;

                    // }
                }else{
                    testLink = process.env.REACT_APP_URL+"t/" + encryptId(response.test.id, response.test);
                }

                setTestLink(testLink);


                // check if share test results password is on
                if(response.test.is_share_results){

                    if(response.test.password){

                        setFormValues({'is_share_results':1,'is_password_required':1,'password':response.test.password});
                        setPasswordSave(true);
                    }else{

                        setFormValues({'is_share_results':1,'is_password_required':0,'password':''});
                        setPasswordSave(false);
                    }

                }else{

                    setFormValues({'is_share_results':0,'is_password_required':0,'password':''});
                    setPasswordSave(false);
                }



            } else {
                showToast(response.message,'error');
            }
        });
    };

    const resetFormErrors = () => {

        let formErrorsLocal = Object.assign({}, formErrors);

        for (var key in formErrors) {
            formErrorsLocal[key] = null;
        }
        setFormErrors(formErrorsLocal);

        return formErrorsLocal;
    }
    const validateFullForm = (focus, key, value=null, inline=false) => {

        var error = false;

        var form_errors = Object.assign([],formErrors) ;

        if(key){
            form_errors[key] = null;
        }

        if(focus){

            form_errors = resetFormErrors();
        }


        var firsterrorid = null;

        if(!key || key=="password"){

            var field_value = key && inline ? value : formValues.password;

            if (field_value == '' || field_value == null) {


                form_errors = {
                    ...form_errors,
                    password: "Required field",
                    error_class: "input_error"
                };

                error = true;
                firsterrorid = firsterrorid == null ? "password" : firsterrorid;
            }
        }
        if((inline && formErrors[key] ) || !inline){

            setFormErrors(form_errors);
        }
        if(focus){
            if (firsterrorid != null) {
                document.getElementById(firsterrorid).scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                });
            }
        }

        return error;
    }

    const submitChangeShareLinkHandler = ()=>{

        if (dataFetchedRef.current) {
            dataFetchedRef.current.abort();

        }

        setMessage('');
        setFormLoading(true);

        const controller = new AbortController();
        dataFetchedRef.current = controller;

        const token = ReactSession.get("token");
        let data = Object.assign({}, '');
        data["test_id"] = props.test_id;
        data["is_share_results"] = (formValues.is_share_results)?'false':'true';


        saveTestResultPasswordService(data, token,dataFetchedRef.current?.signal).then((response) => {

            setFormLoading(false);

            setTyping(false);

            if(response){
                if (response.success) {


                } else {

                    showToast(response.message,'error')
                }
            }
        });
    }

   const submitHandler = (isPassword)=>{

       if (dataFetchedRef.current) {
           dataFetchedRef.current.abort();

       }

       setMessage('');
       setFormLoading(true);

       const controller = new AbortController();
       dataFetchedRef.current = controller;

       const token = ReactSession.get("token");
       let data = Object.assign({}, '');
       data["test_id"] = props.test_id;
       // data["is_share_results"] = (formValues.is_share_results)?0:1;

       if(isPassword)
        data["password"] = (formValues.is_password_required)?formValues.password:null;


       saveTestResultPasswordService(data, token,dataFetchedRef.current?.signal).then((response) => {

           setFormLoading(false);

           setTyping(false);

           if(response){
               if (response.success) {


                   setPasswordSave(true);

                   if(isPassword){

                       setTimeout(function () {
                           setMessage('');
                       }, 5000)

                           setMessage(response.message);
                   }


               } else {

                   showToast(response.message,'error')
               }
           }
       });
   }

  function useOutsideAlerter(ref) {
      useEffect(() => {
          function handleClickOutside(event) {
              if (ref.current && !ref.current.contains(event.target)) {
                  if(event.target.nodeName =="DIV"){
                      closeModal();
                  }
              }
          }
          // Bind the event listener
          document.addEventListener("mousedown", handleClickOutside);
          return () => {
              // Unbind the event listener on clean up
              document.removeEventListener("mousedown", handleClickOutside);
          };
      }, [ref]);
  }

  useOutsideAlerter(wrapperRef);

   const onSubmit = (e)=>{
       e.preventDefault();

       (formValues.password && isTyping)?submitHandler(true):validateFullForm(false,'password')
   }

  return (
    <Modal
      show={props.openModal}
      centered
      className="fade custom-modal-wrap  share-link-modal-wrap share-link"
    >
      <Modal.Body className="" ref={wrapperRef}>
        <div className="">


          <div className="congrats-modal-text p-0">

            <div className="modal-header-top">
              <Text type={`h3`}>Share</Text>

              <button
                  type="button"
                  className="close"
                  onClick={() => {
                    closeModal();
                  }}
                  aria-label="Close"
              >
                            <span aria-hidden="true">
                                <Icon value="Close" size="medium" hover={true} />
                            </span>
              </button>
            </div>

              {isLoading ?
                  <div className="modal-loader text-center">
                      <LoadingIcon/>
                  </div>
                  :
                  <>

                      <div className="row share-link-filters summary-modal-data-repeat test-summary-filters">
                          <div className="col-md-12">
                              <FilterButtons options={["Test", "Results"]} selected={filterOption} onChange={(item) => {
                                  setFilterOption(item)
                              }}/>
                          </div>
                      </div>

                      {filterOption == "Test" &&
                      <>
                          {test.status === 'completed' ?
                              <div className={`no-participants-data no-questions-data`}>
                                  <div className="no-page-data-wrap" style={{marginTop: "80px"}}>
                                      <div className="no-data-icon">
                                          <Icon value={"error"} size={"large"}/>
                                      </div>

                                      <Text type={"body-text-2"} fontWeight="medium-font" cssClasses={'text-center'}>
                                          This test has been completed.<br/> You cannot share a completed test.
                                      </Text>
                                  </div>
                              </div>
                              :
                              <>

                                  <p className="copy-link-subheading">
                                      Share the link with your own testers. The test will be visible to anyone that
                                      possess the
                                      link.
                                  </p>

                                  <div className="copy-link-subwrapper">
                                      <Text type={"body-text-1"} fontWeight="medium-font" cssClasses={'test-link-head black-color mt-20'}>
                                      Your test link
                                      </Text>

                                      <ShareLink link={testLink}/> 

                                      <h5 className="test-link-head share-link-text">Or share it via</h5>
                                      <div className={"share-social-links text-left"}>
                                          <FacebookShareButton
                                              url={testLink}
                                              quote={"Share the link with your own testers. The test will be visible to anyone that possess the link"}
                                              className="facebook__some-network__share-button"
                                          >
                                              <FacebookIcon size={32} round/>
                                          </FacebookShareButton>

                                          <TwitterShareButton
                                              url={testLink}
                                              title={"Share the link with your own testers. The test will be visible to anyone that possess the link"}
                                              className="twitter__some-network__share-button"
                                          >
                                              <TwitterIcon size={32} round/>
                                          </TwitterShareButton>

                                          <WhatsappShareButton
                                              url={testLink}
                                              title={"Share the link with your own testers. The test will be visible to anyone that possess the link"}
                                              separator=":: "
                                              className="whatsapp__some-network__share-button"
                                          >
                                              <WhatsappIcon size={32} round/>
                                          </WhatsappShareButton>
                                      </div>
                                  </div>
                              </>
                          }
                      </>
                      }

                      {filterOption == "Results" &&
                      <>
                          <div className="copy-link-subwrapper result-link-wrapper">
                              <p className="copy-link-subheading test-results-share-text">
                                  Share the results with your team by activating the link below
                              </p>

                              <div className={"share-social-links text-left active-toggle-state mb-12 mt-20"}>
                                  <ToggleButton
                                      id="share_link"
                                      isChecked={formValues.is_share_results == 1 ? true : false}
                                      label={<Text display="inline" fontWeight={'medium-font'} type="body-text-2">Activate results link</Text>}
                                      onChange={(e) => {

                                          resetFormErrors()

                                          submitChangeShareLinkHandler();

                                          setFormValues({
                                              ...formValues,
                                              'is_share_results':e.currentTarget.checked ? 1 : 0,
                                              'password':'',
                                              "is_password_required": 0
                                          });

                                      }}
                                  />
                              </div>


                              <ShareLink isDisabled={formValues.is_share_results == 0? true:false} link={process.env.REACT_APP_URL + "v/results/" + encryptId(test.id, test)+"/overview"}/>

                              <div className={"share-social-links text-left"}>

                                  <ToggleButton
                                      id="link_passwords"
                                      isDisabled={formValues.is_share_results == 0? true:false}
                                      isChecked={formValues.is_password_required == 1 ? true : false}
                                      label={<Text display="inline" fontWeight={'medium-font'} type="body-text-2">Protect your result with a password</Text>}
                                      onChange={(ev) => {

                                          if(formValues.is_share_results){
                                              resetFormErrors()

                                              if(!ev.currentTarget.checked && isPasswordSave){
                                                  submitHandler(false);

                                                  setFormValues({
                                                      ...formValues,
                                                      'password':'',
                                                      "is_password_required":
                                                          ev.currentTarget.checked ? 1 : 0
                                                  });
                                              }else{
                                                  setFormValues({
                                                      ...formValues,
                                                      'password':'',
                                                      "is_password_required":
                                                          ev.currentTarget.checked ? 1 : 0
                                                  });
                                              }
                                          }



                                      }}
                                  />

                                  {formValues.is_password_required === 1 &&
                                      <>
                                      <div className={'share-link-password-wrapper'}>
                                        <Text type={"body-text-2"} fontWeight={'semi-bold-font'}>
                                              Protect your privacy by adding a password to the result view. Only users with the password will be able to view your results.
                                          </Text>
                                          <form method='POST' onSubmit={onSubmit}> 
                                          <Input
                                              id="passwordss"
                                              type="password"
                                              value={formValues.password}
                                              onChange={(e)=>{
                                                  setFormValues({...formValues,password:e.target.value})
                                                  // validateFullForm(false,'password')
                                                  if(e.target.value){
                                                      setTyping(true);
                                                      setFormErrors({
                                                          ...formErrors,
                                                          password: "",
                                                          error_class: "input_error"
                                                      });
                                                  }else{
                                                      setTyping(false);
                                                      setFormErrors({
                                                          ...formErrors,
                                                          password: "Required field",
                                                          error_class: "input_error"
                                                      });
                                                  }
                                              }}
                                              autocomplete="link-password"
                                              required={true}
                                              label={"Password"}
                                              passwordVisible={passwordVisible}
                                              passwordInput={true}
                                              clearField={false}
                                              onBlur={()=>{validateFullForm(false,'password')}}
                                              onFocus={()=>{ setFormErrors({...formErrors, password:null}) }}
                                              passwordField={true}
                                              error={formErrors.password}
                                              error_class={formErrors.error_class}
                                          />

                                          {isMessage &&
                                              <ToastMessage
                                                  type={'success'}
                                                  closable={false}
                                                  message={isMessage}
                                              />
                                          }
                                          </form>

                                      </div>

                                          <Button
                                              type="primary" className="btn btn-block form-btn"
                                              label={isPasswordSave?'Save':"Create password"}
                                              microLoading={isFormLoading}
                                              onClick={(e)=>{
                                                  (formValues.password && isTyping)?submitHandler(true):validateFullForm(false,'password')
                                              }}
                                              state={(formValues.password && isTyping)?"active":"disabled"}
                                              size={"large"}
                                          />

                                      </>
                                  }

                              </div>
                          </div>
                      </>
                      }

                  </>
              }

          </div>

        </div>
      </Modal.Body>
    </Modal>
  );
}