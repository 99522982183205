import React, { useState, useEffect, useRef } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
//import { ReactSession } from 'react-client-session';
import { ReactSession } from '../../../lib/secure_reactsession';
import { Dropdown } from 'reactjs-dropdown-component';
import { useSearchParams, useNavigate } from "react-router-dom";
import { FormattedMessage } from 'react-intl';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css'
import { PhoneNumberUtil } from 'google-libphonenumber';
import ReCAPTCHA from "react-google-recaptcha";
import { load } from 'recaptcha-v3'
import { Text } from '../../themes/userq/Text/Text';
import { CheckBox } from '../../themes/userq/CheckBox/CheckBox';
import { Button } from '../../themes/userq/Button/Button';
import { Input } from '../../themes/userq/Input/Input';
import { ToastMessage } from '../../themes/userq/ToastMessage/ToastMessage';
import { toast } from 'react-hot-toast';
import { settings } from '../../../data/settings';
import { Logo } from '../../themes/userq/Logo/Logo';
import ConfirmationModal from '../../themes/userq/Modal/ConfirmationModal';
import { Footer } from '../../themes/userq/Footer/Footer';
import { roundNumber } from '../../../lib/helpers';

let captcha = null;


export default function ForgotPasswordResearcher() {

    ReactSession.setStoreType("localStorage");

    const navigate = useNavigate();

    const [errorMsg, setErrorMessage] = useState(null);

    const [isLoading, setLoading] = useState(false);

    const [formLoading, setFormLoading] = useState(false);

    const [formValues, setFormValues] = useState({email:'', role: 'Tester' });

    const [formErrors, setFormErrors] = useState({ email: null });

    const [modal, setModal] = useState({ open: false });

    const [timer, setTimer] = useState(0);

    const [sentEmail, setSentEmail] = useState(false);

    useEffect(() => {
        window.animate();
        document.title = "Forgot your password? | "+process.env.REACT_APP_NAME;


    

    }, []);

    useEffect(() => {
        
        if(timer>0){
            countDown();
        }


    

    }, [timer]);
    

    const generateCaptcha = (callback) => {
        load(process.env.REACT_APP_GCAPTCHA_V3).then((recaptcha) => {
            recaptcha.execute('register').then((token) => {
                captcha = token;

                setFormValues({ ...formValues, captcha: captcha });

                callback(token);

            })
        })
    }
    const showError = (error) => {
        
        toast(
            <ToastMessage type={"error"} message={error} closable={true} onClose={() => { toast.dismiss(); }} />,
            {
                className: "errortoast",
                position: "bottom-center",
                duration: settings.toast_duration,
            }
        );
    };

    const showSuccess = (success) => {
        toast(
            <ToastMessage type={"success"} message={success} closable={true} onClose={() => { toast.dismiss(); }} />,
            {
                className: "successtoast",
                position: "bottom-center",
                duration: settings.toast_duration,
            }
        );
    };

    const sendEmailForForgotPassword = () => {

        if(!sentEmail || timer==0){

            setLoading(true);

            generateCaptcha(function (token) {

                setErrorMessage(null);
                
                fetch(process.env.REACT_APP_API_END_POINT + 'forgot-password?role=Tester&email='+encodeURIComponent(formValues.email)+"&captcha="+token, {
                    method: 'GET',
                    headers: { "Content-Type": "application/json" }
                })
                    .then(res => res.json())
                    .then(response => {
                        
                        setLoading(false);
                        if (response.success) {
                            
                            setTimer(60000);

                            countDown();

                            setSentEmail(true);

                            setModal({open:true})
                        } else {
                            showError(response.message);
                        }
                    });

            })
        }

    }
    const countDown = ()=>{
        setTimeout(()=>{
            

            setTimer(timer-1000);
                
        },1000);

        
    }
    const resetFormErrors = () => {

        let formErrorsLocal = Object.assign({}, formErrors);

        for (var key in formErrors) {
            formErrorsLocal[key] = null;
        }
        setFormErrors(formErrorsLocal);

        return formErrorsLocal;
    }
    const validateFullForm = (focus, key, value=null, inline=false) => {

        var error = false;

        var re = /^(?=.*\d)(?=.*[@$!%*#?&])(?=.*[a-zA-Z]).{6,}$/;

        var mailformat=/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;


        var form_errors = resetFormErrors();

        var firsterrorid = null;

        
        if(!key || key=="email"){

            var field_value = key && inline ? value : formValues.email;

            if (field_value == '') {


                form_errors = {
                    ...form_errors,
                    email: "Required field",
                    error_class: "input_error"
                };
                error = true;
                firsterrorid = firsterrorid == null ? "email" : firsterrorid;
            }
            else if (field_value!=null && !field_value.match(mailformat)) {

                form_errors = {
                    ...form_errors,
                    email: "Please enter a valid email",
                    error_class: "input_error"
                };
                error = true;
                firsterrorid = firsterrorid == null ? "email" : firsterrorid;
            }
        }
        
        if((inline && formErrors[key] ) || !inline){
            
            setFormErrors(form_errors);
        }
        

        if(focus){
            if (firsterrorid != null) {
                document.getElementById(firsterrorid).scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                });
            }
        }

        return error;
    }
    const validateBtnState = () => {

        var error = false;

        var re = /^(?=.*\d)(?=.*[@$!%*#?&])(?=.*[a-zA-Z]).{6,}$/;

        var mailformat=/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

        if (formValues.email == '') {
            error = true;
        }
        else if (formValues.email!=null && !formValues.email.match(mailformat)) {

            error = true;
        }
        
        return error;
    }
    const onSubmit = async (event) => {
        event.preventDefault();

        
            setErrorMessage(null);

            if (!isLoading) {

                var error = validateFullForm(true);

                if (!error) {
                    sendEmailForForgotPassword();
                }
            }
                return false;
    }
    const set = name => {
        return ({ target: { value } }) => {
            const re = /^[A-Za-z]+$/;
            if (name == "first_name" || name == "last_name") {
                if (value === "" || re.test(value)) {
                    setFormValues(oldValues => ({ ...oldValues, [name]: value }));
                }
            } else {
                setFormValues(oldValues => ({ ...oldValues, [name]: value }));
            }

            setFormErrors((oldValues) => ({ ...oldValues, [name]: null }));

            validateFullForm(false,name, value ? value : '', true)
        }
    };
    const clearFormValue = name => {
        return ({ target: { value } }) => {

            setTimeout(function () {
                setFormValues(oldValues => ({ ...oldValues, [name]: '' }));
            }, 1000)

        }
    };
    
    
    const clearErrorMessage = () => {
        setErrorMessage(null);
    }
    
    const setFormValue = (name, value) => {
        setFormValues(oldValues => ({ ...oldValues, [name]: value }));
    };
    const timeFormat = (time)=>{
        var ms = time;
var min = Math.floor((ms/1000/60) << 0);
var sec = Math.floor((ms/1000) % 60);
return min + ':' + (sec < 10 ? "0"+sec : sec);

    }
    return (
        <div className="login-page-wrapper new-login-page-wrap  researcher-forgot-pass-main">
              <div className="researcher-registerheader login-page-header">
             <div className="container">
              <div className='row align-items-center'>
                <div className="col-xs-6 col-sm-6 login-hdr-left">
                    <a href={process.env.REACT_APP_RESEARCHER_URL} className="logo">
                        
                        <Logo theme={"black"} background={"white"} />
                    </a>
                </div>
      
                </div>
                </div>
            </div>
            <div className="login-data-wrap researcher-register-wrap forgot-pass-wrap">
                <div className="container">
                    <div className='row'>
                        <div className="col-md-6">
                        <div className="form-group ">
                            <Text type={"h3"} fontWeight={"semi-bold-font"}>Forgot your password?</Text>
                            
                            <Text type={"body-text-2"} fontWeight={"medium-font"}>Just enter your email address and we’ll send you an email containing all the info to reset your password.</Text>  
                            </div>
                            <div className="login-form-inner-data">
                                {errorMsg && (
                                    <div className="profile-complete-progress-wrap login-message-wrap error-bg-color">
                                        <div className="pro-lft-wrap">{errorMsg}</div>
                                        <div className="pro-right-wrap">
                                            <div className="delete-progress-row">
                                                <img
                                                    onClick={clearErrorMessage}
                                                    src={process.env.REACT_APP_URL + "images/cross.svg"}
                                                    alt="img"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <form method="POST" onSubmit={onSubmit}>
                                    <div className="formbox">
                                        <Input 
                                            readOnly={timer >0 ? true:false}
                                            clearField={timer >0 ? false:true}
                                            type="text"
                                            id="email"
                                            name="email"
                                            value={formValues.email}
                                            onChange={set("email")}
                                            label={"Email address"}
                                            onBlur={()=>{validateFullForm(false, "email")}}
                                            onClear={()=>{setFormValues({...formValues,email:''})}}
                                            required={true}
                                            error={formErrors.email}
                                        />
                                        
                                    </div>    
                                    
                                    <div className="full createaccount-btnbox">
                                    <Button 
                                        label={timer <= 0 ? "Send email" : "Send again in "+(timer > 0 ? timeFormat(timer):'') }
                                        state={(validateBtnState() || (timer > 0 && sentEmail))?"disabled":"active"}
                                        microLoading={isLoading && !modal.open}
                                        type={"primary"}
                                        size={"large"}
                                    />
                                    </div>
                                    <div className="full ordivider">
                                        <span>Or</span>
                                    </div>
                                <div className="login-bottom-link form-group resarcher-btn-register">
                                        Back to <Link to="/tester/sign-in">Sign in</Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <ConfirmationModal 
                            close={()=>{
                                setModal({...modal,open:false});
                            }}
                            openModal={modal.open}
                            confirm_title="Reset password"
                            confirm_btn_title={timer <= 0 ? "Send email" : "Send again in "+(timer > 0 ? timeFormat(timer):'') }
                            confirm_btn_disabled={timer > 0 ? "disabled" : "active"}
                            custom_confirm_message={true}
                            btnLoading={false}
                            confirm_btn_loading={isLoading}
                            confirm_message={
                                <span style={{color:"rgba(103, 103, 103, 1)"}}>
                                    <Text type={"body-text-2"} fontWeight="medium-font">
                                        We’ve just sent you an email with the link to reset your password.
                                    </Text>
                                    <div className="mt-25">
                                        <Text type={"body-text-2"} fontWeight="medium-font">
                                            If it doesn’t arrive to your inbox, check spam folder or request a new email.
                                        </Text>
                                    </div>

                                </span>
                            }
                            after_btn_message={
                                <div className="mt-25">
                                    <Text type={"body-text-2"} fontWeight="medium-font">
                                        <Link to="/researcher/sign-in">Back to sign in</Link>
                                    </Text>
                                </div>
                            }
                            cssClass={"forgotpassWrap"}
                            cancel_btn_hide={true}
                            confirm={()=>{sendEmailForForgotPassword();}}
                        />
                    </div>
                </div>
            </div>
              <Footer />
        </div>
    );
}