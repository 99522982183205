import React, { useEffect, useState } from "react";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { Header } from "../Header/Header";
import { Footer } from "../Footer/Footer";
import { ReactSession } from "../../../../lib/secure_reactsession";
import { LoadingIcon } from "../Loader/loadingIcon";
import { useNavigate } from "react-router-dom";
import { TestHeader } from "../TestHeader/TestHeader";
import { TesterHeader } from "../Header/TesterHeader";
import { SupportPanel } from "./elements/support_panel";
import { GuestHeader } from "../Header/GuestHeader";


export  const LayoutResearcher =  ({wrapClass, researcher, isSharedLink, isLoading, skipCheck, activeMenu, resultHeaderClass, openSupportPanel,hideSupportPanel, ...props}) =>{

    const user = ReactSession.get("user");

    const navigate = useNavigate();

    const [show_support_panel, setShowSupportPanel] = useState(false);
    
    useEffect(()=>{

        const user = ReactSession.get("user");

        const token = ReactSession.get("token");

        
        if(!props.skipProfileComplete && !isSharedLink) {

            if (user.role == "Researcher" && user.completed_step != 1) {

                 navigate('/r/profile/complete');
            }
        }
    },[]);

    useEffect(()=>{
        setShowSupportPanel(openSupportPanel);
    },[openSupportPanel])
    
    return (
        <SkeletonTheme baseColor="#cccccc" highlightColor="#e0e0e0">
            {!isLoading &&
            <>
            {isSharedLink ?
            <>
            <GuestHeader isSharedLink={isSharedLink} resultHeaderClass={resultHeaderClass}/>
            </>
            :
            <>
            {props.profileHeader &&
            <TesterHeader researcher={props.profileResearcher} isAccount={false} />
            }
            {!props.profileHeader &&
            <Header openSupportPanel={()=>{  setShowSupportPanel(true)}}   collaborators={[]} user={user} resultHeaderClass={resultHeaderClass} />
            }
            </>
            }
            
            <div className={`page-data-wrapper ${wrapClass} ${resultHeaderClass?"result-body":""}`}>
                {props.children}
            </div>
            
            <Footer />
            </>
            }
            {isLoading &&
                <div className="container inner-page-container">
                    <LoadingIcon />
                </div>
                
            }

            {!isSharedLink &&
                <SupportPanel researcher={researcher} showprop={show_support_panel} hideSupportPanel={() => {
                    setShowSupportPanel(false);

                    if (hideSupportPanel) {
                        hideSupportPanel(false);
                    }
                }}/>
            }
        </SkeletonTheme>
    )
}