import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import { Modal } from "react-bootstrap";
import { Text } from "../Text/Text";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Icon } from "../Icon/Icon";
import { ShareLink } from "../ShareLink/ShareLink";
import {
    FacebookShareButton,
    FacebookIcon,
    TwitterShareButton,
    TwitterIcon,
    WhatsappShareButton,
    WhatsappIcon
} from "react-share";
import {encryptId} from "../../../../lib/helpers";

export default function SuccessMessageModal({
    open,
    title,
    subtitle,
    message,
    btn,
    close,
    test_link,
    test
}) {
    const closeModal = () => {
        close();
    };
    const wrapperRef = useRef(null);

    const [testLink, setTestLink] = useState('');

    useEffect(()=>{

        var testLink = '';
        if(test){

            if(test.target_group.is_screening_questions && test.target_group.is_screening_questions==1){

                testLink = process.env.REACT_APP_URL+"t/screening/" + test.id;

            }else{
                testLink = process.env.REACT_APP_URL+"t/" + encryptId(test.id, test);
            }
        }

        setTestLink(testLink);

    },[open])
    

    const navigate = useNavigate();

    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    if(event.target.nodeName =="DIV"){
                        closeModal();
                        navigate("/dashboard");
                    }
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    useOutsideAlerter(wrapperRef);

    return (
        <Modal
            show={open}
            centered
            className="fade custom-modal-wrap  confirmation-modal-wrap congratulation-modal-wrap"
        >
            
            <Modal.Body className="" ref={wrapperRef}>
            <img className="congrts-img"
                                    src={process.env.REACT_APP_URL + "img/congrts.svg"}
                                    alt="cross"
                                />

                <div className="congrats-modal-text">

                    <Text type={"h1"}>{title}</Text>
                    <Text type={"h2"}>{subtitle}</Text>

                    <ShareLink link={testLink} />


                    <Text type={"body-text-1"} fontWeight={'medium-font'}>Share this link with your testers to get more results.</Text>
                    <div className=" share-social-links-success">
                        <h5 className="test-link-head share-link-text">Or share it via</h5>
                        <div className={"share-social-links text-center"}>
                            <FacebookShareButton
                                url={testLink}
                                quote={"Share the link with your own testers. The test will be visible to anyone that possess the link"}
                                className="facebook__some-network__share-button"
                            >
                                <FacebookIcon size={32} round />
                            </FacebookShareButton>

                            <TwitterShareButton
                                url={testLink}
                                title={"Share the link with your own testers. The test will be visible to anyone that possess the link"}
                                className="twitter__some-network__share-button"
                            >
                                <TwitterIcon size={32} round />
                            </TwitterShareButton>

                            <WhatsappShareButton
                                url={testLink}
                                title={"Share the link with your own testers. The test will be visible to anyone that possess the link"}
                                separator=":: "
                                className="whatsapp__some-network__share-button"
                            >
                                <WhatsappIcon size={32} round />
                            </WhatsappShareButton>
                        </div>
                    </div>
                    <Text type={"body-text-1"} fontWeight={'medium-font'}>Access the test results report from the dashboard</Text>
                    
                    <div className="congrts-modal-wrap">
                        {btn}
                    </div>

                </div> 
            </Modal.Body>
        </Modal>
    );
}
