import React, {useState} from "react";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import {Icon} from "../Icon/Icon";

export const HamburgerMenu = ({open, items, wrapClass}) => {

    const [hamburgerMenuOpen, setHamburgerMenuOpen] =   useState(open);
    return (
      <div className={`hamburger-menu ${wrapClass}`}>

          <Dropdown className="dropdown">
              <Dropdown.Toggle className="dropdown-toggle">
                  <span className="menu-item">
                      <Icon value={'quick-menu'} size={'medium'} colorClass={'hamburger-menu-img'} />
                </span>
              </Dropdown.Toggle>
              {items.length > 0 &&
              <Dropdown.Menu className={'hamburger-items'} show={open}>

                  {items && items.map((item, index) => {
                      return (
                          <>
                              <Dropdown.Item key={index}
                                             className={`dropdown-item ${item.itemClass ? item.itemClass : ''}`}
                                             onClick={item.onClick}>
                                  {item.name}
                              </Dropdown.Item>
                          </>
                      );
                  })}


              </Dropdown.Menu>
              }
          </Dropdown>

      </div>
    );
};


HamburgerMenu.propTypes = {
    //open: PropTypes.bool,
    wrapClass: PropTypes.string,
    items:  PropTypes.arrayOf(PropTypes.shape({
                name: PropTypes.string.isRequired,
                isLink: PropTypes.bool.isRequired,
                urlLink: PropTypes.string,
                onClick: PropTypes.func,
              })).isRequired,

};
HamburgerMenu.defaultProps = {
    //open: false,
    wrapClass: '',
    items: []

};