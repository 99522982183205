import { Link } from "react-router-dom";
import { Icon } from "../../../themes/userq/Icon/Icon";
import React, {useEffect, useRef, useState} from "react";

export const TestResultsSidebarNavigation = ({selected_index,isSharedLink, methodology, test_id, screening,isLoadFirst}) =>{

    const containerRef = useRef(null);

    useEffect(() => {
        setTimeout(() => {
            scrollToActiveElement();
        }, 1000); // Adjust the delay as needed
    }, []);

    const scrollToActiveElement = () => {
        var itemRef = document.getElementById('selected-index-item-' + selected_index)
        if (containerRef.current && itemRef) {

            const container = containerRef.current;
            const containerWidth = container.clientWidth;
            const scrollWidth = container.scrollWidth;
            const itemWidth = itemRef.clientWidth;


            // Calculate the scroll position to center the clicked item
            const itemOffset = itemRef.offsetLeft;
            const scrollPosition = Math.max(itemOffset - (containerWidth - itemWidth) / 2, 0);



            if(document.body.clientWidth < 767){

                const containers = document.getElementById('selected-index-item-' + selected_index);
                containers.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                });

                containers.scrollLeft += scrollPosition;
            }

        }
    };

    const options = isSharedLink 
        ? 
        [
            {"icon":"eye", "title":"Overview", "link": "/v/results/"+test_id+"/overview"}, 
            {"icon":"introduction", "title":"Screening", "link": "/v/results/"+test_id+"/screening-questions"}, 
            {"icon":"group", "title":"Participants", "link": "/v/results/"+test_id+"/participants"},
            {"icon":"introduction", "title":"Introduction", "link": "/v/results/"+test_id+"/introduction-questions"}, 
            {"icon":"click", "title":"Methodology","link": 
            
                {
                    "Card Sorting": "/v/results/"+test_id+"/card-sorting",
                    "Tree Test": "/v/results/"+test_id+"/tree-test",
                    "Survey": "/v/results/"+test_id+"/survey",
                    "5 second test": "/v/results/"+test_id+"/5-second-test",
                    "Preference Test": "/v/results/"+test_id+"/preference-test",
                    "Prototype test": "/v/results/"+test_id+"/prototype-test",
                    "First click": "/v/results/"+test_id+"/first-click",
                },
                "title": 
            
                {
                    "Card Sorting": "Card sorting",
                    "Tree Test": "Tree test",
                    "Survey": "Survey",
                    "5 second test": "5 second test",
                    "Preference Test": "Preference test",
                    "Prototype test": "Prototype test",
                    "First click": "First click",
                }
            },
            {"icon":"introduction", "title":"Conclusion", "link": "/v/results/"+test_id+"/conclusion-questions"}
        ]
        :
        [
            {"icon":"eye", "title": "Overview", "link": "/r/results/"+test_id+"/overview"}, 
            {"icon":"introduction", "title":"Screening", "link": "/r/results/"+test_id+"/screening-questions"},
            {"icon":"group", "title":"Participants", "link": "/r/results/"+test_id+"/participants"}, 
            {"icon":"introduction", "title":"Introduction", "link": "/r/results/"+test_id+"/introduction-questions"}, 
            {"icon":"click", "title":"Methodology","link": 
            
                {
                    "Card Sorting": "/r/results/"+test_id+"/card-sorting",
                    "Tree Test": "/r/results/"+test_id+"/tree-test",
                    "Survey": "/r/results/"+test_id+"/survey",
                    "5 second test": "/r/results/"+test_id+"/5-second-test",
                    "Preference Test": "/r/results/"+test_id+"/preference-test",
                    "Prototype test": "/r/results/"+test_id+"/prototype-test",
                    "First click": "/r/results/"+test_id+"/first-click",
                },
                "title": 
            
                {
                    "Card Sorting": "Card sorting",
                    "Tree Test": "Tree test",
                    "Survey": "Survey",
                    "5 second test": "5 second test",
                    "Preference Test": "Preference test",
                    "Prototype test": "Prototype test",
                    "First click": "First click",
                }
            },
            {"icon":"introduction", "title":"Conclusion", "link": "/r/results/"+test_id+"/conclusion-questions"}
        ];
	
    const getOption = (index, option) => {

                return <li key={option.title}  id={`selected-index-item-${index}`} className={`${selected_index==index?"selected-option":""}`}>
                    {option.link && selected_index!=index &&
                    <Link to={index!=4?option.link:option.link[methodology]}>
                    <span><Icon colorClass="gray-50-svg" value={option.icon} size={"small"} /></span>
                    {index==4 &&
                    <>{option.title[methodology]?option.title[methodology]:methodology}</>
                    }
                    {index!=4 &&
                    <>{option.title}</>
                    }
                    </Link>
                    }
                    {!(option.link && selected_index!=index) &&
                    <>
                    <span><Icon colorClass="gray-50-svg" value={option.icon} size={"small"} /></span>
                    {index==4 &&
                    <>{option.title[methodology]?option.title[methodology]:methodology}</>
                    }
                    {index!=4 &&
                    <>{option.title}</>
                    }
                    </>
                    }
                </li>;
    }

    // const refAssignCallback = (ref) => {
    //     if (ref) {
    //         //ref available = mounted.
    //         var element = ref;
    //         var scrollWidth = element.scrollWidth;
    //         var clientWidth = element.getBoundingClientRect().width;
    //
    //
    //         var itemRef = document.getElementById('selected-index-item-' + selected_index)
    //
    //         //console.log(itemRef)
    //
    //         const itemWidth = itemRef.clientWidth;
    //
    //
    //         // Calculate the scroll position to center the clicked item
    //         const itemOffset = itemRef.offsetLeft;
    //         const scrollPosition = Math.max(itemOffset - (scrollWidth - clientWidth) / 2, 0);
    //
    //         //console.log(scrollWidth)
    //         //console.log(clientWidth)
    //         //console.log(scrollPosition)
    //         //explicitly set the scrollTop position of the scrollContainer
    //         // element.scrollLeft = 200 + (scrollWidth - clientWidth) / 2;
    //
    //         if(scrollPosition){
    //
    //             if(selected_index === 2){
    //
    //                // element.scrollLeft = (scrollWidth - clientWidth) / 2;
    //
    //                 element.scrollLeft = scrollPosition+100;
    //             }else{
    //                 //element.scrollLeft = (scrollWidth - clientWidth) / 2
    //                  element.scrollLeft = scrollPosition+100;
    //             }
    //
    //
    //
    //         }
    //
    //     }
    // };


    return <div className='stepper-wrap test-result-nav-wrap' ref={containerRef}>
        <ul className="test-results-navigation">
            {options.map(function(option, index){
                return (index==1
                        ?
                        <>
                            {screening=="No" ?
                                <></>
                                :
                                <>{getOption(index,option)}</>
                            }
                        </>
                        :
                        <>{getOption(index,option)}</>
                );
            })}
        </ul></div>;

    
}