import React, {useEffect, useRef, useState} from "react";
import { useLocation } from "react-router-dom";
import PropTypes from 'prop-types';
import {Tag} from '../Tag/Tag';
import {encryptId, roundNumber} from "../../../../lib/helpers";
import {Icon} from "../Icon/Icon";
import {Button} from "../Button/Button";
import {Text} from "../Text/Text";
import Skeleton from "react-loading-skeleton";
import {RadioButton} from "../RadioButton/RadioButton";
import toast from "react-hot-toast";
import {ToastMessage} from "../ToastMessage/ToastMessage";
import AutosizeInput from 'react-input-autosize';
import { settings } from "../../../../data/settings";
import { ReactSession } from "../../../../lib/secure_reactsession";
import { Tooltip } from "react-bootstrap";
import { useTestBuilderData } from "../../../researcher/tests/contexts/TestBuilderContext";

export const TestHeader = ({testName,testLanguage,methodology,credits,isLoading,previewLink,isSaved,isSavedMessage,submitHandler,isErrorTestName,savedTestName}) => {

    const [isEdit, setIsEdit] =   useState(false);
    const inputref = useRef(null);

    const location = useLocation();

    const [formValues, setFormValues] = useState({ "test_name": null });

    const {test_time_length} = useTestBuilderData();

    const set = (name) => {
        return ({ target: { value } }) => {
            setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
        };
    };

    const handleKeyDown = (e)=>{

        if (e.key === 'Enter') {
            if(!formValues.test_name){
                showError('Test name field is required')
                return false;
            }

            if (submitHandler) {
                submitHandler(formValues.test_name)
            }
            setIsEdit(false);
        }
    }

    const showError = (error) => {
        toast(
            <ToastMessage type={"error"} message={error} closable={true} onClose={()=>{toast.dismiss();}} />,
            {
                className: "errortoast",
                position: "bottom-center",
                duration: settings.toast_duration,
            }
        );
    };


    useEffect(() => {

        if (isEdit) {
            if (inputref.current) {
                inputref.current.focus()
            }
        }


    }, [isEdit]);

    useEffect(()=>{
        if(isErrorTestName){

            //console.log(savedTestName)
            if(savedTestName){
                setFormValues({test_name: savedTestName});
            }else{
                setFormValues({test_name: testName});
            }

            setIsEdit(true);
        }

    },[isErrorTestName])



    const renderHeader = ()=>{
        if(!isLoading && !testName){
            return (
                <div className="three-column-layout-top-header">
                    <div className="d-flex create-test-inner-hdr w-100 justify-content-between">
                        <div className="test-header-left-data">
                            <div className="test-name-wrap text-center" style={{textAlign:"center"}}>
                                <Text type={"body-text-2"} fontWeight={"medium-font"}> Failed to load test. Please try again later.</Text>
                            </div>
                        </div>
                    </div>
                </div>);
        } else {
            return ( 
                
                <div className="three-column-layout-top-header">
                    <div className="d-flex create-test-inner-hdr w-100 justify-content-between">
                        <div className="test-header-left-data">
                            <div className="test-name-wrap">

                                {isLoading ?
                                    <Skeleton width={150} height={40} />
                                    :
                                    <>
                                        {(isEdit) ?
                                            <div className={'edit-test-name heading'}>
                                                <AutosizeInput
                                                    className={`auto-width-input ${(testLanguage === 'ar')?'arabic_wrapper':''}`}
                                                    value={formValues.test_name}
                                                    placeholder={'Enter name'}
                                                    onChange={set("test_name")}
                                                    onKeyDown={handleKeyDown}
                                                    maxLength={35}
                                                    ref={inputref}
                                                />
                                            </div>
                                            :
                                            <Text type={'h2'}
                                                cssClasses={(testLanguage === 'ar')?'arabic-font':''}
                                                children={
                                                    (!formValues.test_name) ?
                                                        (testName)
                                                        :
                                                        formValues.test_name

                                                }
                                            />

                                        }
                                    </>
                                }

                                <div className="test-name-action">
                                {!isLoading &&
                                    <span className="edit-test-name" onClick={() => {
                                        // enable editing
                                        if (!isEdit && isSaved !== 'loading') {
                                            setIsEdit(true);
                                            if (!formValues.test_name) {
                                                setFormValues({test_name: testName});
                                            }
                                        } else {

                                            if (submitHandler) {
                                                // check value is not empty
                                                if(!formValues.test_name){
                                                    showError('Test name field is required')
                                                    return false;
                                                }

                                                submitHandler(formValues.test_name)
                                                setIsEdit(false);
                                            }
                                            //
                                        }

                                    }}>
                                        {!isEdit ?
                                            <Icon value={'edit'} size={'medium'} hover={true} />
                                            :
                                            <Icon value={'check-mark'} size={'medium'} hover={true} />
                                        }
                                    </span>
                                }  
                                    {test_time_length &&
                                    <div className="test-length-wrap d-flex align-items-center none-for-desktop">
                                        <span className="info-wrap">
                                        <Icon value={'length'} size={'medium length-icon'}/>
                                    
                                            <div className="tooltip-wrapper top-tooltip">
                                                <Text type='body-text-2' fontWeight='normal-font'>{test_time_length}</Text>
                                            </div>
                                        
                                        </span>
                                    </div>
                                    }
                                </div>

                            </div>
                            <div className="test-header-tags">

                                {isLoading ?
                                    <Skeleton width={130} height={40} style={{marginRight:'0px',marginLeft:'20px'}} />
                                    :
                                    <Tag
                                        title={(testLanguage === 'en')?'English':'عربى'}
                                        type="gold-tag"
                                        cssClasses={(testLanguage === 'ar')?'arabic-font':''}
                                    />
                                }

                                {isLoading ?
                                    <Skeleton width={130} height={40} style={{marginRight:'20px',marginLeft:'20px'}} />
                                    :
                                    <Tag
                                        title={methodology=="Five Seconds Test" ? "5 second test" : methodology}
                                        type="pink-tag"
                                    />
                                }
                            </div>
                        </div>
                        <div className="test-header-right-data">
                             {test_time_length &&
                             <div className="test-length-wrap d-flex align-items-center">
                                <Icon value={'length'} size={'medium length-icon'}/>
                                <Text type={'caption'}>{test_time_length}</Text>
                                <div className="tooltip-wrapper top-tooltip">
                                    <Text type='body-text-2' fontWeight='normal-font'>The study duration is determined by the questions and tasks included. The estimated average time for a task is calculated based on the average time taken by UserQ testers for that specific task or question type.</Text>
                                </div>
                             </div>
                            }
                             <div className="header-autosave-icon"> 
                                {(isSaved === 'success') ?
                                <span className="autosave-icon info-wrap position-relative">
                                <Icon value={'success'} colorClass={'success-200-svg success-icon'} />
                                <div className="tooltip-wrapper top-tooltip">
                                    <Text type='body-text-2' id='tooltip'
                                        children={(isSavedMessage) ? isSavedMessage : 'All changes have been saved.'}
                                        fontWeight='normal-font'/>
                                </div>
                            </span>
                                :
                                <>
                                    {(isSaved === 'error') ?
                                        <span className="autosave-icon info-wrap position-relative">

                                        <Icon value={'error'} colorClass={'danger-200-svg error-icon'} />
                                        <div className="tooltip-wrapper top-tooltip">
                                            <Text type='body-text-2' id='tooltip'
                                                children={isSavedMessage ? isSavedMessage : 'Something went wrong.'}
                                                fontWeight='normal-font'/>
                                        </div>
                                    </span>
                                        :
                                        <>
                                            {(isSaved === 'loading') &&
                                            <span className="autosave-icon info-wrap">
                                                <i
                                                    className="fa fa-spinner fa-spin"
                                                    aria-hidden="true"
                                                    style={{ marginRight: "5px" }}
                                                ></i>
                                        </span>
                                            }
                                        </>
                                    }
                                </>
                               
                            }
                            </div>


                            <Button
                                size={'medium'}
                                type={'secondary'}
                                iconLeft={<Icon value={'eye'}/>}
                                label={`Preview test`}
                                onClick={() => {
                                    if(!isLoading && previewLink){

                                        window.dataLayer = window.dataLayer || [];
                                        window.dataLayer.push({
                                            'event' : 'preview_test',
                                            'userID' : ReactSession.get("user") ? ReactSession.get("user").user_id : ""
                                        });

                                        window.open(previewLink,'_blank')

                                        ReactSession.set('preview_test_link',location.pathname.substring(location.pathname.lastIndexOf('/') + 1));
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>
            )
        }
    }
    return renderHeader();

    
};



TestHeader.defaultProps = {
    testName: PropTypes.string,
    isLoading: PropTypes.bool,
    isSaved: PropTypes.oneOf(["success","error","loading"]),
    isSavedMessage:PropTypes.string,
    methodology:PropTypes.string,
    previewLink:PropTypes.string,
    submitHandler:PropTypes.func,

};

TestHeader.defaultProps = {
   // onChange: undefined

};