import { useRef} from "react";
import {Box} from "../../../themes/userq/Box/Box";
import { Icon } from "../../../themes/userq/Icon/Icon";
import { Tooltip } from "../../../themes/userq/Tooltip/Tooltip";
import exportAsImage from "../../../../lib/exportAsImage";

export const DevicesStatsBox = ({devices, active=[]}) =>{

	const exportPngRef = useRef();

	const renderDeviceIcon = (device) => {
		if (device == "Desktop") {
			return <Icon value={"desktop"} size={"large"} />
		}
		if (device == "Mobile") {
			return <Icon value={"Phone"} size={"large"} />
		}
		if (device == "Smartphone") {
			return <Icon value={"Phone"} size={"large"} />
		}
		if (device == "Phone") {
			return <Icon value={"Phone"} size={"large"} />
		}
		if (device == "Tablet") {
			return <Icon value={"Tablet"} size={"large"} />
		}
		if (device == "Unknown") {
			return <Icon value={"support"} size={"large"} />
		}
	}
	const renderDevices = () => {
		var index = 0;

		var data = [];

		for (var key in devices) {
			data.push((
				<div key={"device" + key} className={`device-list-repeat ${index == 0 ? 'active' : ''}  ${key=="Unknown" || active.indexOf(key)!=-1?'device-active':'device-disabled'} `}>
					<div className="tech-icon-wname">
						<span className="info-icon info-wrap position-relative">{renderDeviceIcon(key)}
							{key=="Unknown" &&
							<Tooltip
								className={"tooltip-wrapper"}
								type={"bottom"}
								data="If you directly shared the link with others, you might see unknown users outside your target demographic group."
							/>
							}
						</span>
						<span>
							{key}
                        </span>
					</div>
					<div className={`device-selected-count`}>{devices[key]}</div>
				</div>
			))
			index++;

		}
		return <div className="device-option-data-wrap">{data}</div>;
	}


	return<div className="chart-hold-wrap position-relative">
		<div className="download-chart-png cursor-pointer" onClick={() => exportAsImage(exportPngRef.current, "Favourite devices")}>
			<Icon value={'download'} size={'small'} hover={'true'}></Icon>
			<div className="tooltip-wrapper top-tooltip">
				<p class="body-text body-text-2 normal-font ">Download</p>
			</div>
		</div>
		<div className="donwload-chart-ref" ref={exportPngRef}><Box title={
			"Favourite devices"
		} size={"medium"}>
			{renderDevices()}
		</Box></div></div>;
}